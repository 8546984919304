import React, {Component} from 'react'
import {withStyles, Grid} from '@material-ui/core'
import {RemoveCircle} from '@material-ui/icons'
import PropTypes from 'prop-types'

class UploadFilesList extends Component {
    render() {
        return (
            <Grid container item spacing={24} alignItems={'center'}>
                <Grid item md={2}> </Grid>
                <Grid item>
                    <ol>{this.props.files.map((f, i) =>
                        <li key={'files' + i}>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item>
                                    {f.name}
                                </Grid>
                                <Grid item>
                                    <RemoveCircle color={'secondary'}
                                        onClick={() => this.props.onRemoveCircleClick(f, i)}/>
                                </Grid>
                            </Grid>
                        </li>)
                    }</ol>
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

UploadFilesList.propTypes = {
    files: PropTypes.array.isRequired,
    onRemoveCircleClick: PropTypes.func.isRequired
}

export default withStyles(styles)(UploadFilesList)