import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Grid, TableRow, TableCell, FormControl, FormHelperText, FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import moment from 'moment'
import StyledSection from '../../components/StyledSection'
import { setMockData, resetIndividualFormReducer } from '../../ducks/individualFormDuck'
import { TITLE_PREFIX } from '../../constants/titlePrefix'
import PassengersVoucherTable from '../../components/PassengersVoucherTable'
import ConsentDerivedDuckContainer from '../derivedDuckContainers/consentDerivedDuckContainer'

class CollectorSuccessDisplay extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			consentError: '',
		}
	}

	componentDidMount() {
		document.title = `${TITLE_PREFIX} Individual Collector Success`
	}

	_renderTableBody = () => {
		const {
			individualFormDuck: { formDetailsList },
		} = this.props
		const { individualCollectorOptionDuck } = this.props

		const final = []
		formDetailsList.forEach((p) => {
			if (p.isDeleted) return
			final.push(
				<TableRow key={p.pax} padding={'dense'}>
					<TableCell>{p.eTicketsDetails.eTicket1}</TableCell>
					<TableCell>
						{p.personalDetails.firstName} {p.personalDetails.lastName}
					</TableCell>
					{!individualCollectorOptionDuck.isCtpPhysicalVoucher && <TableCell>{p.cardNo}</TableCell>}
					{individualCollectorOptionDuck.isCtpPhysicalVoucher && <TableCell>{p.genPhysicalVoucherNo}</TableCell>}
					{individualCollectorOptionDuck.isCtpPhysicalVoucher && <TableCell>{p.lpcPhysicalVoucherNo}</TableCell>}
					<TableCell>{`$${p.ctpGenValue} GEN | $${p.ctpLpcValue} LPC`}</TableCell>
				</TableRow>
			)
		})
		return final
	}

	_totalReceiptValue = () => {
		const {
			individualFormDuck: { formDetailsList },
		} = this.props

		let sum = formDetailsList.reduce((sum, p) => {
			return !p.isDeleted ? sum + p.ctpVoucherValue : sum
		}, 0)

		return sum
	}

	render() {
		const { classes, individualFormDuck, consentDerivedDuck } = this.props
		const { collectorDetails } = individualFormDuck

		return (
			<div>
				<div className={classes.container}>
					<ConsentDerivedDuckContainer />

					<StyledSection title={'Collector Details'} toolbarButtons={this.props.toolbarButtons}>
						<Grid container spacing={16} direction={'column'}>
							<Grid container item spacing={16}>
								<Grid item>Collector's name:</Grid>
								<Grid item>
									{collectorDetails.name} {collectorDetails.surname}
								</Grid>
							</Grid>

							<Grid container item spacing={16}>
								<Grid item>Collector Date/Time:</Grid>
								<Grid item>{moment().format('DD-MM-YY HH:mm:ss')}</Grid>
							</Grid>

							<Grid item>
								<PassengersVoucherTable
									collectorDetails={individualFormDuck.collectorDetails}
									passengers={individualFormDuck.formDetailsList}
									collectorSuccess={true}
									history= {this.props.history}
									onEditPax={() => {}}
								/>
							</Grid>

							<Grid item>
								<Typography component='span' variant={'body1'} className={classes.consentText}>
									<div dangerouslySetInnerHTML={{ __html: consentDerivedDuck.airlineConsent }} />
								</Typography>
							</Grid>

							<Grid item>
								<div className={classes.row}>
									<div className={classes.rowItem}>
										<div>
											<FormControl error={this.state.consentError !== ''} variant={'outlined'}>
												<FormHelperText>{this.state.consentError}</FormHelperText>
												<FormControlLabel
													control={<Checkbox onChange={this.onConsent} />}
													disabled={true}
													checked={collectorDetails.allowReceivingInfo}
													className={classes.flexStart}
													label={
														<Typography variant={'body1'} component='span' className={classes.consentText}>
															<div dangerouslySetInnerHTML={{ __html: consentDerivedDuck.marketingConsent }} />
														</Typography>
													}
												/>
											</FormControl>
										</div>
									</div>
								</div>
							</Grid>

							{(collectorDetails.clientSignatureBase64 !== '' || collectorDetails.csoSignatureBase64 !== '') && (
								<Grid container item spacing={16} direction={'column'}>
									<Grid item>
										<Typography variant={'body1'}>Collector's Signature:</Typography>
									</Grid>
									<Grid item>
										<img
											src={collectorDetails.clientSignatureBase64 !== '' ? collectorDetails.clientSignatureBase64 : collectorDetails.csoSignatureBase64}
											alt=''
											className={'signature'}
										/>
									</Grid>

									<Grid item>
										<Typography component='span' variant={'body1'}>
											<div dangerouslySetInnerHTML={{ __html: consentDerivedDuck.collectionConsent }} />
										</Typography>
									</Grid>
								</Grid>
							)}
						</Grid>
					</StyledSection>
				</div>
			</div>
		)
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
	row: { display: 'flex' },
	rowItem: { display: 'flex', flex: 1, marginRight: 60, alignItems: 'center' },
	flexStart: { alignItems: 'flex-start' },
	consentText: { fontSize: '0.8125rem' },
})

const mapStateToProps = (state) => {
	const { individualFormDuck, individualCollectorOptionDuck, consentDerivedDuck } = state

	return { individualFormDuck, individualCollectorOptionDuck, consentDerivedDuck }
}

const mapDispatchToProps = (dispatch) => {
	return {
		setMockData: () => dispatch(setMockData()),
		resetIndividualFormReducer: () => dispatch(resetIndividualFormReducer()),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(CollectorSuccessDisplay))
