import axios from 'axios'
import {getSettings} from '../config/config'
import {getCurrentAuthHeader} from '../helpers/utilities'
import {checkValidStatusCode} from "../helpers/httpHelper";

const GET_ALL_PHYSICAL_VOUCHERS_IS_LOADING = 'GET_ALL_PHYSICAL_VOUCHERS_IS_LOADING'
const GET_ALL_PHYSICAL_VOUCHERS_SUCCESS = 'GET_ALL_PHYSICAL_VOUCHES_SUCCESS'
const GET_ALL_PHYSICAL_VOUCHERS_FAIL = 'GET_ALL_PHYSICAL_VOUCHERS_FAIL'
const ADD_PHYSICAL_VOUCHERS_IS_LOADING = 'ADD_PHYSICAL_VOUCHERS_IS_LOADING'
const ADD_PHYSICAL_VOUCHERS_SUCCESS = 'ADD_PHYSICAL_VOUCHERS_SUCCESS'
const ADD_PHYSICAL_VOUCHERS_FAIL = 'ADD_PHYSICAL_VOUCHERS_FAIL'
const UPDATE_PHYSICAL_VOUCHER_IS_LOADING = 'UPDATE_PHYSICAL_VOUCHER_IS_LOADING'
const UPDATE_PHYSICAL_VOUCHER_SUCCESS = 'UPDATE_PHYSICAL_VOUCHER_SUCCESS'
const UPDATE_PHYSICAL_VOUCHER_FAIL = 'UPDATE_PHYSICAL_VOUCHER_FAIL'
const DELETE_PHYSICAL_VOUCHER_IS_LOADING = 'DELETE_PHYSICAL_VOUCHER_IS_LOADING'
const DELETE_PHYSICAL_VOUCHER_SUCCESS = 'DELETE_PHYSICAL_VOUCHER_SUCCESS'
const DELETE_PHYSICAL_VOUCHER_FAIL = 'DELETE_PHYSICAL_VOUCHER_FAIL'

let settings = getSettings();
let baseURL = settings.baseURL;

export const getAllPhysicalVouchers = data => async dispatch => {
    dispatch(_getAllPhysicalVouchersIsLoading())
    const rs = await axios.get(baseURL + '/PhysicalVoucher/GetPhysicalVouchersByAttributes', data).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_getAllPhysicalVouchersSuccess(rs.data.result.items))
        return {success: true, message: '', totalCount: rs.data.result.totalCount}
    } else {
        dispatch(_getAllPhysicalVouchersFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _getAllPhysicalVouchersIsLoading = () => ({
    type: GET_ALL_PHYSICAL_VOUCHERS_IS_LOADING
})

const _getAllPhysicalVouchersSuccess = physicalVouchers => ({
    type: GET_ALL_PHYSICAL_VOUCHERS_SUCCESS,
    physicalVouchers
})

const _getAllPhysicalVouchersFail = () => ({
    type: GET_ALL_PHYSICAL_VOUCHERS_FAIL
})

export const updatePhysicalVouchers = data => async dispatch => {
    dispatch(_updatePhysicalVoucherIsLoading())

    const rs = await axios.post(baseURL + '/PhysicalVoucher/EditPhysicalVoucher', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_updatePhysicalVoucherSuccess(rs.data.result))
        return {success: true, message: ''}
    } else {
        dispatch(_updatePhysicalVoucherFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _updatePhysicalVoucherIsLoading = () => ({
    type: UPDATE_PHYSICAL_VOUCHER_IS_LOADING
})

const _updatePhysicalVoucherSuccess = () => ({
    type: UPDATE_PHYSICAL_VOUCHER_SUCCESS
})

const _updatePhysicalVoucherFail = () => ({
    type: UPDATE_PHYSICAL_VOUCHER_FAIL
})

export const deletePhysicalVoucher = id => async dispatch => {
    dispatch(_deletePhysicalVoucherIsLoading())

    const deleteHeader = {...getCurrentAuthHeader()}
    const rs = await axios.delete(baseURL + `/PhysicalVoucher/DeletePhysicalVoucher?id=${id}`, deleteHeader).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_deletePhysicalVoucherSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_deletePhysicalVoucherFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _deletePhysicalVoucherIsLoading = () => ({
    type: DELETE_PHYSICAL_VOUCHER_IS_LOADING
})

const _deletePhysicalVoucherSuccess = rs => ({
    type: DELETE_PHYSICAL_VOUCHER_SUCCESS,
    rs
})

const _deletePhysicalVoucherFail = () => ({
    type: DELETE_PHYSICAL_VOUCHER_FAIL
})

export const addPhysicalVouchers = data => async dispatch => {
    dispatch(_addPhysicalVouchersIsLoading())
    const rs = await axios.post(baseURL + '/PhysicalVoucher/AddPhysicalVouchers', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_addPhysicalVouchersSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_addPhysicalVouchersFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _addPhysicalVouchersIsLoading = () => ({
    type: ADD_PHYSICAL_VOUCHERS_IS_LOADING
})

const _addPhysicalVouchersSuccess = () => ({
    type: ADD_PHYSICAL_VOUCHERS_SUCCESS
})

const _addPhysicalVouchersFail = () => ({
    type: ADD_PHYSICAL_VOUCHERS_FAIL
})

const initState = {
    getAllPhysicalVouchersIsLoading: false,
    physicalVouchers: [],
    updatePhysicalVoucherIsLoading: false,
    deletePhysicalVoucherIsLoading: false,
    addPhysicalVouchersIsLoading: false
}

const PhysicalVoucherDuck = (state = initState, action) => {

    switch (action.type) {
        case GET_ALL_PHYSICAL_VOUCHERS_IS_LOADING:
            return {...state, getAllPhysicalVouchersIsLoading: true}

        case GET_ALL_PHYSICAL_VOUCHERS_SUCCESS:
            return {...state, getAllPhysicalVouchersIsLoading: false, physicalVouchers: action.physicalVouchers}

        case GET_ALL_PHYSICAL_VOUCHERS_FAIL:
            return {...state, getAllPhysicalVouchersIsLoading: false}

        case UPDATE_PHYSICAL_VOUCHER_IS_LOADING:
            return {...state, updatePhysicalVoucherIsLoading: true}

        case UPDATE_PHYSICAL_VOUCHER_SUCCESS:
            return {...state, updatePhysicalVoucherIsLoading: false}

        case UPDATE_PHYSICAL_VOUCHER_FAIL:
            return {...state, updatePhysicalVoucherIsLoading: false}

        case DELETE_PHYSICAL_VOUCHER_IS_LOADING:
            return {...state, deletePhysicalVoucherIsLoading: true}

        case DELETE_PHYSICAL_VOUCHER_SUCCESS:
            return {...state, deletePhysicalVoucherIsLoading: false}

        case DELETE_PHYSICAL_VOUCHER_FAIL:
            return {...state, deletePhysicalVoucherIsLoading: false}

        case ADD_PHYSICAL_VOUCHERS_IS_LOADING:
            return {...state, addPhysicalVouchersIsLoading: true}

        case ADD_PHYSICAL_VOUCHERS_SUCCESS:
            return {...state, addPhysicalVouchersIsLoading: false}

        case ADD_PHYSICAL_VOUCHERS_FAIL:
            return {...state, addPhysicalVouchersIsLoading: false}

        default:
            return state
    }
}

export default PhysicalVoucherDuck