import {getSettings} from "../config/config.js"

export const urlData = {
    ctpTncUrl: "http://www.changiairport.com/ctp/tnc",
    crTncUrl: "https://login.changiairport.com/static/login/files/terms_and_conditions_en.html",
    crtTncUrl: "https://www.changirewardstravel.com/en/Terms-And-Conditions.aspx",
    cagPpUrl: "https://www.changiairport.com/en/privacy-policy.html",
    crPpUrl: "https://www.changirewards.com/privacy-policy.aspx",
};

let settings = getSettings()
let voucherCurrency = settings.voucher_currency
// 2 way transit
let ctpVoucher = settings.voucher_sources.find(s => s.membership_type == "CTP")
let ctpGenVoucher = ctpVoucher.vouchers.find(s => s.voucher_type == "GEN")
let ctpLpcVoucher = ctpVoucher.vouchers.find(s => s.voucher_type == "LPC")
let crVoucher = settings.voucher_sources.find(s => s.membership_type == "CR")
let crGenVoucher = crVoucher.vouchers.find(s => s.voucher_type == "GEN")
let crLpcVoucher = crVoucher.vouchers.find(s => s.voucher_type == "LPC")
export const voucherData = {
    ctpGenVoucherValue: ctpGenVoucher.voucher_value,
    ctpLpcVoucherValue: ctpLpcVoucher.voucher_value,
    crGenVoucherValue: crGenVoucher.voucher_value,
    crLpcVoucherValue: crLpcVoucher.voucher_value
}

export const templateData = {
    lpcSubMessage: `<span> and |numberVoucherIssuanced| CTP LPC eVoucher(s) for Liquor, Perfumes and Cosmetics purchases only</span>`,

    groupAdditionalCrAgreement: `By submitting this form to register for the Changi Rewards programme, Iagree to the |crTncUrl| and consent to receiving communications about the benefits and entitlements of the Changi
Rewards programme from time to time. I hereby give CAG permission to use my personal data for the purpose of the Changi Rewards programme, in accordance with and subject to our |crPpUrl|.`,

    airlineConsent: `Please note that the information you provide may be used to verify the relevant itinerary with participating airlines and to improve the products and services we offer to you.`,

    airlineGroupConsent: `Please note that the information you provide may be used to verify the relevant itinerary with participating airlines and to improve the products and services we offer to you (the “Relevant Purpose”).`,

    marketingConsent: `Yes, I want to be the first to know! Send me news about exclusive deals, benefits and other promotions from the CAG Group and its business partners by text message.`,

    crRegistrationAgreement: `By submitting this form,
<br><br>• I agree to the Changi Transit Privileges |crTncUrl|, CAG's  |cagPpUrl| and consent to the creation of my Changi Account.
<br><br>• I have received a total of |numberVoucherIssuanced| CTP generic eVoucher(s)|lpcMessage|.`,

    ctpIndividualCollectionAgreement: `By submitting this form,
<br><br>• I agree to the Changi Transit Privileges |ctpTncUrl|, CAG's |cagPpUrl|
<br><br>• I have received a total of |numberVoucherIssuanced| CTP Voucher(s)|lpcMessage|.`,

    ctpFamilyCollectionAgreementMessage: `By clicking “Submit”:
<br><br>• I agree to CAG's |ctpTncUrl| and |cagPpUrl|.
<br><br>• I declare that I have obtained the consent of each individual in my group/family to provide his/her personal data and flight details to CAG for the Relevant Purpose.
<br><br>• I declare that I am authorized to collect the transit rewards on behalf of my group/family.
<br><br>• I have received of a total of |numberVoucherIssuanced| CTP generic Voucher(s)|lpcMessage|.`,

    ctpFamilyWithCrtCollectionAgreementMessage: `By clicking “Submit”:
<br><br>• I agree to CAG's |crTncUrl| and |cagPpUrl| and consent to the creation of my Changi Account.
<br><br>• I declare that I have obtained the consent of each individual in my group/family to provide his/her personal data and flight details to CAG for the Relevant Purpose.
<br><br>• I declare that I am authorized to collect the transit rewards on behalf of my group/family.
<br><br>• I have received of a total of |numberVoucherIssuanced| CTP generic Voucher(s)|lpcMessage|.`,

    ctpGroupCollectionAgreementMessage: `By submitting this form,
<br><br>• I agree to the Changi Transit Privileges |ctpTncUrl|, CAG's |cagPpUrl|
<br><br>• I declare that I have obtained the consent of each individual in my group to provide his/her personal data and flight details to CAG for the Relevant Purpose.
<br><br>• I declare that I am authorized to collect the transit rewards on behalf of my group/family.
<br><br>• I have received a total of |numberVoucherIssuanced| CTP generic Voucher(s)|lpcMessage| and i will distribute the transit rewards to the individual(s) stated in the table.`,

    numberVoucherIssuanced: `|noOfCards| x S|ctpVoucherCurrency||ctpVoucherValue|`
};


export const groupAdditionalGroupCrAgreement = ({message, urlData}) => {
    let formattedMessage = message;
    formattedMessage = injectUrlLinksIntoMessage({agreementMessage: formattedMessage, urlData: urlData});

    return formattedMessage;
}

export const generateCollectionAgreementMessage = ({agreementMessage, voucherDataCollected, templateData, urlData}) => {
    let formattedMessage = agreementMessage;
    formattedMessage = injectUrlLinksIntoMessage({agreementMessage: formattedMessage, urlData: urlData});
    let numberVoucherIssuancedMessage = ''
    let lpcSubMessage = ''
    // inject lpc number of voucher
    if (voucherDataCollected.totalCtpLpcValue && voucherDataCollected.totalCtpLpcValue > 0) {
        numberVoucherIssuancedMessage = genNumberVoucherIssuanced({
            agreementMessage: templateData.numberVoucherIssuanced,
            totalCtpValue: voucherDataCollected.totalCtpLpcValue,
            ctpVoucherValue: voucherData.ctpLpcVoucherValue
        });
    }

    if (numberVoucherIssuancedMessage.length > 0) {
        // inject number of vouchre into lpc sub message
        lpcSubMessage = templateData.lpcSubMessage
        lpcSubMessage = injectNumberOfVoucher({
            agreementMessage: lpcSubMessage,
            numberVoucherIssuanced: numberVoucherIssuancedMessage
        });
    }

    // inject lpc sub message into fomatted message
    formattedMessage = injectLcpMessage({
        agreementMessage: formattedMessage,
        lpcSubMessage: lpcSubMessage
    });

    // inject gen number of voucher
    // reset numberVoucherIssuancedMessage
    numberVoucherIssuancedMessage = ''
    if (voucherDataCollected.totalCtpGenValue && voucherDataCollected.totalCtpGenValue > 0) {
        numberVoucherIssuancedMessage = genNumberVoucherIssuanced({
            agreementMessage: templateData.numberVoucherIssuanced,
            totalCtpValue: voucherDataCollected.totalCtpGenValue,
            ctpVoucherValue: voucherData.ctpGenVoucherValue
        });
    }

    // inject number of vouchre into fomatted message
    formattedMessage = injectNumberOfVoucher({
        agreementMessage: formattedMessage,
        numberVoucherIssuanced: numberVoucherIssuancedMessage
    });

    // inject currency
    formattedMessage = formattedMessage.replace(/\|ctpVoucherCurrency\|/gi, voucherCurrency)

    return formattedMessage;
}

const injectNumberOfVoucher = ({agreementMessage, numberVoucherIssuanced }) => {
    let formattedAgreementMessage = agreementMessage;

    formattedAgreementMessage = formattedAgreementMessage.replace(/\|numberVoucherIssuanced\|/gi, numberVoucherIssuanced)

    return formattedAgreementMessage;
}

const genNumberVoucherIssuanced = ({agreementMessage, totalCtpValue, ctpVoucherValue}) => {
    let numberVoucherIssuancedMessage = agreementMessage

    numberVoucherIssuancedMessage = injectValue({
        agreementMessage: numberVoucherIssuancedMessage,
        totalCtpValue: totalCtpValue,
        ctpVoucherValue: ctpVoucherValue
    });

    numberVoucherIssuancedMessage = injectNoOfCard({
        agreementMessage: numberVoucherIssuancedMessage,
        totalCtpValue: totalCtpValue,
        ctpVoucherValue: ctpVoucherValue
    });

    return numberVoucherIssuancedMessage
}

const injectNoOfCard = ({agreementMessage, totalCtpValue, ctpVoucherValue }) => {
    let formattedAgreementMessage = agreementMessage;

    if(totalCtpValue && totalCtpValue > 0){
        formattedAgreementMessage = formattedAgreementMessage.replace(/\|noOfCards\|/gi, totalCtpValue/ctpVoucherValue)
    } else {
        formattedAgreementMessage = formattedAgreementMessage.replace(/\|noOfCards\|/gi, 0)
    }

    return formattedAgreementMessage;
}

const injectValue = ({agreementMessage, totalCtpValue, ctpVoucherValue}) => {

    let formattedAgreementMessage = agreementMessage;

    if(totalCtpValue && totalCtpValue > 0){
        formattedAgreementMessage = formattedAgreementMessage.replace(/\|ctpVoucherValue\|/gi, ctpVoucherValue)
    } else {
        formattedAgreementMessage = formattedAgreementMessage.replace(/\|ctpVoucherValue\|/gi, 0)
    }

    return formattedAgreementMessage;
}

const injectLcpMessage = ({agreementMessage, lpcSubMessage}) => {
    let formattedAgreementMessage = agreementMessage;

    formattedAgreementMessage = formattedAgreementMessage.replace(/\|lpcMessage\|/gi, lpcSubMessage)

    return formattedAgreementMessage;
}


const injectUrlLinksIntoMessage = ({agreementMessage, urlData}) => {
    let formattedAgreementMessage = agreementMessage;
    formattedAgreementMessage = formattedAgreementMessage.replace(/\|ctpTncUrl\|/gi, urlLinkTemplate(urlData.ctpTncUrl, "Terms and Conditions"))
    formattedAgreementMessage = formattedAgreementMessage.replace(/\|crTncUrl\|/gi, urlLinkTemplate(urlData.crTncUrl, "Terms and Conditions"))
    formattedAgreementMessage = formattedAgreementMessage.replace(/\|crtTncUrl\|/gi, urlLinkTemplate(urlData.crtTncUrl, "Terms and Conditions"))
    formattedAgreementMessage = formattedAgreementMessage.replace(/\|cagPpUrl\|/gi, urlLinkTemplate(urlData.cagPpUrl, "Privacy Policy"))
    formattedAgreementMessage = formattedAgreementMessage.replace(/\|crPpUrl\|/gi, urlLinkTemplate(urlData.crPpUrl, "Privacy Policy"))

    return formattedAgreementMessage;
}

const urlLinkTemplate = (url, displayMessage) => {
    let template = `
        <a
            href="${url}"
            rel="noopener noreferrer"
            target="_blank">${displayMessage}</a>
    `
    return template
}