
export const selectExistingCr = () => {
    let options = {
        isExistingCr: true,
        isExistingCrCard: true,
        isExistingCrPhysicalVoucher: false,
        isExistingCrPreloadedVouchersCard: false,
        isNewCollector: false,
        isRegisterCr: false,
        isSingleCrCard: false,
        isCtp: false,
        isSingleCtpCard: false,
        isMultipleCtpCard: false,
        isCtpPhysicalVoucher: false,
        isCtpDigitalCard: false,
        isCtpPreloadedVouchersCard: false,
    }
    return options;
}


export const selectExistingCrPhysicalVoucher = (checked) => {
    let options = {
        isExistingCr: true,
        isExistingCrCard: !checked,
        isExistingCrPhysicalVoucher: checked,
        isExistingCrPreloadedVouchersCard: false,
        isNewCollector: false,
        isRegisterCr: false,
        isSingleCrCard: false,
        isCtp: false,
        isSingleCtpCard: false,
        isMultipleCtpCard: false,
        isCtpPhysicalVoucher: false,
        isCtpDigitalCard: false,
        isCtpPreloadedVouchersCard: false,
    }
    return options;
}

export const selectExistingCrPreloadedVouchersCard = () => {
    let options = {
        isExistingCr: true,    //
        isExistingCrCard: false,
        isExistingCrPhysicalVoucher: false,
        isExistingCrPreloadedVouchersCard: true,
        isNewCollector: false,
        isRegisterCr: false,
        isSingleCrCard: false,
        isCtp: false,
        isSingleCtpCard: false,
        isMultipleCtpCard: false,
        isCtpPhysicalVoucher: false,
        isCtpDigitalCard: false,
        isCtpPreloadedVouchersCard: false,
    }
    return options;
}

export const selectNewCollector = (pax) => {
    if (pax=== undefined) throw new Error("Please pass in pax to function")

    let isSingleCard = pax <= 1
    let options = {
        isExistingCr: false,
        isExistingCrCard: false,
        isExistingCrPhysicalVoucher: false,
        isExistingCrPreloadedVouchersCard: false,
        isNewCollector: true,
        isRegisterCr: false,
        isSingleCrCard: false,
        isCtp: true,
        isSingleCtpCard: isSingleCard,
        isMultipleCtpCard: !isSingleCard,
        isCtpPhysicalVoucher: false,
        isCtpDigitalCard: false,
        isCtpPreloadedVouchersCard: false,
    }
    return options;
};


export const selectRegisterCr = (country) => {
    if(country === undefined) throw new Error("Please pass in country")
    let options = {
        isExistingCr: false,
        isExistingCrCard: false,
        isExistingCrPhysicalVoucher: false,
        isExistingCrPreloadedVouchersCard: false,
        isNewCollector: true,
        isRegisterCr: true,
        isSingleCrCard: true,
        isCtp: false,
        isSingleCtpCard: false,
        isMultipleCtpCard: false,
        isCtpPhysicalVoucher: false,
        isCtpDigitalCard: false,
        isCtpPreloadedVouchersCard: false,
    }
    return options;
}

export const selectIssueCtp = (pax) => {
    if (pax=== undefined) throw new Error("Please pass in pax to function")

    let isSingleCard = pax <= 1
    let options = {
        isExistingCr: false,
        isExistingCrCard: false,
        isExistingCrPhysicalVoucher: false,
        isExistingCrPreloadedVouchersCard: false,
        isNewCollector: true,
        isRegisterCr: false,
        isSingleCrCard: false,
        isCtp: true,
        isSingleCtpCard: isSingleCard,
        isMultipleCtpCard: !isSingleCard,
        isCtpPhysicalVoucher: false,
        isCtpDigitalCard: false,
        isCtpPreloadedVouchersCard: false,
    }
    return options;
}

export const selectCtpSingleCard = (pax) => {
    if (pax=== undefined) throw new Error("Please pass in pax to function")
    let isSingleCard = pax <= 1
    let options = {
        isExistingCr: false,
        isExistingCrCard: false,
        isExistingCrPhysicalVoucher: false,
        isExistingCrPreloadedVouchersCard: false,
        isNewCollector: true,
        isRegisterCr: false,
        isSingleCrCard: false,
        isCtp: true,
        isSingleCtpCard: isSingleCard,
        isMultipleCtpCard: !isSingleCard,
        isCtpPhysicalVoucher: false,
        isCtpDigitalCard: false,
        isCtpPreloadedVouchersCard: false,
    }
    return options;
}

export const selectCtpMultipleCards = () => {
    let options = {
        isExistingCr: false,
        isExistingCrCard: false,
        isExistingCrPhysicalVoucher: false,
        isExistingCrPreloadedVouchersCard: false,
        isNewCollector: true,
        isRegisterCr: false,
        isSingleCrCard: false,
        isCtp: true,
        isSingleCtpCard: false,
        isMultipleCtpCard: true,
        isCtpPhysicalVoucher: false,
        isCtpDigitalCard: false,
        isCtpPreloadedVouchersCard: false,
    }
    return options;
}

export const selectCtpPhysicalVoucher = () => {
    let options = {
        isExistingCr: false,
        isExistingCrCard: false,
        isExistingCrPhysicalVoucher: false,
        isExistingCrPreloadedVouchersCard: false,
        isNewCollector: true,
        isRegisterCr: false,
        isSingleCrCard: false,
        isCtp: true,
        isSingleCtpCard: false,
        isMultipleCtpCard: false,
        isCtpPhysicalVoucher: true,
        isCtpDigitalCard: false,
        isCtpPreloadedVouchersCard: false,
    }
    return options;
}

export const selectCtpDigitalCard = () => {
    let options = {
        isExistingCr: false,
        isExistingCrCard: false,
        isExistingCrPhysicalVoucher: false,
        isExistingCrPreloadedVouchersCard: false,
        isNewCollector: true,
        isRegisterCr: false,
        isSingleCrCard: false,
        isCtp: true,
        isSingleCtpCard: false,
        isMultipleCtpCard: false,
        isCtpPhysicalVoucher: false,
        isCtpDigitalCard: true,
        isCtpPreloadedVouchersCard: false,
    }
    return options;
}

export const selectCtpPreloadedVouchersCard = () => {
    let options = {
        isExistingCr: false,
        isExistingCrCard: false,
        isExistingCrPhysicalVoucher: false,
        isExistingCrPreloadedVouchersCard: false,
        isNewCollector: true,
        isRegisterCr: false,
        isSingleCrCard: false,
        isCtp: true,
        isSingleCtpCard: false,
        isMultipleCtpCard: false,
        isCtpPhysicalVoucher: false,
        isCtpDigitalCard: false,
        isCtpPreloadedVouchersCard: true,
    }
    return options;
}

