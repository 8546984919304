export const convertToExcelExportFormat = ({headers, records }) =>{
    let formattedExcelData = [];
    let formattedHeaders = [];
    headers.forEach(header => {
        formattedHeaders.push(header.label);
    });
    formattedExcelData.push(formattedHeaders);

    records.forEach(record => {
        let formattedRow = [];
        headers.forEach(header=>{
            formattedRow.push(record[header.key])
        })
        formattedExcelData.push(formattedRow);
    })

    return formattedExcelData;
}