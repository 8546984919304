import axios from 'axios'
import { getSettings } from '../config/config'
import { getCurrentAuthHeader } from '../helpers/utilities'
import { checkValidStatusCode } from '../helpers/httpHelper'

let settings = getSettings();
let baseURL = settings.baseURL;

const GET_FLIGHTS_IS_LOADING = 'GET_FLIGHTS_IS_LOADING'
const GET_FLIGHTS_SUCCESS = 'GET_FLIGHTS_SUCCESS'
const GET_FLIGHTS_FAIL = 'GET_FLIGHTS_FAIL'
const UPDATE_FLIGHTS = 'UPDATE_FLIGHTS'

export const FLIGHT_NO_KEY = 'flightNo'
export const DEPARTURE_CITY_NAME_KEY = 'departureCityName'
export const ARRIVAL_CITY_NAME_KEY = 'arrivalCityName'

export const ARRIVAL = 'ARRIVAL'
export const DEPARTURE = 'DEPARTURE'

export const getFlightsData = () => async dispatch => {
	dispatch(_getFlightsIsLoading())
	const rs = await axios.get(baseURL + '/Flight/GetFlights', getCurrentAuthHeader()).catch(e => {
		if (!checkValidStatusCode(e.response.status)) {
			const {
				response: {
					data: {
						error: { message }
					}
				}
			} = e
			return { data: { success: false, error: { message } } }
		}
		return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
	})
	if (!rs.data) {
		dispatch(_getFlightsFail())
		return { success: false, message: 'No data from server' }
	}
	if (rs.data.success) {
		dispatch(
			_getFlightsSuccess(
				rs.data.result.items.map((m, i) => {
					m.id = i
					return m
				})
			)
		)
		return { success: true, message: '' }
	} else {
		dispatch(_getFlightsFail())
		return { success: false, message: 'Flight data: ' + rs.data.error.message }
	}
}

const _getFlightsIsLoading = () => ({
	type: GET_FLIGHTS_IS_LOADING
})

const _getFlightsSuccess = flightData => ({
	type: GET_FLIGHTS_SUCCESS,
	flightData: flightData
})

const _getFlightsFail = errorDetails => ({
	type: GET_FLIGHTS_FAIL,
	errorDetails
})

export const updateFlights = flightData => ({
	type: UPDATE_FLIGHTS,
	flightData
})

const initState = {
	getFlightsIsLoading: false,
	flightData: [],
	arrivalSearchOptions: [],
	arrivalFlightNoList: [],
	departureSearchOptions: [],
	departureFlightNoList: [],
	departureCityList: [],
	arrivalCityList: [],
	isFlightPopulated: false
}

const flightDuck = (state = initState, action) => {
	switch (action.type) {
		case GET_FLIGHTS_IS_LOADING:
			return { ...state, getFlightsIsLoading: true }

		case GET_FLIGHTS_SUCCESS:
			return {
				...state,
				getFlightsIsLoading: false,
				flightData: action.flightData,
				arrivalSearchOptions: _mapSearchOptions(action.flightData, ARRIVAL),
				departureSearchOptions: _mapSearchOptions(action.flightData, DEPARTURE),
				arrivalFlightNoList: _formatDataToOptionList(action.flightData, 'flightNo', ARRIVAL),
				departureFlightNoList: _formatDataToOptionList(action.flightData, 'flightNo', DEPARTURE),
				departureCityList: _formatDataToOptionList(action.flightData, 'arrivalCityName', DEPARTURE),
				arrivalCityList: _formatDataToOptionList(action.flightData, 'departureCityName', ARRIVAL),
				isFlightPopulated: true
			}

		case GET_FLIGHTS_FAIL:
			return { ...state, getFlightsIsLoading: false }

		case UPDATE_FLIGHTS:
			return {
				...state,
				flightData: action.flightData,
				arrivalSearchOptions: _mapSearchOptions(action.flightData, ARRIVAL),
				departureSearchOptions: _mapSearchOptions(action.flightData, DEPARTURE),
				arrivalFlightNoList: _formatDataToOptionList(action.flightData, 'flightNo', ARRIVAL),
				departureFlightNoList: _formatDataToOptionList(action.flightData, 'flightNo', DEPARTURE),
				departureCityList: _formatDataToOptionList(action.flightData, 'arrivalCityName', DEPARTURE),
				arrivalCityList: _formatDataToOptionList(action.flightData, 'departureCityName', ARRIVAL),
				isFlightPopulated: true
			}

		default:
			return state
	}
}

const _mapSearchOptions = (flightData, type) => {
	const sectionList = flightData.filter(m => (type === ARRIVAL ? m['arrivalCityName'] === 'Singapore' : m['departureCityName'] === 'Singapore'))
	let set = new Set(sectionList.map(m => __formatSearchOption(m, type)))

	let result = []
	// add default empty value
	result.push({
		label: 'Select...',
		value: ''
	})
	// add flight data
	let temp = [...set].sort().map(m => {
		return { label: m, value: m }
	})
	result = result.concat(temp)
	// return data
	return result
}

const __formatSearchOption = (item, type) => {
	return type === ARRIVAL
		? `${item.flightNo} - ${item.departureCityName} - ${item.departurePortCode}`
		: `${item.flightNo} - ${item.arrivalCityName} - ${item.arrivalPortCode}`
}

const _formatDataToOptionList = (flightData, key, type) => {
	let list = []
	let singaporeKey
	if (type === ARRIVAL) {
		singaporeKey = 'arrivalCityName'
	} else {
		singaporeKey = 'departureCityName'
	}

	for (let i = 0; i < flightData.length; i++) {
		if (flightData[i][singaporeKey] === 'Singapore') {
			let matchedFlight = list.find(item => item.value === flightData[i][key])
			if (!matchedFlight) list.push({ label: flightData[i][key], value: flightData[i][key] })
		}
	}
	list.sort((a, b) => {
		if (a.value < b.value) {
			return -1
		} else {
			return 1
		}
	})

	return list
}

export default flightDuck
