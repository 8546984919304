import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { pink } from '@material-ui/core/colors'

class SqApiCriteriasDialog extends Component {
	_renderCriterias = () => {
		const { criterias, criteriasMessage } = this.props

		if (criteriasMessage !== '') {
			return <Grid item>{criteriasMessage}</Grid>
		}

		const criteriasObj = [
			{ isFlightEligible: 'Is pax flying on eligible flights?' },
			{ haveTwoTouchPoint: 'Is pax on transit 2 times?' },
			{ isWithinCampaign: 'Is pax travelling within campaign period?' },
			{ isThreeSector: 'Is trip a 3-sector with land/sea transfers?' },
			{ isSameOrgDest: 'For 3-sector, did pax return to the same origin country?' },
			{ isValidTransit: 'Is at least one transit within 24 hours?' },
			{ isPaxSeated: 'Is pax above 2 years old?' },
			{ isValidOrigin: 'Did pax start their trip from agreed point of origin?' },
			{ isFlightConfirmed: 'Is upcoming flight sectors confirmed?' },
			{ isUsedOrFlownFlight: 'Has previous flight flown?' },
			{ isFromSpecialBoardPointCountry: 'Is from special board point country?' }
		]

		const elements = criteriasObj.map((k, i) => {
			const { classes } = this.props
			let k2 = Object.keys(k)[0]
			const className = criterias[k2] === 'No' ? classes.no : ''
			return (
				<TableRow hover key={k2} padding={'dense'} className={className}>
					<TableCell>{criteriasObj[i][k2]}</TableCell>
					<TableCell>{criterias[k2]}</TableCell>
				</TableRow>
			)
		})

		return (
			<Table>
				<TableBody>{elements}</TableBody>
			</Table>
		)
	}

	render() {
		return (
			<div>
				<Dialog open={this.props.viewCriterias} scroll={'body'} onClose={this.props.onViewCriteriasClose}>
					<DialogTitle>Airline Check Results</DialogTitle>
					<DialogContent>{this._renderCriterias()}</DialogContent>
					<DialogActions>
						<Button color={'default'} variant='text' onClick={this.props.onViewCriteriasClose}>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}

SqApiCriteriasDialog.propTypes = {
	criterias: PropTypes.shape({
		isFlightEligible: PropTypes.string,
		haveTwoTouchPoint: PropTypes.string,
		isWithinCampaign: PropTypes.string,
		isThreeSector: PropTypes.string,
		isFourSector: PropTypes.string,
		isSameOrgDest: PropTypes.string,
		isValidTransit: PropTypes.string,
		isPaxSeated: PropTypes.string,
		isValidOrigin: PropTypes.string,
		isFlightConfirmed: PropTypes.string,
		isUsedOrFlownFlight: PropTypes.string
	}),
	criteriasMessage: PropTypes.string, // message to show when criteris is {}
	viewCriterias: PropTypes.bool, // determines is dialog shows
	onViewCritierasClose: PropTypes.func // usually function to set viewCriterias to false
}

const styles = theme => ({
	no: { backgroundColor: pink[50] }
})

export default withStyles(styles)(SqApiCriteriasDialog)
