import React, {Component} from 'react'
import {withStyles, Grid, TextField, Typography, Button} from '@material-ui/core'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import Header from '../containers/Header.js'
import StyledSection from '../components/StyledSection'

class ResetPasswordScreen extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            currentPassword: '',
            currentPasswordError: '',
            newPassword: '',
            newPasswordError: '',
            confirmPassword: '',
            confirmPasswordError: ''
        }
    }

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Reset Password`
    }

    onSubmit = () => {
        if (this._validate()) {

        }
    }

    _validate() {

    }

    onFieldChange = (k, e) => {

    }

    render() {
        const {classes} = this.props
        const {currentPassword, currentPasswordError, newPassword, newPasswordError, confirmPassword, confirmPasswordError} = this.state

        return (
            <div>
                <GlobalTimeChecker/>

                <Header history={this.props.history}/>

                <div className={classes.container}>
                    <StyledSection title={'Reset password'}>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={2}>
                                    <Typography variant={'body1'}>Current password</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField id="currentPassword" label="" margin="normal" variant="outlined"
                                        value={currentPassword}
                                        error={!!currentPasswordError}
                                        helperText={currentPasswordError}
                                        onChange={e => this.onFieldChange('currentPassword', e)}/>
                                </Grid>
                            </Grid>

                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={2}>
                                    <Typography variant={'body1'}>New password</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField id="newPassword" label="" margin="normal" variant="outlined"
                                        value={newPassword}
                                        error={!!newPasswordError}
                                        helperText={newPasswordError}
                                        onChange={e => this.onFieldChange('newPassword', e)}/>
                                </Grid>
                            </Grid>

                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={2}>
                                    <Typography variant={'body1'}>Confirm new password</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField id="confirmPassword" label="" margin="normal" variant="outlined"
                                        value={confirmPassword}
                                        error={!!confirmPasswordError}
                                        helperText={confirmPasswordError}
                                        onChange={e => this.onFieldChange('confirmPassword', e)}/>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Button color={'primary'} variant="contained" onClick={this.onSubmit}>Submit</Button>
                            </Grid>

                        </Grid>
                    </StyledSection>
                </div>
            </div>
        )
    }
}

const styles = themes => ({
    container: {...themes.container}
})

export default withStyles(styles)(ResetPasswordScreen)