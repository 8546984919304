import React from 'react'
import {Redirect} from 'react-router-dom'
import {Grid} from '@material-ui/core'
import Header from '../containers/Header.js'
import GroupHeader from '../containers/group/GroupHeader.js'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import {hasVoucherTransferPermission} from '../helpers/utilities'

/**
 * This page exists for routing purpose
 */
class Card extends React.Component {

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Card`
    }

    render() {
        return (
            <div>
                <Grid container spacing={24}>
                    <GlobalTimeChecker/>

                    <Header history={this.props.history}/>
                    {hasVoucherTransferPermission() && <Redirect to={'/card/card-replacement'}/>}
                </Grid>
            </div>
        )
    }
}

export default Card