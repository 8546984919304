import React from 'react'
import SectionHeader from '../components/SectionHeader'
import { HubConnectionBuilder } from '@aspnet/signalr'
import { signalRURL } from '../config/config'
import CollectorPersonalDetails from '../containers/individual/CollectorPersonalDetails'
import {
    updateCollectorDetails,
    resetIndividualFormReducer,
    updateFormDetailsList,
    resetCollectorDetails,
    updateCrDetails
} from '../ducks/individualFormDuck'
import { updateAuthDuck } from '../ducks/authDuck'
import connect from 'react-redux/es/connect/connect'
import { withStyles, Button, Grid } from '@material-ui/core'
import signalRConstants from '../helpers/signalRConstants.js'
import GeneralSignature from '../components/generalForm/GeneralSignature'
import StyledSnackbar from '../components/StyledSnackbar'
import { updateGroupRedemptionFormData, updateGroupCollectorDetails } from '../ducks/groupRedemptionFormDuck'
import GroupCollectorForm from '../containers/group/GroupCollectorForm'
import StyledSection from '../components/StyledSection'
import { getAllBanner } from '../ducks/bannerDuck'
import classNames from 'classnames'
import anime from 'animejs'
import CollectorSuccessDisplay from '../containers/individual/CollectorSuccessDisplay'
import GroupCollectorSuccessDisplay from '../containers/group/GroupCollectorSuccessDisplay'
import PassengersVoucherTable from "../components/PassengersVoucherTable";
import {updateIndividualCollectorOptions} from "../ducks/individualCollectorOptionDuck";
import {getSettings} from "../config/config.js"

const BANNER_SCREEN = 'BANNER_SCREEN'
const INDIVIDUAL_COLLECTOR_SCREEN = 'INDIVIDUAL_COLLECTOR_SCREEN'
const GROUP_COLLECTOR_SCREEN = 'GROUP_COLLECTOR_SCREEN'
const INDIVIDUAL_COLLECTOR_SUCCESS = 'INDIVIDUAL_COLLECTOR_SUCCESS'
const GROUP_COLLECTOR_SUCCESS = 'GROUP_COLLECTOR_SUCCESS'

let settings = getSettings()
let ocidExchangeLink = settings.ocidLink + "/oauth/authorize?redirect_uri=https://ctpuat.changiairport.com/ocid-exchange&response_type=code&client_id=" + settings.ocidClientId + "&state=ctp_app"

class IndividualCollector extends React.Component {
	constructor(props) {
		super(props)
		this.hubConnect = null
		this.state = {
			formState: {},
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
			parentDidSubmit: false,
			parentDidDone: false,
			activeScreen: 'BANNER_SCREEN',
			sortedBanners: [],
            overrideExistingCrOptions: false,
			submitType: ''
		}

		this.collectorPersonalDetails = null
		this.bannerTimeline = null
		this.homeScreenTimer = null
	}

	componentDidMount = async () => {
		const user = localStorage.getItem('username')
		if (!user) {
			this._promptLogin()
			return
		}
		this.hubConnection = new HubConnectionBuilder().withUrl(signalRURL).build()
		this._startSignalRConnection()
		this.hubConnection.on('ReceiveMessage', this._receiveMessage)
		this.hubConnection.onclose(async () => {
			await this._startSignalRConnection()
		})

		const rs = await this.props.getAllBanner()
		if (!rs.success) this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			bannerDuck: { banners, getAllBannerIsLoading }
		} = this.props
		if (prevProps.bannerDuck.getAllBannerIsLoading === true && getAllBannerIsLoading === false) {
			const sortedBanners = banners.filter(b => !b.isDeleted)
			sortedBanners.sort((b1, b2) => b1.sortPriority - b2.sortPriority)
			this.setState({ sortedBanners })
		}
	}

	_startSignalRConnection = async () => {
		this.hubConnection
			.start()
			.then(() => console.log('Connection started!'))
			.catch(err => {
				setTimeout(() => this._startSignalRConnection(), 5000)
				console.log('Error while establishing connection :(')
			})
	}

	_sendMessage = (data, messageType = signalRConstants.UPDATE_COLLECTOR_FORM) => {
		const user = localStorage.getItem('username')
		let messageObj = {
			user, // check the current user
			type: messageType,
			source: signalRConstants.USER_CLIENT,
			data
		}
		let messageStr = JSON.stringify(messageObj)
		this.hubConnection.invoke('SendMessage', 'default', messageStr).catch(function(err) {
			return console.error(err.toString())
		})
	}

	_refreshPage = () => {
		window.location = '/client-view'
	}

	_receiveMessage = (user, messageStr) => {
		let messageObj = JSON.parse(messageStr)

		if (messageObj.type === signalRConstants.REFRESH_CLIENT) {
			//window.location = window.location.origin
			console.log('logout request')
			window.location = '/client-view'
			return
		}

		if (messageObj.source === signalRConstants.USER_CLIENT) return

		const currentUser = localStorage.getItem('username')
		if (messageObj.user !== currentUser) {
			console.log('not same user')
			return
		}

		clearTimeout(this.homeScreenTimer)

		if (messageObj.type === signalRConstants.UPDATE_COLLECTOR_FORM) {
			if (messageObj.data) {
				const { collectorDetails, formState, formDetailsList, collectorOptions } = messageObj.data
                if(collectorDetails) this.props.updateCollectorDetails(collectorDetails)
                if(collectorOptions) this.props.updateIndividualCollectorOptions(collectorOptions)
				if (formState) this.setState({ formState })
				if (formDetailsList) this.props.updateFormDetailsList(formDetailsList)
			}
		} else if (messageObj.type === signalRConstants.DISPLAY_COLLECTOR_FORM) {
			this.setState({ activeScreen: INDIVIDUAL_COLLECTOR_SCREEN, parentDidDone: false, parentDidSubmit: false })
			const { collectorDetails, formState, formDetailsList } = messageObj.data
			this.props.updateCollectorDetails(collectorDetails)
			if (formState) this.setState({ formState })
			if (formDetailsList) this.props.updateFormDetailsList(formDetailsList)
		} else if (messageObj.type === signalRConstants.DISPLAY_GROUP_COLLECTOR_FORM) {
			this.setState({ activeScreen: GROUP_COLLECTOR_SCREEN })
			const { group, tourLeader, passengers, collector, signatureUri, submitType } = messageObj.data
			this.setState({ submitType })
			this.props.updateGroupRedemptionFormData({ group, tourLeader, passengers, collector, signatureUri })
		} else if (messageObj.type === signalRConstants.DISPLAY_INDIVIDUAL_COLLECTOR_SUCCESS) {
			const { collectorDetails, formDetailsList } = messageObj.data
			this.props.updateCollectorDetails(collectorDetails)
			this.props.updateFormDetailsList(formDetailsList)
			this.setState({ activeScreen: INDIVIDUAL_COLLECTOR_SUCCESS })
			this.homeScreenTimer = setTimeout(() => this.setState({ activeScreen: BANNER_SCREEN }), 20000)
		} else if (messageObj.type === signalRConstants.DISPLAY_GROUP_COLLECTOR_SUCCESS) {
			const { group, tourLeader, passengers, collector, signatureUri } = messageObj.data
			this.props.updateGroupRedemptionFormData({ group, tourLeader, passengers, collector, signatureUri })
			this.setState({ activeScreen: GROUP_COLLECTOR_SUCCESS })
			this.homeScreenTimer = setTimeout(() => this.setState({ activeScreen: BANNER_SCREEN }), 20000)
		} else if (messageObj.type === signalRConstants.EXIT_INDIVIDUAL_COLLECTOR_SUCCESS) {
			this.setState({ activeScreen: BANNER_SCREEN })
		} else if(messageObj.type === signalRConstants.UPDATE_CR_DETAILS_FROM_OCID){
            const { crDetails } = messageObj.data
            this.props.updateCrDetails(crDetails)
            this.setState({overrideExistingCrOptions:true})

            this.ocidWindow.close()
		} else if(messageObj.type === signalRConstants.OPEN_OCID_WINDOW){
        	if(this.state.activeScreen === BANNER_SCREEN ) return;
            this.ocidWindow = window.open("https://logindev.cag.wwprojects.com/oauth/authorize?redirect_uri=https://ctpuat.changiairport.com/ocid-exchange&response_type=code&client_id=Iu5FSmVXhwJ1PwUQOGfPns3Se6q2gpS1M2y5TiWc&state=ctp_app"); //todo:minh, need to put as environment variable
		} else if(messageObj.type === signalRConstants.CLOSE_OCID_WINDOW){
            this.ocidWindow.close()
        }
	}

	onDone = () => {
		this.setState({ parentDidDone: true })
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	childIsValidated = formState => {
		const {
			individualFormDuck: { collectorDetails }
		} = this.props
		// after child is validated validate signature
		if (collectorDetails.clientSignatureBase64 === '') {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: 'Signature is required to continue'
			})
			return false
		}

		// hide form and send signalR client done
		this._sendMessage({ collectorDetails, formState }, signalRConstants.CLIENT_DONE)
		this.setState({ parentDidDone: false, activeScreen: BANNER_SCREEN })
		this.props.resetIndividualFormReducer()
	}

	resetParentSubmit = () => {
		this.setState({ parentDidSubmit: false })
	}

	resetParentDone = formState => {
		const {
			individualFormDuck: { collectorDetails }
		} = this.props
		this._sendMessage({ collectorDetails, formState })
		this.setState({ parentDidDone: false })
	}

	// used by INDIVIDUAL_COLLECTOR_SCREEN
	onSignatureEnd = clientSignatureBase64 => {
		const details = { ...this.props.individualFormDuck.collectorDetails }
		const collectorDetails = { ...details, clientSignatureBase64 }
		this.props.updateCollectorDetails(collectorDetails)
		this._sendMessage({ collectorDetails })
	}

	// used by INDIVIDUAL_COLLECTOR_SCREEN
	onSignatureClear = () => {
		const details = { ...this.props.individualFormDuck.collectorDetails }
		const collectorDetails = { ...details, clientSignatureBase64: '' }
		this.props.updateCollectorDetails(collectorDetails)
		this._sendMessage({ collectorDetails })
	}

	// used by GROUP_COLLECTOR_SCREEN
	onGroupCollectorSignatureEnd = clientSignatureBase64 => {
		let {
			groupRedemptionFormDuck: { collector }
		} = this.props
		collector = { ...collector, clientSignatureBase64 }
		this.props.updateGroupCollectorDetails(collector)
		this._sendMessage({ collector }, signalRConstants.UPDATE_GROUP_COLLECTOR_FORM)
	}

	// used by GROUP_COLLECTOR_SCREEN
	onGroupCollectorSignatureClear = () => {
		let {
			groupRedemptionFormDuck: { collector }
		} = this.props
		collector = { ...collector, clientSignatureBase64: '' }
		this.props.updateGroupCollectorDetails(collector)
		this._sendMessage({ collector }, signalRConstants.UPDATE_GROUP_COLLECTOR_FORM)
	}

	onClear = async () => {
		this.props.resetCollectorDetails()
		const formState = await this.collectorPersonalDetails.onClear()
		this._sendMessage({
			collectorDetails: this.props.individualFormDuck.collectorDetails,
			formState
		})
	}

	onGroupUpdate = () => {
		const {
			groupRedemptionFormDuck: { collector }
		} = this.props
		this._sendMessage({ collector }, signalRConstants.UPDATE_GROUP_COLLECTOR_FORM)
	}

	onGroupDone = () => {
		let {
			groupRedemptionFormDuck: { collector }
		} = this.props
		if (!collector.clientSignatureBase64 && this.state.submitType === 'TOUR_LEADER') {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: 'Signature is required'
			})
			return
		}
		collector = { ...collector, clientIsDone: true }
		this.props.updateGroupCollectorDetails(collector)
		this._sendMessage({ collector }, signalRConstants.UPDATE_GROUP_COLLECTOR_FORM)
		this.setState({ activeScreen: BANNER_SCREEN })
	}

	onLogout = () => {
		localStorage.clear()
		this.props.updateAuthDuck('isLoggedIn', null)
		this._promptLogin()
		//window.location = window.location.origin + '/client-view'
	}

	_promptLogin = () => {
		this.setState({
			showSnackbar: true,
			snackbarVariant: 'error',
			snackbarMessage: 'Please login on main screen and refresh this page'
		})
	}


	render() {
		const { classes, individualFormDuck } = this.props
		const { sortedBanners } = this.state

		if (this.state.activeScreen === BANNER_SCREEN && sortedBanners.length > 0) {
			if (this.bannerTimeline === null) {
				this.bannerTimeline = anime.timeline({
					targets: '.banner.child',
					duration: 5000,
					easing: 'linear',
					direction: 'alternate',
					loop: sortedBanners.length > 1 ? true : false
				})
				setTimeout(() => {
					this.bannerTimeline.add({ delay: 5000 })
					this.bannerTimeline.add({
						opacity: [0, 1],
						scale: [0.95, 1],
						translateX: ['5rem', 0],
						delay: anime.stagger(10000)
					})
					this.bannerTimeline.add({ delay: 5000 })
				}, 500)
			}
		}

		return (
			<div>
				{this.state.activeScreen === INDIVIDUAL_COLLECTOR_SCREEN && (
					<div className={classes.sectionSingle}>
						<Grid container spacing={24} direction={'column'}>
							<Grid item>
								<SectionHeader columns={2} title='Collector Details' />
								<CollectorPersonalDetails
									collectorDetails={individualFormDuck.collectorDetails}
									sendMessage={this._sendMessage}
									formState={this.state.formState}
									parentDidSubmit={this.state.parentDidSubmit}
									parentDidDone={this.state.parentDidDone}
									childIsValidated={this.childIsValidated}
									resetParentSubmit={this.resetParentSubmit}
									resetParentDone={this.resetParentDone}
									parentView={'clientView'}
									innerRef={instance => (this.collectorPersonalDetails = instance)}
                                    overrideExistingCrOptions={this.state.overrideExistingCrOptions}
								/>
							</Grid>

							{individualFormDuck.formDetailsList.length > 0 && (
								<StyledSection title={'Passengers'}>
									<Grid container spacing={24} direction={'column'}>
										<Grid item>
                                            <PassengersVoucherTable
                                                collectorDetails = {individualFormDuck.collectorDetails}
                                                passengers={individualFormDuck.formDetailsList}
                                                onEditPax={this._onEditPax}
                                            />
										</Grid>
									</Grid>
								</StyledSection>
							)}
							<Grid container spacing={24} direction={'row'} alignItems='flex-end'>
								<Grid item>
									<GeneralSignature
										penColor={'black'}
										dimensions={{ width: 500, height: 200 }}
										onEnd={this.onSignatureEnd}
										onClear={this.onSignatureClear}
									/>
								</Grid>
								<Grid item>
									<Button color={'primary'} className={classes.doneBtn} variant='contained' size='large' onClick={this.onDone}>
										Done
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</div>
				)}

				{this.state.activeScreen === BANNER_SCREEN && sortedBanners.length > 0 && (
					<div className={classes.fullscreen}>
						{sortedBanners.map((b, i) => {
							let child = ''
							if (i !== 0) child = 'child'
							return <img key={b.id} src={b.presignedUri} alt='banner' className={classNames(classes.image, 'banner', child)} />
						})}
						<Button color={'primary'} variant='contained' onClick={this.onLogout} className={classes.logoutBtn}>
							Logout
						</Button>
					</div>
				)}

				{this.state.activeScreen === GROUP_COLLECTOR_SCREEN && (
					<div className={classes.sectionSingle}>
						<StyledSection title={'Group Collections'}>
							<Grid container spacing={24} direction={'column'}>
								<GroupCollectorForm onGroupUpdate={this.onGroupUpdate} />

								{this.state.submitType === 'TOUR_LEADER' && (
									<Grid item>
										<GeneralSignature
											penColor={'black'}
											dimensions={{ width: 500, height: 200 }}
											onEnd={this.onGroupCollectorSignatureEnd}
											onClear={this.onGroupCollectorSignatureClear}
										/>
									</Grid>
								)}
								<Grid container item spacing={24}>
									<Grid item>
										<Button color={'primary'} variant='contained' onClick={this.onGroupDone}>
											Done
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</StyledSection>
					</div>
				)}

				{this.state.activeScreen === GROUP_COLLECTOR_SUCCESS && (
					<div className={classes.sectionSingle}>
						<GroupCollectorSuccessDisplay toolbarButtons={this._toolbarButtons} />
					</div>
				)}

				{this.state.activeScreen === 'INDIVIDUAL_COLLECTOR_SUCCESS' && (
					<div className={classes.sectionSingle}>
						<CollectorSuccessDisplay toolbarButtons={this._toolbarButtons} />
					</div>
				)}

				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = theme => ({
	sectionSingle: { paddingLeft: 30, paddingRight: 30, paddingTop: 50 },
	doneBtn: { marginBottom: 50, height: 50 },
	logoutBtn: { position: 'absolute', top: '1rem', right: '1rem' },
	image: { objectFit: 'cover', height: '100vh', width: '100%', position: 'absolute' },
	fullscreen: { height: '100vh', width: '100%', background: 'black', overflow: 'hidden', position: 'absolute' }
})

const mapStateToProps = function(state) {
	const { individualFormDuck, groupRedemptionFormDuck, bannerDuck } = state
	return { individualFormDuck, groupRedemptionFormDuck, bannerDuck }
}

const mapDispatchToProps = function(dispatch) {
	return {
		updateCollectorDetails: details => dispatch(updateCollectorDetails(details)),
		resetIndividualFormReducer: details => dispatch(resetIndividualFormReducer(details)),
		updateFormDetailsList: details => dispatch(updateFormDetailsList(details)),
		resetCollectorDetails: () => dispatch(resetCollectorDetails()),
		updateGroupRedemptionFormData: ({ group, tourLeader, passengers, collector, signatureUri }) =>
			dispatch(
				updateGroupRedemptionFormData({
					group,
					tourLeader,
					passengers,
					collector,
					signatureUri
				})
			),
		updateAuthDuck: (k, v) => dispatch(updateAuthDuck(k, v)),
		updateGroupCollectorDetails: collector => dispatch(updateGroupCollectorDetails(collector)),
		getAllBanner: () => dispatch(getAllBanner()),
        updateIndividualCollectorOptions: (collectorOptions) => dispatch(updateIndividualCollectorOptions(collectorOptions)),
        updateCrDetails: (cardNo) => dispatch(updateCrDetails(cardNo))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IndividualCollector))
