import axios from 'axios'
import {getSettings} from '../config/config'
import {getCurrentAuthHeader} from '../helpers/utilities'
import {checkValidStatusCode} from "../helpers/httpHelper";

//<editor-fold desc="Constants">
const UPDATE_USER_ACCESS_ACCOUNT_DUCK = 'UPDATE_USER_ACCESS_ACCOUNT_DUCK'
const UPDATE_USER_ACESS_ACCOUNT_PERMISSIONS = 'UPDATE_USER_ACESS_ACCOUNT_PERMISSIONS'
const CREATE_ROLE_IS_LOADING = 'CREATE_ROLE_IS_LOADING'
const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS'
const CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL'
const UPDATE_ROLE_IS_LOADING = 'UPDATE_ROLE_IS_LOADING'
const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL'
const DELETE_ROLE_IS_LOADING = 'DELETE_ROLE_IS_LOADING'
const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL'
//</editor-fold>

let settings = getSettings();
let baseURL = settings.baseURL;

//<editor-fold desc="Actions">
export const createRole = fields => async (dispatch) => {
    dispatch(_createRoleIsLoading())

    const permissions = []
    for (let k in fields.permissions) {
        if (fields.permissions[k] === true) permissions.push(k)
    }

    const data = {name: fields.roleNameField, displayName: fields.roleNameField, permissions}
    const rs = await axios.post(baseURL + '/Role', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_createRoleSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_createRoleFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _createRoleIsLoading = () => ({
    type: CREATE_ROLE_IS_LOADING
})

const _createRoleSuccess = () => ({
    type: CREATE_ROLE_SUCCESS
})

const _createRoleFail = () => ({
    type: CREATE_ROLE_FAIL
})

export const updateRole = fields => async dispatch => {
    dispatch(_updateRoleIsLoading())

    const permissions = []
    for (let k in fields.permissions) {
        if (fields.permissions[k] === true) permissions.push(k)
    }
    const data = {...fields, permissions}
    const rs = await axios.put(baseURL + '/Role/Update', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_updateRoleSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_updateRoleFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _updateRoleIsLoading = () => ({
    type: UPDATE_ROLE_IS_LOADING
})

const _updateRoleSuccess = rs => ({
    type: UPDATE_ROLE_SUCCESS,
    rs
})

const _updateRoleFail = () => ({
    type: UPDATE_ROLE_FAIL
})

export const updateUserAccessAccountDuck = (k, v) => ({
    type: UPDATE_USER_ACCESS_ACCOUNT_DUCK,
    k, v
})

export const updateUserAccessAccountPermissions = (k, v) => ({
    type: UPDATE_USER_ACESS_ACCOUNT_PERMISSIONS,
    k, v
})

export const deleteRole = Id => async dispatch => {
    dispatch(_deleteRoleIsLoading())

    const rs = await axios.delete(baseURL + '/Role/Delete?Id=' + Id, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_deleteRoleSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_deleteRoleFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _deleteRoleIsLoading = () => ({
    type: DELETE_ROLE_IS_LOADING
})

const _deleteRoleSuccess = rs => ({
    type: DELETE_ROLE_SUCCESS,
    rs
})

const _deleteRoleFail = () => ({
    type: DELETE_ROLE_FAIL
})

//</editor-fold>

const initState = {
    roleNameField: '',
    permissions: {},
    createRoleIsLoading: false,
    updateRoleIsLoading: false,
    deleteRoleIsLoading: false
}

const UserAccessAccountDuck = (state = initState, action) => {
    let newPermissions

    switch (action.type) {

        case UPDATE_USER_ACCESS_ACCOUNT_DUCK:
            return {...state, [action.k]: action.v}

        case UPDATE_USER_ACESS_ACCOUNT_PERMISSIONS:
            newPermissions = {...state.permissions, [action.k]: action.v}
            return {...state, permissions: newPermissions}

        case CREATE_ROLE_SUCCESS:
            newPermissions = {...state.permissions}
            for (let k in newPermissions) {
                newPermissions[k] = false
            }
            return {...state, permissions: newPermissions, roleNameField: ''}

        case UPDATE_ROLE_IS_LOADING:
            return {...state}

        case UPDATE_ROLE_SUCCESS:
            return {...state}

        case UPDATE_ROLE_FAIL:
            return {...state}

        case DELETE_ROLE_IS_LOADING:
            return {...state}

        case DELETE_ROLE_SUCCESS:
            return {...state}

        case DELETE_ROLE_FAIL:
            return {...state}

        default:
            return state
    }
}

export default UserAccessAccountDuck