import React from 'react'
import PropTypes from 'prop-types'
import {NavLink} from 'react-router-dom'
import {withStyles, AppBar, Toolbar, Typography, Button, Menu, MenuItem} from '@material-ui/core'
import {hasPermissions} from '../helpers/utilities'
import {
    USER_ADMIN_CAMPAIGN_READ_PERMISSION, USER_ADMIN_CAMPAIGN_CREATE_PERMISSION,
    USER_ADMIN_CAMPAIGN_EDIT_PERMISSION, USER_ADMIN_ACCOUNT_READ_PERMISSION, USER_ADMIN_ACCOUNT_CREATE_PERMISSION,
    USER_ADMIN_ACCOUNT_EDIT_PERMISSION, USER_ADMIN_ROLE_READ_PERMISSION, USER_ADMIN_ROLE_CREATE_PERMISSION,
    USER_ADMIN_ROLE_EDIT_PERMISSION, GROUP_ISSUER_PERMISSION, GROUP_CREATOR_PERMISSION,
    GROUP_APPROVER_PERMISSION, USER_ADMIN_BANNER_READ_PERMISSION,
    USER_ADMIN_BANNER_CREATE_PERMISSION, USER_ADMIN_BANNER_EDIT_PERMISSION, INDIVIDUAL_ISSUER_PERMISSION,
    GROUP_VIEW_PERMISSION, SEARCH_GROUP_PERMISSION, SEARCH_PASSENGER_PERMISSION, VOUCHER_TRANSFER_EV, APPEAL_APPROVER, APPEAL_SUPER_APPROVER
} from '../constants/permissions'
import Logo from '../components/Logo'

class Header extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isGroupEdit: false,
            isGroupCreate: false,
            userMenu: null
        }
    }

    onLinkClick = (location) => {
        window.location = '/' + location
    }

    onUsername = e => {
        this.setState({userMenu: e.currentTarget})
    }

    onCloseUserMenu = () => {
        this.setState({userMenu: null})
    }

    render() {
        const {classes} = this.props
        const {userMenu} = this.state

        const logout = props => <NavLink to={'/logout'} {...props} activeClassName={classes.active}/>

        const hasAdminPermissions = hasPermissions([USER_ADMIN_CAMPAIGN_READ_PERMISSION, USER_ADMIN_CAMPAIGN_CREATE_PERMISSION,
            USER_ADMIN_CAMPAIGN_EDIT_PERMISSION, USER_ADMIN_ACCOUNT_READ_PERMISSION, USER_ADMIN_ACCOUNT_CREATE_PERMISSION,
            USER_ADMIN_ACCOUNT_EDIT_PERMISSION, USER_ADMIN_ROLE_READ_PERMISSION, USER_ADMIN_ROLE_CREATE_PERMISSION,
            USER_ADMIN_ROLE_EDIT_PERMISSION, USER_ADMIN_BANNER_READ_PERMISSION, USER_ADMIN_BANNER_CREATE_PERMISSION, USER_ADMIN_BANNER_EDIT_PERMISSION], 'or')

        const groupPermissions = hasPermissions([GROUP_ISSUER_PERMISSION, GROUP_CREATOR_PERMISSION, GROUP_APPROVER_PERMISSION, GROUP_VIEW_PERMISSION], 'or')

        const hasIndividualPermission = hasPermissions([INDIVIDUAL_ISSUER_PERMISSION])

        const hasSearchPermissions = hasPermissions([SEARCH_PASSENGER_PERMISSION, SEARCH_GROUP_PERMISSION], 'or')

        const hasCardPermissions = hasPermissions([VOUCHER_TRANSFER_EV])

        const hasAppealPermissions = hasPermissions([APPEAL_APPROVER,APPEAL_SUPER_APPROVER], 'or')

        return (
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Logo invert/>
                    <Button color={'inherit'} /*component={dashboard}*/
                        onClick={() => this.onLinkClick('dashboard')}>Dashboard</Button>
                    {hasIndividualPermission &&
                    <Button color={'inherit'} /*component={individual}*/
                        onClick={() => this.onLinkClick('individual')}>Individual</Button>}
                    {groupPermissions &&
                    <Button color={'inherit'} onClick={() => this.onLinkClick('group')}>Group</Button>}
                    {hasAdminPermissions &&
                    <Button color={'inherit'} onClick={() => this.onLinkClick('admin-settings')}>Admin
                        Settings</Button>}
                    {hasSearchPermissions &&
                    <Button color={'inherit'} onClick={() => this.onLinkClick('search')}>Search</Button>}
                    {hasCardPermissions &&
                        <Button color={'inherit'} onClick={() => this.onLinkClick('card')}>Card</Button>
                    }
                    {hasAppealPermissions &&
                        <Button color={'inherit'} onClick={() => this.onLinkClick('appeal-transaction')}>Pretravel</Button>
                    }

                    <div className={classes.lastItem}>
                        <Button color={'primary'} variant="contained" onClick={this.onUsername}
                            className={classes.clearBtn}>{localStorage.getItem('username')}</Button>
                        <Menu anchorEl={userMenu} open={Boolean(userMenu)} onClose={this.onCloseUserMenu}>
                            <MenuItem onClick={() => this.onLinkClick('change-password')}>Change password</MenuItem>
                        </Menu>
                        <Button color={'inherit'} component={logout} className={classes.logout}>Logout</Button>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }
}

const styles = theme => ({
    lastItem: {marginLeft: 'auto', display: 'flex', alignItems: 'center'},
    logout: {marginLeft: '.5rem'},
    username: {color: 'white'},
    appBar: {zIndex: theme.zIndex.drawer + 1},
    toolbar: {width: '100%'},
    active: {backgroundColor: theme.palette.action.selected},
    clearBtn: {boxShadow: 'none'}
})

Header.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Header)