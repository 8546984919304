import React from 'react'
import { connect } from 'react-redux'
import { withStyles, Grid, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { updateGroupCollectorDetails } from '../../ducks/groupRedemptionFormDuck'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import Paper from '@material-ui/core/Paper/Paper'
import moment from 'moment'
import { generateCollectionAgreementMessage, templateData, urlData, voucherData } from '../../constants/consentMessages'
import { MULTI_CARD_EV } from '../../constants/groupCardAssignment'

class GroupCollectorForm extends React.Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			totalCtpGenValue: 0,
			totalCtpLcpValue: 0,
		}
	}

	componentDidMount() {
		const {
			groupRedemptionFormDuck: { passengers },
		} = this.props
		let voucherDataCollected = this._getOverallVoucherData(passengers)
		this._buildConsentMessages(voucherDataCollected)
	}

	_getOverallVoucherData(passengers){
        let overallVoucherData = {
            totalCtpGenValue: 0,
            totalCtpLpcValue: 0,
            undeletedPax: 0,
        };

        for (const p of passengers) {
            if (!p.isDeleted && p.collecting) {
                overallVoucherData.totalCtpGenValue = parseInt(overallVoucherData.totalCtpGenValue) + parseInt(p.ctpGenValue)
				overallVoucherData.totalCtpLpcValue = parseInt(overallVoucherData.totalCtpLpcValue) + parseInt(p.ctpLpcValue)
				overallVoucherData.undeletedPax++
            }
        }
        return overallVoucherData
    }

	_buildConsentMessages(voucherDataCollected) {
		const {
			groupRedemptionFormDuck: { collector },
		} = this.props
		let newCollector = { ...collector }
		let collectionConsent, airlineConsent
		airlineConsent = templateData.airlineGroupConsent
		collectionConsent = generateCollectionAgreementMessage({
			agreementMessage: templateData.ctpGroupCollectionAgreementMessage,
			voucherDataCollected: voucherDataCollected,
			templateData: templateData,
			urlData: urlData
		})

		newCollector.collectionConsent = collectionConsent
		newCollector.airlineConsent = airlineConsent
		this.props.updateGroupCollectorDetails(newCollector)
	}

	onConsent = async () => {
		let {
			groupRedemptionFormDuck: { collector },
		} = this.props
		collector = { ...collector, allowReceivingInfo: !collector.allowReceivingInfo }
		await this.props.updateGroupCollectorDetails(collector)
		this.props.onGroupUpdate()
	}

	render() {
		const {
			classes,
			groupRedemptionFormDuck: { group, tourLeader, passengers, collector, cardAssignment },
		} = this.props

		return (
			<div>
				<Grid container spacing={24} direction={'column'}>
					<Grid container item spacing={24} alignItems={'center'}>
						<Grid item xs={12} md={4}>
							<Typography variant={'body1'}>Group Reference No</Typography>
						</Grid>

						<Grid item xs={12} md={8}>
							<Typography>{group.refNo}</Typography>
						</Grid>
					</Grid>

					<Grid container item spacing={24} alignItems={'center'}>
						<Grid item xs={12} md={4}>
							<Typography variant={'body1'}>Tour Company</Typography>
						</Grid>

						<Grid item xs={12} md={8}>
							<Typography>{group.tourCompany}</Typography>
						</Grid>
					</Grid>

					<Grid container item spacing={24} alignItems={'center'}>
						<Grid item xs={12} md={4}>
							<Typography variant={'body1'}>Tour Leader Name</Typography>
						</Grid>

						<Grid item xs={12} md={8}>
							<Typography>{tourLeader.lastName + ' ' + tourLeader.firstName}</Typography>
						</Grid>
					</Grid>

					<Grid container item spacing={24} alignItems={'center'}>
						<Grid item xs={12} md={4}>
							<Typography variant={'body1'}>Collection Date / Time</Typography>
						</Grid>

						<Grid item xs={12} md={8}>
							<Typography>{moment().format('DD/MM/YY hh:mm')}</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid container spacing={24} direction={'column'}>
					<Grid item>
						<Paper className={classes.root}>
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										<TableCell>Pax Booking Ref. No.1</TableCell>
										<TableCell>First name</TableCell>
										<TableCell>Last name</TableCell>
										{cardAssignment === 'multi' && <TableCell>Card Number</TableCell>}
										{cardAssignment === 'physical' && <TableCell>GEN Card Number</TableCell>}
										{cardAssignment === 'physical' && <TableCell>LPC Card Number</TableCell>}
										{cardAssignment === 'multi' && <TableCell>Voucher Value</TableCell>}
										{cardAssignment === MULTI_CARD_EV && <TableCell>Preloaded Card No</TableCell>}
									</TableRow>
								</TableHead>
								<TableBody>
									{passengers
										.filter((p) => p.collecting)
										.map((passenger, index) => {
											return (
												<TableRow key={index}>
													<TableCell component='th' scope='row'>
														<p>{passenger.pnr}</p>
													</TableCell>
													<TableCell>{passenger.firstName}</TableCell>
													<TableCell>{passenger.lastName}</TableCell>
													{cardAssignment === 'multi' && (
														<TableCell>
															<p>{passenger.cardNo}</p>
														</TableCell>
													)}
													{cardAssignment === MULTI_CARD_EV && (
														<TableCell>
															<p>{passenger.preloadedVouchersCardNo}</p>
														</TableCell>
													)}

													{cardAssignment === 'physical' && (
														<TableCell>
															<p>{passenger.genPhysicalVoucherNo}</p>
														</TableCell>
													)}

													{cardAssignment === 'physical' && (
														<TableCell>
															<p>{passenger.lpcPhysicalVoucherNo}</p>
														</TableCell>
													)}

													{cardAssignment === 'multi' && (
														<TableCell>
															${passenger.ctpGenValue ? passenger.ctpGenValue : 0} GEN | ${passenger.ctpLpcValue ? passenger.ctpLpcValue : 0} LPC
														</TableCell>
													)}
												</TableRow>
											)
										})}
								</TableBody>
							</Table>
						</Paper>
					</Grid>
					<Grid item>
						<Typography component={'span'} variant={'body1'}>
							<div dangerouslySetInnerHTML={{ __html: collector.airlineConsent }} />
						</Typography>
					</Grid>

					<Grid item>
						<div dangerouslySetInnerHTML={{ __html: collector.collectionConsent }} />
					</Grid>
				</Grid>

				{/* <Grid container direction={'column'} spacing={24} alignItems={'flex-start'}>
					<Grid item>
						<Typography component={'span'} variant={'body1'}>
							<div dangerouslySetInnerHTML={{ __html: collector.additionalCrConsent }}></div>
						</Typography>
					</Grid>
				</Grid> */}
			</div>
		)
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
	waiting: { color: green[600], fontSize: '.8rem' },
})

const mapStateToProps = (state) => {
	const { groupRedemptionFormDuck } = state

	return { groupRedemptionFormDuck }
}

const mapDispatchToProps = (dispatch) => ({
	updateGroupCollectorDetails: (collector) => dispatch(updateGroupCollectorDetails(collector)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(GroupCollectorForm))
