import React, {PureComponent} from 'react'
import {withStyles, Paper} from '@material-ui/core'
import GeneralTable from './GeneralTable'

class StyledTable extends PureComponent {
    render() {
        const {classes, ...others} = this.props

        return (
            <Paper className={classes.container}>
                <GeneralTable {...others} hover/>
            </Paper>
        )
    }
}

const styles = theme => ({
    container: {margin: '1rem 0'}
})

export default withStyles(styles)(StyledTable)