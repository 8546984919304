import axios from 'axios'
import { getSettings } from '../config/config'
import { getCurrentAuthHeader } from '../helpers/utilities'
import { checkValidStatusCode } from '../helpers/httpHelper'

let settings = getSettings();
let baseURL = settings.baseURL;

export const secondUserAuthenticate = data => async dispatch => {
	return await axios.post(baseURL + '/Account/Login', {
		usernameOrEmailAddress: data.secondUserAuthId,
		password: data.secondUserAuthPassword
	})
}

export const passengerCardReplacement = data => async dispatch => {
	dispatch(_passengerCardReplacementIsLoading())

	const rs = await axios.post(baseURL + '/Member/CardReplacement', data, getCurrentAuthHeader()).catch(e => {
		if (!checkValidStatusCode(e.response.status)) {
			const {
				response: {
					data: {
						error: { message }
					}
				}
			} = e
			return { data: { success: false, error: { message } } }
		}
		return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
	})
	if (!rs.data) {
		dispatch(_passengerCardReplacementFail())
		return { success: false, message: 'No data from server' }
	}
	if (rs.data.success) {
		dispatch(_passengerCardReplacementSuccess(rs))
		return { success: true, message: '' }
	} else {
		dispatch(_passengerCardReplacementFail())
		return { success: false, message: rs.data.error.message }
	}
}

const PASSENGER_CARD_REPLACEMENT_IS_LOADING = 'PASSENGER_CARD_REPLACEMENT_IS_LOADING'
const PASSENGER_CARD_REPLACEMENT_SUCCESS = 'PASSENGER_CARD_REPLACEMENT_SUCCESS'
const PASSENGER_CARD_REPLACEMENT_FAIL = 'PASSENGER_CARD_REPLACEMENT_FAIL'

const _passengerCardReplacementIsLoading = () => ({
	type: PASSENGER_CARD_REPLACEMENT_IS_LOADING
})

const _passengerCardReplacementSuccess = rs => ({
	type: PASSENGER_CARD_REPLACEMENT_SUCCESS,
	rs
})

const _passengerCardReplacementFail = () => ({
	type: PASSENGER_CARD_REPLACEMENT_FAIL
})

export const getAllRewardsByCardNo = cardNo => async dispatch => {
	dispatch(_getAllRewardsByCardNoIsFetching())

	const rs = await axios.get(baseURL + '/Member/GetAllRewardsByCardNo?cardNo=' + cardNo, getCurrentAuthHeader()).catch(e => {
		if (!checkValidStatusCode(e.response.status)) {
			const {
				response: {
					data: {
						error: { message }
					}
				}
			} = e
			return { data: { success: false, error: { message } } }
		}
		return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
	})
	if (!rs.data) {
		dispatch(_getAllRewardsByCardNoFail())
		return { success: false, message: 'No data from server' }
	}
	if (rs.data.success) {
		dispatch(_getAllRewardsByCardNoSuccess(rs.data.result.items))
		return { success: true, message: '' }
	} else {
		dispatch(_getAllRewardsByCardNoFail())
		return { success: false, message: rs.data.error.message }
	}
}

const GET_ALL_REWARDS_BY_CARD_NO_IS_FETCHING = 'GET_ALL_REWARDS_BY_CARD_NO_IS_FETCHING'
const GET_ALL_REWARDS_BY_CARD_NO_SUCCESS = 'GET_ALL_REWARDS_BY_CARD_NO_SUCCESS'
const GET_ALL_REWARDS_BY_CARD_NO_FAIL = 'GET_ALL_REWARDS_BY_CARD_NO_FAIL'

const _getAllRewardsByCardNoIsFetching = () => ({
	type: GET_ALL_REWARDS_BY_CARD_NO_IS_FETCHING
})

const _getAllRewardsByCardNoSuccess = data => ({
	type: GET_ALL_REWARDS_BY_CARD_NO_SUCCESS,
	data
})

const _getAllRewardsByCardNoFail = () => ({
	type: GET_ALL_REWARDS_BY_CARD_NO_FAIL
})

const initState = {
	passengerCardReplacementIsLoading: false,
	getAllRewardsByCardNoIsFetching: false
}

const PassengerDuck = (state = initState, action) => {
	switch (action.type) {
		case PASSENGER_CARD_REPLACEMENT_IS_LOADING:
			return { ...state, passengerCardReplacementIsLoading: true }

		case PASSENGER_CARD_REPLACEMENT_SUCCESS:
			return { ...state, passengerCardReplacementIsLoading: false, cardReplacementResponse: action.rs }

		case PASSENGER_CARD_REPLACEMENT_FAIL:
			return { ...state, passengerCardReplacementIsLoading: false }

		case GET_ALL_REWARDS_BY_CARD_NO_IS_FETCHING:
			return { ...state, getAllRewardsByCardNoIsFetching: true }

		case GET_ALL_REWARDS_BY_CARD_NO_SUCCESS:
			return { ...state, getAllRewardsByCardNoIsFetching: false, rewardsByCardNo: action.data }

		case GET_ALL_REWARDS_BY_CARD_NO_FAIL:
			return { ...state, getAllRewardsByCardNoIsFetching: false, rewardsByCardNo: [] }

		default:
			return state
	}
}

export default PassengerDuck
