import React, { Component } from 'react'
import {
	withStyles,
	Paper,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel,
	Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@material-ui/core'
import { getGroupLabel } from '../../helpers/groupStatusHelper'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { requestForReprinting } from '../../ducks/printCardDuck'
import { connect } from 'react-redux'

class SearchResultTable extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			order: 'asc',
			orderBy: 'cardNo',
			reprintDialogOpen: false,
			reprintCard: null,
		}
	}
	renderEticket = (d) => {
		const { classes } = this.props

		const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
		return (
			<ol className={classes.eticket}>
				{keys.map((e, i) => {
					if (d[e] === '') return <div key={d.id + '' + i} />
					return <li key={d.id + '' + e}>{d[e]}</li>
				})}
			</ol>
		)
	}

	onChangeCardNo = (d, idx) => {
		this.props.history.push('/search/passenger/' + d.eTicket1 + '/' + idx)
	}

	onView = (d, idx) => {
		this.props.history.push('/redemption-transaction-detail/' + d.redemptionTransactionId)
	}

	onLinkClick = (type, identifier) => {
		if (type === "group"){
			window.open("/group/group-detail/" + identifier)
		} else if (type === "redemption"){
            window.open("/redemption-transaction-detail/" + identifier)
        } else if (type === "appealTransaction"){
            window.open("/appeal-transaction-detail/" + identifier)
        }
	}

	_getGroupStatus = (d) => {
		if (d.redemptionTransactionId) return <span>COLLECTED</span>
		if (d.groupId && d.group && d.group != null) {
			return <span>{getGroupLabel(d.group)}</span>
		}
	}

    _getAppealTransactionStatus = (d, classes) =>{
		if (!d.appealTransaction) return "";
		if (d.appealTransaction.approval){
			return (<span className = {classes.preTravelStat}>APPROVED</span>)
		} else if (d.appealTransaction.rejected){
			return (<span className = {classes.preTravelStat}>REJECTED</span>)
		} else {
			return (<span className = {classes.preTravelStat}>PENDING</span>)
		}

	}
	_getCardNo = (d, classes) => {
		if (d.cardNo) {
			if (d.cardNo.startsWith('00004')) {
				return <Tooltip title='Print'>
						<span className={classes.clickable} onClick={() => this.handleClickOpen(d.cardNo, d.redemptionTransactionId)}>
							{d.cardNo}
						</span>
					</Tooltip>
			} else if (d.cardNo.startsWith('00002')) {
				return <span>{d.cardNo}</span>
			} else {
				return ''
			}
		}
	}

	descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}
		if (b[orderBy] > a[orderBy]) {
			return 1
		}
		return 0
	}

	getComparator = (order, orderBy) => {
		return order === 'desc' ? (a, b) => this.descendingComparator(a, b, orderBy) : (a, b) => -this.descendingComparator(a, b, orderBy)
	}

	stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index])
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0])
			if (order !== 0) return order
			return a[1] - b[1]
		})
		return stabilizedThis.map((el) => el[0])
	}

	printCard = async () => {
		let url = '/print-card?state=ctp_app';
		const { reprintCard, reprintTransactionId } = this.state

		if (reprintTransactionId) {
			url = url + '&id=' + reprintTransactionId
			this.setState({ reprintTransactionId: null })
		}
		if (reprintCard) {
			url = url + '&cardNo=' + reprintCard.toString()
			this.setState({ reprintCard: null })
		}
		window.open(window.location.origin + url)
	}

	handleDialogClose = () => {
		this.setState({ reprintDialogOpen: false })
	}

	handleClickOpen = (card, id) => {
		this.setState({ reprintDialogOpen: true, reprintCard: card, reprintTransactionId: id })
	}

	handleDialogNo = () => {
		this.setState({ reprintDialogOpen: false, reprintCard: null, reprintTransactionId: null })
	}

	handleDialogYes = async () => {
		this.setState({ reprintDialogOpen: false })
		await this.printCard()
	}

	render() {
		const { classes, data, fields } = this.props
		const { order, orderBy, reprintDialogOpen } = this.state
		const textFields = ['firstName', 'lastName', 'genPhysicalVoucherNo', 'lpcPhysicalVoucherNo', 'customField']
		const handleRequestSort = (event, property) => {
			const isAsc = orderBy !== property || (orderBy === property && order === 'desc')
			this.setState({ order: isAsc ? 'asc' : 'desc', orderBy: property })
		}
		const visuallyHidden = {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		}
		function EnhancedTableHead(props) {
			const { onRequestSort } = props
			const createSortHandler = (property) => (event) => {
				onRequestSort(event, property)
			}
			return (
				<TableHead>
					<TableRow>
						{fields.map((headCell) => (
							<TableCell
								key={headCell.key}
								align={headCell.numeric ? 'right' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === headCell.key ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.key}
									direction={orderBy === headCell.key ? order : 'asc'}
									onClick={createSortHandler(headCell.key)}
								>
									{headCell.label}
									{orderBy === headCell.key ? <span style={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
			)
		}
		return (
			<div>
				<Paper className={classes.container, "search-result-element"}>
					<Table>
						<EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
						<TableBody>
							{this.stableSort(data, this.getComparator(order, orderBy)).map((d, idx) => {
								return (
									<TableRow key={d.id}>
										{fields.map((f) => {
											return (
												<TableCell key={d.id + f.key}>
													{f.key === 'seq' && <span>{idx + 1}</span>}

													{f.key === 'pnr' && d.pnr}

													{textFields.includes(f.key) && <span>{d[f.key]}</span>}

													{f.key === 'cardNo' && this._getCardNo(d, classes)}

                                                    {f.key === 'preloadedVouchersCardNo' && (
                                                        <Tooltip title='Print'>
															<span className={classes.clickable} onClick={() => this.handleClickOpen(d[f.key], d.redemptionTransactionId)}>
																{d[f.key]}
															</span>
                                                        </Tooltip>
                                                    )}

													{f.key === 'eticket' && this.renderEticket(d)}

													{f.key === 'group.refNo' && d.groupId && d.group && (
														<span className={classes.clickable} onClick={() => this.onLinkClick('group', d.group.refNo)}>
															{d.group.refNo}
														</span>
													)}

													{f.key === 'redemptionTransactionId' && d.redemptionTransactionId && !d.group && (
														<span className={classes.clickable} onClick={() => this.onLinkClick('redemption', d.redemptionTransactionId)}>
															{d.redemptionTransaction.refNo
																? d.redemptionTransaction.refNo
																: 'M' + moment(d.creationTime).format('YYMM') + '-' + d.redemptionTransactionId}
														</span>
													)}

													{f.key === 'status' && this._getGroupStatus(d)}

													{f.key === 'appealRefNo' && d.appealTransaction && (
                                                        <span className={classes.pretravelClickable} onClick={() => this.onLinkClick('appealTransaction', d.appealTransaction.id)}>
															{d.appealTransaction.refNo}
														</span>
													)}

													{f.key === 'appealStatus' && this._getAppealTransactionStatus(d, classes)}


												</TableCell>
											)
										})}

									</TableRow>
								)
							})}
						</TableBody>
					</Table>
					<Dialog open={reprintDialogOpen} onClose={this.handleDialogClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
						<DialogTitle id='alert-dialog-title'>{'Are you sure you want to reprint this Card?'}</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-description'>{this.state.reprintCard ? `CardNo: ${this.state.reprintCard}` : ''}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleDialogNo} color='primary'>
								No
							</Button>
							<Button onClick={this.handleDialogYes} color='primary' autoFocus>
								Yes
							</Button>
						</DialogActions>
					</Dialog>
				</Paper>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const { printCardDuck } = state

	return { printCardDuck }
}

const mapDispatchToProps = (dispatch) => {
	return { requestForReprinting: (cards, redemptionTransactionId) => dispatch(requestForReprinting(cards, redemptionTransactionId)) }
}

const styles = (theme) => ({
	container: theme.container,
	eticket: { padding: '0 1rem' },
	clickable: {
		cursor: 'pointer',
		borderBottom: '1px dotted #ccc',
	},
	pretravelClickable: {
		cursor: 'pointer',
		borderBottom: '1px dotted #ccc',
		color: 'blue',
	},
	preTravelStat: { color: 'blue' },
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(SearchResultTable))
