import React from 'react'
import PropTypes from 'prop-types'
import {TextField} from '@material-ui/core'
import moment from "moment";

class GeneralTextDate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dateDisplay: "",
            displayError: false,
            helperText: ""
        }
    }

    componentDidMount = () => {
        if(this.props.value){
            let momentDate = moment(this.props.value, "YYYY-MM-DD");
            if(!momentDate.isValid()) return;

            let formattedString = momentDate.format("DD/MM/YYYY");
            this.setState({dateDisplay: formattedString})
        }
    }

    componentDidUpdate = (prevProps, prevState) => {

        if(prevState.dateDisplay !== this.state.dateDisplay){
            if(!this.state.dateDisplay) return;
            if(this.state.dateDisplay.length < 10) {
                this.setState({displayError: false, helperText: ""})
                this.props.onChange({target: {value: "" }}); //reset value
                return;
            }

            if(!this._checkValidSectionData(this.state.dateDisplay)){
                this.setState({displayError: true, helperText: "Invalid date"})
                return;
            }

            let momentDate = moment(this.state.dateDisplay, "DD/MM/YYYY");

            if(!momentDate.isValid()){
                this.setState({displayError: true, helperText: "Invalid date"})
                return;
            } else {
                this.setState({displayError: false, helperText: momentDate.format("DD MMM YYYY")})
                let formattedDate = momentDate.format("YYYY-MM-DD");
                this.props.onChange({target: {value: formattedDate }});
            }

        }

        if(prevProps.value !== this.props.value){

            if(!this.props.value){ //check for a new date entry and reset the date entry
                let previousDate = moment(prevProps.value, "YYYY-MM-DD");
                if(previousDate.isValid()) this.setState({dateDisplay: "", displayError: false, helperText: "" })
            }

            let momentDate = moment(this.props.value, "YYYY-MM-DD");
            if(!momentDate.isValid()) return;

            let formattedString = momentDate.format("DD/MM/YYYY");
            this.setState({dateDisplay: formattedString})
        }

    }

    onChange = async (e)=>{
        e.preventDefault();
        let rawInput = e.target.value;
        let formattedDate = "";
        if(rawInput.length > 10){
            return;
        } else if(rawInput.length < 1){
            this.setState({dateDisplay: formattedDate});
            return;
        } else{
            if(this._checkInvalidCharacters(rawInput[rawInput.length-1])) return;
            formattedDate = this._addSlash(rawInput);
            if(this._checkIncorrectSectionLength(formattedDate)) return;
            this.setState({dateDisplay: formattedDate})
        }
    };

    _checkIncorrectSectionLength = (string)=>{
        let sections = string.split("/");
        let isIncorrectLength = false;
        if(sections.length > 0){
            if(sections[0].length > 2) isIncorrectLength = true;
        }
        if(sections.length > 1){
            if(sections[1].length > 2) isIncorrectLength = true;
        }
        if(sections.length > 2){
            if(sections[2].length > 4) isIncorrectLength = true;
        }
        return isIncorrectLength;
    };

    _checkValidSectionData = (dateString)=>{
        let sections = dateString.split("/");
        let isValidSectionData = true;
        if(sections.length !== 3) isValidSectionData = false;
        if(sections[0] > 31) isValidSectionData = false;
        if(sections[1] > 12) isValidSectionData = false;
        if(sections[2] < 1900 || sections[2] > 2100) isValidSectionData = false;
        return isValidSectionData;
    };

    _checkInvalidCharacters = (string) => {
        let invalidCharRegex = /[^0-9/]/gi;
        return !!string.match(invalidCharRegex);
    };

    _addSlash = (numericDate) => {
        let formattedDate = numericDate;
        if(this._checkNoOfSlash(numericDate) === 2) return numericDate;

        if(numericDate.length === 3){
            if(this._checkNoOfSlash(numericDate) < 1){
                formattedDate = numericDate.substr(0,2) + "/" + numericDate.substr(2);
            }
        }
        if(numericDate.length === 6){
            if(this._checkNoOfSlash(numericDate) < 2) {
                formattedDate = numericDate.substr(0, 5) + "/" + numericDate.substr(5);
            }
        }
        return formattedDate;
    };

    _checkNoOfSlash = (string) => {
        let slashRegex = /\//gi;
        let slashMatch = string.match(slashRegex)
        if(!slashMatch) return 0;
        else return slashMatch.length
    }


    _checkInvalidChar = (alpherNumbericDate) => {
        var numberRegex = /\D/g;
        if(numberRegex.test(alpherNumbericDate)){

        }
        var reg = /^[a-zA-Z]+$/;
        return !reg.test(alpherNumbericDate)
    };

    render() {
        return (
            <div style={styles.container.styles}>
                <TextField
                    type="text"
                    placeholder="dd/mm/yyyy"
                    id={this.props.key}
                    onChange={(event) => this.onChange(event)}
                    style={this.props.helperText ? {width: '100%', height: 60} : styles.container.textField.styles}
                    value={this.state.dateDisplay}
                    variant="outlined"
                    error={this.state.displayError || this.props.error}
                    helperText={this.state.helperText || this.props.helperText}
                    disabled={this.props.disabled || false}
                />
            </div>
        )
    }

}

const styles = {
    container: {
        styles: {width: '100%'},
        textField: {
            styles: {width: '100%'}
        }
    }
}

GeneralTextDate.propTypes = {
    /*penColor: PropTypes.string.isRequired,
    dimensions: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired

    }).isRequired,
    clearButtonText: PropTypes.string*/
}

export default GeneralTextDate