import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, withStyles, Button, TextField } from '@material-ui/core'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import { TITLE_PREFIX } from '../constants/titlePrefix'
import Header from '../containers/Header.js'
import { getCurrentAuthHeader, hasPermissions } from '../helpers/utilities'
import StyledSnackbar from '../components/StyledSnackbar'
import {
	getRedemptionTransactionAndPassengers,
	updateRedemptionTransactionPassengerSinglePax,
	updateRedemptionTransactionPassenger,
	updateRedemptionTransactionAndPassengers,
	redemptionTransactionPassengersFilesUpload,
	updateRedemptionTransactionPassengersDuck,
	voidTransaction
} from '../ducks/redemptionTransactionPassengersDuck'
import RedemptionPassengersTable from '../containers/redemptionTransactionDetail/RedemptionPassengersTable'
import PassengerDetails from '../containers/redemptionTransactionDetail/PassengerDetails'
import CollectorDetails from '../containers/redemptionTransactionDetail/CollectorDetails'
import StyledSection from '../components/StyledSection'
import DeleteDialog from '../components/DeleteDialog'
import AuditLogTable2 from '../components/AuditLogTable2'
import ReprintAuditLogTable from '../components/ReprintAuditLogTable'

const initState = {
	showSnackbar: false,
	snackbarMessage: '',
	snackbarVariant: 'success',
	deleteDialog: false,
	voidTransactionDialog: false,
	deletedOriginalAttachmentIdx: new Set(),
	confirmDelete: false,
	viewOnly: false,
	redemptionTransactionId: 0,
	files: [],
	voidRemark: ''
}

class RedemptionTransactionDetailScreen extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = { ...initState }
		this.etixkeys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
	}

	componentDidMount = async () => {
		const {
			match: {
				params: { id },
			},
		} = this.props
		this.setState({ redemptionTransactionId: +id })
		document.title = `${TITLE_PREFIX} Redemption transaction detail - ${id}`

		const data = { params: { redemptionTransactionId: id }, ...getCurrentAuthHeader() }
		const rs = await this.props.getRedemptionTransactionAndPassengers(data)
		if (!rs.success) this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
		if (!hasPermissions('Individual.Editor')) {
			this.setState({ viewOnly: true })
		}
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	onNameClick = (idx, p) => {
		this.props.updateRedemptionTransactionPassenger('currentIdx', idx)
	}

	onETicketChange = (idx, sect, etix, e) => {
		const key = 'eTicket' + (etix + 1)
		this.props.updateRedemptionTransactionPassengerSinglePax(idx, sect, key, e.target.value)
	}

	onFieldChange = (sect, k, e) => {
		let idx = this.props.redemptionTransactionPassengersDuck.currentIdx
		this.props.updateRedemptionTransactionPassengerSinglePax(idx, sect, k, e.target.value)
	}

	onCollectorFieldChange = (k, e) => {
		this.props.updateRedemptionTransactionPassengersDuck({ redemptionTransaction: { [k]: e.target.value } })
	}

	onVoidRemarkChange = (e) => {
		this.setState({ voidRemark: e.target.value });
	}

	onSubmit = async () => {
		const { deletedOriginalAttachmentIdx, confirmDelete, files } = this.state

		if (deletedOriginalAttachmentIdx.size > 0 && !confirmDelete) {
			return this.showDeleteDialog()
		}

		if (files.length > 0) {
			const filesrs = await this.uploadFiles()
			if (!filesrs.success) {
				this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'File upload failed' })
				return
			}
		}

		const {
			redemptionTransactionPassengersDuck: { redemptionTransaction, passengers },
		} = this.props
		const data = { redemptionTransaction, passengers }

		this.setState({ ...initState, files: [], deletedOriginalAttachmentIdx: new Set() })
		const rs = await this.props.updateRedemptionTransactionAndPassengers(data)
		if (rs.success) {
			const {
				match: {
					params: { id },
				},
			} = this.props
			const data = { params: { redemptionTransactionId: id }, ...getCurrentAuthHeader() }
			this.props.getRedemptionTransactionAndPassengers(data)
			this.setState({ showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'Record updated' })
		} else {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
		}
	}

	async uploadFiles() {
		const { files } = this.state
		let formData = new FormData()
		files.forEach((f) => {
			formData.append('files', f, f.name)
		})
		return await this.props.redemptionTransactionPassengersFilesUpload(formData)
	}

	showDeleteDialog = () => {
		this.setState({ deleteDialog: true })
	}

	showVoidTransactionDialog = () => {
		this.setState({ voidTransactionDialog: true })
	}

	onAttachmentClick = (url) => {
		window.open(url)
	}

	onUndoDeleteOrigAttachment = (idx) => {
		let newDeletedOriginalAttachmentIdx = new Set(this.state.deletedOriginalAttachmentIdx)
		newDeletedOriginalAttachmentIdx.delete(idx)
		this.setState({ deletedOriginalAttachmentIdx: newDeletedOriginalAttachmentIdx })
	}

	onDeleteOrigAttachment = (idx) => {
		this.setState({ deletedOriginalAttachmentIdx: this.state.deletedOriginalAttachmentIdx.add(idx) })
	}

	onDeleteCancel = () => {
		this.setState({ deleteDialog: false, confirmDelete: false })
	}

	onVoidTransactionCancel = () => {
		this.setState({ voidTransactionDialog: false })
	}

	onDeleteConfirm = () => {
		this.setState({ deleteDialog: false, confirmDelete: true }, async () => {
			// Remove attachments in attachmentUris then proceed as usual
			const {
				redemptionTransactionPassengersDuck: { currentIdx, passengers },
			} = this.props
			let sector2 = passengers[currentIdx].eTicket[0].sector.find((s) => s.sectorNo === 2)
			let attachmentUris = sector2.attachmentUris

			let newAttachmentUris = []
			for (let i = 0; i < attachmentUris.length; i++) {
				if (!this.state.deletedOriginalAttachmentIdx.has(i)) newAttachmentUris.push(attachmentUris[i])
			}
			await this.props.updateRedemptionTransactionPassengerSinglePax(currentIdx, 'sector2', 'attachmentUris', newAttachmentUris)
			this.onSubmit()
		})
	}

	onVoidTransactionConfirm = () => {
		this.setState({ voidTransactionDialog: false }, async () => {
			const rs = await this.props.voidTransaction({
				redemptionTransactionId: this.state.redemptionTransactionId,
				remark: this.state.voidRemark
			})
			
			if (rs.success) {
				this.setState({ showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'Transaction voided' })
			}
			else {
				this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
			}
		})
	}

	onRemoveCircleClick = (f, i) => {
		const files = [...this.state.files]
		files.splice(i, 1)
		this.setState({ files })
	}

	onDrop = (acceptedFiles) => {
		const files = [...this.state.files]
		for (const af of acceptedFiles) {
			let exists = false
			for (const f of files) {
				if (af.name === f.name) exists = true
			}
			if (!exists) files.push(af)
		}
		this.setState({ files })
	}

	onConsent = () => {
		const {
			redemptionTransactionPassengersDuck: {
				redemptionTransaction: { allowReceivingInfo },
			},
		} = this.props
		this.props.updateRedemptionTransactionPassengersDuck({ redemptionTransaction: { allowReceivingInfo: !allowReceivingInfo } })
	}

	render() {
		const {
			classes,
			redemptionTransactionPassengersDuck: {
				redemptionTransaction,
				passengers,
				currentIdx,
				getRedemptionTransactionAndPassengersIsLoading,
				updateRedemptionTransactionAndPassengersIsLoading,
				redemptionTransactionPassengersFilesUploadIsLoading,
			},
		} = this.props
		const { voidTransactionDialog, deleteDialog, deletedOriginalAttachmentIdx, files, viewOnly } = this.state

		let sector1 = {
			flightno: '',
			origin: '',
			departureDate: '',
			destination: '',
			remarks: '',
		}

		let sector2 = {
			flightno: '',
			origin: '',
			departureDate: '',
			destination: '',
			transportMean: 'na',
		}
		if (passengers[currentIdx].eTicket.length > 0) {
			sector1 = passengers[currentIdx].eTicket[0].sector.find((s) => s.sectorNo === 1)
			sector2 = passengers[currentIdx].eTicket[0].sector.find((s) => s.sectorNo === 2)
		}

		const isLoading =
			getRedemptionTransactionAndPassengersIsLoading || updateRedemptionTransactionAndPassengersIsLoading || redemptionTransactionPassengersFilesUploadIsLoading
		return (
			<div>
				<GlobalTimeChecker />

				<Header history={this.props.history} />

				<div className={classes.container}>
					{sector2.transportMean !== 'na' && sector2.attachmentUris && (
						<DeleteDialog showDeleteDialog={deleteDialog} dialogTitle={'Warning'} onDeleteConfirm={this.onDeleteConfirm} onDeleteCancel={this.onDeleteCancel}>
							The following attachments will be removed:
							<ul>
								{sector2.attachmentUris.map((a, i) => {
									if (deletedOriginalAttachmentIdx.has(i)) {
										return <li key={'deleting' + i}>{a}</li>
									} else {
										return <div />
									}
								})}
							</ul>
						</DeleteDialog>
					)}

					<DeleteDialog 
						showDeleteDialog={voidTransactionDialog}
						dialogTitle={'Warning'}
						hideWarningMessage={true}
						onDeleteConfirm={this.onVoidTransactionConfirm}
						onDeleteCancel={this.onVoidTransactionCancel}>
						Are you sure you wish to void the transaction?

						<Grid container spacing={24} alignItems={'center'}>
							<Grid item md={3}>
								Remarks
							</Grid>
							<Grid item md={9}>
								<TextField
									margin='normal'
									variant='outlined'
									onChange={e => this.onVoidRemarkChange(e)}
									fullWidth
								/>
							</Grid>
						</Grid>
					</DeleteDialog>

					<StyledSection title={'Passengers'} isLoading={isLoading}>
						<Grid container spacing={24} direction={'column'}>
							<Grid item>
								<RedemptionPassengersTable data={passengers} onNameClick={this.onNameClick} currentIdx={currentIdx} />
							</Grid>
						</Grid>
					</StyledSection>

					<StyledSection title={'Pax Details'} isLoading={isLoading}>
						<PassengerDetails
							viewOnly={viewOnly}
							passenger={passengers[currentIdx]}
							files={files}
							deletedOriginalAttachmentIdx={deletedOriginalAttachmentIdx}
							onFieldChange={this.onFieldChange}
							isShowing
						/>
					</StyledSection>

					<StyledSection title={'Collector details'} isLoading={isLoading}>
						<CollectorDetails viewOnly={viewOnly} redemptionTransaction={redemptionTransaction} onCollectorFieldChange={this.onCollectorFieldChange} />
					</StyledSection>

					{!viewOnly && (
						<StyledSection title={'Update details'} isLoading={isLoading}>
							<Grid container spacing={24}>
								<Grid item>
									<Button color={'primary'} variant='contained' onClick={this.onSubmit}>
										Submit
									</Button>
								</Grid>
								<Grid item>
									<Button color={'secondary'} variant='contained' onClick={this.showVoidTransactionDialog}>
										Void
									</Button>
								</Grid>
							</Grid>
						</StyledSection>
					)}

					<AuditLogTable2 redemptionTransactionId={this.props.match.params.id}/>

				</div>

				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
	pointer: { cursor: 'pointer' },
})

const mapStateToProps = (state) => {
	const { redemptionTransactionPassengersDuck } = state

	return { redemptionTransactionPassengersDuck }
}

const mapDispatchToProps = (dispatch) => {
	return {
		getRedemptionTransactionAndPassengers: (data) => dispatch(getRedemptionTransactionAndPassengers(data)),
		updateRedemptionTransactionPassengerSinglePax: (idx, sect, k, v) => dispatch(updateRedemptionTransactionPassengerSinglePax(idx, sect, k, v)),
		voidTransaction: (data) => dispatch(voidTransaction(data)),
		updateRedemptionTransactionPassenger: (k, v) => dispatch(updateRedemptionTransactionPassenger(k, v)),
		updateRedemptionTransactionAndPassengers: (data) => dispatch(updateRedemptionTransactionAndPassengers(data)),
		redemptionTransactionPassengersFilesUpload: (formData) => dispatch(redemptionTransactionPassengersFilesUpload(formData)),
		updateRedemptionTransactionPassengersDuck: (obj) => dispatch(updateRedemptionTransactionPassengersDuck(obj)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(RedemptionTransactionDetailScreen))
