import React, {Component} from 'react'
import {connect} from 'react-redux'
import StyledSection from '../components/StyledSection'
import StyledSnackbar from '../components/StyledSnackbar'
import {getGroupAudit} from '../ducks/auditLogDuck'
import StyledTable from '../components/StyledTable'
import {getCurrentAuthHeader} from '../helpers/utilities'
import moment from 'moment'

// TODO: refactor user/time keys
const AUDIT_TABLE_FIELDS = [
    {key: 'seq', label: 'S/N'},
    {key: 'userName', label: 'User'},
    {key: 'formattedChangeTime', label: 'Time'},
    {key: 'entityType', label: 'Type'},
    {key: 'entityDisplayName', label: 'Identity'},
    {key: 'propertyName', label: 'Field'},
    {key: 'originalValue', label: 'Original value'},
    {key: 'newValue', label: 'New value'}
]

class AuditLogTable extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success'
        }
    }

    componentDidMount = async () => {

    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.getGroupAndPassengersIsLoading !== this.props.getGroupAndPassengersIsLoading) {
            if (this.props.group.id) {
                const data = {params: {groupId: this.props.group.id}, ...getCurrentAuthHeader()}
                const rs = await this.props.getGroupAudit(data)
         
            }
        }
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    _formatAuditData = auditDatas => {
        let formattedAuditDatas = [].concat(auditDatas)
        formattedAuditDatas.forEach(formattedAuditData =>
            formattedAuditData.formattedChangeTime = moment(formattedAuditData.changeTime).format('DD-MM-YYYY')
        )
        return formattedAuditDatas
    }

    render() {
        const {auditLogDuck: {entityChangePropertyList, getGroupAuditIsLoading}} = this.props

        return (
            <div>
                <StyledSection title={'Audit Table'} isLoading={getGroupAuditIsLoading}>
                    {entityChangePropertyList.length > 0 && <StyledTable
                        fields={AUDIT_TABLE_FIELDS}
                        rows={this._formatAuditData(entityChangePropertyList)}
                    />}
                </StyledSection>
                <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {auditLogDuck, groupApprovalDuck} = state
    const {group, getGroupAndPassengersIsLoading} = groupApprovalDuck

    return {auditLogDuck, group, getGroupAndPassengersIsLoading}
}

const mapDispatchToProps = dispatch => {
    return {
        getGroupAudit: data => dispatch(getGroupAudit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogTable)