import axios from 'axios'
import { getSettings } from '../config/config'
import { checkValidStatusCode } from '../helpers/httpHelper'
import { getCurrentAuthHeader } from '../helpers/utilities'
let settings = getSettings()
let baseURL = settings.baseURL

export const getReprintAuditLog = (redemptionTransactionId) => async (dispatch) => {
	dispatch(_getReprintAuditLogIsLoading())

	const rs = await axios
		.get(baseURL + '/RedemptionTransaction/GetReprintAuditLog', { params: { redemptionTransactionId: redemptionTransactionId }, ...getCurrentAuthHeader() })
		.catch((e) => {
			if (!checkValidStatusCode(e.response.status)) {
				const {
					response: {
						data: {
							error: { message },
						},
					},
				} = e
				return { data: { success: false, error: { message } } }
			}
			return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
		})

	if (!rs.data) {
		dispatch(_getReprintAuditLogFail())
		return { success: false, message: 'No data from server' }
	}
	if (rs.data.success) {
		dispatch(_getReprintAuditLogSuccess(rs))
		return { success: true, message: '' }
	} else {
		dispatch(_getReprintAuditLogFail())
		return { success: false, message: rs.data.error.message }
	}
}

const GET_REPRINT_AUDIT_LOG_IS_LOADING = 'GET_REPRINT_AUDIT_LOG_IS_LOADING'
const GET_REPRINT_AUDIT_LOG_SUCCESS = 'GET_REPRINT_AUDIT_LOG_SUCCESS'
const GET_REPRINT_AUDIT_LOG_FAIL = 'GET_REPRINT_AUDIT_LOG_FAIL'

const _getReprintAuditLogIsLoading = () => ({
	type: GET_REPRINT_AUDIT_LOG_IS_LOADING,
})

const _getReprintAuditLogSuccess = (rs) => ({
	type: GET_REPRINT_AUDIT_LOG_SUCCESS,
	rs,
})

const _getReprintAuditLogFail = () => ({
	type: GET_REPRINT_AUDIT_LOG_FAIL,
})

let initState = {
	getReprintAuditLogIsLoading: false,
	reprintLogs: [],
}

const reprintAuditLog = (state = initState, action) => {
	switch (action.type) {
		case GET_REPRINT_AUDIT_LOG_IS_LOADING:
			return { ...state, getReprintAuditLogIsLoading: true }

		case GET_REPRINT_AUDIT_LOG_SUCCESS:
			return { ...state, reprintLogs: action.rs.data.result, getReprintAuditLogIsLoading: false }

		case GET_REPRINT_AUDIT_LOG_FAIL:
			return { ...state, getReprintAuditLogIsLoading: false }

		default:
			return state
	}
}

export default reprintAuditLog
