import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router'
import {withStyles} from '@material-ui/core'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import {updateAuthDuck} from '../ducks/authDuck'
import {HubConnectionBuilder} from '@aspnet/signalr'
import {signalRURL} from '../config/config'
import signalRConstants from '../helpers/signalRConstants'

class LogoutScreen extends PureComponent {
    constructor(props, context) {
        super(props, context)
        this.state = {
            loggedOut: false
        }
    }

    componentDidMount = () => {
        document.title = `${TITLE_PREFIX} Logout`
        console.log('clearing all data')
        localStorage.clear()
        this.props.updateAuthDuck('isLoggedIn', null)

        this.hubConnection = new HubConnectionBuilder().withUrl(signalRURL).build()
        this._startSignalRConnection()
        this.hubConnection.onclose(async () => {
            await this._startSignalRConnection()
        })
        // this.setState({loggedOut: true})
        setTimeout(() => window.location = window.location.origin, 2000)
    }

    _startSignalRConnection = async () => {
        this.hubConnection.start()
        .then(() => {console.log('Connection started!'); this._sendMessage();}).catch(err => {
            setTimeout(() => this._startSignalRConnection(), 5000)
            console.log('Error while establishing connection :(')
        })
    }

    _sendMessage = () => {
        let messageType = signalRConstants.REFRESH_CLIENT

        const user = localStorage.getItem('username')
        let messageObj = {
            user,
            type: messageType
        }
        let messageStr = JSON.stringify(messageObj)
        this.hubConnection.invoke('SendMessage', 'default', messageStr).catch(function (err) {
            return console.error(err.toString())
        })
    }

    render() {
        return (
            <div>
                {this.state.loggedOut && <Redirect to='/'/>}
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

const mapDispatchToProps = dispatch => {
    return {
        updateAuthDuck: (k, v) => dispatch(updateAuthDuck(k, v))
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(LogoutScreen))
