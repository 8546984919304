import React, {PureComponent} from 'react'
import {Button} from '@material-ui/core'

class ScrollToTopBtn extends PureComponent {
    onBackToTop = () => {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <Button color={'primary'} variant="outlined" onClick={this.onBackToTop}>Back to top</Button>
        )
    }
}

export default ScrollToTopBtn