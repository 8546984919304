import React, {Component} from 'react'
import {
    withStyles,
    Grid,
    TextField,
    Divider, Typography, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core'
import PropTypes from 'prop-types'
import {toBrowserFriendlyDate} from "../../helpers/utilities";
import StyledAttachmentList from "../../components/StyledAttachmentList";
import GeneralDropzone from "../../components/generalForm/GeneralDropzone";
import {RemoveCircle} from "@material-ui/icons";

const DEFAULT_SECTOR = {
    flightno: '',
    origin: '',
    departureDate: '',
    destination: '',
    remarks: ''
}

class PassengerDetails extends Component {

    onFieldChange = (sect, k, e) => {
        this.props.onFieldChange(sect, k, e)
    }

    onAttachmentClick = (url) => {
        window.open(url)
    }

    render() {
        const {classes, viewOnly, passenger, files, deletedOriginalAttachmentIdx, isShowing} = this.props;
        let sector1 = DEFAULT_SECTOR;
        let sector2 = DEFAULT_SECTOR;
        let sector3 = DEFAULT_SECTOR;
        let sector4 = DEFAULT_SECTOR;
        if (passenger.eTicket.length > 0) {
            sector1 = passenger.eTicket[0].sector.find(s => s.sectorNo === 1);
            sector2 = passenger.eTicket[0].sector.find(s => s.sectorNo === 2)
            sector3 = passenger.eTicket[0].sector.find(s => s.sectorNo === 3);
            sector4 = passenger.eTicket[0].sector.find(s => s.sectorNo === 4)
        }


        return (
            <Grid container spacing={24} direction={'column'}>
                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item spacing={24} alignItems={'center'} md={6}>
                        <Grid item md={4}>
                            First/Given Name*
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                label=''
                                variant='outlined'
                                fullWidth
                                disabled={viewOnly}
                                value={passenger.firstName}
                                onChange={e => this.onFieldChange('', 'firstName', e)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={24} alignItems={'center'} md={6}>
                        <Grid item md={4}>
                            Last/Surname*
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                label=''
                                variant='outlined'
                                fullWidth
                                disabled={viewOnly}
                                value={passenger.lastName}
                                onChange={e => this.onFieldChange('', 'lastName', e)}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item spacing={24} alignItems={'center'} md={6}>
                        <Grid item md={4}>
                            Date of birth*
                        </Grid>
                        <Grid item md={8}>
                        <TextField
                            type={'date'}
                            id=''
                            label=''
                            variant='outlined'
                            fullWidth
                            disabled={viewOnly}
                            value={toBrowserFriendlyDate(passenger.dob)}
                            onChange={e => this.onFieldChange('', 'dob', e)}
                        />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={24} alignItems={'center'} md={6}>
                        <Grid item md={4}>
                            Pnr
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                label=''
                                variant='outlined'
                                fullWidth
                                disabled={viewOnly}
                                value={passenger.pnr}
                                onChange={e => this.onFieldChange('', 'pnr', e)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item spacing={24} alignItems={'center'} md={6}>
                        <Grid item md={4}>
                            Remarks
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                label=''
                                variant='outlined'
                                fullWidth
                                disabled={viewOnly}
                                value={passenger.remarks}
                                onChange={e => this.onFieldChange('', 'remarks', e)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={24} alignItems={'center'} md={6}>
                        <Grid item md={4}>
                            Campaign Code
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                label=''
                                variant='outlined'
                                fullWidth
                                disabled={viewOnly}
                                value={passenger.customField}
                                onChange={e => this.onFieldChange('', 'customField', e)}
                            />
                        </Grid>
                    </Grid>
                    {passenger.attachmentURIs && passenger.attachmentURIs.length > 0 && (
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid container item spacing={24} alignItems={'center'} md={6}>
                                <Grid item md={4}>
                                    Attachments
                                </Grid>
                                <Grid item md={8}>
                                    <Grid item>
                                        <StyledAttachmentList
                                            attachmentUris={passenger.attachmentURIs || []}
                                            presignedAttachmentUris={passenger.presignedAttachmentUris || []}
                                            onAttachmentClick={this.onAttachmentClick}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                <Grid item>
                    <Divider />
                </Grid>

                <Grid container item spacing={24} alignItems={'flex-start'}>
                    <Grid container item spacing={24} direction={'column'} md={6}>
                        <Grid item>
                            <Typography variant={'h5'}>Sector 1</Typography>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Flight No*
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    id=''
                                    label=''
                                    variant='outlined'
                                    value={sector1.flightNo}
                                    disabled={viewOnly}
                                    fullWidth
                                    onChange={e => this.onFieldChange('sector1', 'flightNo', e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Point of Origin
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    id=''
                                    label=''
                                    variant='outlined'
                                    value={sector1.origin}
                                    disabled={viewOnly}
                                    fullWidth
                                    onChange={e => this.onFieldChange('sector1', 'origin', e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Departing Date*
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    type={'date'}
                                    id=''
                                    label=''
                                    variant='outlined'
                                    fullWidth
                                    disabled={viewOnly}
                                    value={toBrowserFriendlyDate(sector1.departureDate)}
                                    onChange={e => this.onFieldChange('sector1', 'departureDate', e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Destination*
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    id=''
                                    label=''
                                    variant='outlined'
                                    value={sector1.destination}
                                    disabled={viewOnly}
                                    fullWidth
                                    onChange={e => this.onFieldChange('sector1', 'destination', e)}
                                />
                            </Grid>
                        </Grid>
                        {!isShowing && (<Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Remark
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    multiline
                                    id=''
                                    label=''
                                    variant='outlined'
                                    fullWidth
                                    disabled={viewOnly}
                                    value={sector1.remarks}
                                    onChange={e => this.onFieldChange('sector1', 'remarks', e)}
                                />
                            </Grid>
                        </Grid>)}
                    </Grid>

                    <Grid container item spacing={24} direction={'column'} md={6}>
                        <Grid item>
                            <Typography variant={'h5'}>Sector 2</Typography>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Flight No*
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    id=''
                                    label=''
                                    variant='outlined'
                                    value={sector2.flightNo}
                                    disabled={viewOnly}
                                    fullWidth
                                    onChange={e => this.onFieldChange('sector2', 'secondFltNo', e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Point of Origin
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    id=''
                                    label=''
                                    variant='outlined'
                                    value={sector2.origin}
                                    disabled={viewOnly}
                                    fullWidth
                                    onChange={e => this.onFieldChange('sector2', 'origin', e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Departing Date*
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    type={'date'}
                                    id=''
                                    label=''
                                    variant='outlined'
                                    fullWidth
                                    disabled={viewOnly}
                                    value={toBrowserFriendlyDate(sector2.departureDate)}
                                    onChange={e => this.onFieldChange('sector2', 'departureDate', e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Destination*
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    id=''
                                    label=''
                                    variant='outlined'
                                    value={sector2.destination}
                                    disabled={viewOnly}
                                    fullWidth
                                    onChange={e => this.onFieldChange('sector2', 'destination', e)}
                                />
                            </Grid>
                        </Grid>
                        {!isShowing &&(<Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Land/Sea/Scoot
                            </Grid>
                            <Grid item md={8}>
                                <RadioGroup row value={sector2.transportMean} onChange={e => this.onFieldChange('sector2', 'transportMean', e)}>
                                    <FormControlLabel value={'na'} control={<Radio color={'secondary'} disabled={viewOnly} />} label={'NA'} />
                                    <FormControlLabel value={'sea'} control={<Radio color={'secondary'} disabled={viewOnly} />} label={'Sea'} />
                                    <FormControlLabel value={'land'} control={<Radio color={'secondary'} disabled={viewOnly} />} label={'Land'} />
                                    <FormControlLabel value={'scoot'} control={<Radio color={'secondary'} disabled={viewOnly} />} label={'Scoot'} />
                                </RadioGroup>
                            </Grid>
                        </Grid>)}

                        {sector2.transportMean !== 'na' && !isShowing && (
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={4}>
                                    Land/Sea/Scoot Remarks
                                </Grid>
                                <Grid item md={8}>
                                    <TextField
                                        multiline
                                        id=''
                                        label=''
                                        variant='outlined'
                                        fullWidth
                                        disabled={viewOnly}
                                        value={sector2.remarks}
                                        onChange={e => this.onFieldChange('sector2', 'remarks', e)}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {sector2.transportMean !== 'na' && !isShowing &&(
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={4}>
                                    Land/Sea/Scoot booking ref
                                </Grid>
                                <Grid item md={8}>
                                    <GeneralDropzone onDrop={this.onDrop} />
                                </Grid>
                            </Grid>
                        )}

                        {sector2.transportMean !== 'na' && !isShowing &&(
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={4}>
                                    {' '}
                                </Grid>
                                <Grid item md={8}>
                                    <ol>
                                        {files.map((f, i) => (
                                            <li key={'files' + i}>
                                                <Grid container item spacing={24} alignItems={'center'}>
                                                    <Grid item>{f.name}</Grid>
                                                    <Grid item>
                                                        <RemoveCircle
                                                            className={classes.pointer}
                                                            color={'secondary'}
                                                            onClick={() => this.onRemoveCircleClick(f, i)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </li>
                                        ))}
                                    </ol>
                                </Grid>
                            </Grid>
                        )}

                        {sector2.transportMean !== 'na' && sector2.attachmentUris && sector2.attachmentUris.length > 0 && !isShowing &&(
                            <Grid item>
                                <StyledAttachmentList
                                    attachmentUris={sector2.attachmentUris}
                                    presignedAttachmentUris={sector2.presignedAttachmentUris}
                                    onAttachmentClick={this.onAttachmentClick}
                                    deletedOriginalAttachmentIdx={deletedOriginalAttachmentIdx}
                                    onDeleteOrigAttachment={this.onDeleteOrigAttachment}
                                    onUndoDeleteOrigAttachment={this.onUndoDeleteOrigAttachment}
                                />
                            </Grid>
                        )}
                    </Grid>
                {/* ===================================================================================== */}
                {isShowing && (<Grid container item spacing={24} direction={'column'} md={6}>
                        <Grid item>
                            <Typography variant={'h5'}>Sector 3</Typography>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Flight No
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    id=''
                                    label=''
                                    variant='outlined'
                                    value={ (sector3 !== null && sector3 !== undefined) ? sector3.flightNo : '' }
                                    disabled={viewOnly}
                                    fullWidth
                                    onChange={e => this.onFieldChange('sector3', 'flightNo', e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Point of Origin
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    id=''
                                    label=''
                                    variant='outlined'
                                    value={ (sector3 !== null && sector3 !== undefined) ? sector3.origin : '' }
                                    disabled={viewOnly}
                                    fullWidth
                                    onChange={e => this.onFieldChange('sector3', 'origin', e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Departing Date
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    type={'date'}
                                    id=''
                                    label=''
                                    variant='outlined'
                                    fullWidth
                                    disabled={viewOnly}
                                    value={ (sector3 !== null && sector3 !== undefined) ? toBrowserFriendlyDate(sector3.departureDate) : '' }
                                    onChange={e => this.onFieldChange('sector3', 'departureDate', e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Destination
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    id=''
                                    label=''
                                    variant='outlined'
                                    value={ (sector3 !== null && sector3 !== undefined) ? sector3.destination : '' }
                                    disabled={viewOnly}
                                    fullWidth
                                    onChange={e => this.onFieldChange('sector3', 'destination', e)}
                                />
                            </Grid>
                        </Grid>
                        {!isShowing && (<Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Remark
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    multiline
                                    id=''
                                    label=''
                                    variant='outlined'
                                    fullWidth
                                    disabled={viewOnly}
                                    value={ (sector3 !== null && sector3 !== undefined) ? sector3.remarks : '' }
                                    onChange={e => this.onFieldChange('sector3', 'remarks', e)}
                                />
                            </Grid>
                        </Grid>)}
                    </Grid>)}

                {/* ================================================================================== */}
                {isShowing && (<Grid container item spacing={24} direction={'column'} md={6}>
                        <Grid item>
                            <Typography variant={'h5'}>Sector 4</Typography>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Flight No
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    id=''
                                    label=''
                                    variant='outlined'
                                    value={ (sector4 !== null && sector4 !== undefined) ? sector4.flightNo : '' }
                                    disabled={viewOnly}
                                    fullWidth
                                    onChange={e => this.onFieldChange('sector2', 'secondFltNo', e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Point of Origin
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    id=''
                                    label=''
                                    variant='outlined'
                                    value={ (sector4 !== null && sector4 !== undefined) ? sector4.origin : '' }
                                    disabled={viewOnly}
                                    fullWidth
                                    onChange={e => this.onFieldChange('sector4', 'origin', e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Departing Date
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    type={'date'}
                                    id=''
                                    label=''
                                    variant='outlined'
                                    fullWidth
                                    disabled={viewOnly}
                                    value={ (sector4 !== null && sector4 !== undefined) ? toBrowserFriendlyDate(sector4.departureDate) : '' }
                                    onChange={e => this.onFieldChange('sector4', 'departureDate', e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Destination
                            </Grid>
                            <Grid item md={8}>
                                <TextField
                                    id=''
                                    label=''
                                    variant='outlined'
                                    value={ (sector4 !== null && sector4 !== undefined) ? sector4.destination : '' }
                                    disabled={viewOnly}
                                    fullWidth
                                    onChange={e => this.onFieldChange('sector4', 'destination', e)}
                                />
                            </Grid>
                        </Grid>
                        {!isShowing && (<Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={4}>
                                Land/Sea/Scoot
                            </Grid>
                            <Grid item md={8}>
                                <RadioGroup row 
                                    value={ (sector4 !== null && sector4 !== undefined) ? sector4.transportMean : '' }
                                    onChange={e => this.onFieldChange('sector4', 'transportMean', e)}>
                                    <FormControlLabel value={'na'} control={<Radio color={'secondary'} disabled={viewOnly} />} label={'NA'} />
                                    <FormControlLabel value={'sea'} control={<Radio color={'secondary'} disabled={viewOnly} />} label={'Sea'} />
                                    <FormControlLabel value={'land'} control={<Radio color={'secondary'} disabled={viewOnly} />} label={'Land'} />
                                    <FormControlLabel value={'scoot'} control={<Radio color={'secondary'} disabled={viewOnly} />} label={'Scoot'} />
                                </RadioGroup>
                            </Grid>
                        </Grid>)}

                        {sector4 !== null && sector4 !== undefined && sector4.transportMean !== 'na' && !isShowing &&(
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={4}>
                                    Land/Sea/Scoot Remarks
                                </Grid>
                                <Grid item md={8}>
                                    <TextField
                                        multiline
                                        id=''
                                        label=''
                                        variant='outlined'
                                        fullWidth
                                        disabled={viewOnly}
                                        value={ (sector4 !== null && sector4 !== undefined) ? sector4.remarks : '' }
                                        onChange={e => this.onFieldChange('sector4', 'remarks', e)}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {sector4 !== null && sector4 !== undefined && sector4.transportMean !== 'na' && !isShowing &&(
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={4}>
                                    Land/Sea/Scoot booking ref
                                </Grid>
                                <Grid item md={8}>
                                    <GeneralDropzone onDrop={this.onDrop} />
                                </Grid>
                            </Grid>
                        )}

                        {sector4 !== null && sector4 !== undefined && sector4.transportMean !== 'na' && !isShowing &&(
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={4}>
                                    {' '}
                                </Grid>
                                <Grid item md={8}>
                                    <ol>
                                        {files.map((f, i) => (
                                            <li key={'files' + i}>
                                                <Grid container item spacing={24} alignItems={'center'}>
                                                    <Grid item>{f.name}</Grid>
                                                    <Grid item>
                                                        <RemoveCircle
                                                            className={classes.pointer}
                                                            color={'secondary'}
                                                            onClick={() => this.onRemoveCircleClick(f, i)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </li>
                                        ))}
                                    </ol>
                                </Grid>
                            </Grid>
                        )}

                        {sector4 !== null && sector4 !== undefined && sector4.transportMean !== 'na' && sector4.attachmentUris && sector4.attachmentUris.length > 0 && !isShowing &&(
                            <Grid item>
                                <StyledAttachmentList
                                    attachmentUris={sector4.attachmentUris}
                                    presignedAttachmentUris={sector4.presignedAttachmentUris}
                                    onAttachmentClick={this.onAttachmentClick}
                                    deletedOriginalAttachmentIdx={deletedOriginalAttachmentIdx}
                                    onDeleteOrigAttachment={this.onDeleteOrigAttachment}
                                    onUndoDeleteOrigAttachment={this.onUndoDeleteOrigAttachment}
                                />
                            </Grid>
                        )}
                    </Grid>)}


                {/* ======================================================================================== */}
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    container: { ...theme.container },
    pointer: { cursor: 'pointer' }
});


PassengerDetails.propTypes = {
    viewOnly: PropTypes.bool.isRequired,
    passenger: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired,
    deletedOriginalAttachmentIdx: PropTypes.number.isRequired,
    onFieldChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(PassengerDetails)