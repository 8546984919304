import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withStyles,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Checkbox,
    Paper,
    Button,
    TextField
} from '@material-ui/core';
import StyledEditBtn from '../../../components/StyledEditBtn';
import StyledDeleteBtn from '../../../components/StyledDeleteBtn';
import StyledUndoBtn from '../../../components/StyledUndoBtn';
import SqApiCriteriasDialog from '../../../components/SqApiCriteriasDialog';
import StyledTinyFabCheckCircle from '../../../components/StyledTinyFabCheckCircle';
import StyledTinyFabError from '../../../components/StyledTinyFabError';
import {
    updateCollectingStatus,
    updateSinglePax,
    updateGroupRedemptionFormDuck
} from '../../../ducks/groupRedemptionFormDuck';
import LoadingContainer from '../../loadingContainer';
import { toHumanFriendlyDate } from '../../../helpers/utilities';
import {
    convertSqStatusForDisplay,
    DISPLAY_YES,
    DISPLAY_MANUAL
} from '../../../helpers/eticketStatusHelper';

let eTicket = [];

class SimplePassengersTable extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            criterias: {},
            criteriasMessage: '',
            viewCriterias: false,
            editingIdx: null,
            physicalEditingIdx: {}
        };
    }

    _renderEticketIssues = (idx, p) => {
        if (!p.meta) return <p>Error</p>;
        const issue = p.meta['eTicket' + idx + 'Status'].issue;
        if (issue) {
            return (
                <Button
                    color={'primary'}
                    variant='contained'
                    onClick={this.onEticketIssueClicked(issue)}
                >
                    <StyledTinyFabError />
                </Button>
            );
        }
    };
    _renderEticketSqCriteria = targetETicket => {
        const {
            classes,
            groupRedemptionFormDuck: { groupSqApiCheckResults }
        } = this.props;

        let matchedTicket = groupSqApiCheckResults.find(
            ticketData => ticketData.ticketNumber === targetETicket
        );
        if (groupSqApiCheckResults.length > 0 && matchedTicket) {
            const ctpEligibility = matchedTicket.ctpEligibility;
            if (convertSqStatusForDisplay(ctpEligibility) === DISPLAY_YES) {
                return (
                    <StyledTinyFabCheckCircle
                        customClass={classes.icon}
                        onClick={() =>
                            this.onShowEligibilityPopup(matchedTicket)
                        }
                    />
                );
            } else if (
                convertSqStatusForDisplay(ctpEligibility) === DISPLAY_MANUAL
            ) {
                return (
                    <StyledTinyFabError
                        customClass={classes.icon}
                        yellow
                        onClick={() =>
                            this.onShowEligibilityPopup(matchedTicket)
                        }
                    />
                );
            } else {
                return (
                    <StyledTinyFabError
                        customClass={classes.icon}
                        onClick={() =>
                            this.onShowEligibilityPopup(matchedTicket)
                        }
                    />
                );
            }
        }
    };

    onShowEligibilityPopup = rs => {
        if (rs.criterias) {
            this.setState({
                viewCriterias: true,
                criterias: rs.criterias,
                criteriasMessage: ''
            });
        } else {
            rs.remarks
                ? this.setState({
                      viewCriterias: true,
                      criterias: {},
                      criteriasMessage: rs.remarks
                  })
                : this.setState({
                      viewCriterias: true,
                      criterias: {},
                      criteriasMessage: 'ETicket not found in database'
                  });
        }
    };

    onViewCriteriasClose = () => {
        this.setState({ viewCriterias: false });
    };

    onEticketIssueClicked = () => {
        // todo: show dialog
    };

    onCollectingChange = idx => {
        this.props.updateCollectingStatus(idx);
    };

    onCardEdit = (idx, p) => {
        if (p.collecting) this.setState({ editingIdx: idx });
    };

    onPreloadedVouchersCardEdit = (idx, p) => {
        if (p.collecting) this.setState({ editingIdx: idx });
    };

    onCardNoChange = (idx, e) => {
        let cardNo = e.target.value;
        if (cardNo.length > 19) {
            cardNo = cardNo.substr(0, 19);
        }
        this.props.updateSinglePax(idx - 1, 'cardNo', cardNo);
    };

    onPreloadedVouchersCardNoChange = (idx, e) => {
        let cardNo = e.target.value;
        if (cardNo.length > 19) {
            cardNo = cardNo.substr(0, 19);
        }
        this.props.updateSinglePax(idx - 1, 'preloadedVouchersCardNo', cardNo);
    };

    onCardNoKeyPress = e => {
        if (e.key === 'Enter')
            this.setState({ editingIdx: null, physicalEditingIdx: {} });
    };

    onPreloadedVouchersCardNoKeyPress = e => {
        if (e.key === 'Enter')
            this.setState({ editingIdx: null, physicalEditingIdx: {} });
    };

    onCardNoBlur = idx => {
        if (this.state.editingIdx === idx) this.setState({ editingIdx: null });
    };

    onPreloadedVouchersCardNoBlur = idx => {
        if (this.state.editingIdx === idx) this.setState({ editingIdx: null });
    };

    onPhysicalCardEdit = (row, col) => {
        this.setState({ physicalEditingIdx: { row, col } });
    };

    onPhysicalCardNoChange = (idx, voucherIdx, e) => {
        let numericValue = e.target.value.replace(/\D/g, '');

        const {
            groupRedemptionFormDuck: { passengers }
        } = this.props;
        const newPax = { ...passengers[idx] };
        if (voucherIdx === 0) {
            newPax.genPhysicalVoucherNo = numericValue;
        } else {
            newPax.lpcPhysicalVoucherNo = numericValue;
        }
        let newPassengers = [...passengers];
        newPassengers.splice(idx, 1, newPax);
        this.props.updateGroupRedemptionFormDuck('passengers', newPassengers);
    };

    onPhysicalCardNoBlur = (idx, col) => {
        const { physicalEditingIdx } = this.state;
        if (physicalEditingIdx.row === idx && physicalEditingIdx.col === col)
            this.setState({ physicalEditingIdx: {} });
    };

    _renderCell = (p, k, i) => {
        const { classes } = this.props;
        const noNeedFormat = [
            'firstName',
            'lastName',
            'pnr',
            'firstFltNo',
            'secondFltNo',
            'thirdFltNo',
            'fourthFltNo'
        ];

        if (k === 'seq') return <TableCell key={p.id + k}>{i}</TableCell>;

        if (noNeedFormat.includes(k)) {
            if (p[k] === undefined) return;
            return <TableCell key={p.id + k}>{p[k]}</TableCell>;
        }

        if (
            k === 'firstFltDepDate' ||
            k === 'secondFltDepDate' ||
            k === 'thirdFltDepDate' ||
            k === 'fourthFltDepDate'
        ) {
            return (
                <TableCell key={p.id + k}>
                    {toHumanFriendlyDate(p[k])}
                </TableCell>
            );
        }

        if (k === 'eTicket') {
            ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4'].forEach(k2 => {
                if (p[k2] !== '') eTicket.push(p[k2]);
            });
            const final = eTicket.map((v, i2) => {
                if (v) {
                    return (
                        <li key={p.id + i2} className={classes.eTicketWrap}>
                            {v} {this._renderEticketIssues(i2 + 1, p)}
                            {this._renderEticketSqCriteria(v)}
                        </li>
                    );
                }
            });
            eTicket = [];

            return (
                <TableCell key={p.id + k}>
                    <ol className={classes.ol}>{final}</ol>
                </TableCell>
            );
        }

        if (k === 'dob') {
            return (
                <TableCell key={p.id + k}>
                    {toHumanFriendlyDate(p.dob)}
                </TableCell>
            );
        }

        if (k === 'edit') {
            return (
                <TableCell key={p.id + k}>
                    <StyledEditBtn
                        disabled={p.sqApiStatus || p.sqApiStatus}
                        onClick={() => this.props.onEdit(p.id)}
                    />
                </TableCell>
            );
        }

        if (k === 'delete') {
            return (
                <TableCell key={p.id + k}>
                    {!p.markedDelete && (
                        <StyledDeleteBtn
                            disabled={p.sqApiStatus}
                            onClick={() => this.props.onDelete(p.id)}
                        />
                    )}

                    {p.markedDelete && (
                        <StyledUndoBtn
                            disabled={p.sqApiStatus}
                            onClick={() => this.props.onUndo(p.id)}
                        />
                    )}
                </TableCell>
            );
        }

        if (k === 'cardNo') {
            const { editingIdx } = this.state;
            return (
                <TableCell key={p.id + k}>
                    {editingIdx !== i && (
                        <span onClick={() => this.onCardEdit(i, p)}>
                            {p.cardNo}
                        </span>
                    )}
                    {editingIdx === i && (
                        <TextField
                            id=''
                            label=''
                            value={p.cardNo}
                            onChange={e => this.onCardNoChange(i, e)}
                            autoFocus
                            InputProps={{ className: classes.textFieldInput }}
                            onKeyPress={this.onCardNoKeyPress}
                            onBlur={() => this.onCardNoBlur(i)}
                            disabled={!p.collecting}
                            type='number'
                        />
                    )}
                </TableCell>
            );
        }

        if (k === 'preloadedVouchersCardNo') {
            const { editingIdx } = this.state;
            return (
                <TableCell key={p.id + k}>
                    {editingIdx !== i && (
                        <span
                            onClick={() =>
                                this.onPreloadedVouchersCardEdit(i, p)
                            }
                        >
                            {p.preloadedVouchersCardNo}
                        </span>
                    )}
                    {editingIdx === i && (
                        <TextField
                            id=''
                            label=''
                            value={p.preloadedVouchersCardNo}
                            onChange={e =>
                                this.onPreloadedVouchersCardNoChange(i, e)
                            }
                            autoFocus
                            InputProps={{ className: classes.textFieldInput }}
                            onKeyPress={this.onPreloadedVouchersCardNoKeyPress}
                            onBlur={() => this.onPreloadedVouchersCardNoBlur(i)}
                            disabled={!p.collecting}
                            type='number'
                        />
                    )}
                </TableCell>
            );
        }

        if (k === 'creationTime') {
            return (
                <TableCell key={p.id + k}>
                    {toHumanFriendlyDate(p[k])}
                </TableCell>
            );
        }

        const { physicalEditingIdx } = this.state;
        let idx = i - 1;
        if (k === 'genCardNo') {
            return (
                <TableCell key={p.id + k}>
                    {(physicalEditingIdx.row !== idx ||
                        physicalEditingIdx.col !== 0) && (
                        <span onClick={() => this.onPhysicalCardEdit(idx, 0)}>
                            {p.genPhysicalVoucherNo}
                        </span>
                    )}
                    {physicalEditingIdx.row === idx &&
                        physicalEditingIdx.col === 0 && (
                            <TextField
                                id=''
                                label=''
                                value={p.genPhysicalVoucherNo}
                                onChange={e =>
                                    this.onPhysicalCardNoChange(idx, 0, e)
                                }
                                autoFocus
                                InputProps={{
                                    className: classes.textFieldInput
                                }}
                                onKeyPress={this.onCardNoKeyPress}
                                onBlur={() => this.onPhysicalCardNoBlur(idx, 0)}
                                disabled={!p.collecting}
                            />
                        )}
                </TableCell>
            );
        }

        if (k === 'lpcCardNo') {
            return (
                <TableCell key={p.id + k}>
                    {(physicalEditingIdx.row !== idx ||
                        physicalEditingIdx.col !== 1) && (
                        <span onClick={() => this.onPhysicalCardEdit(idx, 1)}>
                            {p.lpcPhysicalVoucherNo}
                        </span>
                    )}
                    {physicalEditingIdx.row === idx &&
                        physicalEditingIdx.col === 1 && (
                            <TextField
                                id=''
                                label=''
                                value={p.lpcPhysicalVoucherNo}
                                onChange={e =>
                                    this.onPhysicalCardNoChange(idx, 1, e)
                                }
                                autoFocus
                                InputProps={{
                                    className: classes.textFieldInput
                                }}
                                onKeyPress={this.onCardNoKeyPress}
                                onBlur={() => this.onPhysicalCardNoBlur(idx, 1)}
                                disabled={!p.collecting}
                            />
                        )}
                </TableCell>
            );
        }

        if (k === 'collecting') {
            return (
                <TableCell key={p.id + k}>
                    <Checkbox
                        checked={p.collecting}
                        color={'primary'}
                        onChange={() => this.onCollectingChange(i - 1)}
                    />
                </TableCell>
            );
        }

        return null;
    };

    render() {
        const {
            classes,
            data,
            headers,
            dataKeys,
            groupRedemptionFormDuck: { groupSqApiCheckIsLoading }
        } = this.props;
        const { criterias, criteriasMessage, viewCriterias } = this.state;

        return (
            <Grid item>
                <SqApiCriteriasDialog
                    criterias={criterias}
                    criteriasMessage={criteriasMessage}
                    onViewCriteriasClose={this.onViewCriteriasClose}
                    viewCriterias={viewCriterias}
                />
                <Paper className={classes.paper + " search-result-element"}>
                    <Table padding={'dense'}>
                        <TableHead>
                            <TableRow hover>
                                {headers.map(v => (
                                    <TableCell key={v}>{v}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((p, i) => (
                                <TableRow key={p.id}>
                                    {dataKeys.map(k =>
                                        this._renderCell(p, k, i + 1)
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <LoadingContainer show={groupSqApiCheckIsLoading} />
                </Paper>
            </Grid>
        );
    }
}

const styles = theme => ({
    ol: { padding: '0 1rem' },
    // eTicketWrap: { position: 'relative' },
    icon: { top: '-.3rem', right: '-1.5rem' },
    // paper: { position: 'relative', width: 1800 },
    textFieldInput: { fontSize: '0.8125rem' }
});

const mapStateToProps = state => {
    const { groupRedemptionFormDuck } = state;

    return { groupRedemptionFormDuck };
};

const mapDispatchToProps = dispatch => {
    return {
        updateCollectingStatus: (idx, status) =>
            dispatch(updateCollectingStatus(idx, status)),
        updateSinglePax: (idx, key, status) =>
            dispatch(updateSinglePax(idx, key, status)),
        updateGroupRedemptionFormDuck: (k, v) =>
            dispatch(updateGroupRedemptionFormDuck(k, v))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(SimplePassengersTable));
