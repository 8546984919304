export const OTHER_ACCEPTED_AIRLINE = "OTHER_ACCEPTED_AIRLINE";
export const OTHER_UNACCEPTED_AIRLINE = "OTHER_UNACCEPTED_AIRLINE";
export const SQAPI_PASS = "SQAPI_PASS";
export const SQAPI_MULTIPLE_ELIGIBLE = "SQAPI_MULTIPLE_ELIGIBLE";
export const SQAPI_FAIL = "SQAPI_FAIL";
export const SQAPI_OFFLINE = "SQAPI_OFFLINE";

export const DISPLAY_MANUAL = "M"
export const DISPLAY_YES = "Y"
export const DISPLAY_NO = "N"

export const convertSqStatusForDisplay = (status) => {
    let result = DISPLAY_NO;
    if (status === OTHER_ACCEPTED_AIRLINE || status === SQAPI_OFFLINE || SQAPI_MULTIPLE_ELIGIBLE) result = DISPLAY_MANUAL;
    if (status === OTHER_UNACCEPTED_AIRLINE || status === SQAPI_FAIL) result = DISPLAY_NO;
    if (status === SQAPI_PASS) result = DISPLAY_YES;

    if (status === "M" || status === "Manual") result = DISPLAY_MANUAL;
    if (status === "N" || status === "No") result = DISPLAY_NO;
    if (status === "Y" || status === "Yes") result = DISPLAY_YES;

    return result
}