import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withStyles, Grid, Button} from '@material-ui/core'
import Header from '../containers/Header.js'
import AdminSettingsNav from '../containers/adminSettings/AdminSettingsNav'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import StyledSection from '../components/StyledSection'
import StyledTable from '../components/StyledTable'
import GlobalTimeChecker from "../containers/GlobalTimeChecker";

const EMAIL_TABLE_FIELDS = [
    {key: 'id', label: 'S/N'},
    {key: 'templateName', label: 'Email Template Name'}
]

const DATA = [
    {id: 1, templateName: 'Not within campaign period'},
    {id: 2, templateName: 'Not within campaign period'}
]

class EmailTemplate extends Component {

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Admin settings - Email Template`
    }

    onCreate = () => {

    }

    render() {
        const {classes} = this.props

        return (
            <div>
                <GlobalTimeChecker/>

                <Header history={this.props.history}/>
                <AdminSettingsNav/>

                <div className={classes.container}>
                    <StyledSection title={'Email template'}>
                        <StyledTable
                            fields={EMAIL_TABLE_FIELDS}
                            rows={DATA}
                            isViewable
                            isEditable
                            isDeletable
                        />
                        <Grid item>
                            <Button color={'primary'} variant="contained" onClick={this.onCreate}>Create</Button>
                        </Grid>
                    </StyledSection>

                </div>
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

export default connect(null, null)(withStyles(styles)(EmailTemplate))