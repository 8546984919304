import React from 'react'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'
import {Button, withStyles} from '@material-ui/core'
import {convertToExcelExportFormat} from '../helpers/excelDataHelper'

const sampleDataList = [
    {name: 'Peter', surname: 'Parker'},
    {name: 'Mary', surname: 'Jane'}
]

const sampleHeaders = [
    {key: 'name', label: 'First Name'},
    {key: 'surname', label: 'Last Name'}
]

class SpreadSheetComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [['a', 'b'], [1, 2], [3, 4]],
            col: [{name: 'sydney', surname: 'wu'}]
        }
    }

    exportFile = () => {
        const {records, headers} = this.props

        let formattedExcelData = convertToExcelExportFormat({records, headers})
        const ws = XLSX.utils.aoa_to_sheet(formattedExcelData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'records')

        XLSX.writeFile(wb, 'ctp-export.xlsx')
    }

    render() {
        return (
            <Button
                color={'primary'}
                variant="contained"
                onClick={this.exportFile}
            >
                Export
            </Button>
        )
    }

}

const styles = {}

export default withStyles(styles)(SpreadSheetComponent)