export const SYSTEM_ADMIN_PERMISSION = "System.Administration";
export const INDIVIDUAL_ISSUER_PERMISSION = "Individual.Issuer";
export const INDIVIDUAL_EDITOR_PERMISSION = "Individual.Editor";
// export const INDIVIDUAL_CREATOR_PERMISSION = "Individual.Creator";
export const GROUP_ISSUER_PERMISSION = "Group.Issuer";
export const GROUP_ISSUER_READ_ONLY_PERMISSION = "Group.IssuerReadOnly";
export const GROUP_CREATOR_PERMISSION = "Group.Creator";
export const GROUP_CREATOR_WITH_READ_ALL_PERMISSION = "Group.CreatorWithReadAll";
export const GROUP_APPROVER_PERMISSION = "Group.Approver";
export const GROUP_APPROVER_READ_ONLY_PERMISSION = "Group.ApproverReadOnly";
export const USER_ADMIN_CAMPAIGN_READ_PERMISSION = "User_Admin.CampaignRead";
export const USER_ADMIN_CAMPAIGN_CREATE_PERMISSION = "User_Admin.CampaignCreate";
export const USER_ADMIN_CAMPAIGN_EDIT_PERMISSION = "User_Admin.CampaignEdit";
export const USER_ADMIN_ACCOUNT_READ_PERMISSION = "User_Admin.AccountRead";
export const USER_ADMIN_ACCOUNT_CREATE_PERMISSION = "User_Admin.AccountCreate";
export const USER_ADMIN_ACCOUNT_EDIT_PERMISSION = "User_Admin.AccountEdit";
export const USER_ADMIN_ROLE_READ_PERMISSION = "User_Admin.RoleRead";
export const USER_ADMIN_ROLE_CREATE_PERMISSION = "User_Admin.RoleCreate";
export const USER_ADMIN_ROLE_EDIT_PERMISSION = "User_Admin.RoleEdit";
export const USER_ADMIN_BANNER_READ_PERMISSION = "User_Admin.BannerRead";
export const USER_ADMIN_BANNER_CREATE_PERMISSION = "User_Admin.BannerCreate";
export const USER_ADMIN_BANNER_EDIT_PERMISSION = "User_Admin.BannerEdit";
export const SEARCH_PASSENGER_PERMISSION = "Search.Passenger";
export const SEARCH_GROUP_PERMISSION = "Search.Group";
export const GROUP_VIEW_PERMISSION = "Group.View";
export const VOUCHER_TRANSFER_EV = "VoucherManagement.TransferEV";
export const APPEAL_EDITOR = "Appeal.Editor";
export const APPEAL_APPROVER = "Appeal.Approver";
export const APPEAL_SUPER_APPROVER = "Appeal.SuperApprover";
