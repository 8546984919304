import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withStyles, Grid, Button, TextField, Typography} from '@material-ui/core'
import Header from '../containers/Header.js'
import AdminSettingsNav from '../containers/adminSettings/AdminSettingsNav'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import StyledSection from '../components/StyledSection'
import BannerTable from '../components/BannerTable'
import {getAllBanner, addBanner, editBanner} from '../ducks/bannerDuck'
import StyledSnackbar from '../components/StyledSnackbar'
import GeneralDropzone from '../components/generalForm/GeneralDropzone'
import {REQUIRED_ERROR_MSG} from '../constants/messages'
import {hasPermissions} from '../helpers/utilities'
import {
    USER_ADMIN_BANNER_READ_PERMISSION, USER_ADMIN_BANNER_CREATE_PERMISSION, USER_ADMIN_BANNER_EDIT_PERMISSION
} from '../constants/permissions'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'

class BannerScreen extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            selectedFile: '',
            selectedFileError: '',
            bannerName: '',
            bannerNameError: '',
            bannerSortPriority: ''
        }
    }

    componentDidMount = async () => {
        document.title = `${TITLE_PREFIX} Admin settings - Banner`
    }

    componentDidUpdate(prevProps, prevState) {
        const {bannerDuck: {getAllBannerIsLoading}} = this.props
        let banners = [{id: 0, name: '', uri: '', order: 0, sortPriority: 1}]
        if (prevProps.bannerDuck.getAllBannerIsLoading === true && getAllBannerIsLoading === false) {
            const bannerSortPriority = banners[banners.length - 1].sortPriority + 1
            this.setState({bannerSortPriority})
        }
    }

    validateSubmit = () => {
        const {bannerName, selectedFile} = this.state
        let rs = true
        if (bannerName === '') {
            this.setState({bannerNameError: REQUIRED_ERROR_MSG})
            rs = false
        } else {
            this.setState({bannerNameError: ''})
        }

        if (typeof selectedFile !== 'object') {
            this.setState({selectedFileError: REQUIRED_ERROR_MSG})
            rs = false
        } else {
            this.setState({selectedFileError: ''})
        }
        return rs
    }

    onSubmit = async () => {
        if (!this.validateSubmit()) return

        const formData = new FormData()
        const {selectedFile} = this.state
        formData.append('file', selectedFile, selectedFile.name)

        const {bannerName, bannerSortPriority} = this.state
        const params = {name: bannerName, sortPriority: bannerSortPriority}
        const rs = await this.props.addBanner(params, formData)
        this.props.getAllBanner()
        rs.success
            ? this.setState({
                showSnackbar: true,
                snackbarVariant: 'success',
                snackbarMessage: 'Banner uploaded',
                selectedFile: '',
                bannerName: '',
                bannerSortPriority: this.state.bannerSortPriority + 1
            })
            : this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: rs.message,
                selectedFile: ''
            })

    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length === 1) {
            this.setState({selectedFile: acceptedFiles[0]})
        } else {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Please upload one file at a time'})
        }
        if (rejectedFiles.length > 0) {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: `${rejectedFiles[0].name} is not valid. Please upload only images`})
        }
    }

    onUpdate = async () => {
        const rs = await this.props.editBanner()
        if (rs.success) {
            this.setState({showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'Banners updated'})
            this.props.getAllBanner()
        } else {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        }
    }

    onUploadFieldsChange = (k, e) => {
        if (k === 'name') this.setState({bannerName: e.target.value})
        if (k === 'order') this.setState({bannerSortPriority: e.target.value})
    }

    render() {
        const {classes, bannerDuck: {getAllBannerIsLoading, addBannerIsLoading, banners}} = this.props
        const {bannerNameError, selectedFileError} = this.state

        const hasEditPermission = hasPermissions([USER_ADMIN_BANNER_EDIT_PERMISSION])
        const hasCreatePermission = hasPermissions([USER_ADMIN_BANNER_CREATE_PERMISSION])
        const hasReadPermission = hasPermissions([USER_ADMIN_BANNER_READ_PERMISSION]) || hasEditPermission || hasCreatePermission

        return (
            <div>
                <GlobalTimeChecker/>

                <Header history={this.props.history}/>
                <AdminSettingsNav/>

                <div className={classes.container}>
                    {hasReadPermission && <Grid container spacing={24} direction={'column'}>
                        <Grid item>
                            <StyledSection title={'Banners'} isLoading={getAllBannerIsLoading}>
                                <Grid container item spacing={24} direction={'column'}>
                                    <Grid item>
                                        <BannerTable/>
                                    </Grid>
                                    {hasEditPermission && banners.length>0 && banners[0].uri && <Grid item>
                                        <Button color={'primary'} variant="contained"
                                            onClick={this.onUpdate}>Update</Button>
                                    </Grid>}
                                </Grid>
                            </StyledSection>
                        </Grid>

                        {hasCreatePermission && <Grid item>
                            <StyledSection title={'Add Banner'} isLoading={addBannerIsLoading}>
                                <Grid container spacing={24} direction={'column'}>
                                    <Grid container item spacing={24}>
                                        <Grid item xs={3}>
                                            <TextField id="bannerName" label="Banner Name" margin="normal"
                                                variant="outlined" onChange={e => this.onUploadFieldsChange('name', e)}
                                                fullWidth error={!!bannerNameError} helperText={bannerNameError}/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField id="sortPriority" label="Order" margin="normal"
                                                variant="outlined" onChange={e => this.onUploadFieldsChange('order', e)}
                                                fullWidth/>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Grid item>
                                            <GeneralDropzone onDrop={this.onDrop} accept={'image/*'}/>
                                            {this.state.selectedFile.name && (
                                                <Typography color="default">
                                                    {this.state.selectedFile.name}
                                                </Typography>
                                            )}
                                            {selectedFileError && <Typography variant={'body1'}
                                                color={'secondary'}>{selectedFileError}</Typography>}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button color={'primary'} variant="contained"
                                            onClick={this.onSubmit}>Submit</Button>
                                    </Grid>
                                </Grid>
                            </StyledSection>
                            <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                                message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
                        </Grid>}
                    </Grid>}
                </div>
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

const mapStateToProps = state => {
    const {bannerDuck} = state

    return {bannerDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        getAllBanner: () => dispatch(getAllBanner()),
        addBanner: (params, formData) => dispatch(addBanner(params, formData)),
        editBanner: () => dispatch(editBanner())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BannerScreen))