import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import {Typography, Button, MenuItem, TextField, Radio} from '@material-ui/core';
import Select from 'react-select'
import {labelToObject} from '../../helpers/reactSelectHelper'
import GeneralTextDate from './GeneralTextDate'

class GeneralFormContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formInput: {}
        }
    }

    _handleTextChange = (key, event) => {
        let formInput = {...this.state.formInput};
        let value = event.target.value;
        formInput[key] = value;
        this.setState({formInput: formInput});
        this.props.onFieldChange(key, value)
    };

    _handleFileChange = (key, event) => {
        let formInput = {...this.state.formInput};
        let value = event.target.files;
        formInput[key] = value;
        this.setState({formInput: formInput});
        this.props.onFieldChange(key, value)
    }

    _handleSelectChange = (key, event) => {
        let value = event.target.value;
        let formInput = {...this.state.formInput};
        formInput[key] = value;
        this.setState({formInput: formInput});
        this.props.onFieldChange(key, value)
    };

    _handleRadioClick = (key, value) => {
        let formInput = {...this.state.formInput};
        formInput[key] = value;
        this.setState({formInput: formInput});
        this.props.onFieldChange(key, value)
    };

    _handleAutocompleteChange = (key,valueObj) => {
        this.props.onFieldChange(key,valueObj.label)
        if (this.props.onBlur) this.props.onBlur({key, value: valueObj.label})
    }

    _handleBlur = (key, event) =>{
        let value = event.target.value
        if (this.props.onBlur) this.props.onBlur({key, value})

    }


    _checkDisplayCondition({key,valueArr}){

        for (var i =0; i<valueArr.length; i++) {
            if (this.props.formValues[key] === valueArr[i]) return true;
        }
        return false;
    }

    _renderField(formField){
        
        let selectedValue = {}
        if (this.props.formValues[formField.key] && this.props.formValues[formField.key].label === undefined) {
            // change format to {label: '', value: null} if its just a string
            selectedValue = labelToObject(this.props.formValues[formField.key])
        } else {
            selectedValue = this.props.formValues[formField.key]
        }

        
        return (

            <div style={Object.assign({},
                styles.container.formContainer.row.styles,
                {width: `${100 / this.props.columns}%`}
            )} key={formField.key}>

                <div style={styles.container.formContainer.row.inputRow.styles}>
                    <div
                        style={styles.container.formContainer.row.inputRow.labelWrapper.styles}>
                        {formField.type !== 'hidden' && <Typography
                            variant="subtitle1"
                            style={styles.container.formContainer.row.inputRow.labelWrapper.label.styles}
                        >
                            {formField.label}
                        </Typography>}
                    </div>

                    <div
                        style={styles.container.formContainer.row.inputRow.fieldWrapper.styles}>

                        {(formField.type === 'text' ||
                            formField.type === 'password' ||
                            !formField.type
                        ) && (
                            <TextField
                                id={formField.key}
                                type={formField.type}
                                onChange={(event) => this._handleTextChange(formField.key, event)}
                                style={formField.helperText ? {width: '100%', height: 60} : styles.container.formContainer.row.inputRow.fieldWrapper.input.styles}
                                value={this.props.formValues[formField.key] || ''}
                                variant="outlined"
                                error={formField.error}
                                helperText={formField.helperText}
                                onBlur={(event)=>this._handleBlur(formField.key, event)}
                                disabled={formField.disabled || false}
                            />
                        )}

                        {( formField.type === "file" && <TextField
                                id={formField.key}
                                type={formField.type}
                                onChange={(event) => this._handleFileChange(formField.key, event)}
                                style={formField.helperText ? {width: '100%', height: 60} : styles.container.formContainer.row.inputRow.fieldWrapper.input.styles}
                                variant="outlined"
                                helperText={formField.helperText}
                                onBlur={(event)=>this._handleBlur(formField.key, event)}
                                disabled={formField.disabled || false}
                            />
                        )}

                        {formField.type === "date" &&
                            <TextField
                                id={formField.key}
                                type={formField.type}
                                onChange={(event) => this._handleTextChange(formField.key, event)}
                                style={formField.helperText ? {width: '100%', height: 60} : styles.container.formContainer.row.inputRow.fieldWrapper.input.styles}
                                value={ this.props.formValues[formField.key] !== undefined ? moment(this.props.formValues[formField.key]).format('YYYY-MM-DD') : ''}
                                variant="outlined"
                                helperText={formField.helperText}
                                onBlur={(event)=>this._handleBlur(formField.key, event)}
                                disabled={formField.disabled || false}
                            />
                        }

                        {formField.type === "general-text-date" &&
                        <GeneralTextDate
                            id={formField.key}
                            onChange={(event) => this._handleTextChange(formField.key, event)}
                            style={formField.helperText ? {width: '100%', height: 60} : styles.container.formContainer.row.inputRow.fieldWrapper.input.styles}
                            value={ this.props.formValues[formField.key] !== undefined ? moment(this.props.formValues[formField.key]).format('YYYY-MM-DD') : ''}
                            variant="outlined"
                            helperText={formField.helperText}
                            onBlur={(event)=>this._handleBlur(formField.key, event)}
                            disabled={formField.disabled || false}
                        />
                        }
                        
                        {(formField.type === 'multiline') && (
                            <div>
                                <TextField
                                    id={formField.key}
                                    type={'text'}
                                    onChange={(event) => this._handleTextChange(formField.key, event)}
                                    value={this.props.formValues[formField.key]}
                                    style={styles.container.formContainer.row.inputRow.fieldWrapper.multiline.styles}
                                    variant="outlined"
                                    multiline
                                    rows={formField.multilineRows}
                                    onBlur={(event)=>this._handleBlur(formField.key, event)}
                                    disabled={formField.disabled || false}
                                />
                            </div>
                        )}

                        {formField.type === 'select' && (
                            <TextField
                                id={formField.key}
                                select
                                value={this.props.formValues[formField.key]}
                                onChange={(event) => this._handleSelectChange(formField.key, event)}
                                onBlur={(event)=>this._handleBlur(formField.key, event)}
                                disabled={formField.disabled || false}
                            >
                                {formField.options.map(option => (
                                    <MenuItem value={option} key={option}>{option}</MenuItem>
                                ))}
                            </TextField>
                        )}

                        {formField.type === 'radio' && (
                            <div key={formField.key}>
                                {
                                    formField.options.map(option => (
                                        <div key={option.value} style={{display: 'inline-block'}}>
                                            <Radio
                                                checked={this.props.formValues[formField.key] === option.value}
                                                onChange={() => this._handleRadioClick(formField.key, option.value)}
                                                value={option.value}
                                                name="radio-button-demo"
                                                onBlur={(event)=>this._handleBlur(formField.key, event)}

                                            />
                                            <Typography
                                                style={{display: 'inline-block'}}> {option.label} </Typography>
                                        </div>
                                    ))
                                }
                            </div>
                        )}

                        {formField.type === 'autocomplete' && (
                            <div key={formField.key}>
                                <Select
                                    value={selectedValue}
                                    onChange={v => this._handleAutocompleteChange(formField.key, v)}
                                    options={formField.options}
                                    isDisabled={formField.disabled}
                                />
                            </div>
                        )}

                    </div>
                </div>

            </div>
        )
    }

    render() {
        return (
            <div style={styles.container.styles}>

                <div style={styles.container.formContainer.styles}>

                    {this.props.formFields.map(formField => {
                        if ( formField.displayCondition) {
                            if(this._checkDisplayCondition(formField.displayCondition)){
                                return this._renderField(formField);
                            } else {
                                return null
                            }

                        } else {
                            return this._renderField(formField);
                        }
                    })}


                    {this.props.showButtons && (
                        <div style={styles.container.formContainer.btnRow.styles}>
                            <div style={styles.container.formContainer.btnRow.btnWrapper.styles}>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    onClick={this._onConfirm}
                                    style={styles.container.formContainer.btnRow.btnWrapper.btn.styles}
                                >
                                    Confirm
                                </Button>
                            </div>

                            <div style={styles.container.formContainer.btnRow.btnWrapper.styles}>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    size="small"
                                    onClick={this._onCancel}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

}

const styles = {
    container: {
        styles: {
            width: '100%',
        },
        formContainer: {
            styles: {
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
            },
            row: {
                styles: {
                    width: '100%'
                },
                inputRow: {
                    styles: {
                        marginTop: 20,
                        marginLeft: 0,
                        marginRight: 60,
                        display: 'flex',
                    },
                    labelWrapper: {
                        styles: {flex: 2},
                        label: {
                            styles: {
                                textAlign: 'left',
                                marginTop: 5
                            }
                        }
                    },
                    fieldWrapper: {
                        styles: {flex: 3},
                        input: {
                            styles: {width: '100%'}
                        },
                        multiline: {
                            styles: {width: '100%'}
                        },
                        customComponent: {
                            styles: {width: '100%'}
                        }

                    }

                },
            },

            btnRow: {
                styles: {
                    width: '100%',
                    marginTop: 40,
                    display: 'flex',
                    justifyContent: 'space-between'
                },
                btnWrapper: {
                    styles: {flex: 1},
                    btn: {
                        width: 60,
                        height: 30
                    }
                }
            }

        },
    },
};

GeneralFormContainer.propTypes = {
    columns: PropTypes.number.isRequired,
    formFields: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['text', 'password', 'select', 'file', 'date', 'multiline', 'radio', 'autocomplete', 'checkbox','hidden', 'general-text-date']),
        options: PropTypes.array,
        multilineRows: PropTypes.number
    })).isRequired,
    onFieldChange: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
};

export default GeneralFormContainer;