import moment from 'moment'
import {
	GROUP_APPROVER_PERMISSION,
	GROUP_APPROVER_READ_ONLY_PERMISSION,
	GROUP_CREATOR_PERMISSION,
	GROUP_CREATOR_WITH_READ_ALL_PERMISSION,
	GROUP_ISSUER_PERMISSION,
	GROUP_ISSUER_READ_ONLY_PERMISSION,
	GROUP_VIEW_PERMISSION,
	VOUCHER_TRANSFER_EV
} from '../constants/permissions'
import axios from 'axios'
import { getSettings } from '../config/config'
import aesjs from 'aes-js'
import { PERMISSIONS } from '../constants/localStorageType'
import { checkValidStatusCode } from './httpHelper'

let settings = getSettings();
let baseURL = settings.baseURL;

export const getCurrentAuthHeader = () => {
	return { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
}

export const trueFalseToYesNo = v => {
	if (v === true) return 'yes'
	if (v === false) return 'no'
	return null
}

/**
 * Should return string of 1 and 0 where 1 correspond to Yes, and 0 to No.
 * @param criterias [array]
 */
export const generateSqResponseFromCriterias = criterias => {
	let rs = ''

	const keys = [
		'isFlightConfirmed',
		'haveTwoTouchPoint',
		'isFlightEligible',
		'isFourSector',
		'isPaxSeated',
		'isSameOrgDest',
		'isThreeSector',
		'isUsedOrFlownFlight',
		'isValidOrigin',
		'isValidTransit',
		'isWithinCampaign'
	]

	keys.forEach(k => {
		rs = criterias[k] === 'Yes' ? rs + '1' : rs + '0'
	})

	return rs.trim()
}

/**
 * Convers base64 to binary
 * https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata/5100158
 *
 * @param dataURI
 * @returns {Blob}
 */
export const dataURItoBlob = dataURI => {
	// convert base64/URLEncoded data component to raw binary data held in a string
	let byteString
	if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
	else byteString = unescape(dataURI.split(',')[1])

	// separate out the mime component
	var mimeString = dataURI
		.split(',')[0]
		.split(':')[1]
		.split(';')[0]

	// write the bytes of the string to a typed array
	var ia = new Uint8Array(byteString.length)
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i)
	}

	return new Blob([ia], { type: mimeString })
}

export const toBrowserFriendlyDate = datestring => {
	return datestring ? moment(datestring).format('YYYY-MM-DD') : null
}

export const toHumanFriendlyDate = datestring => {
	return datestring ? moment(datestring).format('DD/MM/YYYY') : null
}
export const toHumanFriendlyDateTime = datestring => {
	return datestring ? moment(datestring).format('DD/MM/YYYY HH:mm') : null
}

export const sqApiResponseStringToObject = string => {
	// order matters in components/SqApiCriteriasDialog.js and middleware
	const criteriasKeys = [
		'isFlightEligible',
		'haveTwoTouchPoint',
		'isWithinCampaign',
		'isThreeSector',
		'isFourSector',
		'isSameOrgDest',
		'isValidTransit',
		'isPaxSeated',
		'isValidOrigin',
		'isFlightConfirmed',
		'isUsedOrFlownFlight'
	]

	const rs = {}
	for (let i = 0; i < string.length; i++) {
		rs[criteriasKeys[i]] = string[i] === '1' ? 'Yes' : 'No'
	}
	return rs
}

export const sqApiObjectToResponseString = obj => {
	// order matters in components/SqApiCriteriasDialog.js and middleware
	const criteriasKeys = [
		'isFlightEligible',
		'haveTwoTouchPoint',
		'isWithinCampaign',
		'isThreeSector',
		'isFourSector',
		'isSameOrgDest',
		'isValidTransit',
		'isPaxSeated',
		'isValidOrigin',
		'isFlightConfirmed',
		'isUsedOrFlownFlight'
	]
	let rs = ''
	criteriasKeys.forEach(k => {
		if (obj[k] === 'Yes') rs += '1'
		if (obj[k] === 'No') rs += '0'
	})
	return rs
}

export const convertCtpEligibilityStatus = ctpEligibility => {
	if (ctpEligibility === 'No') return 'N'
	if (ctpEligibility === 'Yes') return 'Y'
	if (ctpEligibility === 'Manual') return 'M'
}

export const getOriginFromFltNumber = (flightNo, flightData) => {
	const rs = flightData.find(f => f.flightNo.toLowerCase() === flightNo.toLowerCase())
	if (rs) return rs.departureCityName
}

export const getDestinationFromFltNumber = (flightNo, flightData) => {
	const rs = flightData.find(f => f.flightNo === flightNo)
	if (rs) return rs.arrivalCityName
}

/**
 * Takes an array or a string to check if passed value exists in permissions in localStorage
 * `condition` determines whether all passed permissons exists ('and'),
 * or only one of it exists ('or') for result to be true.
 *
 * @param v
 * @param condition ['and' | 'or']
 * @returns {boolean}
 */
export const hasPermissions = (v, condition = 'and') => {
	if (typeof v === 'string') v = [v];
	const permissionsStored = localStorage.getItem(PERMISSIONS);
	if(!permissionsStored) return false;
	const decryptedPermissions = decrypt(permissionsStored);

    const permissions = JSON.parse(decryptedPermissions);
	if (!permissions) return false;
	if (condition === 'and') {
		let rs = true
		for (const p of v) {
			if (!permissions.includes(p)) rs = false
		}
		return rs
	}

	if (condition === 'or') {
		let rs = false
		for (const p of v) {
			if (permissions.includes(p)) return true
		}
		return rs
	}
}

export const hasCreatorWithReadAll = () => hasPermissions([GROUP_CREATOR_WITH_READ_ALL_PERMISSION])
export const hasCreatorPermissions = () => hasPermissions([GROUP_CREATOR_PERMISSION]) || hasCreatorWithReadAll()
export const hasApproverPermissions = () => hasPermissions([GROUP_APPROVER_PERMISSION]) || hasPermissions([GROUP_APPROVER_READ_ONLY_PERMISSION])
export const hasIssuerPermissions = () => hasPermissions([GROUP_ISSUER_PERMISSION]) || hasPermissions([GROUP_ISSUER_READ_ONLY_PERMISSION])
export const hasGroupViewPermission = () => hasPermissions([GROUP_VIEW_PERMISSION])
export const hasVoucherTransferPermission = () => hasPermissions([VOUCHER_TRANSFER_EV])

export const maskContactNo = contactNo => {
	let rs = ''
	for (let i = 0; i < contactNo.length; i++) {
		if (i < 3) {
			rs += contactNo[i]
			continue
		}
		if (i < contactNo.length - 4) {
			rs += 'X'
		} else {
			rs += contactNo[i]
		}
	}
	return rs
}

export const getCurrentCampaign = campaignPeriods => {
	let now = moment()
	for (let i = 0; i < campaignPeriods.length; i++) {
		if (now.isAfter(moment(campaignPeriods[i].startDate)) && now.isBefore(moment(campaignPeriods[i].endDate))) {
			return campaignPeriods[i]
		}
	}
}

export const myaxios = async (type, url, data, header) => {
	if (type === 'get') {
		const rs = await axios.get(baseURL + url, data).catch(e => {
			if (!checkValidStatusCode(e.response.status)) {
				const {
					response: {
						data: {
							error: { message }
						}
					}
				} = e
				return { data: { success: false, error: { message } } }
			}
			return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
		})
		if (!rs.data) {
			return { success: false, message: 'No data from server' }
		}
		if (rs.data.success) {
			return { success: true, message: '', data: rs.data }
		} else {
			return { success: false, message: rs.data.error.message }
		}
	}
	if (type === 'post') {
		const rs = await axios.post(baseURL + url, data, header).catch(e => {
			if (!checkValidStatusCode(e.response.status)) {
				const {
					response: {
						data: {
							error: { message }
						}
					}
				} = e
				return { data: { success: false, error: { message } } }
			}
			return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
		})
		if (!rs.data) {
			return { success: false, message: 'No data from server' }
		}
		if (rs.data.success) {
			return { success: true, message: '', data: rs.data }
		} else {
			return { success: false, message: rs.data.error.message }
		}
	}
}

export const objHasData = obj => {
	return Object.keys(obj).length > 0
}

// Takes a origObj, and newObj. Spread newObj recursively onto origObj
export const spreadObj = (origObj, newObj) => {
	const newOrig = { ...origObj }
	for (let k in newObj) {
		if (newObj.hasOwnProperty(k)) {
			if (typeof newObj[k] !== 'object') {
				newOrig[k] = newObj[k]
			} else {
				if (Array.isArray(newObj[k])) {
					newOrig[k] = [...newObj[k]]
				} else {
					newOrig[k] = { ...spreadObj(origObj[k], newObj[k]) }
				}
			}
		}
	}
	return newOrig
}

/**
 * Expects obj from auditLogDuck: entitiyChangePropertyList
 */
export const toHumanFriendlyMessage = obj => {
	switch (obj.propertyName) {
		case 'SubmittedBy':
			if (obj.originalValue === null && !!obj.newValue) {
				return `${obj.userName} has uploaded group`
			}
			break

		case 'Submitted':
			if (obj.originalValue === 'false' && obj.newValue === 'true') {
				return `${obj.entityType} (${obj.entityDisplayName}) - ${obj.userName} has submitted group for approval`
			}
			break

		case 'Approval':
			if (obj.originalValue === 'false' && obj.newValue === 'true') {
				return `${obj.userName} has approved group`
			}
			if (obj.originalValue === 'true' && obj.newValue === 'false') {
				return `${obj.userName} has unapproved group`
			}
			break

		case 'Rejected':
			if (obj.originalValue === 'false' && obj.newValue === 'true') {
				return `${obj.userName} has rejected group`
			}
			break

		case 'Redemption':
			if (obj.originalValue === 'false' && obj.newValue === 'true') {
				return `${obj.userName} has processed group redemption`
			}
			break

		case 'IsDeleted':
			if (obj.originalValue === 'false' && obj.newValue === 'true') {
				return `${obj.userName} has deleted group`
			}
			break

		case 'RefNo':
			if (obj.originalValue === null && !!obj.newValue) {
				return `${obj.userName} has started this record`
			}
			break

		case 'CreatorId':
			if (obj.originalValue === null && !!obj.newValue) {
				return `${obj.userName} has created this transaction`
			}
			break

		case 'RedeemerId':
			if (obj.originalValue === null && !!obj.newValue) {
				return `${obj.userName} has redeemed this transaction`
			}
			break

		default:
			if (obj.propertyTypeFullName === 'System.DateTime') {
				return `${obj.entityType} (${obj.entityDisplayName}) - ${obj.propertyName} change from ${obj.originalValue} to ${obj.newValue}`
			} else {
				return `${obj.entityType} (${obj.entityDisplayName}) - ${obj.propertyName} change from ${obj.originalValue} to ${obj.newValue}`
			}
	}
}

export const encrypt = string => {
	let key_256 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
	const textBytes = aesjs.utils.utf8.toBytes(string)
	let aesCtr = new aesjs.ModeOfOperation.ctr(key_256)
	const encryptedBytes = aesCtr.encrypt(textBytes)
	return aesjs.utils.hex.fromBytes(encryptedBytes)
}

export const decrypt = enString => {
	let key_256 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
	const enBytes = aesjs.utils.hex.toBytes(enString)
	let aesCtr = new aesjs.ModeOfOperation.ctr(key_256)
	const decryptedBytes = aesCtr.decrypt(enBytes)
	return aesjs.utils.utf8.fromBytes(decryptedBytes)
}

export const parseFullCardNoWithPrefix = cardNo => {
	if (cardNo && cardNo.length >= 24) {
		if (!cardNo.startsWith('0000')) return ''
		return cardNo.substr(0, 19)
	}

	return cardNo
}

export const parseFullCardNo = cardNo => {
	if (cardNo && cardNo.length >= 24) {
		if (!cardNo.startsWith('0000')) return ''
		return cardNo.substr(13, 6)
	}

	return cardNo
}
