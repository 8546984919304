import React from 'react'
import Dropzone from 'react-dropzone'
import {PRIMARY_COLOR} from "../../styles/mui/theme";

class GeneralDropzone extends React.Component {
    constructor() {
        super()
        this.state = {
            files: []
        };
    }

    render() {

        return (
            <section>
                <Dropzone onDrop={this.props.onDrop} accept={this.props.accept}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} style={styles.container.dropzone.inner.styles}>
                            <input {...getInputProps()} />
                            <p>Click to Upload or Drop files here</p>
                        </div>
                    )}
                </Dropzone>
            </section>
        );
    }
}

const styles = {
    container:{
        dropzone: {
            inner: {
                styles: {
                    width: 300,
                    height: 300,
                    borderColor: PRIMARY_COLOR,
                    borderStyle: 'dotted',
                    borderWidth: 2,
                    borderRadius: 10,
                    textAlign: 'center'
                }
            }
        }
    }
}

export default GeneralDropzone