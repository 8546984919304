import React, {Component} from 'react'
import {connect} from 'react-redux'
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select, withStyles} from '@material-ui/core'
import {hoverSelectOverride} from '../styles/mui/theme'
import {MuiThemeProvider} from '@material-ui/core/styles'
import {
    GROUP_TYPE_PENDING_APPROVAL, GROUP_TYPE_COLLECTED,
    GROUP_TYPE_PENDING_COLLECTION, GROUP_TYPE_NOT_COLLECTED,
    GROUP_TYPE_REJECTED, GROUP_TYPE_ACTION_REQUIRED, GROUP_TYPE_VIEW_ALL, GROUP_TYPE_PENDING_SQ_API,
    GROUP_TYPE_APPROVED
} from '../constants/groupType'
import {getAllGroup} from '../ducks/groupApprovalDuck'
import {hasGroupViewPermission} from '../helpers/utilities'
import {
    hasCreatorPermissions, hasApproverPermissions, hasIssuerPermissions
} from '../helpers/utilities'
import StyledSnackbar from '../components/StyledSnackbar'
import {STATUS_PENDING_SQ_API, STATUS_ACTION_REQUIRED, STATUS_PENDING_APPROVAL,
    STATUS_APPROVED, STATUS_PENDING_COLLECTION, STATUS_REJECTED, STATUS_COLLECTED,
    STATUS_NOT_COLLECTED, STATUS_VIEW_ALL
} from "../constants/groupStatus"

class GroupStatusFilter extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success'
        }
        this.statusFilter = this.props.statusFilter
    }

    componentDidMount = async () => {
    }

    componentDidUpdate = async (prevProps, prevState) => {
        /*
        const {statusFilter} = this.props
        if (prevProps.statusFilter !== statusFilter && statusFilter !== this.statusFilter) {
            console.log('updated')

            this.onStatusFilterChange(statusFilter)
        }*/
    }


    onStatusFilterChange = async statusFilter => {
        this.props.onFilterChanged(statusFilter)
    }

    /*
    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }
    */

    render() {
        const {classes, statusFilter} = this.props

        return (
            <MuiThemeProvider theme={hoverSelectOverride}>
                <FormControl variant="outlined" className={classes.statusFilter}>
                    <InputLabel htmlFor="statusFilter">Status Filter</InputLabel>
                    <Select
                        value={statusFilter}
                        onChange={e => this.onStatusFilterChange(e.target.value)}
                        input={
                            <OutlinedInput
                                labelWidth={88}
                                name="statusFilter"
                                id="statusFilter"
                            />
                        }
                    >
                        {hasCreatorPermissions() && <MenuItem value={STATUS_PENDING_SQ_API}
                            classes={{selected: classes.menuItem}}>PENDING SQ API</MenuItem>}

                        {hasCreatorPermissions() && <MenuItem value={STATUS_ACTION_REQUIRED}
                            classes={{selected: classes.menuItem}}>ACTION REQUIRED</MenuItem>}

                        {(hasCreatorPermissions() || hasApproverPermissions() || hasGroupViewPermission()) &&
                        <MenuItem value={STATUS_PENDING_APPROVAL}
                            classes={{selected: classes.menuItem}}>PENDING APPROVAL</MenuItem>}

                        {(hasCreatorPermissions() && !hasApproverPermissions()) &&  //only creator without approval permission need to see this.
                        <MenuItem value={STATUS_APPROVED}
                            classes={{selected: classes.menuItem}}>APPROVED</MenuItem>}

                        {(/*hasCreatorPermissions()|| */ hasApproverPermissions() || hasGroupViewPermission() || hasIssuerPermissions()) &&
                        <MenuItem value={STATUS_PENDING_COLLECTION}
                            classes={{selected: classes.menuItem}}>
                            PENDING COLLECTION
                        </MenuItem>}

                        {(hasCreatorPermissions() || hasApproverPermissions() || hasGroupViewPermission()) &&
                        <MenuItem value={STATUS_REJECTED}
                            classes={{selected: classes.menuItem}}>REJECTED</MenuItem>}

                        {(hasApproverPermissions() || hasGroupViewPermission() || hasIssuerPermissions()) &&
                        <MenuItem value={STATUS_COLLECTED}
                            classes={{selected: classes.menuItem}}>COLLECTED</MenuItem>}

                        {(hasApproverPermissions() || hasGroupViewPermission() || hasIssuerPermissions()) &&
                        <MenuItem value={STATUS_NOT_COLLECTED}
                            classes={{selected: classes.menuItem}}>NOT COLLECTED</MenuItem>}

                        {/*{hasCreatorPermissions() &&*/}
                        {/*<MenuItem value={'DELETED'}*/}
                        {/*    classes={{selected: classes.menuItem}}>DELETED</MenuItem>}*/}

                        {hasCreatorPermissions() &&
                        <MenuItem value={STATUS_VIEW_ALL}
                            classes={{selected: classes.menuItem}}>VIEW ALL</MenuItem>}
                    </Select>
                </FormControl>

            </MuiThemeProvider>
        )
    }
}

const styles = theme => ({
    container: theme.container,
    statusFilter: {width: '18rem'},
    menuItem: {color: 'white'}
})

const mapStateToProps = state => {
    const {groupApprovalDuck} = state

    return {groupApprovalDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        getAllGroup: (groupType, isApprover) => dispatch(getAllGroup(groupType, isApprover))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupStatusFilter))