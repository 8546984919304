import React, {PureComponent} from 'react'
import {Fab} from '@material-ui/core'
import {Delete} from '@material-ui/icons'
import PropTypes from 'prop-types'

class StyledDeleteBtn extends PureComponent {
    render() {
        return (
            <Fab onClick={this.props.onClick} color={'secondary'}
                variant={'round'} size={'small'} {...this.props}><Delete/></Fab>
        )
    }
}

StyledDeleteBtn.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default StyledDeleteBtn