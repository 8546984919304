let chinaCities = [
	'Beijing',
	'Changsha',
	'Chengdu',
	'Chongqing',
	'Dalian',
	'Fuzhou',
	'Guangzhou',
	'Haikou',
	'Hangzhou',
	'Harbin',
	'Jinan',
	'Kunming',
	'Nanchang',
	'Nanjing',
	'Nanning',
	'Ningbo',
	'Qingdao',
	'Quanzhou',
	'Shanghai',
	'Shenyang',
	'Shenzhen',
	'Shenzhen',
	'Tianjin',
	'Wuhan',
	'Wuxi',
	"Xi'an",
	'Xian',
	'Xiamen',
	'Zhengzhou'
]

export const checkBonusCities = cityName => {
	// let matchedCity = chinaCities.find(city => city === cityName);
	// return !!matchedCity;
	return false
}
