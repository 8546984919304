import React, {Component} from 'react'
import classNames from 'classnames'
import {Button} from '@material-ui/core'
import {withRouter} from 'react-router'

class CustomNavButton extends Component {
    onClick = to => {
        window.location = to
    }

    render() {
        const {to, activeClassName, className, match: {path}} = this.props

        const re = new RegExp(to)
        const match = re.test(path) ? activeClassName : ''

        return (
            <Button onClick={() => this.onClick(to)}
                className={classNames(className, match)}>{this.props.children}</Button>
        )
    }
}

export default withRouter(CustomNavButton)