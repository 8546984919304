import React, {Component} from 'react'
import {
    withStyles, Button, DialogTitle, DialogContent, DialogActions, Dialog
} from '@material-ui/core'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import PropTypes from 'prop-types'

class StyledGeneralDialog extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success'
        }
    }

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Individual`
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    render() {
        const {showDialog, title, onClose, onAction, actionText, data} = this.props

        return (
            <Dialog open={showDialog} scroll={'body'} onClose={onClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>{this.props.children}</DialogContent>
                <DialogActions>
                    <Button color={'default'} variant='text' onClick={() => onClose(data)}>Cancel</Button>
                    {actionText !== '' &&
                    <Button color={'primary'} variant='contained' onClick={() => onAction(data)}>{actionText}</Button>}
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

StyledGeneralDialog.propTypes = {
    title: PropTypes.string.isRequired,
    showDialog: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAction: PropTypes.func,
    actionText: PropTypes.string.isRequired,
    data: PropTypes.object
}

export default withStyles(styles)(StyledGeneralDialog)