const UPDATE_ALL_CONSENTS = 'UPDATE_ALL_CONSENTS';

export const updateAllConsents = ({collectionConsent, marketingConsent, airlineConsent, additionalCrConsent}) => ({
    type: UPDATE_ALL_CONSENTS,
    collectionConsent,
    marketingConsent,
    airlineConsent,
    additionalCrConsent
})

const initState = {
    collectionConsent: 'testing',
    marketingConsent: 'testing',
    airlineConsent: 'testing',
    additionalCrConsent: 'testing'
}

const consentDerivedDuck = (state = initState, action) => {
    switch (action.type) {
        case UPDATE_ALL_CONSENTS:
            let {collectionConsent, airlineConsent, marketingConsent, additionalCrConsent} = action;
            return { ...state, collectionConsent, airlineConsent, marketingConsent, additionalCrConsent }

        default:
            return state
    }
}

export default consentDerivedDuck
