import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Grid, TextField, Button, Typography, Select, MenuItem, FormControl, InputLabel, MuiThemeProvider, OutlinedInput } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import Header from '../containers/Header.js'
import { TITLE_PREFIX } from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import StyledSection from '../components/StyledSection'
import StyledSnackbar from '../components/StyledSnackbar'
import { hasPermissions, parseFullCardNoWithPrefix } from '../helpers/utilities'
import { SEARCH_PASSENGER_PERMISSION } from '../constants/permissions'
import { getAllAppealTransaction } from '../ducks/appealTransactionDuck'
import AppealTransactionTable from '../components/AppealTransactionTable.js'
import { hoverSelectOverride } from '../styles/mui/theme'
import {toBrowserFriendlyDate, getCurrentAuthHeader} from "../helpers/utilities";
import PageHelper from "../helpers/pageHelper";

class AppealTransactionScreen extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = { cardNo: '', email: '', refNo: '', startDate: '', endDate: '', showSnackbar: false, snackbarMessage: '', snackbarVariant: 'success', caseStatus: 'PENDING', selectedPage: 1, filterData: { approval: false, rejected: false } }
		this.rowsPerPage = 100
		this.pageHelper = new PageHelper({
			maxResultCount: this.rowsPerPage,
		})
	}

	componentDidMount() {
		document.title = `${TITLE_PREFIX} Search Passenger`
	}

	onSearchFieldsChange = (k, e) => {
		if (k === 'cardNoFrom' || k === 'cardNoTo') this.setState({ [k]: parseFullCardNoWithPrefix(e.target.value) })
		else this.setState({ [k]: e.target.value })
	}

	onPhysicalVoucherFieldsChange = (k, e) => {
		let numericValue = e.target.value.replace(/\D/g, '')
		this.setState({ [k]: numericValue })
	}

	_validateCardNo = (cardNo) => {
		if (cardNo && (cardNo.length !== 19 || /[^0-9]/g.test(cardNo))) {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: `Card no: ${cardNo} should be 19 digits`,
			})
			return false
		}
		return true
	}

	_validate = () => {
		const { firstName, lastName, firstFltDepDate, collectionDate, cardNoFrom, cardNoTo, preloadedCardFrom, preloadedCardTo } = this.state
		if (firstFltDepDate !== '' || collectionDate !== '') {
			if (lastName === '' && firstName === '') {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage:
						'Last Name/First name is required for Departure and/or Collection date filter',
				})
				return false
			}
		}

		if (!this._validateCardNo(cardNoFrom)) return false
		if (!this._validateCardNo(cardNoTo)) return false
		if (!this._validateCardNo(preloadedCardFrom)) return false
		if (!this._validateCardNo(preloadedCardTo)) return false
		return true
	}

	onSubmit = async () => {
		if (!this._validate()) return
		const fields = ['cardNo', 'email', 'refNo', 'startDate', 'endDate', 'caseStatus']

		let data = {}
		let hasFilter = false
		fields.forEach((f) => {
			if (this.state[f] !== '') {
				data[f] = this.state[f]
				hasFilter = true
			}
		})
		if (data.caseStatus === 'ALL') {
			hasFilter = false
			data.caseStatus = null
		}

		if (hasFilter) {
			let dataFiltered = { ...data, ...this.state.filterData }
			this._getAllGroups(dataFiltered)
		} else {
			this.setState(
				{ filterData: { approval: '', rejected: '' } },
				() => {
					this._getAllGroups(data)
				},
			)
		}
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}
	componentWillMount = () => {
		let data = this.state.filterData
		this._getAllGroups(data)
	}

	async _getAllGroups(data){
        let skipCount
		if (this.pageHelper.totalCount === 0) {
			skipCount = 0
		} else {
			let pageCallInput = this.pageHelper.pageCall(this.state.selectedPage)
			skipCount = this.pageHelper.skipCount
		}
		const params = { params: { ...data, maxResultCount: this.rowsPerPage, skipCount: skipCount }, ...getCurrentAuthHeader() }
		const rs = await this.props.getAllAppealTransaction(params)
		if (!rs.success) {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: rs.message,
			})
		} else {
			this.pageHelper.populatePagingData({
				totalCount: rs.result.totalCount,
			})
			this.setState({ totalCount: rs.result.totalCount })
		}
    }

	onChangePage = (e, page) => {
        let selectedPage
		switch (page) {
			case 'first':
				selectedPage = 1
				break

			case 'prev':
				selectedPage = this.state.selectedPage - 1
				break

			case 'next':
				selectedPage = this.state.selectedPage + 1
				break

			case 'last':
				selectedPage = this.pageHelper.getTotalNoOfPages()
				break

			default:
				selectedPage = page
		}

		this.setState({ selectedPage: selectedPage }, () => {
			this._getAllGroups(this.state.filterData)
		})
	}

	handleStatusChange = (event) => {
		this.setState({ caseStatus: event.target.value }, () => {
			const { caseStatus } = this.state
			if (caseStatus === 'PENDING') {
				this.setState({
					filterData: { approval: false, rejected: false },
				})
			}
			if (caseStatus === 'REJECTED') {
				this.setState({
					filterData: { approval: false, rejected: true },
				})
			}
			if (caseStatus === 'APPROVED') {
				this.setState({
					filterData: { approval: true, rejected: '' },
				})
			}
		})
	};

	render() {
		const { classes, appealTransactionDuck: { getAllAppealTransactionLoading, appealTransactions, searchAppealTransactionIsLoading } } = this.props
		const isLoading = searchAppealTransactionIsLoading || getAllAppealTransactionLoading
		const { cardNo, email, refNo, startDate, endDate, caseStatus } = this.state
		if (appealTransactions) {
			appealTransactions.sort(function(a, b) {
				a.status = 'PENDING'
				if (a.rejected) a.status = 'REJECTED'
				if (a.approval) a.status = 'APPROVED'

				b.status = 'PENDING'
				if (b.rejected) b.status = 'REJECTED'
				if (b.approval) b.status = 'APPROVED'

				if (a.status === b.status) return 0
				if (a.status === 'PENDING' && b.status !== 'PENDING') return -1
				if (a.status !== 'PENDING' && b.status === 'PENDING') return 1

				if (a.status === 'REJECTED' && b.status !== 'REJECTED') return -1
				if (a.status !== 'REJECTED' && b.status === 'REJECTED') return 1
			})
		}

		if (!hasPermissions(SEARCH_PASSENGER_PERMISSION)) {
			return <Redirect to={'/not-found'} />
		}

		return (
			<div>
				<GlobalTimeChecker />
				<Header history={this.props.history} />

				<div className={classes.container}>
					<StyledSection title={'Search criterias'} isLoading={isLoading}>
						<Grid container spacing={24} direction={'column'}>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>CR Member Card:</Typography>
								</Grid>
								<Grid item>
									<TextField
										id='eticket'
										label=''
										variant='outlined'
										value={cardNo}
										onChange={(e) => this.onSearchFieldsChange('cardNo', e)}
									/>
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>Email Address:</Typography>
								</Grid>
								<Grid item>
									<TextField id='name' label='' variant='outlined' value={email} onChange={(e) => this.onSearchFieldsChange('email', e)} />
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>Pretravel ID:</Typography>
								</Grid>
								<Grid item>
									<TextField id='name' label='' variant='outlined' value={refNo} onChange={(e) => this.onSearchFieldsChange('refNo', e)} />
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>Pretravel Status:</Typography>
								</Grid>
								<Grid item>
								<MuiThemeProvider theme={hoverSelectOverride}>
									<FormControl variant="outlined" className={classes.statusFilter}>
									<InputLabel htmlFor={'statusFilter'}>Status Filter</InputLabel>
									<Select
          								id="demo-simple-select"
										value={caseStatus}
										onChange={this.handleStatusChange}
										input={<OutlinedInput labelWidth={120} name={'statusFilter'} id={'statusFilter'} />}>
											<MenuItem value={'PENDING'}>PENDING</MenuItem>
          									<MenuItem value={'REJECTED'}>REJECTED</MenuItem>
          									<MenuItem value={'APPROVED'}>APPROVED</MenuItem>
											<MenuItem value={'ALL'}>ALL</MenuItem>
        							</Select>
									</FormControl>
									</MuiThemeProvider>
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>Request Date</Typography>
								</Grid>
								<Grid item>
									<Grid container item spacing={8} alignItems={'center'}>
										<Grid item>
											<TextField
												id='startDate'
												label='From Date'
												variant='outlined'
												type={'date'}
												InputLabelProps={{ shrink: true }}
												value={toBrowserFriendlyDate(startDate)}
												onChange={(e) => this.onSearchFieldsChange('startDate', e)}
											/>
										</Grid>
										<Grid item>
											<Typography variant={'body1'}>to</Typography>
										</Grid>
										<Grid item>
											<TextField
												id='endDate'
												label='To Date'
												variant='outlined'
												type={'date'}
												InputLabelProps={{ shrink: true }}
												value={toBrowserFriendlyDate(endDate)}
												onChange={(e) => this.onSearchFieldsChange('endDate', e)}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container item spacing={24} direction={'column'}>
								<Grid item>
									<Button color={'primary'} variant='contained' onClick={this.onSubmit}>
										Search
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</StyledSection>
						<StyledSection title={'Search result'}>
							<AppealTransactionTable
								history={this.props.history}
								data = {appealTransactions}
								onChangePage={this.onChangePage}
                                rowsPerPage={this.rowsPerPage}
                                page={this.state.selectedPage - 1}
                                count={this.pageHelper.getTotalCount()}>
							</AppealTransactionTable>
						</StyledSection>
				</div>
				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = (theme) => ({
	container: theme.container,
	statusFilter: { width: '14rem' },
})

const mapStateToProps = (state) => {
	const { appealTransactionDuck } = state
	const { getAllAppealTransactionLoading, appealTransactions } = appealTransactionDuck
	return { appealTransactionDuck, getAllAppealTransactionLoading, appealTransactions }
}

const mapDispatchToProps = (dispatch) => {
	return { getAllAppealTransaction: (data) => dispatch(getAllAppealTransaction(data)) }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withStyles(styles)(AppealTransactionScreen))
