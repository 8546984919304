import CallingCodes from "../data/callingCodesCRM";

export const findCallingCodeFromCountry = country => {
    const callingCode = CallingCodes.find(c => c.country === country)
    if (callingCode) {
        this.setState({callingCode: callingCode.label})
        return callingCode.label
    }
}

export const findCountryFromCallingCode = callingCode => {
    const matchedCallingCode = CallingCodes.find(c => c.label === callingCode);
    if (matchedCallingCode) {
        return matchedCallingCode.country
    }
}