import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Button, Grid } from '@material-ui/core'
import Header from '../containers/Header'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import CTPCardPrintLayout1 from '../components/CTPCardPrintLayout1'
import CRCardPrintLayout1 from '../components/CRCardPrintLayout1'
import querystring from 'querystring'
import {getSettings} from "../config/config.js"

class PrintCardOnDemandScreen extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			authorizationCode: '',
			origin: '',
			isLoading: false,
			printingCard: '',
			cardNoPrinted: '',
			showCr: false,
			cards: []
		}
	}

	componentDidMount(){
		let settings = getSettings()

		// this url will be not existing in production
		if (settings.environment == 'production')
		{
			this.props.history('/404')
		}

		let defaultVouchers = settings.voucher_sources.find(s => s.membership_type == "DEFAULT")
		let defaultVoucher = defaultVouchers.vouchers.find(s => s.voucher_type == "DEFAULT")
		var cards = []
        const queryString = require('query-string');
        const cardNoFrom = queryString.parse(window.location.search).cardNoFrom;
        const cardNoTo = queryString.parse(window.location.search).cardNoTo;
        const validFrom = queryString.parse(window.location.search).validFrom;
		const validTo = queryString.parse(window.location.search).validTo;
		const voucherValue = queryString.parse(window.location.search).voucherValue;
		const numberOfVoucher = queryString.parse(window.location.search).numberOfVoucher;
		const cardNos = this.generateCardRange(cardNoFrom, cardNoTo)

		cardNos.forEach(cardNo=>{
			let card = {
                cardNo: cardNo,
                issuedDate: validFrom,
				validTo: validTo,
				voucherValue: voucherValue,
				numberOfVoucher: numberOfVoucher,
                issuedVouchersList: [
                ]
			}
			if (numberOfVoucher) {
				for (let i = 0; i < numberOfVoucher; i++) {
					card.issuedVouchersList.push({
						validFrom: validFrom,
						validTo: validTo,
						typeValue: voucherValue ? voucherValue : defaultVoucher.voucher_value
					})
				}
			} else {
				card.issuedVouchersList.push({
					validFrom: validFrom,
					validTo: validTo,
					typeValue: voucherValue ? voucherValue : defaultVoucher.voucher_value
				})
			}
			
			cards.push(card)
		})

        this.setState({"cards": cards})

    }

    generateCardRange = (cardNoFrom, cardNoTo)=>{

		let cardNos = [];
		let cardNoFromInt = parseInt(cardNoFrom)
		let cardNoToInt = parseInt(cardNoTo)
        let noOfCard =  cardNoToInt - cardNoFromInt
        if(noOfCard < 1) return [cardNoFrom];
		for(let i = 0; i <= noOfCard; i++){
			var currentCardNoInt = cardNoFromInt + i;
			var currentCardNo = "0000" + currentCardNoInt;
			cardNos.push(currentCardNo);
		}
		console.log(cardNos)

		return cardNos


	}

	onPrint = () => {
		window.print()
	}

	render() {

		return (
			<div>
				<Header history={this.props.history} />
				<GlobalTimeChecker />

				<style>{`@media print {
						._page {
							margin: 0;
							box-shadow: 0;
						}
					}`}</style>

					<Button onClick={this.onPrint} variant='contained'>
						PRINT ALL
					</Button>
					{this.state.cards.map((m, i) => {
							return(
								<Grid item key={m.cardNo}>
									<CTPCardPrintLayout1 card={m} />
								</Grid>
							)
						})}
				</div>
		)
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
	row: { display: 'flex' },
	rowItem: { display: 'flex', flex: 1, marginRight: 60, alignItems: 'center' },
	flexStart: { alignItems: 'flex-start' },
	consentText: { fontSize: '0.8125rem' },
})

const mapStateToProps = (state) => {
	const { individualFormDuck } = state

	return { individualFormDuck }
}

export default connect(mapStateToProps)(withStyles(styles)(PrintCardOnDemandScreen))
