import axios from 'axios'
import {getSettings} from '../config/config'
import {getCurrentAuthHeader} from '../helpers/utilities'
import {checkValidStatusCode} from "../helpers/httpHelper";

let settings = getSettings();
let baseURL = settings.baseURL;

const UPDATE_CREATE_NEW_ACCOUNT_DUCK = ' UPDATE_CREATE_NEW_ACCOUNT_DUCK'
const SUBMIT_CREATE_NEW_ACCOUNT_IS_LOADING = 'SUBMIT_CREATE_NEW_ACCOUNT_IS_LOADING'
const SUBMIT_CREATE_NEW_ACCOUNT_SUCCESS = 'SUBMIT_CREATE_NEW_ACCOUNT_SUCCESS'
const SUBMIT_CREATE_NEW_ACCOUNT_FAIL = 'SUBMIT_CREATE_NEW_ACCOUNT_FAIL'

export const updateCreateNewAccountDuck = (k, v) => ({
    type: UPDATE_CREATE_NEW_ACCOUNT_DUCK,
    k, v
})

export const submitCreateNewAccount = fields => async dispatch => {
    dispatch(_submitCreateNewAccountIsLoading())

    const {name, contactNo, emailAddress, roleNames, pos} = fields
    const data = {name, contactNo, emailAddress, roleNames, pos}

    const rs = await axios.post(baseURL + '/Account/Create', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_submitCreateNewAccountSuccess())
        return {success: true, message: ''}
    } else {
        dispatch(_submitCreateNewAccountFail())
        if(rs.data.error){
            if(rs.data.error.message) return {success:false, message: rs.data.error.message}
        }
        return {success: false, message: 'Please try again 1'}
    }
}

const _submitCreateNewAccountIsLoading = () => ({
    type: SUBMIT_CREATE_NEW_ACCOUNT_IS_LOADING
})

const _submitCreateNewAccountSuccess = () => ({
    type: SUBMIT_CREATE_NEW_ACCOUNT_SUCCESS
})

const _submitCreateNewAccountFail = () => ({
    type: SUBMIT_CREATE_NEW_ACCOUNT_FAIL
})

const initState = {
    name: '',
    emailAddress: '',
    contactNo: '',
    pos: '',
    roleNames: [],
    submitCreateNewAccountIsLoading: false
}

const createNewAccountDuck = (state = initState, action) => {
    switch (action.type) {
        case  UPDATE_CREATE_NEW_ACCOUNT_DUCK:
            return {...state, [action.k]: action.v}

        case SUBMIT_CREATE_NEW_ACCOUNT_IS_LOADING:
            return {...state, submitCreateNewAccountIsLoading: true}

        case SUBMIT_CREATE_NEW_ACCOUNT_SUCCESS:
            return {...state, ...initState}

        case SUBMIT_CREATE_NEW_ACCOUNT_FAIL:
            return {...state, submitCreateNewAccountIsLoading: false}

        default:
            return state
    }
}

export default createNewAccountDuck