

export const MEMBER_TYPE_REGISTER_CR = "MEMBER_TYPE_REGISTER_CR";
export const MEMBER_TYPE_EXISTING_CR = "MEMBER_TYPE_EXISTING_CR";
export const MEMBER_TYPE_NO_CR = "MEMBER_TYPE_NO_CR";

export const generateCollectorDetailsMemberFlags = (memberType, country) => {
    let memberFlagContainer = {}
    if (memberType === MEMBER_TYPE_EXISTING_CR) {
        memberFlagContainer.existingCr = true;
        memberFlagContainer.registerCr = false;

    } else if (memberType === MEMBER_TYPE_REGISTER_CR) {
        memberFlagContainer.existingCr = false;
        memberFlagContainer.registerCr = true;

    } else if(memberType === MEMBER_TYPE_NO_CR){
        memberFlagContainer.existingCr = false;
        memberFlagContainer.registerCr = false;
    }

    return memberFlagContainer
}
