import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withStyles, Grid, TextField} from '@material-ui/core'
import {setErrorMessage} from '../ducks/systemError'
import {addUserTicketAndPassport} from '../ducks/individualFormDuck'

class ScanTicket extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            ticket: '',
            passport: ''
        }
        this.ticketRef = React.createRef()
        this.passportRef = React.createRef()
        this.ticketTimeout = null
        this.passportTimeout = null
    }

    componentWillUnmount() {
        clearTimeout(this.ticketTimeout)
        clearTimeout(this.passportTimeout)
    }

    handleInputChange = e => {
        if (e.target.id === 'ticket') {
            let input = e.target.value
            clearTimeout(this.ticketTimeout)
            this.ticketTimeout = setTimeout(() => {
                    this._addTicket(input)
                    this.passportRef.current.focus()
                }, 1000
            )
        }

        if (e.target.id === 'passport') {
            let input = e.target.value
            clearTimeout(this.passportTimeout)
            this.passportTimeout = setTimeout(() => {
                    this._addPassport(input)
                    this.props.addUserTicketAndPassport(this.state)
                    this.props.onScanDone()
                }, 1000
            )
        }

    }

    _addTicket = input => {
        // let tixNo = this._findTixNo(input)
        // if (!tixNo) {
        //     this.props.setErrorMessage('Cannot find ticket no')
        //     return
        // }

        this.setState({ticket: input})
    }

    _addPassport = input => {
        this.setState({passport: input})
    }

    _findTixNo = input => {
        let re = /SQ SQ (\d+)/
        let match = input.match(re)
        if (!match) return
        return match[1]
    }

    render() {
        const {classes} = this.props
        return (
            <Grid container justify={'center'}>
                <Grid item xs={6} className={classes.container}>
                    <form autoComplete={'off'}>
                        <Grid container spacing={32} direction={'column'}>
                            <Grid item>
                                <TextField label="Ticket" autoComplete={'false'} id="ticket" variant={'outlined'}
                                    fullWidth={true}
                                    onChange={this.handleInputChange} autoFocus={true} inputRef={this.ticketRef}/>
                            </Grid>

                            <Grid item>
                                <TextField label="Passport" autoComplete={'false'} id="passport"
                                    variant={'outlined'}
                                    fullWidth={true}
                                    onChange={this.handleInputChange} inputRef={this.passportRef}/>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        )
    }
}

const styles = {
    container: {
        marginTop: '10em'
    }
}

const mapDispatchToProps = function (dispatch) {
    return {
        addUserTicketAndPassport: formState => dispatch(addUserTicketAndPassport(formState)),
        setErrorMessage: msg => dispatch(setErrorMessage(msg))
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ScanTicket))