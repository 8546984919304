import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles, Grid, Button, TextField, Typography } from '@material-ui/core'
import * as cloneDeep from 'lodash/cloneDeep'
import {
	updateCollectorPersonalDetails,
	updateCurrentPaxFormDetails,
	checkAndUpdateEligibility,
	updateBoardingPassDetails,
	replaceDraft,
	updatePromptedRetrieveDraft,
	updatePromptedAutoFillSector,
	findPassengerDataWithCardNo,
	findPassengerInGroup,
	getDraftFromOtherRedemptionTransaction,
    findPassengersDataWithAppealTransaction,
	populatePassengerFromGroup,
	individualGroupCheckRedeemedPassenger,
} from '../../ducks/individualFormDuck.js'
import StyledSection from '../../components/StyledSection'
import StyledSnackbar from '../../components/StyledSnackbar'
import { REQUIRED_ERROR_MSG, ETICKET_ELIGIBILITY_ERROR_MSG } from '../../constants/messages'
import StyledTinyFabCheckCircle from '../../components/StyledTinyFabCheckCircle'
import StyledTinyFabError from '../../components/StyledTinyFabError'
import BoardingPassParser from '../../helpers/boardingPassParser'
import SqApiCriteriasDialog from '../../components/SqApiCriteriasDialog'
import StyledTinyErrorCross from '../../components/StyledTinyErrorCross'
import StyledGeneralDialog from '../../components/StyledGeneralDialog'
import { updateRedemptionTransaction } from '../../ducks/redemptionTransactionDuck'
import { getCurrentAuthHeader, objHasData } from '../../helpers/utilities'
import { convertSqStatusForDisplay, DISPLAY_YES, DISPLAY_MANUAL } from '../../helpers/eticketStatusHelper'

const E_TIX_1_KEY = 'eTicket1'
const E_TIX_2_KEY = 'eTicket2'
const E_TIX_3_KEY = 'eTicket3'
const E_TIX_4_KEY = 'eTicket4'

// generalDialogTitle
const PASSENGER_HAS_EXISTING_CARD = 'Existing card found'
const PASSENGER_HAS_EXISTING_GROUP = 'Existing group found'
const PASSENGER_HAS_EXISTING_DRAFT = 'Existing draft found'
const PASSENGER_HAS_EXISTING_APPEAL = 'Existing appeal found'
const PASSENGER_AUTO_FILL_SECTOR = 'Auto fill sector values'

class IndividualETicketForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			eTixErrorStore: {
				[E_TIX_1_KEY]: '',
				[E_TIX_2_KEY]: '',
				[E_TIX_3_KEY]: '',
				[E_TIX_4_KEY]: '',
			},
			eTix: {
				eTicket1: '',
				eTicket2: '',
				eTicket3: '',
				eTicket4: '',
			},
			flightFormFields: [
				{ key: [E_TIX_1_KEY], label: 'E-Ticket No. 1*', type: 'text', customButton: true },
				{ key: [E_TIX_2_KEY], label: 'E-Ticket No. 2', type: 'text', customButton: true },
				{ key: [E_TIX_3_KEY], label: 'E-Ticket No. 3', type: 'text', customButton: true },
				{ key: [E_TIX_4_KEY], label: 'E-Ticket No. 4', type: 'text', customButton: true },
			],
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
			viewCriterias: false,
			criteriasMessage: '',
			criterias: {},
			showEticketField: 0,
			eTicketIsYes: false,
			showGeneralDialog: false,
			generalDialogTitle: '',
			generalDialogActionText: 'Yes',
			pretravelId:''
		}

		this.eTicketRefs = []
		this.inputTimeout = null
		this.popPropsETixTimeout = null
	}

	componentDidUpdate = (prevProps, prevState) => {
		let eTixKeys = [E_TIX_1_KEY, E_TIX_2_KEY, E_TIX_3_KEY, E_TIX_4_KEY]
		const { currentPaxFormDetails } = this.props
		const { eTix } = this.state
		eTixKeys.forEach((eTixKeys) => {
			if (prevProps.currentPaxFormDetails.eTicketsDetails[eTixKeys] !== currentPaxFormDetails.eTicketsDetails[eTixKeys]) {
				if (prevProps.currentPaxFormDetails.eTicketsDetails[eTixKeys + 'LastName'] !== currentPaxFormDetails.eTicketsDetails[eTixKeys + 'LastName']) {
					if (currentPaxFormDetails.eTicketsDetails[eTixKeys])
						//if etix has values has value
						this._checkEligibility()
				}

				if (currentPaxFormDetails.eTicketsDetails[eTixKeys] !== undefined) {
					if (currentPaxFormDetails.eTicketsDetails[eTixKeys] === '') {
						this.setState({ eTixErrorStore: { [eTixKeys]: '' } })
					}
	
					if (currentPaxFormDetails.eTicketsDetails[eTixKeys].length > 2) {
						this._checkApprovedTicketNo(eTixKeys)
					}
	
					if (currentPaxFormDetails.eTicketsDetails[eTixKeys] !== eTix[eTixKeys]) {
						this._updateStateETicketDetails(eTixKeys, currentPaxFormDetails.eTicketsDetails[eTixKeys])
					}
				}
			}

			if (prevState.eTix[eTixKeys] !== eTix[eTixKeys]) {
				clearTimeout(this.popPropsETixTimeout)
				this.popPropsETixTimeout = setTimeout(() => {
					this._updateFormDetails({ detailsCategory: 'eTicketsDetails', key: eTixKeys, value: eTix[eTixKeys] })
				}, 300)
			}
		})
		// reasign Etix
		if (prevProps.currentPaxFormDetails.eTicketsDetails[E_TIX_1_KEY] !== currentPaxFormDetails.eTicketsDetails[E_TIX_1_KEY]) {
			this.setState({
				eTix: {
					eTicket1: currentPaxFormDetails.eTicketsDetails[E_TIX_1_KEY],
					eTicket2: currentPaxFormDetails.eTicketsDetails[E_TIX_2_KEY],
					eTicket3: currentPaxFormDetails.eTicketsDetails[E_TIX_3_KEY],
					eTicket4: currentPaxFormDetails.eTicketsDetails[E_TIX_4_KEY]
				} 
			})
		}
	}

	_checkApprovedTicketNo = (eTixKey) => {
		let inputtedValue = this.props.currentPaxFormDetails.eTicketsDetails[eTixKey]
		if (!inputtedValue) {
			let eTixErrorStore = { ...this.state.eTixErrorStore }
			eTixErrorStore[eTixKey] = ''
			this.setState({ eTixErrorStore: eTixErrorStore })
			return
		}

		let subString = inputtedValue.substr(0, 3)
		if (
			(subString !== '618' && subString !== '629' && subString !== '086' && subString !== '081' && subString !== '000')
		) {
			let eTixErrorStore = { ...this.state.eTixErrorStore }
			eTixErrorStore[eTixKey] = ETICKET_ELIGIBILITY_ERROR_MSG
			this.setState({ eTixErrorStore: eTixErrorStore })
		} else {
			let eTixErrorStore = { ...this.state.eTixErrorStore }
			eTixErrorStore[eTixKey] = ETICKET_ELIGIBILITY_ERROR_MSG
			this.setState({ eTixErrorStore: '' })
		}
	}

	handleETicketsDetailsChange = (key, value) => {
		this._processManualInput(key, value)

		clearTimeout(this.inputTimeout)
		this.inputTimeout = setTimeout(() => {
			if (value.length >= 90) {
				this._processScanTicket(key, value)
			}
		}, 1000)
	}

	_processScanTicket = (key, value) => {
		if (value.length < 90) return
		let boardingPassParser = new BoardingPassParser(value)
		let bpData = boardingPassParser.getData()
		if (
			bpData.eTix === this.props.currentPaxFormDetails.eTicketsDetails[E_TIX_1_KEY] ||
			bpData.eTix === this.props.currentPaxFormDetails.eTicketsDetails[E_TIX_2_KEY] ||
			bpData.eTix === this.props.currentPaxFormDetails.eTicketsDetails[E_TIX_3_KEY]
		) {
			this.setState({ showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'Same E Ticket Number' })
			this._updateStateETicketDetails(key, '')
			//this._updateFormDetails({detailsCategory: 'eTicketsDetails', key, value: ''})
			return
		}
		this._updateStateETicketDetails(key, bpData.eTix)
		this.props.updateBoardingPassDetails({ eTicketKey: key, boardingPassData: bpData })
	}

	_processManualInput(key, value) {
		this._updateStateETicketDetails(key, value)
		//this._updateFormDetails({detailsCategory: 'eTicketsDetails', key, value})
	}

	_updateStateETicketDetails(key, value) {
		let stateETix = { ...this.state.eTix }
		stateETix[key] = value
		this.setState({ eTix: stateETix })
	}

	updateETixLastName = (key, value) => {
		this._updateFormDetails({ detailsCategory: 'eTicketsDetails', key, value })
	}

	_updateFormDetails = ({ detailsCategory, key, value }) => {
		let formDetails = cloneDeep(this.props.currentPaxFormDetails)
		formDetails[detailsCategory][key] = value
		formDetails[detailsCategory].isETicketsVerified = false
		this.props.updateCurrentPaxFormDetails(formDetails)
	}

	confirmTickets = (tickets) => {
		tickets.forEach((ticket, i) => {
			this.handleETicketsDetailsChange(`eTicket${i + 1}`, ticket)
		})
	}

	_checkEligibility = async () => {
		const { currentPaxFormDetails, editMode } = this.props

		if (!currentPaxFormDetails.eTicketsDetails[E_TIX_1_KEY]) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: `Please check ETicket is valid` })
			return
		}

		if (!this._validatePreviousPaxETixDuplicate(this.props.individualFormDuck.currentPaxFormDetails, this.props.individualFormDuck.formDetailsList)) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: `Please ensure no E Tickets duplicate` })
			return
		}

		if (this._validateEligibility(currentPaxFormDetails)) {
			const ticketDetails = []
			let eTixKeys = [E_TIX_1_KEY, E_TIX_2_KEY, E_TIX_3_KEY, E_TIX_4_KEY]
			let checkDuplicate = []
			let hasDuplicates = false
			for (let eTixKey of eTixKeys) {
				if (currentPaxFormDetails.eTicketsDetails[eTixKey]) {
					// validate for 13 numberic numbers
					if (!/^[0-9][0-9]{12}$/.test(currentPaxFormDetails.eTicketsDetails[eTixKey])) {
						this.setState({
							showSnackbar: true,
							snackbarVariant: 'error',
							snackbarMessage: `ETicket has be to 13 numberic numbers (${currentPaxFormDetails.eTicketsDetails[eTixKey]})`,
						})
						return
					}
					if (!checkDuplicate.includes(currentPaxFormDetails.eTicketsDetails[eTixKey])) {
						ticketDetails.push({
							ticketNumber: currentPaxFormDetails.eTicketsDetails[eTixKey],
							lastName: currentPaxFormDetails.eTicketsDetails[eTixKey + 'LastName'],
						})
					} else {
						hasDuplicates = true
						this.setState({
							showSnackbar: true,
							snackbarVariant: 'error',
							snackbarMessage: 'Please fix duplicate eTicket: ' + currentPaxFormDetails.eTicketsDetails[eTixKey],
						})
						break
					}
				}
				checkDuplicate.push(currentPaxFormDetails.eTicketsDetails[eTixKey])
			}
			if (hasDuplicates) return

			let eTickets = []
			if (currentPaxFormDetails.eTicketsDetails[E_TIX_1_KEY]) eTickets.push(currentPaxFormDetails.eTicketsDetails[E_TIX_1_KEY])
			if (currentPaxFormDetails.eTicketsDetails[E_TIX_2_KEY]) eTickets.push(currentPaxFormDetails.eTicketsDetails[E_TIX_2_KEY])
			if (currentPaxFormDetails.eTicketsDetails[E_TIX_3_KEY]) eTickets.push(currentPaxFormDetails.eTicketsDetails[E_TIX_3_KEY])
			if (currentPaxFormDetails.eTicketsDetails[E_TIX_4_KEY]) eTickets.push(currentPaxFormDetails.eTicketsDetails[E_TIX_4_KEY])

			const checkCardData = { eTickets: eTickets }
			const checkGroupData = { params: { eTicketNo: currentPaxFormDetails.eTicketsDetails[E_TIX_1_KEY] }, ...getCurrentAuthHeader() }

			const rs = await this.props.findPassengerDataWithCardNo(checkCardData)
			if (!rs.success) this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
			if (rs.success) {
				const {
					individualFormDuck: {
						passengerDataWithCardNo: { group, redemptionTransaction },
					},
				} = this.props

				if ((Object.keys(group).length > 0 || Object.keys(redemptionTransaction).length > 0) && !editMode) {
					return this.setState({ generalDialogTitle: PASSENGER_HAS_EXISTING_CARD, showGeneralDialog: true, generalDialogActionText: '' })
				}

				/// check group
				const rs = await this.props.findPassengerInGroup(checkGroupData)
				if (!rs.success){
                    this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
					return;
				}
				if (rs.success) {
                    const {
                        individualFormDuck: {passengerFromGroup},
                    } = this.props
                    if (Object.keys(passengerFromGroup).length > 0 && !editMode) {
                        return this.setState({
                            generalDialogTitle: PASSENGER_HAS_EXISTING_GROUP,
                            showGeneralDialog: true,
                            generalDialogActionText: 'Yes'
                        })
                    }
                }

				/// check appeal
				const checkAppealRequest = { eTickets: eTickets }

				const checkAppealResponse = await this.props.findPassengersDataWithAppealTransaction(checkAppealRequest);
				if (!checkAppealResponse.success) {
					this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
					return;
				}
				if(checkAppealResponse.success){
					if(checkAppealResponse.result.items.length > 0) {
						this.setState({
							generalDialogTitle: PASSENGER_HAS_EXISTING_APPEAL,
							showGeneralDialog: true,
							generalDialogActionText: '',
							pretravelId: checkAppealResponse.result.items[0].appealTransactionId
						})
						return;
					}
				}

				/// check draft
				console.log("checking draft")
				if (this.props.promptedRetrieveDraft) {
					// if already check draft before, then don't check again.
					this._checkSqApi()
				} else {

					const data = {
						params: { eTicketNo: currentPaxFormDetails.eTicketsDetails[E_TIX_1_KEY], redemptionTransactionId: redemptionTransaction.id },
						...getCurrentAuthHeader(),
					}
					const rs = await this.props.getDraftFromOtherRedemptionTransaction(data)
					if (!rs.success) this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })

					if (rs.success) {
						// set promptedRetrieveDraft to true to only prompt for draft one time
						await this.props.updatePromptedRetrieveDraft(true)

						const {
							individualFormDuck: { draft },
						} = this.props
						if (Object.keys(draft).length > 0 && !editMode) {
							return this.setState({ generalDialogTitle: PASSENGER_HAS_EXISTING_DRAFT, showGeneralDialog: true, generalDialogActionText: 'Yes' })
						} else {
							/// check sq api
							this._checkSqApi()
						}
					}
				}
			}
		}
	}

	_validateEligibility(currentPaxFormDetails) {
		let rs = true
		if (currentPaxFormDetails.eTicketsDetails.eTicket1 === '' || currentPaxFormDetails.eTicketsDetails.eTicket1 === undefined) {
			const newEticket1 = { ...this.state.flightFormFields[0] }
			newEticket1.error = true
			newEticket1.helperText = REQUIRED_ERROR_MSG

			const newFlightFormFields = [...this.state.flightFormFields]
			newFlightFormFields.splice(0, 1, newEticket1)
			this.setState({ flightFormFields: newFlightFormFields })

			rs = false
		} else {
			const newEticket1 = { ...this.state.flightFormFields[0] }
			if (!this._checkApprovedTicketNo(currentPaxFormDetails.eTicketsDetails.eTicket1)) {
				const newEticket1 = { ...this.state.flightFormFields[0] }
				newEticket1.error = true
				newEticket1.helperText = ETICKET_ELIGIBILITY_ERROR_MSG

				const newFlightFormFields = [...this.state.flightFormFields]
				newFlightFormFields.splice(0, 1, newEticket1)
				this.setState({ flightFormFields: newFlightFormFields })
			} else {
				newEticket1.error = false
				newEticket1.helperText = null

				const newFlightFormFields = [...this.state.flightFormFields]
				newFlightFormFields.splice(0, 1, newEticket1)
				this.setState({ flightFormFields: newFlightFormFields })
			}
		}

		return rs
	}

	_validatePreviousPaxETixDuplicate(currentPax, existingList) {
		let eTixObj = currentPax.eTicketsDetails

		for (let i = 0; i < existingList.length; i++) {
			if (currentPax.pax === existingList[i].pax) {
				continue
			}
			if (eTixObj.eTicket4) {
				if (
					eTixObj.eTicket4 === existingList[i].eTicketsDetails.eTicket1 ||
					eTixObj.eTicket4 === existingList[i].eTicketsDetails.eTicket2 ||
					eTixObj.eTicket4 === existingList[i].eTicketsDetails.eTicket3 ||
					eTixObj.eTicket4 === existingList[i].eTicketsDetails.eTicket4
				) {
					return false
				}
			}

			if (eTixObj.eTicket3) {
				if (
					eTixObj.eTicket3 === existingList[i].eTicketsDetails.eTicket1 ||
					eTixObj.eTicket3 === existingList[i].eTicketsDetails.eTicket2 ||
					eTixObj.eTicket3 === existingList[i].eTicketsDetails.eTicket3 ||
					eTixObj.eTicket3 === existingList[i].eTicketsDetails.Ticket4
				) {
					return false
				}
			}

			if (eTixObj.eTicket2) {
				if (
					eTixObj.eTicket2 === existingList[i].eTicketsDetails.eTicket1 ||
					eTixObj.eTicket2 === existingList[i].eTicketsDetails.eTicket2 ||
					eTixObj.eTicket2 === existingList[i].eTicketsDetails.eTicket3 ||
					eTixObj.eTicket2 === existingList[i].eTicketsDetails.eTicket4
				) {
					return false
				}
			}

			if (eTixObj.eTicket1) {
				if (
					eTixObj.eTicket1 === existingList[i].eTicketsDetails.eTicket1 ||
					eTixObj.eTicket1 === existingList[i].eTicketsDetails.eTicket2 ||
					eTixObj.eTicket1 === existingList[i].eTicketsDetails.eTicket3 ||
					eTixObj.eTicket1 === existingList[i].eTicketsDetails.eTicket4
				) {
					return false
				}
			}
		}

		return true
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	_renderNotiIcon = (ticketNumber) => {
		const { currentPaxFormDetails } = this.props

		let ticketDetails = currentPaxFormDetails.ticketDetails

		if (!ticketDetails || ticketNumber === '') return null

		const d = ticketDetails.find((d) => d.ticketNumber === ticketNumber)
		if (d === undefined) return null

		if (convertSqStatusForDisplay(d.ctpEligibility) === DISPLAY_YES) {
			if (this.state.eTicketIsYes !== true) this.setState({ eTicketIsYes: true })
			return <StyledTinyFabCheckCircle onClick={() => this.onShowEligibilityPopup(d)} />
		} else if (convertSqStatusForDisplay(d.ctpEligibility) === DISPLAY_MANUAL) {
			return <StyledTinyFabError yellow onClick={() => this.onShowEligibilityPopup(d)} />
		} else {
			return <StyledTinyErrorCross onClick={() => this.onShowEligibilityPopup(d)} />
		}
	}

	onShowEligibilityPopup = (rs) => {
		if (rs.criterias) {
			this.setState({ viewCriterias: true, criterias: rs.criterias, criteriasMessage: '' })
		} else {
			rs.remarks
				? this.setState({ viewCriterias: true, criterias: {}, criteriasMessage: rs.remarks })
				: this.setState({ viewCriterias: true, criterias: {}, criteriasMessage: 'ETicket not found in database' })
		}
	}

	onViewCriteriasClose = () => {
		this.setState({ viewCriterias: false })
	}

	focusETicket = (n) => {
		this.eTicketRefs[n].focus()
	}

	resetAddETicketButton = () => {
		this.eTicketRefs = []
		this.setState({ eTicketIsYes: false, showEticketField: 0, 
			eTix: {
				eTicket1: '',
				eTicket2: '',
				eTicket3: '',
				eTicket4: '',
			} 
		})
	}

	getETicketValue = (k) => {
		//const {currentPaxFormDetails} = this.props
		//return currentPaxFormDetails.eTicketsDetails[k]
		return this.state.eTix[k]
	}

	getETicketLastname = (k) => {
		const { currentPaxFormDetails } = this.props
		return currentPaxFormDetails.eTicketsDetails[k]
	}

	_onAddETicket = () => {
		const { showEticketField } = this.state

		if (showEticketField < 3) this.setState({ showEticketField: showEticketField + 1 })
	}

	onDialogCancel = async () => {
		this.setState({ showGeneralDialog: false })
	}

	_checkSqApi = async () => {
		this.setState({ showGeneralDialog: false })
		const { currentPaxFormDetails, editMode } = this.props
		const ticketDetails = []
		let eTixKeys = [E_TIX_1_KEY, E_TIX_2_KEY, E_TIX_3_KEY, E_TIX_4_KEY]
		for (let eTixKey of eTixKeys) {
			if (currentPaxFormDetails.eTicketsDetails[eTixKey]) {
				ticketDetails.push({
					ticketNumber: currentPaxFormDetails.eTicketsDetails[eTixKey],
					lastName: currentPaxFormDetails.eTicketsDetails[eTixKey + 'LastName'],
				})
			}
		}

		const rs = await this.props.checkAndUpdateEligibility({ ticketDetails: ticketDetails, redemptionTransactionId: this.props.redemptionTransaction.id })
		if (!rs.success) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
		} else {
			if (!editMode) {
				if (this.props.promptedAutoFillSector) {
					// if already check auto fill sector before, then don't check again.
					this._autoFillSector()
				} else {
					const { formDetailsList } = this.props
					if (formDetailsList && formDetailsList.length > 0 && !currentPaxFormDetails.isFirstPax) {
						return this.setState({ generalDialogTitle: PASSENGER_AUTO_FILL_SECTOR, showGeneralDialog: true, generalDialogActionText: 'Yes' })
					}
				}
			}
		} 
	}

	_autoFillSector = async () => {
		this.setState({ showGeneralDialog: false })
		const { formDetailsList, currentPaxFormDetails } = this.props
		// get first passenger 
		let firstPassenger = formDetailsList[0]
		currentPaxFormDetails.sector1Details = firstPassenger.sector1Details
		currentPaxFormDetails.sector2Details = firstPassenger.sector2Details
		currentPaxFormDetails.sector3Details = firstPassenger.sector3Details
		currentPaxFormDetails.sector4Details = firstPassenger.sector4Details
	}

	onLinkClick = async (id) => {
        const url = '/appeal-transaction-detail/'
		window.open(url + id)
	}

	render() {
		const {
			classes,
			currentPaxFormDetails,
			editMode,
			editModeClass,
			checkAndUpdateEligibilityIsLoading,
			individualFormDuck: {
				findPassengerDataWithCardNoIsLoading,
				findPassengerInGroupIsLoading,
                findPassengerWithAppealIsLoading,
				getDraftFromOtherRedemptionTransactionIsLoading,
				passengerDataWithCardNo,
				getAllCampaignsIsLoading,
				createRedemptionTransactionIsLoading,
				individualFilesUploadIsLoading,
				uploadFileIsLoading,
			},
		} = this.props
		const eTicketsKeys = [E_TIX_1_KEY, E_TIX_2_KEY, E_TIX_3_KEY, E_TIX_4_KEY]
		const { eTicketIsYes, showGeneralDialog, showEticketField, generalDialogTitle, generalDialogActionText } = this.state

		const isLoading =
			checkAndUpdateEligibilityIsLoading ||
			findPassengerDataWithCardNoIsLoading ||
			findPassengerInGroupIsLoading ||
			getDraftFromOtherRedemptionTransactionIsLoading ||
            findPassengerWithAppealIsLoading ||
			getAllCampaignsIsLoading ||
			createRedemptionTransactionIsLoading ||
			individualFilesUploadIsLoading ||
			uploadFileIsLoading
			
		let shouldDisable = currentPaxFormDetails.eTicketsDetails.eTicket1 === '' || currentPaxFormDetails.eTicketsDetails.eTicket1LastName === ''

		let onConfirm = function() {}
		let onClose = function() {}
		switch (generalDialogTitle) {
			case PASSENGER_HAS_EXISTING_CARD:
				onConfirm = () => {
					this.setState({ showGeneralDialog: false }, () => {
						// show existing card
					})
				}
				onClose = () => {
					this.onDialogCancel()
				}
				break
			case PASSENGER_HAS_EXISTING_GROUP:
				onConfirm = () => {
					this.setState({ showGeneralDialog: false }, async () => {
						await this.props.populatePassengerFromGroup()
						await this.props.updatePromptedRetrieveDraft(true)
						this._checkSqApi()
					})
				}
				onClose = () => {
					this._checkSqApi()
				}
				break
            case PASSENGER_HAS_EXISTING_APPEAL:
                onConfirm = () => {

                }
                onClose = () => {
                    this.setState({ showGeneralDialog: false })
                }
                break
			case PASSENGER_HAS_EXISTING_DRAFT:
				onConfirm = () => {
					this.setState({ showGeneralDialog: false }, async () => {
						// populate data
						await this.props.replaceDraft()

						const { formDetailsList } = this.props
						if (formDetailsList.length > 0) {
							const eTicketFilter = []
							formDetailsList.forEach((p) => {
								if (p.eTicketsDetails['eTicket1'] !== '') {
									eTicketFilter.push(p.eTicketsDetails['eTicket1'])
								}
								// If need to check all eTickets, use below instead
								// const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
								// for (let k of keys) {
								//     if (p.eTicketsDetails['eTicket1'] !== '') {
								//         eTicketFilter.push(p.eTicketsDetails[k])
								//     }
								// }
							})
							const data = { eTicketFilter }
							this.props.individualGroupCheckRedeemedPassenger(data)
						}
						this.props.updateRedemptionTransaction(this.props.draftRedemptionTransaction)
					})
				}
				onClose = () => {
					this._checkSqApi()
				}
				break
			case PASSENGER_AUTO_FILL_SECTOR:
                onConfirm = () => {
					// update default condition value of auto fill sector
					this.props.updatePromptedAutoFillSector(true)
					// fill data to sector (base on first passenger)
					this._autoFillSector()
                }
                onClose = () => {
                    this.setState({ showGeneralDialog: false })
                }
                break
			default:
				break
		}

		return (
			<div>
				<StyledGeneralDialog showDialog={showGeneralDialog} title={generalDialogTitle} onClose={onClose} onAction={onConfirm} actionText={generalDialogActionText}>
					{generalDialogTitle === PASSENGER_HAS_EXISTING_CARD && (
						<Grid container spacing={24} direction={'column'}>
							{objHasData(passengerDataWithCardNo.group) && (
								<Grid item>
									Passenger has already redeemed. View the group{' '}
									<Link to={`/group/group-detail/${passengerDataWithCardNo.group.refNo}`} target='_blank'>
										here
									</Link>
								</Grid>
							)}

							{!objHasData(passengerDataWithCardNo.group) && objHasData(passengerDataWithCardNo.redemptionTransaction) && (
								<Grid item>
									Passenger has already redeemed. View the redemption history{' '}
									<Link to={`/redemption-transaction-detail/${passengerDataWithCardNo.redemptionTransaction.id}`} target='_blank'>
										here
									</Link>
								</Grid>
							)}
						</Grid>
					)}

					{generalDialogTitle === PASSENGER_HAS_EXISTING_GROUP && (
						<Grid container spacing={24} direction={'column'}>
							<Grid item>Do you want to populate data from group?</Grid>
						</Grid>
					)}

                    {generalDialogTitle === PASSENGER_HAS_EXISTING_APPEAL && (
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item>
								<Typography>There is an existing pretravel appeal for this passenger.&nbsp;
									<span className={classes.clickable} onClick={() => this.onLinkClick(this.state.pretravelId)}>
									 Click here.
									</span>
								</Typography>
							</Grid>
                        </Grid>
                    )}


					{generalDialogTitle === PASSENGER_HAS_EXISTING_DRAFT && (
						<Grid container spacing={24} direction={'column'}>
							<Grid item>Do you want to populate data from draft?</Grid>
						</Grid>
					)}

					{generalDialogTitle === PASSENGER_AUTO_FILL_SECTOR && (
						<Grid container spacing={24} direction={'column'}>
							<Grid item>Do you want to auto fill sector values same as first passenger?</Grid>
						</Grid>
					)}
				</StyledGeneralDialog>

				<SqApiCriteriasDialog
					criterias={this.state.criterias}
					criteriasMessage={this.state.criteriasMessage}
					onViewCriteriasClose={this.onViewCriteriasClose}
					viewCriterias={this.state.viewCriterias}
				/>

				<StyledSection title={'E-Ticket Details'} isLoading={isLoading} paperClass={editMode ? editModeClass : ''}>
					<Grid container spacing={24} direction={'column'}>
						<Grid item>
							{eTicketsKeys.map((k, i) => (
								<div key={k}>
									{(currentPaxFormDetails.eTicketsDetails[k] !== '' || i === 0 || showEticketField >= i) && (
										<div className={classes.row} key={k}>
											<div className={classes.rowItem}>
												<div className={classes.label}>
													E-Ticket No. {i + 1} {i === 0 && <span>*</span>}
												</div>
												<div className={classes.control}>
													<div className={classes.tfWrap}>
														<TextField
															autoFocus={i === 0}
															id={'eTicket' + i}
															variant='outlined'
															className={this.state.eTixErrorStore[k] ? classes.textfieldError : classes.textfield}
															// value={currentPaxFormDetails.eTicketsDetails[k]}
															value={this.getETicketValue(k)}
															error={!!this.state.eTixErrorStore[k]}
															onChange={(e) => this.handleETicketsDetailsChange(k, e.target.value)}
															helperText={this.state.eTixErrorStore[k]}
															inputRef={(e) => this.eTicketRefs.push(e)}
														/>
														{this._renderNotiIcon(currentPaxFormDetails.eTicketsDetails[k])}
													</div>
												</div>
											</div>
											<div className={classes.rowItem}>
												<div className={classes.label}>
													E-Ticket {i + 1} Last Name {i === 0 && <span>*</span>}
												</div>
												<div className={classes.control}>
													<TextField
														id='lastName'
														variant='outlined'
														className={classes.textfield}
														// value={currentPaxFormDetails.eTicketsDetails[k + 'LastName']}
														value={this.getETicketLastname(k + 'LastName')}
														onChange={(e) => this.updateETixLastName(k + 'LastName', e.target.value)}
													/>
												</div>
											</div>
										</div>
									)}
								</div>
							))}
						</Grid>
						<Grid container item spacing={24}>
							<Grid item>
								<Button variant='contained' color='primary' onClick={this._checkEligibility} disabled={shouldDisable}>
									Check Eligibility
								</Button>
							</Grid>
							<Grid item>
								{this.state.showEticketField < 3 && (
									<Button variant='contained' color='primary' onClick={this._onAddETicket} disabled={eTicketIsYes}>
										Add E-Ticket
									</Button>
								)}
							</Grid>
						</Grid>
					</Grid>
				</StyledSection>

				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
	row: { display: 'flex', marginTop: 20 },
	rowItem: { display: 'flex', flex: 1, marginRight: 60, alignItems: 'center' },
	label: { flex: '2' },
	control: { flex: '3' },
	textfield: { width: '100%' },
	textfieldError: { height: 60, width: '100%' },
	tfWrap: { position: 'relative' },
	clickable: { cursor: 'pointer', borderBottom: '1px dotted #ccc', color: 'blue' },
})

const mapStateToProps = function(state) {
	const { individualFormDuck, redemptionTransactionDuck, campaignPeriodDuck, systemDataDuck } = state
	const { checkAndUpdateEligibilityIsLoading, editingPaxDetails, draft, draftRedemptionTransaction } = individualFormDuck
	const { redemptionTransaction, createRedemptionTransactionIsLoading } = redemptionTransactionDuck
	return {
		currentPaxFormDetails: individualFormDuck.currentPaxFormDetails,
		promptedRetrieveDraft: individualFormDuck.promptedRetrieveDraft,
		promptedAutoFillSector: individualFormDuck.promptedAutoFillSector,
		editingPaxDetails,
		currentPaxPosition: individualFormDuck.currentPaxPosition,
		formDetailsList: individualFormDuck.formDetailsList,
		checkAndUpdateEligibilityIsLoading,
		redemptionTransaction,
		createRedemptionTransactionIsLoading,
		draft,
		draftRedemptionTransaction,
		individualFormDuck,
		getAllCampaignsIsLoading: campaignPeriodDuck.getAllCampaignsIsLoading,
		systemDataDuck,
	}
}

const mapDispatchToProps = function(dispatch) {
	return {
		updateCurrentPaxFormDetails: (details) => dispatch(updateCurrentPaxFormDetails(details)),
		updateCollectorPersonalDetails: (flightData) => dispatch(updateCollectorPersonalDetails(flightData)),
		checkAndUpdateEligibility: ({ ticketDetails, redemptionTransactionId }) => dispatch(checkAndUpdateEligibility({ ticketDetails, redemptionTransactionId })),
		updateBoardingPassDetails: (bpData) => dispatch(updateBoardingPassDetails(bpData)),
		replaceDraft: () => dispatch(replaceDraft()),
		updatePromptedRetrieveDraft: (bool) => dispatch(updatePromptedRetrieveDraft(bool)),
		updatePromptedAutoFillSector: (bool) => dispatch(updatePromptedAutoFillSector(bool)),
		updateRedemptionTransaction: (redemptionTransaction) => dispatch(updateRedemptionTransaction(redemptionTransaction)),
		findPassengerDataWithCardNo: (data) => dispatch(findPassengerDataWithCardNo(data)),
		findPassengerInGroup: (data) => dispatch(findPassengerInGroup(data)),
		getDraftFromOtherRedemptionTransaction: (data) => dispatch(getDraftFromOtherRedemptionTransaction(data)),
		findPassengersDataWithAppealTransaction: (data) => dispatch(findPassengersDataWithAppealTransaction(data)),
		populatePassengerFromGroup: () => dispatch(populatePassengerFromGroup()),
		individualGroupCheckRedeemedPassenger: (data) => dispatch(individualGroupCheckRedeemedPassenger(data)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(IndividualETicketForm))
