import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Grid, Typography } from '@material-ui/core'
import headerImage from '../images/CAG-CTP-TransitProgramme-Desktop.jpg'
import cardImage from '../images/crCardPrint.png'
import thumb1 from '../images/thumb1.png'
import thumb2 from '../images/thumb2.jpg'
import thumb3 from '../images/thumb3.png'
import thumb4 from '../images/thumb4.jpg'
import topImgText from '../images/CTP_Logo_Final_Black.png';
import {getSettings} from "../config/config.js"
import '../styles/printcard.css'
var QRCode = require('qrcode.react')

class CRCardPrintLayout1 extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			printing: false,
		}
	}

	render() {
		const { classes, cards } = this.props
		// get first card as default card
		// all card in cards have same data
		let card = cards[0]
		if (card.voucherLists)
			card.issuedVouchersList = JSON.parse(card.voucherLists)

		let noOfVoucher = card.issuedVouchersList && card.issuedVouchersList.length > 0 ? card.issuedVouchersList.length : 1
		// count total voucher issued
		noOfVoucher = noOfVoucher * cards.length
		
		const validFrom = card.issuedVouchersList && card.issuedVouchersList.length ? new Date(card.issuedVouchersList[0].ValidFrom) : new Date()
		const validTo = card.issuedVouchersList && card.issuedVouchersList.length ? new Date(card.issuedVouchersList[0].ValidTo) : new Date()

		let settings = getSettings()
		let crVoucher = settings.voucher_sources.find(s => s.membership_type == "CR")
		let genVoucher = crVoucher.vouchers.find(s => s.voucher_type == "GEN")
		const genValue = card.issuedVouchersList && card.issuedVouchersList.length ? card.issuedVouchersList[0].TypeValue : genVoucher.voucher_value
		let lpcVoucher = crVoucher.vouchers.find(s => s.voucher_type == "LPC")
		let voucherCurrency = settings.voucher_currency
		return (<div className={`${classes.page} _page`}>
				<div className={classes.topImgBlock}>
					<img className={classes.topImg} src={headerImage} alt='top-banner' />
					<img className={classes.topImgText} src={topImgText} alt='top-img-text' />
				</div>
				<div className={classes.voucherBlock}>
					<div className={classes.voucherName}>
						<div className={classes.voucherTitle}>
							Here are your Changi Transit Vouchers!
						</div>
						<div className={classes.voucherText}>
							Present the QR code below at participating
							outlets to enjoy your vouchers.
						</div>
					</div>
					<div className={classes.voucherInfo}>
						<div className={classes.cardImg}>
							<img src={cardImage} width={'100%'} alt='cr-card' />
							<div className={classes.cardNum}>
								<div>
									<strong>
										Changi Rewards Card Number:
									</strong>
								</div>
								<div>{card.cardNo}</div>
							</div>
						</div>
						<div className={classes.voucherDetail}>
							<div className={classes.voucherIssueDate}>
								<div>
									<strong>VOUCHER ISSUE DATE:</strong>
								</div>
								<div>{`${validFrom.getDate()} ${validFrom.toLocaleString(
									'default',
									{
										month: 'long',
									},
								)} ${validFrom.getFullYear()}`}</div>
							</div>
							<div className={classes.voucherQuantity}>
								<div>
									<strong>QUANTITY:</strong>
								</div>
								<div>{noOfVoucher} x {voucherCurrency}{genValue} Voucher</div>
								{!card.isQantasFlight && <div>
									{noOfVoucher} x {voucherCurrency}{lpcVoucher.voucher_value} Liquor, Cosmetic & Perfume Voucher
									</div>}
							</div>
							<div className={classes.voucherExpiryDate}>
								<div>
									<strong>
										VOUCHER EXPIRY DATE:
									</strong>
								</div>
								<div>{`${validTo.getDate()} ${validTo.toLocaleString(
									'default',
									{
										month: 'long',
									},
								)} ${validTo.getFullYear()}`}</div>
							</div>
						</div>
						<div className={classes.qrCodeBlock}>
							<div className={classes.qrCodeText}>
								<strong>Scan to use vouchers</strong>
							</div>
							<div className={classes.qrCode}>
								<QRCode value={card.cardNo + '01001' + card.cvc} size={70} />
							</div>
							<div className={classes.qrWarning}>
								<span className={classes.opacityText}>
									Your temporary CR e-Card QR code
									will
								</span>
								<span>
									<strong>
										{' '}
										expire within 7 days.{' '}
									</strong>
								</span>
								<span className={classes.opacityText}>
									Please download the iChangi app or
									login to rewards.changiairport.com
									to access your Changi Rewards
									e-Card.
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className={classes.recommendBlock}>
					<div className={classes.recommendTitle}>
						<strong>RECOMMENDATIONS FOR YOU!</strong>
					</div>
					<div className={classes.recommendContainer}>
						<div className={classes.recommendItem}>
							<div className={classes.thumbImg}>
								<img src={thumb1} width={'100%'} height={'100%'} alt='thumb1' />
							</div>
							<div className={classes.thumbText}>
								<div>
									<strong>
										Try SKII travel exclusives
									</strong>
								</div>
								<div>
									Shop exclusive curation of brands
									for your beauty needs at Shilla
									Cosmetics & Perfumes Stores!
								</div>
							</div>
						</div>
						<div className={classes.recommendItem}>
							<div className={classes.thumbText}>
								<div>
									<strong>Take SG with you</strong>
								</div>
								<div>
									‘Da bao’ (or takeaway) your
									favourite local delicacies to bring
									home, or bring them with you on your
									travels.
								</div>
							</div>
							<div className={classes.thumbImg}>
								<img src={thumb2} alt='thumb1' width={'100%'} height={'100%'} />
							</div>
						</div>
						<div className={classes.recommendItem}>
							<div className={classes.thumbImg}>
								<img src={thumb3} alt='thumb1' width={'100%'} height={'100%'} />
							</div>
							<div className={classes.thumbText}>
								<div>
									<strong>
										Ultimate Destination for Your
										Taste
									</strong>
								</div>
								<div>
									Enjoy over 490 brands with lowest
									price guaranteed
								</div>
								<div className={classes.thumbTextNote}>
									*Price inclusive of promotions
								</div>
							</div>
						</div>
						<div className={classes.recommendItem}>
							<div className={classes.thumbTextLastPanel}>
								<div>
									<strong>
										Shop Tax-Free Online
									</strong>
								</div>
								<div>
									Access travel exclusives and special offers at your fingertips on iShopChangi.com. Enjoy <strong>
										10%*
									</strong> with code <strong>
										CTP_TY2X
									</strong>
								</div>
								<div className={classes.thumbTextNote}>
									*Valid till 31 March 2021. No min.
									spend. Discount is capped at S$10.
									Product exclusions and T&Cs apply.
								</div>
							</div>
							<div className={classes.thumbImg}>
								<img src={thumb4} alt='thumb1' width={'100%'} height={'100%'} />
							</div>
						</div>
					</div>
				</div>
				<div className={classes.infoBlock}>
					<div className={classes.tncTitle}>
						<strong>
							CHANGI TRANSIT VOUCHER TERMS AND CONDITIONS
							(credited into Changi Rewards account)
						</strong>
					</div>
					<div className={classes.tncList}>
						<ul className={classes.tncListUl}>
							<li>
								The Changi Transit Voucher is issued by
								Changi Airport Group (Singapore) Pte Ltd
								(“CAG”).
							</li>
							<li>
								Changi Transit Vouchers may be used at
								all participating merchants at Singapore
								Changi Airport which accepts Changi
								Transit Vouchers, including food and
								beverage outlets. Changi Rewards (“CR”)
								members receive one (1) additional S$20
								Changi Transit Voucher that can be used
								for only Liquor, Perfumes or Cosmetics
								purchases at Singapore Changi Airport.
								All types of Changi Transit Vouchers
								cannot be used to purchase any tobacco
								products at Singapore Changi Airport.
							</li>
							<li>
								The value of the Changi Transit Vouchers
								(or any part thereof, including any
								unutilised portion) (i) is not
								refundable in any currency; (ii) cannot
								be transferred to any other person apart
								from whom the Changi Transit Vouchers is
								issued to; (iii) cannot be withdrawn in
								exchange for any currency; and (iv)
								cannot be exchanged for any form of cash
								or credit. No refund shall be given if
								the value of the Changi Transit Vouchers
								exceeds the value of the goods/ services
								paid for using the Changi Transit
								Vouchers.
							</li>
							<li>
								The Changi Transit Vouchers expire 6
								months from the date of issuance or as
								indicated, whichever is earlier. No
								replacement, exchange, refund or
								extension of validity, of the Changi
								Transit Vouchers will be considered or
								allowed. All unutilized Changi Transit
								Vouchers will be forfeited upon expiry.
								CAG shall not be liable for any expired
								Changi Transit Vouchers.
							</li>
							<li>
								Changi Transit Vouchers can only be used
								in denominations of S$20. For each
								transaction, only one Changi Transit
								Privileges (“CTP”) Card or the Changi
								Transit Vouchers in one CR account may
								be used. If one CTP Card or one CR
								account has more than one Changi Transit
								Vouchers, all the Changi Transit
								Vouchers in that CTP Card or CR account
								may be used in a single transaction. For
								the avoidance of doubt, (a) the Changi
								Transit Vouchers in one CTP card/ CR
								account and the Changi Transit Vouchers
								in another CTP card/ CR account, cannot
								be combined and used in a single
								transaction; (b) the Changi Transit
								Vouchers in a CTP card cannot be
								combined with the Changi Transit
								Vouchers in a CR account and used in a
								single transaction.
							</li>
							<li>
								Each Changi Transit Voucher cannot be
								used for two or more separate
								transactions.
							</li>
							<li>
								The Changi Transit Vouchers may not be
								used in conjunction with ongoing
								in-store promotions and cannot be
								redeemed on iShopChangi.com.
							</li>
							<li>
								The Changi Transit Vouchers may be used
								in conjunction with Singapore
								Airlines/SilkAir Boarding Pass
								Privileges.
							</li>
							<li>
								Any alteration(s) to the QR code printed
								is not allowed.
							</li>
						</ul>
					</div>
				</div>
			</div>)
        }
}

const styles = (theme) => ({
	container: { ...theme.container },
	page: {
		background: 'white',
		width: '21cm',
		height: '29.7cm',
		display: 'block',
		margin: '0 auto',
		marginBottom: '0.5cm',
		boxShadow: '0 0 0.5cm rgba(0,0,0,0.5)',
	},
	topImgBlock: {
		backGroundColor: 'azure',
		height: '13%',
		boxSizing: 'content-box',
		position: 'relative',
	},
	topImg: {
		width: '100%',
		height: '100%',
	},
	topImgText: {
		position: 'absolute',
		top: '30%',
		left: '14%',
		width: '20%',
	},
	voucherBlock: {
		height: '27%',
		boxSizing: 'border-box',
		borderBottom: '10px solid #E3D3C6',
		padding: '2% 6% 0 6%',
	},
	voucherName: {
		textAlign: 'center',
		color: '#781D83',
		margin: '1% 0',
		fontSize: '15px',
	},
	voucherTitle: {
		fontWeight: 'bold',
	},
	voucherText: {
		fontSize: '15px',
	},
	voucherInfo: {
		padding: '0 4%',
		display: 'flex',
		alignItems: 'stretch',
	},
	cardImg: {
		width: '33%',
		marginTop: '15px',
	},
	cardNum: {
		color: '#781D83',
		fontSize: '12px',
		textAlign: 'center',
	},
	voucherDetail: {
		width: '35%',
		padding: '0 0.5% 0 4%',
		fontSize: '12px',
		marginTop: '12px',
	},
	voucherQuantity: {
		marginTop: '20px',
	},
	voucherExpiryDate: {
		marginTop: '20px',
	},
	qrCodeBlock: {
		fontSize: '12px',
	},
	qrCodeText: {
		fontSize: '12px',
		textAlign: 'center',
	},
	qrCode: {
		textAlign: 'center',
		margin: '12px 0',
	},
	qrWarning: {
		fontSize: '10px',
		textAlign: 'justify',
		width: '157px',
		margin: '0',
	},
	opacityText: {
		opacity: '0.6',
	},
	recommendBlock: {
		height: '35%',
		boxSizing: 'border-box',
		borderBottom: '10px solid #E3D3C6',
		padding: '16px 0 15px 0',
	},
	recommendTitle: {
		textAlign: 'center',
		padding: '0 0 15px 0',
	},
	recommendContainer: {
		display: 'flex',
		padding: '0 10%',
		alignItems: 'stretch',
		height: '90%',
		fontSize: '12px',
	},
	recommendItem: {
		width: '25%',
	},
	thumbImg: {
		width: '100%',
		height: '50%',
	},
	thumbText: {
		height: '50%',
		textAlign: 'center',
		padding: '25px 10px',
		boxSizing: 'border-box',
	},
	thumbTextNote: {
		fontSize: '9px',
		opacity: '0.6',
		fontStyle: 'italic',
		padding: '3px 0',
	},
	thumbTextLastPanel: {
		height: '50%',
		textAlign: 'center',
		padding: '10px 7px',
		boxSizing: 'border-box',
	},
	infoBlock: {
		height: '12%',
		boxSizing: 'content-box',
		padding: '16px 5% 0 5%',
		fontSize: '11px',
		textAlign: 'justify',
	},
	tncTitle: {
		paddingLeft: '35px',
	},
	tncList: {
		fontSize: '9px',
	},
	tncListUl: {
		margin: '5px 0',
		opacity: '0.6',
	},
})

export default connect()(withStyles(styles)(CRCardPrintLayout1))
