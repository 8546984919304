import React, {Component} from 'react'
import {Grid, Typography, FormGroup, FormControlLabel, Switch, Divider} from '@material-ui/core'

class UserAccessAccountRightsTable extends Component {

    render() {
        const userAdmin = [
            {key: 'User_Admin.CampaignRead', label: 'Campaign Read'},
            {key: 'User_Admin.CampaignCreate', label: 'Campaign Create'},
            {key: 'User_Admin.CampaignEdit', label: 'Campaign Edit'},
            {key: 'User_Admin.AccountRead', label: 'Account Read'},
            {key: 'User_Admin.AccountCreate', label: 'Account Create'},
            {key: 'User_Admin.AccountEdit', label: 'Account Edit'},
            {key: 'User_Admin.RoleRead', label: 'Role Read'},
            {key: 'User_Admin.RoleCreate', label: 'Role Create'},
            {key: 'User_Admin.RoleEdit', label: 'Role Edit'},
            {key: 'User_Admin.BannerRead', label: 'Banner Read'},
            {key: 'User_Admin.BannerCreate', label: 'Banner Create'},
            {key: 'User_Admin.BannerEdit', label: 'Banner Edit'}
        ]

        const group = [
            {key: 'Group.Approver', label: 'Approver'},
            {key: 'Group.Creator', label: 'Creator'},
            {key: 'Group.Issuer', label: 'Issuer'},
            {key: 'Group.View', label: 'View'},
        ]

        const individual = [
            {key: 'Individual.Issuer', label: 'Issuer'},
            {key: 'Individual.Editor', label: 'Editor'},
            //{key: 'Individual.View', label: 'View'}
        ]

        const appeal = [
            {key: 'Appeal.Editor', label: 'Editor'},
            {key: 'Appeal.Approver', label: 'Approver'},
            {key: 'Appeal.SuperApprover', label: 'SuperApprover'},
        ]

        const search = [
            {key: 'Search.Group', label: 'Group'},
            {key: 'Search.Passenger', label: 'Passenger'}
        ]

        return (
            <Grid item>
                <Grid container spacing={24} alignItems={'center'}>
                    <Grid item md={6}>
                        <Typography variant={'h5'}>Individual</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <FormGroup>
                            {individual.map(i => {
                                return <FormControlLabel
                                    control={<Switch
                                        checked={this.props.data[i.key]}
                                        onChange={() => this.props.onSwitch(i.key)}
                                        color="primary"
                                    />}
                                    label={i.label}
                                />
                            })}
                        </FormGroup>
                    </Grid>
                </Grid>

                <Divider/>


                <Grid container spacing={24} alignItems={'center'}>
                    <Grid item md={6}>
                        <Typography variant={'h5'}>Group</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <FormGroup>
                            {group.map(g => {
                                return <FormControlLabel
                                    control={<Switch
                                        checked={this.props.data[g.key]}
                                        onChange={() => this.props.onSwitch(g.key)}
                                        color="primary"
                                    />}
                                    label={g.label}
                                />
                            })}
                        </FormGroup>
                    </Grid>
                </Grid>

                <Divider/>

                <Grid container spacing={24} alignItems={'center'}>
                    <Grid item md={6}>
                        <Typography variant={'h5'}>Search</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <FormGroup>
                            {search.map(i => {
                                return <FormControlLabel
                                    control={<Switch
                                        checked={this.props.data[i.key]}
                                        onChange={() => this.props.onSwitch(i.key)}
                                        color="primary"
                                    />}
                                    label={i.label}
                                />
                            })}
                        </FormGroup>
                    </Grid>
                </Grid>

                <Divider/>

                <Grid container spacing={24} alignItems={'center'}>
                    <Grid item md={6}>
                        <Typography variant={'h5'}>Pre-Travel(Ad-hoc)</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch
                                    checked={this.props.data['PreTravel.Creator']}
                                    onChange={() => this.props.onSwitch('PreTravel.Creator')}
                                    color="primary"
                                />}
                                label={'Creator'}
                            />
                            <FormControlLabel
                                control={<Switch
                                    checked={this.props.data['PreTravel.Issuer']}
                                    onChange={() => this.props.onSwitch('PreTravel.Issuer')}
                                    color="primary"
                                />}
                                label={'Issuer'}
                            />
                            <FormControlLabel
                                control={<Switch
                                    checked={this.props.data['PreTravel.Editor']}
                                    onChange={() => this.props.onSwitch('PreTravel.Editor')}
                                    color="primary"
                                />}
                                label={'Editor - edit selected fields with comments'}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Divider/>

                <Grid container spacing={24} alignItems={'center'}>
                    <Grid item md={6}>
                        <Typography variant={'h5'}>Appeal</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <FormGroup>
                            {appeal.map(i => {
                                return <FormControlLabel
                                    control={<Switch
                                        checked={this.props.data[i.key]}
                                        onChange={() => this.props.onSwitch(i.key)}
                                        color="primary"
                                    />}
                                    label={i.label}
                                />
                            })}
                        </FormGroup>
                    </Grid>
                </Grid>

                <Divider/>

                <Grid container spacing={24} alignItems={'center'}>
                    <Grid item md={6}>
                        <Typography variant={'h5'}>Retrospective</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch
                                    checked={this.props.data['Retrospective.Creator']}
                                    onChange={() => this.props.onSwitch('Retrospective.Creator')}
                                    color="primary"
                                />}
                                label={'Creator'}
                            />
                            <FormControlLabel
                                control={<Switch
                                    checked={this.props.data['Retrospective.Approver']}
                                    onChange={() => this.props.onSwitch('Retrospective.Approver')}
                                    color="primary"
                                />}
                                label={'Approver'}
                            />
                            <FormControlLabel
                                control={<Switch
                                    checked={this.props.data['Retrospective.Issuer']}
                                    onChange={() => this.props.onSwitch('Retrospective.Issuer')}
                                    color="primary"
                                />}
                                label={'Issuer'}
                            />
                            <FormControlLabel
                                control={<Switch
                                    checked={this.props.data['Retrospective.Editor']}
                                    onChange={() => this.props.onSwitch('Retrospective.Editor')}
                                    color="primary"
                                />}
                                label={'Editor - edit selected fields with comments'}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Divider/>

                <Grid container spacing={24} alignItems={'center'}>
                    <Grid item md={6}>
                        <Typography variant={'h5'}>Voucher Mangement</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch
                                    checked={this.props.data['VoucherManagement.InvalidateEV']}
                                    onChange={() => this.props.onSwitch('VoucherManagement.InvalidateEV')}
                                    color="primary"
                                />}
                                label={'Invalidate eV'}
                            />
                            <FormControlLabel
                                control={<Switch
                                    checked={this.props.data['VoucherManagement.RefundEV']}
                                    onChange={() => this.props.onSwitch('VoucherManagement.RefundEV')}
                                    color="primary"
                                />}
                                label={'Refund eV'}
                            />
                            <FormControlLabel
                                control={<Switch
                                    checked={this.props.data['VoucherManagement.TransferEV']}
                                    onChange={() => this.props.onSwitch('VoucherManagement.TransferEV')}
                                    color="primary"
                                />}
                                label={'Transfer CTP eV to another account'}
                            />
                            <FormControlLabel
                                control={<Switch
                                    checked={this.props.data['VoucherManagement.VoidTransaction']}
                                    onChange={() => this.props.onSwitch('VoucherManagement.VoidTransaction')}
                                    color="primary"
                                />}
                                label={'Void transaction'}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Divider/>

                <Grid container spacing={24} alignItems={'center'}>
                    <Grid item md={6}>
                        <Typography variant={'h5'}>User admin</Typography>
                    </Grid>
                    <Grid item md={6}>
                        {userAdmin.map(p => {
                            return <FormGroup>
                                <FormControlLabel
                                    control={<Switch
                                        checked={this.props.data[p.key]}
                                        onChange={() => this.props.onSwitch(p.key)}
                                        color="primary"
                                    />}
                                    label={p.label}
                                />
                            </FormGroup>
                        })}
                    </Grid>
                </Grid>

                <Divider/>

                <Grid container spacing={24} alignItems={'center'}>
                    <Grid item md={6}>
                        <Typography variant={'h5'}>Reports</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch
                                    checked={this.props.data.Report}
                                    onChange={() => this.props.onSwitch('Report')}
                                    color="primary"
                                />}
                                label={'Reconciliation and Settlement'}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Divider/>

            </Grid>
        )
    }
}

export default UserAccessAccountRightsTable