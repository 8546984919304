import React, {Component} from 'react'
import Header from '../containers/Header'
import {withStyles, Button, Grid} from '@material-ui/core'
import StyledSection from '../components/StyledSection'
import GroupReferenceForm from '../containers/group/GroupReferenceForm'
import GroupHeader from '../containers/group/GroupHeader.js'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import {GROUP_TYPE_PENDING_APPROVAL} from '../constants/groupType'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import AuditLogTable from '../components/AuditLogTable'
import {hasPermissions} from '../helpers/utilities'
import {GROUP_APPROVER_PERMISSION} from '../constants/permissions'
import {Redirect} from 'react-router-dom'

class GroupApprovalDetail extends Component {
    componentDidMount() {
        document.title = `${TITLE_PREFIX} Group - Group Approval Detail`
    }

    onBackToListing = () => {
        this.props.history.push('/group/group-approval')
    }

    render() {
        const {classes, match} = this.props

        const hasApprovalPermissions = hasPermissions([GROUP_APPROVER_PERMISSION])
        if (!hasApprovalPermissions) {
            return <Redirect to={'/404'}/>
        }

        return (
            <div>
                <GlobalTimeChecker/>

                <Header history={this.props.history}/>
                <GroupHeader/>

                <div className={classes.container}>
                    <StyledSection title={'Group Approval Detail'}>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item>
                                <Button color={'primary'} variant="outlined" onClick={this.onBackToListing}>Back to
                                    listing</Button>
                            </Grid>
                            <Grid item>
                                <GroupReferenceForm
                                    selectedGroupId={match.params.id}
                                    location={this.props.location}
                                    history={this.props.history}
                                    afterSubmitUrl={'/group/group-approval'}
                                    confirmButtonText={'Submit'}
                                    groupType={GROUP_TYPE_PENDING_APPROVAL}
                                />
                            </Grid>
                        </Grid>
                    </StyledSection>

                    <AuditLogTable/>

                </div>
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

export default withStyles(styles)(GroupApprovalDetail)