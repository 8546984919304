import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    withStyles,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Checkbox,
    Button
} from '@material-ui/core'
import {toBrowserFriendlyDate} from '../helpers/utilities'
import StyledDeleteBtn from '../components/StyledDeleteBtn'
import StyledUndoBtn from '../components/StyledUndoBtn'
import {
    updatePassenger, markAsDelete, unmarkDelete, updateTourLeaderFromPassengerData
} from '../ducks/groupApprovalDuck'
import {teal, pink, red} from '@material-ui/core/colors'
import {toHumanFriendlyDate} from '../helpers/utilities'
import {convertSqStatusForDisplay} from '../helpers/eticketStatusHelper'

let eTicket = []

class PassengersTableEditable extends Component {
    onFieldChange = (id, k, e) => {
        const v = e.target ? e.target.value : e
        this.props.updatePassenger(id, k, v)
        if (e.target) this._updateTourLeaderFromPassengerData(id, k, e.target.value)
    }

    _updateTourLeaderFromPassengerData(id, k, v) {
        let {passengers} = this.props.groupApprovalDuck
        let targetPassenger = passengers.find(passenger => passenger.id.value === id)
        if (!targetPassenger) return
        if (!targetPassenger.isTourLeader.value) {
            return
        } else {
            this.props.updateTourLeaderFromPassengerData(k, v)
        }

    }

    renderETicketIssue = eTicket => {
        const {classes} = this.props

        return <Button className={classes.eTicketIssue}
            onClick={() => this.props.onETicketIssueClick(eTicket)}>{convertSqStatusForDisplay(eTicket.issue)}</Button>
    }

    renderSqCheckBtn = (pidx, p) => {
        const {classes, viewOnly} = this.props

        return <Button className={classes.sqCheckBtn}
            onClick={() => this.props.onSqCheckBtnClick(pidx, p)}
            disabled={p.sqApiStatus.value === true || viewOnly}>SQ</Button>
    }

    onDelete = id => {
        this.props.markAsDelete(id)
    }

    onUndo = id => {
        this.props.unmarkDelete(id)
    }

    renderIssue = issue => {
        const {classes} = this.props
        return <span className={classes.issue}>{issue}</span>
    }

    getRowClasses = p => {
        const {classes} = this.props

        if (p.markedDelete) return classes.deleted
        if (p.error) return classes.passengerError
        return;
        // if (convertSqStatusForDisplay(p.eTicket1.issue) === 'Y') return classes.statusY
        // if (convertSqStatusForDisplay(p.eTicket1.issue) === 'N') return classes.statusN
    }

    render() {
        const {classes, headers, data, dataKeys, viewOnly, origTlId} = this.props

        return (
            <Paper className={classes.container + " search-result-element"}>
                <Table padding={'dense'}>
                    <TableHead>
                        <TableRow>
                            {headers.map(h => {
                                return <TableCell key={h}>{h}</TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((p, i) => {
                            return <TableRow key={p.id.value + '-' + i}
                                className={this.getRowClasses(p)}>
                                {dataKeys.map(k => {
                                    if (k === 'seq') return <TableCell key={k + i}>
                                        <div>{i + 1}</div>
                                    </TableCell>

                                    if (k === 'firstName') return <TableCell key={k + i}>
                                        <div className={classes.firstNameWrap}>
                                            {p.id.value === origTlId && <span className={classes.tl}>*</span>}
                                            <TextField id="" label="" margin="dense"
                                                value={p[k].value}
                                                onChange={e => this.onFieldChange(p.id.value, k, e)}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.tf
                                                    }
                                                }}
                                                disabled={viewOnly}
                                            />
                                            {this.renderIssue(p[k].issue)}
                                        </div>

                                    </TableCell>

                                    if (k === 'lastName') return <TableCell key={k + i}>
                                        <TextField id="" label="" margin="dense"
                                            value={p[k].value}
                                            onChange={e => this.onFieldChange(p.id.value, k, e)}
                                            InputProps={{
                                                classes: {
                                                    input: classes.tf
                                                }
                                            }}
                                            disabled={viewOnly}
                                        />
                                        {this.renderIssue(p[k].issue)}
                                    </TableCell>

                                    if (k === 'firstFltNo') return <TableCell key={k + i}>
                                        <TextField id="" label="" margin="dense"
                                            value={p[k].value}
                                            onChange={e => this.onFieldChange(p.id.value, k, e)}
                                            InputProps={{
                                                classes: {
                                                    input: classes.tf
                                                }
                                            }}
                                            disabled={viewOnly}
                                        />
                                        {this.renderIssue(p[k].issue)}
                                    </TableCell>

                                    if (k === 'secondFltNo') return <TableCell key={k + i}>
                                        <TextField id="" label="" margin="dense"
                                            value={p[k].value}
                                            onChange={e => this.onFieldChange(p.id.value, k, e)}
                                            InputProps={{
                                                classes: {
                                                    input: classes.tf
                                                }
                                            }}
                                            disabled={viewOnly}
                                        />
                                        {this.renderIssue(p[k].issue)}
                                    </TableCell>

                                    if (k === 'thirdFltNo') return <TableCell key={k + i}>
                                        <TextField id="" label="" margin="dense"
                                            value={p[k].value || ''}
                                            onChange={e => this.onFieldChange(p.id.value, k, e)}
                                            InputProps={{
                                                classes: {
                                                    input: classes.tf
                                                }
                                            }}
                                            disabled={viewOnly}
                                        />
                                        {this.renderIssue(p[k].issue)}
                                    </TableCell>

                                    if (k === 'fourthFltNo') return <TableCell key={k + i}>
                                        <TextField id="" label="" margin="dense"
                                            value={p[k].value || ''}
                                            onChange={e => this.onFieldChange(p.id.value, k, e)}
                                            InputProps={{
                                                classes: {
                                                    input: classes.tf
                                                }
                                            }}
                                            disabled={viewOnly}
                                        />
                                        {this.renderIssue(p[k].issue)}
                                    </TableCell>

                                    if (k === 'dob') return <TableCell key={k + i}>
                                        <TextField id="" label="" margin="dense" type={'date'}
                                            value={toBrowserFriendlyDate(p[k].value)}
                                            onChange={e => this.onFieldChange(p.id.value, k, e)}
                                            InputProps={{
                                                classes: {
                                                    input: classes.tf
                                                }
                                            }}
                                            disabled={viewOnly}
                                        />
                                        {p[k].issue}
                                    </TableCell>

                                    if (k === 'firstFltDepDate') return <TableCell
                                        key={p.id.value + k}>
                                        <TextField id="" label="" margin="dense" type={'date'}
                                            value={toBrowserFriendlyDate(p[k].value)}
                                            onChange={e => this.onFieldChange(p.id.value, k, e)}
                                            InputProps={{
                                                classes: {
                                                    input: classes.tf
                                                }
                                            }}
                                            disabled={viewOnly}
                                        />
                                        {p[k].issue}
                                    </TableCell>

                                    if (k === 'secondFltDepDate') return <TableCell
                                        key={p.id.value + k}>
                                        <TextField id="" label="" margin="dense" type={'date'}
                                            value={toBrowserFriendlyDate(p[k].value) || ""}
                                            onChange={e => this.onFieldChange(p.id.value, k, e)}
                                            InputProps={{
                                                classes: {
                                                    input: classes.tf
                                                }
                                            }}
                                            disabled={viewOnly}
                                        />
                                        {p[k].issue}
                                    </TableCell>

                                    if (k === 'thirdFltDepDate') return <TableCell
                                        key={p.id.value + k}>
                                        <TextField id="" label="" margin="dense" type={'date'}
                                            value={toBrowserFriendlyDate(p[k].value) || ""}
                                            onChange={e => this.onFieldChange(p.id.value, k, e)}
                                            InputProps={{
                                                classes: {
                                                    input: classes.tf
                                                }
                                            }}
                                            disabled={viewOnly}
                                        />
                                        {p[k].issue}
                                    </TableCell>

                                    if (k === 'fourthFltDepDate') return <TableCell
                                        key={p.id.value + k}>
                                        <TextField id="" label="" margin="dense" type={'date'}
                                            value={toBrowserFriendlyDate(p[k].value) || ""}
                                            onChange={e => this.onFieldChange(p.id.value, k, e)}
                                            InputProps={{
                                                classes: {
                                                    input: classes.tf
                                                }
                                            }}
                                            disabled={viewOnly}
                                        />
                                        {p[k].issue}
                                    </TableCell>

                                    if (k === 'cardNo') {
                                        return <TableCell key={p.id.value + k}>
                                            {p.cardNo.value}
                                        </TableCell>
                                    }

                                    if (k === 'preloadedVouchersCardNo') {
                                        return <TableCell key={p.id.value + k}>
                                            {p.preloadedVouchersCardNo.value}
                                        </TableCell>
                                    }

                                    if (k === 'genPhysicalVoucherNo') {
                                        return <TableCell key={p.id.value + k}>
                                            {p.genPhysicalVoucherNo.value}
                                        </TableCell>
                                    }

                                    if (k === 'lpcPhysicalVoucherNo') {
                                        return <TableCell key={p.id.value + k}>
                                            {p.lpcPhysicalVoucherNo.value}
                                        </TableCell>
                                    }

                                    if (k === 'voucherValue') {
                                        return <TableCell key={p.id.value + k}>
                                            {p.voucherValue.value}
                                        </TableCell>
                                    }

                                    if (k === 'isTourLeader') {
                                        return <TableCell key={p.id.value + k}>
                                            {p.isTourLeader.value && 'Yes'}
                                        </TableCell>
                                    }

                                    if (k === 'eTicket') {
                                        const eTickets = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
                                        eTickets.forEach(k2 => {
                                            if (p[k2]) eTicket.push(p[k2])
                                        })
                                        const final = eTicket.map((v, i2) => {
                                            if (v.value !== null && v.issue !== null) {
                                                return <li key={k + i2}>
                                                    <div className={classes.eticketwrap}>
                                                        <TextField id="" label="" margin="dense"
                                                            value={v.value}
                                                            onChange={e => this.onFieldChange(p.id.value, 'eTicket' + (i2 + 1), e)}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.tf
                                                                }
                                                            }}
                                                            disabled={viewOnly}
                                                        />
                                                        {this.renderETicketIssue(v)}
                                                        {!viewOnly && this.renderSqCheckBtn(i, p)}
                                                    </div>
                                                </li>
                                            }
                                        })
                                        eTicket = []

                                        return <TableCell key={k + i}>
                                            <ol className={classes.ol}>{final}</ol>
                                        </TableCell>
                                    }

                                    if (k === 'pnr') {
                                        return <TableCell key={p.id.value + k}>
                                            <p>{p.pnr.value}</p>

                                            {this.renderIssue(p.error && p.error.message)}
                                        </TableCell>
                                    }

                                    if (k === 'collectionDate') {
                                        return <TableCell key={k + i}>{toHumanFriendlyDate(p.collectionDate.value)}</TableCell>
                                    }

                                    if (k === 'approve' && !viewOnly) {
                                        return <TableCell key={k + i}>
                                            <Checkbox
                                                checked={p.approve.value}
                                                disabled={p.sqApiStatus.value === true || p.markedDelete}
                                                color={'primary'}
                                                onChange={() => this.onFieldChange(p.id.value, 'approve', !p.approve.value)}
                                            />
                                        </TableCell>
                                    }

                                    if (k === 'delete' && !viewOnly) {
                                        return <TableCell key={k + i}>
                                            {!p.markedDelete &&
                                            <StyledDeleteBtn
                                                disabled={p.sqApiStatus.value}
                                                onClick={() => this.onDelete(p.id.value)}/>}

                                            {p.markedDelete &&
                                            <StyledUndoBtn
                                                disabled={p.sqApiStatus.value}
                                                onClick={() => this.onUndo(p.id.value)}/>}
                                        </TableCell>
                                    }
                                })}
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </Paper>
        )
    }
}

const styles = theme => ({
    container: theme.container,
    ol: {padding: '0 1rem', fontSize: '.6rem', width: '12rem'},
    tf: {fontSize: '.8rem'},
    eTicketIssue: {
        color: theme.palette.secondary.main,
        display: 'inline',
        border: 0,
        position: 'absolute',
        fontSize: '.8rem',
        top: '.7rem',
        right: '.5rem',
        minWidth: 'auto',
        minHeight: 'auto',
        width: '1.2rem',
        height: '1.2rem',
        padding: 0
    },
    sqCheckBtn: {
        display: 'inline',
        border: 0,
        position: 'absolute',
        fontSize: '.8rem',
        top: '.7rem',
        right: '-1.3rem',
        minWidth: 'auto',
        minHeight: 'auto',
        width: '1.2rem',
        height: '1.2rem',
        padding: 0
    },
    deleted: {
        background: '#eee',
        '& td input': {
            color: '#bbb',
            textDecoration: 'line-through'
        }
    },
    statusY: {background: teal[50]},
    statusN: {background: pink[50]},
    passengerError: {background: pink[50]},
    issue: {color: red['A100']},
    eticketwrap: {position: 'relative'},
    firstNameWrap: {position: 'relative'},
    tl: {position: 'absolute', top: '.9rem', left: '-1rem'}
})

const mapStateToProps = state => {
    const {groupApprovalDuck} = state

    return {groupApprovalDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        updatePassenger: (i, k, v) => dispatch(updatePassenger(i, k, v)),
        markAsDelete: id => dispatch(markAsDelete(id)),
        unmarkDelete: id => dispatch(unmarkDelete(id)),
        updateTourLeaderFromPassengerData: (k, v) => dispatch(updateTourLeaderFromPassengerData(k, v))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PassengersTableEditable))