import axios from 'axios'
import {getSettings} from '../config/config'
import {getCurrentAuthHeader} from '../helpers/utilities'
import {checkValidStatusCode} from "../helpers/httpHelper";

let settings = getSettings();
let baseURL = settings.baseURL;

const GET_ALL_CAMPAIGNS_IS_LOADING = 'GET_ALL_CAMPAIGNS_IS_LOADING'
const GET_ALL_CAMPAIGNS_SUCCESS = 'GET_ALL_CAMPAIGNS_SUCCESS'
const GET_ALL_CAMPAIGNS_FAIL = 'GET_ALL_CAMPAIGNS_FAIL'
const UPDATE_CAMPAIGN_IS_LOADING = 'UPDATE_CAMPAIGN_IS_LOADING'
const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS'
const UPDATE_CAMPAIGN_FAIL = 'UPDATE_CAMPAIGN_FAIL'
const DELETE_CAMPAIGN_IS_LOADING = 'DELETE_CAMPAIGN_IS_LOADING'
const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS'
const DELETE_CAMPAIGN_FAIL = 'DELETE_CAMPAIGN_FAIL'
const ADD_CAMPAIGN_IS_LOADING = 'ADD_CAMPAIGN_IS_LOADING'
const ADD_CAMPAIGN_SUCCESS = 'ADD_CAMPAIGN_SUCCESS'
const ADD_CAMPAIGN_FAIL = 'ADD_CAMPAIGN_FAIL'

export const getAllCampaigns = () => async dispatch => {
    dispatch(_getAllCampaignsIsLoading())

    const rs = await axios.get(baseURL + '/Campaign/GetAll', getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_getAllCampaignsSuccess(rs.data.result.items))
        return {success: true, message: ''}
    } else {
        dispatch(_getAllCampaignsFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _getAllCampaignsIsLoading = () => ({
    type: GET_ALL_CAMPAIGNS_IS_LOADING
})

const _getAllCampaignsSuccess = campaigns => ({
    type: GET_ALL_CAMPAIGNS_SUCCESS,
    campaigns
})

const _getAllCampaignsFail = () => ({
    type: GET_ALL_CAMPAIGNS_FAIL
})

export const updateCampaign = data => async dispatch => {
    dispatch(_updateCampaignIsLoading())

    const rs = await axios.put(baseURL + '/Campaign/Update', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_updateCampaignSuccess(rs.data.result))
        return {success: true, message: ''}
    } else {
        dispatch(_updateCampaignFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _updateCampaignIsLoading = () => ({
    type: UPDATE_CAMPAIGN_IS_LOADING
})

const _updateCampaignSuccess = campaign => ({
    type: UPDATE_CAMPAIGN_SUCCESS,
    campaign
})

const _updateCampaignFail = () => ({
    type: UPDATE_CAMPAIGN_FAIL
})

export const deleteCampaign = data => async dispatch => {
    dispatch(_deleteCampaignIsLoading())

    const newdata = {...data, ...getCurrentAuthHeader()}
    const rs = await axios.delete(baseURL + '/Campaign/Delete', newdata).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_deleteCampaignSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_deleteCampaignFail())
        return {success: false, message: rs.data.error.message}
    }
}

export const addCampaign = data => async dispatch => {
    dispatch(_addCampaignIsLoading())

    const rs = await axios.post(baseURL + '/Campaign/Create', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_addCampaignSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_addCampaignFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _addCampaignIsLoading = () => ({
    type: ADD_CAMPAIGN_IS_LOADING
})

const _addCampaignSuccess = rs => ({
    type: ADD_CAMPAIGN_SUCCESS,
    rs
})

const _addCampaignFail = () => ({
    type: ADD_CAMPAIGN_FAIL
})

const _deleteCampaignIsLoading = () => ({
    type: DELETE_CAMPAIGN_IS_LOADING
})

const _deleteCampaignSuccess = rs => ({
    type: DELETE_CAMPAIGN_SUCCESS,
    rs
})

const _deleteCampaignFail = () => ({
    type: DELETE_CAMPAIGN_FAIL
})

const initState = {
    getAllCampaignsIsLoading: false,
    campaigns: [],
    updateCampaignIsLoading: false,
    deleteCampaignIsLoading: false,
    addCampaignIsLoading: false
}

const CampaignPeriodDuck = (state = initState, action) => {
    let newCampaign, newCampaigns

    switch (action.type) {
        case GET_ALL_CAMPAIGNS_IS_LOADING:
            return {...state, getAllCampaignsIsLoading: true}

        case GET_ALL_CAMPAIGNS_SUCCESS:
            return {...state, getAllCampaignsIsLoading: false, campaigns: action.campaigns}

        case GET_ALL_CAMPAIGNS_FAIL:
            return {...state, getAllCampaignsIsLoading: false}

        case UPDATE_CAMPAIGN_IS_LOADING:
            return {...state, updateCampaignIsLoading: true}

        case UPDATE_CAMPAIGN_SUCCESS:
            const idx = state.campaigns.findIndex(c => c.id === action.campaign.id)
            if (idx !== -1) {
                newCampaign = {...state.campaigns[idx], ...action.campaign}
                newCampaigns = [...state.campaigns]
                newCampaigns = newCampaigns.splice(idx, 1, newCampaign)
                return {...state, updateCampaignIsLoading: false, campaigns: newCampaigns}
            }
            return state

        case UPDATE_CAMPAIGN_FAIL:
            return {...state, updateCampaignIsLoading: false}

        case DELETE_CAMPAIGN_IS_LOADING:
            return {...state, deleteCampaignIsLoading: true}

        case DELETE_CAMPAIGN_SUCCESS:
            return {...state, deleteCampaignIsLoading: false}

        case DELETE_CAMPAIGN_FAIL:
            return {...state, deleteCampaignIsLoading: false}

        case ADD_CAMPAIGN_IS_LOADING:
            return {...state, addCampaignIsLoading: true}

        case ADD_CAMPAIGN_SUCCESS:
            return {...state, addCampaignIsLoading: false}

        case ADD_CAMPAIGN_FAIL:
            return {...state, addCampaignIsLoading: false}

        default:
            return state
    }
}

export default CampaignPeriodDuck