import React from 'react';
import PropTypes from 'prop-types';
import {Typography, Button} from '@material-ui/core';

class SectionHeader extends React.Component {

    render() {
        return (
            <div style={styles.container.styles}>
                <div style={styles.container.textWrapper.styles}>
                    <Typography variant='h5'> {this.props.title} </Typography>
                </div>
                {this.props.showButton && (
                    <div style={styles.container.btnWrapper.styles}>
                        <Button
                            variant="contained"
                            color={this.props.color || 'primary'}
                            onClick={this.props.onButtonClick}
                        >
                            {this.props.buttonText}
                        </Button>
                    </div>
                )}
            </div>
        );
    }

}

const styles = {
    container: {
        styles: {
            width: '100%',
            display: 'flex'
        },
        textWrapper:{
            styles: {
            }
        },
        btnWrapper:{
            styles: {
                marginLeft: 20,
            }
        }

    },
};

SectionHeader.propTypes = {
    title: PropTypes.string.isRequired,
    showButton: PropTypes.bool,
    buttonText: function(props, propName, componentName) {
        if ((props['showButton'] === true && (props[propName] === undefined || typeof(props[propName]) !== 'string'))) {
            return new Error(
                'Please provide a buttonText string!'
            );
        }
    },
    onButtonClick: function(props, propName, componentName) {
        if ((props['showButton'] === true && (props[propName] === undefined || typeof(props[propName]) !== 'function'))) {
            return new Error(
                'Please provide a onButtonClick function!'
        );
        }
    },
};

export default SectionHeader;