import React, {PureComponent} from 'react'
import classNames from 'classnames'
import {withStyles, Grid, Typography, Toolbar, Paper} from '@material-ui/core'
import LoadingContainer from '../containers/loadingContainer'
import PropTypes from 'prop-types'

class StyledSection extends PureComponent {

    render() {
        const {classes, isLoading, GroupSubmitted, paperClass} = this.props

        return (
            <div>
                <div className={classes.header}>
                    <Typography variant={'h6'}>{this.props.title}</Typography>
                </div>
                <Paper className={classNames(classes.paper, paperClass)}>
                    {this.props.toolbarButtons &&
                    <Toolbar className={[classes.toolbar, 'toolbar'].join(' ')}>
                        <Grid container spacing={24} justify={'flex-end'}>
                            {(GroupSubmitted !== undefined && GroupSubmitted) && <Typography variant={'h5'}>Group Collected</Typography>}
                            {(GroupSubmitted !== undefined && GroupSubmitted) && <span style={{flexGrow:'1'}}></span>}
                            {this.props.toolbarButtons().map((b, i) => <Grid item key={i}>{b}</Grid>)}
                        </Grid>
                    </Toolbar>}

                    <div className={classes.container}>
                        {this.props.children}
                    </div>

                    {/* LoadingContainer will show when isLoading is true */}
                    {(isLoading !== undefined && isLoading) && <LoadingContainer show={true}/>}
                </Paper>
            </div>

        )
    }
}

const styles = theme => ({
    container: {...theme.container, marginBottom: '1rem'},
    header: {padding: '.5rem 1rem'},
    paper: {position: 'relative', minHeight: '5rem'},
    toolbar: {paddingTop: '1rem'}
})

StyledSection.propTypes = {
    title: PropTypes.string,
    isLoading: PropTypes.bool,
    GroupSubmitted: PropTypes.bool,
    toolbarButtons: PropTypes.func
}

export default withStyles(styles)(StyledSection)
