import { combineReducers } from 'redux'
import individualFormDuck from './individualFormDuck.js'
import systemErrorReducer from './systemError.js'
import authDuck from './authDuck.js'
import groupApprovalDuck from './groupApprovalDuck.js'
import groupRedemptionFormDuck from './groupRedemptionFormDuck.js'
import userAccessAccountDuck from './userAccessAccountDuck'
import groupManifestDuck from './groupManifestDuck'
import createNewAccountDuck from './createNewAccountDuck'
import systemDataDuck from './systemDataDuck'
import flightDuck from './flightDuck.js'
import campaignPeriodDuck from './campaignPeriodDuck'
import redemptionTransactionDuck from './redemptionTransactionDuck'
import auditLogDuck from './auditLogDuck'
import bannerDuck from './bannerDuck'
import searchDuck from './searchDuck'
import passengerDuck from './passengerDuck'
import redemptionTransactionPassengersDuck from './redemptionTransactionPassengersDuck'
import groupStatsDuck from './groupStatsDuck'
import iataDuck from './iataDuck'
import physicalVoucherDuck from './physicalVoucherDuck'
import consentDerivedDuck from './derivedDucks/consentDerivedDuck'
import individualCollectorOptionDuck from './individualCollectorOptionDuck'
import printCardDuck from './printCardDuck'
import appealTransactionDuck from './appealTransactionDuck'
import reprintAuditLogDuck from './reprintAuditLogDuck'

export default combineReducers({
	individualFormDuck,
	systemErrorReducer,
	authDuck,
	groupApprovalDuck,
	groupRedemptionFormDuck,
	userAccessAccountDuck,
	groupManifestDuck,
	createNewAccountDuck,
	systemDataDuck,
	flightDuck,
	campaignPeriodDuck,
	redemptionTransactionDuck,
	auditLogDuck,
	bannerDuck,
	searchDuck,
	passengerDuck,
	redemptionTransactionPassengersDuck,
	groupStatsDuck,
	iataDuck,
	physicalVoucherDuck,
	consentDerivedDuck,
	individualCollectorOptionDuck,
	printCardDuck,
	appealTransactionDuck,
	reprintAuditLogDuck,
})
