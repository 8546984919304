import React, {Component} from 'react'
import {
    withStyles,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel
} from '@material-ui/core'
import {getGroupLabel} from '../../helpers/groupStatusHelper'
import {toHumanFriendlyDate} from '../../helpers/utilities'

class SearchGroupResultTable extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            order : 'asc',
            orderBy : 'cardNo'
        }
    }
    onLinkClick = refNo => {
        const url = '/group/group-detail/'
        window.open(url + refNo)
    }

    _getGroupStatus = d => {
        return <span>{getGroupLabel(d)}</span>
    }

    descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }

      getComparator = (order, orderBy) => {
        if(orderBy==='status'){
          return order === 'desc'
          ? (a, b) => {
            a.statusOrder =  this._getGroupStatus(a).props.children;
            b.statusOrder =  this._getGroupStatus(b).props.children;
           return this.descendingComparator(a, b, 'statusOrder');
          }
          : (a, b) => {
            a.statusOrder =  this._getGroupStatus(a).props.children;
            b.statusOrder =  this._getGroupStatus(b).props.children;
           return  -this.descendingComparator(a, b, 'statusOrder');
          }
        }
        return order === 'desc'
          ? (a, b) => this.descendingComparator(a, b, orderBy)
          : (a, b) => -this.descendingComparator(a, b, orderBy);
      }

      stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
      }

    render() {
        const {classes, data, fields} = this.props
        const {order, orderBy} = this.state
        const handleRequestSort = (event, property) => {
            const isAsc = orderBy !== property || orderBy === property && order === 'desc' ;
            this.setState({order:isAsc ? 'asc' : 'desc', orderBy: property})
          };
        const visuallyHidden = {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
          };
        function EnhancedTableHead(props) {
            const { onRequestSort } = props;
            const createSortHandler = property => event => {
              onRequestSort(event, property);
            };
            return (
              <TableHead>
                <TableRow>
                  {fields.map(headCell => (
                    <TableCell
                      key={headCell.key}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === headCell.key ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.key}
                        direction={orderBy === headCell.key ? order : 'asc'}
                        onClick={createSortHandler(headCell.key)}
                      >
                        {headCell.label}
                        {orderBy === headCell.key ? (
                          <span style={visuallyHidden} >
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            );
          }
        return (
            <div>
                <Paper className={classes.container, "search-result-element"}>
                    <Table>
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {this.stableSort(data,this.getComparator(order,orderBy)).map((d, idx) => {
                                return <TableRow key={d.id}>
                                    {fields.map(f => {
                                        return <TableCell key={d.id + f.key}>

                                            {f.key === 'refNo' && <span onClick={() => this.onLinkClick(d[f.key])}
                                                className={classes.clickable}>{d[f.key]}</span>}

                                            {f.key === 'paxCount' && <span>{d[f.key]}</span>}

                                            {f.key === 'firstFlightArrivalDate' &&
                                            <span>{toHumanFriendlyDate(d[f.key])}</span>}

                                            {f.key === 'lastFlightDepartureDate' &&
                                            <span>{toHumanFriendlyDate(d[f.key])}</span>}

                                            {f.key === 'collectionDate' && <span>{toHumanFriendlyDate(d[f.key])}</span>}
                                            {f.key === 'tourCompany' && <span>{d[f.key]}</span>}
                                            {f.key === 'tourLeader' &&
                                            <span>{`${d[f.key][0].firstName} ${d[f.key][0].lastName}`}</span>}

                                            {f.key === 'submitterName' && <span>{d[f.key]}</span>}
                                            {f.key === 'approverName' && <span>{d[f.key]}</span>}
                                            {f.key === 'submissionTime' && <span>{toHumanFriendlyDate(d[f.key])}</span>}
                                            {f.key === 'status' && <span>{this._getGroupStatus(d)}</span>}
                                        </TableCell>
                                    })}
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }
}

const styles = theme => ({
    container: theme.container,
    eticket: {padding: '0 1rem'},
    clickable: {cursor: 'pointer', borderBottom: '1px dotted #ccc'}
})

export default withStyles(styles)(SearchGroupResultTable)