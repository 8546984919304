import React, { Component } from 'react'
import {
	withStyles,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	TablePagination
} from '@material-ui/core'
import { connect } from 'react-redux'
import { getAppealTransactionDetail } from '../ducks/appealTransactionDuck'
import {lime} from '@material-ui/core/colors'
import {toBrowserFriendlyDate, toHumanFriendlyDateTime} from "../helpers/utilities";
import { processAppealTransaction } from "../ducks/appealTransactionDuck"
import { getCurrentAuthHeader } from '../helpers/utilities'
import StyledSnackbar from '../components/StyledSnackbar'
import StyledPagination from '../components/StyledPagination'

class AppealTransactionTable extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			showSnackbar: false,
    		snackbarMessage: '',
			snackbarVariant: 'success',
		}
	}

	onLinkClick = async (refNo) => {
		const data = { id: refNo }
		const rs = await this.props.processAppealTransaction(data)
		if (rs.success) {
            const url = '/appeal-transaction-detail/'
			window.open(url + refNo)
        } else {
            this.setState({
                showSnackbar: true,
                snackbarMessage: rs.message,
                snackbarVariant: 'error',
                parentDidSumit: false,
            })
        }
	}

	closeSnackbar = () => {
        this.setState({ showSnackbar: false })
	}

	render() {
		const { classes, data, fields, currentIdx, count, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = this.props
		return (
			<div className="search-result-element">
					<Table>
                        <TableHead>
          					<TableRow>
            					<TableCell>S/N</TableCell>
            					<TableCell>Pretravel ID</TableCell>
            					<TableCell>CR Member ID</TableCell>
            					<TableCell>First Name</TableCell>
            					<TableCell>Last Name</TableCell>
                                <TableCell>Date/Time of Request</TableCell>
								<TableCell>ETicket</TableCell>
            					<TableCell>Booking Ref No</TableCell>
								<TableCell>Pretravel Status</TableCell>
            					<TableCell>CSO User Name</TableCell>
            					<TableCell>Date/Time CSO Responded</TableCell>
          					</TableRow>
        				</TableHead>
						<TableBody>
          						{data && data.map((row,ridx) => (
            					<TableRow key={row.id} className={ridx === currentIdx ? classes.editing : ''}>
              						<TableCell component="th" scope="row">
                						{ridx + 1}
              						</TableCell>
              						<TableCell>
									 	<span className={classes.clickable} onClick={() => this.onLinkClick(row.id)}>
									  		{row.refNo}
										</span>
									</TableCell>
              						<TableCell>{row.memberId}
									</TableCell>
              						<TableCell>{row.firstName}</TableCell>
              						<TableCell>{row.lastName}</TableCell>
                                    <TableCell>{toHumanFriendlyDateTime(row.creationTime)}</TableCell>
									{/* ETicket */}
									{row.eTicket1 && (<TableCell>{row.eTicket1}</TableCell>)}
								  	{row.eTicket1 === null && (<TableCell></TableCell>)}
              						<TableCell>{row.bookingRefNo}</TableCell>
									{/* Status */}
									{row.approval === true && (<TableCell>APPROVED</TableCell>)}
									{row.approval === false && row.rejected === true &&(<TableCell>REJECTED</TableCell>)}
									{row.approval === false && row.rejected === false &&(<TableCell>PENDING</TableCell>)}
									{/* CSO User Name */}
              						{row.approval === true && (<TableCell>{row.approvedUserName}</TableCell>)}
									{row.approval === false && row.rejected === true && row.rejectedBy && (<TableCell>{row.rejectedUserName}</TableCell>)}
									{row.approval === false && row.rejected === false && (<TableCell></TableCell>)}
									{/* Date/Time CSO Responded */}
									{row.approval === true && row.approvedTime && (<TableCell>{toHumanFriendlyDateTime(row.approvedTime)}</TableCell>)}
									{row.approval === false && row.rejected === true && row.rejectedTime && (<TableCell>{toHumanFriendlyDateTime(row.rejectedTime)}</TableCell>)}
									{row.approval === false && row.rejected === false && (<TableCell></TableCell>)}
            					</TableRow>
          						))}
        				</TableBody>
						<TableFooter>
							<TableRow>
								<TablePagination
									count={count}
									page={page}
									rowsPerPage={rowsPerPage}
									onChangePage={onChangePage}
									onChangeRowsPerPage={onChangeRowsPerPage}
									rowsPerPageOptions={[]}
									ActionsComponent={StyledPagination}
								></TablePagination>
							</TableRow>
						</TableFooter>
					</Table>
					<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const { appealTransactionDuck } = state
	return { appealTransactionDuck }
}

const mapDispatchToProps = (dispatch) => {
	return {
		processAppealTransaction: (data) => dispatch(processAppealTransaction(data)),
	}
}

const styles = (theme) => ({
	container: theme.container,
	eticket: { padding: '0 1rem' },
	clickable: { cursor: 'pointer', borderBottom: '1px dotted #ccc' },
	editing: {backgroundColor: lime[50]}
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(AppealTransactionTable))
