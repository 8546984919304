import React from 'react'
import {connect} from 'react-redux'
import {
    updateAllConsents
} from "../../ducks/derivedDucks/consentDerivedDuck";
import {generateCollectionAgreementMessage, templateData, urlData} from "../../constants/consentMessages";

class ConsentDerivedDuckContainer extends React.Component {

    componentDidMount() {
        this.updateConsent();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.formDetailsList !== this.props.formDetailsList ||
            prevProps.collectorDetails !== this.props.collectorDetails ||
            prevProps.individualCollectorOptionDuck.isExistingCr !== this.props.individualCollectorOptionDuck.isExistingCr ||
            prevProps.individualCollectorOptionDuck.isExistingCrCard !== this.props.individualCollectorOptionDuck.isExistingCrCard ||
            prevProps.individualCollectorOptionDuck.isRegisterCr !== this.props.individualCollectorOptionDuck.isRegisterCr ||
            prevProps.individualCollectorOptionDuck.isSingleCrCard !== this.props.individualCollectorOptionDuck.isSingleCrCard ||
            prevProps.individualCollectorOptionDuck.isCtp !== this.props.individualCollectorOptionDuck.isCtp ||
            prevProps.individualCollectorOptionDuck.isSingleCtpCard !== this.props.individualCollectorOptionDuck.isSingleCtpCard ||
            prevProps.individualCollectorOptionDuck.isMultipleCtpCard !== this.props.individualCollectorOptionDuck.isMultipleCtpCard ||
            prevProps.individualCollectorOptionDuck.isCtpPhysicalVoucher !== this.props.individualCollectorOptionDuck.isCtpPhysicalVoucher

        ){
            this.updateConsent();
        }
    }

    updateConsent(){
        const {individualCollectorOptionDuck} = this.props
        let singleCard = individualCollectorOptionDuck.isExistingCrCard || individualCollectorOptionDuck.isSingleCrCard || individualCollectorOptionDuck.isSingleCtpCard

        let voucherData = this._getOverallVoucherData(this.props.formDetailsList);

        let consentData = this._buildConsentMessages({
            voucherData: voucherData,
            individualCollectorOptionDuck: this.props.individualCollectorOptionDuck})

        this.props.updateAllConsents({
            collectionConsent: consentData.collectionConsent,
            airlineConsent: consentData.airlineConsent,
            marketingConsent: consentData.marketingConsent
        })
    }

    _getOverallVoucherData(passengers){
        let overallVoucherData = {
            totalCtpGenValue: 0,
            totalCtpLpcValue: 0,
            undeletedPax: 0,
        };

        for (const p of passengers) {
            if (!p.isDeleted) {
                // 2 wat transit
                overallVoucherData.totalCtpGenValue = parseInt(overallVoucherData.totalCtpGenValue) + parseInt(p.ctpGenValue)
                overallVoucherData.totalCtpLpcValue = parseInt(overallVoucherData.totalCtpLpcValue) + parseInt(p.ctpLpcValue)
                overallVoucherData.undeletedPax++
            }
        }
        return overallVoucherData
    }

    _buildConsentMessages = ({ voucherData, individualCollectorOptionDuck }) => {
        let collectionConsent, marketingConsent, airlineConsent;
        airlineConsent = voucherData.undeletedPax > 1 ? templateData.airlineGroupConsent : templateData.airlineConsent;
        marketingConsent = templateData.marketingConsent;

        if (individualCollectorOptionDuck.isRegisterCr && voucherData.undeletedPax < 2) {
            collectionConsent = generateCollectionAgreementMessage({
                agreementMessage: templateData.crRegistrationAgreement,
                voucherDataCollected: voucherData,
                templateData: templateData,
                urlData: urlData
            })
        }
        else if (!individualCollectorOptionDuck.isRegisterCr && voucherData.undeletedPax < 2) {
            collectionConsent = generateCollectionAgreementMessage({
                agreementMessage: templateData.ctpIndividualCollectionAgreement,
                voucherDataCollected: voucherData,
                templateData: templateData,
                urlData: urlData
            })
        }
        else if (individualCollectorOptionDuck.isCtp && !individualCollectorOptionDuck.isRegisterCr && voucherData.undeletedPax > 1) {
            collectionConsent = generateCollectionAgreementMessage({
                agreementMessage: templateData.ctpFamilyCollectionAgreementMessage,     //Case 2
                voucherDataCollected: voucherData,
                templateData: templateData,
                urlData: urlData
            })
        }
        else if (!individualCollectorOptionDuck.isCtp && voucherData.undeletedPax > 1 && voucherData.undeletedPax < 9) {
            collectionConsent = generateCollectionAgreementMessage({
                agreementMessage: templateData.ctpFamilyWithCrtCollectionAgreementMessage,
                voucherDataCollected: voucherData,
                templateData: templateData,
                urlData: urlData
            })
        }
        else {
            collectionConsent = generateCollectionAgreementMessage({
                agreementMessage: templateData.ctpGroupCollectionAgreementMessage,
                voucherDataCollected: voucherData,
                templateData: templateData,
                urlData: urlData
            })
        }

        let consentData = { airlineConsent, marketingConsent, collectionConsent}
        return consentData

    }


    render() {

        return (
            <div>

            </div>
        )
    }
}

const mapStateToProps = function (state) {
    const stateClone = {...state}
    const individualFormDuck = stateClone.individualFormDuck
    const individualCollectorOptionDuck = stateClone.individualCollectorOptionDuck
    return {
        formDetailsList: individualFormDuck.formDetailsList,
        collectorDetails: individualFormDuck.collectorDetails,
        individualFormDuck,
        individualCollectorOptionDuck
    }
}

const mapDispatchToProps = function (dispatch) {
    return {
        updateAllConsents: ({collectionConsent, marketingConsent, airlineConsent, additionalCrConsent}) => dispatch(updateAllConsents({collectionConsent, marketingConsent, airlineConsent, additionalCrConsent}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentDerivedDuckContainer)