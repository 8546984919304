
export const generateBatches = ({items, batchSize}) => {
    let batchList = []
    let batchCount = Math.ceil(items.length / batchSize);

    for(let i=0; i<batchCount; i++){
        let itemList = []
        let startIdx = batchSize*i
        let endIdx = Math.min(batchSize*(i+1)-1 , items.length - 1)

        let diff = endIdx - startIdx;
        for(let j=0; j< diff+1; j++){

            itemList.push(items[startIdx + j])
        }
        batchList.push(itemList);
    }

    return batchList
}