import {createMuiTheme} from '@material-ui/core/styles'

export const PRIMARY_COLOR = '#87c2dd'

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(',')
    },
    rowMargin: '1rem auto',
    container: {padding: '1rem'},
    paper: {padding: '1rem'},
    deletedBackground: {background: '#eee'}
})

export default theme

export const hoverSelectOverride = createMuiTheme({
    typography: {useNextVariants: true},
    palette: {
        action: {
            active: 'rgba(0, 0, 0, 0.54)',
            hover: 'rgba(63, 81, 181, 0.08)',
            hoverOpacity: 0.08,
            selected: 'rgba(63, 81, 181, 0.9)',
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)'
        }
    }
})