import axios from 'axios'
import {getSettings} from '../config/config'
import {getCurrentAuthHeader} from '../helpers/utilities'
import {checkValidStatusCode} from "../helpers/httpHelper";

//<editor-fold desc="Constants">
const CREATE_REDEMPTION_TRANSACTION_IS_LOADING = "CREATE_REDEMPTION_TRANSACTION_IS_LOADING";
const CREATE_REDEMPTION_TRANSACTION_SUCCESS = 'CREATE_REDEMPTION_TRANSACTION_SUCCESS';
const CREATE_REDEMPTION_TRANSACTION_FAIL = 'CREATE_REDEMPTION_TRANSACTION_FAIL';
const UPDATE_ENTIRE_REDEMPTION_TRANSACTION = 'UPDATE_ENTIRE_REDEMPTION_TRANSACTION';

let settings = getSettings();
let baseURL = settings.baseURL;

//</editor-fold>

//<editor-fold desc="Actions">
export const createEmptyRedemptionTransaction = () => async (dispatch) => {
    dispatch(_createRedemptionTransactionIsLoading());

    let emptyData = {};
    const rs = await axios.post(baseURL + '/RedemptionTransaction/Create', emptyData, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e;
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    });
    if (rs.data.success) {
        dispatch(_createRedemptionTransactionSuccess(rs.data.result));
        return {success: true, message: ''}
    } else {
        dispatch(_createRedemptionTransactionFail());
        return {success: false, message: rs.data.error.message}
    }
};

const _createRedemptionTransactionIsLoading = () => ({
    type: CREATE_REDEMPTION_TRANSACTION_IS_LOADING
});

const _createRedemptionTransactionSuccess = (redemptionTransaction) => ({
    type: CREATE_REDEMPTION_TRANSACTION_SUCCESS,
    redemptionTransaction,
})

const _createRedemptionTransactionFail = () => ({
    type: CREATE_REDEMPTION_TRANSACTION_FAIL
})

export const updateRedemptionTransaction = (redemptionTransaction) => {

    return {
        type: UPDATE_ENTIRE_REDEMPTION_TRANSACTION,
        redemptionTransaction
    }
}

//</editor-fold>

    const initState = {
        createRedemptionTransactionIsLoading: false,
        redemptionTransaction: {
            id: "",
            collectorFirstName: "",
            collectorLastName: "",
            collectorContactNo: "",
            redeemerId: 0,
            physicalVoucherIssued: false,
            eVoucherIssued: false,
            signatureURI: null,
            groupId: 0,
            allowReceivingInfo: false,
            referenceNo: ""
        }
    }

    const redemptionTransactionDuck = (state = initState, action) => {

        switch (action.type) {

            case CREATE_REDEMPTION_TRANSACTION_IS_LOADING:
                return {...state, createRedemptionTransactionIsLoading: true}

            case CREATE_REDEMPTION_TRANSACTION_SUCCESS:
                return {...state,  createRedemptionTransactionIsLoading: false, redemptionTransaction: action.redemptionTransaction}

            case CREATE_REDEMPTION_TRANSACTION_FAIL:
                return {...state,  createRedemptionTransactionIsLoading: false}

            case UPDATE_ENTIRE_REDEMPTION_TRANSACTION:
                return {redemptionTransaction: action.redemptionTransaction}


            default:
                return state
        }
    }

    export default redemptionTransactionDuck