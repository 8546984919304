
export const STATUS_PENDING_SQ_API = "PENDING SQ API";
export const STATUS_ACTION_REQUIRED = "ACTION REQUIRED";
export const STATUS_PENDING_APPROVAL = "PENDING APPROVAL";
export const STATUS_APPROVED = "APPROVED";
export const STATUS_PENDING_COLLECTION = "PENDING COLLECTION";
export const STATUS_REJECTED = "REJECTED";
export const STATUS_COLLECTED = "COLLECTED";
export const STATUS_NOT_COLLECTED = "NOT COLLECTED";
export const STATUS_VIEW_ALL = "VIEW ALL";
