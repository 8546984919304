import React from 'react'
import { connect } from 'react-redux'
import { withStyles, Fab, Grid, Button, Typography } from '@material-ui/core'
import { ScreenShare } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'
import Header from '../containers/Header.js'
import { TITLE_PREFIX } from '../constants/titlePrefix'
import StyledSection from '../components/StyledSection'
import { updateCollectorDetails, updateFormDetailsList, resetCollectorDetails, copyFormDetailsToCurrentPax,updateCrDetails } from '../ducks/individualFormDuck'
import CollectorPersonalDetails from '../containers/individual/CollectorPersonalDetails'
import { HubConnectionBuilder } from '@aspnet/signalr'
import { signalRURL } from '../config/config'
import signalRConstants from '../helpers/signalRConstants'
import { Link } from 'react-router-dom'
import StyledSnackbar from '../components/StyledSnackbar'
import ParticularsTable from '../components/ParticularsTable.js'
import PassengersVoucherTable from '../components/PassengersVoucherTable.js'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import * as cloneDeep from 'lodash/cloneDeep'
import GeneralSignature from '../components/generalForm/GeneralSignature'
import {updateIndividualCollectorOptions} from "../ducks/individualCollectorOptionDuck";

class IndividualCollector extends React.Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			formState: {},
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
			parentDidSubmit: false,
			clientIsDone: false,
			isSharingScreen: false,
			overrideExistingCrOptions: false,
		}

		this.collectorPersonalDetails = null
	}

	componentDidMount() {
		document.title = `${TITLE_PREFIX} Individual Collector`

		this.hubConnection = new HubConnectionBuilder().withUrl(signalRURL).build()
		this._startSignalRConnection()
		this.hubConnection.on('ReceiveMessage', this._receiveMessage)
		this.hubConnection.onclose(async () => {
			await this._startSignalRConnection()
		})
	}

	_startSignalRConnection = async () => {
		this.hubConnection
			.start()
			.then(() => console.log('Connection started!'))
			.catch(err => {
				setTimeout(() => this._startSignalRConnection(), 5000)
				console.log('Error while establishing connection :(')
			})
	}

	_sendMessage = ({ collectorDetails, formState, formDetailsList, collectorOptions }, messageType) => {
		if (messageType === undefined) messageType = signalRConstants.UPDATE_COLLECTOR_FORM

		const user = localStorage.getItem('username')
		let messageObj = {
			user,
			type: messageType,
			source: signalRConstants.USER_CONCIERGE,
			data: { collectorDetails, formState, formDetailsList, collectorOptions }
		}
		let messageStr = JSON.stringify(messageObj)
		if(this.hubConnection){
            this.hubConnection.invoke('SendMessage', 'default', messageStr).catch(function(err) {
                return console.error(err.toString())
            })
		}
	}

	_receiveMessage = (user, messageStr) => {
		let messageObj = JSON.parse(messageStr)

		if (messageObj.source === signalRConstants.USER_CONCIERGE) return;
		const currentUser = localStorage.getItem('username')
		if (messageObj.user !== currentUser) return

        if (messageObj.data) {
			const { collectorDetails, formState, formDetailsList, collectorOptions } = messageObj.data
			if(collectorDetails) this.props.updateCollectorDetails(collectorDetails)
			if(collectorOptions) this.props.updateIndividualCollectorOptions(collectorOptions)
			if (formState) this.setState({ formState })
			if (formDetailsList) this.props.updateFormDetailsList(formDetailsList)
			if (messageObj.type === signalRConstants.CLIENT_DONE) {
				this.setState({ clientIsDone: true, isSharingScreen: false, formState })
			}
			if(messageObj.type === signalRConstants.UPDATE_CR_DETAILS_FROM_OCID){
                const { crDetails } = messageObj.data;
                this.props.updateCrDetails(crDetails)
				this.setState({overrideExistingCrOptions:true})
			}
		}
	}

    sendOpenOcidWindow = ()=>{
		this._sendMessage({}, signalRConstants.OPEN_OCID_WINDOW)
	};

	_toolbarButtons = () => {
		return [
			<Fab color={'primary'} variant='round' onClick={this.onScreenShare}>
				<ScreenShare />
			</Fab>
		]
	}

	onScreenShare = () => {
		this._sendMessage(
			{
				collectorDetails: this.props.individualFormDuck.collectorDetails,
				formDetailsList: this.props.individualFormDuck.formDetailsList,
				collectorOptions: this.props.individualCollectorOptionDuck
			},
			signalRConstants.DISPLAY_COLLECTOR_FORM
		)
		this.setState({ isSharingScreen: true })
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	resetParentSubmit = () => {
		this.setState({ parentDidSubmit: false })
	}

	onSubmit = () => {
		// this will trigger validation in child component.
		this.setState({ parentDidSubmit: true })
	}

	onClear = async () => {
		this.props.resetCollectorDetails()
		const formState = await this.collectorPersonalDetails.onClear()
		this._sendMessage({
			collectorDetails: this.props.individualFormDuck.collectorDetails,
			formState
		})
		this.setState({ clientIsDone: false })
	}

	_onEditPax = p => {
		if (p.isDeleted) return
		this.props.copyFormDetailsToCurrentPax(cloneDeep(p))
		this.props.history.push('/individual')
	}

	// used by INDIVIDUAL_COLLECTOR_SCREEN
	onSignatureEnd = csoSignatureBase64 => {
		const details = { ...this.props.individualFormDuck.collectorDetails }
		const collectorDetails = { ...details, csoSignatureBase64 }
		this.props.updateCollectorDetails(collectorDetails)
		this._sendMessage({ collectorDetails })
	}

	// used by INDIVIDUAL_COLLECTOR_SCREEN
	onSignatureClear = () => {
		const details = { ...this.props.individualFormDuck.collectorDetails }
		const collectorDetails = { ...details, csoSignatureBase64: '' }
		this.props.updateCollectorDetails(collectorDetails)
		this._sendMessage({ collectorDetails })
	}

	parentStateUpdate = state => {
		this.setState(state)
	}


	render() {
		const { classes, individualFormDuck } = this.props
		const isLoading = individualFormDuck.submitIndividualFormIsLoading || individualFormDuck.memberSearchIsLoading || individualFormDuck.uploadFileIsLoading
		const { clientIsDone, isSharingScreen } = this.state

		return (
			<div>
				<GlobalTimeChecker />

				<Header history={this.props.history} />

				<div className={classes.container}>
					<StyledSection title={'Collector Details'} toolbarButtons={this._toolbarButtons} isLoading={isLoading}>
						<CollectorPersonalDetails
                            sendMessage={this._sendMessage}
							formState={this.state.formState}
							parentDidSubmit={this.state.parentDidSubmit}
							resetParentSubmit={this.resetParentSubmit}
							parentView={'conciergeView'}
							history={this.props.history}
							innerRef={instance => (this.collectorPersonalDetails = instance)}
							parentStateUpdate={this.parentStateUpdate}
							sendOpenOcidWindow = {this.sendOpenOcidWindow}
							overrideExistingCrOptions = {this.state.overrideExistingCrOptions}
						/>

						<Grid container spacing={24} direction={'column'}>
							{!isSharingScreen && !clientIsDone && (
								<Grid item>
									<GeneralSignature
										penColor={'black'}
										dimensions={{ width: 500, height: 200 }}
										onEnd={this.onSignatureEnd}
										onClear={this.onSignatureClear}
									/>
								</Grid>
							)}

							{individualFormDuck.collectorDetails.clientSignatureBase64 !== '' && (
								<Grid item>
									<Grid item>
										<Typography variant={'body1'}>Collector's Signature:</Typography>
									</Grid>
									<Grid item>
										<img src={individualFormDuck.collectorDetails.clientSignatureBase64} alt='' />
									</Grid>
								</Grid>
							)}

							<Grid container item spacing={24} direction={'row'} alignItems={'center'}>
								<Grid item>
									<Button component={Link} color={'primary'} variant='text' to={'/individual'}>
										Back to passengers
									</Button>
								</Grid>

								<Grid item>
									<Button color={'primary'} variant='text' onClick={this.onClear}>
										Clear
									</Button>
								</Grid>

								<Grid item>
									<Button color={'primary'} variant='contained' disabled={isSharingScreen} onClick={this.onSubmit}>
										Submit
									</Button>
								</Grid>

								<Grid item>
									{!clientIsDone && isSharingScreen && (
										<Typography variant={'body1'} className={classes.waiting}>
											Waiting for client to confirm....
										</Typography>
									)}
									{clientIsDone && (
										<Typography variant={'body1'} className={classes.waiting}>
											Client is done
										</Typography>
									)}
								</Grid>
							</Grid>
						</Grid>
					</StyledSection>

					{individualFormDuck.formDetailsList.length > 0 && (
						<StyledSection title={'Passengers'}>
							<Grid container spacing={24} direction={'column'}>

                                <Grid item>
									<PassengersVoucherTable
										collectorDetails = {individualFormDuck.collectorDetails}
										passengers={individualFormDuck.formDetailsList}
										onEditPax={this._onEditPax}
										redemptionTransactionId={individualFormDuck.redemptionTransactionId}
									/>
                                </Grid>

							</Grid>
						</StyledSection>
					)}
				</div>
				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = theme => ({
	container: { ...theme.container },
	waiting: { color: green[600], fontSize: '.8rem' }
})

const mapStateToProps = state => {
	const { individualFormDuck } = state

	return { individualFormDuck }
}

const mapDispatchToProps = dispatch => {
	return {
		updateCollectorDetails: details => dispatch(updateCollectorDetails(details)),
		updateFormDetailsList: formDetailsList => dispatch(updateFormDetailsList(formDetailsList)),
		resetCollectorDetails: () => dispatch(resetCollectorDetails()),
		copyFormDetailsToCurrentPax: p => dispatch(copyFormDetailsToCurrentPax(p)),
        updateIndividualCollectorOptions: (collectorOptions) => dispatch(updateIndividualCollectorOptions(collectorOptions)),
        updateCrDetails: (cardNo) => dispatch(updateCrDetails(cardNo))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IndividualCollector))
