import axios from 'axios'
import {getSettings} from '../config/config'
import {getCurrentAuthHeader} from '../helpers/utilities'
import {checkValidStatusCode} from "../helpers/httpHelper";

const GET_ALL_IATA_IS_LOADING = 'GET_ALL_IATA_IS_LOADING';
const GET_ALL_IATA_SUCCESS = 'GET_ALL_IATA_SUCCESS';
const GET_ALL_IATA_FAIL = 'GET_ALL_IATA_FAIL';
const ADD_IATA_IS_LOADING = 'ADD_IATA_IS_LOADING'
const ADD_IATA_SUCCESS = 'ADD_IATA_SUCCESS'
const ADD_IATA_FAIL = 'ADD_IATA_FAIL'
const UPDATE_IATA_IS_LOADING = 'DELETE_IATA_IS_LOADING'
const UPDATE_IATA_SUCCESS = 'UPDATE_IATA_SUCCESS'
const UPDATE_IATA_FAIL = 'UPDATE_IATA_FAIL'
const DELETE_IATA_IS_LOADING = 'DELETE_IATA_IS_LOADING'
const DELETE_IATA_SUCCESS = 'DELETE_IATA_SUCCESS'
const DELETE_IATA_FAIL = 'DELETE_IATA_FAIL'

let settings = getSettings();
let baseURL = settings.baseURL;

export const getAllIata = () => async dispatch => {
    dispatch(_getAllIataIsLoading())

    const rs = await axios.get(baseURL + '/Iata/GetIataList', getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_getAllIataSuccess(rs.data.result.items))
        return {success: true, message: ''}
    } else {
        dispatch(_getAllIataFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _getAllIataIsLoading = () => ({
    type: GET_ALL_IATA_IS_LOADING
})

const _getAllIataSuccess = iatas => ({
    type: GET_ALL_IATA_SUCCESS,
    iatas
})

const _getAllIataFail = () => ({
    type: GET_ALL_IATA_FAIL
})

export const updateIata = data => async dispatch => {
    dispatch(_updateIataIsLoading())

    const rs = await axios.post(baseURL + '/Iata/EditIata', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_updateIataSuccess(rs.data.result))
        return {success: true, message: ''}
    } else {
        dispatch(_updateIataFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _updateIataIsLoading = () => ({
    type: UPDATE_IATA_IS_LOADING
})

const _updateIataSuccess = () => ({
    type: UPDATE_IATA_SUCCESS
});

const _updateIataFail = () => ({
    type: UPDATE_IATA_FAIL
});

export const deleteIata = id => async dispatch => {
    dispatch(_deleteIataIsLoading());

    const deleteHeader = {...getCurrentAuthHeader()};
    const rs = await axios.delete(baseURL + `/Iata/DeleteIata?id=${id}`, deleteHeader).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e;
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_deleteIataSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_deleteIataFail())
        return {success: false, message: rs.data.error.message}
    }
}


const _deleteIataIsLoading = () => ({
    type: DELETE_IATA_IS_LOADING
})

const _deleteIataSuccess = rs => ({
    type: DELETE_IATA_SUCCESS,
    rs
})

const _deleteIataFail = () => ({
    type: DELETE_IATA_FAIL
})


export const addIata = data => async dispatch => {
    dispatch(_addIataIsLoading())

    const rs = await axios.post(baseURL + '/Iata/AddIata', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_addIataSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_addIataFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _addIataIsLoading = () => ({
    type: ADD_IATA_IS_LOADING
})

const _addIataSuccess = () => ({
    type: ADD_IATA_SUCCESS,
})

const _addIataFail = () => ({
    type: ADD_IATA_FAIL
})

const initState = {
    getAllIataIsLoading: false,
    iatas: [],
    updateIataIsLoading: false,
    deleteIataIsLoading: false,
    addIataIsLoading: false
}

const IataDuck = (state = initState, action) => {

    switch (action.type) {
        case GET_ALL_IATA_IS_LOADING:
            return {...state, getAllIataIsLoading: true};

        case GET_ALL_IATA_SUCCESS:
            return {...state, getAllIataIsLoading: false, iatas: action.iatas};

        case GET_ALL_IATA_FAIL:
            return {...state, getAllIataIsLoading: false};

        case UPDATE_IATA_IS_LOADING:
            return {...state, updateIataIsLoading: true};

        case UPDATE_IATA_SUCCESS:
            return {...state, updateIataIsLoading: false};

        case UPDATE_IATA_FAIL:
            return {...state, updateIataIsLoading: false};

        case DELETE_IATA_IS_LOADING:
            return {...state, deleteIataIsLoading: true};

        case DELETE_IATA_SUCCESS:
            return {...state, deleteIataIsLoading: false};

        case DELETE_IATA_FAIL:
            return {...state, deleteIataIsLoading: false};

        case ADD_IATA_IS_LOADING:
            return {...state, addIataIsLoading: true};

        case ADD_IATA_SUCCESS:
            return {...state, addIataIsLoading: false};

        case ADD_IATA_FAIL:
            return {...state, addIataIsLoading: false};

        default:
            return state
    }
}

export default IataDuck