export const baseURL = process.env.REACT_APP_BASE_URL
export const signalRURL = process.env.REACT_APP_SIGNAL_R_URL
export const signatureBucket = process.env.REACT_APP_SIGNATURE_BUCKET
export const attachmentBucket = process.env.REACT_APP_ATTACHMENT_BUCKET;

const developmentSettings = {
    environment: "development",
    ocidLink:"https://logindev.cag.wwprojects.com/",
    ocidClientId: "Iu5FSmVXhwJ1PwUQOGfPns3Se6q2gpS1M2y5TiWc",
    baseURL: "https://localhost:44395/api",
    voucher_currency: "$",
    voucher_sources: [
        {
            "membership_type": "DEFAULT",
            "vouchers": [
                {
                    "voucher_type": "DEFAULT",
                    "voucher_value": "10"
                }
            ]
        },
        {
            "membership_type": "CITY_BONUS",
            "vouchers": [
                {
                    "voucher_type": "GEN",
                    "voucher_value": "0"
                },
                {
                    "voucher_type": "LPC",
                    "voucher_value": "10"
                }
            ]
        },
        {
            "membership_type": "CR",
            "vouchers": [
                {
                    "voucher_type": "GEN",
                    "voucher_value": "10"
                },
                {
                    "voucher_type": "LPC",
                    "voucher_value": "0"
                }
            ]
        },
        {
            "membership_type": "CTP",
            "vouchers": [
                {
                    "voucher_type": "GEN",
                    "voucher_value": "10"
                },
                {
                    "voucher_type": "LPC",
                    "voucher_value": "0"
                }
            ]
        }
    ],
    reCaptchaSiteKey: '6LeW9P4jAAAAAOHOOF53ZOJ2hzGFzI4Nex5uPCC2'
}

const stagingSettings = {
    environment: "staging",
    ocidLink:"https://logindev.cag.wwprojects.com/",
    ocidClientId: "Iu5FSmVXhwJ1PwUQOGfPns3Se6q2gpS1M2y5TiWc",
    baseURL: "https://ctpuat.changiairport.com/api/api",
    voucher_currency: "$",
    voucher_sources: [
        {
            "membership_type": "DEFAULT",
            "vouchers": [
                {
                    "voucher_type": "DEFAULT",
                    "voucher_value": "10"
                }
            ]
        },
        {
            "membership_type": "CITY_BONUS",
            "vouchers": [
                {
                    "voucher_type": "GEN",
                    "voucher_value": "0"
                },
                {
                    "voucher_type": "LPC",
                    "voucher_value": "10"
                }
            ]
        },
        {
            "membership_type": "CR",
            "vouchers": [
                {
                    "voucher_type": "GEN",
                    "voucher_value": "10"
                },
                {
                    "voucher_type": "LPC",
                    "voucher_value": "0"
                }
            ]
        },
        {
            "membership_type": "CTP",
            "vouchers": [
                {
                    "voucher_type": "GEN",
                    "voucher_value": "10"
                },
                {
                    "voucher_type": "LPC",
                    "voucher_value": "0"
                }
            ]
        }
    ],
    reCaptchaSiteKey: '6LeW9P4jAAAAAOHOOF53ZOJ2hzGFzI4Nex5uPCC2'
}

const productionSettings = {
    environment: "production",
    ocidLink:"https://login.changiairport.com",
    ocidClientId: "sbjm4c9aEyg3Spp8B02UYS12ExedAatJNMwAOSsV",
    baseURL: "https://ctp.changiairport.com/api/api",
    voucher_currency: "$",
    voucher_sources: [
        {
            "membership_type": "DEFAULT",
            "vouchers": [
                {
                    "voucher_type": "DEFAULT",
                    "voucher_value": "10"
                }
            ]
        },
        {
            "membership_type": "CITY_BONUS",
            "vouchers": [
                {
                    "voucher_type": "GEN",
                    "voucher_value": "0"
                },
                {
                    "voucher_type": "LPC",
                    "voucher_value": "10"
                }
            ]
        },
        {
            "membership_type": "CR",
            "vouchers": [
                {
                    "voucher_type": "GEN",
                    "voucher_value": "10"
                },
                {
                    "voucher_type": "LPC",
                    "voucher_value": "0"
                }
            ]
        },
        {
            "membership_type": "CTP",
            "vouchers": [
                {
                    "voucher_type": "GEN",
                    "voucher_value": "10"
                },
                {
                    "voucher_type": "LPC",
                    "voucher_value": "0"
                }
            ]
        }
    ],
    reCaptchaSiteKey: '6LeW9P4jAAAAAOHOOF53ZOJ2hzGFzI4Nex5uPCC2'
}


export const getSettings = () => {
    let environment = process.env.REACT_APP_ENV
    let settings;
    if(environment === "Production"){
        settings = productionSettings
    } else if (environment ==="Staging"){
        settings = stagingSettings
    } else if (environment === "Development"){
        settings = developmentSettings
    }
    return settings
};