import React from 'react';
import PropTypes from 'prop-types';

class Spacing extends React.Component {

    render() {
        return (
            <div style={{
                ...styles.container.styles,
                height: this.props.height,
            }}>

            </div>
        );
    }

}

const styles = {
    container: {
        styles: {
            width: '100%',
            display: 'flex'
        },
        textWrapper:{
            styles: {
            }
        },
        btnWrapper:{
            styles: {
                marginLeft: 20,
            }
        }

    },
};

Spacing.propTypes = {
    height: PropTypes.number.isRequired,
};

export default Spacing;