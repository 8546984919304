class CardRangeHelper {
	constructor({ rangeString, prefix, noOfDigits }) {
		this.rangeString = rangeString
		this.prefix = prefix || ''
		this.noOfDigits = noOfDigits
		this.calculatedFinalDigits = 0
		this.dataPrepared = false
	}

	prepareData = () => {
		this.dataPrepared = true
		let numberTokenList = []
		let formattedRangeList = this.rangeString.split(/[-,]/)

		for (let i = 0; i < formattedRangeList.length; i++) {
			let numberToken = formattedRangeList[i].trim()

			// check the digits are the same.
			if (this.calculatedFinalDigits !== 0) {
				if (this.calculatedFinalDigits !== numberToken.length) return { success: false, message: 'Please ensure the digits are consistent' }
			}
			this.calculatedFinalDigits = numberToken.length

			// check the digits are in ascending order
			if (parseInt(numberTokenList[numberTokenList.length - 1]) >= parseInt(numberToken))
				return { success: false, message: 'Please that the numbers are in ascending order' }

			numberTokenList.push(numberToken)
		}

		return { success: true, message: 'Success' }
	}

	generateRange = () => {
		if (!this.dataPrepared) throw new Error('Please prepareData before generating Range')
		let numGrpArr = this.rangeString.split(',')
		let finalArr = []

		numGrpArr.forEach(numGrp => {
			if (numGrp.indexOf('-') > -1) {
				let formattedNumArr = this._generateFromDash(numGrp)
				finalArr = finalArr.concat(formattedNumArr)
			} else {
				let formattedNum = this.prefix + this._appendZeroUntilFinalRangeDigit(parseInt(numGrp).toString())
				finalArr.push(formattedNum)
			}
		})
		return finalArr
	}

	_generateFromDash = rangeString => {
		let rangeStartEndStr = rangeString.split('-')
		let rangeStartEndNumber = rangeStartEndStr.map(x => parseInt(x))
		let rangeSize = rangeStartEndNumber[1] - rangeStartEndNumber[0] + 1
		let numArr = []
		for (let i = 0; i < rangeSize; i++) {
			let finalDigits = rangeStartEndNumber[0] + i
			finalDigits = this._appendZeroUntilFinalRangeDigit(finalDigits.toString())
			let fullDigits = this.prefix + finalDigits
			numArr.push(fullDigits)
		}
		return numArr
	}

	_appendZeroUntilFinalRangeDigit = numberString => {
		let noOfFinalDigits = 0

		if (this.noOfDigits) {
			noOfFinalDigits = this.noOfDigits - this.prefix.length
		} else {
			noOfFinalDigits = this.calculatedFinalDigits
		}
		let newNumberString = numberString
		while (newNumberString.length < noOfFinalDigits) {
			newNumberString = '0' + newNumberString
		}
		return newNumberString
	}
}

export default CardRangeHelper
