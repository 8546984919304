import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../containers/Header'
import { withStyles, Button, Grid, Typography } from '@material-ui/core'
import StyledSection from '../components/StyledSection'
import GroupReferenceForm from '../containers/group/GroupReferenceForm'
import GroupHeader from '../containers/group/GroupHeader.js'
import { TITLE_PREFIX } from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import AuditLogTable2 from '../components/AuditLogTable2'
import { objHasData } from '../helpers/utilities'

class GroupDetail extends Component {
	componentDidMount() {
		document.title = `${TITLE_PREFIX} Group - Group Detail`
	}

	onBackToListing = () => {
		this.props.history.push('/dashboard')
	}

	render() {
		const {
			classes,
			match,
			groupApprovalDuck: { redemptionTransaction },
		} = this.props
		return (
			<div>
				<GlobalTimeChecker />

				<Header history={this.props.history} />
				<GroupHeader />
				<div className={classes.container}>
					<StyledSection title={'Group Detail'}>
						<Grid>
							<Grid item>
								<Button color={'primary'} variant='outlined' onClick={this.onBackToListing}>
									Back to Dashboard
								</Button>
							</Grid>
							<Grid item>
								<GroupReferenceForm selectedGroupId={match.params.id} location={this.props.location} history={this.props.history} />
							</Grid>
						</Grid>
					</StyledSection>

					{objHasData(redemptionTransaction) && (
						<StyledSection title={'Collector details'}>
							<Grid container spacing={24} direction={'column'}>
								<Grid item>
									<div dangerouslySetInnerHTML={{ __html: redemptionTransaction.airlineConsent }} />
								</Grid>

								<Grid item>
									<div dangerouslySetInnerHTML={{ __html: redemptionTransaction.collectionConsent }} />
								</Grid>

								<Grid item>
									<div dangerouslySetInnerHTML={{ __html: redemptionTransaction.additionalCrConsent }} />
								</Grid>

								<Grid item>
									<Typography variant={'body1'}>Collector's signature</Typography>
								</Grid>

								<Grid item>
									<img src={redemptionTransaction.presignedSignatureURI} alt='' />
								</Grid>
							</Grid>
						</StyledSection>
					)}

					<AuditLogTable2
                        redemptionTransactionId={redemptionTransaction.id}
					/>
				</div>
			</div>
		)
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
})

const mapStateToProps = (state) => {
	const { groupApprovalDuck } = state

	return { groupApprovalDuck }
}

export default connect(
	mapStateToProps,
	null
)(withStyles(styles)(GroupDetail))
