import React from 'react'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core'
import Header from '../containers/Header.js'
import GroupHeader from '../containers/group/GroupHeader.js'
import GroupRedemptionForm from '../containers/group/GroupRedemptionForm'
import {getGroupAndPassengersRedemption, updateSelectedGroupId} from '../ducks/groupRedemptionFormDuck'
import LoadingContainer from '../containers/loadingContainer'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import moment from 'moment'
import {getFlightsData, updateFlights} from '../ducks/flightDuck'
import {hasPermissions} from '../helpers/utilities'
import {GROUP_ISSUER_PERMISSION} from '../constants/permissions'
import {Redirect} from 'react-router-dom'


class GroupIssuance extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false
        }
    }

    componentDidMount = async () => {
        document.title = `${TITLE_PREFIX} Group - Group Issuance`

        let flightDataWithMetaStr = await localStorage.getItem('FLIGHT_DATA')
        let flightDataWithMeta = {}
        if (flightDataWithMetaStr) {
            flightDataWithMeta = JSON.parse(flightDataWithMetaStr)
            let today = moment().format('YYYY-MM-DD')
            if (flightDataWithMeta.flightData && flightDataWithMeta.dateCreated === today) {
                this.props.updateFlights(flightDataWithMeta.flightData)
            } else {
                await this.props.getFlightsData()
            }
        } else {

            await this.props.getFlightsData()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.flightData !== this.props.flightData && this.props.flightData != null) {
            if (this.props.flightData.length > 0) {
                let today = moment().format('YYYY-MM-DD')
                let flightDataWithMeta = {
                    dateCreated: today,
                    flightData: this.props.flightData
                }
                let flightsDataWithMetaStr = JSON.stringify(flightDataWithMeta)
                localStorage.setItem('FLIGHT_DATA', flightsDataWithMetaStr)
            }
        }
    }

    render() {

        const hasIssuerPermissions = hasPermissions([GROUP_ISSUER_PERMISSION])
        if (!hasIssuerPermissions) {
            return <Redirect to={'/404'}/>
        }

        return (
            <div>
                <GlobalTimeChecker/>

                <Header history={this.props.history}/>

                <GroupHeader/>

                <GroupRedemptionForm history={this.props.history}/>


                {this.state.isLoading && <LoadingContainer/>}
            </div>
        )
    }
}

const styles = theme => ({})

const mapStateToProps = state => {
    const {groupRedemptionFormDuck} = state
    const {flightDuck: {flightData}} = state
    return {groupRedemptionFormDuck, flightData}
}

const mapDispatchToProps = dispatch => {
    return {
        getGroupAndPassengersRedemption: groupId => dispatch(getGroupAndPassengersRedemption(groupId)),
        updateSelectedGroupId: groupId => dispatch(updateSelectedGroupId(groupId)),
        getFlightsData: () => dispatch(getFlightsData()),
        updateFlights: (flights) => dispatch(updateFlights(flights))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupIssuance))