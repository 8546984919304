const signalRConstants = {
	USER_CONCIERGE: 'USER_CONCIERGE',
	USER_CLIENT: 'USER_CLIENT',
    OCID_EXCHANGE: 'OCID_EXCHANGE',
	UPDATE_COLLECTOR_FORM: 'UPDATE_COLLECTOR_FORM',
	DISPLAY_COLLECTOR_FORM: 'DISPLAY_COLLECTOR_FORM',
	DISPLAY_GROUP_COLLECTOR_FORM: 'DISPLAY_GROUP_COLLECTOR_FORM',
	UPDATE_GROUP_COLLECTOR_FORM: 'UPDATE_GROUP_COLLECTOR_FORM',
	CLIENT_DONE: 'CLIENT_DONE',
	DISPLAY_INDIVIDUAL_COLLECTOR_SUCCESS: 'DISPLAY_INDIVIDUAL_COLLECTOR_SUCCESS',
	DISPLAY_GROUP_COLLECTOR_SUCCESS: 'DISPLAY_GROUP_COLLECTOR_SUCCESS',
	EXIT_INDIVIDUAL_COLLECTOR_SUCCESS: 'EXIT_INDIVIDUAL_COLLECTOR_SUCCESS',
	REFRESH_CLIENT: 'REFRESH_CLIENT',
    OPEN_OCID_WINDOW: 'OPEN_OCID_WINDOW',
    CLOSE_OCID_WINDOW: 'CLOSE_OCID_WINDOW',
    UPDATE_CR_DETAILS_FROM_OCID: 'UPDATE_CR_DETAILS_FROM_OCID'
}

export default signalRConstants
