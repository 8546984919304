import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Grid, TextField, Button, Typography } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import Header from '../containers/Header.js'
import { TITLE_PREFIX } from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import StyledSection from '../components/StyledSection'
import SearchResultTable from '../containers/search/SearchResultTable'
import { searchPassengers } from '../ducks/searchDuck'
import { getRedemptionTransactionAndPassengers } from '../ducks/redemptionTransactionPassengersDuck'
import StyledSnackbar from '../components/StyledSnackbar'
import StyledPlaceholder from '../components/StyledPlaceholder'
import SearchNav from '../containers/search/SearchNav'
import { hasPermissions, parseFullCardNoWithPrefix } from '../helpers/utilities'
import { SEARCH_PASSENGER_PERMISSION } from '../constants/permissions'

const FIELDS = [
	{ key: 'seq', label: 'S/N' },
	{ key: 'pnr', label: 'Pnr' },
	{ key: 'firstName', label: 'First Name' },
	{ key: 'lastName', label: 'Last Name' },
	{ key: 'customField', label: 'Campaign Code' },
	{ key: 'eticket', label: 'E-Ticket' },
	{ key: 'cardNo', label: 'Card No' },
	{ key: 'preloadedVouchersCardNo', label: 'Preloaded Card No' },
	{ key: 'genPhysicalVoucherNo', label: 'GEN Voucher No' },
	{ key: 'lpcPhysicalVoucherNo', label: 'LPC Voucher No' },
	{ key: 'group.refNo', label: 'Group ref' },
	{ key: 'redemptionTransactionId', label: 'Redemption' },
	{ key: 'status', label: 'Status' },
	{ key: 'appealRefNo', label: 'Pretravel ID' },
	{ key: 'appealStatus', label: 'Pretravel Status' },
]

class SearchPassengerScreen extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			pnr: '',
			eTicketFilter: '',
			firstName: '',
			lastName: '',
			cardNoFrom: '',
			cardNoTo: '',
			preloadedCardFrom: '',
			preloadedCardTo: '',
			voucherNoFrom: '',
			voucherNoTo: '',
			dob: '',
			firstFltDepDate: '',
			collectionDate: '',
			customField: '',
			tl: '',
			tc: '',
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
			hasSearched: false,
		}
	}

	componentDidMount() {
		document.title = `${TITLE_PREFIX} Search Passenger`
	}

	onSearchFieldsChange = (k, e) => {
		if (k === 'cardNoFrom' || k === 'cardNoTo') this.setState({ [k]: parseFullCardNoWithPrefix(e.target.value) })
		else this.setState({ [k]: e.target.value })
	}

	onPhysicalVoucherFieldsChange = (k, e) => {
		let numericValue = e.target.value.replace(/\D/g, '')
		this.setState({ [k]: numericValue })
	}

	_validateCardNo = (cardNo) => {
		if (cardNo && (cardNo.length !== 19 || /[^0-9]/g.test(cardNo))) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: `Card no: ${cardNo} should be 19 digits` })
			return false
		}
		return true
	}

	_validate = () => {
		const { pnr, firstName, lastName, firstFltDepDate, collectionDate, cardNoFrom, cardNoTo, preloadedCardFrom, preloadedCardTo } = this.state
		// if (firstFltDepDate !== '' || collectionDate !== '') {
		// 	if (lastName === '' && firstName === '') {
		// 		this.setState({
		// 			showSnackbar: true,
		// 			snackbarVariant: 'error',
		// 			snackbarMessage: 'Last Name/First name is required for Departure and/or Collection date filter',
		// 		})
		// 		return false
		// 	}
		// }

		if (!this._validateCardNo(cardNoFrom)) return false
		if (!this._validateCardNo(cardNoTo)) return false
		if (!this._validateCardNo(preloadedCardFrom)) return false
		if (!this._validateCardNo(preloadedCardTo)) return false
		return true
	}

	onSubmit = async () => {
		if (!this._validate()) return

		const fields = [
			'pnr',
			'eTicketFilter',
			'firstName',
			'lastName',
			'cardNoFrom',
			'cardNoTo',
			'preloadedCardFrom',
			'preloadedCardTo',
			'voucherNoFrom',
			'voucherNoTo',
			'dob',
			'firstFltDepDate',
			'collectionDate'
		]

		let data = {}
		let hasFilter = false
		fields.forEach((f) => {
			if (this.state[f] !== '') {
				data[f] = this.state[f]
				hasFilter = true
			}
		})

		// customField needs to be combined with at least 1 other field to search
		data.customField = this.state.customField

		if (hasFilter) {
			const rs = await this.props.searchPassengers(data)
			if (!rs.success) this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
			this.setState({ hasSearched: true })
		}
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	render() {
		const {
			classes,
			searchDuck: { passengers, searchPassengersIsLoading },
		} = this.props
		const {
			eTicketFilter,
			pnr,
			firstName,
			lastName,
			cardNoFrom,
			cardNoTo,
			preloadedCardFrom,
			preloadedCardTo,
			voucherNoFrom,
			voucherNoTo,
			dob,
			firstFltDepDate,
			collectionDate,
			customField,
			hasSearched,
		} = this.state

		if (!hasPermissions(SEARCH_PASSENGER_PERMISSION)) {
			return <Redirect to={'/not-found'} />
		}

		return (
			<div>
				<GlobalTimeChecker />
				<Header history={this.props.history} />
				<SearchNav />

				<div className={classes.container}>
					<StyledSection title={'Search criterias'} isLoading={searchPassengersIsLoading}>
						<Grid container spacing={24} direction={'column'}>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>PNR</Typography>
								</Grid>
								<Grid item>
									<TextField id='pnr' label='Pnr' variant='outlined' value={pnr} onChange={(e) => this.onSearchFieldsChange('pnr', e)} />
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>E-ticket number</Typography>
								</Grid>
								<Grid item>
									<TextField
										id='eticket'
										label='E-ticket'
										variant='outlined'
										value={eTicketFilter}
										onChange={(e) => this.onSearchFieldsChange('eTicketFilter', e)}
									/>
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>First Name</Typography>
								</Grid>
								<Grid item>
									<TextField id='name' label='Name' variant='outlined' value={firstName} onChange={(e) => this.onSearchFieldsChange('firstName', e)} />
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>Last Name</Typography>
								</Grid>
								<Grid item>
									<TextField id='name' label='Name' variant='outlined' value={lastName} onChange={(e) => this.onSearchFieldsChange('lastName', e)} />
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>Card No</Typography>
								</Grid>
								<Grid item>
									<Grid container item spacing={8} alignItems={'center'}>
										<Grid item>
											<TextField
												id='cardNoFrom'
												label='Card no from'
												variant='outlined'
												value={cardNoFrom}
												onChange={(e) => this.onSearchFieldsChange('cardNoFrom', e)}
											/>
										</Grid>
										<Grid item>
											<Typography variant={'body1'}>to</Typography>
										</Grid>
										<Grid item>
											<TextField
												id='cardNoTo'
												label='Card no to'
												variant='outlined'
												value={cardNoTo}
												onChange={(e) => this.onSearchFieldsChange('cardNoTo', e)}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>Preloaded Card No</Typography>
								</Grid>
								<Grid item>
									<Grid container item spacing={8} alignItems={'center'}>
										<Grid item>
											<TextField
												id='preloadedCardFrom'
												label='Preloaded Card no from'
												variant='outlined'
												value={preloadedCardFrom}
												onChange={(e) => this.onSearchFieldsChange('preloadedCardFrom', e)}
											/>
										</Grid>
										<Grid item>
											<Typography variant={'body1'}>to</Typography>
										</Grid>
										<Grid item>
											<TextField
												id='preloadedCardTo'
												label='Preloaded Card no to'
												variant='outlined'
												value={preloadedCardTo}
												onChange={(e) => this.onSearchFieldsChange('preloadedCardTo', e)}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>Physical Voucher</Typography>
								</Grid>
								<Grid item>
									<Grid container item spacing={8} alignItems={'center'}>
										<Grid item>
											<TextField
												id='voucherNoFrom'
												label='Voucher No From'
												variant='outlined'
												value={voucherNoFrom}
												onChange={(e) => this.onPhysicalVoucherFieldsChange('voucherNoFrom', e)}
											/>
										</Grid>
										<Grid item>
											<Typography variant={'body1'}>to</Typography>
										</Grid>
										<Grid item>
											<TextField
												id='voucherNoTo'
												label='Voucher No To'
												variant='outlined'
												value={voucherNoTo}
												onChange={(e) => this.onPhysicalVoucherFieldsChange('voucherNoTo', e)}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>Date Of Birth</Typography>
								</Grid>
								<Grid item>
									<TextField
										id='dob'
										label='Date Of Birth'
										variant='outlined'
										type={'date'}
										InputLabelProps={{ shrink: true }}
										value={dob}
										onChange={(e) => this.onSearchFieldsChange('dob', e)}
									/>
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>Departure date</Typography>
								</Grid>
								<Grid item>
									<TextField
										id='firstFltDepDate'
										type='date'
										label='Departure Date'
										variant='outlined'
										value={firstFltDepDate}
										onChange={(e) => this.onSearchFieldsChange('firstFltDepDate', e)}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>Collection date</Typography>
								</Grid>
								<Grid item>
									<TextField
										id='collectionDate'
										type='date'
										label='Collection Date'
										variant='outlined'
										value={collectionDate}
										onChange={(e) => this.onSearchFieldsChange('collectionDate', e)}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
							</Grid>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={3}>
									<Typography variant={'body1'}>Campaign Code</Typography>
								</Grid>
								<Grid item>
									<TextField
										id='customField'
										label='Campaign Code'
										variant='outlined'
										value={customField}
										onChange={(e) => this.onSearchFieldsChange('customField', e)}
									/>
								</Grid>
							</Grid>
							{/*<Grid container item spacing={24} alignItems={'center'}>*/}
							{/*    <Grid item xs={3}>*/}
							{/*        <Typography variant={'body1'}>Tour Leader</Typography>*/}
							{/*    </Grid>*/}
							{/*    <Grid item>*/}
							{/*        <TextField id="tourLeader" label="Tour Leader" variant="outlined"*/}
							{/*            value={tl} onChange={e => this.onSearchFieldsChange('tl', e)}/>*/}
							{/*    </Grid>*/}
							{/*</Grid>*/}
							{/*<Grid container item spacing={24} alignItems={'center'}>*/}
							{/*    <Grid item xs={3}>*/}
							{/*        <Typography variant={'body1'}>Tour Company</Typography>*/}
							{/*    </Grid>*/}
							{/*    <Grid item>*/}
							{/*        <TextField id="tourCompany" label="Tour Company" variant="outlined"*/}
							{/*            value={tc} onChange={e => this.onSearchFieldsChange('tc', e)}/>*/}
							{/*    </Grid>*/}
							{/*</Grid>*/}
							<Grid container item spacing={24} direction={'column'}>
								<Grid item>
									<Button color={'primary'} variant='contained' onClick={this.onSubmit}>
										Submit
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</StyledSection>

					{hasSearched && (
						<StyledSection title={'Search result'}>
							{passengers.length > 0 && <SearchResultTable data={passengers} fields={FIELDS} history={this.props.history} canChangeCardNo />}

							{passengers.length < 1 && <StyledPlaceholder message={'No results found'} />}
						</StyledSection>
					)}
				</div>
				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = (theme) => ({
	container: theme.container,
})

const mapStateToProps = (state) => {
	const { searchDuck, redemptionTransactionPassengersDuck } = state

	return { searchDuck, redemptionTransactionPassengersDuck }
}

const mapDispatchToProps = (dispatch) => {
	return {
		searchPassengers: (data) => dispatch(searchPassengers(data)),
		getRedemptionTransactionAndPassengers: (data) => dispatch(getRedemptionTransactionAndPassengers(data)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(SearchPassengerScreen))
