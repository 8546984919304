import axios from 'axios'
import {getSettings} from '../config/config'
import {getCurrentAuthHeader} from '../helpers/utilities'
import * as cloneDeep from 'lodash/cloneDeep'
import {
    GROUP_TYPE_ACTION_REQUIRED,
    GROUP_TYPE_PENDING_APPROVAL,
    GROUP_TYPE_PENDING_COLLECTION,
    GROUP_TYPE_REJECTED,
    GROUP_TYPE_DELETED,
    GROUP_TYPE_COLLECTED,
    GROUP_TYPE_NOT_COLLECTED,
    GROUP_TYPE_VIEW_ALL,
    GROUP_TYPE_PENDING_SQ_API,
    GROUP_TYPE_APPROVED,
    GROUP_ACTION_TYPE_SUBMITTING,
    GROUP_ACTION_TYPE_APPROVING,
    GROUP_ACTION_TYPE_COLLECTING,
    GROUP_ACTION_TYPE_UNAPPROVING
} from '../constants/groupType'
import {sqApiObjectToResponseString} from '../helpers/utilities'
import {convertSqStatusForDisplay, DISPLAY_YES} from '../helpers/eticketStatusHelper'
import querystring from 'querystring'
import produce from 'immer'
import {checkValidStatusCode} from "../helpers/httpHelper";

const SAVE_GROUP_EDIT_FORM_RECORD = 'SAVE_GROUP_EDIT_FORM_RECORD'
const MARK_AS_DELETE = 'MARK_AS_DELETE'
const UPDATE_PASSENGER = 'UPDATE_PASSENGER'
const UPDATE_ALL_PASSENGERS = 'UPDATE_ALL_PASSENGERS'
const UPDATE_GROUP_FORM = 'UPDATE_GROUP_FORM'
const UPDATE_APPROVE = 'UPDATE_APPROVE'
const GET_GROUP_AND_PASSENGERS_IS_LOADING = 'GET_GROUP_AND_PASSENGERS_IS_LOADING'
const GET_GROUP_AND_PASSENGERS_SUCCESS = 'GET_GROUP_AND_PASSENGERS_SUCCESS'
const GET_GROUP_AND_PASSENGERS_FAIL = 'GET_GROUP_AND_PASSENGERS_FAIL'
const GET_ALL_GROUP_IS_LOADING = 'GET_ALL_GROUP_IS_LOADING'
const GET_ALL_GROUP_SUCCESS = 'GET_ALL_GROUP_SUCCESS'
const GET_ALL_GROUP_FAIL = 'GET_ALL_GROUP_FAIL'
const UPDATE_GROUP_AND_PASSENGERS_IS_LOADING = 'UPDATE_GROUP_AND_PASSENGERS_IS_LOADING'
const UPDATE_GROUP_AND_PASSENGERS_SUCCESS = 'UPDATE_GROUP_AND_PASSENGERS_SUCCESS'
const UPDATE_GROUP_AND_PASSENGERS_FAIL = 'UPDATE_GROUP_AND_PASSENGERS_FAIL'
const UNMARK_DELETE = 'UNMARK_DELETE'
const REJECT_GROUP_AND_PASSENGERS_IS_LOADING = 'REJECT_GROUP_AND_PASSENGERS_IS_LOADING'
const REJECT_GROUP_AND_PASSENGERS_SUCCESS = 'REJECT_GROUP_AND_PASSENGERS_SUCCESS'
const REJECT_GROUP_AND_PASSENGERS_FAIL = 'REJECT_GROUP_AND_PASSENGERS_FAIL'
const UPDATE_TOUR_LEADER_FROM_PASSENGER_DATA = 'UPDATE_TOUR_LEADER_FROM_PASSENGER_DATA'
const UPDATE_PASSENGERS_TOUR_LEADER_FLAG = 'UPDATE_PASSENGERS_TOUR_LEADER_FLAG'

let settings = getSettings();
let baseURL = settings.baseURL;

const initState = {
    passengers: [],
    passengersWithMeta: [],
    rejectedPassengers: [],
    tourLeader: {},
    group: {
        id: '',
        refNo: '',
        transportMean: 'na',
        transportRemarks: '',
        remarks: '',
        attachmentUris: [],
        presignedAttachmentUris: [],
        rejectionReason: ''
    },
    groupMetaInfo: {
        isThreeSector: false
    },
    redemptionTransaction: {},
    getApprovalIsLoading: false,
    getAllGroupIsLoading: false,
    getGroupAndPassengersIsLoading: false,
    groups: [],
    updateGroupAndPassengersIsLoading: false,
    updateGroupAndPassengersSuccess: false,
    updateGroupAndPassengersError: '',
    rejectGroupAndPassengersIsLoading: false,
    groupSubmissionSqApiCheckIsLoading: false,
    voidGroupIsLoading: false,
    approvalFilesUploadIsLoading: false,
    paxIdsToReVerify: [],
    groupSubmissionSqApiCheckReVerifyIsLoading: false,
    groupApprovalGroupCheckRedeemedPassengerIsLoading: false
}

export const updateGroupAndPassengers = ({passengers, group, tourLeader, groupActionType}) => async dispatch => {
    let paramType = 0

    if (groupActionType === GROUP_ACTION_TYPE_SUBMITTING) paramType = 1
    if (groupActionType === GROUP_ACTION_TYPE_APPROVING) paramType = 2
    if (groupActionType === GROUP_ACTION_TYPE_COLLECTING) paramType = 3
    if (groupActionType === GROUP_ACTION_TYPE_UNAPPROVING) paramType = 5

    let params = `?groupActionType=${paramType}`

    let postData = {
        passengersWithMeta: passengers,
        group,
        tourLeader
    }

    dispatch(_updateGroupAndPassengersIsLoading())
    let catchError = {success: true}
    const rs = await axios.post(baseURL + `/Group/UpdateGroupAndPassengers` + params, postData, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            catchError = {success: false, error: {message: message}}
        } else {
            catchError = {success: false, error: {message: e.response.status + ' Unexpected error'}}
        }
    })
    if (catchError.success === false) {
        dispatch(_updateGroupAndPassengersFail())
        return catchError
    }
    if (rs.data.success) {
        dispatch(_updateGroupAndPassengersSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_updateGroupAndPassengersFail())
        return {success: false, message: rs.data.error && rs.data.error.message, result: rs.data.result}
    }
}

const _updateGroupAndPassengersIsLoading = () => ({
    type: UPDATE_GROUP_AND_PASSENGERS_IS_LOADING
})

const _updateGroupAndPassengersSuccess = rs => ({
    type: UPDATE_GROUP_AND_PASSENGERS_SUCCESS,
    rs
})

const _updateGroupAndPassengersFail = () => ({
    type: UPDATE_GROUP_AND_PASSENGERS_FAIL
})

export const getGroupAndPassengers = ({groupId, refNo, getAllUser}) => async dispatch => {
    dispatch(_getGroupAndPassengersIsLoading())

    let urlParams = '?'
    if (groupId) urlParams = urlParams + `groupId=${groupId}&`
    if (refNo) urlParams = urlParams + `refNoFilter=${refNo}&`
    if (getAllUser) urlParams = urlParams + `getAllUser=${getAllUser}&`
    const rs = await axios.get(baseURL + `/Group/GetGroupAndPassengers` + urlParams, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_getGroupAndPassengersSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getGroupAndPassengersFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _getGroupAndPassengersIsLoading = () => ({
    type: GET_GROUP_AND_PASSENGERS_IS_LOADING
})

const _getGroupAndPassengersSuccess = rs => ({
    type: GET_GROUP_AND_PASSENGERS_SUCCESS,
    rs
})

const _getGroupAndPassengersFail = () => ({
    type: GET_GROUP_AND_PASSENGERS_FAIL
})

export const saveGroupEditFormRecord = record => ({
    type: SAVE_GROUP_EDIT_FORM_RECORD,
    record
})

/**
 * Will set to unapprove and set markedDelete true
 * @param id
 * @returns {{id: *, type: string}}
 */
export const markAsDelete = id => ({
    type: MARK_AS_DELETE,
    id
})

export const unmarkDelete = id => ({
    type: UNMARK_DELETE,
    id
})

export const updatePassenger = (id, k, v) => {
    return {
        type: UPDATE_PASSENGER,
        id, k, v
    }
}

export const updateTourLeaderFromPassengerData = (k, v) => {
    return {
        type: UPDATE_TOUR_LEADER_FROM_PASSENGER_DATA,
        k, v
    }
}

export const updatePassengersTourLeaderFlag = (id) => {
    return {
        type: UPDATE_PASSENGERS_TOUR_LEADER_FLAG,
        id
    }
}

export const updateAllPassengers = passengers => ({
    type: UPDATE_ALL_PASSENGERS,
    passengers: passengers
})

export const updateGroupForm = (section, k, v) => ({
    type: UPDATE_GROUP_FORM,
    section, k, v
})

export const updateGroupApprovalDuck = (k, v) => ({
    type: UPDATE_GROUP_APPROVAL_DUCK,
    k, v
})

const UPDATE_GROUP_APPROVAL_DUCK = 'UPDATE_GROUP_APPROVAL_DUCK'

export const updateApprove = status => ({
    type: UPDATE_APPROVE,
    status
})

export const getAllGroup = ({groupType, getAllUser = false, maxResultCount=1000, skipCount=0}) => async dispatch => {
    let paramType
    if (groupType === GROUP_TYPE_PENDING_SQ_API) paramType = 0
    if (groupType === GROUP_TYPE_ACTION_REQUIRED) paramType = 1
    if (groupType === GROUP_TYPE_PENDING_APPROVAL) paramType = 2
    if (groupType === GROUP_TYPE_PENDING_COLLECTION) paramType = 3
    if (groupType === GROUP_TYPE_REJECTED) paramType = 4
    if (groupType === GROUP_TYPE_COLLECTED) paramType = 5
    if (groupType === GROUP_TYPE_NOT_COLLECTED) paramType = 6
    if (groupType === GROUP_TYPE_DELETED) paramType = 7
    if (groupType === GROUP_TYPE_VIEW_ALL) paramType = 8
    if (groupType === GROUP_TYPE_APPROVED) paramType = 9
    let userId = localStorage.getItem('userId')
    const params = {
        params: {
            type: paramType,
            userId,
            getAllUser,
            maxResultCount,
            skipCount
        },
        ...getCurrentAuthHeader()
    }

    dispatch(_getAllGroupIsLoading())

    const rs = await axios.get(baseURL + '/Group/GetAll', params).catch(e => {
        if (!e.response) {
            return {data: {success: false, error: {message: 'No response from server'}}}
        }
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_getAllGroupSuccess(rs))
        return rs.data
    } else {
        dispatch(_getAllGroupFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _getAllGroupIsLoading = () => ({
    type: GET_ALL_GROUP_IS_LOADING
})

const _getAllGroupSuccess = rs => ({
    type: GET_ALL_GROUP_SUCCESS,
    rs
})

const _getAllGroupFail = () => ({
    type: GET_ALL_GROUP_FAIL
})

export const rejectGroupAndPassengers = data => async dispatch => {
    dispatch(_rejectGroupAndPassengersIsLoading())

    const rs = await axios.post(baseURL + '/Group/RejectGroupAndPassengers', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_rejectGroupAndPassengersSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_rejectGroupAndPassengersFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _rejectGroupAndPassengersIsLoading = () => ({
    type: REJECT_GROUP_AND_PASSENGERS_IS_LOADING
})

const _rejectGroupAndPassengersSuccess = rs => ({
    type: REJECT_GROUP_AND_PASSENGERS_SUCCESS,
    rs
})

const _rejectGroupAndPassengersFail = () => ({
    type: REJECT_GROUP_AND_PASSENGERS_FAIL
})

export const groupSubmissionSqApiCheck = (pidx, data) => async dispatch => {
    dispatch(_groupSubmissionSqApiCheckIsLoading())

    const rs = await axios.post(baseURL + '/SqApi/GroupSubmissionSqApiCheck', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_groupSubmissionSqApiCheckFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_groupSubmissionSqApiCheckSuccess(rs, pidx))
        return {success: true, message: ''}
    } else {
        dispatch(_groupSubmissionSqApiCheckFail())
        return {success: false, message: rs.data.error.message}
    }
}

const GROUP_SUBMISSION_SQ_API_CHECK_IS_LOADING = 'GROUP_SUBMISSION_SQ_API_CHECK_IS_LOADING'
const GROUP_SUBMISSION_SQ_API_CHECK_SUCCESS = 'GROUP_SUBMISSION_SQ_API_CHECK_SUCCESS'
const GROUP_SUBMISSION_SQ_API_CHECK_FAIL = 'GROUP_SUBMISSION_SQ_API_CHECK_FAIL'

const _groupSubmissionSqApiCheckIsLoading = () => ({
    type: GROUP_SUBMISSION_SQ_API_CHECK_IS_LOADING
})

const _groupSubmissionSqApiCheckSuccess = (rs, pidx) => ({
    type: GROUP_SUBMISSION_SQ_API_CHECK_SUCCESS,
    rs, pidx
})

const _groupSubmissionSqApiCheckFail = () => ({
    type: GROUP_SUBMISSION_SQ_API_CHECK_FAIL
})

export const voidGroup = data => async dispatch => {
    dispatch(_voidGroupIsLoading())

    const rs = await axios.post(baseURL + '/Group/VoidGroup', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_voidGroupFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_voidGroupSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_voidGroupFail())
        return {success: false, message: rs.data.error.message}
    }
}

const VOID_GROUP_IS_LOADING = 'VOID_GROUP_IS_LOADING'
const VOID_GROUP_SUCCESS = 'VOID_GROUP_SUCCESS'
const VOID_GROUP_FAIL = 'VOID_GROUP_FAIL'

const _voidGroupIsLoading = () => ({
    type: VOID_GROUP_IS_LOADING
})

const _voidGroupSuccess = rs => ({
    type: VOID_GROUP_SUCCESS,
    rs
})

const _voidGroupFail = () => ({
    type: VOID_GROUP_FAIL
})

export const approvalFilesUpload = data => async dispatch => {
    dispatch(_approvalFilesUploadIsLoading())

    const rs = await axios.post(baseURL + '/File/FilesUpload?' + querystring.stringify({bucketType: 1}), data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_approvalFilesUploadFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_approvalFilesUploadSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_approvalFilesUploadFail())
        return {success: false, message: rs.data.error.message}
    }
}

const APPROVAL_FILES_UPLOAD_IS_LOADING = 'APPROVAL_FILES_UPLOAD_IS_LOADING'
const APPROVAL_FILES_UPLOAD_SUCCESS = 'APPROVAL_FILES_UPLOAD_SUCCESS'
const APPROVAL_FILES_UPLOAD_FAIL = 'APPROVAL_FILES_UPLOAD_FAIL'

const _approvalFilesUploadIsLoading = () => ({
    type: APPROVAL_FILES_UPLOAD_IS_LOADING
})

const _approvalFilesUploadSuccess = rs => ({
    type: APPROVAL_FILES_UPLOAD_SUCCESS,
    rs
})

const _approvalFilesUploadFail = () => ({
    type: APPROVAL_FILES_UPLOAD_FAIL
})

export const groupSubmissionSqApiCheckReVerify = data => async dispatch => {
    dispatch(_groupSubmissionSqApiCheckReVerifyIsLoading())

    const rs = await axios.post(baseURL + '/SqApi/GroupSubmissionSqApiCheck', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_groupSubmissionSqApiCheckReVerifyFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_groupSubmissionSqApiCheckReVerifySuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_groupSubmissionSqApiCheckReVerifyFail())
        return {success: false, message: rs.data.error.message}
    }
}

const GROUP_SUBMISSION_SQ_API_CHECK_RE_VERIFY_IS_LOADING = 'GROUP_SUBMISSION_SQ_API_CHECK_RE_VERIFY_IS_LOADING'
const GROUP_SUBMISSION_SQ_API_CHECK_RE_VERIFY_SUCCESS = 'GROUP_SUBMISSION_SQ_API_CHECK_RE_VERIFY_SUCCESS'
const GROUP_SUBMISSION_SQ_API_CHECK_RE_VERIFY_FAIL = 'GROUP_SUBMISSION_SQ_API_CHECK_RE_VERIFY_FAIL'

const _groupSubmissionSqApiCheckReVerifyIsLoading = () => ({
    type: GROUP_SUBMISSION_SQ_API_CHECK_RE_VERIFY_IS_LOADING
})

const _groupSubmissionSqApiCheckReVerifySuccess = rs => ({
    type: GROUP_SUBMISSION_SQ_API_CHECK_RE_VERIFY_SUCCESS,
    rs
})

const _groupSubmissionSqApiCheckReVerifyFail = () => ({
    type: GROUP_SUBMISSION_SQ_API_CHECK_RE_VERIFY_FAIL
})

export const groupApprovalGroupCheckRedeemedPassenger = data => async dispatch => {
    dispatch(_groupApprovalGroupCheckRedeemedPassengerIsLoading())

    const rs = await axios.post(baseURL + '/SqApi/GroupCheckRedeemedPassenger', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_groupApprovalGroupCheckRedeemedPassengerFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_groupApprovalGroupCheckRedeemedPassengerSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_groupApprovalGroupCheckRedeemedPassengerFail())
        return {success: false, message: rs.data.error.message}
    }
}

const GROUP_APPROVAL_GROUP_CHECK_REDEEMED_PASSENGER_IS_LOADING = 'GROUP_APPROVAL_GROUP_CHECK_REDEEMED_PASSENGER_IS_LOADING'
const GROUP_APPROVAL_GROUP_CHECK_REDEEMED_PASSENGER_SUCCESS = 'GROUP_APPROVAL_GROUP_CHECK_REDEEMED_PASSENGER_SUCCESS'
const GROUP_APPROVAL_GROUP_CHECK_REDEEMED_PASSENGER_FAIL = 'GROUP_APPROVAL_GROUP_CHECK_REDEEMED_PASSENGER_FAIL'

const _groupApprovalGroupCheckRedeemedPassengerIsLoading = () => ({
    type: GROUP_APPROVAL_GROUP_CHECK_REDEEMED_PASSENGER_IS_LOADING
})

const _groupApprovalGroupCheckRedeemedPassengerSuccess = rs => ({
    type: GROUP_APPROVAL_GROUP_CHECK_REDEEMED_PASSENGER_SUCCESS,
    rs
})

const _groupApprovalGroupCheckRedeemedPassengerFail = () => ({
    type: GROUP_APPROVAL_GROUP_CHECK_REDEEMED_PASSENGER_FAIL
})

const groupApprovalDuck = (state = initState, action) => {
    let idx, newPassenger, newPassengers, id, k, v, items

    switch (action.type) {

        case SAVE_GROUP_EDIT_FORM_RECORD:
            idx = state.passengers.findIndex(p => p.id.value === action.record.id.value)
            if (idx !== -1) {
                newPassenger = {...state.passengers[idx], ...action.record}
                newPassengers = [...state.passengers]
                newPassengers.splice(idx, 1, newPassenger)
                return {...state, passengers: newPassengers}
            }
            return state

        case MARK_AS_DELETE:
            idx = state.passengers.findIndex(p => p.id.value === action.id)
            if (idx !== -1) {
                newPassenger = {...state.passengers[idx]}
                newPassenger.approve.value = false
                newPassenger.markedDelete = true
                newPassengers = [...state.passengers]
                newPassengers.splice(idx, 1, newPassenger)
                return {...state, passengers: newPassengers}
            }
            return state

        case UNMARK_DELETE:
            idx = state.passengers.findIndex(p => p.id.value === action.id)
            if (idx !== -1) {
                newPassenger = {...state.passengers[idx]}
                newPassenger.approve.value = false
                newPassenger.markedDelete = false
                newPassengers = [...state.passengers]
                newPassengers.splice(idx, 1, newPassenger)
                return {...state, passengers: newPassengers}
            }
            return state

        case UPDATE_PASSENGER:
            ({id, k, v} = action)
            idx = state.passengers.findIndex(p => p.id.value === id)
            if (idx !== -1) {
                // if (k === 'approve') {
                //     let issue = 'Y'
                //     const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
                //     const p = state.passengers[idx]
                //     for (const k of keys) {
                //         if (p[k].issue === 'N') {
                //             issue = 'N'
                //             break
                //         }
                //     }
                //     if (issue === 'N') return state
                // }

                // let newPaxIdsToReVerify = [...state.paxIdsToReVerify]
                // if (k.indexOf('eTicket') !== -1 || k === 'lastName') {
                //     const p = state.passengers[idx]
                //     if (!newPaxIdsToReVerify.includes(p.id.value)) newPaxIdsToReVerify.push(p.id.value)
                // }

                newPassenger = {...state.passengers[idx]}
                newPassenger[k].value = v
                newPassengers = [...state.passengers]
                newPassengers.splice(idx, 1, newPassenger)
                return {...state, passengers: newPassengers}
            }

            return state

        case UPDATE_ALL_PASSENGERS:
            const allPassengers = action.passengers
            return {...state, passengers: allPassengers}

        case UPDATE_GROUP_FORM:
            const newSection = {...state[action.section]}
            newSection[action.k] = action.v
            return {...state, [action.section]: newSection}

        case UPDATE_APPROVE:
            newPassengers = state.passengers.map(p => {
                if (p.approve.value === !action.status && p.sqApiStatus.value === false && !!!p.markedDelete) {
                    let issue = 'Y'
                    // const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
                    // for (const k of keys) {
                    //     if (p[k].issue === 'N') {
                    //         issue = 'N'
                    //         break
                    //     }
                    // }
                    // Only update those eTicket without issue 'N'
                    if (issue === 'Y') {
                        p.approve = {...p.approve, value: action.status}
                        return p
                    }
                }
                return p
            })
            return {...state, passengers: newPassengers}

        case GET_GROUP_AND_PASSENGERS_IS_LOADING:
            return {...state, getGroupAndPassengersIsLoading: true}

        case GET_GROUP_AND_PASSENGERS_SUCCESS:

            const {data: {result: {passengersWithMeta, tourLeader, group, groupMetaInfo, redemptionTransaction}}} = action.rs
            return {
                ...state,
                getGroupAndPassengersIsLoading: false,
                passengers: passengersWithMeta.filter(p => (p.isDeleted.value !== true && p.rejected.value !== true && p.isRedeemed.value !== true)),
                rejectedPassengers: passengersWithMeta.filter(p => (p.rejected.value === true)),
                redeemedPassengers: passengersWithMeta.filter(p => (p.isRedeemed.value === true)),
                tourLeader,
                group: {
                    ...state.group, ...group, transportMean: group.transportMean !== null ? group.transportMean : 'na',
                    remarks: group.remarks !== null ? group.remarks : ''
                },
                redemptionTransaction: redemptionTransaction ? redemptionTransaction : {},
                groupMetaInfo: groupMetaInfo
            }
        // return {...state, getGroupAndPassengersIsLoading: false, ...dummy}

        case UPDATE_TOUR_LEADER_FROM_PASSENGER_DATA:
            let clonedTourLeader = {...state.tourLeader}
            clonedTourLeader[action.k] = action.v
            return {...state, tourLeader: clonedTourLeader}

        case UPDATE_PASSENGERS_TOUR_LEADER_FLAG:
            if (!action.id) {
                let clonedPassengers = cloneDeep(state.passengers)
                clonedPassengers.forEach(passenger => {
                    passenger.isTourLeader.value = false
                })
                return {...state, passengers: clonedPassengers}
            } else {
                let clonedPassengers = cloneDeep(state.passengers)
                clonedPassengers.forEach(passenger => {
                    passenger.isTourLeader.value = passenger.id.value === action.id
                })
                return {...state, passengers: clonedPassengers}

            }

        case GET_GROUP_AND_PASSENGERS_FAIL:
            return {...state, getGroupAndPassengersIsLoading: false}

        case GET_ALL_GROUP_IS_LOADING:
            return {...state, getAllGroupIsLoading: true}

        case GET_ALL_GROUP_SUCCESS:
            return {...state, getAllGroupIsLoading: false, groups: action.rs.data.result.items}

        case GET_ALL_GROUP_FAIL:
            return {...state, getAllGroupIsLoading: false}

        case UPDATE_GROUP_AND_PASSENGERS_IS_LOADING:
            return {...state, updateGroupAndPassengersIsLoading: true, updateGroupAndPassengersSuccess: false}

        case UPDATE_GROUP_AND_PASSENGERS_SUCCESS:
            return {...state, updateGroupAndPassengersIsLoading: false, updateGroupAndPassengersSuccess: true}

        case UPDATE_GROUP_AND_PASSENGERS_FAIL:
            return {
                ...state,
                updateGroupAndPassengersIsLoading: false,
                updateGroupAndPassengerError: 'There\'s a problem updating data'
            }

        case REJECT_GROUP_AND_PASSENGERS_IS_LOADING:
            return {...state, rejectGroupAndPassengersIsLoading: true}

        case REJECT_GROUP_AND_PASSENGERS_SUCCESS:
            return {...state, rejectGroupAndPassengersIsLoading: false}

        case REJECT_GROUP_AND_PASSENGERS_FAIL:
            return {...state, rejectGroupAndPassengersIsLoading: false}

        case GROUP_SUBMISSION_SQ_API_CHECK_IS_LOADING:
            return {...state, groupSubmissionSqApiCheckIsLoading: true}

        case GROUP_SUBMISSION_SQ_API_CHECK_SUCCESS:
            const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
            // format data structure to be consistent with passengerWithMeta
            newPassenger = {...state.passengers[action.pidx]}
            action.rs.data.result.ticketDetails.forEach(e => {
                keys.forEach(k => {
                    if (newPassenger[k].value === e.ticketNumber) {

                        newPassenger[k].issue = e.ctpEligibility
                        newPassenger.sqApiStatus.value = convertSqStatusForDisplay(e.ctpEligibility) === DISPLAY_YES
                        newPassenger.approve.value = convertSqStatusForDisplay(e.ctpEligibility) === DISPLAY_YES
                        if (e.criterias) {
                            newPassenger[k].remarks = sqApiObjectToResponseString(e.criterias)
                        } else {
                            newPassenger[k].remarks = e.remarks
                        }
                    }
                })
            })
            newPassengers = [...state.passengers]
            newPassengers.splice(action.pidx, 1, newPassenger)
            return {...state, groupSubmissionSqApiCheckIsLoading: false, passengers: newPassengers}

        case GROUP_SUBMISSION_SQ_API_CHECK_FAIL:
            return {...state, groupSubmissionSqApiCheckIsLoading: false}

        case VOID_GROUP_IS_LOADING:
            return {...state, voidGroupIsLoading: true}

        case VOID_GROUP_SUCCESS:
            return {...state, voidGroupIsLoading: false}

        case VOID_GROUP_FAIL:
            return {...state, voidGroupIsLoading: false}

        case APPROVAL_FILES_UPLOAD_IS_LOADING:
            return {...state, approvalFilesUploadIsLoading: true}

        case APPROVAL_FILES_UPLOAD_SUCCESS:
            return {
                ...state, approvalFilesUploadIsLoading: false,
                group: {...state.group, attachmentUris: [...state.group.attachmentUris, ...action.rs.data.result]}
            }

        case APPROVAL_FILES_UPLOAD_FAIL:
            return {...state, approvalFilesUploadIsLoading: false}

        case GROUP_SUBMISSION_SQ_API_CHECK_RE_VERIFY_IS_LOADING:
            return {...state, groupSubmissionSqApiCheckReVerifyIsLoading: true}

        case GROUP_SUBMISSION_SQ_API_CHECK_RE_VERIFY_SUCCESS:
            let resultsToProcess = action.rs.data.result.ticketDetails
            let seen = new Set()
            state.paxIdsToReVerify.forEach(pid => {
                newPassengers = [...state.passengers]
                idx = newPassengers.findIndex(p => p.id.value === pid)
                const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
                for (let k of keys) {
                    newPassenger = newPassengers[idx]
                    if (newPassenger[k].value !== '' && !newPassenger[k].markedDelete) {

                        let toUpdate = resultsToProcess.find(rs => rs.ticketNumber === newPassenger[k].value)
                        if (toUpdate && !seen.has(toUpdate.ticketNumber)) {
                            seen.add(toUpdate.ticketNumber)
                            newPassenger[k].issue = toUpdate.ctpEligibility
                            newPassenger.sqApiStatus.value = convertSqStatusForDisplay(toUpdate.ctpEligibility) === DISPLAY_YES
                            newPassenger.approve.value = convertSqStatusForDisplay(toUpdate.ctpEligibility) === DISPLAY_YES

                            if (toUpdate.criterias) {
                                newPassenger[k].remarks = sqApiObjectToResponseString(toUpdate.criterias)
                            } else {
                                newPassenger[k].remarks = toUpdate.remarks
                            }
                            newPassengers.splice(idx, 1, newPassenger)
                        }
                    }
                }

            })
            return {...state, groupSubmissionSqApiCheckReVerifyIsLoading: false, passengers: newPassengers, paxIdsToReVerify: []}

        case GROUP_SUBMISSION_SQ_API_CHECK_RE_VERIFY_FAIL:
            return {...state, groupSubmissionSqApiCheckReVerifyIsLoading: false}

        case UPDATE_GROUP_APPROVAL_DUCK:
            ({k, v} = action)
            return {...state, [k]: v}

        case GROUP_APPROVAL_GROUP_CHECK_REDEEMED_PASSENGER_IS_LOADING:
            return {...state, groupApprovalGroupCheckRedeemedPassengerIsLoading: true}

        case GROUP_APPROVAL_GROUP_CHECK_REDEEMED_PASSENGER_SUCCESS:
            ({data: {result: {items}}} = action.rs)
            if (items.length === 0) {
                return {...state, groupApprovalGroupCheckRedeemedPassengerIsLoading: true}
            }
            // newPassengers = cloneDeep(state.passengers)
            newPassengers = produce(state.passengers, draft => {
                const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
                for (let p of draft) {
                    for (let k of keys) {
                        if (p[k].value) {
                            for (let item of items) {
                                if (p[k].value === item.eTicketNo) {
                                    if(p.cardNo.value) continue; //if the passenger already has a time
                                    p.cardNo.value = item.cardNo
                                    p.collectionDate.value = item.creationTime
                                }
                            }
                        }
                    }
                }
                // items.forEach(item => {
                //     let found = false
                //     for (let p of draft) {
                //         found = false
                //         for (let k of keys) {
                //             if (p[k].value === item.eTicketNo) {
                //                 p.cardNo.value = item.cardNo
                //                 p.creationTime.value = item.creationTime
                //                 found = true
                //                 break
                //             }
                //         }
                //     }
                // })
            })

            return {...state, groupApprovalGroupCheckRedeemedPassengerIsLoading: false, passengers: newPassengers}

        case GROUP_APPROVAL_GROUP_CHECK_REDEEMED_PASSENGER_FAIL:
            return {...state, groupApprovalGroupCheckRedeemedPassengerIsLoading: false}

        default:
            return state
    }
}

export default groupApprovalDuck 