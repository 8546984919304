import React, {Component} from 'react'
import {
    withStyles,
    Grid,
    TextField,
    Typography, FormControlLabel, FormControl, Checkbox, Button
} from '@material-ui/core'
import PropTypes from 'prop-types'
import {toBrowserFriendlyDate} from "../../helpers/utilities";

class AppealCollectorDetails extends Component {

    onCollectorFieldChange = (k, e) =>{
        this.props.onCollectorFieldChange(k,e)
    };

    render() {
        const {classes, viewOnly, appealTransactionDetail, isShowing} = this.props;
        console.log("detail", appealTransactionDetail)
        console.log("allow", appealTransactionDetail.allowReceivingInfo)
        return (
            <Grid container spacing={24} direction={'column'}>
                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            First/Given Name*
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={appealTransactionDetail.firstName}
                                onChange={e => this.onCollectorFieldChange('collectorFirstName', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Last Name/Surname*
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={appealTransactionDetail.lastName}
                                onChange={e => this.onCollectorFieldChange('collectorLastName', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Country of Residence*
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={appealTransactionDetail.country}
                                onChange={e => this.onCollectorFieldChange('collectorCountry', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Date of birth*
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                type='date'
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={toBrowserFriendlyDate(appealTransactionDetail.dob)}
                                onChange={e => this.onCollectorFieldChange('collectorDob', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item md={6} spacing={24} alignItems={'center'}></Grid>

                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Country code
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={appealTransactionDetail.countryCode}
                                onChange={e => this.onCollectorFieldChange('collectorCountryCode', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Email address
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={appealTransactionDetail.email}
                                onChange={e => this.onCollectorFieldChange('collectorEmail', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Area code
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={appealTransactionDetail.areaCode}
                                onChange={e => this.onCollectorFieldChange('collectorAreaCode', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item md={6} spacing={24} alignItems={'center'}></Grid>

                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Mobile number
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={appealTransactionDetail.contactNo}
                                onChange={e => this.onCollectorFieldChange('collectorContactNo', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <div dangerouslySetInnerHTML={{ __html: appealTransactionDetail.airlineConsent }} />
                </Grid>

                <Grid item>
                    <FormControl variant={'outlined'}>
                        <FormControlLabel
                            control={<Checkbox />}
                            checked={appealTransactionDetail.allowReceivingInfo != null? appealTransactionDetail.allowReceivingInfo : false}
                            className={classes.flexStart}
                            label={
                                <Typography component='span' variant={'body1'} className={classes.consentText}>
                                    <div dangerouslySetInnerHTML={{ __html: appealTransactionDetail.marketingConsent }} />
                                </Typography>
                            }
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <div dangerouslySetInnerHTML={{ __html: appealTransactionDetail.collectionConsent }} />
                </Grid>

                {!isShowing && (<Grid container item md={6} spacing={24} alignItems={'center'}>
                    <Grid item md={4}>
                        Collector Remarks
                    </Grid>
                    <Grid item md={8}>
                        <TextField
                            id=''
                            margin='normal'
                            variant='outlined'
                            disabled={viewOnly}
                            value={appealTransactionDetail.remarks}
                            onChange={e => this.onCollectorFieldChange('remarks', e)}
                            fullWidth
                        />
                    </Grid>
                </Grid>)}

            </Grid>
        )
    }
}

const styles = theme => ({
    container: { ...theme.container },
    pointer: { cursor: 'pointer' }
});


AppealCollectorDetails.propTypes = {
    viewOnly: PropTypes.bool.isRequired,
    appealTransactionDetail: PropTypes.object.isRequired,
    onCollectorFieldChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(AppealCollectorDetails)