import React, { Component } from 'react'
import { withStyles, Grid } from '@material-ui/core'
import moment from 'moment'
import querystring from 'querystring'

import StyledSnackbar from '../components/StyledSnackbar'
import { LOCAL_STORAGE_LAST_ACTION_TIME, LOCAL_STORAGE_BROWSER_CLOSE_TIME, LOCAL_STORAGE_LOGIN_TIME } from '../constants/localStorageType'

class GlobalTimeChecker extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
		}
		this.globalTimeoutChecker = null
		this.logoutBrowserCloseTimer = null
		this.tokenExpiryPromptTimer = null
	}

	componentDidMount = async () => {
		this._logoutIfTimeout()
		this._logoutIfBrowserCloseForTooLong()
		this.globalTimeoutChecker = setInterval(() => {
			this._promptIfNearTimeout()
			this._logoutIfTimeout()
		}, 60000)

		this.logoutBrowserCloseTimer = setInterval(() => {
			this._logoutIfBrowserCloseForTooLong()
		}, 3000)

		this._saveLoginTime()
		this.tokenExpiryPromptTimer = setInterval(() => {
			this._promptIfNearTokenExpiry()
		}, 60000)

		window.addEventListener('beforeunload', this._saveBrowserCloseTimeToLocalStorage)
	}

	componentWillUnmount = async () => {
		clearInterval(this.globalTimeoutChecker)
		clearInterval(this.logoutBrowserCloseTimer)
		clearInterval(this.tokenExpiryPromptTimer)

		window.removeEventListener('beforeunload', this._saveBrowserCloseTimeToLocalStorage)
	}

	_saveBrowserCloseTimeToLocalStorage = () => {
		let now = moment().format()
		localStorage.setItem(LOCAL_STORAGE_BROWSER_CLOSE_TIME, now)
	}

	_saveLoginTime = () => {
		let loginTime = localStorage.getItem(LOCAL_STORAGE_LOGIN_TIME)
		if (loginTime) return

		let now = moment().format()
		localStorage.setItem(LOCAL_STORAGE_LOGIN_TIME, now)
		return now
	}

	_promptIfNearTimeout = () => {
		let fourteenMinutesAgo = moment().subtract(14, 'minutes')
		let lastActionTime = localStorage.getItem(LOCAL_STORAGE_LAST_ACTION_TIME)
		if (moment(lastActionTime).isBefore(fourteenMinutesAgo)) {
			this._showTimeoutPrompt()
		}
	}

	_promptIfNearTokenExpiry = () => {
		let threeFiftyMinuteAgo = moment().subtract(350, 'minutes')
		let loginTime = localStorage.getItem(LOCAL_STORAGE_LOGIN_TIME)
		if (!loginTime) loginTime = this._saveLoginTime()
		if (moment(loginTime).isBefore(threeFiftyMinuteAgo)) {
			this._showTokenExpiryPrompt()
		}
	}

	_logoutIfTimeout = () => {
		let fifteenMinutesAgo = moment().subtract(15, 'minutes')
		let lastActionTime = localStorage.getItem(LOCAL_STORAGE_LAST_ACTION_TIME)

		if (moment(lastActionTime).isBefore(fifteenMinutesAgo)) {
			window.location = '/logout'
		}
	}

	_logoutIfBrowserCloseForTooLong = () => {
		const code = querystring.parse(window.location.search).id
		let browserCloseTime = localStorage.getItem(LOCAL_STORAGE_BROWSER_CLOSE_TIME)

		if (!browserCloseTime) return

		let browserCloseThreshold = moment().subtract(10, 'seconds')
		if (moment(browserCloseTime).isBefore(browserCloseThreshold)) {
			localStorage.setItem(LOCAL_STORAGE_BROWSER_CLOSE_TIME, '')
			localStorage.setItem(code, '')
			window.location = '/logout'
		} else {
			localStorage.setItem(LOCAL_STORAGE_BROWSER_CLOSE_TIME, '')
			localStorage.setItem(code, '')
		}
	}

	_showTimeoutPrompt = () => {
		this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Your session will expire in one minute. \n To refresh, please click somewhere' })
	}

	_showTokenExpiryPrompt = () => {
		this.setState({
			showSnackbar: true,
			snackbarVariant: 'error',
			snackbarMessage: 'Your session will expire in ten minutes and you are required to re-login to continue thereafter.',
		})
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	render() {
		return (
			<Grid item>
				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</Grid>
		)
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
	dialog: { width: '20rem' },
})

export default withStyles(styles)(GlobalTimeChecker)
