import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper, TextField } from '@material-ui/core'
import { lime } from '@material-ui/core/colors'
import StyledDeleteBtn from './StyledDeleteBtn'
import StyledUndoBtn from './StyledUndoBtn'
import SqApiCriteriasDialog from '../components/SqApiCriteriasDialog'
import { updateFormDetailsList } from '../ducks/individualFormDuck'
import { toHumanFriendlyDate } from '../helpers/utilities'
import { convertSqStatusForDisplay } from '../helpers/eticketStatusHelper'

class ParticularsTable extends React.Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			criterias: {},
			criteriasMessage: '',
			viewCriterias: false,
			editingIdx: null,
			physicalEditingIdx: {}
		}
	}

	onViewCritierias = r => {
		if (r.ticketDetails && r.ticketDetails[0].criterias) {
			this.setState({
				viewCriterias: true,
				criterias: r.ticketDetails[0].criterias,
				criteriasMessage: ''
			})
			return
		}
		if (r.ticketDetails && r.ticketDetails[0].remarks) {
			this.setState({
				viewCriterias: true,
				criterias: {},
				criteriasMessage: r.ticketDetails[0].remarks
			})
		} else {
			this.setState({
				viewCriterias: true,
				criterias: {},
				criteriasMessage: 'No results'
			})
		}
	}

	onViewCriteriasClose = () => {
		this.setState({ viewCriterias: false })
	}

	onCardNoKeyPress = e => {
		if (e.key === 'Enter') this.setState({ editingIdx: null, physicalEditingIdx: {} })
	}

	onCardNoChange = (idx, e) => {
		let value = e.target.value
		if (value.length > 19) {
			value = value.substring(0, 19)
		}
		const newPax = { ...this.props.rows[idx] }
		newPax.cardNo = value
		let newFormDetailsList = [...this.props.rows]
		newFormDetailsList.splice(idx, 1, newPax)
		this.props.updateFormDetailsList(newFormDetailsList)
	}

	onCardEdit = (i, row) => {
		this.setState({ editingIdx: i })
	}

	onPhysicalCardNoChange = (idx, k, e) => {
		const newPax = { ...this.props.rows[idx] }
		newPax[k] = e.target.value
		let newFormDetailsList = [...this.props.rows]
		newFormDetailsList.splice(idx, 1, newPax)
		this.props.updateFormDetailsList(newFormDetailsList)
	}

	onPhysicalCardEdit = (row, col) => {
		this.setState({ physicalEditingIdx: { row, col } })
	}

	onCardNoBlur = idx => {
		if (this.state.editingIdx === idx) this.setState({ editingIdx: null })
	}

	onPhysicalCardNoBlur = (row, col) => {
		const { physicalEditingIdx } = this.state
		if (physicalEditingIdx.row === row && physicalEditingIdx.col === col) this.setState({ physicalEditingIdx: [] })
	}

	render() {
		const { classes, currentPaxPosition, editMode, multiCard, physicalCard, singleCard } = this.props
		const { criterias, viewCriterias, criteriasMessage, editingIdx, physicalEditingIdx } = this.state

		return (
			<Paper className={classes.root}>
				<SqApiCriteriasDialog
					onViewCriteriasClose={this.onViewCriteriasClose}
					criterias={criterias}
					criteriasMessage={criteriasMessage}
					viewCriterias={viewCriterias}
				/>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>No</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>E-ticket</TableCell>
							<TableCell>Voucher Value</TableCell>
							<TableCell>CTP Eligibility</TableCell>
							<TableCell>Verified ET</TableCell>
							<TableCell>Redemption Date</TableCell>
                            {!multiCard && !singleCard && !physicalCard && <TableCell>Card No</TableCell>}
							{multiCard && <TableCell>Card no</TableCell>}
							{singleCard && <TableCell>Card no</TableCell>}
							{physicalCard && <TableCell>GEN Voucher no</TableCell>}
							{physicalCard && <TableCell>LPC Voucher no</TableCell>}
							{this.props.onDelete && <TableCell>Delete/Undo</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{this.props.rows.map((row, index) => {
							const eTicket = []
							let classNames = []

							const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
							keys.forEach(k => {
								if (row.eTicketsDetails[k] !== '' && row.eTicketsDetails[k] !== undefined) eTicket.push(row.eTicketsDetails[k])
							})
							if (row.isDeleted) classNames.push(classes.deleted)
							if (row.pax === currentPaxPosition) classNames.push(classes.editing)
							return (
								<TableRow key={index} className={classNames.join(' ')}>
									<TableCell component='th' scope='row'>
										<p className={row.isDeleted ? classes['textDeleted'] : null}>{row.pax}</p>
									</TableCell>
									<TableCell>
										<p className={row.isDeleted ? classes['textDeleted'] : null}>
											<span onClick={() => this.props.onEditPax(row)} className={row.isDeleted ? '' : classes.btn}>
												{row.personalDetails.firstName}
											</span>
										</p>
									</TableCell>
									<TableCell>
										{Object.keys(row.eTicketsDetails).length > 0 && (
											<ol className={row.isDeleted ? classes['textDeleted'] : null}>
												{eTicket.map((e, i) => (
													<li key={i}>{e}</li>
												))}
											</ol>
										)}
									</TableCell>
									<TableCell>
										<p className={row.isDeleted ? classes['textDeleted'] : null}>
											${row.ctpGenValue ? row.ctpGenValue : 0} GEN | ${row.ctpLpcValue ? row.ctpLpcValue : 0} LPC
										</p>
									</TableCell>
									<TableCell>
										<p className={row.isDeleted ? classes['textDeleted'] : null}>
											{row.ticketDetails.length > 0 ? (
												row.ticketDetails[0] ? (
													<span className={classes.btn} onClick={() => this.onViewCritierias(row)}>
														{convertSqStatusForDisplay(row.ticketDetails[0].ctpEligibility)}
													</span>
												) : null
											) : null}
										</p>
									</TableCell>
									<TableCell>
										<p className={row.isDeleted ? classes['textDeleted'] : null}>
											{row.ticketDetails.length > 0 ? (row.ticketDetails[0] ? row.ticketDetails[0].etVerified : null) : null}
										</p>
									</TableCell>


									<TableCell>
										<p className={row.isDeleted ? classes['textDeleted'] : null}>{row.redeemed ? toHumanFriendlyDate(row.redeemed.creationTime) : null}</p>
									</TableCell>

									{!multiCard && !singleCard && !physicalCard &&
										<TableCell>
											<p className={row.isDeleted ? classes['textDeleted'] : null}>{row.redeemed ? row.redeemed.cardNo : null}</p>
										</TableCell>
									}


									{this.props.multiCard && (
										<TableCell>
											{editingIdx !== index && (
												<span
													onClick={() => (row.isDeleted ? null : this.onCardEdit(index, row))}
													className={row.isDeleted ? classes['textDeleted'] : null}
												>
													{row.cardNo}
												</span>
											)}
											{editingIdx === index && (
												<TextField
													autoFocus
													InputProps={{ className: classes.textFieldInput }}
													onKeyPress={this.onCardNoKeyPress}
													value={row.cardNo}
													onChange={e => this.onCardNoChange(index, e)}
													onBlur={() => this.onCardNoBlur(index)}
													type='number'
												/>
											)}
										</TableCell>
									)}

                                    {this.props.singleCard && (
                                        <TableCell>
                                            <p className={row.isDeleted ? classes['textDeleted'] : null}>{this.props.collectorDetails.cardNo}</p>
										</TableCell>
                                    )}

									{this.props.physicalCard && (
										<TableCell>
											{(physicalEditingIdx.row !== index || physicalEditingIdx.col !== 0) && (
												<span
													onClick={() => (row.isDeleted ? null : this.onPhysicalCardEdit(index, 0))}
													className={row.isDeleted ? classes['textDeleted'] : null}
												>
													{row.genPhysicalVoucherNo}
												</span>
											)}
											{physicalEditingIdx.row === index && physicalEditingIdx.col === 0 && (
												<TextField
													autoFocus
													InputProps={{ className: classes.textFieldInput }}
													onKeyPress={this.onCardNoKeyPress}
													value={row.genPhysicalVoucherNo}
													onChange={e => this.onPhysicalCardNoChange(index, 'genPhysicalVoucherNo', e)}
													onBlur={() => this.onPhysicalCardNoBlur(index, 0)}
												/>
											)}
										</TableCell>
									)}

									{this.props.physicalCard && (
										<TableCell>
											{(physicalEditingIdx.row !== index || physicalEditingIdx.col !== 1) && (
												<span
													onClick={() => (row.isDeleted ? null : this.onPhysicalCardEdit(index, 1))}
													className={row.isDeleted ? classes['textDeleted'] : null}
												>
													{row.lpcPhysicalVoucherNo}
												</span>
											)}
											{physicalEditingIdx.row === index && physicalEditingIdx.col === 1 && (
												<TextField
													autoFocus
													InputProps={{ className: classes.textFieldInput }}
													onKeyPress={this.onCardNoKeyPress}
													value={row.lpcPhysicalVoucherNo}
													onChange={e => this.onPhysicalCardNoChange(index, 'lpcPhysicalVoucherNo', e)}
													onBlur={() => this.onPhysicalCardNoBlur(index, 1)}
												/>
											)}
										</TableCell>
									)}

									{this.props.onDelete && (
										<TableCell>
											{!row.isDeleted && <StyledDeleteBtn onClick={() => this.props.onDelete(row.pax)} disabled={editMode} />}

											{row.isDeleted && <StyledUndoBtn onClick={() => this.props.onRestore(row.pax)} />}
										</TableCell>
									)}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

const styles = theme => ({
	root: { width: '100%', marginTop: theme.spacing.unit * 3, overflowX: 'auto' },
	table: { minWidth: 700 },
	deleted: { background: '#eee' },
	textDeleted: { color: '#bbb', textDecoration: 'line-through' },
	btn: { border: 0, cursor: 'pointer', borderBottom: '1px dotted black' },
	editing: { backgroundColor: lime[50] },
	textFieldInput: { fontSize: '0.8125rem' }
})

ParticularsTable.propTypes = {
	classes: PropTypes.object.isRequired,
	rows: PropTypes.array.isRequired
}

const mapStateToProps = state => {
	const {
		individualFormDuck: { currentPaxPosition, editMode }
	} = state

	return { currentPaxPosition, editMode }
}

const mapDispatchToProps = dispatch => {
	return { updateFormDetailsList: formDetailsList => dispatch(updateFormDetailsList(formDetailsList)) }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ParticularsTable))
