export const FlightNos = [
    {
        "carrierCode": "TR",
        "serviceNumber": "109",
        "departureCityCode": "SZX",
        "departureCityName": "Shenzhen",
        "departurePortCode": "SZX",
        "departurePortName": "Bao'an International",
        "arrivalCityCode": "SIN",
        "arrivalCityName": "Singapore",
        "arrivalPortCode": "SIN",
        "arrivalPortName": "Singapore Changi Apt",
        "arrivalDateTime": "2019-01-15 04:00:00.000",
    },
    {
        "carrierCode": "SQ",
        "serviceNumber": "6090",
        "departureCityCode": "ADD",
        "departureCityName": "Addis Ababa",
        "departurePortCode": "ADD",
        "departurePortName": "Bole Intl",
        "arrivalCityCode": "SIN",
        "arrivalCityName": "Singapore",
        "arrivalPortCode": "SIN",
        "arrivalPortName": "Singapore Changi Apt",
        "arrivalDateTime": "2019-01-17 14:50:00.000",
    },
    {
        "carrierCode": "SQ",
        "serviceNumber": "5905",
        "departureCityCode": "TYO",
        "departureCityName": "Tokyo",
        "departurePortCode": "HND",
        "departurePortName": "Tokyo Intl (Haneda)",
        "arrivalCityCode": "SIN",
        "arrivalCityName": "Singapore",
        "arrivalPortCode": "SIN",
        "arrivalPortName": "Singapore Changi Apt",
        "arrivalDateTime": "2019-03-30 06:55:00.000",
    },
    {
        "carrierCode": "MI",
        "serviceNumber": "804",
        "departureCityCode": "DRW",
        "departureCityName": "Darwin",
        "departurePortCode": "DRW",
        "departurePortName": "Darwin International",
        "arrivalCityCode": "SIN",
        "arrivalCityName": "Singapore",
        "arrivalPortCode": "SIN",
        "arrivalPortName": "Singapore Changi Apt",
        "arrivalDateTime": "2019-02-05 06:10:00.000",
    },
    {
        "carrierCode": "NZ",
        "serviceNumber": "3439",
        "departureCityCode": "MNL",
        "departureCityName": "Manila",
        "departurePortCode": "MNL",
        "departurePortName": "Manila Ninoy Aquino International",
        "arrivalCityCode": "SIN",
        "arrivalCityName": "Singapore",
        "arrivalPortCode": "SIN",
        "arrivalPortName": "Singapore Changi Apt",
        "arrivalDateTime": "2019-03-30 11:20:00.000",
    },
]