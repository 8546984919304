import axios from 'axios'
import { getSettings } from '../config/config'
import { getCurrentAuthHeader } from '../helpers/utilities'
import { checkValidStatusCode } from '../helpers/httpHelper'
import { cloneDeep, forEach } from 'lodash'

let settings = getSettings()
let baseURL = settings.baseURL

const initState = {
    getAllAppealTransactionLoading: false,

    appealTransactionDetail: {
        "id": null,
        "submittedBy": null,
        "approvedBy": null,
        "approvedUserName": null,
        "approval": false,
        "approvedTime": null,
        "rejectedBy": null,
        "rejectedUserName": null,
        "rejected": false,
        "rejectedTime": null,
        "refNo": "",
        "remarks": null,
        "passengers": [
            {
                "id": null,
                "groupId": null,
                "cardNo": null,
                "preloadedVouchersCardNo": null,
                "firstName": "",
                "lastName": "",
                "dob": null,
                "collectionDate": null,
                "passportNo": null,
                "countryOfResidence": null,
                "origin": null,
                "sqResponse": null,
                "eTicket": [
                    {
                        "eTicketNo": "",
                        "lastName": "",
                        "id": null,
                        "sqResponse": "",
                        "sqError": false,
                        "checkStatus": "",
                        "status": null,
                        "sector": [
                            {
                                "id": null,
                                "sectorNo": 1,
                                "origin": "",
                                "destination": "",
                                "flightNo": "",
                                "departureDate": null,
                                "arrivalDate": null,
                                "transportMean": null,
                                "landSea": null,
                                "remarks": null,
                                "attachmentUris": [],
                                "presignedAttachmentUris": null
                            },
                            {
                                "id": null,
                                "sectorNo": 2,
                                "origin": "",
                                "destination": "",
                                "flightNo": "",
                                "departureDate": null,
                                "arrivalDate": null,
                                "transportMean": null,
                                "landSea": null,
                                "remarks": null,
                                "attachmentUris": [],
                                "presignedAttachmentUris": null
                            },
                            {
                                "id": null,
                                "sectorNo": 3,
                                "origin": "",
                                "destination": "",
                                "flightNo": "",
                                "departureDate": null,
                                "arrivalDate": null,
                                "transportMean": null,
                                "landSea": null,
                                "remarks": null,
                                "attachmentUris": [],
                                "presignedAttachmentUris": null
                            },
                            {
                                "id": null,
                                "sectorNo": 4,
                                "origin": "",
                                "destination": "",
                                "flightNo": "",
                                "departureDate": null,
                                "arrivalDate": null,
                                "transportMean": null,
                                "landSea": null,
                                "remarks": null,
                                "attachmentUris": [],
                                "presignedAttachmentUris": null
                            }
                        ]
                    }
                ]
            }
        ],
        "attachmentURIs": [],
        "memberId": "",
        "cardNo": "",
        "firstName": "",
        "lastName": "",
        "email": "",
        "nationality": "",
        "contactNo": "",
        "dob": "",
        "countryCode": "",
        "country": "",
        "areaCode": "",
        "source": "",
        "rejectionReason": null,
        "processingTimeoutEndDate": null,
        "processingUser": null,
        "bookingRefNo": "",
        "creationTime": null
    }
}

const GET_ALL_APPEAL_TRANSACTIONS_IS_LOADING = 'GET_ALL_APPEAL_TRANSACTIONS_IS_LOADING'
const GET_ALL_APPEAL_TRANSACTIONS_SUCCESS = 'GET_ALL_APPEAL_TRANSACTIONS_SUCCESS'
const GET_ALL_APPEAL_TRANSACTIONS_FAIL = 'GET_ALL_APPEAL_TRANSACTIONS_FAIL'

const GET_APPEAL_TRANSACTION_DETAIL_IS_LOADING = 'GET_APPEAL_TRANSACTION_DETAIL_IS_LOADING'
const GET_APPEAL_TRANSACTION_DETAIL_SUCCESS = 'GET_APPEAL_TRANSACTION_DETAIL_SUCCESS'
const GET_APPEAL_TRANSACTION_DETAIL_FAIL = 'GET_APPEAL_TRANSACTION_DETAIL_FAIL'

const APPROVE_APPEAL_TRANSACTION_DETAIL_IS_LOADING = 'APPROVE_APPEAL_TRANSACTION_DETAIL_IS_LOADING'
const APPROVE_APPEAL_TRANSACTION_DETAIL_SUCCESS = 'APPROVE_APPEAL_TRANSACTION_DETAIL_SUCCESS'
const APPROVE_APPEAL_TRANSACTION_DETAIL_FAIL = 'APPROVE_APPEAL_TRANSACTION_DETAIL_FAIL'

const UPDATE_ETICKET_APPEAL_TRANSACTION_SUCCESS = 'UPDATE_ETICKET_APPEAL_TRANSACTION_SUCCESS'
const UPDATE_ETICKET_APPEAL_TRANSACTION_FAIL = 'UPDATE_ETICKET_APPEAL_TRANSACTION_FAIL'

const REJECT_APPEAL_TRANSACTION_DETAIL_IS_LOADING = 'REJECT_APPEAL_TRANSACTION_DETAIL_IS_LOADING'
const REJECT_APPEAL_TRANSACTION_DETAIL_SUCCESS = 'REJECT_APPEAL_TRANSACTION_DETAIL_SUCCESS'
const REJECT_APPEAL_TRANSACTION_DETAIL_FAIL = 'REJECT_APPEAL_TRANSACTION_DETAIL_FAIL'

const PROCESS_APPEAL_TRANSACTION_IS_LOADING = 'PROCESS_APPEAL_TRANSACTION_IS_LOADING'
const PROCESS_APPEAL_TRANSACTION_SUCCESS = 'PROCESS_APPEAL_TRANSACTION_SUCCESS'
const PROCESS_APPEAL_TRANSACTION_FAIL = 'PROCESS_APPEAL_TRANSACTION_FAIL'


export const getAllAppealTransaction = (data) => async dispatch => {
    dispatch(_getAllAppealTransactionIsLoading())

    const rs = await axios.post(baseURL + '/AppealTransaction/GetAllAppealTransactions', data.params, {
        headers: data.headers
    }).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_getAllAppealTransactionFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_getAllAppealTransactionSuccess(rs))
        return rs.data
    } else {
        dispatch(_getAllAppealTransactionFail())
        return {success: false, message: rs.data.error.message}
    }
}


const _getAllAppealTransactionIsLoading = () => ({
    type: GET_ALL_APPEAL_TRANSACTIONS_IS_LOADING
})

const _getAllAppealTransactionSuccess = rs => ({
    type: GET_ALL_APPEAL_TRANSACTIONS_SUCCESS,
    rs
})

const _getAllAppealTransactionFail = () => ({
    type: GET_ALL_APPEAL_TRANSACTIONS_FAIL
})


export const getAppealTransactionDetail = (data) => async dispatch => {
    dispatch(_getAppealTransactionDetailIsLoading())

    const rs = await axios.post(baseURL + '/AppealTransaction/GetAppealTransaction?id=' + data, null ,getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_getAppealTransactionDetailSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getAppealTransactionDetailFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _getAppealTransactionDetailIsLoading = () => ({
    type: GET_APPEAL_TRANSACTION_DETAIL_IS_LOADING
})

const _getAppealTransactionDetailSuccess = rs => ({
    type: GET_APPEAL_TRANSACTION_DETAIL_SUCCESS,
    rs
})

const _getAppealTransactionDetailFail = () => ({
    type: GET_APPEAL_TRANSACTION_DETAIL_FAIL
})

export const updateEicketAppealTransaction = (data) => async dispatch => {
    dispatch(_approveAppealTransactionDetailIsLoading())
    const rs = await axios.post(baseURL + '/Pretravel/UpdateETicketAppealCase', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        data.result = rs.data.result
        dispatch(_updateEicketAppealTransactionSuccess(data))
        return {success: true, message: ''}
    } else {
        dispatch(_updateEicketAppealTransactionFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _updateEicketAppealTransactionSuccess = (data) => ({
    type: UPDATE_ETICKET_APPEAL_TRANSACTION_SUCCESS,
    data
})

const _updateEicketAppealTransactionFail = () => ({
    type: UPDATE_ETICKET_APPEAL_TRANSACTION_FAIL
})

export const approveAppealTransactionDetail = (data, appealTransactionDetail) => async dispatch => {
    dispatch(_approveAppealTransactionDetailIsLoading())
    const rs = await axios.post(baseURL + '/AppealTransaction/ApproveAppealTransaction', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        appealTransactionDetail.approval = rs.data.result.approval;
        appealTransactionDetail.approvedTime = rs.data.result.approvedTime;
        appealTransactionDetail.approvedBy = rs.data.result.approvedBy;
        appealTransactionDetail.approvedUserName = rs.data.result.approvedUserName;
        appealTransactionDetail.approvalRemarks = rs.data.result.approvalRemarks;
        dispatch(_approveAppealTransactionDetailSuccess(appealTransactionDetail))
        return {success: true, message: ''}
    } else {
        dispatch(_approveAppealTransactionDetailFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _approveAppealTransactionDetailIsLoading = () => ({
    type: APPROVE_APPEAL_TRANSACTION_DETAIL_IS_LOADING
})

const _approveAppealTransactionDetailSuccess = rs => ({
    type: APPROVE_APPEAL_TRANSACTION_DETAIL_SUCCESS,
    rs
})

const _approveAppealTransactionDetailFail = () => ({
    type: APPROVE_APPEAL_TRANSACTION_DETAIL_FAIL
})


export const rejectAppealTransactionDetail = (data, appealTransactionDetail) => async dispatch => {
    dispatch(_rejectAppealTransactionDetailIsLoading())
    const rs = await axios.post(baseURL + '/AppealTransaction/RejectAppealTransaction', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        appealTransactionDetail.rejected = rs.data.result.rejected;
        appealTransactionDetail.rejectedTime = rs.data.result.rejectedTime;
        appealTransactionDetail.rejectedBy = rs.data.result.rejectedBy;
        appealTransactionDetail.rejectedUserName = rs.data.result.rejectedUserName;
        appealTransactionDetail.rejectionRemarks = rs.data.result.rejectionRemarks;
        dispatch(_rejectAppealTransactionDetailSuccess(appealTransactionDetail))
        return {success: true, message: ''}
    } else {
        dispatch(_rejectAppealTransactionDetailFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _rejectAppealTransactionDetailIsLoading = () => ({
    type: REJECT_APPEAL_TRANSACTION_DETAIL_IS_LOADING
})

const _rejectAppealTransactionDetailSuccess = rs => ({
    type: REJECT_APPEAL_TRANSACTION_DETAIL_SUCCESS,
    rs
})

const _rejectAppealTransactionDetailFail = () => ({
    type: REJECT_APPEAL_TRANSACTION_DETAIL_FAIL
})


export const processAppealTransaction = (data) => async dispatch => {
    dispatch(_processAppealTransactionIsLoading())
    const rs = await axios.post(baseURL + '/AppealTransaction/ProcessAppealTransaction', data, getCurrentAuthHeader() ).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_processAppealTransactionSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_processAppealTransactionFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _processAppealTransactionIsLoading = () => ({
    type: PROCESS_APPEAL_TRANSACTION_IS_LOADING
})

const _processAppealTransactionSuccess = rs => ({
    type: PROCESS_APPEAL_TRANSACTION_SUCCESS,
    rs
})

const _processAppealTransactionFail = () => ({
    type: PROCESS_APPEAL_TRANSACTION_FAIL
})


// ===============================================Reducer=============================================
const appealTransactionDuck = (state = initState, action) => {
	switch (action.type) {
		case GET_ALL_APPEAL_TRANSACTIONS_IS_LOADING:
            return {...state, getAllAppealTransactionLoading: true}

        case GET_ALL_APPEAL_TRANSACTIONS_SUCCESS:
            return {...state, getAllAppealTransactionLoading: false, appealTransactions: action.rs.data.result.items}

        case GET_ALL_APPEAL_TRANSACTIONS_FAIL:
            return {...state, getAllAppealTransactionLoading: false}

        case GET_APPEAL_TRANSACTION_DETAIL_IS_LOADING:
            return {...state, getAppealTransactionDetailIsLoading: true}

        case GET_APPEAL_TRANSACTION_DETAIL_SUCCESS:
            return {...state, getAppealTransactionDetailIsLoading: false, appealTransactionDetail: action.rs.data.result}

        case GET_APPEAL_TRANSACTION_DETAIL_FAIL:
            return {...state, getAppealTransactionDetailIsLoading: false}

        case APPROVE_APPEAL_TRANSACTION_DETAIL_IS_LOADING:
            return {...state, approveAppealTransactionIsLoading: true}

        case UPDATE_ETICKET_APPEAL_TRANSACTION_SUCCESS:
            // map new eTicket to current passenger
            let newAppealTransactionDetail = cloneDeep(state.appealTransactionDetail)
            for (let currentPax of newAppealTransactionDetail.passengers) {
                if (currentPax.id === action.data.passengerId) {
                    const eTicketsKeys = ['eTicket2', 'eTicket3', 'eTicket4']
                    let i = 0
                    for (let eTixKey of eTicketsKeys) {
                        if (action.data.eTicketNumbers[i]) {
                            currentPax[eTixKey] = action.data.eTicketNumbers[i]
                        }
                        i++
                    }
                }
            }
            // add more new audit log
            newAppealTransactionDetail.auditLogs.push(...action.data.result)
            
            return {...state, approveAppealTransactionIsLoading: false, appealTransactionDetail: newAppealTransactionDetail }

        case UPDATE_ETICKET_APPEAL_TRANSACTION_FAIL:
            return {...state, approveAppealTransactionIsLoading: false}

        case APPROVE_APPEAL_TRANSACTION_DETAIL_SUCCESS:
            return {...state, approveAppealTransactionIsLoading: false, appealTransactionDetail: action.rs}

        case APPROVE_APPEAL_TRANSACTION_DETAIL_FAIL:
            return {...state, approveAppealTransactionIsLoading: false}

        case REJECT_APPEAL_TRANSACTION_DETAIL_IS_LOADING:
            return {...state, rejectAppealTransactionIsLoading: true}

        case REJECT_APPEAL_TRANSACTION_DETAIL_SUCCESS:
            return {...state, rejectAppealTransactionIsLoading: false, appealTransactionDetail: action.rs}

        case REJECT_APPEAL_TRANSACTION_DETAIL_FAIL:
            return {...state, rejectAppealTransactionIsLoading: false}

        case PROCESS_APPEAL_TRANSACTION_IS_LOADING:
            return {...state, processAppealTransactionIsLoading: true}

        case PROCESS_APPEAL_TRANSACTION_SUCCESS:
            return {...state, processAppealTransactionIsLoading: false}

        case PROCESS_APPEAL_TRANSACTION_FAIL:
            return {...state, processAppealTransactionIsLoading: false}

		default:
			return state
	}
}

export default appealTransactionDuck
