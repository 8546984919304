import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Paper, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import StyledDeleteBtn from '../components/StyledDeleteBtn'
import StyledUndoBtn from '../components/StyledUndoBtn'

class StyledAttachmentList extends Component {
	render() {
		console.log(this.props);
		const { classes, attachmentUris, presignedAttachmentUris, deletedOriginalAttachmentIdx, viewOnly } = this.props
		const deletable = deletedOriginalAttachmentIdx && this.props.onDeleteOrigAttachment && this.props.onUndoDeleteOrigAttachment
		if (!deletable) {
			return (
				<Paper>
					<List>
						{presignedAttachmentUris.map((alink, i) => (
							<ListItem key={'attachment' + i}>
								<ListItemText classes={{ root: classes.listItemText }}>{i + 1}.</ListItemText>
								<ListItemText onClick={() => this.props.onAttachmentClick(alink)} classes={{ root: classes.listItemText }} className={classes.pointer}>
									{attachmentUris[i]}
								</ListItemText>
							</ListItem>
						))}
					</List>
				</Paper>
			)
		}
		return (
			<Paper>
				<List>
					{presignedAttachmentUris.map((alink, i) => (
						<ListItem key={'attachment' + i} className={deletedOriginalAttachmentIdx.has(i) ? classes.markedDelete : ''}>
							<ListItemText classes={{ root: classes.listItemText }}>{i + 1}.</ListItemText>
							<ListItemText onClick={() => this.props.onAttachmentClick(alink)} classes={{ root: classes.listItemText }} className={classes.pointer}>
								{attachmentUris[i]}
							</ListItemText>
							{this.props.onDeleteOrigAttachment && (
								<ListItemSecondaryAction>
									{!deletedOriginalAttachmentIdx.has(i) && <StyledDeleteBtn onClick={() => this.props.onDeleteOrigAttachment(i)} disabled={viewOnly} />}
									{deletedOriginalAttachmentIdx.has(i) && <StyledUndoBtn onClick={() => this.props.onUndoDeleteOrigAttachment(i)} disabled={viewOnly} />}
								</ListItemSecondaryAction>
							)}
						</ListItem>
					))}
				</List>
			</Paper>
		)
	}
}

StyledAttachmentList.propsTypes = {
	attachmentUris: PropTypes.array.isRequired,
	onAttachmentClick: PropTypes.func.isRequired,
	onUndoDeleteOrigAttachment: PropTypes.func.isRequired,
	deletedOriginalAttachmentIdx: PropTypes.func.isRequired
}

const styles = theme => ({
	container: { ...theme.container },
	listItemText: { flex: 'none' },
	pointer: { cursor: 'pointer' },
	markedDelete: { ...theme.deletedBackground }
})

export default withStyles(styles)(StyledAttachmentList)
