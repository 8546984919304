const airportCodeList = [
    {code: "SIN", country: "Singapore"},
    {code: "LHR", country: "United Kingdom"},
    {code: "NGO", airport: "Chubu Centrair International Airport", country: "Japan"},
    {code: "PVG", airport: "Shanghai Pudong Airport", country: "China"},
    {code: "FUK", airport: "Fukuoka Airport", country: "Japan"},

]

export const getCountryFromCode = (airportCode) => {
    let airport = airportCodeList.find(x => x.code === airportCode);
    if (airport){
        return airport.country
    } else {
        return "Not Found"
    }

};

