import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as cloneDeep from 'lodash/cloneDeep'
import { Grid, withStyles, TextField, Divider, Typography, RadioGroup, FormControlLabel, Radio, Button, FormControl, Checkbox, TableBody, TableRow, TableCell, Table, Paper, TableHead } from '@material-ui/core'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import { TITLE_PREFIX } from '../constants/titlePrefix'
import Header from '../containers/Header.js'
import StyledSnackbar from '../components/StyledSnackbar'
import RedemptionPassengersTable from '../containers/redemptionTransactionDetail/RedemptionPassengersTable'
import AppealETicketForm from '../containers/appealTransactionDetail/AppealETicketForm'
import PassengerDetails from '../containers/redemptionTransactionDetail/PassengerDetails'
import AppealCollectorDetails from '../containers/appealTransactionDetail/AppealCollectorDetails'
import StyledSection from '../components/StyledSection'
import DeleteDialog from '../components/DeleteDialog'
import { getAppealTransactionDetail, approveAppealTransactionDetail, rejectAppealTransactionDetail } from '../ducks/appealTransactionDuck'
import {
	copyFormDetailsToCurrentPax
} from '../ducks/individualFormDuck.js'
import {REQUIRED_ERROR_MSG} from '../constants/messages'
import { hasPermissions, getCurrentAuthHeader, toHumanFriendlyDateTime } from '../helpers/utilities'
import { APPEAL_SUPER_APPROVER } from '../constants/permissions'


const initState = {
	showSnackbar: false,
	snackbarMessage: '',
	snackbarVariant: 'success',
	currentIdx: 0,
	remarkError: '',
    remarks: '',
    eventName: ''
}

class AppealTransactionDetailScreen extends Component {
    constructor(props, context) {
        super(props, context)
		this.state = { ...initState }
        this.etixkeys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
        this.individualETicket = null
    }

    componentDidMount = async () => {
        const { match: { params: { id } } } = this.props
		document.title = `${TITLE_PREFIX} Appeal transaction detail - ${id}`
        const rs = await this.props.getAppealTransactionDetail(id)

        const {
            appealTransactionDuck: {
                appealTransactionDetail
            }
        } = this.props
        const { currentIdx } = this.state
        let passengers = []
		if (appealTransactionDetail) {
			passengers = appealTransactionDetail.passengers
        }
        this.props.copyFormDetailsToCurrentPax(cloneDeep(passengers[currentIdx]))
    }

    closeSnackbar = () => {
        this.setState({ showSnackbar: false })
    }

    onNameClick = (idx, p) => {
        this.setState({ currentIdx: idx })
        this.props.copyFormDetailsToCurrentPax(cloneDeep(p))
    }

    onApprove = async (id, remark, appealTransaction) => {
        const data = { id: id, approvalRemarks: remark }
        if (!this._validateRemark()) return
        const rs = await this.props.approveAppealTransactionDetail(data, appealTransaction)
        if (rs.success) {
            // No need to send auditLogs on first request
            this.setState({
                showSnackbar: true,
                snackbarMessage: 'Success',
                snackbarVariant: 'success',
                parentDidSumit: false,
                eventName: 'approve'
            })
        } else {
            this.setState({
                showSnackbar: true,
                snackbarMessage: rs.message,
                snackbarVariant: 'error',
                parentDidSumit: false,
                eventName: 'approve'
            })
        }
    }

    onReject = async (id, remark, appealTransaction) => {
        const data = { id: id, rejectionRemarks: remark }
        if (!this._validateRemark()) return
		const rs = await this.props.rejectAppealTransactionDetail(data, appealTransaction)
		if (rs.success) {
			// No need to send auditLogs on first request
			this.setState({
				showSnackbar: true,
				snackbarMessage: 'Success',
				snackbarVariant: 'success',
                parentDidSumit: false,
                eventName: 'reject'
			})
		} else {
			this.setState({
				showSnackbar: true,
				snackbarMessage: rs.message,
				snackbarVariant: 'error',
                parentDidSumit: false,
                eventName: 'reject'
			})
		}
    }

    _validateRemark = () => {
        const { remarks } = this.state
		if (remarks === '') {
			this.setState({ remarkError: REQUIRED_ERROR_MSG })
			return false
		} else {
			this.setState({ remarkError: '' })
			return true
		}
	}

    onCollectorFieldChange = (k, e) => {
    }
    onFieldChange = e => {
        this.setState({ remarks: e.target.value })
    }

    render() {
        const {
            classes,
            editMode,
            appealTransactionDuck: {
                appealTransactionDetail,
                getAppealTransactionDetailIsLoading,
                rejectAppealTransactionIsLoading,
                approveAppealTransactionIsLoading
            }
        } = this.props
        const { currentIdx, remarks, remarkError } = this.state
		const isLoading = getAppealTransactionDetailIsLoading || rejectAppealTransactionIsLoading || approveAppealTransactionIsLoading //todo: need to load when send api request
		let passengers = []
		let appealTransaction
		if (appealTransactionDetail) {
			appealTransaction = appealTransactionDetail
			passengers = appealTransactionDetail.passengers
        }

        // check disabled or not for button after approve/reject
        const hasSuperApproverPermissions = hasPermissions([APPEAL_SUPER_APPROVER])
        const snackbarVariant = this.state.snackbarVariant
        const eventName = this.state.eventName
        let isDisabled = false;
        if ((eventName == 'approve' && snackbarVariant == 'success') 
            || (eventName == 'reject' && snackbarVariant == 'success' && !hasSuperApproverPermissions)) {
                isDisabled = true
            }
        return (
            <div>
                <GlobalTimeChecker />

                <Header history={this.props.history} />

                <div className={classes.container}>

                <StyledSection title={`Pretravel Appeal: ${appealTransactionDetail.refNo}`} isLoading={isLoading}>
                    <StyledSection title={'Passengers'} isLoading={isLoading}>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item>
                                <RedemptionPassengersTable data={passengers} onNameClick={this.onNameClick} currentIdx={currentIdx} />
                            </Grid>
                        </Grid>
                    </StyledSection>

                    <AppealETicketForm 
                        innerRef={(instance) => (this.individualETicket = instance)}
                        passenger = {passengers[currentIdx]}
                        editMode={editMode} 
                        editModeClass={classes.editMode} />

                    {appealTransaction && (<StyledSection title={'Pax Details'} isLoading={isLoading}>
                        <PassengerDetails
                            viewOnly = {true}
                            passenger = {passengers[currentIdx]}
                            files = {[]}
                            deletedOriginalAttachmentIdx = {0}
                            onFieldChange = {()=>{}}
                            isShowing
                        />
                    </StyledSection>)}

                    {appealTransaction && (<StyledSection title={'Collector details'} isLoading={isLoading}>
                        <AppealCollectorDetails
                            viewOnly = {true}
                            appealTransactionDetail={appealTransaction}
                            onCollectorFieldChange = {this.onCollectorFieldChange}
                            isShowing
                        />

                    </StyledSection>)}


                    {appealTransaction && (<StyledSection title={'Update Details'} isLoading={isLoading}>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid container item md={6} spacing={24} alignItems={'center'}>
                                    <Grid item md={4}>
                                        Status
                                    </Grid>
                                    <Grid item md={8}>
                                        {appealTransaction.approval == false && appealTransaction.rejected == false && (<TextField
                                            id=''
                                            margin='normal'
                                            variant='outlined'
                                            disabled={true}
                                            value={"PENDING"}
                                            fullWidth
                                        />)}

                                        {appealTransaction.approval == false && appealTransaction.rejected == true && (<TextField
                                            id=''
                                            margin='normal'
                                            variant='outlined'
                                            disabled={true}
                                            value={"REJECTED"}
                                            fullWidth
                                        />)}

                                        {appealTransaction.approval == true && (<TextField
                                            id=''
                                            margin='normal'
                                            variant='outlined'
                                            disabled={true}
                                            value={"APPROVED"}
                                            fullWidth
                                        />)}
                                    </Grid>
                                </Grid>
                                <Grid container item md={6} spacing={24} alignItems={'center'}>
                                    <Grid item md={4}>
                                        CR Member ID
                                    </Grid>
                                    <Grid item md={8}>
                                    <TextField
                                        id=''
                                        margin='normal'
                                        variant='outlined'
                                        disabled={true}
                                        value={appealTransaction.memberId}
                                        fullWidth
                                    />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid container item md={6} spacing={24} alignItems={'center'}>
                                    <Grid item md={4}>
                                        CSO Remark
                                    </Grid>
                                    <Grid item md={8}>
                                        <TextField
                                            id=''
                                            margin='normal'
                                            variant='outlined'
                                            value={appealTransaction.remarks}
                                            onChange={this.onFieldChange}
                                            error={!!remarkError} helperText={remarkError}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item md={6} spacing={24} alignItems={'center'}>
                                    <Grid item md={4}>
                                        Card Number
                                    </Grid>
                                    <Grid item md={8}>
                                    <TextField
                                        id=''
                                        margin='normal'
                                        variant='outlined'
                                        disabled={true}
                                        value={appealTransaction.cardNo}
                                        fullWidth
                                    />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    <Grid container spacing={8} direction={'row'}>
                        {(!appealTransaction.approval && (!appealTransaction.rejected || hasSuperApproverPermissions)) && (<Grid item>
                            <Button color={'primary'} variant='contained' isLoading = {isLoading} disabled = {isDisabled} onClick={() => this.onApprove(appealTransaction.id, remarks, appealTransaction)}>
                                Issue Ctp
                            </Button>
                        </Grid>)}
                        {(!appealTransaction.rejected && !appealTransaction.approval) && (<Grid item>
                            <Button color={'primary'} variant='contained' isLoading = {isLoading} disabled = {isDisabled} onClick={() => this.onReject(appealTransaction.id, remarks, appealTransaction)}>
                                Reject
                            </Button>
                        </Grid>)}
                    </Grid>
                    </StyledSection>)}

                    <StyledSection title={'Audit Table'} isLoading = {isLoading}>
						<Paper className={classes.container}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell className = {classes.auditColumn}>Date</TableCell>
										<TableCell>Changes</TableCell>
                                        <TableCell>Remarks</TableCell>
									</TableRow>
								</TableHead>
                                <TableBody>
                                    {appealTransaction.auditLogs && (appealTransaction.auditLogs.map((auditLog, i) => {
                                        return <TableRow TableRow key={i}>
                                            <TableCell>{toHumanFriendlyDateTime(auditLog.creationTime)} {auditLog.createdByName}</TableCell>
                                            <TableCell>The {auditLog.field} was updated{auditLog.oldValue && (<span> from {auditLog.oldValue}</span> )} to {auditLog.newValue}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    }))}
                                    {appealTransaction.rejectedTime && (<TableRow TableRow key={"rejected"}>
              						    <TableCell>{toHumanFriendlyDateTime(appealTransaction.rejectedTime)} {appealTransaction.rejectedUserName}</TableCell>
                                        <TableCell>{appealTransaction.rejectedUserName} has rejected this case</TableCell>
                                        <TableCell>{appealTransaction.rejectionRemarks}</TableCell>
            					    </TableRow>)}
                                    {appealTransaction.approvedTime && (<TableRow TableRow key={"approved"}>
                                        <TableCell>{toHumanFriendlyDateTime(appealTransaction.approvedTime)} {appealTransaction.approvedUserName}</TableCell>
                                        <TableCell>{appealTransaction.approvedUserName} has approved this case</TableCell>
                                        <TableCell>{appealTransaction.approvalRemarks}</TableCell>
            					    </TableRow>)}
                                </TableBody>
							</Table>
						</Paper>
				    </StyledSection>
                    </StyledSection>
                </div>

                <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
            </div>
        )
    }
}

const styles = theme => ({
    container: { ...theme.container },
    pointer: { cursor: 'pointer' },
    auditColumn : { width:'20%'},
    editMode: {}
})

const mapStateToProps = state => {
    const { appealTransactionDuck } = state
    return { 
        appealTransactionDuck,
        editMode: appealTransactionDuck.editMode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        approveAppealTransactionDetail: (data, appealTransaction) => dispatch(approveAppealTransactionDetail(data, appealTransaction)),
        rejectAppealTransactionDetail: (data, appealTransaction) => dispatch(rejectAppealTransactionDetail(data, appealTransaction)),
        getAppealTransactionDetail: (data) => dispatch(getAppealTransactionDetail(data)),
        copyFormDetailsToCurrentPax: (p) => dispatch(copyFormDetailsToCurrentPax(p)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppealTransactionDetailScreen))
