import React, {Component} from 'react'
import {withStyles, Paper, Table, TableRow, TableHead, TableBody, TableCell} from '@material-ui/core'
import {lime} from '@material-ui/core/colors'
import { convertSqStatusForDisplay } from '../../helpers/eticketStatusHelper'

const FIELDS = [
    {key: 'seq', label: 'S/N'},
    {key: 'firstName', label: 'First name'},
    {key: 'lastName', label: 'Last name'},
    {key: 'pnr', label: 'Pnr'},
    {key: 'customField', label: 'Campaign Code'},
    {key: 'eticket', label: 'E-Ticket'},
    {key: 'cardNo', label: 'Card No'},
    {key: 'ctpEligibility', label: 'CTP Eligibility'},
    {key: 'etVerified', label: 'Verified ET'},
    {key: 'genPhysicalVoucherNo', label: 'GEN Voucher No'},
    {key: 'lpcPhysicalVoucherNo', label: 'LPC Voucher No'},
    {key: 'voucherValue', label: 'Voucher Value'}
]

class RedemptionPassengersTable extends Component {

    _renderEticket = d => {
        const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
        return <ol>
            {keys.map(k => {
                if (d[k] !== '') return <li key={d.id + k}>{d[k]}</li>
                else return <div />
            })}
        </ol>
    }

    render() {
        const {classes, data, currentIdx} = this.props

        return (
            <Paper className={classes.container}>

                <Table padding={'dense'}>
                    <TableHead>
                        <TableRow>
                            {FIELDS.map(f => {
                                return <TableCell key={f.key}>{f.label}</TableCell>
                            })}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data.map((d, ridx) => {
                            return <TableRow key={d.id} className={ridx === currentIdx ? classes.editing : ''}>
                                {FIELDS.map(f => {
                                    return <TableCell key={d.id + f.key}>
                                        {f.key === 'seq' && ridx + 1}

                                        {f.key === 'firstName' &&
                                        <span className={classes.name}
                                            onClick={() => this.props.onNameClick(ridx, d)}>{d.firstName}</span>}

                                        {f.key === 'lastName' &&
                                        <span className={classes.name}
                                            onClick={() => this.props.onNameClick(ridx, d)}>{d.lastName}</span>}

                                        {f.key === 'pnr' && <p>{d.pnr}</p>}

                                        {f.key === 'customField' && <p>{d.customField}</p>}

                                        {f.key === 'eticket' && this._renderEticket(d)}

                                        {f.key === 'cardNo' && <p>{d.cardNo}</p>}

                                        {f.key === 'ctpEligibility' && <p>{convertSqStatusForDisplay(d.ctpEligibility)}</p>}

                                        {f.key === 'etVerified' && <p>{d.etVerified}</p>}

                                        {f.key === 'genPhysicalVoucherNo' && <p>{d.genPhysicalVoucherNo}</p>}

                                        {f.key === 'lpcPhysicalVoucherNo' && <p>{d.lpcPhysicalVoucherNo}</p>}

                                        {f.key === 'voucherValue' && <p>{d.voucherValue}</p>}

                                    </TableCell>
                                })}
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </Paper>
        )
    }
}

const styles = theme => ({
    container: {...theme.container},
    name: {cursor: 'pointer', borderBottom: '1px dotted #ccc'},
    editing: {backgroundColor: lime[50]}
})

export default withStyles(styles)(RedemptionPassengersTable)