import React from 'react'
import {Redirect} from 'react-router-dom'
import {hasPermissions} from '../helpers/utilities'
import {
    USER_ADMIN_CAMPAIGN_READ_PERMISSION, USER_ADMIN_CAMPAIGN_CREATE_PERMISSION, USER_ADMIN_CAMPAIGN_EDIT_PERMISSION,
    USER_ADMIN_ACCOUNT_READ_PERMISSION, USER_ADMIN_ACCOUNT_CREATE_PERMISSION, USER_ADMIN_ACCOUNT_EDIT_PERMISSION,
    USER_ADMIN_ROLE_READ_PERMISSION, USER_ADMIN_ROLE_CREATE_PERMISSION, USER_ADMIN_ROLE_EDIT_PERMISSION,
    USER_ADMIN_BANNER_READ_PERMISSION, USER_ADMIN_BANNER_CREATE_PERMISSION, USER_ADMIN_BANNER_EDIT_PERMISSION
} from '../constants/permissions'

/**
 * This page exists for routing purpose
 */
class AdminSettings extends React.Component {

    componentDidMount() {
        document.title = `Changi Transit Privileges - Admin settings`
    }

    render() {
        const campaignPermissions = hasPermissions([USER_ADMIN_CAMPAIGN_READ_PERMISSION, USER_ADMIN_CAMPAIGN_CREATE_PERMISSION,
            USER_ADMIN_CAMPAIGN_EDIT_PERMISSION], 'or')

        const accountPermissions = hasPermissions([USER_ADMIN_ACCOUNT_READ_PERMISSION, USER_ADMIN_ACCOUNT_CREATE_PERMISSION,
            USER_ADMIN_ACCOUNT_EDIT_PERMISSION], 'or')

        const accessPermissions = hasPermissions([USER_ADMIN_ROLE_READ_PERMISSION, USER_ADMIN_ROLE_CREATE_PERMISSION,
            USER_ADMIN_ROLE_EDIT_PERMISSION], 'or')

        const bannerPermissions = hasPermissions([USER_ADMIN_BANNER_READ_PERMISSION, USER_ADMIN_BANNER_CREATE_PERMISSION,
            USER_ADMIN_BANNER_EDIT_PERMISSION], 'or')

        return <div>
            {campaignPermissions && <Redirect to={'/admin-settings/campaign-period'}/>}
            {!campaignPermissions && accountPermissions && <Redirect to={'/admin-settings/create-new-account'}/>}
            {!campaignPermissions && !accountPermissions && accessPermissions &&
            <Redirect to={'/admin-settings/user-access-account'}/>}
            {!campaignPermissions && !accountPermissions && !accessPermissions && bannerPermissions &&
            <Redirect to={'/admin-settings/banner'}/>}
        </div>
    }
}

export default AdminSettings
