import axios from 'axios'
import {getSettings} from '../config/config'
import {checkValidStatusCode} from "../helpers/httpHelper";

let settings = getSettings();
let baseURL = settings.baseURL;

export const getGroupStats = params => async dispatch => {
    dispatch(_getGroupStatsIsLoading())

    const rs = await axios.get(baseURL + '/Group/GetGroupStats', params).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_getGroupStatsFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_getGroupStatsSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getGroupStatsFail())
        return {success: false, message: rs.data.error.message}
    }
}

const GET_GROUP_STATS_IS_LOADING = 'GET_GROUP_STATS_IS_LOADING'
const GET_GROUP_STATS_SUCCESS = 'GET_GROUP_STATS_SUCCESS'
const GET_GROUP_STATS_FAIL = 'GET_GROUP_STATS_FAIL'

const _getGroupStatsIsLoading = () => ({
    type: GET_GROUP_STATS_IS_LOADING
})

const _getGroupStatsSuccess = rs => ({
    type: GET_GROUP_STATS_SUCCESS,
    rs
})

const _getGroupStatsFail = () => ({
    type: GET_GROUP_STATS_FAIL
})

const initState = {
    getGroupStatsIsLoading: false,
    pendingSQ: 0,
    actionRequired: 0,
    pendingApproval: 0,
    approved: 0,
    rejected: 0,
    deleted: 0,
    total: 0
}

const GroupStatsDuck = (state = initState, action) => {
    switch (action.type) {
        case GET_GROUP_STATS_IS_LOADING:
            return {...state, getGroupStatsIsLoading: true}

        case GET_GROUP_STATS_SUCCESS:
            return {...state, getGroupStatsIsLoading: false, ...action.rs.data.result}

        case GET_GROUP_STATS_FAIL:
            return {...state, getGroupStatsIsLoading: false}

        default:
            return state
    }
}

export default GroupStatsDuck