import axios from 'axios'
import {getSettings} from '../config/config'
import {getCurrentAuthHeader} from '../helpers/utilities'
import {checkValidStatusCode} from "../helpers/httpHelper";

let settings = getSettings();
let baseURL = settings.baseURL;

const MANIFEST_UPLOAD_IS_LOADING = 'MANIFEST_UPLOAD_IS_LOADING'
const MANIFEST_UPLOAD_SUCCESS = 'MANIFEST_UPLOAD_SUCCESS'
const MANIFEST_UPLOAD_FAIL = 'MANIFEST_UPLOAD_FAIL'
const UPDATE_GROUP_DETAILS = 'UPDATE_GROUP_DETAILS'
const GET_CAMPAIGN_CODE_IS_LOADING = 'GET_CAMPAIGN_CODE_IS_LOADING'
const GET_CAMPAIGN_CODE_SUCCESS = 'GET_CAMPAIGN_CODE_SUCCESS'

const initState = {
    manifestUploadStatus: null,
    manifestUploadIsLoading: false,
    manifestUploadError: false,
    manifestUploadErrorArray: [],
    groupDetails: {
        collectionDate: null,
        pos: '',
        lastFlightDepartureDate: null,
        tourCompany: ''
    }

}

export const uploadGroupManifest = (file, groupDetails) => async dispatch => {
    dispatch(_manifestUploadIsLoading())

    const formData = new FormData()
    formData.append('excelFile', file, file.name)
    let colDateParams = 'collectionDate=' + groupDetails.collectionDate
    let posParams = 'pos=' + groupDetails.pos
    let lastFltDateParams = 'lastFlightDepartureDate=' + groupDetails.lastFlightDepartureDate
    let tourCompanyParams = 'tourCompany=' + groupDetails.tourCompany
    let params = '?' + colDateParams + '&' + posParams + '&' + lastFltDateParams + '&' + tourCompanyParams
    if (groupDetails && groupDetails.campaignCode) {
        params += '&campaignCode=' + groupDetails.campaignCode
    }
    let error = ''
    const rs = await axios.post(baseURL + '/Group/ManifestUpload' + params, formData, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            error = message
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_manifestUploadFail({error: error}))
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_manifestUploadSuccess())
        return {success: true, message: ''}
    } else {
        dispatch(_manifestUploadFail({error: error}))
        return {success: false, message: rs.data.error.message}
    }
}

export const getCampaignCodes = () => async dispatch => {
    dispatch(_getCampaignCodesIsLoading())

    const rs = await axios.get(baseURL + `/Group/CampaignCodes`, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_getCampaignCodesSuccess(rs))
        return {success: true, result: rs.data.result}
    }
}

const _getCampaignCodesIsLoading = () => ({
    type: GET_CAMPAIGN_CODE_IS_LOADING
})

const _getCampaignCodesSuccess = rs => ({
    type: GET_CAMPAIGN_CODE_SUCCESS,
    rs
})

const _manifestUploadIsLoading = () => ({
    type: MANIFEST_UPLOAD_IS_LOADING
})

const _manifestUploadSuccess = () => ({
    type: MANIFEST_UPLOAD_SUCCESS
})

const _manifestUploadFail = ({error, errorArray = []}) => ({
    type: MANIFEST_UPLOAD_FAIL,
    error: error,
    errorArray: errorArray
})

export const updateGroupDetails = (groupDetails) => ({
    type: UPDATE_GROUP_DETAILS,
    groupDetails
})

const groupManifestDuck = (state = initState, action) => {
    switch (action.type) {
        case MANIFEST_UPLOAD_IS_LOADING:
            return {
                ...state,
                manifestUploadIsLoading: true,
                manifestUploadStatus: null,
                error: '',
                errorArray: []
            }

        case MANIFEST_UPLOAD_SUCCESS:
            return {
                ...state,
                manifestUploadStatus: true,
                manifestUploadIsLoading: false,
                error: '',
                errorArray: []

            }

        case MANIFEST_UPLOAD_FAIL:
            return {
                ...state,
                manifestUploadStatus: false,
                manifestUploadIsLoading: false,
                error: action.error,
                errorArray: action.errorArray
            }

        case UPDATE_GROUP_DETAILS:
            return {
                ...state,
                groupDetails: action.groupDetails
            }

        default:
            return state
    }
}

export default groupManifestDuck 