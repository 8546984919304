import axios from 'axios'
import { getSettings } from '../config/config'
import { getCurrentAuthHeader, encrypt } from '../helpers/utilities'
import { USER_ID, PERMISSIONS, USER_POS, SHOULD_CHANGE_PASSWORD } from '../constants/localStorageType'
import { checkValidStatusCode } from '../helpers/httpHelper'

let settings = getSettings();
let baseURL = settings.baseURL;

const IS_LOGGINGIN = 'IS_LOGGINGIN'
const IS_LOGGEDIN = 'IS_LOGGEDIN'
const LOGIN_FAILED = 'LOGIN_FAILED'
const UPDATE_AUTH_DUCK = 'UPDATE_AUTH_DUCK'
const SHOULD_CHANGE_PASSWORD_SUCCESS = 'SHOULD_CHANGE_PASSWORD_SUCCESS'
const UPDATE_MULTIPLE_AUTH_PROPERTIES = 'UPDATE_MULTIPLE_AUTH_PROPERTIES'

export const login = (usernameOrEmailAddress, password) => async dispatch => {
	dispatch(isLoggingin())

	const rs = await axios
		.post(baseURL + '/Account/Login', {
			usernameOrEmailAddress,
			password
		})
		.catch(e => dispatch(loginFailed(e)))
	if (rs.data.success) {
		localStorage.setItem('accessToken', rs.data.result.accessToken)
		localStorage.setItem('username', usernameOrEmailAddress)

		const authHeader = { headers: { Authorization: 'Bearer ' + rs.data.result.accessToken } }
		const rs2 = await axios.get(baseURL + '/Account/GetUserProfile', authHeader).catch(e => {
			if (!checkValidStatusCode(e.response.status)) {
				const {
					response: {
						data: {
							error: { message }
						}
					}
				} = e
				return { data: { success: false, error: { message } } }
			}
			return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
		})

		if (rs2.data.success) {
			localStorage.setItem(USER_ID, rs.data.result.userId)
			localStorage.setItem(PERMISSIONS, encrypt(JSON.stringify(rs2.data.result.permissions)))
			localStorage.setItem(USER_POS, rs2.data.result.pos || '')

			const params = { params: { userName: rs2.data.result.userName }, ...authHeader, timeout: 10 * 60 * 1000 }

			const rs3 = await axios({
				url: baseURL + '/Account/ShouldChangePassword',
				headers: authHeader.headers,
				params: { userName: rs2.data.result.userName }
			}).catch(e => {
				if (!checkValidStatusCode(e.response.status)) {
					const {
						response: {
							data: {
								error: { message }
							}
						}
					} = e
					return { data: { success: false, error: { message } } }
				}
				return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
			})

			if (rs3.data.success) {
				dispatch({ type: SHOULD_CHANGE_PASSWORD_SUCCESS, rs: rs3 })

				dispatch(isLoggedin(rs, usernameOrEmailAddress))
				return { success: true, message: '' }
			}
		}
	}
	dispatch(loginFailed(rs.data.error.message))
	return { success: false, message: rs.data.error.message }
}

const isLoggingin = () => ({
	type: IS_LOGGINGIN
})

const isLoggedin = (rs, usernameOrEmailAddress) => ({
	type: IS_LOGGEDIN,
	rs,
	usernameOrEmailAddress
})

const loginFailed = message => ({
	type: LOGIN_FAILED,
	message
})

export const updateAuthDuck = (k, v) => ({
	type: UPDATE_AUTH_DUCK,
	k,
	v
})

export const updateMultipleAuthProperties = propertiesToUpdate => ({
	type: UPDATE_MULTIPLE_AUTH_PROPERTIES,
	propertiesToUpdate
})

export const forgotPassword = data => async dispatch => {
	dispatch(_forgotPasswordIsLoading())

	const rs = await axios.post(baseURL + '/Account/ForgotPassword', data).catch(e => {
		if (!checkValidStatusCode(e.response.status)) {
			const {
				response: {
					data: {
						error: { message }
					}
				}
			} = e
			return { data: { success: false, error: { message } } }
		}
		return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
	})
	if (!rs.data) {
		dispatch(_forgotPasswordFail())
		return { success: false, message: 'No data from server' }
	}
	if (rs.data.success) {
		dispatch(_forgotPasswordSuccess(rs))
		return { success: true, message: '' }
	} else {
		dispatch(_forgotPasswordFail())
		return { success: false, message: rs.data.error.message }
	}
}

const FORGOT_PASSWORD_IS_LOADING = 'FORGOT_PASSWORD_IS_LOADING'
const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

const _forgotPasswordIsLoading = () => ({
	type: FORGOT_PASSWORD_IS_LOADING
})

const _forgotPasswordSuccess = rs => ({
	type: FORGOT_PASSWORD_SUCCESS,
	rs
})

const _forgotPasswordFail = () => ({
	type: FORGOT_PASSWORD_FAIL
})

export const changePassword = data => async dispatch => {
	dispatch(_changePasswordIsLoading())

	const rs = await axios.post(baseURL + '/Account/ChangePassword', data, getCurrentAuthHeader()).catch(e => {
		if (!checkValidStatusCode(e.response.status)) {
			const {
				response: {
					data: {
						error: { message }
					}
				}
			} = e
			return { data: { success: false, error: { message } } }
		}
		return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
	})
	if (!rs.data) {
		dispatch(_changePasswordFail())
		return { success: false, message: 'No data from server' }
	}
	if (rs.data.success) {
		dispatch(_changePasswordSuccess(rs))
		return { success: true, message: '' }
	} else {
		dispatch(_changePasswordFail())
		return { success: false, message: rs.data.error.message }
	}
}

const CHANGE_PASSWORD_IS_LOADING = 'CHANGE_PASSWORD_IS_LOADING'
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'

const _changePasswordIsLoading = () => ({
	type: CHANGE_PASSWORD_IS_LOADING
})

const _changePasswordSuccess = rs => ({
	type: CHANGE_PASSWORD_SUCCESS,
	rs
})

const _changePasswordFail = () => ({
	type: CHANGE_PASSWORD_FAIL
})

export const authSendOtp = data => async dispatch => {
	dispatch(_authSendOtpIsLoading())

	const rs = await axios.post(baseURL + '/Account/SendOtp', data).catch(e => {
		if (!checkValidStatusCode(e.response.status)) {
			const {
				response: {
					data: {
						error: { message }
					}
				}
			} = e
			return { data: { success: false, error: { message } } }
		}
		return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
	})
	if (!rs.data) {
		dispatch(_authSendOtpFail())
		return { success: false, message: 'No data from server' }
	}
	if (rs.data.success) {
		dispatch(_authSendOtpSuccess(rs))
		return { success: true, message: '' }
	} else {
		dispatch(_authSendOtpFail())
		return { success: false, message: rs.data.error.message }
	}
}

const AUTH_SEND_OTP_IS_LOADING = 'AUTH_SEND_OTP_IS_LOADING'
const AUTH_SEND_OTP_SUCCESS = 'AUTH_SEND_OTP_SUCCESS'
const AUTH_SEND_OTP_FAIL = 'AUTH_SEND_OTP_FAIL'

const _authSendOtpIsLoading = () => ({
	type: AUTH_SEND_OTP_IS_LOADING
})

const _authSendOtpSuccess = rs => ({
	type: AUTH_SEND_OTP_SUCCESS,
	rs
})

const _authSendOtpFail = () => ({
	type: AUTH_SEND_OTP_FAIL
})

export const authSendEmailOtp = data => async dispatch => {
	dispatch(_authSendEmailOtpIsLoading())

	const rs = await axios.post(baseURL + '/Account/SendEmailOtp', data).catch(e => {
		if (!checkValidStatusCode(e.response.status)) {
			const {
				response: {
					data: {
						error: { message }
					}
				}
			} = e
			return { data: { success: false, error: { message } } }
		}
		return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
	})

	if (!rs.data) {
		dispatch(_authSendEmailOtpFail())
		return { success: false, message: 'No data from server' }
	}

	if (rs.data.success) {
		dispatch(_authSendEmailOtpSuccess())
		return { success: true, message: '' }
	} else {
		dispatch(_authSendEmailOtpFail())
		return { success: false, message: rs.data.error.message }
	}
}

const AUTH_SEND_EMAIL_OTP_IS_LOADING = 'AUTH_SEND_EMAIL_OTP_IS_LOADING'
const AUTH_SEND_EMAIL_OTP_SUCCESS = 'AUTH_SEND_EMAIL_OTP_SUCCESS'
const AUTH_SEND_EMAIL_OTP_FAIL = 'AUTH_SEND_EMAIL_OTP_FAIL'

const _authSendEmailOtpIsLoading = () => ({
	type: AUTH_SEND_EMAIL_OTP_IS_LOADING
})

const _authSendEmailOtpSuccess = () => ({
	type: AUTH_SEND_EMAIL_OTP_SUCCESS
})

const _authSendEmailOtpFail = () => ({
	type: AUTH_SEND_EMAIL_OTP_FAIL
})

const AUTH_AUTHENTICATE_WITH_EMAIL_OTP_IS_LOADING = 'AUTH_AUTHENTICATE_WITH_EMAIL_OTP_IS_LOADING'
const AUTH_AUTHENTICATE_WITH_EMAIL_OTP_SUCCESS = 'AUTH_AUTHENTICATE_WITH_EMAIL_OTP_SUCCESS'
const AUTH_AUTHENTICATE_WITH_EMAIL_OTP_FAIL = 'AUTH_AUTHENTICATE_WITH_EMAIL_OTP_FAIL'

const _authAuthenticateWithEmailOtpIsLoading = () => ({
	type: AUTH_AUTHENTICATE_WITH_EMAIL_OTP_IS_LOADING
})

const _authAuthenticateWithEmailOtpSuccess = () => ({
	type: AUTH_AUTHENTICATE_WITH_EMAIL_OTP_SUCCESS
})

const _authAuthenticateWithEmailOtpFail = () => ({
	type: AUTH_AUTHENTICATE_WITH_EMAIL_OTP_FAIL
})

export const authAuthenticateWithEmailOtp = data => async dispatch => {
	dispatch(_authAuthenticateWithEmailOtpIsLoading())

	const rs = await axios.post(baseURL + '/Account/AuthenticateWithEmailOtp', data).catch(e => {
		if (!checkValidStatusCode(e.response.status)) {
			const {
				response: {
					data: {
						error: { message }
					}
				}
			} = e
			return { data: { success: false, error: { message } } }
		}
		return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
	})

	if (!rs.data) {
		dispatch(_authAuthenticateWithEmailOtpFail())
		return { success: false, message: 'No data from server' }
	}

	if (rs.data.success) {
		localStorage.setItem('accessToken', rs.data.result.accessToken)
		localStorage.setItem('username', data.usernameOrEmailAddress)

		const authHeader = { headers: { Authorization: 'Bearer ' + rs.data.result.accessToken } }
		const rs2 = await axios.get(baseURL + '/Account/GetUserProfile', authHeader).catch(e => {
			if (!checkValidStatusCode(e.response.status)) {
				const {
					response: {
						data: {
							error: { message }
						}
					}
				} = e
				return { data: { success: false, error: { message } } }
			}
			return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
		})

		if (rs2.data.success) {
			localStorage.setItem(USER_ID, rs.data.result.userId)
			//localStorage.setItem(PERMISSIONS, JSON.stringify(rs2.data.result.permissions))
			localStorage.setItem(PERMISSIONS, encrypt(JSON.stringify(rs2.data.result.permissions)))
			localStorage.setItem(USER_POS, rs2.data.result.pos || '')

			const params = { params: { userName: rs2.data.result.userName }, ...authHeader }
			const rs3 = await axios.get(baseURL + '/Account/ShouldChangePassword', params).catch(e => {
				if (!checkValidStatusCode(e.response.status)) {
					const {
						response: {
							data: {
								error: { message }
							}
						}
					} = e
					return { data: { success: false, error: { message } } }
				}
				return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
			})

			if (rs3.data.success) {
				dispatch({ type: SHOULD_CHANGE_PASSWORD_SUCCESS, rs: rs3 })

				dispatch(_authAuthenticateWithEmailOtpSuccess())
				return { success: true, message: '' }
			}
		}
		return { success: true, message: '' }
	} else {
		dispatch(_authAuthenticateWithEmailOtpFail())
		return { success: false, message: rs.data.error.message }
	}
}

export const authAuthenticateWithOtp = data => async dispatch => {
	dispatch(_authAuthenticateWithOtpIsLoading())

	const rs = await axios.post(baseURL + '/Account/AuthenticateWithOtp', data).catch(e => {
		if (!checkValidStatusCode(e.response.status)) {
			const {
				response: {
					data: {
						error: { message }
					}
				}
			} = e
			return { data: { success: false, error: { message } } }
		}
		return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
	})
	if (!rs.data) {
		dispatch(_authAuthenticateWithOtpFail())
		return { success: false, message: 'No data from server' }
	}
	if (rs.data.success) {
		localStorage.setItem('accessToken', rs.data.result.accessToken)
		localStorage.setItem('username', data.usernameOrEmailAddress)

		const authHeader = { headers: { Authorization: 'Bearer ' + rs.data.result.accessToken } }
		const rs2 = await axios.get(baseURL + '/Account/GetUserProfile', authHeader).catch(e => {
			if (!checkValidStatusCode(e.response.status)) {
				const {
					response: {
						data: {
							error: { message }
						}
					}
				} = e
				return { data: { success: false, error: { message } } }
			}
			return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
		})

		if (rs2.data.success) {
			localStorage.setItem(USER_ID, rs.data.result.userId)
			//localStorage.setItem(PERMISSIONS, JSON.stringify(rs2.data.result.permissions))
			localStorage.setItem(PERMISSIONS, encrypt(JSON.stringify(rs2.data.result.permissions)))
			localStorage.setItem(USER_POS, rs2.data.result.pos || '')

			const params = { params: { userName: rs2.data.result.userName }, ...authHeader }
			const rs3 = await axios.get(baseURL + '/Account/ShouldChangePassword', params).catch(e => {
				if (!checkValidStatusCode(e.response.status)) {
					const {
						response: {
							data: {
								error: { message }
							}
						}
					} = e
					return { data: { success: false, error: { message } } }
				}
				return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
			})

			if (rs3.data.success) {
				dispatch({ type: SHOULD_CHANGE_PASSWORD_SUCCESS, rs: rs3 })

				dispatch(_authAuthenticateWithOtpSuccess(rs))
				return { success: true, message: '' }
			}
		}
		return { success: true, message: '' }
	} else {
		dispatch(_authAuthenticateWithOtpFail())
		return { success: false, message: rs.data.error.message }
	}
}

const AUTH_AUTHENTICATE_WITH_OTP_IS_LOADING = 'AUTH_AUTHENTICATE_WITH_OTP_IS_LOADING'
const AUTH_AUTHENTICATE_WITH_OTP_SUCCESS = 'AUTH_AUTHENTICATE_WITH_OTP_SUCCESS'
const AUTH_AUTHENTICATE_WITH_OTP_FAIL = 'AUTH_AUTHENTICATE_WITH_OTP_FAIL'

const _authAuthenticateWithOtpIsLoading = () => ({
	type: AUTH_AUTHENTICATE_WITH_OTP_IS_LOADING
})

const _authAuthenticateWithOtpSuccess = rs => ({
	type: AUTH_AUTHENTICATE_WITH_OTP_SUCCESS,
	rs
})

const _authAuthenticateWithOtpFail = () => ({
	type: AUTH_AUTHENTICATE_WITH_OTP_FAIL
})

const initState = {
	isLoggedIn: null,
	isLoggingIn: false,
	loginFailedReason: null,
	forgotPasswordIsLoading: false,
	changePasswordIsLoading: false,
	authSendOtpIsLoading: false,
	uid: '',
	contactNo: '',
	authAuthenticateWithOtpIsLoading: false,
	shouldChangePassword: false
}

const authDuck = (state = initState, action) => {
	switch (action.type) {
		case IS_LOGGINGIN:
			return { ...state, isLoggingIn: true, isLoggedIn: null, loginFailedReason: null }

		case IS_LOGGEDIN:
			return { ...state, isLoggingIn: false, isLoggedIn: true }

		case LOGIN_FAILED:
			return { ...state, isLoggingIn: false, isLoggedIn: false, loginFailedReason: action.message }

		case UPDATE_AUTH_DUCK:
			return { ...state, [action.k]: action.v }

		case UPDATE_MULTIPLE_AUTH_PROPERTIES:
			let clonedState = { ...state }
			action.propertiesToUpdate.forEach(update => (clonedState[update.key] = update.value))
			return { ...clonedState }

		case FORGOT_PASSWORD_IS_LOADING:
			return { ...state, forgotPasswordIsLoading: true }

		case FORGOT_PASSWORD_SUCCESS:
			return { ...state, forgotPasswordIsLoading: false }

		case FORGOT_PASSWORD_FAIL:
			return { ...state, forgotPasswordIsLoading: false }

		case CHANGE_PASSWORD_IS_LOADING:
			return { ...state, changePasswordIsLoading: true }

		case CHANGE_PASSWORD_SUCCESS:
			localStorage.setItem(SHOULD_CHANGE_PASSWORD, encrypt(false.toString()))
			return { ...state, changePasswordIsLoading: false, shouldChangePassword: false }

		case CHANGE_PASSWORD_FAIL:
			return { ...state, changePasswordIsLoading: false }

		case AUTH_SEND_OTP_IS_LOADING:
			return { ...state, authSendOtpIsLoading: true }

		case AUTH_SEND_OTP_SUCCESS:
			const { uid, contactNo } = action.rs.data.result
			return { ...state, authSendOtpIsLoading: false, uid, contactNo }

		case AUTH_SEND_OTP_FAIL:
			return { ...state, authSendOtpIsLoading: false }

		case AUTH_SEND_EMAIL_OTP_IS_LOADING:
			return { ...state, authSendOtpIsLoading: true }

		case AUTH_SEND_EMAIL_OTP_SUCCESS:
			return { ...state, authSendOtpIsLoading: false }

		case AUTH_SEND_EMAIL_OTP_FAIL:
			return { ...state, authSendOtpIsLoading: false }

		case AUTH_AUTHENTICATE_WITH_OTP_IS_LOADING:
			return { ...state, authAuthenticateWithOtpIsLoading: true }

		case AUTH_AUTHENTICATE_WITH_OTP_SUCCESS:
			return { ...state, authAuthenticateWithOtpIsLoading: false, isLoggedIn: true }

		case AUTH_AUTHENTICATE_WITH_OTP_FAIL:
			return { ...state, authAuthenticateWithOtpIsLoading: false }

		case AUTH_AUTHENTICATE_WITH_EMAIL_OTP_IS_LOADING:
			return { ...state, authAuthenticateWithOtpIsLoading: true }

		case AUTH_AUTHENTICATE_WITH_EMAIL_OTP_SUCCESS:
			return { ...state, authAuthenticateWithOtpIsLoading: false, isLoggedIn: true }

		case AUTH_AUTHENTICATE_WITH_EMAIL_OTP_FAIL:
			return { ...state, authAuthenticateWithOtpIsLoading: false }

		case SHOULD_CHANGE_PASSWORD_SUCCESS:
			let shouldChangePassword = action.rs.data.result
			localStorage.setItem(SHOULD_CHANGE_PASSWORD, encrypt(shouldChangePassword.toString()))
			return { ...state, shouldChangePassword: shouldChangePassword }

		default:
			return state
	}
}

export default authDuck
