import axios from 'axios'
import {getSettings} from '../config/config'
import {getCurrentAuthHeader, spreadObj} from '../helpers/utilities'
import querystring from 'querystring'
import {checkValidStatusCode} from "../helpers/httpHelper";

//<editor-fold desc="Constants">
const GET_REDEMPTION_TRANSACTION_AND_PASSENGERS_IS_LOADING = 'GET_REDEMPTION_TRANSACTION_AND_PASSENGERS_IS_LOADING'
const GET_REDEMPTION_TRANSACTION_AND_PASSENGERS_SUCCESS = 'GET_REDEMPTION_TRANSACTION_AND_PASSENGERS_SUCCESS'
const GET_REDEMPTION_TRANSACTION_AND_PASSENGERS_FAIL = 'GET_REDEMPTION_TRANSACTION_AND_PASSENGERS_FAIL'

let settings = getSettings();
let baseURL = settings.baseURL;
//</editor-fold>

//<editor-fold desc="Actions">
export const getRedemptionTransactionAndPassengers = data => async dispatch => {
    dispatch(_getRedemptionTransactionAndPassengersIsLoading())

    const rs = await axios.get(baseURL + '/RedemptionTransaction/GetRedemptionTransactionAndPassengers', data).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_getRedemptionTransactionAndPassengersFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_getRedemptionTransactionAndPassengersSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getRedemptionTransactionAndPassengersFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _getRedemptionTransactionAndPassengersIsLoading = () => ({
    type: GET_REDEMPTION_TRANSACTION_AND_PASSENGERS_IS_LOADING
})

const _getRedemptionTransactionAndPassengersSuccess = rs => ({
    type: GET_REDEMPTION_TRANSACTION_AND_PASSENGERS_SUCCESS,
    rs
})

const _getRedemptionTransactionAndPassengersFail = () => ({
    type: GET_REDEMPTION_TRANSACTION_AND_PASSENGERS_FAIL
})

export const updateRedemptionTransactionPassengerSinglePax = (idx, sect, k, v) => ({
    type: UPDATE_REDEMPTION_TRANSATION_PASSENGER_SINGLE_PAX,
    idx, sect, k, v
})
const UPDATE_REDEMPTION_TRANSATION_PASSENGER_SINGLE_PAX = 'UPDATE_REDEMPTION_TRANSATION_PASSENGER_SINGLE_PAX'

export const updateRedemptionTransactionPassenger = (k, v) => ({
    type: UPDATE_REDEMPTION_TRANSATION_PASSENGER,
    k, v
})

const UPDATE_REDEMPTION_TRANSATION_PASSENGER = 'UPDATE_REDEMPTION_TRANSATION_PASSENGER'

export const updateRedemptionTransactionAndPassengers = data => async dispatch => {
    dispatch(_updateRedemptionTransactionAndPassengersIsLoading())

    const rs = await axios.post(baseURL + '/RedemptionTransaction/UpdateRedemptionTransactionAndPassengers', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_updateRedemptionTransactionAndPassengersFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_updateRedemptionTransactionAndPassengersSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_updateRedemptionTransactionAndPassengersFail())
        return {success: false, message: rs.data.error.message}
    }
}

const UPDATE_REDEMPTION_TRANSACTION_AND_PASSENGERS_IS_LOADING = 'UPDATE_REDEMPTION_TRANSACTION_AND_PASSENGERS_IS_LOADING'
const UPDATE_REDEMPTION_TRANSACTION_AND_PASSENGERS_SUCCESS = 'UPDATE_REDEMPTION_TRANSACTION_AND_PASSENGERS_SUCCESS'
const UPDATE_REDEMPTION_TRANSACTION_AND_PASSENGERS_FAIL = 'UPDATE_REDEMPTION_TRANSACTION_AND_PASSENGERS_FAIL'

const _updateRedemptionTransactionAndPassengersIsLoading = () => ({
    type: UPDATE_REDEMPTION_TRANSACTION_AND_PASSENGERS_IS_LOADING
})

const _updateRedemptionTransactionAndPassengersSuccess = rs => ({
    type: UPDATE_REDEMPTION_TRANSACTION_AND_PASSENGERS_SUCCESS,
    rs
})

const _updateRedemptionTransactionAndPassengersFail = () => ({
    type: UPDATE_REDEMPTION_TRANSACTION_AND_PASSENGERS_FAIL
})

export const voidTransaction = data => async dispatch => {
    dispatch(_voidTransactionIsLoading())

    const rs = await axios.post(baseURL + '/RedemptionTransaction/VoidTransaction', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_voidTransactionFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_voidTransactionSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_voidTransactionFail())
        return {success: false, message: rs.data.error.message}
    }
}

const VOID_TRANSACTION_IS_LOADING = 'VOID_TRANSACTION_IS_LOADING'
const VOID_TRANSACTION_SUCCESS = 'VOID_TRANSACTION_SUCCESS'
const VOID_TRANSACTION_FAIL = 'VOID_TRANSACTION_FAIL'

const _voidTransactionIsLoading = () => ({
    type: VOID_TRANSACTION_IS_LOADING
})

const _voidTransactionSuccess = rs => ({
    type: VOID_TRANSACTION_SUCCESS,
    rs
})

const _voidTransactionFail = () => ({
    type: VOID_TRANSACTION_FAIL
})

export const redemptionTransactionPassengersFilesUpload = data => async dispatch => {
    dispatch(_redemptionTransactionPassengersFilesUploadIsLoading())

    const rs = await axios.post(baseURL + '/File/FilesUpload?' + querystring.stringify({bucketType: 1}), data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_redemptionTransactionPassengersFilesUploadFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_redemptionTransactionPassengersFilesUploadSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_redemptionTransactionPassengersFilesUploadFail())
        return {success: false, message: rs.data.error.message}
    }
}

const REDEMPTION_TRANSACTION_PASSENGERS_FILES_UPLOAD_IS_LOADING = 'REDEMPTION_TRANSACTION_PASSENGERS_FILES_UPLOAD_IS_LOADING'
const REDEMPTION_TRANSACTION_PASSENGERS_FILES_UPLOAD_SUCCESS = 'REDEMPTION_TRANSACTION_PASSENGERS_FILES_UPLOAD_SUCCESS'
const REDEMPTION_TRANSACTION_PASSENGERS_FILES_UPLOAD_FAIL = 'REDEMPTION_TRANSACTION_PASSENGERS_FILES_UPLOAD_FAIL'

const _redemptionTransactionPassengersFilesUploadIsLoading = () => ({
    type: REDEMPTION_TRANSACTION_PASSENGERS_FILES_UPLOAD_IS_LOADING
})

const _redemptionTransactionPassengersFilesUploadSuccess = rs => ({
    type: REDEMPTION_TRANSACTION_PASSENGERS_FILES_UPLOAD_SUCCESS,
    rs
})

const _redemptionTransactionPassengersFilesUploadFail = () => ({
    type: REDEMPTION_TRANSACTION_PASSENGERS_FILES_UPLOAD_FAIL
})

export const updateRedemptionTransactionPassengersDuck = obj => ({
    type: UPDATE_REDEMPTION_TRANSACTION_PASSENGERS_DUCK,
    obj
})

const UPDATE_REDEMPTION_TRANSACTION_PASSENGERS_DUCK = 'UPDATE_REDEMPTION_TRANSACTION_PASSENGERS_DUCK'

//</editor-fold>

const initETicket = {
    'eTicketNo': '',
    'lastName': '',
    'id': '',
    'sqResponse': '',
    'sector': [
        {
            'sectorNo': 1,
            'origin': '',
            'destination': '',
            'flightNo': '',
            'departureDate': '',
            'arrivalDate': '',
            'transportMean': '',
            'landSea': '',
            'remarks': '',
            'attachmentUri': ''
        },
        {
            'sectorNo': 2,
            'origin': '',
            'destination': '',
            'flightNo': '',
            'departureDate': '',
            'arrivalDate': '',
            'transportMean': '',
            'landSea': '',
            'remarks': '',
            'attachmentUri': ''
        },
        {
            'sectorNo': 3,
            'origin': '',
            'destination': '',
            'flightNo': '',
            'departureDate': '',
            'arrivalDate': '',
            'transportMean': '',
            'landSea': '',
            'remarks': '',
            'attachmentUri': ''
        },
        {
            'sectorNo': 4,
            'origin': '',
            'destination': '',
            'flightNo': '',
            'departureDate': '',
            'arrivalDate': '',
            'transportMean': '',
            'landSea': '',
            'remarks': '',
            'attachmentUri': ''
        },
    ]
}

const initState = {
    redemptionTransaction: {
        id: '',
        collectorFirstName: '',
        collectorLastName: '',
        collectorContactNo: '',
        redeemerId: 0,
        physicalVoucherIssued: false,
        eVoucherIssued: false,
        signatureURI: null,
        groupId: 0,
        allowReceivingInfo: false,
        referenceNo: ''
    },
    passengers: [
        {
            'id': '',
            'groupId': '',
            'transactionId': 0,
            'cardNo': '',
            'firstName': '',
            'lastName': '',
            'dob': '',
            'passportNo': '',
            'countryOfResidence': '',
            'origin': '',
            'sqResponse': '',
            'eTicket': [
                {...initETicket, sector: [...initETicket.sector]},
                {...initETicket, sector: [...initETicket.sector]},
                {...initETicket, sector: [...initETicket.sector]},
                {...initETicket, sector: [...initETicket.sector]}
            ],
            'memberType': '',
            'eTicket1': '',
            'eTicket2': '',
            'eTicket3': '',
            'eTicket4': '',
            'firstFltDepDate': '0001-01-01T00:00:00',
            'firstFltNo': '',
            'secondFltDepDate': '0001-01-01T00:00:00',
            'secondFltNo': '',
            'isDeleted': false,
            'isRedeemed': false,
            'rejected': false,
            'redemptionTransactionId': 0,
            'redeemerId': '',
            'firstFltArrDate': '',
            'secondFltArrDate': '',
            'creationTime': '',
            'ctpEligibility': '',
            'etVerified': ''
        }
    ],
    currentIdx: 0,
    getRedemptionTransactionAndPassengersIsLoading: false,
    updateRedemptionTransactionAndPassengersIsLoading: false,
    redemptionTransactionPassengersFilesUploadIsLoading: false
}

const redemptionTransactionPassengersDuck = (state = initState, action) => {

    let newPassenger, newPassengers, idx, k, v, sect, sector1, sector2, sector3, sector4 , sector2Idx, newSector2, newState, obj
    switch (action.type) {

        case GET_REDEMPTION_TRANSACTION_AND_PASSENGERS_IS_LOADING:
            return {...state, getRedemptionTransactionAndPassengersIsLoading: true}

        case GET_REDEMPTION_TRANSACTION_AND_PASSENGERS_SUCCESS:
            const {data: {result: {redemptionTransaction, passengers}}} = action.rs
            return {
                ...state, redemptionTransaction, passengers, getRedemptionTransactionAndPassengersIsLoading: false
            }

        case GET_REDEMPTION_TRANSACTION_AND_PASSENGERS_FAIL:
            return {...state, getRedemptionTransactionAndPassengersIsLoading: false}

        case UPDATE_REDEMPTION_TRANSATION_PASSENGER_SINGLE_PAX:
            ({idx, sect, k, v} = action)

            if (sect === '' && (k.indexOf('eTicket') === -1)) {
                newPassenger = {...state.passengers[idx]}
                newPassenger[k] = v
            }

            if (sect === '' && k.indexOf('eTicket') !== -1) {
                newPassenger = {...state.passengers[idx]}
                newPassenger[k] = v
                let etixidx = parseInt(k[k.length - 1])
                etixidx--
                newPassenger.eTicket[etixidx].eTicketNo = v
            }

            if (sect === 'lastName') {
                newPassenger = {...state.passengers[idx]}
                let etixidx = parseInt(k[k.length - 1])
                etixidx--
                newPassenger.eTicket[etixidx].lastName = v
            }

            if (sect === 'sector1') {
                newPassenger = {...state.passengers[idx]}
                sector1 = newPassenger.eTicket[0].sector.find(s => s.sectorNo === 1)
                sector1[k] = v
            }

            if (sect === 'sector2') {
                newPassenger = {...state.passengers[idx]}
                sector2 = newPassenger.eTicket[0].sector.find(s => s.sectorNo === 2)
                sector2[k] = v
            }

            if (sect === 'sector3') {
                newPassenger = {...state.passengers[idx]}
                sector3 = newPassenger.eTicket[0].sector.find(s => s.sectorNo === 3)
                sector3[k] = v
            }

            if (sect === 'sector4') {
                newPassenger = {...state.passengers[idx]}
                sector4 = newPassenger.eTicket[0].sector.find(s => s.sectorNo === 4)
                sector4[k] = v
            }

            newPassengers = [...state.passengers]
            newPassengers.splice(idx, 1, newPassenger)

            return {...state, passengers: newPassengers}

        case UPDATE_REDEMPTION_TRANSATION_PASSENGER:
            ({k, v} = action)
            return {...state, [k]: v}

        case UPDATE_REDEMPTION_TRANSACTION_AND_PASSENGERS_IS_LOADING:
            return {...state, updateRedemptionTransactionAndPassengersIsLoading: true}

        case UPDATE_REDEMPTION_TRANSACTION_AND_PASSENGERS_SUCCESS:
            return {...state, updateRedemptionTransactionAndPassengersIsLoading: false}

        case UPDATE_REDEMPTION_TRANSACTION_AND_PASSENGERS_FAIL:
            return {...state, updateRedemptionTransactionAndPassengersIsLoading: false}

        case VOID_TRANSACTION_IS_LOADING:
            return {...state, updateRedemptionTransactionAndPassengersIsLoading: true}
        case VOID_TRANSACTION_SUCCESS:
        case VOID_TRANSACTION_FAIL:
            return {...state, updateRedemptionTransactionAndPassengersIsLoading: false}

        case REDEMPTION_TRANSACTION_PASSENGERS_FILES_UPLOAD_IS_LOADING:
            return {...state, redemptionTransactionPassengersFilesUploadIsLoading: true}

        case REDEMPTION_TRANSACTION_PASSENGERS_FILES_UPLOAD_SUCCESS:
            // append results to existing attachementUris
            idx = state.currentIdx
            newPassenger = {...state.passengers[idx]}
            sector2Idx = newPassenger.eTicket[0].sector.findIndex(s => s.sectorNo === 2)
            newSector2 = newPassenger.eTicket[0].sector[sector2Idx]
            newSector2.attachmentUris = [...newSector2.attachmentUris, ...action.rs.data.result]
            newPassenger.eTicket[0].sector[sector2Idx] = newSector2
            newPassengers = [...state.passengers]
            newPassengers.splice(idx, 1, newPassenger)
            return {
                ...state, redemptionTransactionPassengersFilesUploadIsLoading: false,
                passengers: newPassengers
            }

        case REDEMPTION_TRANSACTION_PASSENGERS_FILES_UPLOAD_FAIL:
            return {...state, redemptionTransactionPassengersFilesUploadIsLoading: false}

        case UPDATE_REDEMPTION_TRANSACTION_PASSENGERS_DUCK:
            ({obj} = action)
            newState = {...state}
            newState = {...spreadObj(newState, obj)}
            return newState

        default:
            return state
    }
}

export default redemptionTransactionPassengersDuck