import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Table, TableBody, TableCell, TableHead, TableRow, TextField, Grid, Button, Typography } from '@material-ui/core'
import Header from '../containers/Header.js'
import { TITLE_PREFIX } from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import StyledSection from '../components/StyledSection'
import { REQUIRED_ERROR_MSG } from '../constants/messages'
import { passengerCardReplacement, getAllRewardsByCardNo } from '../ducks/passengerDuck'
import StyledSnackbar from '../components/StyledSnackbar'
import { toHumanFriendlyDate, toHumanFriendlyDateTime } from '../helpers/utilities'
import { CR_CARD_PREFIX } from '../constants/cardConstants'
const cardReplacementInitState = {
	oldCardNo: '',
	oldCardNoError: '',
	newCardNo: '',
	newCardNoError: '',
	reason: '',
	reasonError: '',
	onApproverAuthenticating: false,
	approverId: '',
	approverIdError: '',
	approverPassword: '',
	approverPasswordError: ''
}

class CardReplacementScreen extends Component {
	constructor(props, context) {
		super(props, context)

		this.state = {
			cardNo: '',
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
			...cardReplacementInitState
		}
	}

	componentDidMount = async () => {
		document.title = `${TITLE_PREFIX} Card Replacement`
	}

	onFieldChange = (key, e) => {
		this.setState({ [key]: e.target.value })
	}

	onSubmit = () => {
		if (this.validate(['oldCardNo', 'newCardNo', 'reason'])) {
			const { oldCardNo, newCardNo } = this.state
			if (oldCardNo.startsWith(CR_CARD_PREFIX) || newCardNo.startsWith(CR_CARD_PREFIX))
				this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'This operation cannot be done with CR Card', ...cardReplacementInitState })
			else this.setState({ onApproverAuthenticating: true })
		}
	}

	approverOnSubmit = async () => {
		await this._cardReplacement()
	}

	approverOnCancel = () => {
		this.setState({ onApproverAuthenticating: false })
	}

	_cardReplacement = async () => {
		const { oldCardNo, newCardNo, reason, approverId, approverPassword } = this.state
		const data = { oldCardNo, newCardNo, reason, approverId, approverPassword }
		const rs = await this.props.passengerCardReplacement(data)
		rs.success
			? this.setState({ showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'Card replaced', ...cardReplacementInitState })
			: this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message, ...cardReplacementInitState })
	}

	onListRewards = async () => {
		const { cardNo } = this.state
		if (await this.props.getAllRewardsByCardNo(cardNo)) {
			this.setState({ oldCardNo: cardNo })
		}
	}

	validate(required) {
		let rs = true
		for (const k of required) {
			const errorKey = k + 'Error'
			if (this.state[k] === '') {
				this.setState({ [errorKey]: REQUIRED_ERROR_MSG })
				rs = false
			} else {
				this.setState({ [errorKey]: '' })
			}
		}
		return rs
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	render() {
		const {
			classes,
			passengerDuck: { passengerCardReplacementIsLoading, getAllRewardsByCardNoIsFetching, rewardsByCardNo }
		} = this.props
		const {
			cardNo,
			oldCardNo,
			oldCardNoError,
			newCardNo,
			newCardNoError,
			reason,
			reasonError,
			onApproverAuthenticating,
			approverId,
			approverIdError,
			approverPassword,
			approverPasswordError
		} = this.state

		return (
			<div>
				<GlobalTimeChecker />
				<Header history={this.props.history} />

				<div className={classes.container}>
					<StyledSection title={'Search card rewards'} isLoading={getAllRewardsByCardNoIsFetching}>
						<Grid container spacing={24} direction={'column'}>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item md={2}>
									<Typography variant={'body1'}>Card no:</Typography>
								</Grid>
								<Grid item>
									<TextField id='' label='' margin='normal' variant='outlined' value={cardNo} onChange={e => this.onFieldChange('cardNo', e)} />
								</Grid>
							</Grid>
							<Grid item>
								<Button color={'primary'} variant='contained' onClick={this.onListRewards}>
									List Rewards
								</Button>
							</Grid>

							{rewardsByCardNo && (
								<Grid item>
									{rewardsByCardNo.length === 0 && <Typography variant={'body1'}>Card not found!</Typography>}
									{rewardsByCardNo.length > 0 && (
										<Table className={classes.table}>
											<TableHead>
												<TableRow>
													<TableCell>No</TableCell>
													<TableCell>Voucher Name</TableCell>
													<TableCell>Voucher Value</TableCell>
													<TableCell>Issued Date</TableCell>
													<TableCell>Expiry Date</TableCell>
													<TableCell>Redeemed/Voided On</TableCell>
													<TableCell>Redeemed Outlet Name</TableCell>
													<TableCell>Voucher Status</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{rewardsByCardNo.length > 0 &&
													rewardsByCardNo.map((r, i) => {
														return (
															<TableRow key={i}>
																<TableCell>
																	<p>{i + 1}</p>
																</TableCell>
																<TableCell>
																	<p>{r.voucherTypeName}</p>
																</TableCell>
																<TableCell>
																	<p>{r.typeValue ? `SGD ${r.typeValue.toFixed(2)}` : 'N/A'}</p>
																</TableCell>
																<TableCell>
																	<p>{toHumanFriendlyDate(r.voucherIssuedOn)}</p>
																</TableCell>
																<TableCell>
																	<p>{toHumanFriendlyDate(r.validTo)}</p>
																</TableCell>
																<TableCell>
																	<p>{toHumanFriendlyDateTime(r.voucherUsedOn)}</p>
																</TableCell>
																<TableCell>
																	<p>{r.redeemedOutletName}</p>
																</TableCell>
																<TableCell>
																	<p>{r.voucherStatus}</p>
																</TableCell>
															</TableRow>
														)
													})}
											</TableBody>
										</Table>
									)}
								</Grid>
							)}
						</Grid>
					</StyledSection>

					{!onApproverAuthenticating && (
						<StyledSection title={'Replace card no'} isLoading={passengerCardReplacementIsLoading}>
							<Grid container spacing={24} direction={'column'}>
								<Grid container item spacing={24} alignItems={'center'}>
									<Grid item md={2}>
										<Typography variant={'body1'}>Old card no:</Typography>
									</Grid>
									<Grid item>
										<TextField
											id=''
											label=''
											margin='normal'
											variant='outlined'
											value={oldCardNo}
											onChange={e => this.onFieldChange('oldCardNo', e)}
											error={!!oldCardNoError}
											helperText={oldCardNoError}
										/>
									</Grid>
								</Grid>
								<Grid container item spacing={24} alignItems={'center'}>
									<Grid item md={2}>
										<Typography variant={'body1'}>New card no:</Typography>
									</Grid>
									<Grid item>
										<TextField
											id=''
											label=''
											margin='normal'
											variant='outlined'
											value={newCardNo}
											onChange={e => this.onFieldChange('newCardNo', e)}
											error={!!newCardNoError}
											helperText={newCardNoError}
										/>
									</Grid>
								</Grid>
								<Grid container item spacing={24} alignItems={'center'}>
									<Grid item md={2}>
										<Typography variant={'body1'}>Reason</Typography>
									</Grid>
									<Grid item>
										<TextField
											id=''
											label=''
											margin='normal'
											variant='outlined'
											value={reason}
											onChange={e => this.onFieldChange('reason', e)}
											error={!!reasonError}
											helperText={reasonError}
										/>
									</Grid>
								</Grid>
								<Grid item>
									<Button color={'primary'} variant='contained' onClick={this.onSubmit}>
										Submit
									</Button>
								</Grid>
							</Grid>
						</StyledSection>
					)}

					{onApproverAuthenticating && (
						<StyledSection title={'Authenticate for approval'} isLoading={passengerCardReplacementIsLoading}>
							<Grid container spacing={24} direction={'column'}>
								<Grid container item spacing={24} alignItems={'center'}>
									<Grid item md={2}>
										<Typography variant={'body1'}>Old Card No</Typography>
									</Grid>
									<Grid item>
										<Typography variant={'body1'}>{oldCardNo}</Typography>
									</Grid>
								</Grid>
								<Grid container item spacing={24} alignItems={'center'}>
									<Grid item md={2}>
										<Typography variant={'body1'}>New Card No</Typography>
									</Grid>
									<Grid item>
										<Typography variant={'body1'}>{newCardNo}</Typography>
									</Grid>
								</Grid>
								<Grid container item spacing={24} alignItems={'center'}>
									<Grid item md={2}>
										<Typography variant={'body1'}>Reason</Typography>
									</Grid>
									<Grid item>
										<Typography variant={'body1'}>{reason}</Typography>
									</Grid>
								</Grid>
								<Grid container item spacing={24} alignItems={'center'}>
									<Grid item md={2}>
										<Typography variant={'body1'}>ID</Typography>
									</Grid>
									<Grid item>
										<TextField
											id=''
											label='User ID'
											margin='normal'
											variant='outlined'
											value={approverId}
											onChange={e => this.onFieldChange('approverId', e)}
											error={!!approverIdError}
											helperText={approverIdError}
										/>
									</Grid>
								</Grid>
								<Grid container item spacing={24} alignItems={'center'}>
									<Grid item md={2}>
										<Typography variant={'body1'}>Password</Typography>
									</Grid>
									<Grid item>
										<TextField
											id=''
											label='Password'
											type='password'
											margin='normal'
											variant='outlined'
											value={approverPassword}
											onChange={e => this.onFieldChange('approverPassword', e)}
											error={!!approverPasswordError}
											helperText={approverPasswordError}
										/>
									</Grid>
								</Grid>
								<Grid container item spacing={24} alignItems={'center'}>
									<Grid item>
										<Button color={'primary'} variant='contained' onClick={this.approverOnCancel}>
											Cancel
										</Button>
									</Grid>
									<Grid item>
										<Button color={'primary'} variant='contained' onClick={this.approverOnSubmit}>
											Submit
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</StyledSection>
					)}
				</div>
				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = theme => ({
	container: theme.container
})

const mapStateToProps = state => {
	const { passengerDuck } = state

	return { passengerDuck }
}

const mapDispatchToProps = dispatch => {
	return {
		passengerCardReplacement: data => dispatch(passengerCardReplacement(data)),
		getAllRewardsByCardNo: cardNo => dispatch(getAllRewardsByCardNo(cardNo))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CardReplacementScreen))
