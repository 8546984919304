import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Grid, TextField, Button, Typography } from '@material-ui/core'
import Header from '../containers/Header.js'
import { TITLE_PREFIX } from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import StyledSection from '../components/StyledSection'
import SearchIssuanceSummaryResultTable from '../containers/search/SearchIssuanceSummaryResultTable'
import { getPassengersByCardRange, getPhysicalVoucherByRange } from '../ducks/searchDuck'
import StyledSnackbar from '../components/StyledSnackbar'
import StyledPlaceholder from '../components/StyledPlaceholder'
import SearchNav from '../containers/search/SearchNav'
import { getCurrentAuthHeader, hasPermissions, parseFullCardNoWithPrefix } from '../helpers/utilities'
import { Redirect } from 'react-router-dom'
import { SEARCH_GROUP_PERMISSION } from '../constants/permissions'

const FIELDS = [
	{ key: 'refNo', label: 'Group ref no.' },
	{ key: 'paxCount', label: 'No. of Pax' },
	{ key: 'firstFlightArrivalDate', label: 'First Flt arrival date' },
	{ key: 'lastFlightDepartureDate', label: 'Last Flt depart date' },
	{ key: 'collectionDate', label: 'Collection date' },
	{ key: 'tourCompany', label: 'Company' },
	{ key: 'tourLeader', label: 'Tour Leader' },
	{ key: 'submitterName', label: 'Submitted by' },
	{ key: 'approverName', label: 'Approved by' },
	{ key: 'submissionTime', label: 'Submitted date' },
	{ key: 'status', label: 'Status' }
]

class SearchGroupScreen extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
			cardNoFrom: '',
			cardNoTo: '',
			voucherNoFrom: '',
			voucherNoTo: '',
			hasSearched: false,
			fields: []
		}
	}

	componentDidMount() {
		document.title = `${TITLE_PREFIX} Search Issuance Summary`
	}

	onCardFieldsChange = (k, e) => {
		if (k === 'cardNoFrom' || k === 'cardNoTo') this.setState({ [k]: parseFullCardNoWithPrefix(e.target.value) })
		else this.setState({ [k]: e.target.value })
	}

	onPhysicalVoucherFieldsChange = (k, e) => {
		let numericValue = e.target.value.replace(/\D/g, '')
		this.setState({ [k]: numericValue })
	}

	onSubmit = () => {
		if (this.props.searchDuck.getPassengersByCardRangeIsLoading || this.props.searchDuck.getPhysicalVoucherByRangeIsLoading) {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: 'Still retrieving previous data'
			})
			return
		}

		const { cardNoFrom, cardNoTo, voucherNoFrom, voucherNoTo } = this.state

		if ((cardNoFrom !== '' || cardNoTo !== '') && (voucherNoFrom !== '' || voucherNoTo !== '')) {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: 'Please search either card or voucher'
			})
			return
		}

		if ((cardNoFrom !== '' || cardNoTo !== '') && (voucherNoFrom !== '' || voucherNoTo !== '')) {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: 'Please search either card or voucher'
			})
			return
		}

		if (cardNoFrom !== '' && cardNoTo !== '') {
			if (parseInt(cardNoTo) - parseInt(cardNoFrom) > 500) {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: 'Please limit search to 500'
				})
			}
			const data = { params: { cardNoFrom, cardNoTo }, ...getCurrentAuthHeader() }
			this.setState(
				{
					fields: [
						{ key: 'eTicket1', label: 'E-Ticket' },
						{ key: 'cardNo', label: 'Card Number' },
						{ key: 'redeemedBy', label: 'CSO Name' },
						{ key: 'redeemedDateTime', label: 'Date' },
						{ key: 'redeemed', label: 'Status' }
					],
					hasSearched: true
				},
				() => {
					this.props.getPassengersByCardRange(data)
				}
			)
		}

		if (voucherNoFrom !== '' && voucherNoTo !== '') {
			if (parseInt(voucherNoTo) - parseInt(voucherNoFrom) > 500) {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: 'Please limit search to 500'
				})
			}
			const data = { params: { voucherNoFrom, voucherNoTo }, ...getCurrentAuthHeader() }
			this.props.getPhysicalVoucherByRange(data)
			this.setState({
				fields: [
					{ key: 'eTicket1', label: 'E-Ticket' },
					{ key: 'voucherType', label: 'Voucher Type' },
					{ key: 'voucherNo', label: 'Voucher Number' },
					{ key: 'redeemedBy', label: 'CSO Name' },
					{ key: 'redeemedDateTime', label: 'Date' },
					{ key: 'redeemed', label: 'Status' }
				],
				hasSearched: true
			})
		}
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	_formatData = data => {
		if (data.length > 0) {
			if (data[0].voucherNo) {
				data.sort((a, b) => a.voucherNo - b.voucherNo)
			}
		}
		return data
	}

	render() {
		const {
			classes,
			searchDuck: { cardVoucherRangeSearchResults, getPassengersByCardRangeIsLoading, getPhysicalVoucherByRangeIsLoading }
		} = this.props
		const { cardNoFrom, cardNoTo, voucherNoFrom, voucherNoTo, hasSearched, fields } = this.state
		const isLoading = getPassengersByCardRangeIsLoading || getPhysicalVoucherByRangeIsLoading

		if (!hasPermissions(SEARCH_GROUP_PERMISSION)) {
			return <Redirect to={'/not-found'} />
		}

		return (
			<div>
				<GlobalTimeChecker />
				<Header history={this.props.history} />
				<SearchNav />

				<div className={classes.container}>
					<StyledSection title={''} isLoading={isLoading}>
						<Grid container spacing={24} direction={'column'}>
							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={2}>
									<Typography variant={'body1'}>Card No</Typography>
								</Grid>
								<Grid item>
									<TextField id='' label='From' variant='outlined' value={cardNoFrom} onChange={e => this.onCardFieldsChange('cardNoFrom', e)} />
								</Grid>
								<Grid item>
									<TextField id='' label='To' variant='outlined' value={cardNoTo} onChange={e => this.onCardFieldsChange('cardNoTo', e)} />
								</Grid>
							</Grid>

							<Grid container item spacing={24} alignItems={'center'}>
								<Grid item xs={2}>
									<Typography variant={'body1'}>Physical Voucher</Typography>
								</Grid>
								<Grid item>
									<TextField
										id=''
										label='From'
										variant='outlined'
										value={voucherNoFrom}
										onChange={e => this.onPhysicalVoucherFieldsChange('voucherNoFrom', e)}
									/>
								</Grid>
								<Grid item>
									<TextField id='' label='To' variant='outlined' value={voucherNoTo} onChange={e => this.onPhysicalVoucherFieldsChange('voucherNoTo', e)} />
								</Grid>
							</Grid>

							<Grid container item spacing={24} direction={'column'}>
								<Grid item>
									<Button color={'primary'} variant='contained' onClick={this.onSubmit}>
										Submit
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</StyledSection>

					{hasSearched && (
						<StyledSection title={'Search result'} isLoading={isLoading}>
							{cardVoucherRangeSearchResults.length > 0 && (
								<SearchIssuanceSummaryResultTable data={this._formatData(cardVoucherRangeSearchResults)} fields={fields} history={this.props.history} />
							)}

							{!isLoading && cardVoucherRangeSearchResults.length < 1 && <StyledPlaceholder message={'No results found'} />}
						</StyledSection>
					)}
				</div>
				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = theme => ({
	container: theme.container
})

const mapStateToProps = state => {
	const { searchDuck } = state

	return { searchDuck }
}

const mapDispatchToProps = dispatch => {
	return {
		getPassengersByCardRange: data => dispatch(getPassengersByCardRange(data)),
		getPhysicalVoucherByRange: data => dispatch(getPhysicalVoucherByRange(data))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchGroupScreen))
