import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles, Grid, Button, Divider, Typography, TextField, ListItem, ListItemText, ListItemSecondaryAction, Paper, List, FormControlLabel, Switch } from '@material-ui/core'
import * as cloneDeep from 'lodash/cloneDeep'
import GeneralFormColumnsContainer from '../../components/generalForm/GeneralFormColumns.js'
import GeneralTextDate from '../../components/generalForm/GeneralTextDate.js'
import {
	updateCollectorPersonalDetails,
	updateCurrentPaxFormDetails,
	clearPaxDetails,
	commitFormDetailsIntoList,
	updateCurrentPaxCtpValue,
	deletePax,
	restorePax,
	uploadFile,
	updateEntireIndividualForm,
	initFormDetails,
	copyFormDetailsToCurrentPax,
	cancelEditingPax,
	individualFilesUpload,
} from '../../ducks/individualFormDuck.js'
import { getAllCampaigns } from '../../ducks/campaignPeriodDuck.js'
import ParticularsTable from '../../components/ParticularsTable.js'
import Countries from '../../data/countriesCRM'
import { formatFlightNoHelper } from '../../helpers/formatFlightNoHelper'
import PassportParser from '../../helpers/passportParser'
import { FlightNos } from '../../dummy/flightNos'
import StyledSection from '../../components/StyledSection'
import StyledSnackbar from '../../components/StyledSnackbar'
import StyledPlaceholder from '../../components/StyledPlaceholder'
import { checkBonusCities } from '../../helpers/voucherBonusHelper'
import { checkBlankForm, validateCurrentPax, validateFormDetailsList } from './helper/individualFlightFormHelper'

import IndividualETicketForm from './IndividualETicketForm'
import { createEmptyRedemptionTransaction, updateRedemptionTransaction } from '../../ducks/redemptionTransactionDuck'
import axios from 'axios'
import { getSettings } from '../../config/config'
import { getCurrentAuthHeader, myaxios } from '../../helpers/utilities'
import GeneralDropzone from '../../components/generalForm/GeneralDropzone'
import Spacing from '../../components/Spacing'
import UploadFilesList from '../../components/UploadFilesList'
import StyledDeleteBtn from '../../components/StyledDeleteBtn'
import StyledUndoBtn from '../../components/StyledUndoBtn'
import DeleteDialog from '../../components/DeleteDialog'
import { attachmentBucket } from '../../config/config'
import { checkValidStatusCode } from '../../helpers/httpHelper'
import PromiseModalWrapper from '../../components/promiseModal/PromiseModalWrapper'
import { NA, LAND, SEA, SCOOT } from '../../constants/sector2Options.js'
import { sector2FormBuilder, sector3FormBuilder } from '../../helpers/individualFlightSectorFormHelper.js'

let settings = getSettings()
let baseURL = settings.baseURL

class IndividualFlightForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			paxFormFields: [
				{ key: 'dob', label: 'Date of birth*', type: 'general-text-date' },
				{ key: 'dob', label: 'Date of birth*', type: 'custom-text-date' },
				{ key: 'firstName', label: 'First/Given Name*', type: 'text' },
				{ key: 'lastName', label: 'Last Name/Surname*', type: 'text' },
				{ key: 'remarks', label: 'Remarks', type: 'text' },
			],
			sector1FormFields: [],
			sector2FormFields: [],
			sector3FormFields: [],
			sector4FormFields: [],
			files: [],
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
			readyToProceedAfterPaxCommitted: false,
			ppInputTimeout: null,
			previousDraftExist: false,
			deletedOriginalAttachmentIdx: new Set(),
			showDeleteDialog: false,
			afterDeleteConfirm: '',
			promiseModalProceedMessage: '',
			promiseModalCancelMessage: '',
			promiseModalContent: '',
		}

		this.flightNoOptions = null
		this.countriesOptions = null
		this.individualETicket = null
		this.saveDraftTimer = null
		this.promiseModalRef = React.createRef()
	}

	componentDidMount = async () => {
		this.props.getAllCampaigns()
		this.flightNoOptions = formatFlightNoHelper(FlightNos)
		this.countriesOptions = Countries
		if (this.props.departureFlightNoList.length > 0) {
			this._setFlightSectorFields()
		}
		if (!this.props.redemptionTransaction.id) {
			this.props.createEmptyRedemptionTransaction()
		}

		this.saveDraftTimer = setInterval(() => {
			this.onSaveDraft()
		}, 30000)
	}

	componentDidUpdate = (prevProps) => {
		let formDetails = cloneDeep(this.props.currentPaxFormDetails)

		if (prevProps.currentPaxFormDetails.sector1Details.search !== this.props.currentPaxFormDetails.sector1Details.search) {
			if (this.props.currentPaxFormDetails.sector1Details.search) {
				const terms = this.props.currentPaxFormDetails.sector1Details.search == 'Select...' ? [] : this.props.currentPaxFormDetails.sector1Details.search.split('-')
				this._updateMultipleFormDetails(formDetails, [
					{
						detailsCategory: 'sector1Details',
						key: 'flightNo',
						value: terms.length > 0 ? terms[0].trim() : '',
					},
					{
						detailsCategory: 'sector1Details',
						key: 'origin',
						value: terms.length > 0 ? terms[1].trim() : '',
					},
					{
						detailsCategory: 'sector1Details',
						key: 'originCode',
						value: terms.length > 0 ? terms[2].trim() : '',
					},
				])
			}
		}

		if (prevProps.currentPaxFormDetails.sector2Details.search !== this.props.currentPaxFormDetails.sector2Details.search) {
			if (this.props.currentPaxFormDetails.sector2Details.search) {
				const terms = this.props.currentPaxFormDetails.sector2Details.search == 'Select...' ? [] : this.props.currentPaxFormDetails.sector2Details.search.split('-')
				this._updateMultipleFormDetails(formDetails, [
					{
						detailsCategory: 'sector2Details',
						key: 'flightNo',
						value: terms.length > 0 ? terms[0].trim() : '',
					},
					{
						detailsCategory: 'sector2Details',
						key: 'destination',
						value: terms.length > 0 ? terms[1].trim() : '',
					},
					{
						detailsCategory: 'sector2Details',
						key: 'destinationCode',
						value: terms.length > 0 ? terms[2].trim() : '',
					},
				])
			}
		}

		if (prevProps.currentPaxFormDetails.sector3Details.search !== this.props.currentPaxFormDetails.sector3Details.search) {
			if (this.props.currentPaxFormDetails.sector3Details.search) {
				const terms = this.props.currentPaxFormDetails.sector3Details.search == 'Select...' ? [] : this.props.currentPaxFormDetails.sector3Details.search.split('-')
				this._updateMultipleFormDetails(formDetails, [
					{
						detailsCategory: 'sector3Details',
						key: 'flightNo',
						value: terms.length > 0 ? terms[0].trim() : '',
					},
					{
						detailsCategory: 'sector3Details',
						key: 'origin',
						value: terms.length > 0 ? terms[1].trim() : '',
					},
					{
						detailsCategory: 'sector3Details',
						key: 'originCode',
						value: terms.length > 0 ? terms[2].trim() : '',
					},
				])
			}
		}

		if (prevProps.currentPaxFormDetails.sector4Details.search !== this.props.currentPaxFormDetails.sector4Details.search) {
			if (this.props.currentPaxFormDetails.sector4Details.search) {
				const terms = this.props.currentPaxFormDetails.sector4Details.search == 'Select...' ? [] : this.props.currentPaxFormDetails.sector4Details.search.split('-')
				this._updateMultipleFormDetails(formDetails, [
					{
						detailsCategory: 'sector4Details',
						key: 'flightNo',
						value: terms.length > 0 ? terms[0].trim() : '',
					},
					{
						detailsCategory: 'sector4Details',
						key: 'destination',
						value: terms.length > 0 ? terms[1].trim() : '',
					},
					{
						detailsCategory: 'sector4Details',
						key: 'destinationCode',
						value: terms.length > 0 ? terms[2].trim() : '',
					},
				])
			}
		}

		if (prevProps.currentPaxFormDetails.sector1Details.flightNo !== this.props.currentPaxFormDetails.sector1Details.flightNo) {
			if (this.props.currentPaxFormDetails.sector1Details.flightNo) {
				this._updateDataBasedOnFlightNo({ sectorKey: 'sector1Details' })
			}
		}

		if (prevProps.currentPaxFormDetails.sector4Details.flightNo !== this.props.currentPaxFormDetails.sector4Details.flightNo) {
			if (this.props.currentPaxFormDetails.sector4Details.flightNo) {
				this._updateDataBasedOnFlightNo({ sectorKey: 'sector4Details' })
			}
		}

		if (prevProps.currentPaxFormDetails.sector2Details.flightNo !== this.props.currentPaxFormDetails.sector2Details.flightNo) {
			if (this.props.currentPaxFormDetails.sector2Details.flightNo) {
				this._updateDataBasedOnFlightNo({ sectorKey: 'sector2Details' })
			}
		}

		if (prevProps.currentPaxFormDetails.sector3Details.flightNo !== this.props.currentPaxFormDetails.sector3Details.flightNo) {
			if (this.props.currentPaxFormDetails.sector3Details.flightNo) {
				this._updateDataBasedOnFlightNo({ sectorKey: 'sector3Details' })
			}
		}

		if (prevProps.currentPaxFormDetails.sector1Details.origin !== this.props.currentPaxFormDetails.sector1Details.origin) {
			if (this.props.currentPaxFormDetails.sector1Details.origin) {
				this._updateCtpVoucherBasedOnOrigin()
			}
		}


		if (prevProps.isFlightPopulated !== this.props.isFlightPopulated) {
			if (this.props.isFlightPopulated) {
				this._setFlightSectorFields()
			}
		}

		if (prevProps.formDetailsList.length < this.props.formDetailsList.length) {
			if (this.state.readyToProceedAfterPaxCommitted) {
				this._validateAllPassengersBeforeNextScreen()
			}
		}

		if (prevProps.currentPaxFormDetails.sector2Details.destinationCityCode !== this.props.currentPaxFormDetails.sector2Details.destinationCityCode) {
			this._updateCityBasedOnCityCode({ sectorKey: 'sector2Details' })
		}

		if (prevProps.currentPaxFormDetails.sector3Details.destinationCityCode !== this.props.currentPaxFormDetails.sector3Details.destinationCityCode) {
			this._updateCityBasedOnCityCode({ sectorKey: 'sector3Details' })
		}

		if (prevProps.currentPaxFormDetails.sector2Details.landSeaScootBool !== this.props.currentPaxFormDetails.sector2Details.landSeaScootBool) {
			let landSeaScootBoolValue = this.props.currentPaxFormDetails.sector2Details.landSeaScootBool
			let flightCompulsoryFlag = true
			if (landSeaScootBoolValue === LAND || landSeaScootBoolValue === SEA) flightCompulsoryFlag = false
			else if (landSeaScootBoolValue === NA || landSeaScootBoolValue === SCOOT) flightCompulsoryFlag = true
			this.setState({
				sector2FormFields: sector2FormBuilder({
					flightCompulsory: flightCompulsoryFlag,
					departureFlightNoList: flightCompulsoryFlag ? this.props.departureFlightNoList : [],
					departureCityList: flightCompulsoryFlag ? this.props.departureCityList : [],
					departureSearchOptions: flightCompulsoryFlag ? this.props.departureSearchOptions : []
				}),
			})
		}

		if (prevProps.currentPaxFormDetails.sector3Details.landSeaScootBool !== this.props.currentPaxFormDetails.sector3Details.landSeaScootBool) {
			let landSeaScootBoolValue = this.props.currentPaxFormDetails.sector3Details.landSeaScootBool
			let flightCompulsoryFlag = true
			if (landSeaScootBoolValue === LAND || landSeaScootBoolValue === SEA) flightCompulsoryFlag = false
			else if (landSeaScootBoolValue === NA || landSeaScootBoolValue === SCOOT) flightCompulsoryFlag = true
			this.setState({
				sector3FormFields: sector3FormBuilder({
					arrivalFlightNoList: flightCompulsoryFlag ? this.props.arrivalFlightNoList : [],
					arrivalCityList: flightCompulsoryFlag ? this.props.arrivalCityList : [],
					arrivalSearchOptions: flightCompulsoryFlag ? this.props.arrivalSearchOptions : []
				}),
			})
		}
	}

	componentWillUnmount = async () => {
		clearInterval(this.saveDraftTimer)
	}

	_setFlightSectorFields = () => {
		this.setState({
			sector1FormFields: [
				{ key: 'search', label: 'Search Flight', type: 'autocomplete', options: this.props.arrivalSearchOptions },
				{ key: 'flightNo', label: 'Flight No*', type: 'autocomplete', options: this.props.arrivalFlightNoList, disabled: true },
				{ key: 'origin', label: 'Point Of Origin', type: 'autocomplete', options: this.props.arrivalCityList, disabled: true },
				{ key: 'departingDate', label: 'Departing Date*', type: 'general-text-date' },
				{
					key: 'destination',
					label: 'Destination*',
					type: 'autocomplete',
					options: [{ value: 'Singapore', label: 'Singapore' }],
					disabled: true,
				},
				{ key: 'remarks', label: 'Remark', type: 'multiline', multilineRows: 4 },
			],
			sector2FormFields: sector2FormBuilder({
                flightCompulsory: true,
				departureFlightNoList: this.props.departureFlightNoList,
				departureCityList: this.props.departureCityList,
				departureSearchOptions: this.props.departureSearchOptions,
			}),
			sector3FormFields: sector3FormBuilder({
                arrivalFlightNoList: this.props.arrivalFlightNoList,
                arrivalCityList: this.props.arrivalCityList,
                arrivalSearchOptions: this.props.arrivalSearchOptions,
			}),
            sector4FormFields: [
                { key: 'search', label: 'Search Flight', type: 'autocomplete', options: this.props.departureSearchOptions },
                { key: 'flightNo', label: 'Flight No', type: 'autocomplete', options: this.props.departureFlightNoList, disabled: true },
                { key: 'origin', label: 'Point Of Origin', type: 'autocomplete', options: [{ value: 'Singapore', label: 'Singapore' }], disabled: true },
                { key: 'departingDate', label: 'Departing Date', type: 'general-text-date' },
                {
                    key: 'destination',
                    label: 'Destination',
                    type: 'autocomplete',
                    options: this.props.departureFlightNoList,
                    disabled: true,
                },
                { key: 'remarks', label: 'Remark', type: 'multiline', multilineRows: 4 },
            ],
		})
	};

	_updateCtpVoucherBasedOnOrigin = () => {
		let origin = this.props.currentPaxFormDetails.sector1Details.origin
		if (origin.length >= 3) {
			// let ctpValue = checkBonusCities(origin) ? 40 : 20
			this.props.updateCurrentPaxCtpValue(0)
		}
	}

	_updateDataBasedOnFlightNo = ({ sectorKey }) => {
		let selectedFlightNo = this.props.currentPaxFormDetails[sectorKey].flightNo
		if (selectedFlightNo.length >= 3) {
			if (sectorKey === 'sector1Details') {
				const matchedFlights = this.props.flightData.filter((flight) => flight.flightNo === selectedFlightNo && flight.arrivalCityName === 'Singapore')
				if (matchedFlights.length) {
					const citiesFiltered = this.props.arrivalCityList.filter((f) => matchedFlights.map((m) => m.departureCityName).includes(f.label))
					this.setState((prevState) => {
						const idx = prevState.sector1FormFields.findIndex((f) => f.key === 'origin')
						prevState.sector1FormFields.splice(idx, 1, {
							key: 'origin',
							label: 'Point Of Origin',
							type: 'autocomplete',
							options: citiesFiltered,
						})

						return {
							sector1FormFields: [...prevState.sector1FormFields],
							promtedRetrieveDraft: false,
						}
					})
					if (this.props.replacingFromDraft !== true) {
						const cityCount = new Set(matchedFlights.map((m) => m.departureCityName)).size

						if (cityCount === 1) {
							this._updateFormDetails({
								detailsCategory: sectorKey,
								key: 'origin',
								value: matchedFlights[0].departureCityName,
							})
						}
					}
				}
			} else {
				const matchedFlights = this.props.flightData.filter((flight) => flight.flightNo === selectedFlightNo && flight.departureCityName === 'Singapore')
				if (matchedFlights.length) {
					const citiesFiltered = this.props.departureCityList.filter((f) => matchedFlights.map((m) => m.arrivalCityName).includes(f.label))
					this.setState((prevState) => {
						const idx = prevState.sector2FormFields.findIndex((f) => f.key === 'destination')
						prevState.sector2FormFields.splice(idx, 1, {
							key: 'destination',
							label: 'Destination',
							type: 'autocomplete',
							options: citiesFiltered,
						})

						return {
							sector2FormFields: [...prevState.sector2FormFields],
						}
					})
					if (this.props.replacingFromDraft !== true) {
						const cityCount = new Set(matchedFlights.map((m) => m.arrivalCityName)).size

						if (cityCount === 1) {
							this._updateFormDetails({
								detailsCategory: sectorKey,
								key: 'destination',
								value: matchedFlights[0].arrivalCityName,
							})
						}
					}
				}
			}
		}
	}

	_updateCityBasedOnCityCode = ({ sectorKey }) => {
		let destinationCityCode = this.props.currentPaxFormDetails[sectorKey].destinationCityCode
		let matchedFlight = this.props.flightData.find((flight) => flight.departureCityCode === destinationCityCode)
		if (matchedFlight) {
			setTimeout(() => {
				this._updateMultipleFormDetails([{ detailsCategory: sectorKey, key: 'destination', value: matchedFlight.departureCityName }])
			}, 500)
		}
	}

	_checkApprovedTicketNo = (ticketNo) => {
		let subString = ticketNo.substr(0, 3)
		return subString === '618' || subString === '629' || subString === '086' || subString === '081' || subString === '000'
	}

	/*
    handleETicketsDetailsChange = (key, value) => {
        if (/[^0-9]/.test(value)) return false
        if (value.length >= 3) {
            let approvedTicketNo = this._checkApprovedTicketNo(value)
            if (!approvedTicketNo) {
                this.setState({[key + 'Error']: ETICKET_ELIGIBILITY_ERROR_MSG})
            } else {
                this.setState({[key + 'Error']: ''})
            }
        }

        this._updateFormDetails({detailsCategory: 'eTicketsDetails', key, value})
    }
    */

	handlePersonalDetailsChange = (key, value) => {
		this._updateFormDetails({ detailsCategory: 'personalDetails', key, value })

		// check for scan
		if (key === 'firstName') {
			clearTimeout(this.ppInputTimeout)
			this.ppInputTimeout = setTimeout(() => {
				let regex = /\//gi
				if (value.match(regex) && value.match(regex).length >= 4) {
					this._processScanPassport(value)
				}
			}, 1000)
		}
	}

	_processScanPassport = (passportString) => {
		let passportParser = new PassportParser(passportString)
		let passportData = passportParser.getData()
		this._updateMultipleFormDetails([
			{ detailsCategory: 'personalDetails', key: 'firstName', value: passportData.firstName },
			{ detailsCategory: 'personalDetails', key: 'lastName', value: passportData.lastName },
			{ detailsCategory: 'personalDetails', key: 'dob', value: passportData.dob },
		])
	}

	handleSector1DetailsChange = (key, value) => {
		this._updateFormDetails({ detailsCategory: 'sector1Details', key, value })
	}

	handleSector2DetailsChange = (key, value) => {
		this._updateFormDetails({ detailsCategory: 'sector2Details', key, value })
	}

	handleSector3DetailsChange = (key, value) => {
		this._updateFormDetails({ detailsCategory: 'sector3Details', key, value })
	}

	handleSector4DetailsChange = (key, value) => {
		this._updateFormDetails({ detailsCategory: 'sector4Details', key, value })
	}

	_updateFormDetails = ({ detailsCategory, key, value }) => {
		if (key === 'landSeaRef') {
			this.setState({ fileupload: value })
			return
		}

		let formDetails = cloneDeep(this.props.currentPaxFormDetails)
		formDetails[detailsCategory][key] = value
		if (key === 'flightNo') {
			//todo: syd remove this when flightNo option is removed.
			formDetails[detailsCategory].search = null
		}

		// case select sea/land option in sector 2 & 3
		if (key === 'landSeaScootBool' && (value === 'sea' || value === 'land')) {
			// remove search, flight no
			formDetails[detailsCategory].search = null
			formDetails[detailsCategory].flightNo = null
			// case sector 2: remove destination
			if (detailsCategory === 'sector2Details') {
				formDetails[detailsCategory].destination = null
				formDetails[detailsCategory].destinationCode = null
			}
			// case sector 3: remove POO
			if (detailsCategory === 'sector3Details') {
				formDetails[detailsCategory].origin = null
				formDetails[detailsCategory].originCode = null
			}
		}

		if (formDetails['personalDetails'].numberOfSector === 4) {
			// get fligt data
			const { flightData } = JSON.parse(localStorage.getItem('FLIGHT_DATA'))

			// set default value for sector 4 if sector 1 selected
			let sectionList = flightData;
			if (detailsCategory === 'sector1Details' && key == 'search') {
				if (value === 'Select...') {
					formDetails['sector4Details'][key] = value
				} else {
					sectionList = flightData.filter(m => m['departureCityName'] === 'Singapore')
					let portCodeSector1 = value.split('-').pop().trim()
					let dfFlightSector4 = sectionList.filter(m => m['arrivalPortCode'] === portCodeSector1)[0]
					if (dfFlightSector4 !== null && dfFlightSector4 !== undefined) {
						let sector4Search = `${dfFlightSector4.flightNo} - ${dfFlightSector4.arrivalCityName} - ${dfFlightSector4.arrivalPortCode}`
						formDetails['sector4Details'][key] = sector4Search
					}
				}
			}

			// set default value for sector 3 if sector 2 selected
			sectionList = flightData;
			if (detailsCategory === 'sector2Details' && key == 'search') {
				if (value === 'Select...') {
					formDetails['sector3Details'][key] = value
				} else {
					sectionList = flightData.filter(m => m['arrivalCityName'] === 'Singapore')
					let portCodeSector2 = value.split('-').pop().trim()
					let dfFlightSector3 = sectionList.filter(m => m['departurePortCode'] === portCodeSector2)[0]
					if (dfFlightSector3 !== null && dfFlightSector3 !== undefined) {
						let sector3Search = `${dfFlightSector3.flightNo} - ${dfFlightSector3.departureCityName} - ${dfFlightSector3.departurePortCode}`
						formDetails['sector3Details'][key] = sector3Search
					}
				}
			}
		}
		
		this.props.updateCurrentPaxFormDetails(formDetails)
	}

	_updateMultipleFormDetails = (formDetails, detailsArray) => {
		detailsArray.forEach((item) => {
			formDetails[item.detailsCategory][item.key] = item.value
		})
		this.props.updateCurrentPaxFormDetails(formDetails)
	}

	_commitCurrentPax = async () => {
		const { deletedOriginalAttachmentIdx } = this.state
		if (deletedOriginalAttachmentIdx.size > 0) {
			return this.setState({ showDeleteDialog: true, afterDeleteConfirm: '_commitCurrentPax' })
		} else {
			const { files } = this.state
			if (files.length > 0) {
				const rs = await this.uploadFiles()
				// cannot store file objects in redux, have to store it in state, upload, then pass the url string to
				// redux const formData = new FormData() formData.append('file', this.state.fileupload[0],
				// this.state.fileupload[0].name) const rs = await this.props.uploadFile(formData, 'file')
				if (rs.success) {
					this.props.commitFormDetailsIntoList()
					this.setState({ files: [] })
				} else {
					this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Files upload failed' })
					return
				}
			} else {
				this.props.commitFormDetailsIntoList()
			}
		}
	}

	async uploadFiles() {
		const { files } = this.state
		let formData = new FormData()
		files.forEach((f) => {
			formData.append('files', f, f.name)
		})
		const rs = await this.props.individualFilesUpload(formData)
		if (rs.success) {
			this.setState({ files: [] })
		}
		return rs
	}

	_validateMaxPassengers = () => {
		const { formDetailsList } = this.props
		let c = 0
		formDetailsList.forEach((p) => {
			if (p.isDeleted === false) c++
		})
		if (c >= 9) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Max 9 passengers allowed' })
			return false
		}
		return true
	}

	addPax = async () => {
		if (!this._validateMaxPassengers()) return
		if (checkBlankForm(this.props.currentPaxFormDetails, initFormDetails)) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Nothing to add' })
			return
		}

		this.calNoSCurrentPax(true)
		let validation = validateCurrentPax({
			currentPaxFormDetails: this.props.currentPaxFormDetails,
			formDetailsList: this.props.formDetailsList,
			campaignPeriods: this.props.campaigns,
			files: this.state.files,
		})
		if (!validation.success) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: validation.message })
			return
		}

		this._commitCurrentPax()
		this.individualETicket.resetAddETicketButton()
		window.scrollTo(0, 0)
	}

	onClickNext = async () => {
		if (this.props.formDetailsList.length > 0 && checkBlankForm(this.props.currentPaxFormDetails, initFormDetails)) {
			//if (this.props.formDetailsList.length > 0) {
			this._validateAllPassengersBeforeNextScreen()
			return
		}

		this.calNoSCurrentPax(false)
		let validateCurrentPaxResult = validateCurrentPax({
			currentPaxFormDetails: this.props.currentPaxFormDetails,
			formDetailsList: this.props.formDetailsList,
			campaignPeriods: this.props.campaigns,
			files: this.state.files,
		})

		if (!validateCurrentPaxResult.success) {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: validateCurrentPaxResult.message,
			})
			return
		}

		this._commitCurrentPax()

		// set state to ready to wait for pax to be committed.
		this.setState({ readyToProceedAfterPaxCommitted: true })
	}

	_validateAllPassengersBeforeNextScreen = () => {
		let undeletedPassengers = this.props.formDetailsList.filter((p) => p.isDeleted !== true)
		if (undeletedPassengers.length > 9) {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: 'Number of passengers cannot exceed 9',
			})
			return
		}

		let validateFormDetailsListResult = validateFormDetailsList({ formDetailsList: this.props.formDetailsList })
		if (!validateFormDetailsListResult.success) {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: validateFormDetailsListResult.message,
			})
			this.setState({ readyToProceedAfterPaxCommitted: false })
			return
		}

		this._proceedToNextScreen()
	}

	_proceedToNextScreen = () => {
		window.scrollTo(0, 0)
		const { flightData } = this.props
		this.props.updateCollectorPersonalDetails(flightData)
		this.props.history.push('/individual-collector')
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	onClear = () => {
		this.props.clearPaxDetails()
		this.individualETicket.resetAddETicketButton()
		// this.individualETicket.focusETicket(0)
	}

	_onDelete = (pax) => {
		this.props.deletePax(pax)
	}

	_onRestore = (pax) => {
		if (!this._validateMaxPassengers()) return false
		this.props.restorePax(pax)
	}

	onSaveDraft = async () => {
		const {
			individualFormDuck: { formDetailsList, currentPaxFormDetails },
			redemptionTransaction,
		} = this.props
		if (formDetailsList.length === 0 && !currentPaxFormDetails.eTicketsDetails.eTicket1) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Please fill up ETicket to save' })
			return
		}

		if (!redemptionTransaction.id) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Error saving. Please try again' })
			return
		}

		const { deletedOriginalAttachmentIdx } = this.state
		if (deletedOriginalAttachmentIdx.size > 0) {
			return this.setState({ showDeleteDialog: true, afterDeleteConfirm: 'onSaveDraft' })
		} else {
			const { files } = this.state
			if (files.length > 0) {
				const rs = await this.uploadFiles()
				if (!rs.success) {
					this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Files upload failed' })
					return
				}
			}
		}

		let individualFormJsonString = JSON.stringify(this.props.individualFormDuck)

		let submitDraftObj = {
			redemptionTransactionId: redemptionTransaction.id,
			userId: localStorage.getItem('userId'),
			data: individualFormJsonString,
		}

		if (formDetailsList.length > 0) {
			submitDraftObj.eTicket1 = formDetailsList[0].eTicketsDetails.eTicket1
			submitDraftObj.eTicket2 = formDetailsList[0].eTicketsDetails.eTicket2
			submitDraftObj.eTicket3 = formDetailsList[0].eTicketsDetails.eTicket3
			submitDraftObj.eTicket4 = formDetailsList[0].eTicketsDetails.eTicket4
		} else {
			submitDraftObj.eTicket1 = currentPaxFormDetails.eTicketsDetails.eTicket1
			submitDraftObj.eTicket2 = currentPaxFormDetails.eTicketsDetails.eTicket2
			submitDraftObj.eTicket3 = currentPaxFormDetails.eTicketsDetails.eTicket3
			submitDraftObj.eTicket4 = currentPaxFormDetails.eTicketsDetails.eTicket4
		}

		const draftRs = await axios.post(baseURL + '/Draft/AddDraft', submitDraftObj, getCurrentAuthHeader()).catch((e) => {
			if (!checkValidStatusCode(e.response.status)) {
				this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Draft save failed' })
			}
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Draft save failed' })
		})
		if (draftRs) {
			this.setState({ showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'Draft saved' })
		}
	}

	_onEditPax = (p) => {
		if (p.isDeleted) return
		this.props.copyFormDetailsToCurrentPax(cloneDeep(p))
	}

	onEditCancel = () => {
		this.props.cancelEditingPax()
	}

	onUpdate = async () => {
		const { deletedOriginalAttachmentIdx } = this.state

		if (deletedOriginalAttachmentIdx.size > 0) {
			return this.setState({ showDeleteDialog: true, afterDeleteConfirm: 'onUpdate' })
		} else {
			const { files } = this.state
			if (files.length > 0) {
				const rs = await this.uploadFiles()
				if (rs.success) {
					this.props.commitFormDetailsIntoList()
					this.setState({ files: [], deletedOriginalAttachmentIdx: new Set() })
				} else {
					this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Files upload failed' })
				}
			} else {
				this.calNoSCurrentPax(false)
				let validation = validateCurrentPax({
					currentPaxFormDetails: this.props.currentPaxFormDetails,
					formDetailsList: this.props.formDetailsList,
					campaignPeriods: this.props.campaigns,
					files: this.state.files,
				})
				if (!validation.success) {
					this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: validation.message })
					return
				}
				this.props.commitFormDetailsIntoList()
			}
		}

		this.individualETicket.resetAddETicketButton()
	}

	calNoSCurrentPax(isAdding) {
		// set number of sector for first pax
		if (this.props.formDetailsList.length === 0) {
			// other accepted airline
			if (this.props.currentPaxFormDetails.personalDetails.numberOfSector
				&& this.props.currentPaxFormDetails.personalDetails.numberOfSector == 0) {
				this.props.currentPaxFormDetails.numberOfSector = this.props.currentPaxFormDetails.sector3Details.flightNo ? 4 : 2
			}
			// more than one eticket
			else if (this.props.currentPaxFormDetails.ticketDetails && this.props.currentPaxFormDetails.ticketDetails.length > 1) {
				this.props.currentPaxFormDetails.numberOfSector = this.props.currentPaxFormDetails.sector3Details.flightNo ? 4 : 2
			}
			// sq eticket
			else {
				this.props.currentPaxFormDetails.numberOfSector = this.props.currentPaxFormDetails.personalDetails.numberOfSector
			}
			this.props.currentPaxFormDetails.isFirstPax = true
		} else {
			// other accepted airline & more than 1 eTicket
			if (this.props.currentPaxFormDetails.personalDetails.numberOfSector == 0
				|| this.props.currentPaxFormDetails.ticketDetails.length > 1) {
				this.props.currentPaxFormDetails.numberOfSector = this.props.formDetailsList[0].numberOfSector
			}
			// sq eticket 
			else {
				this.props.currentPaxFormDetails.numberOfSector = this.props.currentPaxFormDetails.personalDetails.numberOfSector
			}
			if (isAdding) {
				this.props.currentPaxFormDetails.isFirstPax = false
			}
		}
	}

	getFormDetails = (k) => {
		const { currentPaxFormDetails } = this.props
		return currentPaxFormDetails[k]
	}

	onRemoveCircleClick = (f, i) => {
		const files = [...this.state.files]
		files.splice(i, 1)
		this.setState({ files })
	}

	onDrop = (acceptedFiles) => {
		const files = [...this.state.files]
		for (const af of acceptedFiles) {
			let exists = false
			for (const f of files) {
				if (af.name === f.name) exists = true
			}
			if (!exists) files.push(af)
		}
		this.setState({ files })
	}

	onDeleteOrigAttachment = (i) => {
		this.setState({ deletedOriginalAttachmentIdx: this.state.deletedOriginalAttachmentIdx.add(i) })
	}

	onUndoDeleteOrigAttachment = (i) => {
		let newDeletedOriginalAttachmentIdx = new Set(this.state.deletedOriginalAttachmentIdx)
		newDeletedOriginalAttachmentIdx.delete(i)
		this.setState({ deletedOriginalAttachmentIdx: newDeletedOriginalAttachmentIdx })
	}

	onDeleteConfirm = async () => {
		const newCurrentPaxFormDetails = cloneDeep(this.props.currentPaxFormDetails)
		let newAttachmentUris = newCurrentPaxFormDetails.sector2Details.attachmentUris
		let newAttachmentUrisSector3 = newCurrentPaxFormDetails.sector3Details.attachmentUris
		this.state.deletedOriginalAttachmentIdx.forEach((i) => {
			newAttachmentUris.splice(i, 1)
			newAttachmentUrisSector3.splice(i, 1)
		})
		newCurrentPaxFormDetails.sector2Details.attachmentUris = newAttachmentUris
		newCurrentPaxFormDetails.sector3Details.attachmentUris = newAttachmentUrisSector3
		await this.props.updateCurrentPaxFormDetails(newCurrentPaxFormDetails)

		const { files, afterDeleteConfirm } = this.state
		if (files.length > 0) {
			const rs = await this.uploadFiles()
			if (rs.success) {
				// this.props.commitFormDetailsIntoList()
				this.setState({ files: [], deletedOriginalAttachmentIdx: new Set(), showDeleteDialog: false, afterDeleteConfirm: '' })
			} else {
				this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Files upload failed', showDeleteDialog: false })
			}
		} else {
			// this.props.commitFormDetailsIntoList()
			this.setState({ deletedOriginalAttachmentIdx: new Set(), showDeleteDialog: false, afterDeleteConfirm: '' })
		}

		let validateCurrentPaxResult = validateCurrentPax({
			currentPaxFormDetails: this.props.currentPaxFormDetails,
			formDetailsList: this.props.formDetailsList,
			campaignPeriods: this.props.campaigns,
			files: this.state.files,
		})
		if (!validateCurrentPaxResult.success) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: validateCurrentPaxResult.message })
			return
		}
		switch (afterDeleteConfirm) {
			case '_commitCurrentPax':
				this._commitCurrentPax()
				break
			case 'onUpdate':
				this.onUpdate()
				break
			case 'onSaveDraft':
				this.onSaveDraft()
				break
		}
	}

	onDeleteCancel = () => {
		this.setState({ showDeleteDialog: false, afterDeleteConfirm: '', readyToProceedAfterPaxCommitted: false })
	}

	onAttachmentClick = async (attachmentUri) => {
		const data = { objectKey: [attachmentUri], bucket: attachmentBucket }
		const rs = await myaxios('post', '/File/GeneratePresignedUris', data, getCurrentAuthHeader())
		if (rs.success) {
			window.open(rs.data.result[0])
		}
	}

	render() {
		const { classes, editMode, currentPaxFormDetails, getAllCampaignsIsLoading, createRedemptionTransactionIsLoading, uploadFileIsLoading } = this.props
		const { deletedOriginalAttachmentIdx, showDeleteDialog } = this.state
		const isLoading = this.props.individualFilesUploadIsLoading || getAllCampaignsIsLoading || createRedemptionTransactionIsLoading || uploadFileIsLoading
		return (
			<div className={classes.container}>
				{currentPaxFormDetails['sector2Details'] && currentPaxFormDetails['sector2Details'].attachmentUris.length > 0 && (
					<DeleteDialog dialogTitle={'Warning'} showDeleteDialog={showDeleteDialog} onDeleteConfirm={this.onDeleteConfirm} onDeleteCancel={this.onDeleteCancel}>
						The following attachments will be removed:
						<ul>
							{currentPaxFormDetails['sector2Details'].attachmentUris.map((a, i) => {
								if (deletedOriginalAttachmentIdx.has(i)) {
									return <li key={'deleting' + i}>{a}</li>
								}
							})}
						</ul>
					</DeleteDialog>
				)}
				{currentPaxFormDetails['sector3Details'] && currentPaxFormDetails['sector3Details'].attachmentUris.length > 0 && (
					<DeleteDialog dialogTitle={'Warning'} showDeleteDialog={showDeleteDialog} onDeleteConfirm={this.onDeleteConfirm} onDeleteCancel={this.onDeleteCancel}>
						The following attachments will be removed:
						<ul>
							{currentPaxFormDetails['sector3Details'].attachmentUris.map((a, i) => {
								if (deletedOriginalAttachmentIdx.has(i)) {
									return <li key={'deleting' + i}>{a}</li>
								}
							})}
						</ul>
					</DeleteDialog>
				)}

				<PromiseModalWrapper
					ref={this.promiseModalRef}
					proceedMessage={this.state.promiseModalProceedMessage}
					cancelMessage={this.state.promiseModalCancelMessage}
					content={this.state.promiseModalContent}
				/>

				{this.props.redemptionTransaction.refNo && <Typography variant={'h6'}>{'Reference No: ' + this.props.redemptionTransaction.refNo}</Typography>}

				{this.props.formDetailsList.length > 0 && (
					<StyledSection title={'Passengers'} paperClass={editMode ? classes.editMode : ''}>
						<Grid container spacing={24} direction={'column'}>
							<Grid item>
								<ParticularsTable rows={this.props.formDetailsList} onDelete={this._onDelete} onRestore={this._onRestore} onEditPax={this._onEditPax} />
							</Grid>
							{!editMode && (
								<Grid item>
									<Button color={'primary'} variant='contained' onClick={() => this.onClickNext()}>
										Next
									</Button>
								</Grid>
							)}
						</Grid>
						{this.props.formDetailsList.length === 0 && <StyledPlaceholder message={'Add passengers to continue'} />}
					</StyledSection>
				)}

				<IndividualETicketForm innerRef={(instance) => (this.individualETicket = instance)} editMode={editMode} editModeClass={classes.editMode} />

				<StyledSection title={'Pax Details'} isLoading={isLoading} paperClass={editMode ? classes.editMode : ''}>
					<Grid container spacing={24} direction={'column'}>
						<Grid item>
							<div className={classes.row}>
								<div className={classes.rowItem}>
									<div className={classes.label}>First/Given Name*</div>
									<div className={classes.control}>
										<TextField
											id=''
											className={classes.textfield}
											label=''
											variant='outlined'
											value={currentPaxFormDetails['personalDetails']['firstName']}
											onChange={(e) => this.handlePersonalDetailsChange('firstName', e.target.value)}
										/>
									</div>
								</div>
								<div className={classes.rowItem}>
									<div className={classes.label}>Last/Surname*</div>
									<div className={classes.control}>
										<TextField
											id=''
											className={classes.textfield}
											label=''
											variant='outlined'
											value={currentPaxFormDetails['personalDetails']['lastName']}
											onChange={(e) => this.handlePersonalDetailsChange('lastName', e.target.value)}
										/>
									</div>
								</div>
							</div>

							<div className={classes.row}>
								<div className={classes.rowItem}>
									<div className={classes.label}>Date of birth*</div>
									<div className={classes.control}>
										<GeneralTextDate
											id=''
											className={classes.textfield}
											label=''
											variant='outlined'
											value={currentPaxFormDetails['personalDetails']['dob']}
											//value="2018-01-01"
											onChange={(e) => this.handlePersonalDetailsChange('dob', e.target.value)}
										/>
									</div>
								</div>
								<div className={classes.rowItem}>
									<div className={classes.label}>Remarks</div>
									<div className={classes.control}>
										<TextField
											id=''
											className={classes.textfield}
											label=''
											variant='outlined'
											value={currentPaxFormDetails['personalDetails']['remarks']}
											onChange={(e) => this.handlePersonalDetailsChange('remarks', e.target.value)}
										/>
									</div>
								</div>
							</div>
						</Grid>

						<Grid item>
							<Divider />
						</Grid>

						{this.flightNoOptions !== null && (
							<Grid container item spacing={24} direction={'row'}>
								<Grid item md={6}>
									<Typography variant={'h5'}>Sector 1</Typography>
									<GeneralFormColumnsContainer
										columns={1}
										formFields={this.state.sector1FormFields}
										// formValues={currentPaxFormDetails['sector1Details']}
										formValues={this.getFormDetails('sector1Details')}
										onFieldChange={this.handleSector1DetailsChange}
									/>
								</Grid>
								<Grid item md={6}>
									<Typography variant={'h5'}>Sector 2</Typography>
									<GeneralFormColumnsContainer
										columns={1}
										formFields={this.state.sector2FormFields}
										// formValues={currentPaxFormDetails['sector2Details']}
										formValues={this.getFormDetails('sector2Details')}
										onFieldChange={this.handleSector2DetailsChange}
									/>

									<Spacing height={40} />

									{currentPaxFormDetails['sector2Details'].landSeaScootBool !== 'na' && (
										<Fragment>
											<Grid container item spacing={24} alignItems={'center'}>
												<Grid item md={5}>
													Land/Sea/Scoot booking ref
												</Grid>
												<Grid item>
													<GeneralDropzone onDrop={this.onDrop} />
												</Grid>
											</Grid>
											<Grid container item spacing={24} alignItems={'center'}>
												<Grid item md={5} />
												<Grid item>
													<UploadFilesList files={this.state.files} onRemoveCircleClick={this.onRemoveCircleClick} />
												</Grid>
											</Grid>
											{currentPaxFormDetails['sector2Details'].attachmentUris.length > 0 && (
												<Paper>
													<List>
														{currentPaxFormDetails['sector2Details'].attachmentUris.map((a, i) => {
															return (
																<ListItem key={'attachment' + i} className={deletedOriginalAttachmentIdx.has(i) ? classes.markedDelete : ''}>
																	<ListItemText classes={{ root: classes.listItemText }}>{i + 1}.</ListItemText>
																	<ListItemText
																		classes={{ root: classes.listItemText }}
																		onClick={() => this.onAttachmentClick(currentPaxFormDetails['sector2Details'].attachmentUris[i])}
																		className={classes.pointer}
																	>
																		{currentPaxFormDetails['sector2Details'].attachmentUris[i]}
																	</ListItemText>
																	<ListItemSecondaryAction>
																		{!deletedOriginalAttachmentIdx.has(i) && (
																			<StyledDeleteBtn onClick={() => this.onDeleteOrigAttachment(i)} />
																		)}
																		{deletedOriginalAttachmentIdx.has(i) && (
																			<StyledUndoBtn onClick={() => this.onUndoDeleteOrigAttachment(i)} />
																		)}
																	</ListItemSecondaryAction>
																</ListItem>
															)
														})}
													</List>
												</Paper>
											)}
										</Fragment>
									)}
								</Grid>
								{/* =======================================Sector 3 & 4============================================================ */}

								<Grid item md={6}>
									<Typography variant={'h5'}>Sector 3</Typography>
									<GeneralFormColumnsContainer
										columns={1}
										formFields={this.state.sector3FormFields}
										formValues={this.getFormDetails('sector3Details')}
										onFieldChange={this.handleSector3DetailsChange}
									/>

									<Spacing height={40} />

									{currentPaxFormDetails['sector3Details'] && currentPaxFormDetails['sector3Details'].landSeaScootBool !== 'na' && (
										<Fragment>
											<Grid container item spacing={24} alignItems={'center'}>
												<Grid item md={5}>
													Land/Sea/Scoot booking ref
												</Grid>
												<Grid item>
													<GeneralDropzone onDrop={this.onDrop} />
												</Grid>
											</Grid>
											<Grid container item spacing={24} alignItems={'center'}>
												<Grid item md={5} />
												<Grid item>
													<UploadFilesList files={this.state.files} onRemoveCircleClick={this.onRemoveCircleClick} />
												</Grid>
											</Grid>
											{currentPaxFormDetails['sector3Details'].attachmentUris.length > 0 && (
												<Paper>
													<List>
														{currentPaxFormDetails['sector3Details'].attachmentUris.map((a, i) => {
															return (
																<ListItem key={'attachment' + i} className={deletedOriginalAttachmentIdx.has(i) ? classes.markedDelete : ''}>
																	<ListItemText classes={{ root: classes.listItemText }}>{i + 1}.</ListItemText>
																	<ListItemText
																		classes={{ root: classes.listItemText }}
																		onClick={() => this.onAttachmentClick(currentPaxFormDetails['sector3Details'].attachmentUris[i])}
																		className={classes.pointer}
																	>
																		{currentPaxFormDetails['sector3Details'].attachmentUris[i]}
																	</ListItemText>
																	<ListItemSecondaryAction>
																		{!deletedOriginalAttachmentIdx.has(i) && (
																			<StyledDeleteBtn onClick={() => this.onDeleteOrigAttachment(i)} />
																		)}
																		{deletedOriginalAttachmentIdx.has(i) && (
																			<StyledUndoBtn onClick={() => this.onUndoDeleteOrigAttachment(i)} />
																		)}
																	</ListItemSecondaryAction>
																</ListItem>
															)
														})}
													</List>
												</Paper>
											)}
										</Fragment>
									)}
								</Grid>
								<Grid item md={6}>
									<Typography variant={'h5'}>Sector 4</Typography>
									<GeneralFormColumnsContainer
										columns={1}
										formFields={this.state.sector4FormFields}
										formValues={this.getFormDetails('sector4Details')}
										onFieldChange={this.handleSector4DetailsChange}
									/>
								</Grid>
							</Grid>
						)}

						{editMode === false && (
							<Grid container item spacing={24} direction={'row'}>
								<Grid item xs={6}>
									<Button color={'primary'} variant='text' onClick={this.onClear}>
										Clear
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Grid container spacing={24} justify={'flex-end'} alignItems={'center'}>
										<Grid item>
											<Button color={'primary'} variant='contained' onClick={() => this.addPax()}>
												Add Pax
											</Button>
										</Grid>
										<Grid item>
											<Button color={'default'} variant='contained' onClick={this.onSaveDraft}>
												Save Draft
											</Button>
										</Grid>
										<Grid item>
											<Button color={'primary'} variant='contained' onClick={() => this.onClickNext()}>
												{' '}
												Next
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)}

						{editMode === true && (
							<Grid container item spacing={24}>
								<Grid item>
									<Button color={'primary'} variant='text' onClick={this.onEditCancel}>
										Cancel
									</Button>
								</Grid>
								<Grid item>
									<Button color={'primary'} variant='contained' onClick={() => this.onUpdate()}>
										Update
									</Button>
								</Grid>
							</Grid>
						)}
					</Grid>
				</StyledSection>

				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
	row: { display: 'flex', marginTop: 20 },
	rowItem: { display: 'flex', flex: 1, marginRight: 60, alignItems: 'center' },
	label: { flex: '2' },
	control: { flex: '3' },
	textfield: { width: '100%' },
	textfieldError: { height: 60, width: '100%' },
	tfWrap: { position: 'relative' },
	// editMode: {borderStyle: 'dashed', borderWidth: '2px', backgroundColor: blue[50]}
	editMode: {},
	listItemText: { flex: 'none' },
	pointer: { cursor: 'pointer' },
	markedDelete: { ...theme.deletedBackground },
})

const mapStateToProps = function(state) {
	const stateClone = { ...state }
	const { flightDuck, individualFormDuck, campaignPeriodDuck, redemptionTransactionDuck } = stateClone
	const {
		flightData,
		arrivalFlightNoList,
		departureFlightNoList,
		departureCityList,
		arrivalCityList,
		isFlightPopulated,
		arrivalSearchOptions,
		departureSearchOptions,
	} = flightDuck
	const { campaigns, getAllCampaignsIsLoading } = campaignPeriodDuck
	const { individualFilesUploadIsLoading, uploadFileIsLoading, replacingFromDraft } = individualFormDuck
	const { redemptionTransaction, createRedemptionTransactionIsLoading } = redemptionTransactionDuck
	return {
		individualFormDuck,
		currentPaxFormDetails: individualFormDuck.currentPaxFormDetails,
		currentPaxPosition: individualFormDuck.currentPaxPosition,
		formDetailsList: individualFormDuck.formDetailsList,
		arrivalFlightNoList: arrivalFlightNoList,
		arrivalSearchOptions: arrivalSearchOptions,
		departureSearchOptions: departureSearchOptions,
		departureFlightNoList: departureFlightNoList,
		departureCityList,
		arrivalCityList,
		flightData,
		isFlightPopulated: isFlightPopulated,
		campaigns,
		getAllCampaignsIsLoading,
		individualFilesUploadIsLoading,
		uploadFileIsLoading,
		replacingFromDraft,
		redemptionTransaction,
		createRedemptionTransactionIsLoading,
		editMode: individualFormDuck.editMode,
	}
}

const mapDispatchToProps = function(dispatch) {
	return {
		updateCurrentPaxFormDetails: (details) => dispatch(updateCurrentPaxFormDetails(details)),
		updateCollectorPersonalDetails: (flightData) => dispatch(updateCollectorPersonalDetails(flightData)),
		clearPaxDetails: () => dispatch(clearPaxDetails()),
		commitFormDetailsIntoList: () => dispatch(commitFormDetailsIntoList()),
		updateCurrentPaxCtpValue: (value, editMode) => dispatch(updateCurrentPaxCtpValue(value, editMode)),
		deletePax: (pax) => dispatch(deletePax(pax)),
		restorePax: (pax) => dispatch(restorePax(pax)),
		getAllCampaigns: () => dispatch(getAllCampaigns()),
		uploadFile: (formData, type) => dispatch(uploadFile(formData, type)),
		createEmptyRedemptionTransaction: () => dispatch(createEmptyRedemptionTransaction()),
		updateEntireIndividualForm: (individualForm) => dispatch(updateEntireIndividualForm(individualForm)),
		updateRedemptionTransaction: (redemptionTransaction) => dispatch(updateRedemptionTransaction(redemptionTransaction)),
		copyFormDetailsToCurrentPax: (p) => dispatch(copyFormDetailsToCurrentPax(p)),
		cancelEditingPax: () => dispatch(cancelEditingPax()),
		individualFilesUpload: (formData) => dispatch(individualFilesUpload(formData)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(IndividualFlightForm))
