export const GROUP_TYPE_PENDING_SQ_API = "GROUP_TYPE_PENDING_SQ_API";
export const GROUP_TYPE_ACTION_REQUIRED = "GROUP_TYPE_ACTION_REQUIRED";
export const GROUP_TYPE_PENDING_APPROVAL = "GROUP_TYPE_PENDING_APPROVAL";
export const GROUP_TYPE_PENDING_COLLECTION = "GROUP_TYPE_PENDING_COLLECTION";
export const GROUP_TYPE_REJECTED = "GROUP_TYPE_REJECTED";
export const GROUP_TYPE_COLLECTED = "GROUP_TYPE_COLLECTED";
export const GROUP_TYPE_NOT_COLLECTED = "GROUP_TYPE_NOT_COLLECTED";
export const GROUP_TYPE_DELETED = "GROUP_TYPE_DELETED";
export const GROUP_TYPE_VIEW_ALL = "GROUP_TYPE_VIEW_ALL";
export const GROUP_TYPE_APPROVED = "GROUP_TYPE_APPROVED";
export const GROUP_TYPE_APPROVED_TO_UNAPPROVED = "GROUP_TYPE_APPROVED_TO_UNAPPROVED";

export const GROUP_ACTION_TYPE_SUBMITTING = "GROUP_ACTION_TYPE_SUBMITTING";
export const GROUP_ACTION_TYPE_APPROVING = "GROUP_ACTION_TYPE_APPROVING";
export const GROUP_ACTION_TYPE_COLLECTING = "GROUP_ACTION_TYPE_COLLECTING";
export const GROUP_ACTION_TYPE_UNAPPROVING = "GROUP_ACTION_TYPE_UNAPPROVING";