import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Grid, Button, Fab, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Header from '../containers/Header.js';
import { TITLE_PREFIX } from '../constants/titlePrefix';
import { HubConnectionBuilder } from '@aspnet/signalr';
import { signalRURL } from '../config/config';
import signalRConstants from '../helpers/signalRConstants';
import StyledSnackbar from '../components/StyledSnackbar';
import GlobalTimeChecker from '../containers/GlobalTimeChecker';
import GroupCollectorForm from '../containers/group/GroupCollectorForm';
import {
    createGroupTransactionAndAssignDigitalCards,
    groupIssueCards,
    updateGroupCollectorDetails,
    groupCollectorSignatureUpload,
    multipleActivateAndIssueCard
} from '../ducks/groupRedemptionFormDuck';
import { ScreenShare } from '@material-ui/icons';
import StyledSection from '../components/StyledSection';
import { dataURItoBlob } from '../helpers/utilities';
import GeneralSignature from '../components/generalForm/GeneralSignature';
import LinearIndeterminate from '../components/LinearIndeterminate';

class GroupCollector extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            formState: {},
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            parentDidSubmit: false,
            clientIsDone: false,
            isSharingScreen: false,
            isSubmitted: false,
            promiseModalProceedMessage: '',
            promiseModalCancelMessage: '',
            promiseModalContent: '',
            submitType: 'TOUR_LEADER'
        };

        this.collectorPersonalDetails = null;
    }

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Group Collector`;

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(signalRURL)
            .build();
        this._startSignalRConnection();
        this.hubConnection.on('ReceiveMessage', this._receiveMessage);
        this.hubConnection.onclose(async () => {
            await this._startSignalRConnection();
        });

        this.props.history.listen(location => {
            const { isSubmitted } = this.state;
            if (
                isSubmitted &&
                location.pathname.indexOf('/group/group-issuance') !== -1
            ) {
                window.location = '/dashboard';
            }
        });
    }

    _startSignalRConnection = async () => {
        this.hubConnection
            .start()
            .then(() => console.log('Connection started!'))
            .catch(err => {
                setTimeout(() => this._startSignalRConnection(), 5000);
                console.log('Error while establishing connection :(');
            });
    };

    _sendMessage = (data, messageType) => {
        const user = localStorage.getItem('username');
        let messageObj = {
            user,
            type: messageType,
            source: signalRConstants.USER_CONCIERGE,
            data: data
        };

        let messageStr = JSON.stringify(messageObj);
        this.hubConnection
            .invoke('SendMessage', 'default', messageStr)
            .catch(function(err) {
                return console.error(err.toString());
            });
    };

    _receiveMessage = (user, messageStr) => {
        let messageObj = JSON.parse(messageStr);

        if (messageObj.source === signalRConstants.USER_CONCIERGE) return;
        const currentUser = localStorage.getItem('username');
        if (messageObj.user !== currentUser) return;

        // if (messageObj.data) {
        //     const {collectorDetails, formState, formDetailsList} = messageObj.data
        //     this.props.updateCollectorDetails(collectorDetails)
        //     if (formState) this.setState({formState})
        //     if (formDetailsList) this.props.updateFormDetailsList(formDetailsList)
        //     if (messageObj.type === signalRConstants.CLIENT_DONE) {
        //         this.setState({clientIsDone: true, formState})
        //     }
        // }

        if (messageObj.type === signalRConstants.UPDATE_GROUP_COLLECTOR_FORM) {
            const { collector } = messageObj.data;
            this.props.updateGroupCollectorDetails(collector);
        }
    };

    _toolbarButtons = () => {
        return [
            // <Fab color={'primary'} variant='round' onClick={this.onPrint}>
            // 	<Print />
            // </Fab>,
            <Fab color={'primary'} variant='round' onClick={this.onScreenShare}>
                <ScreenShare />
            </Fab>
        ];
    };

    onPrint = () => {
        window.print();
    };

    closeSnackbar = () => {
        this.setState({ showSnackbar: false });
    };

    _createGroupTransactionAndAssignDigitalCards = async () => {
        let { groupRedemptionFormDuck } = this.props;
        let response = await this.props.createGroupTransactionAndAssignDigitalCards(
            {
                tourLeader: groupRedemptionFormDuck.tourLeader,
                group: groupRedemptionFormDuck.group,
                passengers: groupRedemptionFormDuck.passengers,
                signatureUri: groupRedemptionFormDuck.signatureUri,
                collector: groupRedemptionFormDuck.collector,
                cardAssignment: groupRedemptionFormDuck.cardAssignment
            }
        );
        if (!response.success) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: response.message
            });
            return false;
        } else {
            return true;
        }
    };

    _multipleActivateAndIssueCards = async () => {
        let passengersWithCard = this.props.groupRedemptionFormDuck.passengers.filter(
            x => !!x.cardNo
        );

        let issueCardResponse = await this.props.multipleActivateAndIssueCard(
            passengersWithCard
        );
        if (!issueCardResponse.success) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: issueCardResponse.message
            });
            return null;
        } else {
            return issueCardResponse;
        }
    };

    _groupIssueCards = async cardActivationResponse => {
        let { groupRedemptionFormDuck } = this.props;
        let response = await this.props.groupIssueCards({
            tourLeader: groupRedemptionFormDuck.tourLeader,
            group: groupRedemptionFormDuck.group,
            passengers: groupRedemptionFormDuck.passengers,
            signatureUri: groupRedemptionFormDuck.signatureUri,
            collector: groupRedemptionFormDuck.collector,
            cardAssignment: groupRedemptionFormDuck.cardAssignment,
            redemptionTransactionId:
                groupRedemptionFormDuck.redemptionTransactionId,
            cardActivationResponse: cardActivationResponse
        });
        if (!response.success) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: response.message
            });
            return false;
        } else {
            return true;
        }
    };

    onSubmit = async () => {
        const {
            collector,
            cardAssignment
        } = this.props.groupRedemptionFormDuck;

        if (
            collector.csoSignatureBase64 === '' &&
            collector.clientSignatureBase64 === '' &&
            this.state.submitType === 'TOUR_LEADER'
        ) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: 'Signature is required'
            });
            return;
        }

        if (this.state.submitType === 'TOUR_LEADER') {
            const blob = dataURItoBlob(
                collector.csoSignatureBase64
                    ? collector.csoSignatureBase64
                    : collector.clientSignatureBase64
            );
            const formData = new FormData();
            formData.append('file', blob);
            // api call: upload signature
            let rs = await this.props.groupCollectorSignatureUpload(formData);
            if (!rs.success) {
                this.setState({
                    showSnackbar: true,
                    snackbarMessage: rs.message,
                    snackbarVariant: 'error'
                });
                return false;
            }
        }

        // api call: get redemption transaction and digital cards if applicable
        let createTransactionAndDigitalCardsSuccess = await this._createGroupTransactionAndAssignDigitalCards();
        if (!createTransactionAndDigitalCardsSuccess) return;
        let cardActivationResponse = null;
        // api call: Card Activation
        // if (
        //     cardAssignment === MULTI_CARD_DIGITAL ||
        //     cardAssignment === MULTI_CARD_UNLOADED
        // ) {
        //     cardActivationResponse = await this._multipleActivateAndIssueCards();
        //     if (!cardActivationResponse) return;
        // }

        // api call: final submission
        let response = await this._groupIssueCards(cardActivationResponse);
        if (response) this.props.history.push('/group-collector-success');

        //todo:syd remove as refactor to its own method
        /*
		let passengersWithCard = this.props.groupRedemptionFormDuck.passengers.filter(x => !!x.cardNo)
		if (passengersWithCard) {
			let issueCardResponse = await this.props.multipleActivateAndIssueCard(passengersWithCard)
			if (!issueCardResponse.success) {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: issueCardResponse.message
				})
			} else {
				preIssuanceCheck = true
			}
		} else {
			//physicalVoucher don't have to check
			preIssuanceCheck = true
		}
		*/

        //todo:syd remove as refactor to use sub-method
        /*
		let response = await this.props.groupIssueCards({
			tourLeader: groupRedemptionFormDuck.tourLeader,
			group: groupRedemptionFormDuck.group,
			passengers: groupRedemptionFormDuck.passengers,
			signatureUri: groupRedemptionFormDuck.signatureUri,
			collector: groupRedemptionFormDuck.collector,
			cardAssignment: groupRedemptionFormDuck.cardAssignment
		})
		response.success
			? this.props.history.push('/group-collector-success')
			: this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: response.message })
		*/
    };

    onScreenShare = () => {
        let formattedPassengers = [];
        this.props.groupRedemptionFormDuck.passengers.forEach(passenger => {
            let formattedPassenger = {
                eTicket1: passenger.eTicket1,
                eTicket2: passenger.eTicket2,
                eTicket3: passenger.eTicket3,
                eTicket4: passenger.eTicket4,
                firstName: passenger.firstName,
                lastName: passenger.lastName,
                cardNo: passenger.cardNo,
                genPhysicalVoucherNo: passenger.genPhysicalVoucherNo,
                lpcPhysicalVoucherNo: passenger.lpcPhysicalVoucherNo,
                id: passenger.id,
                collecting: passenger.collecting,
                ctpVoucherValue: passenger.ctpVoucherValue,
                ctpGenValue: passenger.ctpGenValue,
                ctpLpcValue: passenger.ctpLpcValue
            };
            formattedPassengers.push(formattedPassenger);
        });
        this._sendMessage(
            {
                group: this.props.groupRedemptionFormDuck.group,
                passengers: formattedPassengers,
                tourLeader: this.props.groupRedemptionFormDuck.tourLeader,
                collector: this.props.groupRedemptionFormDuck.collector,
                signatureUri: this.props.groupRedemptionFormDuck.signatureUri,
                submitType: this.state.submitType
            },
            signalRConstants.DISPLAY_GROUP_COLLECTOR_FORM
        );
        this.setState({ isSharingScreen: true });
    };

    onBack = () => {
        const {
            groupRedemptionFormDuck: {
                group: { refNo }
            }
        } = this.props;
        const { isSubmitted } = this.state;
        isSubmitted
            ? (window.location = '/dashboard')
            : this.props.history.push('/group/group-issuance/' + refNo);
    };

    onSignatureEnd = async csoSignatureBase64 => {
        let {
            groupRedemptionFormDuck: { collector }
        } = this.props;
        collector = { ...collector, csoSignatureBase64 };
        await this.props.updateGroupCollectorDetails(collector);
    };

    onSignatureClear = async () => {
        let {
            groupRedemptionFormDuck: { collector }
        } = this.props;
        collector = { ...collector, csoSignatureBase64: '' };
        await this.props.updateGroupCollectorDetails(collector);
    };

    onSubmitTypeChange = e => {
        this.setState({ submitType: e.target.value });
    };

    render() {
        const {
            classes,
            groupRedemptionFormDuck: {
                collector,
                groupCollectorSignatureUploadIsLoading,
                groupIssueCardIsLoading,
                multipleActivateAndIssueCardIsLoading
            }
        } = this.props;
        const { isSharingScreen, clientIsDone, isSubmitted } = this.state;
        const isLoading =
            groupCollectorSignatureUploadIsLoading ||
            groupIssueCardIsLoading ||
            multipleActivateAndIssueCardIsLoading;

        return (
            <div>
                <GlobalTimeChecker />

                <Header history={this.props.history} />

                <div className={classes.container}>
                    <StyledSection
                        title={'Group Collections'}
                        toolbarButtons={this._toolbarButtons}
                        isLoading={isLoading}
                        GroupSubmitted={isSubmitted}
                    >
                        <GroupCollectorForm />

                        <Grid item>
                            <RadioGroup
                                row
                                value={this.state.submitType}
                                onChange={this.onSubmitTypeChange}
                            >
                                <FormControlLabel
                                    value={'TOUR_LEADER'}
                                    control={<Radio color='primary' />}
                                    label='Tour Leader Acknowledgement'
                                />
                                <FormControlLabel
                                    value={'CSO'}
                                    control={<Radio color='primary' />}
                                    label='CSO Acknowledgement'
                                />
                            </RadioGroup>
                        </Grid>

                        {!isSharingScreen && !clientIsDone && this.state.submitType === 'TOUR_LEADER' && (
                            <Grid container item spacing={24}>
                                <Grid item>
                                    <GeneralSignature
                                        penColor={'black'}
                                        dimensions={{ width: 500, height: 200 }}
                                        onEnd={this.onSignatureEnd}
                                        onClear={this.onSignatureClear}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        <Grid container item spacing={24} direction={'column'}>
                            {collector.clientSignatureBase64 && (
                                <Grid
                                    container
                                    item
                                    spacing={24}
                                    direction={'column'}
                                >
                                    <Grid item>
                                        <Typography variant={'body1'}>
                                            Collector's Signature:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <img
                                            src={
                                                collector.clientSignatureBase64
                                            }
                                            alt=''
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            <Grid
                                container
                                item
                                spacing={24}
                                alignItems={'center'}
                            >
                                <Grid item>
                                    <Button
                                        color={'primary'}
                                        onClick={this.onBack}
                                    >
                                        {isSubmitted
                                            ? 'Back to dashboard'
                                            : 'Back to issuance'}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color={'primary'}
                                        variant='contained'
                                        disabled={isSubmitted}
                                        onClick={this.onSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                {isSharingScreen && (
                                    <Grid item>
                                        {!collector.clientIsDone && (
                                            <Typography
                                                variant={'body1'}
                                                className={classes.waiting}
                                            >
                                                Waiting for client to
                                                confirm....
                                            </Typography>
                                        )}
                                        {collector.clientIsDone && (
                                            <Typography
                                                variant={'body1'}
                                                className={classes.waiting}
                                            >
                                                Client is done
                                            </Typography>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </StyledSection>
                </div>
                {this.state.multipleActivateAndIssueCardIsLoading && (
                    <LinearIndeterminate />
                )}
                <StyledSnackbar
                    open={this.state.showSnackbar}
                    onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage}
                    variant={this.state.snackbarVariant}
                />
            </div>
        );
    }
}

const styles = theme => ({
    container: { ...theme.container },
    waiting: { color: green[600], fontSize: '.8rem' }
});

const mapStateToProps = state => {
    const { groupRedemptionFormDuck } = state;

    return { groupRedemptionFormDuck };
};

const mapDispatchToProps = dispatch => {
    return {
        groupIssueCards: ({
            tourLeader,
            collector,
            group,
            passengers,
            signatureUri,
            cardAssignment,
            redemptionTransactionId,
            cardActivationResponse
        }) =>
            dispatch(
                groupIssueCards({
                    tourLeader,
                    collector,
                    group,
                    passengers,
                    signatureUri,
                    cardAssignment,
                    redemptionTransactionId,
                    cardActivationResponse
                })
            ),
        updateGroupCollectorDetails: collector =>
            dispatch(updateGroupCollectorDetails(collector)),
        groupCollectorSignatureUpload: (formData, type) =>
            dispatch(groupCollectorSignatureUpload(formData, type)),
        multipleActivateAndIssueCard: cards =>
            dispatch(multipleActivateAndIssueCard(cards)),
        createGroupTransactionAndAssignDigitalCards: ({
            tourLeader,
            collector,
            group,
            passengers,
            signatureUri,
            cardAssignment
        }) =>
            dispatch(
                createGroupTransactionAndAssignDigitalCards({
                    tourLeader,
                    collector,
                    group,
                    passengers,
                    signatureUri,
                    cardAssignment
                })
            )
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(GroupCollector));
