import React, {PureComponent} from 'react'
import classNames from 'classnames'
import {withStyles, Fab} from '@material-ui/core'
import {Error} from '@material-ui/icons'

class StyledTinyFabError extends PureComponent {
    render() {
        const {classes, yellow, customClass} = this.props

        return (
            <Fab className={classNames(classes.tinyFab, customClass)} onClick={this.props.onClick}><Error
                color={'secondary'}
                className={yellow ? classes.yellow : ''}/></Fab>
        )
    }
}

const styles = theme => ({
    tinyFab: {
        width: '1.5rem',
        height: '1.5rem',
        minHeight: 'auto',
        position: 'absolute',
        right: '-2rem',
        top: '1rem',
        boxShadow: 'none',
        backgroundColor: 'transparent'
    },
    yellow: {color: '#f7cf08'}
})

export default withStyles(styles)(StyledTinyFabError)