import axios from 'axios'
import {getSettings} from '../config/config'
import {getCurrentAuthHeader} from '../helpers/utilities'
import {checkValidStatusCode} from '../helpers/httpHelper'

let settings = getSettings();
let baseURL = settings.baseURL;

export const searchPassengers = data => async dispatch => {
    dispatch(_searchPassengersIsLoading())

    const rs = await axios.post(baseURL + '/Passenger/SearchPassengers', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status)) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_searchPassengersFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_searchPassengersSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_searchPassengersFail())
        return {success: false, message: rs.data.error.message}
    }
}

const SEARCH_PASSENGERS_IS_LOADING = 'SEARCH_PASSENGERS_IS_LOADING'
const SEARCH_PASSENGERS_SUCCESS = 'SEARCH_PASSENGERS_SUCCESS'
const SEARCH_PASSENGERS_FAIL = 'SEARCH_PASSENGERS_FAIL'

const _searchPassengersIsLoading = () => ({
    type: SEARCH_PASSENGERS_IS_LOADING
})

const _searchPassengersSuccess = rs => ({
    type: SEARCH_PASSENGERS_SUCCESS,
    rs
})

const _searchPassengersFail = () => ({
    type: SEARCH_PASSENGERS_FAIL
})

export const getGroupsByAttribute = data => async dispatch => {
    dispatch(_getGroupsByAttributeIsLoading())

    const rs = await axios.get(baseURL + '/Group/GetGroupsByAttribute', data).catch(e => {
        if (!checkValidStatusCode(e.response.status)) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_getGroupsByAttributeFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_getGroupsByAttributeSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getGroupsByAttributeFail())
        return {success: false, message: rs.data.error.message}
    }
}

const GET_GROUPS_BY_ATTRIBUTE_IS_LOADING = 'GET_GROUPS_BY_ATTRIBUTE_IS_LOADING'
const GET_GROUPS_BY_ATTRIBUTE_SUCCESS = 'GET_GROUPS_BY_ATTRIBUTE_SUCCESS'
const GET_GROUPS_BY_ATTRIBUTE_FAIL = 'GET_GROUPS_BY_ATTRIBUTE_FAIL'

const _getGroupsByAttributeIsLoading = () => ({
    type: GET_GROUPS_BY_ATTRIBUTE_IS_LOADING
})

const _getGroupsByAttributeSuccess = rs => ({
    type: GET_GROUPS_BY_ATTRIBUTE_SUCCESS,
    rs
})

const _getGroupsByAttributeFail = () => ({
    type: GET_GROUPS_BY_ATTRIBUTE_FAIL
})

export const getPassengersByCardRange = data => async dispatch => {
    dispatch(_getPassengersByCardRangeIsLoading())

    const rs = await axios.get(baseURL + '/Passenger/GetPassengersByCardRange', data).catch(e => {
        if (e.response.status === 500) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_getPassengersByCardRangeFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_getPassengersByCardRangeSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getPassengersByCardRangeFail())
        return {success: false, message: rs.data.error.message}
    }
}

const GET_PASSENGERS_BY_CARD_RANGE_IS_LOADING = 'GET_PASSENGERS_BY_CARD_RANGE_IS_LOADING'
const GET_PASSENGERS_BY_CARD_RANGE_SUCCESS = 'GET_PASSENGERS_BY_CARD_RANGE_SUCCESS'
const GET_PASSENGERS_BY_CARD_RANGE_FAIL = 'GET_PASSENGERS_BY_CARD_RANGE_FAIL'

const _getPassengersByCardRangeIsLoading = () => ({
    type: GET_PASSENGERS_BY_CARD_RANGE_IS_LOADING
})

const _getPassengersByCardRangeSuccess = rs => ({
    type: GET_PASSENGERS_BY_CARD_RANGE_SUCCESS,
    rs
})

const _getPassengersByCardRangeFail = () => ({
    type: GET_PASSENGERS_BY_CARD_RANGE_FAIL
})

export const getPhysicalVoucherByRange = data => async dispatch => {
    dispatch(_getPhysicalVoucherByRangeIsLoading())

    const rs = await axios.get(baseURL + '/PhysicalVoucher/GetPhysicalVoucherByRange', data).catch(e => {
        if (e.response.status === 500) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_getPhysicalVoucherByRangeFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_getPhysicalVoucherByRangeSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getPhysicalVoucherByRangeFail())
        return {success: false, message: rs.data.error.message}
    }
}

const GET_PHYSICAL_VOUCHER_BY_RANGE_IS_LOADING = 'GET_PHYSICAL_VOUCHER_BY_RANGE_IS_LOADING'
const GET_PHYSICAL_VOUCHER_BY_RANGE_SUCCESS = 'GET_PHYSICAL_VOUCHER_BY_RANGE_SUCCESS'
const GET_PHYSICAL_VOUCHER_BY_RANGE_FAIL = 'GET_PHYSICAL_VOUCHER_BY_RANGE_FAIL'

const _getPhysicalVoucherByRangeIsLoading = () => ({
    type: GET_PHYSICAL_VOUCHER_BY_RANGE_IS_LOADING
})

const _getPhysicalVoucherByRangeSuccess = rs => ({
    type: GET_PHYSICAL_VOUCHER_BY_RANGE_SUCCESS,
    rs
})

const _getPhysicalVoucherByRangeFail = () => ({
    type: GET_PHYSICAL_VOUCHER_BY_RANGE_FAIL
})

const initState = {
    searchPassengersIsLoading: false,
    passengers: [],
    getGroupsByAttributeIsLoading: false,
    groups: [],
    getPassengersByCardRangeIsLoading: false,
    getPhysicalVoucherByRangeIsLoading: false,
    cardVoucherRangeSearchResults: []
}

const searchDuck = (state = initState, action) => {
    switch (action.type) {

        case SEARCH_PASSENGERS_IS_LOADING:
            return {...state, searchPassengersIsLoading: true}

        case SEARCH_PASSENGERS_SUCCESS:
            return {...state, searchPassengersIsLoading: false, passengers: action.rs.data.result.items}

        case SEARCH_PASSENGERS_FAIL:
            return {...state, searchPassengersIsLoading: false}

        case GET_GROUPS_BY_ATTRIBUTE_IS_LOADING:
            return {...state, getGroupsByAttributeIsLoading: true}

        case GET_GROUPS_BY_ATTRIBUTE_SUCCESS:
            return {...state, getGroupsByAttributeIsLoading: false, groups: action.rs.data.result.items}

        case GET_GROUPS_BY_ATTRIBUTE_FAIL:
            return {...state, getGroupsByAttributeIsLoading: false}

        case GET_PASSENGERS_BY_CARD_RANGE_IS_LOADING:
            return {...state, getPassengersByCardRangeIsLoading: true}

        case GET_PASSENGERS_BY_CARD_RANGE_SUCCESS:
            return {...state, getPassengersByCardRangeIsLoading: false, cardVoucherRangeSearchResults: action.rs.data.result.items}

        case GET_PASSENGERS_BY_CARD_RANGE_FAIL:
            return {...state, getPassengersByCardRangeIsLoading: false}

        case GET_PHYSICAL_VOUCHER_BY_RANGE_IS_LOADING:
            return {...state, getPhysicalVoucherByRangeIsLoading: true}

        case GET_PHYSICAL_VOUCHER_BY_RANGE_SUCCESS:
            return {...state, getPhysicalVoucherByRangeIsLoading: false, cardVoucherRangeSearchResults: action.rs.data.result.items}

        case GET_PHYSICAL_VOUCHER_BY_RANGE_FAIL:
            return {...state, getPhysicalVoucherByRangeIsLoading: false}

        default:
            return state
    }
}

export default searchDuck