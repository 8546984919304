import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Grid, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import Header from '../containers/Header.js'
import AdminSettingsNav from '../containers/adminSettings/AdminSettingsNav'
import { TITLE_PREFIX } from '../constants/titlePrefix'
import StyledSection from '../components/StyledSection'
import StyledSelect from '../components/StyledSelect'
import { updateCreateNewAccountDuck, submitCreateNewAccount } from '../ducks/createNewAccountDuck'
import { getRoles, getUsers, deleteUser, updateUser, unlockUser } from '../ducks/systemDataDuck'
import { getAllIata } from '../ducks/iataDuck'
import { labelToObject, rolesToLabelObject } from '../helpers/reactSelectHelper'
import StyledSnackbar from '../components/StyledSnackbar'
import StyledTable from '../components/StyledTable'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import { hasPermissions } from '../helpers/utilities'
import { USER_ADMIN_ACCOUNT_READ_PERMISSION, USER_ADMIN_ACCOUNT_CREATE_PERMISSION, USER_ADMIN_ACCOUNT_EDIT_PERMISSION } from '../constants/permissions'
import { USER_ACCOUNTS_TABLE_KEY_NAME } from '../constants/tableKeys'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

const USER_ACCOUNTS_TABLE_FIELDS = [
	{ key: 'seq', label: 'No.' },
	{ key: 'id', label: 'Id' },
	{ key: 'name', label: 'Name' },
	{ key: 'contactNo', label: 'Mobile No' },
	{ key: 'roleNames', label: 'Role' },
	{ key: 'status', label: 'Status' }
]

class CreateNewAccount extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			nameError: '',
			emailAddressError: '',
			contactNoError: '',
			nameEditError: '',
			emailAddressEditError: '',
			contactNoEditError: '',
			roleNamesError: '',
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
			showDialog: false,
			showUnlockDialog: false,
			editingUser: {},
			editingUsername: '',
			showDeleteDialog: false,
			deletingUser: {},
			filteredUsers: [],
			unlockingUser: {},
			roleFilter: { label: 'View All', value: '' },
			statusFilter: 'all'
		}
	}

	componentDidMount = async () => {
		document.title = `${TITLE_PREFIX} Admin settings - Create New Account`

		this.props.getAllIata()

		let rs = ''
		rs = await this.props.getRoles()
		if (!rs.success) this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })

		rs = await this.props.getUsers()
		if (!rs.success) this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })

		if (rs.success) {
			const { systemDataDuck } = this.props
			this.setState({ filteredUsers: this._removeAdminUser(systemDataDuck.users) })
		}
	}

	handleStatusRadioGroupChange = e => {
		this.setState({
			statusFilter: e.target.value,
			filteredUsers: this.__filterUsersByStatus(this.__filterUsersByRole(this.props.systemDataDuck.users, this.state.roleFilter), e.target.value)
		})
	}

	__filterUsersByStatus(users, statusFilter) {
		return statusFilter === 'all' ? users : statusFilter === 'active' ? users.filter(u => u.isActive) : users.filter(u => !u.isActive)
	}

	__filterUsersByRole(users, roleFilter) {
		return users.filter(u => {
			return u.roleNames.length > 0 ? u.roleNames[0].toLowerCase().indexOf(roleFilter.value.toLowerCase()) !== -1 : false
		})
	}

	onRoleChange = e => {
		this.props.updateCreateNewAccountDuck('roleNames', [e.label])
	}

	onPosChange = e => {
		this.props.updateCreateNewAccountDuck('pos', e.label)
	}

	onSubmit = async () => {
		const { createNewAccountDuck } = this.props

		if (this._validate(createNewAccountDuck, 'Error')) {
			const rs = await this.props.submitCreateNewAccount(createNewAccountDuck)
			if (rs.success) {
				this.props.getUsers()
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'success',
					snackbarMessage: `Created account: ${createNewAccountDuck.name}`
				})
			} else {
				this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
			}
		}
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	_validate = (fields, errorSuffix) => {
		let rs = true

		const requiredFields = ['name', 'emailAddress', 'contactNo']
		requiredFields.forEach(k => {
			let sk = k + errorSuffix
			if (fields[k] === '') {
				this.setState({ [sk]: 'This field is required' })
				rs = false
			} else {
				this.setState({ [sk]: '' })
			}
		})
		if (!rs) return rs

		if (!/^\+/.test(fields.contactNo)) {
			this.state.showDialog
				? this.setState({ contactNoEditError: 'Please include `+` followed by area code and mobile number' })
				: this.setState({ contactNoError: 'Please include `+` followed by area code and mobile number' })
			rs = false
			if (!rs) return rs
		} else {
			this.state.showDialog ? this.setState({ contactNoEditError: '' }) : this.setState({ contactNoError: '' })
		}

		if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(fields.emailAddress)) {
			this.setState({ emailAddressError: 'Email is invalid' })
			rs = false
		}

		if (fields['roleNames'].length === 0) {
			this.setState({ roleNamesError: 'This field is required' })
			rs = false
		} else {
			this.setState({ roleNamesError: '' })
		}

		if (!rs) return rs

		return rs
	}

	onFormChange = (k, e) => {
		if (k === 'contactNo') {
			if (/[^+0-9]/g.test(e.target.value)) return false
		}
		this.props.updateCreateNewAccountDuck(k, e.target.value)
	}

	onUserEdit = row => {
		this.setState({ editingUser: { ...row }, showDialog: true, editingUsername: row.name })
	}

	onUserDelete = row => {
		this.setState({ showDeleteDialog: true, deletingUser: row })
	}

	onUnlockingUser = user => {
		if (!user.isLockout) {
			return
		}
		this.setState({ showUnlockDialog: true, unlockingUser: user })
	}

	onUnlockUser = async () => {
		const { name, emailAddress, id } = this.state.unlockingUser
		const rs = await this.props.unlockUser({
			name,
			emailAddress,
			id
		})
		if (rs.success) {
			this.setState(prevState => ({
				showSnackbar: true,
				snackbarVariant: 'success',
				snackbarMessage: `Unlocked user: ${name}`,
				showUnlockDialog: false,
				unlockingUser: null,
				editingUser: {
					...prevState.editingUser,
					isLockout: false
				}
			}))
		} else {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
		}
	}

	onUnlockCancel = () => {
		this.setState({ showUnlockDialog: false, unlockingUser: null })
	}

	onEditSave = async () => {
		if (this._validate(this.state.editingUser, 'EditError')) {
			this.setState({ showDialog: false })
			const rs = await this.props.updateUser(this.state.editingUser)
			if (rs.success) {
				const UserRefresh = await this.props.getUsers()
				if (UserRefresh.success) {
					const { systemDataDuck } = this.props
					const users = systemDataDuck.users
					const { roleFilter } = this.state
					if (roleFilter.value.length >= 1) {
						this.setState({
							filteredUsers: this.__filterUsersByStatus(this.__filterUsersByRole(users, roleFilter), this.state.statusFilter)
						})
					} else {
						this.setState({ filteredUsers: this.__filterUsersByStatus(users, this.state.statusFilter) })
					}
				}

				this.setState({
					showSnackbar: true,
					snackbarVariant: 'success',
					snackbarMessage: `Updated user: ${this.state.editingUser.name}`,
					editingUser: {}
				})
			} else {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: rs.message,
					editingUser: {}
				})
			}
		}
	}

	onEditCancel = () => {
		this.setState({ showDialog: false, emailAddressEditError: '', contactNoEditError: '', nameEditError: '' })
	}

	onEditChange = (k, e) => {
		if (k === 'contactNo') {
			if (/[^+0-9]/g.test(e.target.value)) return false
		}
		this.setState({ editingUser: { ...this.state.editingUser, [k]: e.target.value } })
	}

	onRoleEditChange = v => {
		this.setState({ editingUser: { ...this.state.editingUser, roleNames: [v.label] } })
	}

	onPosEditChange = v => {
		this.setState({ editingUser: { ...this.state.editingUser, pos: v.label } })
	}

	onStatusEditChange = v => {
		this.setState({ editingUser: { ...this.state.editingUser, isActive: v.value } })
	}

	onDeleteCancel = () => {
		this.setState({ showDeleteDialog: false, deletingUser: {} })
	}

	onDeleteConfirm = async () => {
		this.setState({ showDeleteDialog: false })

		const rs = await this.props.deleteUser(this.state.deletingUser.id)
		if (rs.success) {
			this.props.getUsers()
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'success',
				snackbarMessage: `Deleted user: ${this.state.deletingUser.name}`,
				deletingUser: {}
			})
		} else {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: rs.message !== '' ? rs.message : 'Unexpected error'
			})
		}
	}

	_removeAdminUser = roles => {
		return roles.filter(r => r.name !== 'admin')
	}

	_removeAdminRole = roles => {
		return roles.filter(r => r.name !== 'Admin')
	}

	onFilterChange = e => {
		const { systemDataDuck } = this.props
		const users = systemDataDuck.users
		const v = e.value.toLowerCase()
		if (v.length > 1) {
			this.setState({
				filteredUsers: this.__filterUsersByStatus(this.__filterUsersByRole(users, e), this.state.statusFilter)
			})
		}
		if (v.length < 1) {
			this.setState({ filteredUsers: this.__filterUsersByStatus(users, this.state.statusFilter) })
		}
		this.setState({ roleFilter: { label: e.label, value: e.value } })
	}

	render() {
		const { classes, createNewAccountDuck, systemDataDuck } = this.props
		const { editingUser, editingUsername, deletingUser, roleFilter, filteredUsers, statusFilter } = this.state

		const hasCreatePermission = hasPermissions([USER_ADMIN_ACCOUNT_CREATE_PERMISSION])
		const hasEditPermission = hasPermissions([USER_ADMIN_ACCOUNT_EDIT_PERMISSION])
		const hasReadPermission = hasPermissions([USER_ADMIN_ACCOUNT_READ_PERMISSION]) || hasCreatePermission || hasEditPermission

		const RolesDropDown = rolesToLabelObject(systemDataDuck.roles)
		RolesDropDown.unshift({ label: 'View All', value: '' })

		return (
			<div>
				<GlobalTimeChecker />

				<Header history={this.props.history} />
				<AdminSettingsNav />

				{/*{<editor-fold desc="Alert Dialog">}*/}
				{Object.keys(deletingUser).length > 0 && (
					<Dialog open={this.state.showDeleteDialog} onClose={this.onDeleteCancel}>
						<DialogTitle>Proceed to delete the following user:</DialogTitle>
						<DialogContent>
							<Grid container spacing={24} direction={'column'}>
								<Grid item container spacing={24}>
									<Grid item md={3}>
										<Typography variant={'body1'}> Name: </Typography>
									</Grid>
									<Grid item md={3}>
										<Typography variant={'body1'}>{deletingUser.name}</Typography>
									</Grid>
								</Grid>
								<Grid item container spacing={24}>
									<Grid item md={3}>
										<Typography variant={'body1'}> Role:</Typography>
									</Grid>
									<Grid item md={3}>
										<Typography variant={'body1'}>{deletingUser.roleNames[0]}</Typography>
									</Grid>
								</Grid>
								<Grid item>
									<Typography variant={'body1'} color={'secondary'}>
										<Warning className={classes.icon} /> This process cannot be reversed.
									</Typography>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button color={'default'} variant='text' onClick={this.onDeleteCancel}>
								Cancel
							</Button>
							<Button color={'secondary'} variant='contained' onClick={this.onDeleteConfirm}>
								Yes, Delete
							</Button>
						</DialogActions>
					</Dialog>
				)}
				{/*{</editor-fold>}*/}

				{/*{<editor-fold desc="Edit Dialog">}*/}
				<Dialog open={this.state.showDialog} scroll={'body'} onClose={this.onEditCancel}>
					<DialogTitle>Editing {editingUsername} </DialogTitle>
					<DialogContent>
						<Grid container spacing={24} direction={'column'}>
							<Grid item>
								<StyledSelect
									value={labelToObject(editingUser.roleNames)}
									className={classes.select}
									options={rolesToLabelObject(this._removeAdminRole(systemDataDuck.roles))}
									onChange={e => this.onRoleEditChange(e)}
									label={'Role'}
									error={this.state.roleNamesError !== ''}
									helperText={this.state.roleNamesError}
								/>
							</Grid>

							<Grid item>
								<StyledSelect
									value={{ label: editingUser.isActive ? 'Active' : 'Inactive', value: editingUser.isActive }}
									className={classes.select}
									options={[
										{ label: 'Active', value: true },
										{ label: 'Inactive', value: false }
									]}
									onChange={e => this.onStatusEditChange(e)}
									label={'Status'}
								/>
							</Grid>

							<Grid item>
								<StyledSelect
									value={labelToObject(editingUser.pos)}
									className={classes.select}
									options={this.props.iataDuck.iatas.map(iata => ({ label: iata.iataCode, value: iata.iataCode }))}
									onChange={e => this.onPosEditChange(e)}
									label={'POS'}
								/>
							</Grid>

							<Grid item>
								<TextField
									id='name'
									label='Name'
									margin='normal'
									variant='outlined'
									error={this.state.nameEditError !== ''}
									helperText={this.state.nameEditError}
									onChange={e => this.onEditChange('name', e)}
									value={editingUser.name}
									fullWidth
								/>
							</Grid>
							<Grid item>
								<TextField
									id='emailAddress'
									label='Email Address'
									margin='normal'
									type={'email'}
									error={this.state.emailAddressEditError !== ''}
									helperText={this.state.emailAddressEditError}
									onChange={e => this.onEditChange('emailAddress', e)}
									value={editingUser.emailAddress}
									variant='outlined'
									fullWidth
								/>
							</Grid>
							<Grid item>
								<TextField
									id='contactNoNumber'
									label='Mobile Number'
									margin='normal'
									error={this.state.contactNoEditError !== ''}
									helperText={this.state.contactNoEditError !== '' ? this.state.contactNoEditError : '+[country code][mobile no]'}
									onChange={e => this.onEditChange('contactNo', e)}
									value={editingUser.contactNo || ''}
									variant='outlined'
									fullWidth
								/>
							</Grid>
							<Grid item>
								<FormControlLabel
									control={
										<Switch
											checked={editingUser.isLockout}
											color='primary'
											onChange={() => this.onUnlockingUser(editingUser)}
											value={editingUser.isLockout}
										/>
									}
									label={editingUser.isLockout ? 'Unlock User' : 'User locked out?'}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button color={'default'} variant='text' onClick={this.onEditCancel}>
							Cancel
						</Button>
						<Button color={'primary'} variant='contained' onClick={this.onEditSave}>
							Save
						</Button>
					</DialogActions>
				</Dialog>
				{/*{</editor-fold>}*/}
				{/*{<editor-fold desc="Alert Dialog">}*/}
				<Dialog open={this.state.showUnlockDialog} onClose={this.onUnlockCancel}>
					<DialogTitle>Unlock User</DialogTitle>
					<DialogContent>
						Are you sure you want to unlock user <strong>{editingUsername}</strong>?
					</DialogContent>
					<DialogActions>
						<Button color={'default'} variant='text' onClick={this.onUnlockCancel}>
							No
						</Button>
						<Button color={'primary'} variant='contained' onClick={this.onUnlockUser}>
							Yes
						</Button>
					</DialogActions>
				</Dialog>
				{/*{</editor-fold>}*/}
				<div className={classes.container}>
					{hasReadPermission && (
						<Grid container spacing={24} direction={'column'}>
							<Grid item>
								<StyledSection
									title={'User accounts'}
									isLoading={systemDataDuck.getUsersIsLoading || systemDataDuck.deleteUserIsLoading || systemDataDuck.updateUserIsLoading}
								>
									<StyledSelect value={roleFilter} options={RolesDropDown} onChange={e => this.onFilterChange(e)} label={'Role Filter'} />

									<Typography variant='h6'>Status</Typography>
									<RadioGroup
										row={true}
										aria-label='Status'
										name='statusFilterRadio'
										onChange={e => this.handleStatusRadioGroupChange(e)}
										value={statusFilter}
									>
										<FormControlLabel value='all' control={<Radio />} label='All' />
										<FormControlLabel value='active' control={<Radio />} label='Active' />
										<FormControlLabel value='inactive' control={<Radio />} label='Inactive' />
									</RadioGroup>
									{/*<StyledTable
                                    fields={USER_ACCOUNTS_TABLE_FIELDS}
                                    rows={filteredUsers}
                                    onEdit={this.onUserEdit}
                                    onDelete={this.onUserDelete}
                                    isEditable={hasEditPermission}
                                    isDeletable={hasEditPermission}
                                    isFilterable
                                    filter={roleFilter}
                                    filterLabel={'Role filter'}
                                    onFilterChange={this.onFilterChange}
                                />*/}
									<StyledTable
										fields={USER_ACCOUNTS_TABLE_FIELDS}
										tableKeyName={USER_ACCOUNTS_TABLE_KEY_NAME}
										rows={filteredUsers}
										onEdit={this.onUserEdit}
										onDelete={this.onUserDelete}
										isEditable={hasEditPermission}
										isDeletable={hasEditPermission}
									/>
								</StyledSection>
							</Grid>
							{hasCreatePermission && (
								<Grid item>
									<StyledSection title={'Create New Account'} isLoading={createNewAccountDuck.submitCreateNewAccountIsLoading}>
										<Grid container spacing={24} direction={'column'}>
											<Grid container item spacing={24}>
												<Grid item xs={3}>
													<TextField
														id='name'
														label='Name'
														margin='normal'
														variant='outlined'
														error={this.state.nameError !== ''}
														helperText={this.state.nameError}
														onChange={e => this.onFormChange('name', e)}
														value={createNewAccountDuck.name}
														fullWidth
													/>
												</Grid>
												<Grid item xs={3}>
													<TextField
														id='emailAddress'
														label='Email Address'
														margin='normal'
														error={this.state.emailAddressError !== ''}
														helperText={this.state.emailAddressError}
														onChange={e => this.onFormChange('emailAddress', e)}
														value={createNewAccountDuck.emailAddress}
														variant='outlined'
														fullWidth
													/>
												</Grid>
												<Grid item xs={3}>
													<TextField
														id='contactNoNumber'
														label='Mobile Number'
														margin='normal'
														error={this.state.contactNoError !== ''}
														helperText={this.state.contactNoError !== '' ? this.state.contactNoError : 'Format: +[country code][mobile no]'}
														onChange={e => this.onFormChange('contactNo', e)}
														value={createNewAccountDuck.contactNo}
														variant='outlined'
														fullWidth
													/>
												</Grid>
											</Grid>

											<Grid container item spacing={24}>
												<Grid item xs={3}>
													<StyledSelect
														value={labelToObject(createNewAccountDuck.pos)}
														options={this.props.iataDuck.iatas.map(iata => ({ label: iata.iataCode, value: iata.iataCode }))}
														onChange={e => this.onPosChange(e)}
														label={'POS'}
													/>
												</Grid>
											</Grid>

											<Grid container item spacing={24}>
												<Grid item xs={3}>
													<StyledSelect
														value={labelToObject(createNewAccountDuck.roleNames)}
														options={rolesToLabelObject(this._removeAdminRole(systemDataDuck.roles))}
														onChange={e => this.onRoleChange(e)}
														label={'Role'}
														error={this.state.roleNamesError !== ''}
														helperText={this.state.roleNamesError}
													/>
												</Grid>
											</Grid>

											<Grid item xs={3}>
												<Button color={'primary'} variant='contained' onClick={this.onSubmit}>
													Submit
												</Button>
											</Grid>
										</Grid>
									</StyledSection>
								</Grid>
							)}
						</Grid>
					)}
				</div>
				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = theme => ({
	container: { ...theme.container },
	icon: { display: 'inline-block', verticalAlign: 'bottom' },
	select: { marginTop: '5px' }
})

const mapStateToProps = state => {
	const { createNewAccountDuck, systemDataDuck, iataDuck } = state

	return { createNewAccountDuck, systemDataDuck, iataDuck }
}

const mapDispatchToProps = dispatch => {
	return {
		updateCreateNewAccountDuck: (k, v) => dispatch(updateCreateNewAccountDuck(k, v)),
		getRoles: () => dispatch(getRoles()),
		submitCreateNewAccount: fields => dispatch(submitCreateNewAccount(fields)),
		getUsers: () => dispatch(getUsers()),
		deleteUser: id => dispatch(deleteUser(id)),
		updateUser: user => dispatch(updateUser(user)),
		unlockUser: user => dispatch(unlockUser(user)),
		getAllIata: () => dispatch(getAllIata())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateNewAccount))
