import React, {Component} from 'react'
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, withStyles
} from '@material-ui/core'
import PropTypes from 'prop-types'
import {Warning} from '@material-ui/icons'

class DeleteDialog extends Component {
    render() {
        const {classes, dialogTitle, onDeleteCancel, onDeleteConfirm, hideWarningMessage} = this.props

        return (
            <Dialog open={this.props.showDeleteDialog} onClose={this.onDeleteCancel} classes={{paper: classes.paper}}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {this.props.children}
                    {!hideWarningMessage &&
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item>
                                <Typography variant={'body1'} color={'secondary'}>
                                    <Warning className={classes.icon}/> This process cannot be reversed.
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button color={'default'} variant='text' onClick={onDeleteCancel}>Cancel</Button>
                    <Button color={'secondary'} variant='contained' onClick={onDeleteConfirm}>Yes, Delete</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = theme => ({
    container: theme.container,
    paper: {minWidth: '40rem'},
    icon: {display: 'inline-block', verticalAlign: 'bottom'}
})

DeleteDialog.propTypes = {
    showDeleteDialog: PropTypes.bool.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    onDeleteCancel: PropTypes.func.isRequired,
    onDeleteConfirm: PropTypes.func.isRequired
}

export default withStyles(styles)(DeleteDialog)