import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withStyles, Grid, Button, FormControlLabel, Switch} from '@material-ui/core'
import Header from '../containers/Header.js'
import AdminSettingsNav from '../containers/adminSettings/AdminSettingsNav'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import StyledSection from '../components/StyledSection'
import {getShouldCallSQ, updateShouldCallSQ} from '../ducks/systemDataDuck'
import StyledSnackbar from '../components/StyledSnackbar'
import GlobalTimeChecker from "../containers/GlobalTimeChecker";

class SqApiScreen extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            shouldCallSQ: false,
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success'
        }
    }

    componentDidMount = async () => {
        document.title = `${TITLE_PREFIX} Admin settings - SQ API`

        const rs = await this.props.getShouldCallSQ()
        if (!rs.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.systemDataDuck.getShouldCallSQIsLoading === true && this.props.systemDataDuck.getShouldCallSQIsLoading === false) {
            const {systemDataDuck: {shouldCallSQ}} = this.props
            this.setState({shouldCallSQ: shouldCallSQ})
        }
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    onSubmit = async () => {
        const data = {input: this.state.shouldCallSQ}
        const rs = await this.props.updateShouldCallSQ(data)
        if (rs.success) {
            this.setState({showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'SQ API updated'})
            this.props.getShouldCallSQ()
        } else {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        }
    }

    render() {
        const {classes, systemDataDuck: {getShouldCallSQIsLoading, updateShouldCallSQIsLoading}} = this.props
        const {shouldCallSQ} = this.state
        const isLoading = getShouldCallSQIsLoading || updateShouldCallSQIsLoading

        return (
            <div>
                <GlobalTimeChecker/>
                <Header history={this.props.history}/>
                <AdminSettingsNav/>

                <div className={classes.container}>
                    <StyledSection title={'SQ API'} isLoading={isLoading}>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item>
                                <FormControlLabel
                                    label={'SQ API'}
                                    control={
                                        <Switch
                                            checked={shouldCallSQ}
                                            onChange={() => this.setState({shouldCallSQ: !this.state.shouldCallSQ})}
                                            color={'primary'}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Button color={'primary'} variant="contained" onClick={this.onSubmit}>Submit</Button>
                            </Grid>
                        </Grid>
                    </StyledSection>
                    <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                        message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
                </div>
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

const mapStateToProps = state => {
    const {systemDataDuck} = state

    return {systemDataDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        getShouldCallSQ: () => dispatch(getShouldCallSQ()),
        updateShouldCallSQ: data => dispatch(updateShouldCallSQ(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SqApiScreen))