import {generateBatches} from "../helpers/batchHelper.js"
import axios from "axios";
import {getSettings} from "../config/config";
import {getCurrentAuthHeader} from "../helpers/utilities";
import {checkValidStatusCode} from "../helpers/httpHelper";

let settings = getSettings();
let baseURL = settings.baseURL;

export const calculatePassengersVouchers = async ({formsDetailList, isCr = true, isPreloadedVoucher = true}) => {
    let passengers = [];
    formsDetailList.forEach(form => {
        if(!form.isDeleted){
            let passenger = {}
            passenger.eTicket1 = form.eTicketsDetails.eTicket1;
            passenger.origin = form.sector1Details.origin;
            passengers.push(passenger)
        }
    });
    let numberOfSector = formsDetailList[0].numberOfSector;
    const rs = await axios.post(baseURL + '/Passenger/CalculatePassengersVouchers', {passengers: passengers, isCr: isCr, numberOfSector: numberOfSector, isPreloadedVoucher: isPreloadedVoucher}, getCurrentAuthHeader()).catch(e => {
        if(!e.response) return {success: false, message: 'cannot connect'}
        if (!checkValidStatusCode(e.response.status)) {
            const {response: {data: {error: {message}}}} = e
            return {success: false, message: message}
        }
        return {success: false, message: e.response.status + ' Unexpected error'}
    });
    if (!rs.data) {
        return {success: false, message: rs.message}
    }
    if (!rs.data.success) {
        return {success: false, message: 'unexpected error'}
    } else {
    }
    var formattedFormsDetailList = [].concat(formsDetailList);
    var passengersWithVouchers = rs.data.result.items;
    formattedFormsDetailList.forEach(p=>{
        var matchedPassenger = passengersWithVouchers.find(pwv => pwv.eTicket1 === p.eTicketsDetails.eTicket1);
        if(matchedPassenger){
            p.ctpGenValue = matchedPassenger.ctpGenValue;
            p.ctpLpcValue = matchedPassenger.ctpLpcValue;
            p.ctpVoucherValue = matchedPassenger.ctpVoucherValue;
        }
    })
    return {success: true, passengers: formattedFormsDetailList}
}
