import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {withStyles, Grid, Button, TextField, Typography, Paper} from '@material-ui/core'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import MySnackbarContent from '../components/MySnackbarContent'
import {forgotPassword} from '../ducks/authDuck'
import {REQUIRED_ERROR_MSG} from '../constants/messages'
import LoadingContainer from '../containers/loadingContainer'

class ForgotPasswordScreen extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            userId: '',
            userIdError: '',
            submitSuccess: false,
            submitMessage: ''
        }
    }

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Forgot Password`
    }

    _onSubmit = async () => {
        if (this.validate()) {
            const data = {userId: this.state.userId}
            const rs = await this.props.forgotPassword(data)
            this.setState({
                submitSuccess: true,
                submitMessage: 'An email with further instructions has been sent to your email'
            })
            // if (rs.success) {
            //     this.setState({
            //         submitSuccess: rs.success,
            //         submitMessage: 'An email with further instructions has been sent to your email'
            //     })
            // } else {
            //     this.setState({submitSuccess: rs.success, submitMessage: 'Email is not valid'})
            // }
        }
    }

    validate() {
        let rs = true
        if (this.state.userId === '') {
            this.setState({userIdError: REQUIRED_ERROR_MSG})
            rs = false
        } else {
            this.setState({userIdError: ''})
        }
        return rs
    }

    onFieldChange = e => {
        this.setState({userId: e.target.value})
    }

    clearErrorMessage = () => {
        this.setState({submitMessage: ''})
    }

    render() {
        const {classes, forgotPasswordIsLoading} = this.props
        const {submitSuccess, submitMessage, userId, userIdError} = this.state

        return (
            <Grid container className={classes.container}>

                <div className={classes.inner}>
                    <Grid container spacing={24} direction={'column'}>
                        <Grid item>
                            <Typography variant={'h4'}>Forgot Password</Typography>
                        </Grid>

                        <Paper className={classes.paper}>
                            <Grid container spacing={24} direction={'column'}>
                                <Grid item>
                                    <form>
                                        <Grid container spacing={24}>
                                            <Grid item xs={12}>
                                                <TextField id={'userId'} variant={'outlined'}
                                                    value={userId}
                                                    label={'Registered email'}
                                                    placeholder={'Enter your registered email'} fullWidth={true}
                                                    onChange={this.onFieldChange}
                                                    error={!!userIdError} helperText={userIdError}
                                                />
                                            </Grid>

                                            {!submitSuccess && <Grid item xs={12}>
                                                <Button variant={'contained'} color={'primary'}
                                                    onClick={this._onSubmit}>Submit</Button>
                                            </Grid>}
                                        </Grid>
                                    </form>
                                </Grid>

                                {submitSuccess && submitMessage !== '' &&
                                <Grid item>
                                    <MySnackbarContent variant={'success'} message={submitMessage}
                                        onClose={this.clearErrorMessage}/>
                                </Grid>}

                                {!submitSuccess && submitMessage !== '' &&
                                <Grid item>
                                    <MySnackbarContent variant={'error'} message={submitMessage}
                                        onClose={this.clearErrorMessage}/>
                                </Grid>}

                                {submitMessage !== '' && <Grid item>
                                    <Button component={Link} to={'/'} color={'primary'} variant={'contained'}>Back to
                                        login</Button>
                                </Grid>}
                            </Grid>
                            <LoadingContainer show={forgotPasswordIsLoading}/>
                        </Paper>

                    </Grid>
                </div>
            </Grid>
        )
    }
}

const styles = {
    container: {width: '100%', height: '100vh'},
    inner: {width: '40em', height: '30em', textAlign: 'center', maxWidth: '20em', margin: 'auto'},
    notice: {textAlign: 'center'},
    paper: {padding: '2rem', position: 'relative'}
}

const mapStateToProps = state => {
    const {authDuck: {forgotPasswordIsLoading}} = state

    return {forgotPasswordIsLoading}
}

const mapDispatchToProps = dispatch => {
    return {
        forgotPassword: data => dispatch(forgotPassword(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPasswordScreen))