import axios from 'axios'
import {getSettings} from '../config/config'
import {getCurrentAuthHeader} from '../helpers/utilities'
import {checkValidStatusCode} from "../helpers/httpHelper";

const GET_ROLES_IS_LOADING = 'GET_ROLES_IS_LOADING'
const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'
const GET_ROLES_FAIL = 'GET_ROLES_FAIL'
const GET_PERMISSIONS_IS_LOADING = 'GET_PERMISSIONS_IS_LOADING'
const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS'
const GET_PERMISSIONS_FAIL = 'GET_PERMISSIONS_FAIL'
const GET_USERS_IS_LOADING = 'GET_USERS_IS_LOADING'
const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
const GET_USERS_FAIL = 'GET_USERS_FAIL'
const DELETE_USER_IS_LOADING = 'DELETE_USER_IS_LOADING'
const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
const DELETE_USER_FAIL = 'DELETE_USER_FAIL'
const UPDATE_USER_IS_LOADING = 'UPDATE_USER_IS_LOADING'
const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'
const UNLOCK_USER_IS_LOADING = 'UNLOCK_USER_IS_LOADING'
const UNLOCK_USER_SUCCESS = 'UNLOCK_USER_SUCCESS'
const UNLOCK_USER_FAIL = 'UNLOCK_USER_FAIL'
const GET_ALL_ROLES_IS_LOADING = 'GET_ALL_ROLES_IS_LOADING'
const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS'
const GET_ALL_ROLES_FAIL = 'GET_ALL_ROLES_FAIL'

let settings = getSettings();
let baseURL = settings.baseURL;

export const getAllRoles = () => async dispatch => {
    dispatch(_getAllRolesIsLoading())

    const rs = await axios.get(baseURL + '/Role/GetAll', getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs && rs.data.success) {
        dispatch(_getAllRolesSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getAllRolesFail())
        return {success: true, message: rs.data.error.message}
    }
}

const _getAllRolesIsLoading = () => ({
    type: GET_ALL_ROLES_IS_LOADING
})

const _getAllRolesSuccess = rs => ({
    type: GET_ALL_ROLES_SUCCESS,
    rs
})

const _getAllRolesFail = () => ({
    type: GET_ALL_ROLES_FAIL
})

export const getRoles = () => async dispatch => {
    dispatch(_getRolesIsLoading())

    const rs = await axios.get(baseURL + '/Role/GetAll', getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs && rs.data.success) {
        dispatch(_getRolesSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getRolesFail())
        return {success: true, message: rs.data.error.message}
    }
}

const _getRolesIsLoading = () => ({
    type: GET_ROLES_IS_LOADING
})

const _getRolesSuccess = rs => ({
    type: GET_ROLES_SUCCESS,
    rs
})

const _getRolesFail = errorDetails => ({
    type: GET_ROLES_FAIL,
    errorDetails
})

export const getPermissions = () => async dispatch => {
    dispatch(_getPermissionsIsLoading())

    const rs = await axios.get(baseURL + '/Permission', getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs.data.success) {
        dispatch(_getPermissionsSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getPermissionsFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _getPermissionsIsLoading = () => ({
    type: GET_PERMISSIONS_IS_LOADING
})

const _getPermissionsSuccess = rs => ({
    type: GET_PERMISSIONS_SUCCESS,
    rs
})

const _getPermissionsFail = () => ({
    type: GET_PERMISSIONS_FAIL
})

export const getUsers = () => async dispatch => {
    dispatch(_getUsersIsLoading())

    const rs = await axios.get(baseURL + '/Account/GetAll', getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs && rs.data.success) {
        dispatch(_getUsersSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getUsersFail())
        return {success: false, message: 'Unable to load users'}
    }
}

const _getUsersIsLoading = () => ({
    type: GET_USERS_IS_LOADING
})

const _getUsersSuccess = rs => ({
    type: GET_USERS_SUCCESS,
    rs
})

const _getUsersFail = () => ({
    type: GET_USERS_FAIL
})

export const deleteUser = id => async dispatch => {
    dispatch(_deleteUserIsLoading())

    const rs = await axios.delete(baseURL + '/Account/Delete?Id=' + id, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs && rs.data.success) {
        dispatch(_deleteUserSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_deleteUserFail())
        return {success: false, message: ''}
    }
}

const _deleteUserIsLoading = () => ({
    type: DELETE_USER_IS_LOADING
})

const _deleteUserSuccess = rs => ({
    type: DELETE_USER_SUCCESS,
    rs
})

const _deleteUserFail = () => ({
    type: DELETE_USER_FAIL
})

export const updateUser = user => async dispatch => {
    dispatch(_updateUserIsLoading())

    const {id, name, contactNo, emailAddress, roleNames, pos, isActive} = user
    const data = {id, name, contactNo, emailAddress, roleNames, pos, isActive}

    const rs = await axios.put(baseURL + '/Account/Update', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs && rs.data.success) {
        dispatch(_updateUserSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_updateUserFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _updateUserIsLoading = () => ({
    type: UPDATE_USER_IS_LOADING
})

const _updateUserSuccess = rs => ({
    type: UPDATE_USER_SUCCESS,
    rs
})

const _updateUserFail = () => ({
    type: UPDATE_USER_FAIL
})

export const unlockUser = user => async dispatch => {
    dispatch(_unlockUserIsLoading())

    const rs = await axios.post(baseURL + '/Account/ReleaseLockout', user, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (rs && rs.data.success) {
        dispatch(_unlockUserSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_unlockUserFail())
        return {success: false, message: rs.data.error.message}
    }
}

const _unlockUserIsLoading = () => ({
    type: UNLOCK_USER_IS_LOADING
})

const _unlockUserSuccess = rs => ({
    type: UNLOCK_USER_SUCCESS,
    rs
})

const _unlockUserFail = () => ({
    type: UNLOCK_USER_FAIL
})

export const getShouldCallSQ = () => async dispatch => {
    dispatch(_getShouldCallSQIsLoading())

    const rs = await axios.get(baseURL + '/SqApi/GetShouldCallSQ', getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_getShouldCallSQFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_getShouldCallSQSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getShouldCallSQFail())
        return {success: false, message: rs.data.error.message}
    }
}
const GET_SHOULD_CALL_SQ_IS_LOADING = 'GET_SHOULD_CALL_SQ_IS_LOADING'
const GET_SHOULD_CALL_SQ_SUCCESS = 'GET_SHOULD_CALL_SQ_SUCCESS'
const GET_SHOULD_CALL_SQ_FAIL = 'GET_SHOULD_CALL_SQ_FAIL'
const _getShouldCallSQIsLoading = () => ({
    type: GET_SHOULD_CALL_SQ_IS_LOADING
})
const _getShouldCallSQSuccess = rs => ({
    type: GET_SHOULD_CALL_SQ_SUCCESS,
    rs
})
const _getShouldCallSQFail = () => ({
    type: GET_SHOULD_CALL_SQ_FAIL
})

export const updateShouldCallSQ = data => async dispatch => {
    dispatch(_updateShouldCallSQIsLoading())

    const rs = await axios.post(baseURL + '/SqApi/UpdateShouldCallSQ', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_updateShouldCallSQFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_updateShouldCallSQSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_updateShouldCallSQFail())
        return {success: false, message: rs.data.error.message}
    }
}
const UPDATE_SHOULD_CALL_SQ_IS_LOADING='UPDATE_SHOULD_CALL_SQ_IS_LOADING'
const UPDATE_SHOULD_CALL_SQ_SUCCESS='UPDATE_SHOULD_CALL_SQ_SUCCESS'
const UPDATE_SHOULD_CALL_SQ_FAIL='UPDATE_SHOULD_CALL_SQ_FAIL'
const _updateShouldCallSQIsLoading = () => ({
    type: UPDATE_SHOULD_CALL_SQ_IS_LOADING
})
const _updateShouldCallSQSuccess = rs => ({
    type: UPDATE_SHOULD_CALL_SQ_SUCCESS,
    rs
})
const _updateShouldCallSQFail = () => ({
    type: UPDATE_SHOULD_CALL_SQ_FAIL
})

const initState = {
    getRolesIsLoading: false,
    roles: [],
    getPermissionsIsLoading: false,
    permissions: [],
    getUsersIsLoading: false,
    users: [],
    deleteUserIsLoading: false,
    getShouldCallSQIsLoading: false,
    shouldCallSQ: false,
    updateShouldCallSQIsLoading: false
}

const systemDataDuck = (state = initState, action) => {
    let newPermissions

    switch (action.type) {
        case GET_ROLES_IS_LOADING:
            return {...state, getRolesIsLoading: true}

        case GET_ROLES_SUCCESS:
            return {...state, getRolesIsLoading: false, roles: action.rs.data.result.items}

        case GET_ROLES_FAIL:
            return {...state, getRolesIsLoading: false}

        case GET_PERMISSIONS_IS_LOADING:
            return {...state, getPermissionsIsLoading: true}

        case GET_PERMISSIONS_SUCCESS:
            newPermissions = {...state.permissions}
            action.rs.data.result.items.forEach(i => {
                newPermissions[i.name] = false
            })
            return {...state, getPermissionsIsLoading: false, permissions: newPermissions}

        case GET_PERMISSIONS_FAIL:
            return {...state, getPermissionsIsLoading: false}

        case GET_USERS_IS_LOADING:
            return {...state, getUsersIsLoading: true}

        case GET_USERS_SUCCESS:
            return {...state, getUsersIsLoading: false, users: action.rs.data.result.items}

        case GET_USERS_FAIL:
            return {...state, getUsersIsLoading: false}

        case DELETE_USER_IS_LOADING:
            return {...state, deleteUserIsLoading: true}

        case DELETE_USER_SUCCESS:
            return {...state, deleteUserIsLoading: false}

        case DELETE_USER_FAIL:
            return {...state, deleteUserIsLoading: false}

        case UPDATE_USER_IS_LOADING:
            return {...state, updateUserIsLoading: true}

        case UPDATE_USER_SUCCESS:
            return {...state, updateUserIsLoading: false}

        case UPDATE_USER_FAIL:
            return {...state, updateUserIsLoading: false}

        case GET_ALL_ROLES_IS_LOADING:
            return {...state, getAllRolesIsLoading: true}

        case GET_ALL_ROLES_SUCCESS:
            return {...state, getAllRolesIsLoading: false, roles: action.rs.data.result.items}

        case GET_ALL_ROLES_FAIL:
            return {...state, getAllRolesIsLoading: false}

        case GET_SHOULD_CALL_SQ_IS_LOADING:
            return {...state, getShouldCallSQIsLoading: true}

        case GET_SHOULD_CALL_SQ_SUCCESS:
            return {...state, getShouldCallSQIsLoading: false, shouldCallSQ: action.rs.data.result}

        case GET_SHOULD_CALL_SQ_FAIL:
            return {...state, getShouldCallSQIsLoading: false}

        case UPDATE_SHOULD_CALL_SQ_IS_LOADING:
            return {...state, updateShouldCallSQIsLoading: true}

        case UPDATE_SHOULD_CALL_SQ_SUCCESS:
            return {...state, updateShouldCallSQIsLoading: false}

        case UPDATE_SHOULD_CALL_SQ_FAIL:
            return {...state, updateShouldCallSQIsLoading: false}

        default:
            return state
    }
}

export default systemDataDuck
