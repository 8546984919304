import React from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from '@material-ui/core';
import {
    uploadGroupManifest,
    updateGroupDetails,
    getCampaignCodes
} from '../../ducks/groupManifestDuck.js';
import GeneralDropzone from '../../components/generalForm/GeneralDropzone.js';
import GeneralFormColumnsContainer from '../../components/generalForm/GeneralFormColumns';
import * as cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import StyledSnackbar from '../../components/StyledSnackbar';
import { USER_POS } from '../../constants/localStorageType';
import {
    hasCreatorPermissions,
    hasApproverPermissions
} from '../../helpers/utilities';

class GroupSubmissionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            selectedFile: {},
            groupDetails: [],
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            isLoading: false,
            showRedirectDialog: false
        };
    }

    componentDidMount = async () => {
        let userPos = localStorage.getItem(USER_POS);
        if (userPos.length === 0) userPos = 'SIN';
        this.handleGroupDetailsChange('pos', userPos);

        const rs = await this.props.getCampaignCodes();
        this.setState({
            groupDetails: [
                {
                    key: 'collectionDate',
                    label: 'Collection Date*',
                    type: 'general-text-date'
                },
                { key: 'pos', label: 'POS*', type: 'text', disabled: true },
                {
                    key: 'lastFlightDepartureDate',
                    label: 'Return Flight Departure Date*',
                    type: 'general-text-date'
                },
                { key: 'tourCompany', label: 'Tour Company*', type: 'text' },
                {
                    key: 'campaignCode',
                    label: 'Campaign Code*',
                    type: 'select',
                    options: (rs && rs.result) || []
                }
            ]
        });
    };

    downloadManifest = () => {
        window.location = '/files/ctp_group_manifest.xlsx';
    };

    onDrop = (acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length === 1) {
            this.setState({
                selectedFile: acceptedFiles[0],
                loaded: 0
            });
        }
    };

    _validateForm = ({
        collectionDate,
        pos,
        lastFlightDepartureDate,
        tourCompany,
        campaignCode
    }) => {
        if (!collectionDate) {
            this._showSnackBarError('Please fill in collection date');
            return false;
        }
        if (!pos) {
            this._showSnackBarError('Please fill in pos');
            return false;
        }
        if (!campaignCode) {
            this._showSnackBarError('Please fill in campaign code');
            return false;
        }

        if (!lastFlightDepartureDate) {
            this._showSnackBarError(
                'Please fill in return flight departure date'
            );
            return false;
        }
        if (!tourCompany) {
            this._showSnackBarError('Please fill in tour company');
            return false;
        }

        let twoDaysLater = moment().add(2, 'days');
        if (!(hasApproverPermissions() && hasCreatorPermissions())) {
            // approver + creator permission can by pass 72 hours check.
            if (moment(collectionDate).isBefore(twoDaysLater)) {
                this._showSnackBarError(
                    'Collection Date must be more than 72 hours later'
                );
                return false;
            }
        }

        // Validates collection date cannot be after last flight departure, but same day will still go through
        if (moment(collectionDate).isAfter(moment(lastFlightDepartureDate))) {
            this._showSnackBarError(
                'Collection date cannot be after last flight depature date'
            );
            return false;
        }

        return true;
    };

    _showSnackBarError = message => {
        this.setState({
            showSnackbar: true,
            snackbarMessage: message,
            snackbarVariant: 'error'
        });
    };

    onSubmit = async () => {
        let isValidated = this._validateForm(this.props.groupDetails);
        if (isValidated) {
            const rs = await this.props.uploadGroupManifest(
                this.state.selectedFile,
                this.props.groupDetails
            );
            rs.success
                ? this.setState({ showRedirectDialog: true })
                : this.setState({
                      showSnackbar: true,
                      snackbarMessage: rs.message,
                      snackbarVariant: 'error'
                  });
        }
    };

    handleGroupDetailsChange = (key, value) => {
        let groupDetails = cloneDeep(this.props.groupDetails);
        groupDetails[key] = value;
        this.props.updateGroupDetails(groupDetails);
    };

    closeSnackbar = () => {
        this.setState({ showSnackbar: false });
    };

    onYes = () => {
        window.location = '/dashboard';
    };

    onUploadAnotherFile = () => {
        window.location = '/group/group-submission';
    };

    onRedirectDialogClose = () => {
        this.setState({ showRedirectDialog: false });
    };

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.showRedirectDialog}
                    onClose={this.onRedirectDialogClose}
                >
                    <DialogTitle>File uploaded</DialogTitle>
                    <DialogContent>
                        <Typography variant={'body1'}>
                            File verifiction in progress. Status of request will
                            be updated once read.
                            <br />
                            Do you want to be redirected to dashboard?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onYes} color='primary'>
                            Yes
                        </Button>
                        <Button
                            onClick={this.onUploadAnotherFile}
                            color='primary'
                            autoFocus
                        >
                            Upload another file
                        </Button>
                    </DialogActions>
                </Dialog>

                <Grid container spacing={24} direction={'column'}>
                    <Grid item>
                        <Button
                            color='primary'
                            variant={'contained'}
                            onClick={this.downloadManifest}
                        >
                            Download Sample File
                        </Button>
                    </Grid>

                    <Grid item>
                        <GeneralFormColumnsContainer
                            columns={2}
                            formFields={this.state.groupDetails}
                            formValues={this.props.groupDetails}
                            onFieldChange={this.handleGroupDetailsChange}
                        />
                    </Grid>

                    <Grid item>
                        <GeneralDropzone
                            onDrop={this.onDrop}
                            accept={'*,.xlsx'}
                        />

                        {this.state.selectedFile.name && (
                            <Typography color='default'>
                                {this.state.selectedFile.name}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item>
                        <Grid container spacing={24} direction={'row'}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={this.onSubmit}
                                    disabled={
                                        this.state.selectedFile.name == null
                                    }
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <StyledSnackbar
                    open={this.state.showSnackbar}
                    onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage}
                    variant={this.state.snackbarVariant}
                />
            </div>
        );
    }
}

const mapStateToProps = function(state) {
    const { groupManifestDuck } = state;
    const { groupDetails } = groupManifestDuck;
    return {
        groupDetails
    };
};

const mapDispatchToProps = function(dispatch) {
    return {
        uploadGroupManifest: (file, groupDetails) =>
            dispatch(uploadGroupManifest(file, groupDetails)),
        updateGroupDetails: groupDetails =>
            dispatch(updateGroupDetails(groupDetails)),
        getCampaignCodes: () => dispatch(getCampaignCodes())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupSubmissionForm);
