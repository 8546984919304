import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    withStyles,
    Grid,
    TextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core'
import {Warning} from '@material-ui/icons'
import {
    updateUserAccessAccountDuck,
    updateUserAccessAccountPermissions,
    createRole,
    updateRole,
    deleteRole
} from '../ducks/userAccessAccountDuck'
import {getAllRoles, getPermissions} from '../ducks/systemDataDuck'
import Header from '../containers/Header.js'
import AdminSettingsNav from '../containers/adminSettings/AdminSettingsNav'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import StyledTable from '../components/StyledTable'
import UserAccessAccountRightsTable from '../containers/adminSettings/UserAccessAccountRightsTable'
import StyledSection from '../components/StyledSection'
import StyledSnackbar from '../components/StyledSnackbar'
import LoadingContainer from '../containers/loadingContainer'
import axios from 'axios'
import {getCurrentAuthHeader, hasPermissions} from '../helpers/utilities'
import {getSettings} from '../config/config'
import {
    USER_ADMIN_ROLE_READ_PERMISSION, USER_ADMIN_ROLE_CREATE_PERMISSION, USER_ADMIN_ROLE_EDIT_PERMISSION
} from '../constants/permissions'
import GlobalTimeChecker from "../containers/GlobalTimeChecker";
import {checkValidStatusCode} from "../helpers/httpHelper";

let settings = getSettings();
let baseURL = settings.baseURL;

const RIGHT_TABLE_FIELDS = [
    {key: 'seq', label: 'S/N'},
    {key: 'displayName', label: 'Role Name'}
]

class UserAccessAccount extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            showDialog: false,
            roleNameFieldError: '',
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            editing: {},
            deletingRole: {},
            showDeleteDialog: false,
            getRoleForEditIsLoading: false
        }
    }

    componentDidMount = async () => {
        document.title = `${TITLE_PREFIX} Admin settings - User Access Account`
        this.props.getAllRoles()
        const rs = await this.props.getPermissions()
        if (rs.success) {
            const permissions = {}
            for (let k in this.props.systemDataDuck.permissions) {
                permissions[k] = false
            }
            this.props.updateUserAccessAccountDuck('permissions', {...permissions})
        }
    }

    onPermissionsSwitch = k => {
        this.props.updateUserAccessAccountPermissions(k, !this.props.userAccessAccountDuck.permissions[k])
    }

    onRightsEdit = async row => {
        this.setState({getRoleForEditIsLoading: true})

        // calls api to get granted permissions.
        const data = {params: {Id: row.id}, ...getCurrentAuthHeader()}
        const rs = await axios.get(baseURL + '/Role/GetRoleForEdit', data).catch(e => {
            if (!checkValidStatusCode(e.response.status )) {
                const {response: {data: {error: {message}}}} = e
                return {data: {success: false, error: {message}}}
            }
            return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
        })
        if (!rs.data) {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'No data from server'})
        }
        if (rs.data.success) {
            const {grantedPermissionNames} = rs.data.result

            const permissions = {}
            for (let k in this.props.systemDataDuck.permissions) {
                permissions[k] = !!grantedPermissionNames.includes(k)
            }

            this.setState({showDialog: true, editing: {...row, permissions}, getRoleForEditIsLoading: false})
        } else {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.data.error.message})
        }
    }

    onRightsDelete = row => {
        this.setState({showDeleteDialog: true, deletingRole: row})
    }

    onEditCancel = () => {
        this.setState({showDialog: false, editing: {}})
    }

    onEditSave = async () => {
        const rs = await this.props.updateRole(this.state.editing)
        this.setState({showDialog: false, editing: {}})
        if (rs.success) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'success',
                snackbarMessage: 'Role updated',
                showDialog: false,
                editing: {}
            })
            this.props.getAllRoles()
        } else {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: rs.message,
                showDialog: false,
                editing: {}
            })
        }
    }

    onSubmit = async () => {
        if (this._validate()) {
            const rs = await this.props.createRole(this.props.userAccessAccountDuck)
            if (rs.success) {
                this.setState({showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'Role created'})
                this.props.getAllRoles()
            } else {
                this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
            }
        }
    }

    _validate() {
        if (this.props.userAccessAccountDuck.roleNameField === '') {
            this.setState({roleNameFieldError: 'This field is required'})
            return false
        }
        return true
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    onSwitch = (k) => {
        this.setState({
            editing: {
                ...this.state.editing,
                permissions: {...this.state.editing.permissions, [k]: !this.state.editing.permissions[k]}
            }
        })
    }

    onDeleteCancel = () => {
        this.setState({showDeleteDialog: false, deletingRole: {}})
    }

    onDeleteConfirm = async () => {
        const rs = await this.props.deleteRole(this.state.deletingRole.id)

        if (rs.success) {
            this.props.getAllRoles()
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'success',
                snackbarMessage: 'Role deleted',
                showDeleteDialog: false
            })
        } else {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: rs.message,
                showDeleteDialog: false
            })
        }
    }

    _removeAdminRole = roles => {
        return roles.filter(r => r.name !== 'Admin')
    }

    render() {
        const {classes, userAccessAccountDuck, systemDataDuck} = this.props
        const {deletingRole} = this.state
        const permissionsLoaded = Object.keys(userAccessAccountDuck.permissions).length > 0

        const hasCreatePermission = hasPermissions([USER_ADMIN_ROLE_CREATE_PERMISSION])
        const hasEditPermission = hasPermissions([USER_ADMIN_ROLE_EDIT_PERMISSION])
        const hasReadPermission = hasPermissions([USER_ADMIN_ROLE_READ_PERMISSION]) || hasEditPermission || hasCreatePermission

        return (
            <div>
                <GlobalTimeChecker/>

                <Header history={this.props.history}/>
                <AdminSettingsNav/>

                {/*{<editor-fold desc="Alert Dialog">}*/}
                {Object.keys(deletingRole).length > 0 &&
                <Dialog open={this.state.showDeleteDialog} onClose={this.onDeleteCancel}>
                    <DialogTitle>Proceed to delete the following role:</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item container spacing={24}>
                                <Grid item md={3}>
                                    <Typography variant={'body1'}> Role: </Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography variant={'body1'}>{deletingRole.name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'} color={'secondary'}>
                                    <Warning className={classes.icon}/> This process cannot be reversed.
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color={'default'} variant='text' onClick={this.onDeleteCancel}>Cancel</Button>
                        <Button color={'secondary'} variant='contained' onClick={this.onDeleteConfirm}>Yes,
                            Delete</Button>
                    </DialogActions>
                </Dialog>}
                {/*{</editor-fold>}*/}

                <Dialog open={this.state.showDialog} scroll={'body'} onClose={this.onEditCancel}>
                    <DialogTitle>Editing {this.state.editing.name}</DialogTitle>
                    {Object.keys(this.state.editing).length > 0 && <DialogContent>
                        <UserAccessAccountRightsTable
                            data={this.state.editing.permissions}
                            onSwitch={this.onSwitch}
                        />
                        {userAccessAccountDuck.updateRoleIsLoading && <LoadingContainer show={true}/>}
                    </DialogContent>}
                    <DialogActions>
                        <Button color={'default'} variant='text' onClick={this.onEditCancel}>Cancel</Button>
                        <Button color={'primary'} variant='contained' onClick={this.onEditSave}>Save</Button>
                    </DialogActions>
                </Dialog>

                <div className={classes.container}>
                    {hasReadPermission && <Grid container spacing={24} direction={'column'}>
                        <Grid item>
                            <StyledSection title={'User Roles'} isLoading={systemDataDuck.getAllRolesIsLoading}>
                                {systemDataDuck.roles.length > 0 && <StyledTable
                                    fields={RIGHT_TABLE_FIELDS}
                                    rows={this._removeAdminRole(systemDataDuck.roles)}
                                    isDeletable={hasEditPermission}
                                    isEditable={hasEditPermission}
                                    onEdit={this.onRightsEdit}
                                    onDelete={this.onRightsDelete}
                                />}
                            </StyledSection>
                        </Grid>

                        {hasCreatePermission && <Grid item>
                            <StyledSection title={'Create User Rights'}>
                                <Grid container spacing={24} direction={'column'}>
                                    <Grid item md={6}>
                                        <TextField id="roleName" label="Enter Role Name*" margin="normal"
                                            helperText={this.state.roleNameFieldError}
                                            error={this.state.roleNameFieldError !== ''}
                                            variant="outlined"
                                            placeholder={'Role name'} fullWidth
                                            value={userAccessAccountDuck.roleNameField}
                                            onChange={e => this.props.updateUserAccessAccountDuck('roleNameField', e.target.value)}/>
                                    </Grid>

                                    {permissionsLoaded &&
                                    <UserAccessAccountRightsTable
                                        data={userAccessAccountDuck.permissions}
                                        onSwitch={this.onPermissionsSwitch}/>}

                                    <Grid item>
                                        <Button color={'primary'} variant="contained"
                                            onClick={this.onSubmit}>Submit</Button>
                                    </Grid>
                                </Grid>
                            </StyledSection>
                        </Grid>}
                    </Grid>}
                </div>
                <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

const mapStateToProps = state => {
    const {userAccessAccountDuck, systemDataDuck} = state

    return {userAccessAccountDuck, systemDataDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        updateUserAccessAccountDuck: (k, v) => dispatch(updateUserAccessAccountDuck(k, v)),
        getPermissions: () => dispatch(getPermissions()),
        getAllRoles: () => dispatch(getAllRoles()),
        updateRole: fields => dispatch(updateRole(fields)),
        updateUserAccessAccountPermissions: (k, v) => dispatch(updateUserAccessAccountPermissions(k, v)),
        createRole: fields => dispatch(createRole(fields)),
        deleteRole: Id => dispatch(deleteRole(Id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserAccessAccount))