import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles, Grid, Button, TextField, Typography } from '@material-ui/core'
import * as cloneDeep from 'lodash/cloneDeep'
import StyledSection from '../../components/StyledSection'
import StyledSnackbar from '../../components/StyledSnackbar'
import { REQUIRED_ERROR_MSG, ETICKET_ELIGIBILITY_ERROR_MSG } from '../../constants/messages'
import StyledTinyFabCheckCircle from '../../components/StyledTinyFabCheckCircle'
import StyledTinyFabError from '../../components/StyledTinyFabError'
import BoardingPassParser from '../../helpers/boardingPassParser'
import SqApiCriteriasDialog from '../../components/SqApiCriteriasDialog'
import StyledTinyErrorCross from '../../components/StyledTinyErrorCross'
import StyledGeneralDialog from '../../components/StyledGeneralDialog'
import { hasPermissions } from '../../helpers/utilities'
import { convertSqStatusForDisplay, DISPLAY_YES, DISPLAY_MANUAL } from '../../helpers/eticketStatusHelper'
import { updateEicketAppealTransaction } from '../../ducks/appealTransactionDuck'
import {
	checkAndUpdateEligibility,
	findPassengerDataWithCardNo,
	findPassengerInGroup,
	findPassengersDataWithAppealTransaction,
	copyFormDetailsToCurrentPax
} from '../../ducks/individualFormDuck.js'
import { APPEAL_EDITOR, APPEAL_APPROVER, APPEAL_SUPER_APPROVER } from '../../constants/permissions'

const E_TIX_1_KEY = 'eTicket1'
const E_TIX_2_KEY = 'eTicket2'
const E_TIX_3_KEY = 'eTicket3'
const E_TIX_4_KEY = 'eTicket4'

// generalDialogTitle
const PASSENGER_HAS_EXISTING_CARD = 'Existing card found'
const PASSENGER_HAS_EXISTING_GROUP = 'Existing group found'
const PASSENGER_HAS_EXISTING_DRAFT = 'Existing draft found'
const PASSENGER_HAS_EXISTING_APPEAL = 'Existing appeal found'

class AppealETicketForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			eTixErrorStore: {
				[E_TIX_1_KEY]: '',
				[E_TIX_2_KEY]: '',
				[E_TIX_3_KEY]: '',
				[E_TIX_4_KEY]: '',
			},
			eTix: {
				eTicket1: '',
				eTicket2: '',
				eTicket3: '',
				eTicket4: '',
			},
			eTixBackUp: {
				eTicket1: '',
				eTicket2: '',
				eTicket3: '',
				eTicket4: '',
			},
			flightFormFields: [
				{ key: [E_TIX_1_KEY], label: 'E-Ticket No. 1*', type: 'text', customButton: true },
				{ key: [E_TIX_2_KEY], label: 'E-Ticket No. 2', type: 'text', customButton: true },
				{ key: [E_TIX_3_KEY], label: 'E-Ticket No. 3', type: 'text', customButton: true },
				{ key: [E_TIX_4_KEY], label: 'E-Ticket No. 4', type: 'text', customButton: true },
			],
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
			viewCriterias: false,
			criteriasMessage: '',
			criterias: {},
			showEticketField: 0,
			eTicketIsYes: false,
			showGeneralDialog: false,
			generalDialogTitle: '',
			generalDialogActionText: 'Yes',
			pretravelId:'',
			isUpdateETicket: false,
			isCheckEligibilitySuccess: false
		}

		this.eTicketRefs = []
		this.inputTimeout = null
		this.popPropsETixTimeout = null
	}

	componentDidUpdate = (prevProps, prevState) => {
	}

	componentDidMount() {
		
	}

	_checkApprovedTicketNo = (eTixNo) => {
		if (!eTixNo) {
			return false
		}

		let subString = eTixNo.substr(0, 3)
		if (
			(subString !== '618' && subString !== '629' && subString !== '086' && subString !== '081' && subString !== '000')
		) {
			return false
		}

		return true
	}

	handleETicketsDetailsChange = (key, value) => {
		this._updateStateETicketDetails(key, value)

		clearTimeout(this.inputTimeout)
		this.inputTimeout = setTimeout(() => {
			if (value.length >= 90) {
				this._processScanTicket(key, value)
			}
		}, 1000)
	}

	_processScanTicket = (key, value) => {
		if (value.length < 90) return
		let boardingPassParser = new BoardingPassParser(value)
		let bpData = boardingPassParser.getData()
		if (
			bpData.eTix === this.props.passenger[E_TIX_1_KEY] ||
			bpData.eTix === this.props.passenger[E_TIX_2_KEY] ||
			bpData.eTix === this.props.passenger[E_TIX_3_KEY]
		) {
			this.setState({ showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'Same E Ticket Number' })
			this._updateStateETicketDetails(key, '')
			return
		}
		this._updateStateETicketDetails(key, bpData.eTix)
		this.props.updateBoardingPassDetails({ eTicketKey: key, boardingPassData: bpData })
	}

	_loadStateEticketDetails(pax) {
		let stateETix = {
			eTicket1: '',
			eTicket2: '',
			eTicket3: '',
			eTicket4: '',
		}
		pax.eTicket.forEach((obj, i) => {
			if (obj.eTicketNo) {
				let key = 'eTicket' + (i + 1)
				stateETix[key] = obj.eTicketNo
			}
		})
		this.setState({ eTix: stateETix, eTixBackUp: stateETix }) // keep 1 back up version to compare later
	}

	_updateStateETicketDetails(key, value) {
		let stateETix = { ...this.state.eTix }
		stateETix[key] = value
		this.setState({ eTix: stateETix })
		this._isCheckEligibilityDisable(stateETix)
	}

	_isCheckEligibilityDisable(stateETix) {
		const { eTixBackUp } = this.state
		let flag = false
		for (let i = 1; i < 4; i++) {
			let key = 'eTicket' + (i + 1)
			if (stateETix[key] !== eTixBackUp[key]) {
				flag = true
				this.setState({ isUpdateETicket: true, isCheckEligibilitySuccess: false })
				break
			}
		}
		if (!flag) {
			this.setState({ isUpdateETicket: false, isCheckEligibilitySuccess: false })
		}
	}

	confirmTickets = (tickets) => {
		tickets.forEach((ticket, i) => {
			this.handleETicketsDetailsChange(`eTicket${i + 1}`, ticket)
		})
	}

	_checkEligibility = async () => {
		const { passenger, editMode } = this.props
		const { eTix, eTixBackUp } = this.state

		// map eTix (state) to passenger
		passenger.eTicket.splice(1)
		for (let i = 1; i < 4; i++) {
			let key = 'eTicket' + (i + 1)
			// set default info as 1st eTicket
			if (eTix[key] && eTix[key] !== '') {
				let newEticket = cloneDeep(passenger.eTicket[0])
				newEticket.eTicketNo = eTix[key]
				passenger[key] = eTix[key]
				passenger.eTicket.push(newEticket)
			}
		}

		if (!passenger.eTicket[0]) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: `Please check ETicket is valid` })
			return
		}

		// if (!this._validatePreviousPaxETixDuplicate(this.props.individualFormDuck.passenger, this.props.individualFormDuck.formDetailsList)) {
		// 	this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: `Please ensure no E Tickets duplicate` })
		// 	return
		// }

		if (this._validateEligibility(passenger)) {
			const ticketDetails = []
			let eTixKeys = [E_TIX_1_KEY, E_TIX_2_KEY, E_TIX_3_KEY, E_TIX_4_KEY]
			let checkDuplicate = []
			let hasDuplicates = false
			for (let eTixKey of eTixKeys) {
				if (passenger[eTixKey]) {
					// validate for 13 numberic numbers
					if (!/^[0-9][0-9]{12}$/.test(passenger[eTixKey])) {
						this.setState({
							showSnackbar: true,
							snackbarVariant: 'error',
							snackbarMessage: `ETicket has be to 13 numberic numbers (${passenger[eTixKey]})`,
						})
						return
					}
					if (!checkDuplicate.includes(passenger[eTixKey])) {
						ticketDetails.push({
							ticketNumber: passenger[eTixKey],
							lastName: passenger.eTicket[eTixKey + 'LastName'],
						})
					} else {
						hasDuplicates = true
						this.setState({
							showSnackbar: true,
							snackbarVariant: 'error',
							snackbarMessage: 'Please fix duplicate eTicket: ' + passenger[eTixKey],
						})
						break
					}
				}
				checkDuplicate.push(passenger[eTixKey])
			}
			if (hasDuplicates) return

			const { eTixBackUp } = this.state
			let eTickets = []
			if (passenger[E_TIX_2_KEY] && passenger[E_TIX_2_KEY] !== eTixBackUp[E_TIX_2_KEY]) eTickets.push(passenger[E_TIX_2_KEY])
			if (passenger[E_TIX_3_KEY] && passenger[E_TIX_3_KEY] !== eTixBackUp[E_TIX_3_KEY]) eTickets.push(passenger[E_TIX_3_KEY])
			if (passenger[E_TIX_4_KEY] && passenger[E_TIX_4_KEY] !== eTixBackUp[E_TIX_4_KEY]) eTickets.push(passenger[E_TIX_4_KEY])

			const checkCardData = { eTickets: eTickets }

			const rs = await this.props.findPassengerDataWithCardNo(checkCardData)
			if (!rs.success) this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
			if (rs.success) {
				const {
					individualFormDuck: {
						passengerDataWithCardNo: { group, redemptionTransaction },
					},
				} = this.props

				if ((Object.keys(group).length > 0 || Object.keys(redemptionTransaction).length > 0) && !editMode) {
					return this.setState({ generalDialogTitle: PASSENGER_HAS_EXISTING_CARD, showGeneralDialog: true, generalDialogActionText: '' })
				}

				/// check appeal
				const checkAppealResponse = await this.props.findPassengersDataWithAppealTransaction(checkCardData);
				if (!checkAppealResponse.success) {
					this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
					return;
				}
				if(checkAppealResponse.success){
					if(checkAppealResponse.result.items.length > 0) {
						this.setState({
							generalDialogTitle: PASSENGER_HAS_EXISTING_APPEAL,
							showGeneralDialog: true,
							generalDialogActionText: '',
							pretravelId: checkAppealResponse.result.items[0].appealTransactionId
						})
						return;
					}
				}

				// check sq api
				this._checkSqApi()
			}
		}
	}

	_validateEligibility(passenger) {
		let rs = true
		if (passenger.eTicket[0].eTicketNo === '' || passenger.eTicket[0].eTicketNo === undefined) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: `Please check ETicket 1 is valid` })
			return
		} else {
			if (!this._checkApprovedTicketNo(passenger.eTicket[0].eTicketNo)) {
				this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: ETICKET_ELIGIBILITY_ERROR_MSG })
				return
			}
		}

		return rs
	}

	_validatePreviousPaxETixDuplicate(currentPax, existingList) {
		let eTixObj = currentPax.eTicketsDetails

		for (let i = 0; i < existingList.length; i++) {
			if (currentPax.pax === existingList[i].pax) {
				continue
			}
			if (eTixObj.eTicket4) {
				if (
					eTixObj.eTicket4 === existingList[i].eTicketsDetails.eTicket1 ||
					eTixObj.eTicket4 === existingList[i].eTicketsDetails.eTicket2 ||
					eTixObj.eTicket4 === existingList[i].eTicketsDetails.eTicket3 ||
					eTixObj.eTicket4 === existingList[i].eTicketsDetails.eTicket4
				) {
					return false
				}
			}

			if (eTixObj.eTicket3) {
				if (
					eTixObj.eTicket3 === existingList[i].eTicketsDetails.eTicket1 ||
					eTixObj.eTicket3 === existingList[i].eTicketsDetails.eTicket2 ||
					eTixObj.eTicket3 === existingList[i].eTicketsDetails.eTicket3 ||
					eTixObj.eTicket3 === existingList[i].eTicketsDetails.Ticket4
				) {
					return false
				}
			}

			if (eTixObj.eTicket2) {
				if (
					eTixObj.eTicket2 === existingList[i].eTicketsDetails.eTicket1 ||
					eTixObj.eTicket2 === existingList[i].eTicketsDetails.eTicket2 ||
					eTixObj.eTicket2 === existingList[i].eTicketsDetails.eTicket3 ||
					eTixObj.eTicket2 === existingList[i].eTicketsDetails.eTicket4
				) {
					return false
				}
			}

			if (eTixObj.eTicket1) {
				if (
					eTixObj.eTicket1 === existingList[i].eTicketsDetails.eTicket1 ||
					eTixObj.eTicket1 === existingList[i].eTicketsDetails.eTicket2 ||
					eTixObj.eTicket1 === existingList[i].eTicketsDetails.eTicket3 ||
					eTixObj.eTicket1 === existingList[i].eTicketsDetails.eTicket4
				) {
					return false
				}
			}
		}

		return true
	}

	_saveEticket = async () => {
		const {
			passenger,
			individualFormDuck: {
				currentPaxFormDetails
			},
		} = this.props;
		const { eTix } = this.state

		const eTicketsKeys = [E_TIX_2_KEY, E_TIX_3_KEY, E_TIX_4_KEY]
		let eTicketNumbers = []
		for (let eTixKey of eTicketsKeys) {
			if (eTix[eTixKey]) {
				eTicketNumbers.push(eTix[eTixKey])
			}
		}

		const data = { passengerId: currentPaxFormDetails.id, eTicketNumbers: eTicketNumbers }
		const rs = await this.props.updateEicketAppealTransaction(data)
        if (rs.success) {
            // No need to send auditLogs on first request
            this.setState({
                showSnackbar: true,
                snackbarMessage: 'Success',
                snackbarVariant: 'success',
                parentDidSumit: false,
				eventName: 'updateEticket',
				eTix: eTix,
				eTixBackUp: eTix,
				isUpdateETicket: false,
				isCheckEligibilitySuccess: false
            })
        } else {
            this.setState({
                showSnackbar: true,
                snackbarMessage: rs.message,
                snackbarVariant: 'error',
                parentDidSumit: false,
                eventName: 'updateEticket'
            })
        }
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	_renderNotiIcon = (ticketNumber) => {
		const { isCheckEligibilitySuccess } = this.state
		const {
			individualFormDuck: {
				currentPaxFormDetails
			},
		} = this.props;

		if (isCheckEligibilitySuccess) {
			let ticketDetails = currentPaxFormDetails.ticketDetails

			if (!ticketDetails || ticketNumber === '') return null

			const d = ticketDetails.find((d) => d.ticketNumber === ticketNumber)
			if (d === undefined) return null

			if (convertSqStatusForDisplay(d.ctpEligibility) === DISPLAY_YES) {
				if (this.state.eTicketIsYes !== true) this.setState({ eTicketIsYes: true })
				return <StyledTinyFabCheckCircle onClick={() => this.onShowEligibilityPopup(d)} />
			} else if (convertSqStatusForDisplay(d.ctpEligibility) === DISPLAY_MANUAL) {
				return <StyledTinyFabError yellow onClick={() => this.onShowEligibilityPopup(d)} />
			} else {
				return <StyledTinyErrorCross onClick={() => this.onShowEligibilityPopup(d)} />
			}
		}
		return null
	}

	onShowEligibilityPopup = (rs) => {
		if (rs.criterias) {
			this.setState({ viewCriterias: true, criterias: rs.criterias, criteriasMessage: '' })
		} else {
			rs.remarks
				? this.setState({ viewCriterias: true, criterias: {}, criteriasMessage: rs.remarks })
				: this.setState({ viewCriterias: true, criterias: {}, criteriasMessage: 'ETicket not found in database' })
		}
	}

	onViewCriteriasClose = () => {
		this.setState({ viewCriterias: false })
	}

	onDialogCancel = async () => {
		this.setState({ showGeneralDialog: false })
	}

	_checkSqApi = async () => {
		this.setState({ showGeneralDialog: false })
		const { eTixBackUp } = this.state
		const { passenger } = this.props
		const ticketDetails = []
		let eTixKeys = [E_TIX_2_KEY, E_TIX_3_KEY, E_TIX_4_KEY]
		for (let eTixKey of eTixKeys) {
			if (passenger[eTixKey] && passenger[eTixKey] !== eTixBackUp[eTixKey]) {
				ticketDetails.push({
					ticketNumber: passenger[eTixKey],
					lastName: passenger.lastName,
				})
			}
		}
		if (ticketDetails.length > 0) {
			const rs = await this.props.checkAndUpdateEligibility({ ticketDetails: ticketDetails, redemptionTransactionId: null })
			if (!rs.success) {
				this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message })
			} else {
				this._isCheckEligibilitySuccess()
			}
		} else {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Need have atleast 1 E-Ticket Number except E-Ticket No.1' })
		}
	}

	_isCheckEligibilitySuccess() {
		const {
			individualFormDuck: {
				currentPaxFormDetails
			},
		} = this.props;

		let ticketDetails = currentPaxFormDetails.ticketDetails

		if (!ticketDetails){
			this.setState({ isCheckEligibilitySuccess: false })
		} else {
			let flag = true
			for (let ticketDetail of ticketDetails) {
				if (convertSqStatusForDisplay(ticketDetail.ctpEligibility) !== DISPLAY_YES
					&& convertSqStatusForDisplay(ticketDetail.ctpEligibility) !== DISPLAY_MANUAL) {
					flag = false
				}
			}
			this.setState({ isCheckEligibilitySuccess: flag })
		}
	}

	onLinkClick = async (id) => {
        const url = '/appeal-transaction-detail/'
		window.open(url + id)
	}

	render() {
		const {
			classes, innerRef, editMode, editModeClass,
			passenger,
			checkAndUpdateEligibilityIsLoading,
			individualFormDuck: {
				currentPaxFormDetails,
				findPassengerDataWithCardNoIsLoading,
				findPassengerInGroupIsLoading,
                findPassengerWithAppealIsLoading,
			},
		} = this.props;
		const eTicketsKeys = [E_TIX_1_KEY, E_TIX_2_KEY, E_TIX_3_KEY, E_TIX_4_KEY]
		const { eTicketIsYes, showGeneralDialog, showEticketField, generalDialogTitle, generalDialogActionText, eTix, isUpdateETicket, isCheckEligibilitySuccess } = this.state

		const hasEditorPermissions = hasPermissions([APPEAL_EDITOR], 'or')
		const isLoading =
			checkAndUpdateEligibilityIsLoading ||
			findPassengerDataWithCardNoIsLoading ||
			findPassengerInGroupIsLoading ||
			findPassengerWithAppealIsLoading
		
		// update eTicket from passenger to eTix state
		if (!this.state.eTix['eTicket1'] || passenger.eTicket[0].eTicketNo !== this.state.eTix['eTicket1']) {
			if (passenger.eTicket[0].eTicketNo) {
				this._loadStateEticketDetails(passenger)
			}
		}

		let onConfirm = function() {}
		let onClose = function() {}
		switch (generalDialogTitle) {
			case PASSENGER_HAS_EXISTING_GROUP:
				onConfirm = () => {
					this.setState({ showGeneralDialog: false }, async () => {
						await this.props.populatePassengerFromGroup()
						await this.props.updatePromptedRetrieveDraft(true)
						this._checkSqApi()
					})
				}
				onClose = () => {
					this._checkSqApi()
				}
				break
            case PASSENGER_HAS_EXISTING_APPEAL:
                onConfirm = () => {

                }
                onClose = () => {
                    this.setState({ showGeneralDialog: false })
                }
                break
			case PASSENGER_HAS_EXISTING_DRAFT:
				onConfirm = () => {
					this.setState({ showGeneralDialog: false }, async () => {
						// populate data
						await this.props.replaceDraft()

						const { formDetailsList } = this.props
						if (formDetailsList.length > 0) {
							const eTicketFilter = []
							formDetailsList.forEach((p) => {
								if (p.eTicketsDetails['eTicket1'] !== '') {
									eTicketFilter.push(p.eTicketsDetails['eTicket1'])
								}
							})
							const data = { eTicketFilter }
							this.props.individualGroupCheckRedeemedPassenger(data)
						}
						this.props.updateRedemptionTransaction(this.props.draftRedemptionTransaction)
					})
				}
				onClose = () => {
					this._checkSqApi()
				}
				break
			default:
				break
		}

		return (
			<div>
				<StyledGeneralDialog showDialog={showGeneralDialog} title={generalDialogTitle} onClose={onClose} onAction={onConfirm} actionText={generalDialogActionText}>
					{generalDialogTitle === PASSENGER_HAS_EXISTING_APPEAL && (
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item>
								<Typography>There is an existing pretravel appeal for this passenger.&nbsp;
									<span className={classes.clickable} onClick={() => this.onLinkClick(this.state.pretravelId)}>
									 Click here.
									</span>
								</Typography>
							</Grid>
                        </Grid>
                    )}
				</StyledGeneralDialog>

				<SqApiCriteriasDialog
					criterias={this.state.criterias}
					criteriasMessage={this.state.criteriasMessage}
					onViewCriteriasClose={this.onViewCriteriasClose}
					viewCriterias={this.state.viewCriterias}
				/>

				<StyledSection title={'E-Ticket Details'} isLoading={isLoading} paperClass={editMode ? editModeClass : ''}>
					<Grid container spacing={24} direction={'column'}>
						<Grid item>
							{eTicketsKeys.map((k, i) => (
								<div key={k}>
									{(passenger.eTicket[k] !== '' || i === 0 || showEticketField >= i) && (
										<div className={classes.row} key={k}>
											<div className={classes.rowItem}>
												<div className={classes.label}>
													E-Ticket No. {i + 1} {i === 0 && <span>*</span>}
												</div>
												<div className={classes.control}>
													<div className={classes.tfWrap}>
														<TextField
															disabled={i === 0}
															id={'eTicket' + i}
															variant='outlined'
															className={this.state.eTixErrorStore[k] ? classes.textfieldError : classes.textfield}
															value={eTix[k]}
															error={!!this.state.eTixErrorStore[k]}
															onChange={(e) => this.handleETicketsDetailsChange(k, e.target.value)}
														/>
														{this._renderNotiIcon(eTix[k])}
													</div>
												</div>
											</div>
											<div className={classes.rowItem}>
												{i === 0 &&
													<div className={classes.rowItem}>
														<div className={classes.label}>
															Last Name *
														</div>
														<div className={classes.control}>
															<TextField
																id='lastName'
																variant='outlined'
																className={classes.textfield}
																value={passenger.eTicket[i] ? passenger.eTicket[i].lastName : ''}
																disabled={true}
															/>
														</div>
													</div>
												}
											</div>
										</div>
									)}
								</div>
							))}
						</Grid>
						{(hasEditorPermissions && (<Grid container item spacing={24}>
							<Grid item>
								<Button variant='contained' color='primary' onClick={this._checkEligibility} disabled={!isUpdateETicket}>
									Check Eligibility
								</Button>
							</Grid>
							<Grid item>
								<Button variant='contained' color='primary' onClick={this._saveEticket} disabled={!isCheckEligibilitySuccess}>
									Save
								</Button>
							</Grid>
						</Grid>))}
					</Grid>
				</StyledSection>

				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
	row: { display: 'flex', marginTop: 20 },
	rowItem: { display: 'flex', flex: 1, marginRight: 60, alignItems: 'center' },
	label: { flex: '2' },
	control: { flex: '3' },
	textfield: { width: '100%' },
	textfieldError: { height: 60, width: '100%' },
	tfWrap: { position: 'relative' },
	clickable: { cursor: 'pointer', borderBottom: '1px dotted #ccc', color: 'blue' },
})

const mapStateToProps = function(state) {
	const { individualFormDuck } = state
	const { checkAndUpdateEligibilityIsLoading } = individualFormDuck
	return {
		individualFormDuck,
		checkAndUpdateEligibilityIsLoading
	}
}

const mapDispatchToProps = function(dispatch) {
	return {
		checkAndUpdateEligibility: ({ ticketDetails, redemptionTransactionId }) => dispatch(checkAndUpdateEligibility({ ticketDetails, redemptionTransactionId })),
		findPassengerDataWithCardNo: (data) => dispatch(findPassengerDataWithCardNo(data)),
		findPassengerInGroup: (data) => dispatch(findPassengerInGroup(data)),
		findPassengersDataWithAppealTransaction: (data) => dispatch(findPassengersDataWithAppealTransaction(data)),
		copyFormDetailsToCurrentPax: (p) => dispatch(copyFormDetailsToCurrentPax(p)),
		updateEicketAppealTransaction: (data) => dispatch(updateEicketAppealTransaction(data)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(AppealETicketForm))
