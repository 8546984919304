import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {hasPermissions} from '../helpers/utilities'
import {SEARCH_GROUP_PERMISSION, SEARCH_PASSENGER_PERMISSION} from '../constants/permissions'

/**
 * This page exists for routing purpose
 */
class SearchScreen extends Component {
    render() {
        if (hasPermissions(SEARCH_PASSENGER_PERMISSION)) {
            return <Redirect to={'/search/passenger'}/>
        }
        if (hasPermissions(SEARCH_GROUP_PERMISSION)) {
            return <Redirect to={'/search/group'}/>
        }
        return <Redirect to={'/not-found'}/>
    }
}

export default SearchScreen