import { LAND, NA, SCOOT, SEA } from '../constants/sector2Options'

export const sector2FormBuilder = ({ flightCompulsory, departureFlightNoList, departureCityList, departureSearchOptions }) => {
	const FLIGHT_NO_LABEL = flightCompulsory ? 'Flight No*' : 'Flight No'
	const DESTINATION_LABEL = flightCompulsory ? 'Destination*' : 'Destination'
	let form = [
		{ key: 'search', label: 'Search', type: 'autocomplete', options: departureSearchOptions },
		{ key: 'flightNo', label: FLIGHT_NO_LABEL, type: 'autocomplete', options: departureFlightNoList, disabled: true },
		{
			key: 'origin',
			label: 'Point Of Origin',
			type: 'autocomplete',
			options: [{ value: 'Singapore', label: 'Singapore' }],
			disabled: true
		},
		{ key: 'departingDate', label: 'Departing Date', type: 'general-text-date' },
		{
			key: 'destination',
			label: DESTINATION_LABEL,
			type: 'autocomplete',
			options: departureCityList,
			disabled: true,
		},
		{
			key: 'landSeaScootBool',
			label: 'Land/Sea/Scoot',
			type: 'radio',
			options: [
				{ value: NA, label: NA },
				{ value: SEA, label: SEA },
				{ value: LAND, label: LAND },
				{ value: SCOOT, label: SCOOT }
			]
		},
		{
			key: 'landSeaRemarks',
			label: 'Land/Sea/Scoot Remarks',
			type: 'multiline',
			displayCondition: { key: 'landSeaScootBool', valueArr: [SEA, LAND, SCOOT] }
		}
	]
	return form
}


export const sector3FormBuilder = ({ arrivalFlightNoList, arrivalCityList, arrivalSearchOptions }) => {

    let form = [
        { key: 'search', label: 'Search', type: 'autocomplete', options: arrivalSearchOptions },
        { key: 'flightNo', label: 'Flight No', type: 'autocomplete', options: arrivalFlightNoList, disabled: true },
        {
            key: 'origin',
            label: 'Point Of Origin',
            type: 'autocomplete',
            options:arrivalCityList,
			disabled: true
        },
        { key: 'departingDate', label: 'Departing Date', type: 'general-text-date' },
        {
            key: 'destination',
            label: 'Destination',
            type: 'autocomplete',
            options:  [{ value: 'Singapore', label: 'Singapore' }],
            disabled: true
        },
        {
            key: 'landSeaScootBool',
            label: 'Land/Sea/Scoot',
            type: 'radio',
            options: [
                { value: NA, label: NA },
                { value: SEA, label: SEA },
                { value: LAND, label: LAND },
                { value: SCOOT, label: SCOOT }
            ]
        },
        {
            key: 'landSeaRemarks',
            label: 'Land/Sea/Scoot Remarks',
            type: 'multiline',
            displayCondition: { key: 'landSeaScootBool', valueArr: [SEA, LAND, SCOOT] }
        }
    ]
    return form
}
