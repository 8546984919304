import React, {PureComponent} from 'react'
import {Fab} from '@material-ui/core'
import {Undo} from '@material-ui/icons'
import PropTypes from 'prop-types'

class StyledUndoBtn extends PureComponent {
    render() {
        return (
            <Fab onClick={this.props.onClick} color={'primary'}
                variant={'round'} size={'small'} {...this.props}><Undo/></Fab>
        )
    }
}

StyledUndoBtn.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default StyledUndoBtn