import axios from 'axios'
import {getSettings} from '../config/config'
import {getCurrentAuthHeader} from '../helpers/utilities'
import querystring from 'querystring'
import {checkValidStatusCode} from "../helpers/httpHelper";

let settings = getSettings();
let baseURL = settings.baseURL;

export const getAllBanner = () => async dispatch => {
    dispatch(_getAllBannerIsLoading())

    const rs = await axios.get(baseURL + '/Banner/GetAllBanner', getCurrentAuthHeader()).catch(e => {
        if (!e.response) {
            return {data: {success: false, error: {message: 'No response from server'}}}
        }
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_getAllBannerFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_getAllBannerSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getAllBannerFail())
        return {success: false, message: rs.data.error.message}
    }
}
const GET_ALL_BANNER_IS_LOADING = 'GET_ALL_BANNER_IS_LOADING'
const GET_ALL_BANNER_SUCCESS = 'GET_ALL_BANNER_SUCCESS'
const GET_ALL_BANNER_FAIL = 'GET_ALL_BANNER_FAIL'

const _getAllBannerIsLoading = () => ({
    type: GET_ALL_BANNER_IS_LOADING
})

const _getAllBannerSuccess = rs => ({
    type: GET_ALL_BANNER_SUCCESS,
    rs
})

const _getAllBannerFail = () => ({
    type: GET_ALL_BANNER_FAIL
})

const UPDATE_SINGLE_BANNER_DETAILS = 'UPDATE_SINGLE_BANNER_DETAILS'
export const updateSingleBannerDetails = (id, k, v) => ({
    type: UPDATE_SINGLE_BANNER_DETAILS,
    id, k, v
})

export const addBanner = (params, formData) => async dispatch => {
    dispatch(_addBannerIsLoading())

    const rs = await axios.post(baseURL + '/File/FileUpload?' + querystring.stringify({bucketType: 2}), formData).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            if(e.response.data && e.response.data.error) {
                const {response: {data: {error: {message}}}} = e
                return {data: {success: false, error: {message}}}
            }
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_addBannerFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        params.uri = rs.data.result[0]
        const rs2 = await axios.post(baseURL + '/Banner/CreateBanner', params, getCurrentAuthHeader()).catch(e => {
            if (!checkValidStatusCode(e.response.status )) {
                if(e.response.data && e.response.data.error) {
                    const {response: {data: {error: { message }}}} = e
                    return {data: {success: false, error: { message }}}
                }
            }
            return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
        })
        if (rs2.data.success) {
            dispatch(_addBannerSuccess(rs))
            return {success: true, message: ''}
        }
        dispatch(_addBannerFail())
        return {success: false, message: rs2.data.error.message}
    } else {
        dispatch(_addBannerFail())
        return {success: false, message: rs.data.error.message}
    }
}

const ADD_BANNER_IS_LOADING = 'ADD_BANNER_IS_LOADING'
const ADD_BANNER_SUCCESS = 'ADD_BANNER_SUCCESS'
const ADD_BANNER_FAIL = 'ADD_BANNER_FAIL'

const _addBannerIsLoading = () => ({
    type: ADD_BANNER_IS_LOADING
})

const _addBannerSuccess = rs => ({
    type: ADD_BANNER_SUCCESS,
    rs
})

const _addBannerFail = () => ({
    type: ADD_BANNER_FAIL
})

export const voidBanner = data => async dispatch => {
    dispatch(_voidBannerIsLoading())

    const rs = await axios.post(baseURL + '/Banner/VoidBanner', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            if(e.response.data && e.response.data.error) {
                const {response: {data: {error: {message}}}} = e
                return {data: {success: false, error: {message}}}
            }
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_voidBannerFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_voidBannerSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_voidBannerFail())
        return {success: false, message: rs.data.error.message}
    }
}

const VOID_BANNER_IS_LOADING = 'VOID_BANNER_IS_LOADING'
const VOID_BANNER_SUCCESS = 'VOID_BANNER_SUCCESS'
const VOID_BANNER_FAIL = 'VOID_BANNER_FAIL'

const _voidBannerIsLoading = () => ({
    type: VOID_BANNER_IS_LOADING
})

const _voidBannerSuccess = rs => ({
    type: VOID_BANNER_SUCCESS,
    rs
})

const _voidBannerFail = () => ({
    type: VOID_BANNER_FAIL
})

export const editBanner = () => async (dispatch, getState) => {
    dispatch(_editBannerIsLoading())

    const data = {bannerList: getState().bannerDuck.banners}
    const rs = await axios.post(baseURL + '/Banner/EditBanner', data, getCurrentAuthHeader()).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            if(e.response.data && e.response.data.error) {
                const {response: {data: {error: {message}}}} = e
                return {data: {success: false, error: {message}}}
            }
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_editBannerFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_editBannerSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_editBannerFail())
        return {success: false, message: rs.data.error.message}
    }
}

const EDIT_BANNER_IS_LOADING = 'EDIT_BANNER_IS_LOADING'
const EDIT_BANNER_SUCCESS = 'EDIT_BANNER_SUCCESS'
const EDIT_BANNER_FAIL = 'EDIT_BANNER_FAIL'

const _editBannerIsLoading = () => ({
    type: EDIT_BANNER_IS_LOADING
})

const _editBannerSuccess = rs => ({
    type: EDIT_BANNER_SUCCESS,
    rs
})

const _editBannerFail = () => ({
    type: EDIT_BANNER_FAIL
})

const initState = {
    getAllBannerIsLoading: false,
    banners: [{id: 0, name: '', uri: '', order: 0, sortPriority: 1}],
    addBannerIsLoading: false,
    voidBannerIsLoading: false,
    editBannerIsLoading: false
}

const BannerDuck = (state = initState, action) => {
    let idx, banner, banners
    switch (action.type) {
        case GET_ALL_BANNER_IS_LOADING:
            return {...state, getAllBannerIsLoading: true}

        case GET_ALL_BANNER_SUCCESS:
            return {...state, getAllBannerIsLoading: false, banners: action.rs.data.result.items}

        case GET_ALL_BANNER_FAIL:
            return {...state, getAllBannerIsLoading: false}

        case ADD_BANNER_IS_LOADING:
            return {...state, addBannerIsLoading: true}

        case ADD_BANNER_SUCCESS:
            return {...state, addBannerIsLoading: false}

        case ADD_BANNER_FAIL:
            return {...state, addBannerIsLoading: false}

        case VOID_BANNER_IS_LOADING:
            return {...state, voidBannerIsLoading: true}

        case VOID_BANNER_SUCCESS:
            return {...state, voidBannerIsLoading: false}

        case VOID_BANNER_FAIL:
            return {...state, voidBannerIsLoading: false}

        case UPDATE_SINGLE_BANNER_DETAILS:
            let {id, k, v} = action
            idx = state.banners.findIndex(b => b.id === id)
            if (idx !== -1) {
                banner = {...state.banners[idx]}
                banner[k] = v
                banners = [...state.banners]
                banners.splice(idx, 1, banner)
                return {...state, banners}
            }
            return state

        case EDIT_BANNER_IS_LOADING:
            return {...state, editBannerIsLoading: true}

        case EDIT_BANNER_SUCCESS:
            return {...state, editBannerIsLoading: false}

        case EDIT_BANNER_FAIL:
            return {...state, editBannerIsLoading: false}

        default:
            return state
    }
}

export default BannerDuck