import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withStyles, Grid, Button, TextField} from '@material-ui/core'
import Header from '../containers/Header.js'
import AdminSettingsNav from '../containers/adminSettings/AdminSettingsNav'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import StyledSection from '../components/StyledSection'
import StyledTable from '../components/StyledTable'
import StyledSelect from '../components/StyledSelect'
import GlobalTimeChecker from "../containers/GlobalTimeChecker";

const REJECT_TABLE_FIELDS = [
    {key: 'id', label: 'S/N'},
    {key: 'rejectReason', label: 'Reject reason'},
    {key: 'emailTemplate', label: 'Email template'},
    {key: 'system', label: 'system'}
]

const DATA = [
    {id: 1, rejectReason: 'reasons', emailTemplate: 'nil', system: 'CSO'}
]

class RejectReason extends Component {

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Admin settings - Reject Reason`
    }

    onSubmit = () => {

    }

    render() {
        const {classes} = this.props

        return (
            <div>
                <GlobalTimeChecker/>

                <Header history={this.props.history}/>
                <AdminSettingsNav/>

                <div className={classes.container}>
                    <Grid container spacing={24} direction={'column'}>
                        <Grid item>
                            <StyledSection>
                                <StyledTable fields={REJECT_TABLE_FIELDS} rows={DATA}
                                />
                            </StyledSection>
                        </Grid>

                        <Grid item>
                            <StyledSection title={'Create Reject Reason For Retrospective Claim'}>
                                <Grid container spacing={24} direction={'column'}>
                                    <Grid item xs={3}>
                                        <StyledSelect onChange={this.onSelectChange} label={'Roles'}/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField id="rejectReason" label="Reject reason" margin="normal"
                                            variant="outlined"
                                            fullWidth/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <StyledSelect onChange={this.onSelectChange} label={'Email template'}/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button color={'primary'} variant="contained"
                                            onClick={this.onSubmit}>Submit</Button>
                                    </Grid>
                                </Grid>
                            </StyledSection>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

export default connect(null, null)(withStyles(styles)(RejectReason))