import React from 'react'
import {connect} from 'react-redux'
import Header from '../containers/Header.js'
import IndividualFlightForm from '../containers/individual/IndividualFlightForm.js'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import {getFlightsData, updateFlights} from '../ducks/flightDuck'
import {createEmptyRedemptionTransaction} from '../ducks/redemptionTransactionDuck'
import moment from 'moment'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import StyledSnackbar from '../components/StyledSnackbar'

// import AutosaveDraft from '../containers/AutosaveDraft'

class IndividualScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            subsequentScanModalOpen: false,
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success'
        }
    }

    componentDidMount = async () => {
        document.title = `${TITLE_PREFIX} Individual`

        let flightDataWithMetaStr = await localStorage.getItem('FLIGHT_DATA')
        let flightDataWithMeta = {}
        if (flightDataWithMetaStr) {
            flightDataWithMeta = JSON.parse(flightDataWithMetaStr)
            let today = moment().format('YYYY-MM-DD')
            if (flightDataWithMeta.flightData && flightDataWithMeta.dateCreated === today) {
                this.props.updateFlights(flightDataWithMeta.flightData)
            } else {
                const rs = await this.props.getFlightsData()
                if (!rs.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
            }
        } else {
            const rs = await this.props.getFlightsData()
            if (!rs.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        }

    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.flights !== this.props.flightData && this.props.flightData != null) {
            if (this.props.flightData.length > 0) {
                let today = moment().format('YYYY-MM-DD')
                let flightDataWithMeta = {
                    dateCreated: today,
                    flightData: this.props.flightData
                }
                let flightsDataWithMetaStr = JSON.stringify(flightDataWithMeta)
                localStorage.setItem('FLIGHT_DATA', flightsDataWithMetaStr)
            }
        }
    }

    closeSubsequentScanModal = () => {
        this.setState({subsequentScanModalOpen: false})
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    render() {
        return (
            <div>
                <GlobalTimeChecker/>
                {/*<AutosaveDraft/>*/}

                <Header history={this.props.history}/>

                <IndividualFlightForm
                    history={this.props.history}
                    match={this.props.match}
                />

                <StyledSnackbar open={this.state.showSnackbar}
                                onClose={this.closeSnackbar}
                                message={this.state.snackbarMessage}
                                variant={this.state.snackbarVariant}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {systemDataDuck, flightDuck, individualFormDuck} = state
    const {flightData} = flightDuck
    return {systemDataDuck, individualFormDuck, flightData}
}

const mapDispatchToProps = dispatch => {
    return {
        getFlightsData: () => dispatch(getFlightsData()),
        updateFlights: (flights) => dispatch(updateFlights(flights)),
        createEmptyRedemptionTransaction: () => dispatch(createEmptyRedemptionTransaction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndividualScreen)