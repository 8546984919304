import React, { Component } from 'react'
import {
	withStyles,
	Paper,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	MuiThemeProvider,
	OutlinedInput,
	Grid,
	TableFooter,
	TableSortLabel
} from '@material-ui/core'
import { toHumanFriendlyDateTime } from '../../helpers/utilities'
import { hoverSelectOverride } from '../../styles/mui/theme'
import SpreadSheetComponent from '../../components/SpreadSheetComponent'

class SearchIssuanceSummaryResultTable extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			statusFilter: '',
			issuedCount: 0,
			totalCount: 0,
			order: 'asc',
			orderBy: 'cardNo'
		}
	}

	componentDidMount() {
		this.updateCount()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.data !== this.props.data) {
			this.updateCount()
		}
	}

	updateCount = () => {
		const { data } = this.props
		if (data) {
			this.setState({
				issuedCount: data.filter(d => d.redeemed === true).length,
				totalCount: data.length
			})
		}
	}

	onStatusFilterChange = v => {
		this.setState({ statusFilter: v })
	}

	filterStatus = data => {
		const { statusFilter } = this.state
		if (statusFilter === 'issued') return data.filter(d => d.redeemed === true)
		if (statusFilter === 'not issued') return data.filter(d => d.redeemed === false)
		return data
	}

	formatToExcel = data => {
		let clonedData = []
		data.forEach(record => {
			let clonedRecord = { ...record }
			clonedRecord.redeemedDateTime = toHumanFriendlyDateTime(record.redeemedDateTime)
			clonedRecord.redeemed = record.redeemed ? 'Issued' : 'Not Issued'
			clonedData.push(clonedRecord)
		})
		return clonedData
	}

	descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}
		if (b[orderBy] > a[orderBy]) {
			return 1
		}
		return 0
	}

	getComparator = (order, orderBy) => {
		return order === 'desc' ? (a, b) => this.descendingComparator(a, b, orderBy) : (a, b) => -this.descendingComparator(a, b, orderBy)
	}

	stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index])
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0])
			if (order !== 0) return order
			return a[1] - b[1]
		})
		return stabilizedThis.map(el => el[0])
	}

	render() {
		const { classes, fields, data } = this.props
		const { statusFilter, issuedCount, totalCount, order, orderBy } = this.state
		const filtered = this.filterStatus(data)
		const handleRequestSort = (event, property) => {
			const isAsc = orderBy !== property || (orderBy === property && order === 'desc')
			this.setState({ order: isAsc ? 'asc' : 'desc', orderBy: property })
		}
		const visuallyHidden = {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1
		}
		function EnhancedTableHead(props) {
			const { onRequestSort } = props
			const createSortHandler = property => event => {
				onRequestSort(event, property)
			}
			return (
				<TableHead>
					<TableRow>
						{fields.map(headCell => (
							<TableCell
								key={headCell.key}
								align={headCell.numeric ? 'right' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === headCell.key ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.key}
									direction={orderBy === headCell.key ? order : 'asc'}
									onClick={createSortHandler(headCell.key)}
								>
									{headCell.label}
									{orderBy === headCell.key ? <span style={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
			)
		}
		return (
			<div>
				<Paper className={classes.container}>
					<Grid container item spacing={24} alignItems={'center'}>
						<Grid item>
							<Typography variant={'body1'}>{`${issuedCount} out of ${totalCount} had been issued`}</Typography>
						</Grid>
					</Grid>

					<Grid container item spacing={24} alignItems={'center'}>
						<Grid item>
							{/* <MuiThemeProvider theme={hoverSelectOverride}> */}
								<FormControl variant={'outlined'} className={classes.statusFilter}>
									<InputLabel htmlFor={'statusFilter'}>Status Filter</InputLabel>
									<Select
										value={statusFilter}
										onChange={e => this.onStatusFilterChange(e.target.value)}
										input={<OutlinedInput labelWidth={120} name={'statusFilter'} id={'statusFilter'} />}
									>
										<MenuItem value={'all'} classes={{ selected: classes.menuItem }}>
											All
										</MenuItem>
										<MenuItem value={'issued'} classes={{ selected: classes.menuItem }}>
											Issued
										</MenuItem>
										<MenuItem value={'not issued'} classes={{ selected: classes.menuItem }}>
											Not issued
										</MenuItem>
									</Select>
								</FormControl>
							{/* </MuiThemeProvider> */}
						</Grid>
						<Grid item>
							<SpreadSheetComponent records={this.formatToExcel(filtered)} headers={fields} />
						</Grid>
					</Grid>

					<Table>
						<EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
						<TableBody>
							{this.stableSort(filtered, this.getComparator(order, orderBy)).map((d, ridx) => {
								let rowKey = d.cardNo ? d.cardNo : d.voucherNo
								return (
									<TableRow key={rowKey}>
										{fields.map(f => {
											if (f.key === 'redeemedDateTime') {
												return (
													<TableCell key={rowKey + f.key}>
														<Typography>{toHumanFriendlyDateTime(d[f.key])}</Typography>
													</TableCell>
												)
											}

											if (f.key === 'redeemed') {
												return (
													<TableCell key={rowKey + f.key}>
														<Typography>{d[f.key] === true ? 'issued' : 'not issued'}</Typography>
													</TableCell>
												)
											}

											return (
												<TableCell key={rowKey + f.key}>
													<Typography>{d[f.key]}</Typography>
												</TableCell>
											)
										})}
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</Paper>
			</div>
		)
	}
}

const styles = theme => ({
	container: theme.container,
	statusFilter: { width: '18rem' },
	menuItem: { color: 'white' }
})

export default withStyles(styles)(SearchIssuanceSummaryResultTable)
