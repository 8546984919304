import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Paper, Table, TableHead, TableBody, TableRow, TableCell, Button, Grid } from '@material-ui/core'
import StyledSection from './StyledSection'
import StyledSnackbar from './StyledSnackbar'
import { getReprintAuditLog } from '../ducks/reprintAuditLogDuck'
import { toHumanFriendlyDateTime } from '../helpers/utilities'

class ReprintAuditLogTable extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
		}
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	getReprintAuditLog = async () => {
		if (this.props.redemptionTransactionId) await this.props.getReprintAuditLog(this.props.redemptionTransactionId)
	}

	render() {
		const {
			classes,
			reprintAuditLogDuck: { getReprintAuditLogIsLoading, reprintLogs },
		} = this.props

		return (
			<div>
				<StyledSection title={'Reprint Audit Logs'} isLoading={getReprintAuditLogIsLoading}>
					<Grid container item spacing={24} alignItems={'center'}>
						<Grid item>
							<Button color={'primary'} variant='contained' onClick={this.getReprintAuditLog}>
								Retrieve
							</Button>
						</Grid>
					</Grid>
					{reprintLogs && reprintLogs.length > 0 && (
						<Paper className={classes.container}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Date</TableCell>
										<TableCell>Printed By</TableCell>
										<TableCell>Card No</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{reprintLogs.map((m, i) => {
										return (
											<TableRow key={i}>
												<TableCell>{toHumanFriendlyDateTime(m.creationTime)}</TableCell>
												<TableCell>{m.userName}</TableCell>
												<TableCell>{m.reprintCardNo}</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</Paper>
					)}
				</StyledSection>
				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = (themes) => ({
	container: { ...themes.container, marginTop: 20 },
	ul: { paddingLeft: '1rem' },
})

const mapStateToProps = (state) => {
	const { reprintAuditLogDuck } = state

	return { reprintAuditLogDuck }
}

const mapDispatchToProps = (dispatch) => {
	return {
		getReprintAuditLog: (data) => dispatch(getReprintAuditLog(data)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(ReprintAuditLogTable))
