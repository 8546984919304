import {
    GROUP_TYPE_ACTION_REQUIRED, GROUP_TYPE_COLLECTED, GROUP_TYPE_DELETED,
    GROUP_TYPE_PENDING_APPROVAL, GROUP_TYPE_PENDING_COLLECTION,
    GROUP_TYPE_PENDING_SQ_API, GROUP_TYPE_REJECTED,
    GROUP_TYPE_NOT_COLLECTED
} from "../constants/groupType";
import moment from "moment";

export const getGroupType = (group) => {
    let {sqCheck, submitted, approval, redemption, rejected, isDeleted, lastFlightDepartureDate} = group;

    if(!sqCheck && !submitted && !approval && !redemption && !rejected && !isDeleted){
        return GROUP_TYPE_PENDING_SQ_API

    } else if(sqCheck && !submitted && !approval && !redemption && !rejected && !isDeleted){
        return GROUP_TYPE_ACTION_REQUIRED

    } else if(sqCheck && submitted && !approval && !redemption && !rejected && !isDeleted){
        return GROUP_TYPE_PENDING_APPROVAL

    } else if(approval && redemption){
        return GROUP_TYPE_COLLECTED

    } else if(sqCheck && approval && !redemption && !rejected && !isDeleted) {
        if (moment(lastFlightDepartureDate).endOf('day').isBefore(moment.now())) {
            return GROUP_TYPE_NOT_COLLECTED
        } else {
            return GROUP_TYPE_PENDING_COLLECTION
        }


    } else if (redemption && !rejected && !isDeleted){
        return GROUP_TYPE_COLLECTED

    } else if (rejected && !isDeleted){
        return GROUP_TYPE_REJECTED

    } else if (isDeleted) {
        return GROUP_TYPE_DELETED
    }
};


export const getGroupLabel = (group) =>{
    let status = "PENDING"
    let type = getGroupType(group);
    if(type === GROUP_TYPE_PENDING_SQ_API) status = "PENDING SQ";
    if(type === GROUP_TYPE_ACTION_REQUIRED) status = "ACTION REQUIRED";
    if(type === GROUP_TYPE_PENDING_APPROVAL) status = "PENDING APPROVAL";
    if(type === GROUP_TYPE_NOT_COLLECTED) status = "NOT COLLECTED";
    if(type === GROUP_TYPE_PENDING_COLLECTION) status = "PENDING COLLECTION";
    if(type === GROUP_TYPE_COLLECTED) status = "COLLECTED";
    if(type === GROUP_TYPE_REJECTED) status = "REJECTED";
    if(type === GROUP_TYPE_DELETED) status = "DELETED";

    return status;
}