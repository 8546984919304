import React, {Component} from 'react'
import {connect} from 'react-redux'
import Header from '../containers/Header.js'
import {withStyles, Grid, Button, TextField} from '@material-ui/core'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import StyledSection from '../components/StyledSection'
import {changePassword} from '../ducks/authDuck'
import StyledSnackbar from '../components/StyledSnackbar'

class ChangePasswordScreen extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            formSubmitted: false,
            currentPassword: '',
            currentPasswordError: '',
            newPassword: '',
            newPasswordError: '',
            confirmNewPassword: '',
            confirmNewPasswordError: '',
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success'
        }
    }

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Change Password`

        const {authDuck: {shouldChangePassword}} = this.props

        if (shouldChangePassword) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Please change your password'})
    }

    onFieldChange = (k, e) => {
        this.setState({[k]: e.target.value})
    }

    _onSubmit = async () => {
        const {currentPassword, newPassword, confirmNewPassword} = this.state
        if (this._validate(currentPassword, newPassword, confirmNewPassword)) {
            const data = {userName: localStorage.getItem('username'), currentPassword, newPassword}
            const rs = await this.props.changePassword(data)
            rs.success
                ? this.setState({showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'Password updated'})
                : this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        }
    }

    _validate = (currentPassword, newPassword, confirmNewPassword) => {
        const required = ['currentPassword', 'newPassword', 'confirmNewPassword']
        let rs = true
        required.forEach(k => {
            if (eval(k) === '') {
                this.setState({[k + 'Error']: 'This field is required'})
                rs = false
            } else {
                this.setState({[k + 'Error']: ''})
            }
        })
        if (!rs) return rs

        if (newPassword.length < 10) {
            this.setState({newPasswordError: 'Password should be at least 10 characters'})
            rs = false
            return rs
        }
        if (!/[0-9]/g.test(newPassword)) {
            this.setState({newPasswordError: 'Password should have at least one numeric number'})
            rs = false
            return rs
        }
        if (!/[a-z]/g.test(newPassword)) {
            this.setState({newPasswordError: 'Password should have at least one lower case character (a - z)'})
            rs = false
            return rs
        }
        if (!/[A-Z]/g.test(newPassword)) {
            this.setState({newPasswordError: 'Password should have at least one upper case character (A - Z)'})
            rs = false
            return rs
        }
        if (!/[^a-zA-z\s0-9]/g.test(newPassword)) {
            this.setState({newPasswordError: 'Password should have at least one special character'})
            rs = false
            return rs
        } else {
            this.setState({newPasswordError: ''})
        }

        if (newPassword !== confirmNewPassword) {
            this.setState({confirmNewPasswordError: 'Password doesn\'t match'})
            rs = false
            return rs
        } else {
            this.setState({confirmNewPasswordError: ''})
        }

        return rs
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    render() {
        const {classes, authDuck: {changePasswordIsLoading}} = this.props
        const {currentPassword, currentPasswordError, newPassword, newPasswordError, confirmNewPassword, confirmNewPasswordError} = this.state

        return (
            <div>
                <GlobalTimeChecker/>
                <Header history={this.props.history}/>

                <div className={classes.container}>
                    <StyledSection title={'Change password'} isLoading={changePasswordIsLoading}>
                        <p>Password Policy<br/>
                            (e.g. min 10 char, 1 special char, 1 upper case, 1 lower case)
                        </p>
                        <form>
                            <Grid container spacing={24} direction={'column'}>
                                <Grid container item spacing={24} alignItems={'center'}>
                                    <Grid item xs={2}>
                                        Current password
                                    </Grid>
                                    <Grid item>
                                        <TextField id={'currentPassword'} variant={'outlined'}
                                            type={'password'}
                                            value={currentPassword}
                                            label={'Current Password'}
                                            error={!!currentPasswordError}
                                            helperText={currentPasswordError}
                                            onChange={e => this.onFieldChange('currentPassword', e)}
                                            fullWidth={true}/>
                                    </Grid>
                                </Grid>

                                <Grid container item spacing={24} alignItems={'center'}>
                                    <Grid item xs={2}>
                                        New password
                                    </Grid>
                                    <Grid item>
                                        <TextField id={'newPassword'} variant={'outlined'} label={'New Password'}
                                            type={'password'}
                                            value={newPassword}
                                            error={!!newPasswordError}
                                            helperText={newPasswordError}
                                            onChange={e => this.onFieldChange('newPassword', e)}
                                            fullWidth={true}/>
                                    </Grid>
                                </Grid>

                                <Grid container item spacing={24} alignItems={'center'}>
                                    <Grid item xs={2}>
                                        Confirm new password
                                    </Grid>
                                    <Grid item>
                                        <TextField id={'confirmNewPassword'} variant={'outlined'}
                                            type={'password'}
                                            value={confirmNewPassword}
                                            error={!!confirmNewPasswordError}
                                            helperText={confirmNewPasswordError}
                                            onChange={e => this.onFieldChange('confirmNewPassword', e)}
                                            label={'Confirm New Password'} fullWidth={true}/>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Button variant={'contained'} color={'primary'}
                                        onClick={this._onSubmit}>Submit</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </StyledSection>
                </div>
                <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
            </div>
        )
    }
}

const styles = theme => ({
    container: theme.container
})

const mapStateToProps = state => {
    const {authDuck} = state

    return {authDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        changePassword: data => dispatch(changePassword(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChangePasswordScreen))