import React from 'react'
import {Redirect} from 'react-router-dom'
import {Grid} from '@material-ui/core'
import Header from '../containers/Header.js'
import GroupHeader from '../containers/group/GroupHeader.js'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import {
    hasCreatorPermissions, hasApproverPermissions, hasIssuerPermissions, hasGroupViewPermission
} from '../helpers/utilities'

/**
 * This page exists for routing purpose
 */
class Group extends React.Component {

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Group`
    }

    render() {
        return (
            <div>
                <Grid container spacing={24}>
                    <GlobalTimeChecker/>

                    <Header history={this.props.history}/>
                    <GroupHeader/>
                    {hasCreatorPermissions() && <Redirect to={'/group/group-submission'}/>}
                    {(!hasCreatorPermissions() && (hasApproverPermissions() || hasGroupViewPermission())) &&
                    <Redirect to={'/group/group-approval'}/>}
                    {(!hasCreatorPermissions() && !hasApproverPermissions() && !hasGroupViewPermission() && hasIssuerPermissions()) &&
                    <Redirect to={'/group/group-issuance'}/>}
                </Grid>
            </div>
        )
    }
}

export default Group