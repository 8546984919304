import React, {PureComponent} from 'react'
import {Grid} from '@material-ui/core'

class NotFoundScreen extends PureComponent {
    render() {
        return (
            <Grid container spacing={16}>
                <Grid item xs>
                    Not found
                </Grid>
            </Grid>
        )
    }
}

export default NotFoundScreen