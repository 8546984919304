import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {withStyles, Button, TextField, Typography, Grid, Paper} from '@material-ui/core'
import {ExitToApp} from '@material-ui/icons'
import {login, authSendOtp, authAuthenticateWithOtp} from '../ducks/authDuck'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import MySnackbarContent from '../components/MySnackbarContent'
import LoadingContainer from '../containers/loadingContainer'
import {maskContactNo} from '../helpers/utilities'
import Logo from '../components/Logo'
import moment from 'moment'
import { LOCAL_STORAGE_LAST_ACTION_TIME } from '../constants/localStorageType'

class LoginScreen extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            usernameOrEmailAddress: '',
            usernameOrEmailAddressError: '',
            password: '',
            passwordError: '',
            verifyOtpScreen: false,
            otp: '',
            otpError: '',
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: ''
        }
    }

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Login`

        const accessToken = localStorage.getItem('accessToken')
        if (accessToken) {
            this.props.history.push('/dashboard')
        }
    }

    onTextFieldChange = name => e => {
        const errorKey = name + 'Error'
        this.setState({[name]: e.target.value, [errorKey]: ''})
    }

    onKeyPress = e => {
        if (e.key === 'Enter') {
            // set last actopm time
            let lastActionTime = moment().format()
            localStorage.setItem(LOCAL_STORAGE_LAST_ACTION_TIME, lastActionTime)
            // submit
            this.onSubmit()
        } 
    }

    validate() {
        if (this.state.usernameOrEmailAddress === '') this.setState({usernameOrEmailAddressError: 'This field is required'})
        if (this.state.password === '') this.setState({passwordError: 'This field is required'})
        return !(this.state.usernameOrEmailAddress === '' || this.state.password === '')
    }

    onSubmit = async () => {

        this.setState({showSnackbar: false})
        if (!this.validate()) return

        // Without OTP login
        if (!this.props.isLoggedIn) {
            const {usernameOrEmailAddress, password} = this.state
            const rs = await this.props.login(usernameOrEmailAddress, password)
            if (rs.success) {
                const {shouldChangePassword} = this.props
                shouldChangePassword
                    ? this.props.history.push('/change-password')
                    : this.props.history.push('/dashboard')
            } else {
                this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
            }
        } else {
            this.props.history.push('/dashboard')
        }

        // OTP login
        // if (!this.props.isLoggedIn) {
        //     const {usernameOrEmailAddress, password} = this.state
        //     const data = {usernameOrEmailAddress, password}
        //     const rs = await this.props.authSendOtp(data)
        //     rs.success
        //         ? this.setState({verifyOtpScreen: true})
        //         : this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        // } else {
        //     this.props.history.push('/dashboard')
        // }
    }

    onResend = async () => {
        const {usernameOrEmailAddress, password} = this.state
        const data = {usernameOrEmailAddress, password}
        const rs = await this.props.authSendOtp(data)
        rs.success
            ? this.setState({showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'OTP sent'})
            : this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
    }

    clearErrorMessage = () => {
        this.setState({showSnackbar: false})
    }

    onSubmitOtp = async e => {
        if (e) e.preventDefault()

        const {usernameOrEmailAddress, password, otp} = this.state

        if (otp === '') {
            this.setState({otpError: 'This field is required'})
            return
        } else {
            this.setState({otpError: ''})
        }

        const {uid} = this.props
        const data = {usernameOrEmailAddress, password, otp, uid}
        const rs = await this.props.authAuthenticateWithOtp(data)
        if (rs.success) {
            this.props.history.push('/dashboard')
        } else {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        }
    }

    render() {
        const {classes, isLoggingIn, authSendOtpIsLoading, authAuthenticateWithOtpIsLoading, contactNo} = this.props
        const {verifyOtpScreen, otp, snackbarVariant, snackbarMessage, otpError} = this.state
        const isLoading = isLoggingIn || authSendOtpIsLoading || authAuthenticateWithOtpIsLoading

        return (
            <Grid container spacing={16} className={classes.loginContainer}>
                <div className={classes.loginWrapper}>
                    <div className={classes.logoWrap}>
                        <Logo width={'12rem'}/>
                    </div>

                    <Paper className={classes.paper}>
                        {!verifyOtpScreen && <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
                            <Grid container spacing={24} direction={'column'}>
                                <Grid item>
                                    <TextField
                                        autoFocus
                                        error={this.state.usernameOrEmailAddressError !== ''}
                                        helperText={this.state.usernameOrEmailAddressError}
                                        id="username"
                                        variant="outlined"
                                        label="Email"
                                        margin="normal"
                                        fullWidth={true}
                                        value={this.state.usernameOrEmailAddress}
                                        onKeyPress={this.onKeyPress}
                                        onChange={this.onTextFieldChange('usernameOrEmailAddress')}
                                    />
                                </Grid>

                                <Grid item>
                                    <TextField
                                        error={this.state.passwordError !== ''}
                                        helperText={this.state.passwordError}
                                        id="password"
                                        variant="outlined"
                                        label="Password"
                                        type="password"
                                        margin="normal"
                                        fullWidth={true}
                                        value={this.state.password}
                                        onKeyPress={this.onKeyPress}
                                        onChange={this.onTextFieldChange('password')}
                                    />
                                </Grid>

                                {this.state.showSnackbar &&
                                <Grid item>
                                    <MySnackbarContent variant={snackbarVariant} message={snackbarMessage}
                                        onClose={this.clearErrorMessage}/>
                                </Grid>}

                                <Grid item>
                                    <Link to={'forgot-password'}>
                                        <Typography paragraph={true}>Forgot password</Typography>
                                    </Link>
                                </Grid>

                                <Grid item>
                                    <Button color="primary" size="large" variant="contained"
                                        onClick={this.onSubmit}><ExitToApp/> Login</Button>
                                </Grid>
                            </Grid>
                        </form>}

                        {verifyOtpScreen && <form noValidate autoComplete="off" onSubmit={this.onSubmitOtp}>
                            <Grid container spacing={24} direction={'column'}>
                                <Grid item>
                                    <Typography variant={'body1'}>Please enter the OTP code sent
                                        to {maskContactNo(contactNo)}</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField type="password" id="otp" label="OTP" margin="normal" variant="outlined"
                                        value={otp}
                                        onChange={this.onTextFieldChange('otp')}
                                        error={!!otpError}
                                        helperText={otpError}
                                        autoFocus
                                    />
                                </Grid>
                                {this.state.showSnackbar &&
                                <Grid item>
                                    <MySnackbarContent variant={snackbarVariant} message={snackbarMessage}
                                        onClose={this.clearErrorMessage}/>
                                </Grid>}
                                <Grid container item spacing={24} justify={'center'}>
                                    <Grid item>
                                        <Button color={'primary'} variant="outlined" onClick={this.onResend}>Resend
                                            OTP</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button color={'primary'} variant="contained"
                                            onClick={this.onSubmitOtp}>Submit</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>}

                        {isLoading && <LoadingContainer show={true}/>}
                    </Paper>
                </div>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    const {
        authDuck: {
            isLoggedIn, loginFailedReason, isLoggingIn, uid, authSendOtpIsLoading,
            authAuthenticateWithOtpIsLoading, contactNo, shouldChangePassword
        }
    } = state

    return {
        isLoggedIn, loginFailedReason, isLoggingIn, uid, authSendOtpIsLoading,
        authAuthenticateWithOtpIsLoading, contactNo, shouldChangePassword
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (username, password) => dispatch(login(username, password)),
        authSendOtp: (username, password) => dispatch(authSendOtp(username, password)),
        authAuthenticateWithOtp: (username, password, otp, uid) => dispatch(authAuthenticateWithOtp(username, password, otp, uid))
    }
}

const styles = {
    loginContainer: {width: '100%', height: '100vh'},
    loginWrapper: {width: '30rem', height: '30rem', textAlign: 'center', margin: 'auto'},
    logoWrap: {padding: '1rem'},
    paper: {padding: '2rem', position: 'relative'}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginScreen))