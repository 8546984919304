import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Typography,
    withStyles
} from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeleteDialog from '../../components/DeleteDialog';
import PassengersTableEditable from '../../components/PassengersTableEditable';
import ScrollToTopBtn from '../../components/ScrollToTopBtn';
import Spacing from '../../components/Spacing';
import SqApiCriteriasDialog from '../../components/SqApiCriteriasDialog';
import StyledDeleteBtn from '../../components/StyledDeleteBtn';
import StyledGeneralDialog from '../../components/StyledGeneralDialog';
import StyledSection from '../../components/StyledSection';
import StyledSnackbar from '../../components/StyledSnackbar';
import StyledUndoBtn from '../../components/StyledUndoBtn';
import GeneralDropzone from '../../components/generalForm/GeneralDropzone';
import GeneralFormColumnsContainer from '../../components/generalForm/GeneralFormColumns';
import {
    MULTI_CARD_DIGITAL,
    MULTI_CARD_EV,
    MULTI_CARD_UNLOADED,
    SINGLE_CARD_DIGITAL
} from '../../constants/groupCardAssignment.js';
import {
    GROUP_ACTION_TYPE_APPROVING,
    GROUP_ACTION_TYPE_COLLECTING,
    GROUP_ACTION_TYPE_SUBMITTING,
    GROUP_ACTION_TYPE_UNAPPROVING,
    GROUP_TYPE_ACTION_REQUIRED,
    GROUP_TYPE_COLLECTED,
    GROUP_TYPE_DELETED,
    GROUP_TYPE_NOT_COLLECTED,
    GROUP_TYPE_PENDING_APPROVAL,
    GROUP_TYPE_PENDING_COLLECTION,
    GROUP_TYPE_PENDING_SQ_API,
    GROUP_TYPE_REJECTED
} from '../../constants/groupType';
import { REQUIRED_ERROR_MSG } from '../../constants/messages';
import {
    approvalFilesUpload,
    getGroupAndPassengers,
    groupApprovalGroupCheckRedeemedPassenger,
    markAsDelete,
    rejectGroupAndPassengers,
    saveGroupEditFormRecord,
    unmarkDelete,
    updateAllPassengers,
    updateApprove,
    updateGroupAndPassengers,
    updateGroupApprovalDuck,
    updateGroupForm,
    updatePassenger,
    updatePassengersTourLeaderFlag
} from '../../ducks/groupApprovalDuck';
import { getCampaignCodes } from '../../ducks/groupManifestDuck.js';
import {
    DISPLAY_NO,
    convertSqStatusForDisplay
} from '../../helpers/eticketStatusHelper';
import { getGroupType } from '../../helpers/groupStatusHelper';
import {
    hasApproverPermissions,
    hasCreatorPermissions,
    hasGroupViewPermission,
    hasIssuerPermissions,
    sqApiResponseStringToObject,
    toBrowserFriendlyDate,
    toHumanFriendlyDate
} from '../../helpers/utilities';

class GroupReferenceForm extends Component {
    sectorHeaders = [
        'Sector 1 Flight No.',
        'Sector 1 Flight Date',
        'Sector 2 Flight No.',
        'Sector 2 Flight Date',
        'Sector 3 Flight No.',
        'Sector 3 Flight Date',
        'Sector 4 Flight No.',
        'Sector 4 Flight Date'
    ];

    sectorDataKeys = [
        'firstFltNo',
        'firstFltDepDate',
        'secondFltNo',
        'secondFltDepDate',
        'thirdFltNo',
        'thirdFltDepDate',
        'fourthFltNo',
        'fourthFltDepDate'
    ];

    constructor(props, context) {
        super(props, context);
        this.state = {
            saveAsDraft: false,
            showDialog: false,
            editForm: {},
            action: 'approveSelected',
            sqApiFailedCount: 0,
            allChecked: false,
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            criterias: {},
            viewCriterias: false,
            criteriasMessage: '',
            paxWithoutNIssue: 0,
            paxWithYIssue: 0,
            paxWithMIssue: 0,
            paxWithNIssue: 0,
            totalPax: 0,
            passengersHeaders: [
                'S/N',
                'First name',
                'Last name',
                'DOB',
                ...this.sectorHeaders,
                'Booking Ref. No.',
                'Tour leader',
                'Approve',
                'Delete'
            ],
            passengersDataKeys: [
                'seq',
                'firstName',
                'lastName',
                'dob',
                ...this.sectorDataKeys,
                'pnr',
                'isTourLeader',
                'approve',
                'delete'
            ],
            files: [],
            groupRefForm: [],
            tourLeaderDetailsForm: [
                {
                    key: 'firstName',
                    label: 'TL First name',
                    type: 'text',
                    customButton: true
                },
                {
                    key: 'lastName',
                    label: 'TL Last name',
                    type: 'text',
                    customButton: true
                },
                {
                    key: 'dob',
                    label: 'TL DOB',
                    type: 'general-text-date',
                    customButton: true
                },
                {
                    key: 'pnr',
                    label: 'TL Booking Ref. No. 1',
                    type: 'text',
                    customButton: true
                },
                {
                    key: 'pnr2',
                    label: 'TL Booking Ref. No. 2',
                    type: 'text',
                    customButton: true
                },
                {
                    key: 'pnr3',
                    label: 'TL Booking Ref. No. 3',
                    type: 'text',
                    customButton: true
                },
                {
                    key: 'pnr4',
                    label: 'TL Booking Ref. No. 4',
                    type: 'text',
                    customButton: true
                }
            ],
            groupType: '',
            viewOnly: false,
            accessChecked: false,
            deletedOriginalAttachmentIdx: new Set(),
            deleteDialog: false,
            confirmDelete: false,
            transportMeanError: '',
            transportRemarksError: '',
            attachmentError: '',
            deleteNStatusDialog: false,
            tlEditable: false,
            origTlId: '',
            tlDialog: false,
            deleteTlDialog: false
        };

        this.grpReferenceNo = this.props.location.pathname.split('/').pop();
        this.InputLabelRef = null;
    }

    componentDidMount = async () => {
        const { selectedGroupId } = this.props;
        if (selectedGroupId[0] === 'G') {
            let getAllUserFlag = false;
            if (hasIssuerPermissions() || hasGroupViewPermission())
                getAllUserFlag = true;
            const rs = await this.props.getGroupAndPassengers({
                refNo: this.props.selectedGroupId,
                getAllUser: getAllUserFlag
            });
            if (!rs.success)
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'error',
                    snackbarMessage: rs.message
                });
        } else {
            // const rs = await this.props.getGroupAndPassengers({groupId: this.props.selectedGroupId, getAllUser:
            // true}); if (!rs.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage:
            // rs.message})
        }

        const rs = await this.props.getCampaignCodes();
        this.setState({
            groupRefForm: [
                {
                    key: 'collectionDate',
                    label: 'Collection Date*',
                    type: 'general-text-date',
                    customButton: true
                },
                {
                    key: 'tourCompany',
                    label: 'Tour Company*',
                    type: 'text',
                    customButton: true
                },
                {
                    key: 'pos',
                    label: 'POS*',
                    type: 'text',
                    customButton: true,
                    disabled: true
                },
                {
                    key: 'lastFlightDepartureDate',
                    label: 'Return Flight Departure Date*',
                    type: 'general-text-date',
                    customButton: true
                },
                {
                    key: 'campaignCode',
                    label: 'Campaign Code*',
                    type: 'select',
                    options: (rs && rs.result) || []
                }
            ]
        });

        const { group } = this.props;
        let groupType = getGroupType(group);
        this.setState({ groupType });

        let viewOnlytemp = false;

        switch (groupType) {
            case GROUP_TYPE_PENDING_SQ_API:
                break;
            case GROUP_TYPE_ACTION_REQUIRED:
                if (hasCreatorPermissions()) {
                    // submittor will always submit all.
                    this.setState({
                        passengersHeaders: [
                            'S/N',
                            'First name',
                            'Last name',
                            'DOB',
                            ...this.sectorHeaders,
                            'Booking Ref. No.',
                            'Tour leader',
                            'Delete'
                        ],
                        passengersDataKeys: [
                            'seq',
                            'firstName',
                            'lastName',
                            'dob',
                            ...this.sectorDataKeys,
                            'pnr',
                            'isTourLeader',
                            'delete'
                        ],
                        action: 'approveAll',
                        groupType,
                        viewOnly: false,
                        accessChecked: true
                    });
                    viewOnlytemp = false;
                } else {
                    this.setState({
                        passengersHeaders: [
                            'S/N',
                            'First name',
                            'Last name',
                            'DOB',
                            ...this.sectorHeaders,
                            'Booking Ref. No.',
                            'Tour leader'
                        ],
                        passengersDataKeys: [
                            'seq',
                            'firstName',
                            'lastName',
                            'dob',
                            ...this.sectorDataKeys,
                            'pnr',
                            'isTourLeader'
                        ],
                        groupType,
                        viewOnly: true,
                        accessChecked: true
                    });
                    viewOnlytemp = true;
                }
                break;
            case GROUP_TYPE_PENDING_APPROVAL:
                if (hasApproverPermissions()) {
                    this.setState({
                        passengersHeaders: [
                            'S/N',
                            'First name',
                            'Last name',
                            'DOB',
                            ...this.sectorHeaders,
                            'Booking Ref. No.',
                            'Tour leader',
                            'Approve'
                        ],
                        passengersDataKeys: [
                            'seq',
                            'firstName',
                            'lastName',
                            'dob',
                            ...this.sectorDataKeys,
                            'pnr',
                            'isTourLeader',
                            'approve'
                        ],
                        groupType,
                        viewOnly: false,
                        accessChecked: true
                    });
                    viewOnlytemp = false;
                    break;
                }

                if (hasGroupViewPermission()) {
                    this.setState({
                        passengersHeaders: [
                            'S/N',
                            'First name',
                            'Last name',
                            'DOB',
                            ...this.sectorHeaders,
                            'Booking Ref. No.',
                            'Tour leader',
                            'Approve'
                        ],
                        passengersDataKeys: [
                            'seq',
                            'firstName',
                            'lastName',
                            'dob',
                            ...this.sectorDataKeys,
                            'pnr',
                            'isTourLeader',
                            'approve'
                        ],
                        groupType,
                        viewOnly: true,
                        accessChecked: true
                    });
                    viewOnlytemp = true;
                    break;
                }

                if (hasCreatorPermissions()) {
                    this.setState({
                        passengersHeaders: [
                            'S/N',
                            'First name',
                            'Last name',
                            'DOB',
                            ...this.sectorHeaders,
                            'Booking Ref. No.',
                            'Tour leader'
                        ],
                        passengersDataKeys: [
                            'seq',
                            'firstName',
                            'lastName',
                            'dob',
                            ...this.sectorDataKeys,
                            'pnr',
                            'isTourLeader'
                        ],
                        groupType,
                        viewOnly: true,
                        accessChecked: true
                    });
                    viewOnlytemp = true;
                } else {
                    this.setState({
                        passengersHeaders: [
                            'S/N',
                            'First name',
                            'Last name',
                            'DOB',
                            ...this.sectorHeaders,
                            'Booking Ref. No.',
                            'Tour leader'
                        ],
                        passengersDataKeys: [
                            'seq',
                            'firstName',
                            'lastName',
                            'dob',
                            ...this.sectorDataKeys,
                            'pnr',
                            'isTourLeader'
                        ],
                        groupType,
                        viewOnly: true,
                        accessChecked: true
                    });
                    viewOnlytemp = true;
                }
                break;
            case GROUP_TYPE_PENDING_COLLECTION:
                if (
                    hasCreatorPermissions() ||
                    hasApproverPermissions() ||
                    hasGroupViewPermission() ||
                    hasIssuerPermissions()
                ) {
                    this.setState({
                        passengersHeaders: [
                            'S/N',
                            'First name',
                            'Last name',
                            'DOB',
                            ...this.sectorHeaders,
                            'Booking Ref. No.',
                            'Tour leader'
                        ],
                        passengersDataKeys: [
                            'seq',
                            'firstName',
                            'lastName',
                            'dob',
                            ...this.sectorDataKeys,
                            'pnr',
                            'isTourLeader'
                        ],
                        groupType,
                        viewOnly: true,
                        accessChecked: true
                    });
                    viewOnlytemp = true;
                }
                break;
            case GROUP_TYPE_COLLECTED:
                if (
                    hasCreatorPermissions() ||
                    hasApproverPermissions() ||
                    hasGroupViewPermission() ||
                    hasIssuerPermissions()
                ) {
                    this.setState({
                        passengersHeaders: [
                            'S/N',
                            'First name',
                            'Last name',
                            'DOB',
                            ...this.sectorHeaders,
                            'Booking Ref. No.',
                            'Card No',
                            'Preloaded Card No',
                            'GEN Voucher No',
                            'LPC Voucher No',
                            'Voucher Value',
                            'Tour leader',
                            'Collection Date'
                        ],
                        passengersDataKeys: [
                            'seq',
                            'firstName',
                            'lastName',
                            'dob',
                            ...this.sectorDataKeys,
                            'pnr',
                            'cardNo',
                            'preloadedVouchersCardNo',
                            'genPhysicalVoucherNo',
                            'lpcPhysicalVoucherNo',
                            'voucherValue',
                            'isTourLeader',
                            'collectionDate'
                        ],
                        groupType,
                        viewOnly: true,
                        accessChecked: true
                    });
                    viewOnlytemp = true;
                }
                const { passengers } = this.props;
                // if (passengers.length > 0) {
                //     const keys = [
                //         'eTicket1',
                //         'eTicket2',
                //         'eTicket3',
                //         'eTicket4'
                //     ];
                //     let eTicketFilter = [];
                //     passengers.forEach(p => {
                //         for (let k of keys) {
                //             if (p[k].value) {
                //                 eTicketFilter.push(p[k].value);
                //             }
                //         }
                //     });
                //     const rs = await this.props.groupApprovalGroupCheckRedeemedPassenger(
                //         { eTicketFilter }
                //     );
                //     if (!rs.success)
                //         this.setState({
                //             showSnackbar: true,
                //             snackbarVariant: 'error',
                //             snackbarMessage: rs.message
                //         });
                // }
                break;
            case GROUP_TYPE_NOT_COLLECTED:
                if (
                    hasCreatorPermissions() ||
                    hasApproverPermissions() ||
                    hasGroupViewPermission() ||
                    hasIssuerPermissions()
                ) {
                    this.setState({
                        passengersHeaders: [
                            'S/N',
                            'First name',
                            'Last name',
                            'DOB',
                            ...this.sectorHeaders,
                            'Booking Ref. No.',
                            'Tour leader'
                        ],
                        passengersDataKeys: [
                            'seq',
                            'firstName',
                            'lastName',
                            'dob',
                            ...this.sectorDataKeys,
                            'pnr',
                            'isTourLeader'
                        ],
                        groupType,
                        viewOnly: true,
                        accessChecked: true
                    });
                    viewOnlytemp = true;
                }
                break;

            case GROUP_TYPE_REJECTED:
                if (
                    hasApproverPermissions() ||
                    hasGroupViewPermission() ||
                    hasCreatorPermissions()
                ) {
                    this.setState({
                        passengersHeaders: [
                            'S/N',
                            'First name',
                            'Last name',
                            'DOB',
                            ...this.sectorHeaders,
                            'Booking Ref. No.',
                            'Tour leader'
                        ],
                        passengersDataKeys: [
                            'seq',
                            'firstName',
                            'lastName',
                            'dob',
                            ...this.sectorDataKeys,
                            'pnr',
                            'isTourLeader'
                        ],
                        groupType,
                        viewOnly: true,
                        accessChecked: true
                    });
                }
                viewOnlytemp = true;
                break;

            case GROUP_TYPE_DELETED:
                if (hasCreatorPermissions()) {
                    this.setState({
                        passengersHeaders: [
                            'S/N',
                            'First name',
                            'Last name',
                            'DOB',
                            ...this.sectorHeaders,
                            'Booking Ref. No.',
                            'Tour leader'
                        ],
                        passengersDataKeys: [
                            'seq',
                            'firstName',
                            'lastName',
                            'dob',
                            ...this.sectorDataKeys,
                            'pnr',
                            'isTourLeader'
                        ],
                        groupType,
                        viewOnly: true,
                        accessChecked: true
                    });
                }
                viewOnlytemp = true;
                break;

            default:
                throw new Error('Unable to determine status');
        }

        if (viewOnlytemp) {
            let { groupRefForm, tourLeaderDetailsForm } = this.state;
            groupRefForm.forEach(f => {
                f.disabled = true;
            });
            tourLeaderDetailsForm.forEach(f => {
                f.disabled = true;
            });
            this.setState({ groupRefForm, tourLeaderDetailsForm });
        }

        const { tlEditable } = this.state;
        if (!tlEditable) {
            let { tourLeaderDetailsForm } = this.state;
            tourLeaderDetailsForm.forEach(f => {
                f.disabled = true;
            });
            this.setState({ tourLeaderDetailsForm });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.getGroupAndPassengersIsLoading === true &&
            this.props.getGroupAndPassengersIsLoading === false
        ) {
            for (let p of this.props.passengers) {
                if (p.isTourLeader.value === true) {
                    this.setState({ origTlId: p.id.value });
                    break;
                }
            }
        }

        if (prevProps.passengers !== this.props.passengers) {
            let checkedCount = 0;
            let sqApiFailedCount = 0;
            let paxWithYIssue = 0;
            let paxWithMIssue = 0;
            let paxWithNIssue = 0;

            this.props.passengers.forEach(p => {
                // if (
                //     convertSqStatusForDisplay(p['eTicket1'].issue) ===
                //     DISPLAY_YES
                // )
                //     paxWithYIssue++;
                // if (
                //     convertSqStatusForDisplay(p['eTicket1'].issue) ===
                //     DISPLAY_MANUAL
                // )
                //     paxWithMIssue++;
                // if (
                //     convertSqStatusForDisplay(p['eTicket1'].issue) ===
                //     DISPLAY_NO
                // )
                //     paxWithNIssue++;
                if (p.approve.value) checkedCount++;
                // if (p.sqApiStatus.value === false) sqApiFailedCount++;
            });
            let totalPax = this.props.passengers.length;
            let paxWithoutNIssue = totalPax - paxWithNIssue;
            const allChecked = checkedCount === paxWithoutNIssue;

            this.setState({
                allChecked,
                sqApiFailedCount,
                paxWithoutNIssue,
                paxWithYIssue,
                paxWithMIssue,
                paxWithNIssue,
                totalPax
            });
        }

        if (prevProps.tourLeader !== this.props.tourLeader) {
            this._updateTourLeaderFlagInPassengers();
        }

        if (prevState.tlEditable !== this.state.tlEditable) {
            let { tourLeaderDetailsForm } = this.state;
            if (this.state.tlEditable) {
                tourLeaderDetailsForm.forEach(f => {
                    delete f.disabled;
                });
            } else {
                tourLeaderDetailsForm.forEach(f => {
                    f.disabled = true;
                });
            }

            this.setState({ tourLeaderDetailsForm });
        }
    }

    onGroupRefChange = (k, v) => {
        this.props.updateGroupForm('group', k, v);
    };

    onTourLeaderDetailsChange = (k, v) => {
        this.props.updateGroupForm('tourLeader', k, v);
    };

    _updateTourLeaderFlagInPassengers = () => {
        let matchedPassenger = this.props.passengers.find(
            passenger =>
                passenger.firstName.value === this.props.tourLeader.firstName &&
                passenger.lastName.value === this.props.tourLeader.lastName
        );
        if (!matchedPassenger) this.props.updatePassengersTourLeaderFlag(null);
        if (matchedPassenger)
            this.props.updatePassengersTourLeaderFlag(
                matchedPassenger.id.value
            );
    };

    onRowEdit = id => {
        const idx = this.props.passengers.findIndex(p => p.id.value === id);
        const editForm = this.props.passengers[idx];
        this.setState({ editForm, showDialog: true });
    };

    onRowDelete = id => {
        this.props.markAsDelete(id);
    };

    onRowUndo = id => {
        this.props.unmarkDelete(id);
    };

    closeModel = () => {
        this.setState({ showDialog: false });
    };

    onEditFormChange = (k, v) => {
        this.setState({
            editForm: { ...this.state.editForm, [k]: { value: v } }
        });
    };

    onEditFormSave = async () => {
        const rs = await this.props.saveGroupEditFormRecord(
            this.state.editForm
        );
        if (rs.success) {
            this.setState({ showDialog: false, editForm: {} });
        } else {
        }
    };

    onEditFormCancel = () => {
        this.setState({ showDialog: false, editForm: {} });
    };

    onStatusChange = e => {
        this.setState({ action: e.target.value });
    };

    _checkMinPax = passengers => {
        let approvedCount = 0;
        passengers.forEach(p => {
            if (p.approve.value === true) {
                approvedCount += 1;
            }
        });
        if (approvedCount < 10) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: 'Min. of 10 pax should be approved'
            });
            return false;
        }
        return true;
    };

    _checkNIssuePaxIsDeleted = passengers => {
        let rs = true;
        let illegibleCount = 0;
        const { totalPax } = this.state;
        for (let p of passengers) {
            if (
                convertSqStatusForDisplay(p.eTicket1.issue) === DISPLAY_NO &&
                !p.markedDelete
            )
                illegibleCount++;
        }
        if (illegibleCount > 0) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage:
                    illegibleCount +
                    ' of ' +
                    totalPax +
                    ' are not eligible. Please remove or edit entries to proceed'
            });
            rs = false;
        }
        return rs;
    };

    showDeleteDialog = () => {
        this.setState({ deleteDialog: true });
    };

    onDeleteCancel = () => {
        this.setState({ deleteDialog: false, confirmDelete: false });
    };

    onDeleteConfirm = () => {
        this.setState(
            { deleteDialog: false, confirmDelete: true },
            async () => {
                // Remove attachments in group.attachmentUris then proceed as usual
                const {
                    group: { attachmentUris }
                } = this.props;
                const { deletedOriginalAttachmentIdx } = this.state;

                let newAttachmentUris = [];
                for (let i = 0; i < attachmentUris.length; i++) {
                    if (!deletedOriginalAttachmentIdx.has(i))
                        newAttachmentUris.push(attachmentUris[i]);
                }
                await this.props.updateGroupForm(
                    'group',
                    'attachmentUris',
                    newAttachmentUris
                );
                this.onSubmit();
            }
        );
    };

    validateTransportMean = () => {
        const { group } = this.props;
        const { deletedOriginalAttachmentIdx } = this.state;
        this.setState({
            transportMeanError: '',
            transportRemarksError: '',
            attachmentError: ''
        });

        let err = [];
        if (group.transportMean === 'na') err.push('transportMeanError');
        if (!group.transportRemarks) err.push('transportRemarksError');

        if (this.state.files.length > 0) {
            //continue
        } else {
            if (group.presignedAttachmentUris.length < 1) {
                err.push('attachmentError');
            } else {
                if (
                    deletedOriginalAttachmentIdx.size ===
                    group.presignedAttachmentUris.length
                ) {
                    err.push('attachmentError');
                }
            }
        }

        if (err.length > 0) {
            err.forEach(k => {
                this.setState({ [k]: REQUIRED_ERROR_MSG });
            });
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: 'Please fill in 3-sector fields'
            });
            return false;
        }
        return true;
    };

    _currentTourLeader = () => {
        const { passengers } = this.props;

        for (let p of passengers) {
            if (p.isTourLeader.value) return p.id.value;
        }
        return '';
    };

    _validate2yearsOld = () => {
        // 2 years from collection date
        const {
            group: { collectionDate },
            tourLeader: { dob: tlDob, firstName, lastName },
            passengers
        } = this.props;
        let minDob = moment(collectionDate).subtract(2, 'years');
        if (moment(tlDob).isAfter(minDob)) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: `${firstName} ${lastName} has to be at least 2 years old from collection date`
            });
            return false;
        }
        let valid = true;
        for (let p of passengers) {
            if (moment(p.dob.value).isAfter(minDob)) {
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'error',
                    snackbarMessage: `${p.firstName.value} ${
                        p.lastName.value
                    } has to be at least 2 years old from collection date`
                });
                valid = false;
                break;
            }
        }
        return valid;
    };

    _validateUniqueETickets = () => {
        let eTickets = [];
        const { passengers } = this.props;

        for (let passenger of passengers) {
            eTickets.push(passenger.eTicket1.value);
            if (passenger.eTicket2.value)
                eTickets.push(passenger.eTicket2.value);
            if (passenger.eTicket3.value)
                eTickets.push(passenger.eTicket3.value);
            if (passenger.eTicket4.value)
                eTickets.push(passenger.eTicket4.value);
        }
        if (new Set(eTickets).size !== eTickets.length) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: `Please ensure no duplicate ETickets`
            });
            return false;
        }

        return true;
    };

    onSubmit = async () => {
        const {
            groupMetaInfo: { isThreeSector },
            paxIdsToReVerify
        } = this.props;
        const {
            groupType,
            deletedOriginalAttachmentIdx,
            confirmDelete
        } = this.state;

        if (paxIdsToReVerify.length > 0) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage:
                    'Outstanding changes not verified with SQ checks'
            });
            return;
        }

        if (isThreeSector && !this.validateTransportMean()) return;

        if (!this._validate2yearsOld()) return;

        // if (!this._validateUniqueETickets()) return;

        if (deletedOriginalAttachmentIdx.size > 0 && !confirmDelete) {
            return this.showDeleteDialog();
        }

        // const { passengers } = this.props;
        // let valid = true;
        // for (let p of passengers) {
        //     const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4'];
        //     for (let k of keys) {
        //         if (p[k].value) {
        //             if (
        //                 !this._validateEticket(
        //                     p[k].value,
        //                     `ETicket ${p[k].value} has to be 13 digits`
        //                 )
        //             ) {
        //                 valid = false;
        //                 break;
        //             }
        //         }
        //     }
        //     if (!valid) break;
        // }
        // if (!valid) return;

        let successMessage = '';
        if (groupType === GROUP_TYPE_ACTION_REQUIRED) {
            if (this.state.saveAsDraft) {
                successMessage =
                    'Save as Draft Successful. You will be redirected to the Dashboard.';
            } else {
                successMessage =
                    'Submitted for Processing. Please check Dashboard on status of request.';
            }
        }
        if (groupType === GROUP_TYPE_PENDING_APPROVAL)
            successMessage =
                'Group Approved - Email has been sent to Requestor. You will be redirected to the Approver Dashboard.';

        if (this.state.action === 'rejectAll') {
            const { group, history } = this.props;
            const rs = await this.props.rejectGroupAndPassengers(group);
            if (rs.success) {
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'success',
                    snackbarMessage: 'Rejected successfully'
                });
                setTimeout(() => {
                    history.push('/group/group-submission');
                }, 3000);
            } else {
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'error',
                    snackbarMessage: rs.message
                });
            }
        }

        if (
            this.state.action === 'approveAll' ||
            this.state.action === 'approveSelected'
        ) {
            // if approve all, same as "check all" then approve selected
            if (this.state.action === 'approveAll')
                await this.props.updateApprove(true);
            const { passengers } = this.props;

            // should have at least 10 pax to be sent for approval
            if (!this._checkMinPax(passengers)) return;
            // N issues pax should be deleted
            // if (!this._checkNIssuePaxIsDeleted(passengers)) return;

            const { files } = this.state;
            if (files.length > 0) {
                const filesrs = await this.uploadFiles();
                if (!filesrs.success) {
                    this.setState({
                        showSnackbar: true,
                        snackbarVariant: 'error',
                        snackbarMessage:
                            'File upload failed for ' + filesrs.message
                    });
                    return;
                }
            }

            const { tourLeader, group } = this.props;
            let groupActionType = '';
            if (this.state.groupType === GROUP_TYPE_ACTION_REQUIRED && !this.state.saveAsDraft)
                groupActionType = GROUP_ACTION_TYPE_SUBMITTING;
            if (this.state.groupType === GROUP_TYPE_PENDING_APPROVAL)
                groupActionType = GROUP_ACTION_TYPE_APPROVING;
            if (this.state.groupType === GROUP_TYPE_PENDING_COLLECTION)
                groupActionType = GROUP_ACTION_TYPE_COLLECTING;
            let updateResponse = await this.props.updateGroupAndPassengers({
                passengers,
                tourLeader,
                group,
                groupActionType
            });
            if (!updateResponse.success) {
                if (updateResponse.result && updateResponse.result.length) {
                    passengers.forEach(p => (p.error = null));
                    passengers
                        .filter(
                            passenger => passenger.isDeleted.value === false
                        )
                        .forEach((p, i) => {
                            p.error = updateResponse.result[i];
                        });
                }
                this.setState({
                    showSnackbar: true,
                    snackbarMessage:
                        (updateResponse.error &&
                            updateResponse.error.message) ||
                        updateResponse.message ||
                        'An error has occurred',
                    snackbarVariant: 'error'
                });
            } else {
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'success',
                    snackbarMessage: successMessage
                });
                setTimeout(() => {
                    window.location = '/dashboard';
                }, 3000);
            }
        }
    };

    onUnapproveGroup = async () => {
        let successMessage = 'Group status is now Pending Approval.';

        const { tourLeader, group, passengers } = this.props;
        const groupActionType = GROUP_ACTION_TYPE_UNAPPROVING;
        let updateResponse = await this.props.updateGroupAndPassengers({
            passengers,
            tourLeader,
            group,
            groupActionType
        });
        if (!updateResponse.success) {
            this.setState({
                showSnackbar: true,
                snackbarMessage: updateResponse.error
                    ? updateResponse.error.message
                    : 'An error has occurred',
                snackbarVariant: 'error'
            });
        } else {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'success',
                snackbarMessage: successMessage
            });
            setTimeout(() => {
                window.location = '/dashboard';
            }, 3000);
        }
    };

    async uploadFiles() {
        const { files } = this.state;
        let formData = new FormData();
        files.forEach(f => {
            formData.append('files', f, f.name);
        });
        return await this.props.approvalFilesUpload(formData);
    }

    onCheckBox = (id, v) => {
        this.props.updatePassenger(id, 'approve', !v);
    };

    onCheckAll = () => {
        this.props.updateApprove(true);
    };

    onUncheckAll = () => {
        this.props.updateApprove(false);
    };

    closeSnackbar = () => {
        this.setState({ showSnackbar: false });
    };

    onETicketIssueClick = eTicket => {
        let criterias = {};
        if (/^\d{11}$/.test(eTicket.remarks))
            criterias = sqApiResponseStringToObject(eTicket.remarks);

        Object.keys(criterias).length > 0
            ? this.setState({
                  viewCriterias: true,
                  criterias,
                  criteriasMessage: ''
              })
            : this.setState({
                  viewCriterias: true,
                  criterias: {},
                  criteriasMessage: eTicket.remarks
              });
    };

    // onSqCheckBtnClick = async (pidx, p) => {
    //     if (this._checkDuplicateETix()) {
    //         this.setState({
    //             showSnackbar: true,
    //             snackbarVariant: 'error',
    //             snackbarMessage: 'Please ensure no duplicated Ticket'
    //         });
    //         return;
    //     }

    //     const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4'];
    //     const data = { ticketDetails: [] };
    //     keys.forEach(k => {
    //         if (p[k].value !== '' && p[k].value !== null)
    //             data.ticketDetails.push({
    //                 ticketNumber: p[k].value,
    //                 lastName: p.lastName.value,
    //                 passengerId: p.id.value
    //             });
    //     });
    //     const rs = await this.props.groupSubmissionSqApiCheck(pidx, data);
    //     if (rs.success) {
    //         const { paxIdsToReVerify } = this.props;
    //         if (paxIdsToReVerify.includes(p.id.value)) {
    //             let newPaxIdsToReVerify = paxIdsToReVerify.filter(
    //                 i => i !== p.id.value
    //             );
    //             this.props.updateGroupApprovalDuck(
    //                 'paxIdsToReVerify',
    //                 newPaxIdsToReVerify
    //             );
    //         }
    //     } else {
    //         this.setState({
    //             showSnackbar: true,
    //             snackbarVariant: 'error',
    //             snackbarMessage: rs.message
    //         });
    //     }
    // };

    onViewCriteriasClose = () => {
        this.setState({ viewCriterias: false });
    };

    onRadioChange = (k, e) => {
        this.props.updateGroupForm('group', k, e.target.value);
    };

    onCardAssignmentChange = e => {
        this.props.updateGroupForm('group', 'cardAssignment', e.target.value);
    };

    ontransportMeanRemarksChange = (k, e) => {
        this.props.updateGroupForm('group', k, e.target.value);
    };

    onDrop = acceptedFiles => {
        const files = [...this.state.files];
        for (const af of acceptedFiles) {
            let exists = false;
            for (const f of files) {
                if (af.name === f.name) exists = true;
            }
            if (!exists) files.push(af);
        }
        this.setState({ files });
    };

    onRemoveCircleClick = (f, i) => {
        const files = [...this.state.files];
        files.splice(i, 1);
        this.setState({ files });
    };

    onAttachmentClick = alink => {
        window.open(alink);
    };

    onCancel = () => {
        this.props.history.goBack();
    };

    _groupStatus = () => {
        const { group, redemptionTransaction } = this.props;
        let redeemedDate =
            redemptionTransaction.collectionDate ||
            redemptionTransaction.creationTime;

        if (redemptionTransaction.creationTime) {
            return `Redeemed (${toHumanFriendlyDate(redeemedDate)})`;
        } else if (group.approval) {
            return 'Approved';
        } else if (group.rejected) {
            return 'Rejected';
        } else {
            return '';
        }
    };

    _formatHumanFriendlyDate = fields => {
        let data = { ...fields };
        data.dob = toBrowserFriendlyDate(fields.dob);
        return data;
    };

    onDeleteOrigAttachment = idx => {
        this.setState({
            deletedOriginalAttachmentIdx: this.state.deletedOriginalAttachmentIdx.add(
                idx
            )
        });
    };

    onUndoDeleteOrigAttachment = idx => {
        this.state.deletedOriginalAttachmentIdx.delete(idx);
        this.setState({
            deletedOriginalAttachmentIdx: this.state
                .deletedOriginalAttachmentIdx
        });
    };

    onDeleteNStatus = () => {
        this.setState({ deleteNStatusDialog: true });
    };

    onDeleteNStatusCancel = () => {
        this.setState({ deleteNStatusDialog: false });
    };

    onDeleteNStatusConfirm = () => {
        const passengers = [...this.props.passengers];
        passengers.forEach(p => {
            const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4'];
            for (let k of keys) {
                if (
                    p[k].value !== '' &&
                    convertSqStatusForDisplay(p[k].issue) === DISPLAY_NO
                ) {
                    p.markedDelete = true;
                }
            }
        });
        this.setState({ deleteNStatusDialog: false });
        this.props.updateAllPassengers(passengers);
    };

    // _checkDuplicateETix() {
    //     let passengers = this.props.passengers;
    //     let existingETickets = [];
    //     for (let i = 0; i < passengers.length; i++) {
    //         if (!passengers[i].markedDelete) {
    //             let eTix1Matched = existingETickets.find(
    //                 x => x === passengers[i].eTicket1.value
    //             );
    //             let eTix2Matched = existingETickets.find(
    //                 x => x === passengers[i].eTicket2.value
    //             );
    //             let eTix3Matched = existingETickets.find(
    //                 x => x === passengers[i].eTicket3.value
    //             );
    //             let eTix4Matched = existingETickets.find(
    //                 x => x === passengers[i].eTicket4.value
    //             );
    //             if (
    //                 eTix1Matched ||
    //                 eTix2Matched ||
    //                 eTix3Matched ||
    //                 eTix4Matched
    //             ) {
    //                 return true;
    //             }
    //             if (passengers[i].eTicket1.value)
    //                 existingETickets.push(passengers[i].eTicket1.value);
    //             if (passengers[i].eTicket2.value)
    //                 existingETickets.push(passengers[i].eTicket2.value);
    //             if (passengers[i].eTicket3.value)
    //                 existingETickets.push(passengers[i].eTicket3.value);
    //             if (passengers[i].eTicket4.value)
    //                 existingETickets.push(passengers[i].eTicket4.value);
    //         }
    //     }
    //     return false;
    // }

    // onSqVerifyAllChanged = async () => {
    //     if (this._checkDuplicateETix()) {
    //         this.setState({
    //             showSnackbar: true,
    //             snackbarVariant: 'error',
    //             snackbarMessage: 'Please ensure no duplicated Ticket'
    //         });
    //         return;
    //     }

    //     const { passengers, paxIdsToReVerify } = this.props;
    //     let ticketDetails = [];
    //     if (paxIdsToReVerify.length < 1) return;

    //     paxIdsToReVerify.forEach(pid => {
    //         let pax = passengers.find(p => p.id.value === pid);
    //         const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4'];
    //         for (let k of keys) {
    //             if (pax[k].value !== '' && !pax[k].markedDelete) {
    //                 ticketDetails.push({
    //                     ticketNumber: pax[k].value,
    //                     lastName: pax.lastName.value,
    //                     passengerId: pax.id.value
    //                 });
    //             }
    //         }
    //     });
    //     const data = { ticketDetails };
    //     const rs = await this.props.groupSubmissionSqApiCheckReVerify(data);
    //     if (!rs.success)
    //         this.setState({
    //             showSnackbar: true,
    //             snackbarVariant: 'error',
    //             snackbarMessage: rs.message
    //         });
    // };

    _validateEticket = (eticket, message) => {
        if (/[^0-9]/g.test(eticket) || eticket.length !== 13) {
            // setTimeout cause any clicking will close snackbar, it causes race condition with onBlur
            setTimeout(() => {
                const snackbarMessage = message
                    ? message
                    : `TL Eticket ${eticket} has to be 13 digits`;
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'error',
                    snackbarMessage
                });
            }, 500);
            return false;
        }
        return true;
    };

    onCheckTLAndSubmit = () => {
        // const { tourLeader } = this.props;
        // const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4'];
        // let valid = true;
        // for (let k of keys) {
        //     if (tourLeader[k] !== '') {
        //         if (!this._validateEticket(tourLeader[k])) {
        //             valid = false;
        //             break;
        //         }
        //     }
        // }
        // if (!valid) return;

        const { origTlId } = this.state;

        if (this._currentTourLeader() !== origTlId) {
            this.setState({ tlDialog: true });
        } else {
            this.setState({
                saveAsDraft: false
            }, () => {
                this.onSubmit();
            });
        }
    };

    onSaveAsDraft = () => {
        const { origTlId } = this.state;

        if (this._currentTourLeader() !== origTlId) {
            this.setState({ tlDialog: true });
        } else {
            this.setState({
                saveAsDraft: true
            }, () => {
                this.onSubmit();
            });
        }
    };

    onTlEditableChange = () => {
        this.setState({ tlEditable: !this.state.tlEditable });
    };

    onTlDialogCancel = () => {
        this.setState({ tlDialog: false });
    };

    onTlDialogProceed = () => {
        this.setState({ tlDialog: false }, () => {
            const { passengers } = this.props;
            const { origTlId } = this.state;
            const p = passengers.find(p => {
                if (p.id.value === origTlId && !p.markedDelete) return p;
            });
            p ? this.setState({ deleteTlDialog: true }) : this.onSubmit();
        });
    };

    onDeleteTlDialogRetain = () => {
        this.setState({ deleteTlDialog: false }, () => {
            this.onSubmit();
        });
    };

    onDeleteTlDialogDelete = () => {
        this.setState({ deleteTlDialog: false }, () => {
            const { origTlId } = this.state;
            this.props.markAsDelete(origTlId);
            this.onSubmit();
        });
    };

    onTourLeaderDetailsBlur = () => {
        const { tourLeader } = this.props;
        const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4'];
        for (let k of keys) {
            if (tourLeader[k] !== '') {
                if (!this._validateEticket(tourLeader[k])) break;
            }
        }
    };

    render() {
        const {
            classes,
            group,
            tourLeader,
            passengers,
            getGroupAndPassengersIsLoading,
            updateGroupAndPassengersIsLoading,
            groupSubmissionSqApiCheckIsLoading,
            approvalFilesUploadIsLoading,
            rejectGroupAndPassengersIsLoading,
            groupSubmissionSqApiCheckReVerifyIsLoading,
            paxIdsToReVerify,
            rejectedPassengers
        } = this.props;
        const {
            totalPax,
            passengersHeaders,
            passengersDataKeys,
            files,
            tourLeaderDetailsForm,
            groupRefForm,
            groupType,
            viewOnly,
            deletedOriginalAttachmentIdx,
            deleteDialog,
            transportMeanError,
            transportRemarksError,
            attachmentError,
            deleteNStatusDialog,
            tlEditable,
            origTlId,
            deleteTlDialog,
            tlDialog
        } = this.state;
        let unDeletedPassengers = passengers.filter(
            passenger => passenger.isDeleted.value === false
        );
        const isLoading =
            getGroupAndPassengersIsLoading ||
            updateGroupAndPassengersIsLoading ||
            approvalFilesUploadIsLoading ||
            rejectGroupAndPassengersIsLoading ||
            groupSubmissionSqApiCheckReVerifyIsLoading;

        return (
            <Grid item>
                <Dialog
                    open={deleteTlDialog}
                    scroll={'body'}
                    onClose={this.onDeleteTlDialogCancel}
                >
                    <DialogTitle>
                        Delete/Retain original tour leader
                    </DialogTitle>
                    <DialogContent>
                        Do you want to delete original tour leader?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color={'default'}
                            variant='text'
                            onClick={this.onDeleteTlDialogRetain}
                        >
                            Retain
                        </Button>
                        <Button
                            color={'primary'}
                            variant='contained'
                            onClick={this.onDeleteTlDialogDelete}
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                <StyledGeneralDialog
                    showDialog={tlDialog}
                    title={'Tour leader changed'}
                    actionText={'Proceed'}
                    onClose={this.onTlDialogCancel}
                    onAction={this.onTlDialogProceed}
                >
                    Tour leader has changed do you want to proceed?
                </StyledGeneralDialog>
                <DeleteDialog
                    showDeleteDialog={deleteNStatusDialog}
                    dialogTitle={''}
                    onDeleteConfirm={this.onDeleteNStatusConfirm}
                    onDeleteCancel={this.onDeleteNStatusCancel}
                >
                    All 'N' status will be marked as delete
                </DeleteDialog>
                <DeleteDialog
                    showDeleteDialog={deleteDialog}
                    dialogTitle={'Warning'}
                    onDeleteConfirm={this.onDeleteConfirm}
                    onDeleteCancel={this.onDeleteCancel}
                >
                    The following attachments will be removed:
                    <ul>
                        {group.attachmentUris.map((a, i) => {
                            if (deletedOriginalAttachmentIdx.has(i)) {
                                return <li key={'deleting' + i}>{a}</li>;
                            }
                        })}
                    </ul>
                </DeleteDialog>

                <SqApiCriteriasDialog
                    criterias={this.state.criterias}
                    criteriasMessage={this.state.criteriasMessage}
                    viewCriterias={this.state.viewCriterias}
                    onViewCriteriasClose={this.onViewCriteriasClose}
                />

                <StyledSection
                    title={`Group Reference No:  ${
                        this.props.group.refNo ? this.props.group.refNo : ''
                    } ${this._groupStatus()}`}
                    isLoading={isLoading}
                >
                    {group && (
                        <GeneralFormColumnsContainer
                            columns={2}
                            formFields={groupRefForm}
                            formValues={group}
                            onFieldChange={this.onGroupRefChange}
                        />
                    )}
                    {group && <Spacing height={20} />}
                    {group.rejectionReason && (
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={2}>
                                Rejection Reason
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    disabled
                                    margin='normal'
                                    variant='outlined'
                                    value={group.rejectionReason}
                                    fullWidth
                                    style={{ marginLeft: '1rem' }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </StyledSection>

                <StyledSection
                    title={'Tour Leader Details'}
                    isLoading={isLoading}
                >
                    {tourLeader && (
                        <GeneralFormColumnsContainer
                            columns={2}
                            formFields={tourLeaderDetailsForm}
                            formValues={this._formatHumanFriendlyDate(
                                tourLeader
                            )}
                            onFieldChange={this.onTourLeaderDetailsChange}
                            // onBlur={this.onTourLeaderDetailsBlur}
                        />
                    )}
                    <FormControlLabel
                        control={
                            <Switch
                                value={tlEditable}
                                onChange={this.onTlEditableChange}
                                color={'primary'}
                            />
                        }
                        disabled={viewOnly}
                        label={'Edit tour leader'}
                    />

                    {tourLeader && <Spacing height={40} />}
                </StyledSection>

                <StyledSection title={'Card assignment'} isLoading={isLoading}>
                    <Grid item>
                        <RadioGroup
                            row
                            value={this.props.group.cardAssignment}
                            onChange={this.onCardAssignmentChange}
                        >
                            <FormControlLabel
                                value={MULTI_CARD_UNLOADED}
                                control={<Radio color='primary' />}
                                label='Multiple Cards (Unloaded)'
                            />
                            <FormControlLabel
                                value={MULTI_CARD_DIGITAL}
                                control={<Radio color='primary' />}
                                label='Multiple Cards (Digital)'
                            />
                            <FormControlLabel
                                value={SINGLE_CARD_DIGITAL}
                                control={<Radio color='primary' />}
                                label='Single Card (Digital)'
                            />
                            <FormControlLabel
                                value={MULTI_CARD_EV}
                                control={<Radio color='primary' />}
                                label='Multiple Cards (Preloaded eV)'
                            />
                        </RadioGroup>
                    </Grid>
                </StyledSection>
                <StyledSection title={'3-Sector only'} isLoading={isLoading}>
                    <Grid container spacing={24} direction={'column'}>
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={2}>
                                Land/Sea
                            </Grid>
                            <Grid item>
                                <RadioGroup
                                    row
                                    value={group.transportMean}
                                    onChange={e =>
                                        this.onRadioChange('transportMean', e)
                                    }
                                >
                                    <FormControlLabel
                                        value={'na'}
                                        control={<Radio />}
                                        label={'NA'}
                                        disabled={viewOnly}
                                    />
                                    <FormControlLabel
                                        value={'sea'}
                                        control={<Radio />}
                                        label={'Sea'}
                                        disabled={viewOnly}
                                    />
                                    <FormControlLabel
                                        value={'land'}
                                        control={<Radio />}
                                        label={'Land'}
                                        disabled={viewOnly}
                                    />
                                </RadioGroup>
                                {!!transportMeanError && (
                                    <FormHelperText error>
                                        {transportMeanError}
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {group.transportMean !== 'na' && (
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={2}>
                                Land/Sea/Remarks
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    id=''
                                    label=''
                                    margin='normal'
                                    variant='outlined'
                                    fullWidth
                                    value={group.transportRemarks || ''}
                                    onChange={e =>
                                        this.ontransportMeanRemarksChange(
                                            'transportRemarks',
                                            e
                                        )
                                    }
                                    disabled={viewOnly}
                                    error={!!transportRemarksError}
                                    helperText={transportRemarksError}
                                />
                            </Grid>
                        </Grid>
                    )}

                    {!viewOnly && group.transportMean !== 'na' && (
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={2}>
                                Land/Sea booking ref
                            </Grid>
                            <Grid item>
                                <GeneralDropzone onDrop={this.onDrop} />
                                {!!attachmentError && (
                                    <FormHelperText error>
                                        {attachmentError}
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    {!viewOnly && group.transportMean !== 'na' && (
                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item md={2}>
                                {' '}
                            </Grid>
                            <Grid item>
                                <ol>
                                    {files.map((f, i) => (
                                        <li key={'files' + i}>
                                            <Grid
                                                container
                                                item
                                                spacing={24}
                                                alignItems={'center'}
                                            >
                                                <Grid item>{f.name}</Grid>
                                                <Grid item>
                                                    <RemoveCircle
                                                        className={
                                                            classes.pointer
                                                        }
                                                        color={'secondary'}
                                                        onClick={() =>
                                                            this.onRemoveCircleClick(
                                                                f,
                                                                i
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </li>
                                    ))}
                                </ol>
                            </Grid>
                        </Grid>
                    )}

                    {group.presignedAttachmentUris.length > 0 && (
                        <Grid container item spacing={16} direction={'column'}>
                            <Grid item>
                                <Typography variant={'subtitle2'}>
                                    Original Attachments
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Paper>
                                    <List>
                                        {group.presignedAttachmentUris.map(
                                            (alink, i) => (
                                                <ListItem
                                                    key={'attachment' + i}
                                                    className={
                                                        deletedOriginalAttachmentIdx.has(
                                                            i
                                                        )
                                                            ? classes.markedDelete
                                                            : ''
                                                    }
                                                >
                                                    <ListItemText
                                                        classes={{
                                                            root:
                                                                classes.listItemText
                                                        }}
                                                    >
                                                        {i + 1}.
                                                    </ListItemText>
                                                    <ListItemText
                                                        onClick={() =>
                                                            this.onAttachmentClick(
                                                                alink
                                                            )
                                                        }
                                                        classes={{
                                                            root:
                                                                classes.listItemText
                                                        }}
                                                        className={
                                                            classes.pointer
                                                        }
                                                    >
                                                        {
                                                            group
                                                                .attachmentUris[
                                                                i
                                                            ]
                                                        }
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                        {!deletedOriginalAttachmentIdx.has(
                                                            i
                                                        ) && (
                                                            <StyledDeleteBtn
                                                                onClick={() =>
                                                                    this.onDeleteOrigAttachment(
                                                                        i
                                                                    )
                                                                }
                                                                disabled={
                                                                    viewOnly
                                                                }
                                                            />
                                                        )}
                                                        {deletedOriginalAttachmentIdx.has(
                                                            i
                                                        ) && (
                                                            <StyledUndoBtn
                                                                onClick={() =>
                                                                    this.onUndoDeleteOrigAttachment(
                                                                        i
                                                                    )
                                                                }
                                                                disabled={
                                                                    viewOnly
                                                                }
                                                            />
                                                        )}
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        )}
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>
                    )}
                </StyledSection>

                <StyledSection
                    title={'Remarks'}
                    isLoading={isLoading || groupSubmissionSqApiCheckIsLoading}
                >
                    <TextField
                        multiline
                        fullWidth
                        rows={2}
                        id=''
                        label=''
                        margin='normal'
                        variant='outlined'
                        disabled={viewOnly}
                        value={group.remarks}
                        onChange={e =>
                            this.onGroupRefChange('remarks', e.target.value)
                        }
                    />
                </StyledSection>

                <StyledSection
                    title={'Passengers'}
                    isLoading={isLoading || groupSubmissionSqApiCheckIsLoading}
                >
                    <Grid container direction={'column'}>
                        <Grid container item spacing={24}>
                            <Grid item>
                                <Typography variant={'h6'}>
                                    Total records submitted: {totalPax}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24}>
                            <Grid item>
                                <Typography variant={'body1'} paragraph={true}>
                                    Records with error:{' '}
                                    {this.state.paxWithNIssue}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'} paragraph={true}>
                                    {!group.approval
                                        ? 'Records requiring approval'
                                        : 'Approved'}
                                    : {this.state.paxWithMIssue}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'} paragraph={true}>
                                    Records eligible: {this.state.paxWithYIssue}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ width: 'inherit' }}>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        const redemptionTransactionId = this.props.redemptionTransaction.id;
                                        window.open(
                                            '/print-card?state=ctp_app&id=' + redemptionTransactionId
                                        );
                                    }}
                                    variant='contained'
                                >
                                    Print All Cards
                                </Button>
                            </Grid>
                            <br />
                            <PassengersTableEditable
                                data={unDeletedPassengers}
                                headers={passengersHeaders}
                                dataKeys={passengersDataKeys}
                                onCheckBox={this.onCheckBox}
                                onDelete={this.onRowDelete}
                                onUndo={this.onRowUndo}
                                onETicketIssueClick={this.onETicketIssueClick}
                                // onSqCheckBtnClick={this.onSqCheckBtnClick}
                                origTlId={origTlId}
                                viewOnly={viewOnly}
                            />
                        </Grid>

                        {!viewOnly && (
                            <Grid
                                container
                                item
                                spacing={24}
                                alignItems={'center'}
                                className={classes.marginTop12}
                            >
                                {groupType === GROUP_TYPE_PENDING_APPROVAL && (
                                    <Grid item>
                                        <FormControl variant={'outlined'}>
                                            <InputLabel
                                                ref={ref => {
                                                    this.InputLabelRef = ref;
                                                }}
                                                htmlFor='action'
                                            >
                                                Action
                                            </InputLabel>
                                            {groupType ===
                                                GROUP_TYPE_PENDING_APPROVAL && (
                                                <Select
                                                    value={this.state.action}
                                                    input={
                                                        <OutlinedInput
                                                            name='action'
                                                            id='action'
                                                            labelWidth={50}
                                                        />
                                                    }
                                                    label={'Action'}
                                                    displayEmpty
                                                    onChange={
                                                        this.onStatusChange
                                                    }
                                                    variant={'filled'}
                                                    name='action'
                                                >
                                                    <MenuItem
                                                        value={
                                                            'approveSelected'
                                                        }
                                                    >
                                                        Approve Selected
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'approveAll'}
                                                    >
                                                        Approve All
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'rejectAll'}
                                                    >
                                                        Reject All
                                                    </MenuItem>
                                                </Select>
                                            )}
                                        </FormControl>
                                    </Grid>
                                )}
                                {groupType === GROUP_TYPE_PENDING_APPROVAL && (
                                    <Grid item>
                                        {!this.state.allChecked && (
                                            <Button
                                                color={'primary'}
                                                variant='contained'
                                                // disabled={
                                                //     this.state
                                                //         .sqApiFailedCount === 0
                                                // }
                                                onClick={this.onCheckAll}
                                            >
                                                Check All
                                            </Button>
                                        )}
                                        {this.state.allChecked && (
                                            <Button
                                                color={'primary'}
                                                variant='contained'
                                                // disabled={
                                                //     this.state
                                                //         .sqApiFailedCount === 0
                                                // }
                                                onClick={this.onUncheckAll}
                                            >
                                                Uncheck All
                                            </Button>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        )}

                        {this.state.action === 'rejectAll' && (
                            <Grid
                                container
                                item
                                spacing={24}
                                direction={'column'}
                            >
                                <Grid item xs={6}>
                                    <TextField
                                        id='rejectionReason'
                                        label='Reason for rejecting'
                                        margin='normal'
                                        fullWidth
                                        variant='outlined'
                                        value={group.rejectionReason}
                                        onChange={e =>
                                            this.onGroupRefChange(
                                                'rejectionReason',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {/* {groupType === GROUP_TYPE_ACTION_REQUIRED && (
                            <Grid
                                container
                                item
                                spacing={24}
                                alignItems={'center'}
                            >
                                <Grid item>
                                    <Button
                                        color={'secondary'}
                                        variant='contained'
                                        onClick={this.onDeleteNStatus}
                                    >
                                        Delete N status
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color={'primary'}
                                        variant='contained'
                                        disabled={paxIdsToReVerify.length < 1}
                                        onClick={this.onSqVerifyAllChanged}
                                    >
                                        Sq verify all changed
                                    </Button>
                                </Grid>
                            </Grid>
                        )} */}

                        {!viewOnly && (
                            <Grid
                                container
                                item
                                spacing={24}
                                justify={'space-between'}
                                className={classes.marginTop12}
                            >
                                <Grid
                                    container
                                    item
                                    spacing={24}
                                    alignItems={'center'}
                                    xs={6}
                                >
                                    <Grid item>
                                        <Button
                                            color={'primary'}
                                            onClick={this.onCancel}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color={'primary'}
                                            variant='contained'
                                            disabled={
                                                paxIdsToReVerify.length > 0
                                            }
                                            onClick={this.onCheckTLAndSubmit}
                                        >
                                            {groupType ===
                                                GROUP_TYPE_ACTION_REQUIRED &&
                                                'Submit for approval'}
                                            {groupType ===
                                                GROUP_TYPE_PENDING_APPROVAL &&
                                                'Submit'}
                                        </Button>
                                    </Grid>
                                    <Grid>
                                        {groupType ===
                                            GROUP_TYPE_ACTION_REQUIRED && (
                                            <Button
                                                color={'primary'}
                                                variant='contained'
                                                disabled={
                                                    paxIdsToReVerify.length > 0
                                                }
                                                onClick={this.onSaveAsDraft}
                                            >
                                                Save as draft
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <ScrollToTopBtn />
                                </Grid>
                            </Grid>
                        )}

                        {viewOnly &&
                            hasApproverPermissions() &&
                            groupType === GROUP_TYPE_PENDING_COLLECTION && (
                                <Grid
                                    container
                                    item
                                    spacing={24}
                                    justify={'space-between'}
                                >
                                    <Grid
                                        container
                                        item
                                        spacing={24}
                                        alignItems={'center'}
                                        xs={6}
                                    >
                                        <Grid item>
                                            <Button
                                                color={'primary'}
                                                variant='contained'
                                                disabled={
                                                    paxIdsToReVerify.length > 0
                                                }
                                                onClick={this.onUnapproveGroup}
                                            >
                                                Unapproved Group
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <ScrollToTopBtn />
                                    </Grid>
                                </Grid>
                            )}

                        <Grid container item justify={'space-between'} />
                    </Grid>
                </StyledSection>

                {rejectedPassengers.length > 0 && (
                    <StyledSection title={'Rejected Passengers'}>
                        <PassengersTableEditable
                            data={rejectedPassengers}
                            headers={passengersHeaders}
                            dataKeys={passengersDataKeys}
                            onETicketIssueClick={this.onETicketIssueClick}
                            viewOnly={true}
                        />
                    </StyledSection>
                )}

                <StyledSnackbar
                    open={this.state.showSnackbar}
                    onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage}
                    variant={this.state.snackbarVariant}
                />
            </Grid>
        );
    }
}

const styles = theme => ({
    container: { ...theme.container },
    dialog: { width: '20rem' },
    listItemText: { flex: 'none' },
    pointer: { cursor: 'pointer' },
    markedDelete: { ...theme.deletedBackground },
    marginTop12: { marginTop: '12px' }
});

const mapStateToProps = state => {
    const {
        groupApprovalDuck: {
            passengers,
            tourLeader,
            group,
            getGroupAndPassengersIsLoading,
            updateGroupAndPassengersIsLoading,
            groupSubmissionSqApiCheckIsLoading,
            approvalFilesUploadIsLoading,
            groupMetaInfo,
            rejectGroupAndPassengersIsLoading,
            paxIdsToReVerify,
            groupSubmissionSqApiCheckReVerifyIsLoading,
            rejectedPassengers,
            redemptionTransaction
        }
    } = state;

    return {
        passengers,
        tourLeader,
        group,
        getGroupAndPassengersIsLoading,
        updateGroupAndPassengersIsLoading,
        groupSubmissionSqApiCheckIsLoading,
        approvalFilesUploadIsLoading,
        groupMetaInfo,
        rejectGroupAndPassengersIsLoading,
        paxIdsToReVerify,
        groupSubmissionSqApiCheckReVerifyIsLoading,
        rejectedPassengers,
        redemptionTransaction
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveGroupEditFormRecord: record =>
            dispatch(saveGroupEditFormRecord(record)),
        markAsDelete: id => dispatch(markAsDelete(id)),
        unmarkDelete: id => dispatch(unmarkDelete(id)),
        updatePassenger: (ridx, k, v) => dispatch(updatePassenger(ridx, k, v)),
        updateGroupForm: (section, k, v) =>
            dispatch(updateGroupForm(section, k, v)),
        updateApprove: status => dispatch(updateApprove(status)),
        getGroupAndPassengers: data => dispatch(getGroupAndPassengers(data)),
        updateGroupAndPassengers: ({
            passengers,
            group,
            tourLeader,
            groupActionType
        }) =>
            dispatch(
                updateGroupAndPassengers({
                    passengers,
                    group,
                    tourLeader,
                    groupActionType
                })
            ),
        rejectGroupAndPassengers: data =>
            dispatch(rejectGroupAndPassengers(data)),
        // groupSubmissionSqApiCheck: (pidx, data) =>
        //     dispatch(groupSubmissionSqApiCheck(pidx, data)),
        approvalFilesUpload: data => dispatch(approvalFilesUpload(data)),
        updateAllPassengers: passengers =>
            dispatch(updateAllPassengers(passengers)),
        // groupSubmissionSqApiCheckReVerify: data =>
        //     dispatch(groupSubmissionSqApiCheckReVerify(data)),
        updateGroupApprovalDuck: (k, v) =>
            dispatch(updateGroupApprovalDuck(k, v)),
        updatePassengersTourLeaderFlag: id =>
            dispatch(updatePassengersTourLeaderFlag(id)),
        groupApprovalGroupCheckRedeemedPassenger: data =>
            dispatch(groupApprovalGroupCheckRedeemedPassenger(data)),
        getCampaignCodes: () => dispatch(getCampaignCodes())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(GroupReferenceForm));
