import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    withStyles,
    Button,
    TextField,
    Typography,
    Grid,
    Paper
} from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import {
    login,
    authSendOtp,
    authAuthenticateWithOtp,
    authSendEmailOtp,
    authAuthenticateWithEmailOtp
} from '../ducks/authDuck';
import { TITLE_PREFIX } from '../constants/titlePrefix';
import MySnackbarContent from '../components/MySnackbarContent';
import LoadingContainer from '../containers/loadingContainer';
import { maskContactNo } from '../helpers/utilities';
import Logo from '../components/Logo';
import { getSettings } from '../config/config.js';

class LoginScreen2 extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            usernameOrEmailAddress: '',
            usernameOrEmailAddressError: '',
            password: '',
            passwordError: '',
            verifyOtpScreen: false,
            verifyEmailOtpScreen: false,
            otp: '',
            otpError: '',
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: '',
            otpResendAttempts: 0,
            otpResendDisabled: false,
            otpSubmitAttempt: 0
        };

        const settings = getSettings();
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${
            settings.reCaptchaSiteKey
        }`;

        document.body.appendChild(script);
    }

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Login`;

        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            this.props.history.push('/dashboard');
        }
    }

    componentDidUpdate(prevState) {
        if (prevState.otpResendAttempts !== this.state.otpResendAttempts) {
            if (this.state.otpResendAttempts > 1) {
                this.setState({ otpResendDisabled: true });
            }
        }
    }

    onTextFieldChange = name => e => {
        const errorKey = name + 'Error';
        this.setState({ [name]: e.target.value, [errorKey]: '' });
    };

    // onKeyPress = e => {
    // 	if (e.key === 'Enter') this.onSubmit()
    // }

    validate() {
        if (this.state.usernameOrEmailAddress === '')
            this.setState({
                usernameOrEmailAddressError: 'This field is required'
            });
        if (this.state.password === '')
            this.setState({ passwordError: 'This field is required' });
        return !(
            this.state.usernameOrEmailAddress === '' ||
            this.state.password === ''
        );
    }

    genReCaptchaToken = () => {
        const settings = getSettings();

        return window.grecaptcha.execute(settings.reCaptchaSiteKey, {
            action: 'login'
        });
    };

    onSubmit = async () => {
        this.setState({ showSnackbar: false });
        if (!this.validate()) return;

        if (!this.props.isLoggedIn) {
            const { usernameOrEmailAddress, password } = this.state;
            const captchaToken = await this.genReCaptchaToken();
            const data = { usernameOrEmailAddress, password, captchaToken };
            const rs = await this.props.authSendOtp(data);
            rs.success
                ? this.setState({ verifyOtpScreen: true })
                : this.setState({
                      showSnackbar: true,
                      snackbarVariant: 'error',
                      snackbarMessage: rs.message
                  });
        } else {
            this.props.history.push('/dashboard');
        }
    };

    onSubmitEmail = async () => {
        this.setState({ showSnackbar: false });
        if (!this.validate()) return;

        if (!this.props.isLoggedIn) {
            const { usernameOrEmailAddress, password } = this.state;
            const captchaToken = await this.genReCaptchaToken();
            const data = { usernameOrEmailAddress, password, captchaToken };
            const rs = await this.props.authSendEmailOtp(data);
            this.genReCaptchaToken();
            rs.success
                ? this.setState({ verifyEmailOtpScreen: true })
                : this.setState({
                      showSnackbar: true,
                      snackbarVariant: 'error',
                      snackbarMessage: rs.message
                  });
        } else {
            this.props.history.push('/dashboard');
        }
    };

    onResend = async () => {
        this.setState({ otpSubmitAttempt: 0 });

        const captchaToken = await this.genReCaptchaToken();
        const { usernameOrEmailAddress, password } = this.state;
        const data = { usernameOrEmailAddress, password, captchaToken };
        const rs = await this.props.authSendOtp(data);
        rs.success
            ? this.setState({
                  showSnackbar: true,
                  snackbarVariant: 'success',
                  snackbarMessage: 'OTP sent',
                  otpResendAttempts: this.state.otpResendAttempts + 1
              })
            : this.setState({
                  showSnackbar: true,
                  snackbarVariant: 'error',
                  snackbarMessage: rs.message,
                  otpResendAttempts: this.state.otpResendAttempts + 1
              });
    };

    onResendEmailOtp = async () => {
        this.setState({ otpSubmitAttempt: 0 });

        const { usernameOrEmailAddress, password } = this.state;
        const captchaToken = await this.genReCaptchaToken();
        const data = { usernameOrEmailAddress, password, captchaToken };
        const rs = await this.props.authSendEmailOtp(data);
        rs.success
            ? this.setState({
                  showSnackbar: true,
                  snackbarVariant: 'success',
                  snackbarMessage: 'OTP sent',
                  otpResendAttempts: this.state.otpResendAttempts + 1
              })
            : this.setState({
                  showSnackbar: true,
                  snackbarVariant: 'error',
                  snackbarMessage: rs.message,
                  otpResendAttempts: this.state.otpResendAttempts + 1
              });
    };

    onResendEmailOtpFromSMS = async () => {
        await this.onResendEmailOtp();
        this.setState({ verifyOtpScreen: false, verifyEmailOtpScreen: true });
    };

    clearErrorMessage = () => {
        this.setState({ showSnackbar: false });
    };

    onSubmitOtp = async e => {
        if (e) e.preventDefault();

        const { usernameOrEmailAddress, password, otp } = this.state;

        if (otp === '') {
            this.setState({ otpError: 'This field is required' });
            return;
        } else {
            this.setState({ otpError: '' });
        }

        const { uid } = this.props;
        const data = { usernameOrEmailAddress, password, otp, uid };
        const rs = await this.props.authAuthenticateWithOtp(data);

        if (rs.success) {
            const { shouldChangePassword } = this.props;

            shouldChangePassword
                ? this.props.history.push('/change-password')
                : this.props.history.push('/dashboard');
        } else {
            this.setState({
                otpSubmitAttempt: this.state.otpSubmitAttempt + 1
            });
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: rs.message
            });
        }
    };

    onSubmitEmailOtp = async e => {
        if (e) e.preventDefault();

        const { usernameOrEmailAddress, password, otp } = this.state;

        if (otp === '') {
            this.setState({ otpError: 'This field is required' });
            return;
        } else {
            this.setState({ otpError: '' });
        }

        const data = { usernameOrEmailAddress, password, otp };
        const rs = await this.props.authAuthenticateWithEmailOtp(data);

        if (rs.success) {
            const { shouldChangePassword } = this.props;

            shouldChangePassword
                ? this.props.history.push('/change-password')
                : this.props.history.push('/dashboard');
        } else {
            this.setState({
                otpSubmitAttempt: this.state.otpSubmitAttempt + 1
            });
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: rs.message
            });
        }
    };

    render() {
        const {
            classes,
            isLoggingIn,
            authSendOtpIsLoading,
            authAuthenticateWithOtpIsLoading,
            contactNo
        } = this.props;
        const {
            verifyOtpScreen,
            verifyEmailOtpScreen,
            otp,
            snackbarVariant,
            snackbarMessage,
            otpError,
            usernameOrEmailAddress
        } = this.state;
        const isLoading =
            isLoggingIn ||
            authSendOtpIsLoading ||
            authAuthenticateWithOtpIsLoading;

        return (
            <Grid container spacing={16} className={classes.loginContainer}>
                <div className={classes.loginWrapper}>
                    <div className={classes.logoWrap}>
                        <Logo width={'12rem'} />
                    </div>

                    <Paper className={classes.paper}>
                        {!verifyOtpScreen && !verifyEmailOtpScreen && (
                            <form noValidate autoComplete='off'>
                                <Grid
                                    container
                                    spacing={24}
                                    direction={'column'}
                                >
                                    <Grid item>
                                        <TextField
                                            autoFocus
                                            error={
                                                this.state
                                                    .usernameOrEmailAddressError !==
                                                ''
                                            }
                                            helperText={
                                                this.state
                                                    .usernameOrEmailAddressError
                                            }
                                            id='username'
                                            variant='outlined'
                                            label='Email'
                                            margin='normal'
                                            fullWidth={true}
                                            style={{ width: 400 }}
                                            value={
                                                this.state
                                                    .usernameOrEmailAddress
                                            }
                                            onKeyPress={this.onKeyPress}
                                            onChange={this.onTextFieldChange(
                                                'usernameOrEmailAddress'
                                            )}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <TextField
                                            error={
                                                this.state.passwordError !== ''
                                            }
                                            helperText={
                                                this.state.passwordError
                                            }
                                            id='password'
                                            variant='outlined'
                                            label='Password'
                                            type='password'
                                            margin='normal'
                                            fullWidth={true}
                                            style={{ width: 400 }}
                                            value={this.state.password}
                                            onKeyPress={this.onKeyPress}
                                            onChange={this.onTextFieldChange(
                                                'password'
                                            )}
                                        />
                                    </Grid>

                                    {this.state.showSnackbar && (
                                        <Grid item>
                                            <MySnackbarContent
                                                variant={snackbarVariant}
                                                message={snackbarMessage}
                                                onClose={this.clearErrorMessage}
                                            />
                                        </Grid>
                                    )}

                                    <Grid item>
                                        <Link to={'forgot-password'}>
                                            <Typography paragraph={true}>
                                                Forgot password
                                            </Typography>
                                        </Link>
                                    </Grid>

                                    <Grid item>
                                        <Typography paragraph={true}>
                                            Get your OTP via one of the
                                            following options
                                        </Typography>
                                    </Grid>

                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Button
                                                color='primary'
                                                size='large'
                                                variant='contained'
                                                onClick={this.onSubmit}
                                            >
                                                <ExitToApp /> Mobile
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                color='primary'
                                                size='large'
                                                variant='contained'
                                                onClick={this.onSubmitEmail}
                                            >
                                                <ExitToApp /> Email
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}

                        {verifyOtpScreen && (
                            <form
                                noValidate
                                autoComplete='off'
                                onSubmit={this.onSubmitOtp}
                            >
                                <Grid
                                    container
                                    spacing={24}
                                    direction={'column'}
                                >
                                    <Grid item>
                                        <Typography variant={'body1'}>
                                            Please enter the OTP code sent to{' '}
                                            {maskContactNo(contactNo)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            type='password'
                                            id='otp'
                                            label='OTP'
                                            margin='normal'
                                            variant='outlined'
                                            value={otp}
                                            onChange={this.onTextFieldChange(
                                                'otp'
                                            )}
                                            error={!!otpError}
                                            helperText={otpError}
                                            autoFocus
                                        />
                                    </Grid>
                                    {this.state.showSnackbar && (
                                        <Grid item>
                                            <MySnackbarContent
                                                variant={snackbarVariant}
                                                message={snackbarMessage}
                                                onClose={this.clearErrorMessage}
                                            />
                                        </Grid>
                                    )}
                                    <Grid
                                        container
                                        item
                                        spacing={24}
                                        justify={'center'}
                                    >
                                        <Grid item>
                                            <Button
                                                color={'primary'}
                                                variant='outlined'
                                                onClick={
                                                    this.onResendEmailOtpFromSMS
                                                }
                                                disabled={
                                                    this.state.otpResendDisabled
                                                }
                                            >
                                                Resend OTP via Email
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color={'primary'}
                                                variant='outlined'
                                                onClick={this.onResend}
                                                disabled={
                                                    this.state.otpResendDisabled
                                                }
                                            >
                                                Resend OTP
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color={'primary'}
                                                variant='contained'
                                                onClick={this.onSubmitOtp}
                                                disabled={
                                                    this.state
                                                        .otpSubmitAttempt > 2
                                                }
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}

                        {verifyEmailOtpScreen && (
                            <form
                                noValidate
                                autoComplete='off'
                                onSubmit={this.onSubmitEmailOtp}
                            >
                                <Grid
                                    container
                                    spacing={24}
                                    direction={'column'}
                                >
                                    <Grid item>
                                        <Typography variant={'body1'}>
                                            Please enter the OTP code sent to{' '}
                                            {maskContactNo(
                                                usernameOrEmailAddress
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            type='password'
                                            id='otp'
                                            label='OTP'
                                            margin='normal'
                                            variant='outlined'
                                            value={otp}
                                            onChange={this.onTextFieldChange(
                                                'otp'
                                            )}
                                            error={!!otpError}
                                            helperText={otpError}
                                            autoFocus
                                        />
                                    </Grid>
                                    {this.state.showSnackbar && (
                                        <Grid item>
                                            <MySnackbarContent
                                                variant={snackbarVariant}
                                                message={snackbarMessage}
                                                onClose={this.clearErrorMessage}
                                            />
                                        </Grid>
                                    )}
                                    <Grid
                                        container
                                        item
                                        spacing={24}
                                        justify={'center'}
                                    >
                                        <Grid item>
                                            <Button
                                                color={'primary'}
                                                variant='outlined'
                                                onClick={this.onResendEmailOtp}
                                                disabled={
                                                    this.state.otpResendDisabled
                                                }
                                            >
                                                Resend OTP
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color={'primary'}
                                                variant='contained'
                                                onClick={this.onSubmitEmailOtp}
                                                disabled={
                                                    this.state
                                                        .otpSubmitAttempt > 2
                                                }
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}

                        {isLoading && <LoadingContainer show={true} />}
                    </Paper>
                </div>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    const {
        authDuck: {
            isLoggedIn,
            loginFailedReason,
            isLoggingIn,
            uid,
            authSendOtpIsLoading,
            authAuthenticateWithOtpIsLoading,
            contactNo,
            shouldChangePassword
        }
    } = state;

    return {
        isLoggedIn,
        loginFailedReason,
        isLoggingIn,
        uid,
        authSendOtpIsLoading,
        authAuthenticateWithOtpIsLoading,
        contactNo,
        shouldChangePassword
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (username, password) => dispatch(login(username, password)),
        authSendOtp: (username, password) =>
            dispatch(authSendOtp(username, password)),
        authAuthenticateWithOtp: (username, password, otp, uid) =>
            dispatch(authAuthenticateWithOtp(username, password, otp, uid)),
        authSendEmailOtp: (username, password) =>
            dispatch(authSendEmailOtp(username, password)),
        authAuthenticateWithEmailOtp: (username, password) =>
            dispatch(authAuthenticateWithEmailOtp(username, password))
    };
};

const styles = {
    loginContainer: { width: '100%', height: '100vh' },
    loginWrapper: {
        width: '30rem',
        height: '30rem',
        textAlign: 'center',
        margin: 'auto'
    },
    logoWrap: { padding: '1rem' },
    paper: { padding: '2rem', position: 'relative' }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LoginScreen2));
