import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import ctpPrintBackground from '../images/ctp-print-background-new1.png'
import {getSettings} from "../config/config.js"
import '../styles/printcard.css'
import '../styles/printcard-font.css'
var QRCode = require('qrcode.react')

class CTPCardPrintLayout1 extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			printing: false,
		}
	}

	render() {
		const { classes, card } = this.props
		let settings = getSettings()
		let ctpVoucher = settings.voucher_sources.find(s => s.membership_type == "CTP")
		let genVoucher = ctpVoucher.vouchers.find(s => s.voucher_type == "GEN")
		let voucherCurrency = settings.voucher_currency
		const defaultRemarks = `1 x ${voucherCurrency}${genVoucher.voucher_value}`

		if (card.voucherLists)
			card.issuedVouchersList = JSON.parse(card.voucherLists)

		const validFrom =
			card.issuedVouchersList && card.issuedVouchersList.length && card.issuedVouchersList[0]
				? new Date(card.issuedVouchersList[0].ValidFrom)
				: card.issuedDate
				? new Date(card.issuedDate)
				: null

		const validTo =
			card.issuedVouchersList && card.issuedVouchersList.length && card.issuedVouchersList[0]
				? new Date(card.issuedVouchersList[0].ValidTo)
				: card.voucherValidTo
				? new Date(card.voucherValidTo)
				: null

		const voucherRemarks =
			card.issuedVouchersList && card.issuedVouchersList.length > 0
				? `${card.issuedVouchersList.length} X $${card.issuedVouchersList[0].TypeValue}`
				: card.voucherValuesAndRemarks
				? card.voucherValuesAndRemarks
				: defaultRemarks

		const validFromStr = `${validFrom.getDate()} ${validFrom.toLocaleString(
			'default',
			{
				month:
					'long',
			},
		)} ${validFrom.getFullYear()}`;

		const validToStr = `${validTo.getDate()} ${validTo.toLocaleString(
			'default',
			{
				month:
					'long',
			},
		)} ${validTo.getFullYear()}`;
		const src = '/card-template.html?cardNumber=' + card.cardNo 
			+ '&vQty=Voucher Quantity: ' + voucherRemarks
			+ '&validFrom=Valid from' + validFromStr
			+ '&validTo=Expires on' + validToStr
		return <div className={`${classes.page} _page`}>
			<img src={ctpPrintBackground} className={classes.background} alt='background' />
			<div>
				<div className={classes.titleWrapper}>
					<div className={classes.titleText}>HERE'S YOUR</div>
					<div className={classes.titleText}>CHANGI E-VOUCHER</div>
				</div>
				<div className={classes.description}>
					<div className={classes.desText}>
						Simply present and <span className={classes.desTextBold}>scan your Changi e-Voucher Card QR code prior to</span>
					</div>
					<div className={classes.desText}>
						<span className={classes.desTextBold}>making payment</span> to offset the final amount at any participating outlet
					</div>
				</div>
				<div className={classes.qr}>
					<QRCode value={card.cardNo + '01001'} size={125} />
					<div className={classes.cardNo}>
						{card.cardNo}
					</div>
				</div>
				<div className={classes.cardInfo}>
					<div className={classes.cardDes}>
						<div className={classes.cardDesText}>
							Accepted at participating outlets at Changi
						</div>
						<div className={classes.cardDesText}>
							Airport main terminals and
						</div>
						<div className={classes.cardDesText}>
							Jewel Changi Airport.
						</div>
					</div>
					<div className={classes.voucherDes}>
						<div className={classes.voucherQty}>
							Voucher Quantity: {voucherRemarks}
						</div>
						<div className={classes.cardDesText}>
							Valid from {validFromStr}
						</div>
						<div className={classes.cardDesText}>
							Expires on {validToStr}
						</div>
					</div>
				</div>
				<div className={classes.webInfo}>
					<div>
						Visit <span className={classes.webInfoLink}><u>https://ev.changirewards.com</u></span> to check for
					</div>
					<div>
						<span className={classes.webInfoLink}>available balance</span>, validity of the card and the full
					</div>
					<div>
						list of the <span className={classes.webInfoLink}>participating outlets</span> in Changi Airport
					</div>
				</div>
				<div className={classes.tnc}>
					<div className={classes.tncTitle}>
						Terms and Conditions
					</div>
					<div className={classes.tncItem}>
						• This Changi e-Voucher Card copy contains Changi e-Voucher(s) with no minimum spend required. E-Voucher(s) should be utilised in $5 denominations and any unused amount will not be refunded.
					</div>
					<div className={classes.tncItem}>
						• Each Changi e-Voucher Card contains Changi e-Voucher(s) with such value as determined by Changi Airport Group (Singapore) Pte Ltd (“CAG”). The Changi e-Voucher(s) credited are in denominations of S$5.
					</div>
					<div className={classes.tncItem}>
						• Changi Airport Group (Singapore) Pte Ltd (“CAG”) may at its absolute discretion, modify the total amount of Changi e-Voucher(s) loaded in the Changi e-Voucher card.
					</div>
					<div className={classes.tncItem}>
						• Changi e-Voucher Card will expire 1 year from the date of issuance.
					</div>
					<div className={classes.tncItem}>
						• No replacement or refund of Changi e-Voucher(s), and no extension of Changi e-Voucher(s)' validity, will be allowed.
					</div>
					<div className={classes.tncItem}>
						• Lost Changi e-Voucher Card will not be replaced
					</div>
					<div className={classes.tncItem}>
						• Subject to the terms and conditions herein, the use of Changi e-Voucher(s) within Changi e-Voucher Card is subject to the terms and conditions governing the use of "Changi Rewards e-Voucher(s)" as set out at Clauses 5.7.3 to 5.7.10 of the (except for Clause 5.7.7) of the Changi Rewards Programme terms and conditions:
https://rewards.changiairport.com/en/terms-and-conditions.html
					</div>
				</div>
				{/* <div className={classes.footer}>
					<div className={classes.footerTop}>
						This section will be updated with the Changi
					</div>
					<div className={classes.footerTop}>
						app artwork
					</div>
					<br />
					<div className={classes.footerBottom}>
						Download Changi App
					</div>
					<div className={classes.footerBottom}>
						rewards.changiairport.com.
					</div>
				</div> */}
			</div>
		</div>
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
	page: {
		overflow: 'hidden',
		backgroundColor: 'white',
		width: '14.8cm',
		height: '21cm',
		border: 0,
		margin: '13px auto',
		/*boxShadow: '1px 1px 3px 1px #333',*/
		borderCollapse: 'separate',
		position: 'relative',
		'-webkit-transform-origin': '0 0',
	},
	background: {
		width: '14.8cm',
		height: '21cm',
		bottom: '-0.5px',
		border: 0,
		margin: 0,
		userSelect: 'none',
		position: 'absolute',
	},
	titleWrapper: {
		position: 'absolute',
		whiteSpace: 'pre',
		fontFamily: 'ff3',
		color: '#443f3a',
		width: '100%',

		lineHeight: '0.9',
		fontStyle: 'normal',
		fontWeight: 'normal',
		visibility: 'visible',
		fontSize: '35px',
		marginTop: '55px',
	},
	titleText: {
		textAlign: 'center',
	},
	description: {
		position: 'absolute',
		whiteSpace: 'pre',
		fontFamily: 'ff5',
		color: '#443f3a',
		marginTop: '130px',
		lineHeight: '1.3',
		fontStyle: 'normal',
		fontWeight: 'normal',
		width: '100%',
		fontSize: '13.5px',
		margin: '130px auto 0 auto',
	},
	desText: {
		textAlign: 'center',
		width: '100%',
	},
	desTextBold: {
		fontFamily: 'ff4',
		lineHeight: 1.004000,
		fontStyle: 'normal',
		fontWeight: 'normal',
		visibility: 'visible',
	},
	qr: {
		position: 'absolute',
		top: '187px',
		right: '93px',
		lineHeight: 1.45,
	},
	cardNo: {
		fontFamily: 'ff5',
		color: '#443f3a',
		fontSize: '12px',
		textAlign: 'center',
	},
	cardInfo: {
		position: 'absolute',
		fontFamily: 'ff5',
		color: '#443f3a',
		lineHeight: '1.2',
		fontStyle: 'normal',
		fontWeight: 'normal',
		width: '72%',
		marginLeft: '14%',
		display: 'flex',
		flexDirection: 'row',
	},
	cardDes: {
		width: '58%',
		marginTop: '340px',
		lineHeight: '1.45',
		fontSize: '11.5px',
		fontFamily: 'ff5',
	},
	cardDesText: {
		textAlign: 'center',
	},
	voucherDes: {
		width: '42%',
		marginTop: '340px',
		lineHeight: '1.45',
		fontSize: '11.5px',
		fontFamily: 'ff5',
	},
	voucherQty: {
		color: '#6c217f',
		textAlign: 'center',
		fontFamily: 'ff5',
		fontWeight: 'bold',
	},
	webInfo: {
		position: 'absolute',
		fontFamily: 'ff5',
		color: '#443f3a',
		width: '100%',
		fontSize: '13.2px',
		top: '411px',
    	left: '175.3px',
		lineHeight: '1.35',
	},
	webInfoLink: {
		fontFamily: 'ff4',
		lineHeight: 1.004000,
		fontStyle: 'normal',
		fontWeight: 'normal',
		visibility: 'visible',
		fontSize: '13.2px',
	},
	tnc: {
		position: 'absolute',
		color: '#443f3a',
		width: '77%',
		marginLeft: '11.5%',
		top: '495px',
		lineHeight: '1.3',
		marginTop: '15px'
	},
	tncTitle: {
		fontFamily: 'ff4',
		fontSize: '9px',
	},
	tncItem: {
		fontFamily: 'ff6',
		fontSize: '8.35px',
	},
	footer: {
		position: 'absolute',
		color: '#000000',
		width: '100%',
		top: '675px',
		lineHeight: '1.3',
		textAlign: 'center',
	},
	footerTop: {
		fontFamily: 'ff3',
		fontSize: '11px',
	},
	footerBottom: {
		fontFamily: 'ff7',
		fontSize: '11px',
	}
})

export default connect()(withStyles(styles)(CTPCardPrintLayout1))
