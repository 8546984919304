import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Fab, Button, Grid } from '@material-ui/core'
import Header from '../containers/Header'
import { Print, ScreenShare } from '@material-ui/icons'
import { resetIndividualFormReducer, updateIndividualFormDuck } from '../ducks/individualFormDuck'
import { TITLE_PREFIX } from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import CollectorSuccessDisplay from '../containers/individual/CollectorSuccessDisplay'
import { HubConnectionBuilder } from '@aspnet/signalr'
import { signalRURL } from '../config/config'
import signalRConstants from '../helpers/signalRConstants'

class IndividualCollectorSuccess extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			consentError: '',
			totalCtpGenValue: 0,
			totalCtpLpcValue: 0,
			undeletedPax: 0,
			TABLE_HEAD: [],
		}
	}

	componentDidMount() {
		document.title = `${TITLE_PREFIX} Individual Collector Success`

		this.hubConnection = new HubConnectionBuilder().withUrl(signalRURL).build()
		this._startSignalRConnection()
		this.hubConnection.on('ReceiveMessage', this._receiveMessage)
		this.hubConnection.onclose(async () => {
			await this._startSignalRConnection()
		})

		const {
			individualFormDuck: { formDetailsList },
		} = this.props
		let totalCtpGenValue = 0
		let totalCtpLpcValue = 0
		let undeletedPax = 0
		for (const p of formDetailsList) {
			if (!p.isDeleted) {
				totalCtpGenValue = totalCtpGenValue + p.ctpGenValue
				totalCtpLpcValue = totalCtpLpcValue + p.ctpLpcValue
				undeletedPax++
			}
		}

		this.setState({ totalCtpGenValue, totalCtpLpcValue, undeletedPax })
	}

	_startSignalRConnection = async () => {
		this.hubConnection
			.start()
			.then(() => console.log('Connection started!'))
			.catch((err) => {
				setTimeout(() => this._startSignalRConnection(), 5000)
				console.log('Error while establishing connection :(')
			})
	}

	_sendMessage = (dataObj, messageType) => {
		const user = localStorage.getItem('username')
		let messageObj = {
			user,
			type: messageType,
			source: signalRConstants.USER_CONCIERGE,
			data: dataObj,
		}
		let messageStr = JSON.stringify(messageObj)
		this.hubConnection.invoke('SendMessage', 'default', messageStr).catch(function(err) {
			return console.error(err.toString())
		})
	}

	_receiveMessage = (user, messageStr) => {
		let messageObj = JSON.parse(messageStr)

		if (messageObj.source === signalRConstants.USER_CONCIERGE) return
		const currentUser = localStorage.getItem('username')
		if (messageObj.user !== currentUser) return

		if (messageObj.data) {
			const { collectorDetails, formState, formDetailsList } = messageObj.data
			this.props.updateCollectorDetails(collectorDetails)
			if (formState) this.setState({ formState })
			if (formDetailsList) this.props.updateFormDetailsList(formDetailsList)
			if (messageObj.type === signalRConstants.CLIENT_DONE) {
				this.setState({ clientIsDone: true, isSharingScreen: false, formState })
			}
		}
	}

	_toolbarButtons = () => {
		return [
			<div>
				<Fab color={'primary'} variant='round' onClick={this.onPrint}>
					<Print />
				</Fab>
				<Fab color={'primary'} variant='round' onClick={this.onScreenShare}>
					<ScreenShare />
				</Fab>
			</div>,
		]
	}

	onScreenShare = () => {
		this._sendMessage(
			{
				collectorDetails: this.props.individualFormDuck.collectorDetails,
				formDetailsList: this.props.individualFormDuck.formDetailsList,
			},
			signalRConstants.DISPLAY_INDIVIDUAL_COLLECTOR_SUCCESS
		)
	}

	onPrint = () => {
		window.print()
	}

	onBackClick = () => {
		this._sendMessage({}, signalRConstants.EXIT_INDIVIDUAL_COLLECTOR_SUCCESS)
		this.props.resetIndividualFormReducer()
		//this.props.resetRedemptionTransactionReducer()

		//this.props.history.push('/individual')
		window.location.replace('/individual')
	}

	onPrintAll = () => {
		window.open('print-card?state=ctp_app&id=' + this.props.individualFormDuck.redemptionTransactionId)
	}

	shouldDisablePrintAll = () => {
		if (
			(this.props.individualFormDuck.ctpDigitalCards && this.props.individualFormDuck.ctpDigitalCards.length) ||
			(this.props.individualFormDuck.preloadedVouchersCards && this.props.individualFormDuck.preloadedVouchersCards.length)
		) {
			return false
		}
		return true
	}

	render() {
		const { classes } = this.props

		return (
			<div>
				<GlobalTimeChecker />

				<Header history={this.props.history} />

				<div className={classes.container}>
					<CollectorSuccessDisplay toolbarButtons={this._toolbarButtons} history={this.props.history} />

					<Grid container item spacing={16} direction={'row'} alignItems={'center'}>
						<Grid item>
							<Button onClick={this.onBackClick} variant='contained'>
								{' '}
								Start New Form{' '}
							</Button>
						</Grid>
						<Grid item>
							<Button onClick={this.onPrintAll} disabled={this.shouldDisablePrintAll()} variant='contained'>
								Print All
							</Button>
						</Grid>
					</Grid>
				</div>
			</div>
		)
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
	row: { display: 'flex' },
	rowItem: { display: 'flex', flex: 1, marginRight: 60, alignItems: 'center' },
	flexStart: { alignItems: 'flex-start' },
	consentText: { fontSize: '0.8125rem' },
})

const mapStateToProps = (state) => {
	const { individualFormDuck } = state

	return { individualFormDuck }
}

const mapDispatchToProps = (dispatch) => {
	return {
		resetIndividualFormReducer: () => dispatch(resetIndividualFormReducer()),
		//resetRedemptionTransactionReducer: () => dispatch(resetRedemptionTransactionReducer()),
		updateIndividualFormDuck: (obj) => dispatch(updateIndividualFormDuck(obj)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(IndividualCollectorSuccess))
