import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {withStyles, Typography} from '@material-ui/core'

class StyledPlaceholder extends PureComponent {

    render() {
        const {classes} = this.props

        return (
            <div className={classes.container}>
                <Typography variant={'h5'} className={classes.text}>{this.props.message}</Typography>
            </div>
        )
    }
}

const styles = theme => ({
    container: {
        padding: '2rem', backgroundColor: theme.palette.grey[200], display: 'flex', justifyContent: 'center',
        alignItems: 'center', borderRadius: '.25rem'
    },
    text: {color: theme.palette.grey[400]}
})

StyledPlaceholder.propTypes = {
    message: PropTypes.string.isRequired
}

export default withStyles(styles)(StyledPlaceholder)