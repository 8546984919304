import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Snackbar} from '@material-ui/core'
import MySnackbarContent from './MySnackbarContent'

class StyledSnackbar extends Component {
    render() {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <MySnackbarContent variant={this.props.variant || 'success'} onClose={this.props.onClose}
                    message={this.props.message}/>
            </Snackbar>
        )
    }
}

StyledSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.string,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
}

export default StyledSnackbar