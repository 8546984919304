import React from "react";
import {Button} from "@material-ui/core";
import PropTypes from 'prop-types'
import PromiseModal from "./PromiseModal"

class PromiseModalWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        };
        this.promiseInfo = {};
    }

    show = async () => {
        return new Promise((resolve, reject) => {
            this.promiseInfo = {
                resolve,
                reject
            };
            this.setState({
                modalOpen: true
            });
        });
    };

    hide = () => {
        this.setState({
            modalOpen: false
        });
    };

    render() {
        const { modalOpen } = this.state;

        return (
            <PromiseModal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                modalOpen={modalOpen}
            >
                <div>
                    <p> {this.props.content}</p>
                    <Button
                        color="secondary"
                        style={style.proceedBtnStyle}
                        onClick={() => {
                            this.hide();
                            this.promiseInfo.resolve(true);
                        }}
                    >
                        {this.props.proceedMessage || "proceed"}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            this.hide();
                            this.promiseInfo.resolve(false);
                        }}
                    >
                        {this.props.cancelMessage || "cancel"}
                    </Button>
                </div>
            </PromiseModal>

        );
    }

    getResolve() {
        const { resolve = () => {} } = this.promiseInfo || {};
        return result => {
            resolve(result);
            this.hide();
        };
    }

    getReject() {
        const { reject = () => {} } = this.promiseInfo || {};
        return err => {
            reject(err);
            this.hide();
        };
    }
}


PromiseModalWrapper.propTypes = {
    proceedMessage: PropTypes.string.isRequired,
    cancelMessage: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
}

const style = {
    proceedBtnStyle: {
        marginRight: "10px"
    }
}

export default (PromiseModalWrapper)