import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {withStyles, TextField} from '@material-ui/core'
import Select from 'react-select'

function inputComponent({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />
}

function Control(props) {
    return (
        <TextField
            fullWidth
            variant={'outlined'}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps
                }
            }}
            {...props.selectProps.textFieldProps}
            error={props.selectProps.error}
            helperText={props.selectProps.helperText}
        />
    )
}

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 2
    })
}

class StyledSelect extends PureComponent {
    render() {
        const {classes} = this.props

        return (
            <Select
                components={{Control}}
                textFieldProps={{label: this.props.label, InputLabelProps: {shrink: true}}}
                classes={classes}
                {...this.props}
                styles={customStyles}
                isDisabled = {this.props.disabled}
            />
        )
    }
}

const styles = theme => ({
    input: {display: 'flex', padding: '.6rem .8rem'}
})

StyledSelect.propTypes = {
    value: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string
}

export default withStyles(styles)(StyledSelect)