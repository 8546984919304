import React, {PureComponent} from 'react'
import classNames from 'classnames'
import {withStyles, Fab} from '@material-ui/core'
import {CheckCircle} from '@material-ui/icons'
import {green} from '@material-ui/core/colors'

class StyledTinyFabCheckCircle extends PureComponent {
    render() {
        const {classes, customClass} = this.props

        return (
            <Fab className={classNames(classes.tinyFab, customClass)} onClick={this.props.onClick}><CheckCircle/></Fab>
        )
    }
}

const styles = theme => ({
    tinyFab: {
        width: '1.5rem',
        height: '1.5rem',
        minHeight: 'auto',
        position: 'absolute',
        right: '-2rem',
        top: '1rem',
        color: green[600],
        backgroundColor: 'white',
        boxShadow: 'none'
    }
})

export default withStyles(styles)(StyledTinyFabCheckCircle)