import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { withStyles, Table, TableHead, TableRow, TableBody, TableCell, Checkbox, Typography, TablePagination, TableFooter, TextField, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import StyledDeleteBtn from './StyledDeleteBtn'
import StyledEditBtn from './StyledEditBtn'
import StyledViewableBtn from './StyledViewableBtn'
import { USER_ACCOUNTS_TABLE_KEY_NAME } from '../constants/tableKeys'

class GeneralTable extends Component {
	render() {
		const {
			classes,
			rowsPerPage,
			onChangeRowsPerPage,
			onChangePage,
			ActionsComponent,
			page,
			count,
			hasPagination,
			fields,
			rows,
			isViewable,
			isEditable,
			isDeletable,
			isFilterable,
			filter,
			filterLabel,
			tableKeyName
		} = this.props

		let colSpan = fields.length
		if (isViewable) colSpan += 1
		if (isEditable) colSpan += 1
		if (isDeletable) colSpan += 1

		return (
			<div className="search-result-element">
				<Table>
					<TableHead>
						{isFilterable && (
							<TableRow>
								<TableCell colSpan={colSpan}>
									<Grid container item spacing={24} alignItems={'center'}>
										<Grid item md={3}>
											<TextField
												id={filterLabel}
												label={filterLabel}
												margin='normal'
												variant='outlined'
												value={filter}
												onChange={e => this.props.onFilterChange(e)}
												fullWidth
											/>
										</Grid>
									</Grid>
								</TableCell>
							</TableRow>
						)}
						<TableRow>
							{fields.map(field => {
								if (field.type && field.type === 'hidden') {
									return null
								}
								return (
									<TableCell key={field.key} onClick={() => this.props.onHeaderClick(field.key)}>
										{field.label}
									</TableCell>
								)
							})}
							{isViewable && <TableCell>View</TableCell>}
							{isEditable && <TableCell>Edit</TableCell>}
							{isDeletable && <TableCell>Delete</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, ridx) => {
							return (
								<TableRow key={row.id} hover={this.props.hover || false}>
									{this.props.fields.map((field, i) => {
										if (field.type && field.type === 'hidden') {
											return null
										}

										return (
											<TableCell component='th' scope='row' key={field.key}>
												{/* set key as 'seq' to show sequence. eg.{key: 'seq', label: 'S/N'} */}
												{field.key === 'seq' && !hasPagination && <Typography variant={'body1'}>{ridx + 1}</Typography>}

												{field.key === 'seq' && hasPagination && <Typography variant={'body1'}>{page * rowsPerPage + 1 + ridx}</Typography>}

												{field.type && field.type === 'link' && field.url && <Link to={field.url + row[field.key]}>{row[field.key]}</Link>}

												{/* This type is to allow consumer to generate links, and to populate under the appCustomLink for display  */}
												{field.type && field.type === 'appCustomLink' && row[field.key]}

												{field.key === 'status' && tableKeyName === USER_ACCOUNTS_TABLE_KEY_NAME && (
													<Typography>{row.isLockout ? 'Lockout' : row.isActive ? 'Active' : 'Inactive'}</Typography>
												)}

												{field.type && field.type === 'checkbox' && (
													<Checkbox
														checked={row[field.key]}
														onChange={() => this.props.fields[i].onChange(ridx, this.props.fields[i].key, row[field.key])}
														color={'primary'}
													/>
												)}

												{field.type && field.type === 'date' && moment(row[field.key]).format('YYYY-MM-DD')}

												{field.type && field.type === 'image' && <img src={row[field.key]} className={classes.image} alt={''} />}

												{typeof field.render === 'function' && this.props.fields[i].render(row[field.key])}

												{!field.type && !field.render && row[field.key]}
											</TableCell>
										)
									})}

									{this.props.isViewable && (
										<TableCell>
											<StyledViewableBtn onClick={() => this.props.onView(row)} />
										</TableCell>
									)}

									{this.props.isEditable && (
										<TableCell>
											<StyledEditBtn onClick={() => this.props.onEdit(row)} />
										</TableCell>
									)}

									{this.props.isDeletable && (
										<TableCell>
											<StyledDeleteBtn onClick={() => this.props.onDelete(row)} />
										</TableCell>
									)}
								</TableRow>
							)
						})}
					</TableBody>
					{hasPagination && (
						<TableFooter>
							<TableRow>
								<TablePagination
									count={count}
									page={page}
									rowsPerPage={rowsPerPage}
									onChangePage={onChangePage}
									onChangeRowsPerPage={onChangeRowsPerPage}
									rowsPerPageOptions={[]}
									ActionsComponent={ActionsComponent}
								></TablePagination>
							</TableRow>
						</TableFooter>
					)}
				</Table>
			</div>
		)
	}
}

const styles = theme => ({
	image: { maxWidth: '100%' }
})

GeneralTable.propTypes = {
	fields: PropTypes.array.isRequired,
	rows: PropTypes.array.isRequired,
	isEditable: PropTypes.bool,
	isDeletable: PropTypes.bool,
	isViewable: PropTypes.bool,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
	onView: PropTypes.func,
	onHeaderClick: PropTypes.func
}

export default withStyles(styles)(GeneralTable)
