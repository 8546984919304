import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withStyles, Grid, TextField, Button, Typography} from '@material-ui/core'
import Header from '../containers/Header.js'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import StyledSection from '../components/StyledSection'
import SearchGroupResultTable from '../containers/search/SearchGroupResultTable'
import {getGroupsByAttribute} from '../ducks/searchDuck'
import StyledSnackbar from '../components/StyledSnackbar'
import StyledPlaceholder from '../components/StyledPlaceholder'
import SearchNav from '../containers/search/SearchNav'
import {getCurrentAuthHeader, hasPermissions} from '../helpers/utilities'
import {Redirect} from 'react-router-dom'
import {SEARCH_GROUP_PERMISSION} from '../constants/permissions'
import GeneralTextDate from '../components/generalForm/GeneralTextDate'

const FIELDS = [{key: 'refNo', label: 'Group ref no.'}, {key: 'paxCount', label: 'No. of Pax'},
    {key: 'firstFlightArrivalDate', label: 'First Flt arrival date'}, {key: 'lastFlightDepartureDate', label: 'Return Flt Departure Date'},
    {key: 'collectionDate', label: 'Collection date'}, {key: 'tourCompany', label: 'Company'}, {key: 'tourLeader', label: 'Tour Leader'},
    {key: 'submitterName', label: 'Submitted by'}, {key: 'approverName', label: 'Approved by'}, {key: 'submissionTime', label: 'Submitted date'}, {key: 'status', label: 'Status'}]

class SearchGroupScreen extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            TourLeaderFirstName: '',
            TourLeaderLastName: '',
            TourCompany: '',
            LastFlightDepartureDate: '',
            CollectionDate: '',
            RefNo: '',
            TourLeaderDob: '',
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            hasSearched: false
        }
    }

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Search Group`
    }

    onSearchFieldsChange = (k, e) => {
        this.setState({[k]: e.target.value})
    }

    onSubmit = async () => {
        const fields = ['TourLeaderFirstName', 'TourLeaderLastName', 'TourCompany', 'LastFlightDepartureDate', 'CollectionDate', 'RefNo', 'TourLeaderDob']

        let params = {}
        let hasFilter = false
        fields.forEach(f => {
            if (this.state[f] !== '') {
                params[f] = this.state[f]
                hasFilter = true
            }
        })

        if (hasFilter) {
            const rs = await this.props.getGroupsByAttribute({params: params, ...getCurrentAuthHeader()})
            if (!rs.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
            this.setState({hasSearched: true})
        }
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    render() {
        const {classes, searchDuck: {groups, getGroupsByAttributeIsLoading}} = this.props
        const {TourLeaderFirstName, TourLeaderLastName, TourCompany, LastFlightDepartureDate, CollectionDate, RefNo, TourLeaderDob, hasSearched} = this.state

        if (!hasPermissions(SEARCH_GROUP_PERMISSION)) {
            return <Redirect to={'/not-found'}/>
        }

        return (
            <div>
                <GlobalTimeChecker/>
                <Header history={this.props.history}/>
                <SearchNav/>

                <div className={classes.container}>
                    <StyledSection title={'Search criterias'} isLoading={getGroupsByAttributeIsLoading}>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'}>Tour Leader Firstname</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField id="TourLeaderFirstName" label="Tour Leader Firstname" variant="outlined"
                                        value={TourLeaderFirstName}
                                        onChange={e => this.onSearchFieldsChange('TourLeaderFirstName', e)}/>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'}>Tour Leader Lastname</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField id="TourLeaderLastName" label="Tour Leader Lastname" variant="outlined"
                                        value={TourLeaderLastName}
                                        onChange={e => this.onSearchFieldsChange('TourLeaderLastName', e)}/>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'}>Tour Company</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField id="tourCompany" label="Tour Company" variant="outlined"
                                        value={TourCompany}
                                        onChange={e => this.onSearchFieldsChange('TourCompany', e)}/>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'}>Last Flt Departure Date</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container item spacing={8} alignItems={'center'}>
                                        <Grid item>
                                            <GeneralTextDate
                                                id=""
                                                label=""
                                                variant="outlined"
                                                value={LastFlightDepartureDate}
                                                //value="2018-01-01"
                                                onChange={(e) => this.onSearchFieldsChange('LastFlightDepartureDate', e)}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'}>Collection Date</Typography>
                                </Grid>
                                <Grid item>
                                    <GeneralTextDate
                                        id=""
                                        label=""
                                        variant="outlined"
                                        value={CollectionDate}
                                        //value="2018-01-01"
                                        onChange={(e) => this.onSearchFieldsChange('CollectionDate', e)}/>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'}>Ref No</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField id="firstFltDepDate" label="Ref No"
                                        variant="outlined"
                                        value={RefNo}
                                        onChange={e => this.onSearchFieldsChange('RefNo', e)}/>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'}>Date of birth</Typography>
                                </Grid>
                                <Grid item>
                                    <GeneralTextDate
                                        id=""
                                        label=""
                                        variant="outlined"
                                        value={TourLeaderDob}
                                        //value="2018-01-01"
                                        onChange={(e) => this.onSearchFieldsChange('TourLeaderDob', e)}/>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} direction={'column'}>
                                <Grid item>
                                    <Button color={'primary'} variant="contained"
                                        onClick={this.onSubmit}>Submit</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledSection>

                    {hasSearched && <StyledSection title={'Search result'}>
                        {groups.length > 0 &&
                        <SearchGroupResultTable data={groups} fields={FIELDS} history={this.props.history}/>}

                        {groups.length < 1 && <StyledPlaceholder message={'No results found'}/>}
                    </StyledSection>}
                </div>
                <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
            </div>
        )
    }
}

const styles = theme => ({
    container: theme.container
})

const mapStateToProps = state => {
    const {searchDuck, redemptionTransactionPassengersDuck} = state

    return {searchDuck, redemptionTransactionPassengersDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        getGroupsByAttribute: data => dispatch(getGroupsByAttribute(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchGroupScreen))

