import { generateBatches } from '../helpers/batchHelper.js'
import axios from 'axios'
import { getSettings } from '../config/config'
import { getCurrentAuthHeader } from '../helpers/utilities'
import { checkValidStatusCode } from '../helpers/httpHelper'

let settings = getSettings()
let baseURL = settings.baseURL

export const MemberRepo = {
	multipleActivateAndIssueCards: async (passengers) => {
		let passengerBatches = generateBatches({ items: passengers, batchSize: 10 })
		let result = []
		for (let passengerBatch of passengerBatches) {
			const rs = await axios.post(baseURL + '/Member/MultipleActivateAndIssueCard', { passengers: passengerBatch }, getCurrentAuthHeader()).catch((e) => {
				if (!checkValidStatusCode(e.response.status)) {
					const {
						response: {
							data: {
								error: { message },
							},
						},
					} = e
					return { success: false, message: message }
				}
				return { success: false, message: e.response.status + ' Unexpected error' }
			})
			if (!rs.data) {
				return { success: false, message: rs.message }
			}
			if (!rs.data.success) {
				return { success: false, message: 'unexpected error' }
			} else {
			}
			result.push(...rs.data.result.items)
			// if no exception, continue
		}
		return { success: true, result: result }
	},
}
