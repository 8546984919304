import React from 'react'
import {withStyles, Toolbar, Button} from '@material-ui/core'
import CustomNavButton from '../../components/CustomNavButton'
import {hasPermissions} from '../../helpers/utilities'
import {
    USER_ADMIN_CAMPAIGN_READ_PERMISSION, USER_ADMIN_CAMPAIGN_CREATE_PERMISSION, USER_ADMIN_CAMPAIGN_EDIT_PERMISSION,
    USER_ADMIN_ACCOUNT_READ_PERMISSION, USER_ADMIN_ACCOUNT_CREATE_PERMISSION, USER_ADMIN_ACCOUNT_EDIT_PERMISSION,
    USER_ADMIN_ROLE_READ_PERMISSION, USER_ADMIN_ROLE_CREATE_PERMISSION, USER_ADMIN_ROLE_EDIT_PERMISSION,
    USER_ADMIN_BANNER_READ_PERMISSION, USER_ADMIN_BANNER_CREATE_PERMISSION, USER_ADMIN_BANNER_EDIT_PERMISSION
} from '../../constants/permissions'

class AdminSettingsNav extends React.Component {

    render() {
        const {classes} = this.props

        const campaignPermissions = hasPermissions([USER_ADMIN_CAMPAIGN_READ_PERMISSION, USER_ADMIN_CAMPAIGN_CREATE_PERMISSION,
            USER_ADMIN_CAMPAIGN_EDIT_PERMISSION], 'or')
        const accountPermissions = hasPermissions([USER_ADMIN_ACCOUNT_READ_PERMISSION, USER_ADMIN_ACCOUNT_CREATE_PERMISSION,
            USER_ADMIN_ACCOUNT_EDIT_PERMISSION], 'or')
        const accessPermissions = hasPermissions([USER_ADMIN_ROLE_READ_PERMISSION, USER_ADMIN_ROLE_CREATE_PERMISSION,
            USER_ADMIN_ROLE_EDIT_PERMISSION], 'or')
        const bannerPermissions = hasPermissions([USER_ADMIN_BANNER_READ_PERMISSION, USER_ADMIN_BANNER_CREATE_PERMISSION, USER_ADMIN_BANNER_EDIT_PERMISSION], 'or')

        const customCampaignPeriod = props => <CustomNavButton to={'/admin-settings/campaign-period'} {...props}
            activeClassName={classes.active}/>

        const customCreateNewAccount = props => <CustomNavButton to={'/admin-settings/create-new-account'} {...props}
            activeClassName={classes.active}/>

        const customUserAccessAccount = props => <CustomNavButton to={'/admin-settings/user-access-account'} {...props}
            activeClassName={classes.active}/>


        const customBanner = props => <CustomNavButton to={'/admin-settings/banner'} {...props}
            activeClassName={classes.active}/>
        const customSqApi = props => <CustomNavButton to={'/admin-settings/sq-check'} {...props}
            activeClassName={classes.active}/>

        const customIata = props => <CustomNavButton to={'/admin-settings/iata'} {...props}
                                                       activeClassName={classes.active}/>

        const customPhysicalVoucher = props => <CustomNavButton to={'/admin-settings/physical-voucher'} {...props}
                                                      activeClassName={classes.active}/>

        return (
            <Toolbar>
                {campaignPermissions && <Button color={'primary'} component={customCampaignPeriod}>Campaign Period</Button>}

                {accountPermissions && <Button color={'primary'} component={customCreateNewAccount}>User Account</Button>}

                {accessPermissions && <Button color={'primary'} component={customUserAccessAccount}>User Access</Button>}

                {/*<Button color={'primary'} component={customRejectReason}>Reject Reason (Restrospective)</Button>*/}
                {/*<Button color={'primary'} component={customEmailTemplate}>Email Template (Restrospective)</Button>*/}

                {bannerPermissions && <Button color={'primary'} component={customBanner}>Banner</Button>}

                {<Button color={'primary'} component={customIata}>Iata</Button>}

                {campaignPermissions && <Button color={'primary'} component={customSqApi}>SQ API</Button>}

                {campaignPermissions && <Button color={'primary'} component={customPhysicalVoucher}>Physical Voucher</Button>}
            </Toolbar>
        )
    }
}

const styles = theme => ({
    active: {
        backgroundColor: theme.palette.action.selected
    }
})

export default withStyles(styles)(AdminSettingsNav)