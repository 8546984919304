import React, {Component} from 'react'
import {
    withStyles,
    Grid,
    TextField,
    Typography, FormControlLabel, FormControl, Checkbox, Button
} from '@material-ui/core'
import PropTypes from 'prop-types'
import StyledAttachmentList from "../../components/StyledAttachmentList";
import {toBrowserFriendlyDate} from "../../helpers/utilities";

class CollectorDetails extends Component {

    onCollectorFieldChange = (k, e) =>{
        this.props.onCollectorFieldChange(k,e)
    };

    onPromoClick = (url) => {
        window.open(url)
    }

    render() {
        const {classes, viewOnly, redemptionTransaction} = this.props;

        return (
            <Grid container spacing={24} direction={'column'}>
                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            First/Given Name*
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={redemptionTransaction.collectorFirstName}
                                onChange={e => this.onCollectorFieldChange('collectorFirstName', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Last Name/Surname*
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={redemptionTransaction.collectorLastName}
                                onChange={e => this.onCollectorFieldChange('collectorLastName', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Country of Residence*
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={redemptionTransaction.collectorCountry}
                                onChange={e => this.onCollectorFieldChange('collectorCountry', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Date of birth*
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                type='date'
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={toBrowserFriendlyDate(redemptionTransaction.collectorDob)}
                                onChange={e => this.onCollectorFieldChange('collectorDob', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item md={6} spacing={24} alignItems={'center'}></Grid>

                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Country code
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={redemptionTransaction.collectorCountryCode}
                                onChange={e => this.onCollectorFieldChange('collectorCountryCode', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Email address
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={redemptionTransaction.collectorEmail}
                                onChange={e => this.onCollectorFieldChange('collectorEmail', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Area code
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={redemptionTransaction.collectorAreaCode}
                                onChange={e => this.onCollectorFieldChange('collectorAreaCode', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item spacing={24} alignItems={'center'}>
                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Email Type
                        </Grid>
                        <Grid item md={8}>
                            <div dangerouslySetInnerHTML={{ __html: redemptionTransaction.emailType }} />
                        </Grid>
                    </Grid>
                    <Grid container item md={6} spacing={24} alignItems={'center'}>
                        <Grid item md={4}>
                            Mobile number
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id=''
                                margin='normal'
                                variant='outlined'
                                disabled={viewOnly}
                                value={redemptionTransaction.collectorContactNo}
                                onChange={e => this.onCollectorFieldChange('collectorContactNo', e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {redemptionTransaction.promocodeURIs && redemptionTransaction.promocodeURIs.length > 0 && (
                    <Grid container item spacing={24} alignItems={'center'}>
                        <Grid container item spacing={24} alignItems={'center'} md={6}>
                            <Grid item md={4}>
                                Promo Attachments
                            </Grid>
                            <Grid item md={8}>
                                <Grid item>
                                    <StyledAttachmentList
                                        attachmentUris={redemptionTransaction.promocodes || []}
                                        presignedAttachmentUris={redemptionTransaction.promocodeURIs || []}
                                        onAttachmentClick={this.onPromoClick}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid item>
                    <div dangerouslySetInnerHTML={{ __html: redemptionTransaction.airlineConsent }} />
                </Grid>

                <Grid item>
                    <FormControl variant={'outlined'}>
                        <FormControlLabel
                            control={<Checkbox />}
                            checked={redemptionTransaction.allowReceivingInfo}
                            className={classes.flexStart}
                            label={
                                <Typography component='span' variant={'body1'} className={classes.consentText}>
                                    <div dangerouslySetInnerHTML={{ __html: redemptionTransaction.marketingConsent }} />
                                </Typography>
                            }
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <div dangerouslySetInnerHTML={{ __html: redemptionTransaction.collectionConsent }} />
                </Grid>

                <Grid item md={12}>
                    <Typography variant={'body1'}>Collector's Signature</Typography>
                    <img src={redemptionTransaction.presignedSignatureURI} alt='' />
                </Grid>

                <Grid container item md={6} spacing={24} alignItems={'center'}>
                    <Grid item md={4}>
                        Collector Remarks
                    </Grid>
                    <Grid item md={8}>
                        <TextField
                            id=''
                            margin='normal'
                            variant='outlined'
                            disabled={viewOnly}
                            value={redemptionTransaction.remarks}
                            onChange={e => this.onCollectorFieldChange('remarks', e)}
                            fullWidth
                        />
                    </Grid>
                </Grid>

            </Grid>
        )
    }
}

const styles = theme => ({
    container: { ...theme.container },
    pointer: { cursor: 'pointer' }
});


CollectorDetails.propTypes = {
    viewOnly: PropTypes.bool.isRequired,
    redemptionTransaction: PropTypes.object.isRequired,
    onCollectorFieldChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(CollectorDetails)