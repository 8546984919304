import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from '../styles/mui/theme'
import SystemModal from '../containers/SystemModal.js'
import Dashboard from './Dashboard'
import IndividualScreen from './IndividualScreen'
import IndividualCollector from './IndividualCollector'
import ClientView from './ClientView'
import Group from './Group'
import GroupSubmission from './GroupSubmission'
import GroupApproval from './GroupApproval'
import GroupApprovalDetail from './GroupApprovalDetail'
import GroupDetail from './GroupDetail'
import GroupIssuance from './GroupIssuance'
import GroupIssuanceDetail from './GroupIssuanceDetail'
import AdminSettings from './AdminSettings'
import LoginScreen from './LoginScreen'
import NotFoundScreen from './NotFoundScreen'
import ScanTicket from '../containers/ScanTicket'
import ForgotPasswordScreen from './ForgotPasswordScreen'
import ResetPasswordScreen from './ResetPasswordScreen'
import ChangePasswordScreen from './ChangePasswordScreen'
import store from '../store.js'
import ProtectedRoute from '../components/ProtectedRoute'
import CampaignPeriod from './CampaignPeriod'
import CreateNewAccount from './CreateNewAccount'
import UserAccessAccount from './UserAccessAccount'
import RejectReason from './RejectReason'
import EmailTemplate from './EmailTemplate'
import Banner from './BannerScreen'
import LogoutScreen from './LogoutScreen'
import IndividualCollectorSuccess from './IndividualCollectorSuccess'
import GroupCollectorSuccess from './GroupCollectorSuccess'
import GroupSubmissionDetail from './GroupSubmissionDetail'
import GroupCollector from './GroupCollector'
import moment from 'moment'
import SearchScreen from './SearchScreen'
import SearchPassengerScreen from './SearchPassengerScreen'
import CardReplacementScreen from './CardReplacementScreen'
import Card from './Card'
import { LOCAL_STORAGE_LAST_ACTION_TIME } from '../constants/localStorageType'
import LoginScreen2 from './LoginScreen2'
import RedemptionTransactionDetailScreen from './RedemptionTransactionDetailScreen'
import AppealTransactionDetailScreen from './AppealTransactionDetailScreen'
import AdminIataScreen from './AdminIataScreen'
import SqApi from './SqApiScreen'
import PhysicalVoucherScreen from './PhysicalVoucherScreen'
import SearchGroupScreen from './SearchGroupScreen'
import SearchIssuanceSummaryScreen from './SearchIssuanceSummaryScreen'
import OCIDExchange from './OCIDExchangeScreen'
import PrintCard from './PrintCardScreen'
import PrintCardOnDemandScreen from './PrintCardOnDemandScreen'
import AppealTransactionScreen from './AppealTransactionScreen'

class Root extends Component {
	componentDidMount = () => {}

	_onGlobalClick = () => {
		let lastActionTime = moment().format()
		localStorage.setItem(LOCAL_STORAGE_LAST_ACTION_TIME, lastActionTime)
	}

	render() {
		return (
			<Provider store={store}>
				<BrowserRouter>
					<React.Fragment>
						<CssBaseline />
						<MuiThemeProvider theme={theme}>
							<SystemModal />
							<div onClick={this._onGlobalClick}>
								<Switch>
									<Route exact path='/' component={LoginScreen2} />
									<Route exact path='/login2' component={LoginScreen} />
									<Route path='/forgot-password' component={ForgotPasswordScreen} />
									{/* <ProtectedRoute path='/otp' component={OTPScreen} /> */}
									{/*<Route path="/client-login" component={ClientLoginScreen}/>*/}
									<ProtectedRoute path='/reset-password' component={ResetPasswordScreen} />
									<ProtectedRoute path='/dashboard' component={Dashboard} />
									{/*<ProtectedRoute path="/client-view" component={ClientView}
                                        redirectPath={'/client-login'}/>*/}
									<Route path='/client-view' component={ClientView} />

									<ProtectedRoute path='/individual/:editMode(editMode)?' component={IndividualScreen} />
									<ProtectedRoute path='/scan-ticket' component={ScanTicket} />
									<ProtectedRoute path='/individual-collector' component={IndividualCollector} />
									<ProtectedRoute path='/individual-collector-success' component={IndividualCollectorSuccess} />
									<ProtectedRoute path='/group-collector-success' component={GroupCollectorSuccess} />
									<ProtectedRoute path='/group/group-submission/:id' component={GroupSubmissionDetail} />
									<ProtectedRoute path='/group/group-submission' component={GroupSubmission} />
									<ProtectedRoute path='/group/group-detail/:id' component={GroupDetail} />
									<ProtectedRoute path='/group/group-approval/:id' component={GroupApprovalDetail} />
									<ProtectedRoute path='/group/group-approval' component={GroupApproval} />
									<ProtectedRoute path='/group/group-issuance/:id' component={GroupIssuanceDetail} />
									<ProtectedRoute path='/group/group-issuance' component={GroupIssuance} />
									<ProtectedRoute path='/group/group-collector' component={GroupCollector} />
									<ProtectedRoute path='/group' component={Group} />
									<ProtectedRoute path='/change-password' component={ChangePasswordScreen} />
									<ProtectedRoute path='/admin-settings/campaign-period' component={CampaignPeriod} />
									<ProtectedRoute path='/admin-settings/create-new-account' component={CreateNewAccount} />
									<ProtectedRoute path='/admin-settings/user-access-account' component={UserAccessAccount} />
									<ProtectedRoute path='/admin-settings/reject-reason' component={RejectReason} />
									<ProtectedRoute path='/admin-settings/email-template' component={EmailTemplate} />
									<ProtectedRoute path='/admin-settings/banner' component={Banner} />
									<ProtectedRoute path='/admin-settings/iata' component={AdminIataScreen} />
									<ProtectedRoute path='/admin-settings/sq-check' component={SqApi} />
									<ProtectedRoute path='/admin-settings/physical-voucher' component={PhysicalVoucherScreen} />
									<ProtectedRoute path='/admin-settings' component={AdminSettings} />
									{/*idx, position in duck's result*/}

									<ProtectedRoute path='/card/card-replacement' component={CardReplacementScreen} />

									<ProtectedRoute path='/card' component={Card} />

									<ProtectedRoute path='/search/passenger' component={SearchPassengerScreen} />
									<ProtectedRoute path='/search/group' component={SearchGroupScreen} />
									<ProtectedRoute path='/search/issuance-summary' component={SearchIssuanceSummaryScreen} />
									<ProtectedRoute path='/search' component={SearchScreen} />
									<ProtectedRoute path='/redemption-transaction-detail/:id' component={RedemptionTransactionDetailScreen} />
									<ProtectedRoute path='/appeal-transaction' component={AppealTransactionScreen} />
									<ProtectedRoute path='/appeal-transaction-detail/:id' component={AppealTransactionDetailScreen} />
									<Route path='/logout' component={LogoutScreen} />
									<ProtectedRoute path='/ocid-exchange' component={OCIDExchange} />
									<ProtectedRoute path='/print-card' component={PrintCard} />
									<Route path='/print-card-on-demand' component={PrintCardOnDemandScreen} />
									<Route component={NotFoundScreen} />
								</Switch>
							</div>
						</MuiThemeProvider>
					</React.Fragment>
				</BrowserRouter>
			</Provider>
		)
	}
}

export default Root
