import React, {Component} from 'react'
import {withStyles, CircularProgress} from '@material-ui/core'

class LoadingContainer extends Component {
    render() {
        const {classes} = this.props

        return (
            <div>
                {this.props.show &&
                <div className={classes.fullscreen}>
                    <CircularProgress className={classes.progress}/>
                </div>
                }
            </div>
        )
    }
}

const styles = theme => ({
    fullscreen: {
        position: 'absolute',
        background: 'white',
        opacity: '.8',
        zIndex: 1399,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        width: '100%'
    }
})

export default withStyles(styles)(LoadingContainer)