import React from 'react'
import PropTypes from 'prop-types'
import SignatureCanvas from 'react-signature-canvas'
import {Paper, Button} from '@material-ui/core'

class GeneralCheckbox extends React.Component {
    constructor(props) {
        super(props)
        this.sigCanvas = null
    }

    clearSignature = () => {
        this.sigCanvas.clear()
        this.props.onClear()
    }

    onEnd = () => {
        this.props.onEnd && this.props.onEnd(this.sigCanvas.toDataURL())
    }

    render() {
        return (
            <div style={styles.container.styles}>
                <div style={styles.container.canvasWrapper.styles}>
                    <Paper style={this.props.dimensions}>
                        <SignatureCanvas
                            ref={(ref) => {
                                this.sigCanvas = ref
                            }}
                            penColor={this.props.penColor}
                            canvasProps={this.props.dimensions}
                            onEnd={this.onEnd}
                        />
                    </Paper>
                </div>

                <div style={styles.container.buttonWrapper.styles}>
                    <Button
                        onClick={this.clearSignature}
                        color='secondary'
                        variant="contained"
                    >
                        {this.props.clearButtonText ? this.props.clearButtonText : 'Clear Signature'}
                    </Button>
                </div>
            </div>
        )
    }

}

const styles = {
    container: {
        styles: {width: '100%'},
        canvasWrapper: {
            styles: {}
        },
        buttonWrapper: {
            styles: {paddingTop: 10}
        }
    }
}

GeneralCheckbox.propTypes = {
    penColor: PropTypes.string.isRequired,
    dimensions: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired

    }).isRequired,
    clearButtonText: PropTypes.string
}

export default GeneralCheckbox