import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Button, Grid } from '@material-ui/core'
import Header from '../containers/Header'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import StyledSection from '../components/StyledSection'
import backgroundGrey from '../images/background-grey.svg'
import CTPCardPrintLayout1 from '../components/CTPCardPrintLayout1'
import CRCardPrintLayout1 from '../components/CRCardPrintLayout1'
import { requestForReprinting } from '../ducks/printCardDuck'
import querystring from 'querystring'

class PrintCard extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			authorizationCode: '',
			origin: '',
			isLoading: false,
			printingCard: '',
			cardNoPrinted: '',
			showCr: false,
			cards: []
		}
	}

	componentDidMount = async () => {
		const redemptionTransactionId = querystring.parse(window.location.search).id
		const cardNo = querystring.parse(window.location.search).cardNo
		let lstCardNo = []
		if (cardNo !== null && cardNo !== undefined && cardNo !== '') {
			lstCardNo = [cardNo]
		}
		await this.props.requestForReprinting(lstCardNo, redemptionTransactionId)
		let cards = this.props.printCardDuck.cards
		this.setState({ cards })
	}

	onPrint = () => {
		window.print()
	}

	render() {
		const { cards } = this.state;
		let submittedCards = cards;
		let submittedCRCards = submittedCards.filter((m) => {
			return m.cardNo && m.cardNo.startsWith('00002')
		})
		let submittedCTPCards = submittedCards.filter((m) => {
			return m.cardNo && m.cardNo.startsWith('00004')
		})
		return (
			<div style={{
				backgroundColor: '#9e9e9e',
				backgroundImage: `url(${backgroundGrey})`,
				transition: 'left 500ms',
			}}>
				<Header history={this.props.history} />
				<GlobalTimeChecker />

				<style>{`@media print {
						._page {
							margin: 0;
							box-shadow: 0;
						}
					}`}</style>

					<Button onClick={this.onPrint} variant='contained'>
						PRINT ALL
					</Button>
					{submittedCRCards && submittedCRCards.length > 0 &&
						<Grid>
							<CRCardPrintLayout1 cards={submittedCRCards} />
						</Grid>
					}
					{submittedCTPCards && submittedCTPCards.length > 0 &&
						submittedCTPCards.map((m, i) => {
							return(
								<Grid item key={m.cardNo}>
									<CTPCardPrintLayout1 card={m} />
								</Grid>
							)
						})}
				</div>
		)
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
	row: { display: 'flex' },
	rowItem: { display: 'flex', flex: 1, marginRight: 60, alignItems: 'center' },
	flexStart: { alignItems: 'flex-start' },
	consentText: { fontSize: '0.8125rem' },
})

const mapStateToProps = (state) => {
	const { individualFormDuck, printCardDuck } = state

	return { individualFormDuck, printCardDuck }
}

const mapDispatchToProps = (dispatch) => {
	return { requestForReprinting: (cards, redemptionTransactionId) => dispatch(requestForReprinting(cards, redemptionTransactionId)) }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrintCard))
