import React from 'react';
import { connect } from 'react-redux';
import {
    withStyles,
    Button,
    Grid,
    Typography,
    TextField,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core';
import { updatePassengers } from '../../ducks/groupRedemptionFormDuck';
import * as cloneDeep from 'lodash/cloneDeep';
import CardRangeHelper from '../../helpers/cardRangeHelper';
import StyledSection from '../../components/StyledSection';
import SimplePassengersTable from './components/SimplePassengersTable';
import {
    getGroupAndPassengersRedemption,
    groupSqApiCheck,
    groupCheckRedeemedPassenger,
    updateGroupRedemptionFormDuck,
    updateGroupRedemptionFormDuckObj,
    checkInactiveCards
} from '../../ducks/groupRedemptionFormDuck';
import StyledSnackbar from '../../components/StyledSnackbar';
import StyledPlaceholder from '../../components/StyledPlaceholder';
import moment from 'moment';
import PassportParser from '../../helpers/passportParser';
import { checkBonusCities } from '../../helpers/voucherBonusHelper';
import BoardingPassParser from '../../helpers/boardingPassParser';
import {
    getOriginFromFltNumber,
    parseFullCardNo
} from '../../helpers/utilities';
import {
    NOT_ALL_PAX_ASSIGNED_CARD_ERROR_MSG,
    REASSIGN_CARD_OR_VOUCHER
} from '../../constants/messages';
import StyledGeneralDialog from '../../components/StyledGeneralDialog';
import {
    CARD_NO_OF_DIGITS,
    CTP_CARD_PREFIX
} from '../../constants/cardConstants';
import PromiseModalWrapper from '../../components/promiseModal/PromiseModalWrapper.js';
import {
    MULTI_CARD_UNLOADED,
    MULTI_CARD_DIGITAL,
    SINGLE_CARD_DIGITAL,
    MULTI_CARD_EV
} from '../../constants/groupCardAssignment.js';
import { getSettings } from '../../config/config.js';

const sectorHeaders = [
    'Sector 1 Flight No.',
    'Sector 1 Flight Date',
    'Sector 2 Flight No.',
    'Sector 2 Flight Date',
    'Sector 3 Flight No.',
    'Sector 3 Flight Date',
    'Sector 4 Flight No.',
    'Sector 4 Flight Date'
];

const sectorDataKeys = [
    'firstFltNo',
    'firstFltDepDate',
    'secondFltNo',
    'secondFltDepDate',
    'thirdFltNo',
    'thirdFltDepDate',
    'fourthFltNo',
    'fourthFltDepDate'
];

const PASSENGERS_NOT_COLLECTING_HEADERS = [
    'S/N',
    'First name',
    'Last name',
    'DOB',
    ...sectorHeaders,
    'Booking Ref. No.'
];
const PASSENGERS_NOT_COLLECTING_DATA_KEYS = [
    'seq',
    'firstName',
    'lastName',
    'dob',
    ...sectorDataKeys,
    'pnr'
];
const settings = getSettings();
const PASSENGERS_REDEEMED_HEADERS = [
    'S/N',
    'First name',
    'Last name',
    'DOB',
    ...sectorHeaders,
    'Booking Ref. No.',
    'Card No',
    'Collection Date'
];
const PASSENGERS_REDEEMED_DATA_KEYS = [
    'seq',
    'firstName',
    'lastName',
    'dob',
    ...sectorDataKeys,
    'pnr',
    'cardNo',
    'creationTime'
];

class GroupRedemptionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPax: 0,
            cardPrefix: '0000400000000',
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            isGroupApproved: true,
            isDateValid: true,
            isRedeemed: false,
            groupRefNo: this.props.groupRefNo,
            tlLastName: '',
            tlDob: '',
            tlETicket: '',
            tlVerified: false,
            genCardPrefix: '',
            lpcCardPrefix: '',
            cardRangeStringFrom: '',
            cardRangeStringTo: '',
            genCardRangeFrom: '',
            genCardRangeTo: '',
            lpcCardRangeFrom: '',
            lpcCardRangeTo: '',
            PASSENGERS_HEADERS: [
                'S/N',
                'First name',
                'Last name',
                'DOB',
                ...sectorHeaders,
                'Booking Ref. No.',
                'Collecting',
                'Card No'
            ],
            PASSENGERS_DATA_KEYS: [
                'seq',
                'firstName',
                'lastName',
                'dob',
                ...sectorDataKeys,
                'pnr',
                'collecting',
                'cardNo'
            ],
            showGeneralDialog: false,
            generalDialogTitle: '',
            promiseModalProceedMessage: '',
            promiseModalCancelMessage: '',
            promiseModalContent: '',
            allowChangeCardAssignment: false,
            allCollectingChecked: false
        };
        this.ppInputTimeout = null;
        this.eTixInputTimeout = null;
        this.promiseModalRef = React.createRef();
    }

    componentDidMount = async () => {
        const { groupRefNo } = this.state;
        const {
            groupRedemptionFormDuck: {
                group: { id }
            }
        } = this.props;

        if (groupRefNo && id === '') {
            this.fetchGroupRefNo(groupRefNo);
        } else {
            const {
                groupRedemptionFormDuck: { ui }
            } = this.props;
            if (!!ui.tlETicket)
                this.setState({ tlETicket: ui.tlETicket, tlVerified: true });
            if (!!ui.tlDob)
                this.setState({ tlDob: ui.tlDob, tlVerified: true });

            const {
                groupRedemptionFormDuck: { cardAssignment }
            } = this.props;
            if (
                cardAssignment === MULTI_CARD_UNLOADED ||
                cardAssignment === MULTI_CARD_DIGITAL
            ) {
                this.setState({
                    PASSENGERS_HEADERS: [
                        'S/N',
                        'First name',
                        'Last name',
                        'DOB',
                        ...sectorHeaders,
                        'Booking Ref. No.',
                        'Collecting',
                        'Card No'
                    ]
                });
                this.setState({
                    PASSENGERS_DATA_KEYS: [
                        'seq',
                        'firstName',
                        'lastName',
                        'dob',
                        ...sectorDataKeys,
                        'pnr',
                        'collecting',
                        'cardNo'
                    ]
                });
            }

            if (cardAssignment === SINGLE_CARD_DIGITAL) {
                // TODO:
                // this.setState({
                //     PASSENGERS_HEADERS: [
                //         'S/N',
                //         'First name',
                //         'Last name',
                //         'DOB',
                //         ...sectorHeaders,
                //         'Booking Ref. No.',
                //         'Collecting',
                //         'GEN Voucher No',
                //         'LPC Voucher No'
                //     ]
                // });
                // this.setState({
                //     PASSENGERS_DATA_KEYS: [
                //         'seq',
                //         'firstName',
                //         'lastName',
                //         'dob',
                //         ...sectorDataKeys,
                //         'pnr',
                //         'collecting',
                //         'genCardNo',
                //         'lpcCardNo'
                //     ]
                // });
            }

            if (cardAssignment === MULTI_CARD_EV) {
                this.setState({
                    PASSENGERS_HEADERS: [
                        'S/N',
                        'First name',
                        'Last name',
                        'DOB',
                        ...sectorHeaders,
                        'Booking Ref. No.',
                        'Collecting',
                        'Preloaded Card No'
                    ]
                });
                this.setState({
                    PASSENGERS_DATA_KEYS: [
                        'seq',
                        'firstName',
                        'lastName',
                        'dob',
                        ...sectorDataKeys,
                        'pnr',
                        'collecting',
                        'preloadedVouchersCardNo'
                    ]
                });

                this.props.groupRedemptionFormDuck.passengers.forEach(p => {
                    p.preloadedVouchersCardNo = '';
                });
            }
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const {
            groupRedemptionFormDuck: { passengers, group }
        } = this.props;

        if (prevProps.groupRedemptionFormDuck.passengers !== passengers) {
            this.setState({ totalPax: passengers.length });
            this.setState({
                allCollectingChecked: passengers.every(p => p.collecting)
            });
        }

        if (prevProps.groupRedemptionFormDuck.group !== group) {
            if (!group.approval || group.rejected) {
                this.setState({ isGroupApproved: false });
            }

            // Can only collection between collectionDate and lastFlightDepartureDate (inclusive)
            const now = moment();
            if (
                now.isBefore(moment(group.collectionDate)) ||
                now.isSameOrAfter(
                    moment(group.lastFlightDepartureDate).add(1, 'day')
                )
            ) {
                this.setState({ isDateValid: false });
            }

            if (group.redemption) {
                this.setState({ isRedeemed: true });
            }
        }

        if (
            prevProps.groupRedemptionFormDuck.cardAssignment !==
            this.props.groupRedemptionFormDuck.cardAssignment
        ) {
            const {
                groupRedemptionFormDuck: { cardAssignment }
            } = this.props;
            if (
                cardAssignment === MULTI_CARD_UNLOADED ||
                cardAssignment === MULTI_CARD_DIGITAL
            ) {
                this.setState({
                    PASSENGERS_HEADERS: [
                        'S/N',
                        'First name',
                        'Last name',
                        'DOB',
                        ...sectorHeaders,
                        'Booking Ref. No.',
                        'Collecting',
                        'Card No'
                    ]
                });
                this.setState({
                    PASSENGERS_DATA_KEYS: [
                        'seq',
                        'firstName',
                        'lastName',
                        'dob',
                        ...sectorDataKeys,
                        'pnr',
                        'collecting',
                        'cardNo'
                    ]
                });
            }

            if (cardAssignment === MULTI_CARD_EV) {
                this.setState({
                    PASSENGERS_HEADERS: [
                        'S/N',
                        'First name',
                        'Last name',
                        'DOB',
                        ...sectorHeaders,
                        'Booking Ref. No.',
                        'Collecting',
                        'Preloaded Card No'
                    ]
                });
                this.setState({
                    PASSENGERS_DATA_KEYS: [
                        'seq',
                        'firstName',
                        'lastName',
                        'dob',
                        ...sectorDataKeys,
                        'pnr',
                        'collecting',
                        'preloadedVouchersCardNo'
                    ]
                });
            }

            if (cardAssignment === SINGLE_CARD_DIGITAL) {
                // TODO
                // this.setState({
                //     PASSENGERS_HEADERS: [
                //         'S/N',
                //         'First name',
                //         'Last name',
                //         'DOB',
                //         ...sectorHeaders,
                //         'Booking Ref. No.',
                //         'Collecting',
                //         'GEN Voucher No',
                //         'LPC Voucher No'
                //     ]
                // });
                // this.setState({
                //     PASSENGERS_DATA_KEYS: [
                //         'seq',
                //         'firstName',
                //         'lastName',
                //         'dob',
                //         ...sectorDataKeys,
                //         'pnr',
                //         'collecting',
                //         'genCardNo',
                //         'lpcCardNo'
                //     ]
                // });
            }

            const passengers = this.props.groupRedemptionFormDuck.passengers;
            passengers.forEach(p => {
                p.lpcPhysicalVoucherNo = '';
                p.genPhysicalVoucherNo = '';
                p.physicalVoucherIssued = false;
                p.physicalVoucher = [];
                p.cardNo = '';
                p.preloadedVouchersCardNo = '';
            });

            this.props.updateGroupRedemptionFormDuckObj({ passengers });
        }
    }

    requestToChangeCardAssignment = () => {
        this.setState({
            allowChangeCardAssignment: true
        });
    };

    onCheckAll = () => {
        this.setPassengersCollecting(true);
    };

    onUncheckAll = () => {
        this.setPassengersCollecting(false);
    };

    setPassengersCollecting = (state) => {
        const {
            groupRedemptionFormDuck: { passengers }
        } = this.props;
        let updatedPassengers = cloneDeep(passengers);
        updatedPassengers.forEach(p => {
            p.collecting = state;
        });
        this.props.updateGroupRedemptionFormDuckObj({ passengers: updatedPassengers });
        this.setState({
            allCollectingChecked: state
        });
    };

    onAssign = async () => {
        const {
            groupRedemptionFormDuck: { passengers, cardAssignment }
        } = this.props;
        let updatedPassengers = cloneDeep(passengers);
        const { flightData } = JSON.parse(localStorage.getItem('FLIGHT_DATA'));

        if (
            cardAssignment === MULTI_CARD_UNLOADED ||
            cardAssignment === MULTI_CARD_EV
        ) {
            const {
                cardRangeStringFrom,
                cardRangeStringTo,
                cardPrefix
            } = this.state;
            if (
                cardRangeStringTo === '' ||
                /[a-zA-Z]/.test(cardRangeStringTo) ||
                cardRangeStringFrom === '' ||
                /[a-zA-Z]/.test(cardRangeStringFrom)
            ) {
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'error',
                    snackbarMessage: 'Please enter a valid card range'
                });
                return;
            }

            let cardRangeString = cardRangeStringFrom + '-' + cardRangeStringTo;
            let cardRangeHelper = new CardRangeHelper({
                rangeString: cardRangeString,
                prefix: cardPrefix,
                noOfDigits: CARD_NO_OF_DIGITS
            });
            cardRangeHelper.prepareData();
            let cards = cardRangeHelper.generateRange();

            if (cardAssignment === MULTI_CARD_UNLOADED) {
                updatedPassengers = this._populateVoucherValue({
                    passengers: updatedPassengers,
                    cards: cards,
                    cardAssignment: MULTI_CARD_UNLOADED
                });
            } else if (cardAssignment === MULTI_CARD_EV) {
                updatedPassengers = this._populateVoucherValue({
                    passengers: updatedPassengers,
                    cards: cards,
                    cardAssignment: MULTI_CARD_EV
                });
            }
        }

        this.props.updatePassengers(updatedPassengers);
    };

    _validatePassengers = ({ passengers, cardAssignmentType }) => {
        let passValidation = true;

        if (
            cardAssignmentType === MULTI_CARD_EV &&
            passengers.some(m => !m.preloadedVouchersCardNo)
        ) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: NOT_ALL_PAX_ASSIGNED_CARD_ERROR_MSG
            });
            return false;
        }

        for (let p of passengers) {
            if (!p.collecting && (p.cardNo || p.genPhysicalVoucherNo)) {
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'error',
                    snackbarMessage: REASSIGN_CARD_OR_VOUCHER
                });
                passValidation = false;
                break;
            }

            if (!p.collecting) continue;

            if (cardAssignmentType === MULTI_CARD_UNLOADED) {
                if (!p.cardNo) {
                    this.setState({
                        showSnackbar: true,
                        snackbarVariant: 'error',
                        snackbarMessage: NOT_ALL_PAX_ASSIGNED_CARD_ERROR_MSG
                    });
                    passValidation = false;
                    break;
                }
                if (p.cardNo.substr(0, 5) !== CTP_CARD_PREFIX) {
                    this.setState({
                        showSnackbar: true,
                        snackbarVariant: 'error',
                        snackbarMessage: 'Please input correct ctp card no'
                    });
                    passValidation = false;
                    break;
                }
            }
        }

        if (cardAssignmentType === MULTI_CARD_UNLOADED) {
            let eligibleCardPassengers = passengers.filter(
                p => p.collecting === true
            );
            eligibleCardPassengers = passengers.filter(p => !!p.cardNo);

            let cardNoSet = new Set();
            eligibleCardPassengers.forEach(p => cardNoSet.add(p.cardNo));
            if (cardNoSet.size !== eligibleCardPassengers.length) {
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'error',
                    snackbarMessage: 'Duplicated Card No Detected'
                });
                return;
            }
        }

        return passValidation;
    };

    onProceed = async () => {
        const {
            groupRedemptionFormDuck: { passengers, cardAssignment }
        } = this.props;
        let passValidation = this._validatePassengers({
            passengers,
            cardAssignmentType: cardAssignment
        });
        if (!passValidation) return;
        this.props.history.push('/group/group-collector');
        // let passengerCards = passengers.map(p => p.cardNo);
        // let checkCardsResponse = await this.props.checkInactiveCards(
        //     passengerCards
        // );
        // if (!checkCardsResponse.success) {
        //     if (checkCardsResponse.error) {
        //         this.setState(
        //             this.setState({
        //                 showSnackbar: true,
        //                 snackbarVariant: 'error',
        //                 snackbarMessage: checkCardsResponse.error.message
        //             })
        //         );
        //     } else {
        //         this.setState(
        //             this.setState({
        //                 showSnackbar: true,
        //                 snackbarVariant: 'error',
        //                 snackbarMessage: 'An unexpected Error has occured'
        //             })
        //         );
        //     }
        //     return;
        // } else {
        //     this.props.history.push('/group/group-collector');
        // }
    };

    _updateCardPrefix = event => {
        this.setState({ cardPrefix: event.target.value });
    };

    closeSnackbar = () => {
        this.setState({ showSnackbar: false });
    };

    onBack = () => {
        window.location = window.location.href;
    };

    updateGroupRefNo = async e => {
        let groupRefNo = e.target.value;
        this.setState({ groupRefNo: groupRefNo });
        this.fetchGroupRefNo(groupRefNo);
    };

    fetchGroupRefNo = async groupRefNo => {
        if (groupRefNo.length === 10 && groupRefNo[0].toLowerCase() === 'g') {
            //starts with g
            const rs = await this.props.getGroupAndPassengersRedemption({
                refNo: groupRefNo,
                getAllUser: true
            }); //rename property to refNo
            if (!rs.success)
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'error',
                    snackbarMessage: rs.message
                });
            // call GroupSqApiCheck
            if (rs.success) {
                const {
                    groupRedemptionFormDuck: { passengers, groupMetaInfo }
                } = this.props;
                this.props.updateGroupRedemptionFormDuck(
                    'cardAssignment',
                    this.props.groupRedemptionFormDuck.group.cardAssignment
                );

                if (!groupMetaInfo.isTourLeaderInPassengerList) {
                    this.setState({
                        showGeneralDialog: true,
                        generalDialogTitle: 'Tour leader is not in group'
                    });
                }
            }
        }
    };

    updateTlLastName = e => {
        let lastName = e.target.value;
        clearTimeout(this.ppInputTimeout);
        this.ppInputTimeout = setTimeout(() => {
            let regex = /\//gi;
            if (lastName.match(regex) && lastName.match(regex).length >= 4) {
                this._processScanPassport(lastName);
            }
        }, 1000);
        this.setState({ tlLastName: lastName });
    };

    updateTlDob = e => {
        let ppInput = e.target.value;
        clearTimeout(this.ppInputTimeout);
        this.ppInputTimeout = setTimeout(() => {
            let regex = /\//gi;
            if (ppInput.match(regex) && ppInput.match(regex).length >= 4) {
                this._processScanPassport(ppInput);
            }
        }, 1000);
        this.setState({ tlDob: ppInput });
    };

    updateTlETicket = e => {
        let eTixInput = e.target.value;
        clearTimeout(this.eTixInputTimeout);
        this.eTixInputTimeout = setTimeout(() => {
            if (eTixInput.length >= 90) {
                this._processBoardingPass(eTixInput);
            }
        }, 1000);
        this.setState({ tlETicket: eTixInput });
    };

    onLastNameKeyUp = e => {
        //if (e.key === 'Enter') this._verifyTlIdentity()
    };

    onLastNameBlur = e => {
        //this._verifyTlIdentity()
    };

    _verifyTlIdentity = ({ dob, eTix }) => {
        if (dob) {
            if (
                moment(dob).isSame(
                    moment(this.props.groupRedemptionFormDuck.tourLeader.dob),
                    'day'
                )
            ) {
                this.setState({ tlVerified: true });
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'success',
                    snackbarMessage: 'Tour Leader Verified'
                });
            } else {
                this.setState({ tlVerified: false });
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'error',
                    snackbarMessage: 'Tour Leader not matched'
                });
            }
        }

        if (eTix) {
            let {
                eTicket1,
                eTicket2,
                eTicket3,
                eTicket4
            } = this.props.groupRedemptionFormDuck.tourLeader;
            if (
                eTix === eTicket1 ||
                eTix === eTicket2 ||
                eTix === eTicket3 ||
                eTix === eTicket4
            ) {
                this.setState({ tlVerified: true });
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'success',
                    snackbarMessage: 'Tour Leader Verified'
                });
            } else {
                this.setState({ tlVerified: false });
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'error',
                    snackbarMessage: 'Tour Leader not matched'
                });
            }
        }
    };

    _processScanPassport = passportString => {
        let passportParser = new PassportParser(passportString);
        let passportData = passportParser.getData();
        this.setState({ tlLastName: passportData.lastName });
        this.setState({ tlDob: moment(passportData.dob).format('DD/MM/YYYY') });
        this.props.updateGroupRedemptionFormDuckObj({
            ui: {
                tlDob: moment(passportData.dob).format('DD/MM/YYYY'),
                tlLastName: passportData.lastName
            }
        });
        this._verifyTlIdentity({ dob: passportData.dob });
    };

    _processBoardingPass = eTicketInput => {
        let boardingPassParser = new BoardingPassParser(eTicketInput);
        let boardingPassData = boardingPassParser.getData();
        this.setState({ tlETicket: boardingPassData.eTix });
        this.props.updateGroupRedemptionFormDuckObj({
            ui: { tlETicket: boardingPassData.eTix }
        });
        this._verifyTlIdentity({ eTix: boardingPassData.eTix });
    };

    onViewDetails = groupRefNo => {
        window.open('/group/group-detail/' + groupRefNo);
    };

    onCardAssignmentChange = e => {
        let updatedPassengers = this._populateVoucherValue({
            passengers: this.props.groupRedemptionFormDuck.passengers,
            cards: [],
            cardAssignment: MULTI_CARD_DIGITAL
        });
        this.props.updateGroupRedemptionFormDuck(
            'cardAssignment',
            e.target.value
        );
        this.props.updatePassengers(updatedPassengers);
    };

    _populateVoucherValue = ({ passengers, cards, cardAssignment }) => {
        let ctpVoucher = settings.voucher_sources.find(
            s => s.membership_type == 'CTP'
        );
        let genVoucher = ctpVoucher.vouchers.find(s => s.voucher_type == 'GEN');
        let lpcVoucher = ctpVoucher.vouchers.find(s => s.voucher_type == 'LPC');
        let updatedPassengers = cloneDeep(passengers);
        const { flightData } = JSON.parse(localStorage.getItem('FLIGHT_DATA'));

        for (let i = 0; i < updatedPassengers.length; i++) {
            if (!updatedPassengers[i].collecting) {
                //set to default if not collecting
                updatedPassengers[i].cardNo = '';
                updatedPassengers[i].preloadedVouchersCardNo = '';
                updatedPassengers[i].ctpVoucherValue = null;
                updatedPassengers[i].ctpGenValue = null;
                updatedPassengers[i].ctpLpcValue = null;
            } else if (updatedPassengers[i].collecting) {
                if (cardAssignment === MULTI_CARD_UNLOADED) {
                    if (cards) updatedPassengers[i].cardNo = cards.shift(); //DIGITALCARD assignment do not provide cards.
                }
                if (cardAssignment === MULTI_CARD_EV) {
                    if (cards)
                        updatedPassengers[
                            i
                        ].preloadedVouchersCardNo = cards.shift(); //DIGITALCARD assignment do not provide cards.
                }

                updatedPassengers[i].origin = getOriginFromFltNumber(
                    updatedPassengers[i].firstFltNo,
                    flightData
                );
                let bonusCityEntitlement = checkBonusCities(
                    updatedPassengers[i].origin
                );
                if (bonusCityEntitlement) {
                    let cityBonusVoucher = settings.voucher_sources.find(
                        s => s.membership_type == 'CITY_BONUS'
                    );
                    let genCityBonusVoucher = cityBonusVoucher.vouchers.find(
                        s => s.voucher_type == 'GEN'
                    );
                    let lpcCityBonusVoucher = cityBonusVoucher.vouchers.find(
                        s => s.voucher_type == 'LPC'
                    );
                    updatedPassengers[i].ctpGenValue =
                        genVoucher.voucher_value +
                        genCityBonusVoucher.voucher_value;
                    updatedPassengers[i].ctpLpcValue =
                        lpcVoucher.voucher_value +
                        lpcCityBonusVoucher.voucher_value;
                    updatedPassengers[i].ctpVoucherValue =
                        parseInt(updatedPassengers[i].ctpGenValue) +
                        parseInt(updatedPassengers[i].ctpLpcValue);
                } else {
                    updatedPassengers[i].ctpGenValue = genVoucher.voucher_value;
                    updatedPassengers[i].ctpLpcValue = lpcVoucher.voucher_value;
                    updatedPassengers[i].ctpVoucherValue =
                        parseInt(updatedPassengers[i].ctpGenValue) +
                        parseInt(updatedPassengers[i].ctpLpcValue);
                }
            }
        }
        return updatedPassengers;
    };

    onGenCardPrefixChange = e => {
        this.setState({ genCardPrefix: e.target.value });
    };

    onlpcCardPrefixChange = e => {
        this.setState({ lpcCardPrefix: e.target.value });
    };

    _updateCardRangeStringTo = event => {
        this.setState({
            cardRangeStringTo: parseFullCardNo(event.target.value)
        });
    };

    _updateCardRangeStringFrom = event => {
        this.setState({
            cardRangeStringFrom: parseFullCardNo(event.target.value)
        });
    };

    _updateGenCardRangeFrom = e => {
        let numericValue = e.target.value.replace(/\D/g, '');
        this.setState({ genCardRangeFrom: numericValue });
    };

    _updateGenCardRangeTo = e => {
        let numericValue = e.target.value.replace(/\D/g, '');
        this.setState({ genCardRangeTo: numericValue });
    };

    _updatelpcCardRangeFrom = e => {
        let numericValue = e.target.value.replace(/\D/g, '');
        this.setState({ lpcCardRangeFrom: numericValue });
    };

    _updatelpcCardRangeTo = e => {
        let numericValue = e.target.value.replace(/\D/g, '');
        this.setState({ lpcCardRangeTo: numericValue });
    };

    onCloseGeneralDialog = () => {
        this.setState({ showGeneralDialog: false });
    };

    render() {
        const {
            classes,
            groupRedemptionFormDuck: {
                getGroupAndPassengersIsLoading,
                group,
                passengers,
                rejectedPassengers,
                paxFlown,
                cardAssignment
            }
        } = this.props;
        const {
            isGroupApproved,
            isRedeemed,
            groupRefNo,
            tlVerified,
            cardRangeStringFrom,
            cardRangeStringTo,
            PASSENGERS_HEADERS,
            PASSENGERS_DATA_KEYS,
            showGeneralDialog,
            generalDialogTitle,
            allowChangeCardAssignment
        } = this.state;

        if (!isGroupApproved) {
            let message = '';
            if (!group.approved)
                message = `Group ref: ${group.refNo} has not been approved`;
            if (group.rejected)
                message = `Group ref: ${
                    group.refNo
                } has been rejected with the following reason: ${
                    group.rejectionReason
                }`;

            return (
                <div className={classes.container}>
                    <Grid
                        container
                        spacing={24}
                        direction={'column'}
                        justify={'center'}
                    >
                        <Grid item>
                            <StyledPlaceholder message={message} />
                        </Grid>
                        <Grid item className={classes.backBtn}>
                            <Button
                                color={'primary'}
                                variant='outlined'
                                onClick={this.onBack}
                            >
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            );
        }

        if (isRedeemed) {
            return (
                <div className={classes.container}>
                    <Grid
                        container
                        spacing={24}
                        direction={'column'}
                        justify={'center'}
                    >
                        <Grid item>
                            <StyledPlaceholder
                                message={`Group ref: ${
                                    group.refNo
                                } has been redeemed`}
                            />
                        </Grid>
                        <Grid item className={classes.backBtn}>
                            <Button
                                color={'primary'}
                                variant='outlined'
                                onClick={this.onBack}
                            >
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            );
        }

        return (
            <div className={classes.container}>
                <PromiseModalWrapper
                    ref={this.promiseModalRef}
                    proceedMessage={this.state.promiseModalProceedMessage}
                    cancelMessage={this.state.promiseModalCancelMessage}
                    content={this.state.promiseModalContent}
                />

                <StyledGeneralDialog
                    showDialog={showGeneralDialog}
                    title={generalDialogTitle}
                    onClose={this.onCloseGeneralDialog}
                    actionText={''}
                >
                    Tour Leader is not part of this group. Please issue card
                    individually if eligible
                </StyledGeneralDialog>

                <StyledSection
                    title={'Group Details'}
                    isLoading={
                        getGroupAndPassengersIsLoading ||
                        this.props.groupRedemptionFormDuck
                            .checkInactiveCardsIsLoading
                    }
                >
                    <Grid container spacing={24} direction={'column'}>
                        {this.props.groupRefNo && (
                            <Grid item>
                                <Button
                                    color={'primary'}
                                    variant='contained'
                                    onClick={() =>
                                        this.onViewDetails(groupRefNo)
                                    }
                                >
                                    View Details
                                </Button>
                            </Grid>
                        )}

                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item xs={12} md={4}>
                                <Typography variant={'body1'}>
                                    Group Reference No
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <TextField
                                    id='grpReferenceNo'
                                    variant='outlined'
                                    fullWidth
                                    onChange={this.updateGroupRefNo}
                                    value={this.state.groupRefNo}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item xs={12} md={4}>
                                <Typography variant={'body1'}>
                                    TL ETicket
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <TextField
                                    id='tlETicket'
                                    label=''
                                    variant='outlined'
                                    fullWidth
                                    value={this.state.tlETicket}
                                    onChange={this.updateTlETicket}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item xs={12} md={4}>
                                <Typography variant={'body1'}>
                                    TL DOB
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <TextField
                                    id='tlDOB'
                                    label=''
                                    variant='outlined'
                                    fullWidth
                                    value={this.state.tlDob}
                                    onChange={this.updateTlDob}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={24} alignItems={'center'}>
                            <Grid item xs={12} md={4}>
                                <Typography variant={'body1'}>
                                    TL Last Name
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <TextField
                                    id='tlLastName'
                                    label=''
                                    variant='outlined'
                                    fullWidth
                                    value={
                                        this.state.tlVerified
                                            ? this.props.groupRedemptionFormDuck
                                                  .tourLeader.lastName
                                            : ''
                                    }
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>

                        {tlVerified && (
                            <Grid item>
                                <Grid item>
                                    <Typography variant={'body1'}>
                                        Actual Pax flown: {paxFlown} out of{' '}
                                        {passengers.length +
                                            rejectedPassengers.length}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                        {tlVerified && (
                            <Grid
                                container
                                item
                                spacing={24}
                                direction={'column'}
                            >
                                <Grid item>
                                    <FormLabel>Card assignment</FormLabel>
                                    <RadioGroup
                                        row
                                        value={cardAssignment}
                                        onChange={this.onCardAssignmentChange}
                                    >
                                        <FormControlLabel
                                            disabled={
                                                !allowChangeCardAssignment
                                            }
                                            value={MULTI_CARD_UNLOADED}
                                            control={<Radio color='primary' />}
                                            label='Multiple Cards (Unloaded)'
                                        />
                                        <FormControlLabel
                                            disabled={
                                                !allowChangeCardAssignment
                                            }
                                            value={MULTI_CARD_DIGITAL}
                                            control={<Radio color='primary' />}
                                            label='Multiple Cards (Digital)'
                                        />
                                        <FormControlLabel
                                            disabled={true}
                                            value={SINGLE_CARD_DIGITAL}
                                            control={<Radio color='primary' />}
                                            label='Single Card (Digital)'
                                        />
                                        <FormControlLabel
                                            disabled={
                                                !allowChangeCardAssignment
                                            }
                                            value={MULTI_CARD_EV}
                                            control={<Radio color='primary' />}
                                            label='Multiple Cards (Preloaded eV)'
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color={'primary'}
                                        variant='contained'
                                        onClick={
                                            this.requestToChangeCardAssignment
                                        }
                                    >
                                        Request to change
                                    </Button>
                                </Grid>
                                {(cardAssignment === MULTI_CARD_UNLOADED ||
                                    cardAssignment === MULTI_CARD_EV) && (
                                    <Grid item>
                                        <Grid
                                            container
                                            item
                                            alignItems={'center'}
                                            spacing={24}
                                        >
                                            <Grid item xs={4}>
                                                <Typography variant={'body1'}>
                                                    Card Prefix
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id='cardRange'
                                                    fullWidth
                                                    variant='outlined'
                                                    onChange={
                                                        this._updateCardPrefix
                                                    }
                                                    value={
                                                        this.state.cardPrefix
                                                    }
                                                    autoComplete={'off'}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            alignItems={'center'}
                                            spacing={24}
                                        >
                                            <Grid item xs={4}>
                                                <Typography variant={'body1'}>
                                                    Card Range (Last 6 Digit.
                                                    E.g. 000001 - 000009)
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id=''
                                                    label='From'
                                                    fullWidth
                                                    variant='outlined'
                                                    onChange={
                                                        this
                                                            ._updateCardRangeStringFrom
                                                    }
                                                    value={cardRangeStringFrom}
                                                    autoComplete={'off'}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id=''
                                                    label='To'
                                                    fullWidth
                                                    variant='outlined'
                                                    onChange={
                                                        this
                                                            ._updateCardRangeStringTo
                                                    }
                                                    value={cardRangeStringTo}
                                                    autoComplete={'off'}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    color={'primary'}
                                                    variant='contained'
                                                    onClick={this.onAssign}
                                                >
                                                    Assign
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        )}

                        {this.state.tlVerified && (
                            <Grid
                                container
                                item
                                spacing={24}
                                direction={'column'}
                            >
                                <Grid item style={{ width: '100%' }}>
                                    <SimplePassengersTable
                                        data={passengers}
                                        headers={PASSENGERS_HEADERS}
                                        dataKeys={PASSENGERS_DATA_KEYS}
                                    />
                                </Grid>

                                <Grid container item spacing={24}>
                                    <Grid item>
                                        <Button
                                            color={'primary'}
                                            variant='contained'
                                            disabled={passengers.length < 1}
                                            onClick={this.onProceed}
                                        >
                                            Proceed
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        {!this.state.allCollectingChecked && (
                                            <Button
                                                color={'primary'}
                                                variant='contained'
                                                onClick={this.onCheckAll}
                                            >
                                                Check All
                                            </Button>
                                        )}
                                        {this.state.allCollectingChecked && (
                                            <Button
                                                color={'primary'}
                                                variant='contained'
                                                onClick={this.onUncheckAll}
                                            >
                                                Uncheck All
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </StyledSection>

                {this.state.tlVerified && (
                    <StyledSection title={'Passengers redeemed'}>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item style={{ width: '100%' }}>
                                <SimplePassengersTable
                                    data={
                                        this.props.groupRedemptionFormDuck
                                            .redeemedPassengers
                                    }
                                    headers={PASSENGERS_REDEEMED_HEADERS}
                                    dataKeys={PASSENGERS_REDEEMED_DATA_KEYS}
                                />
                            </Grid>
                        </Grid>
                    </StyledSection>
                )}

                {this.state.tlVerified && (
                    <StyledSection title={'Passengers not eligible'}>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item style={{ width: '100%' }}>
                                <SimplePassengersTable
                                    data={
                                        this.props.groupRedemptionFormDuck
                                            .rejectedPassengers
                                    }
                                    headers={PASSENGERS_NOT_COLLECTING_HEADERS}
                                    dataKeys={
                                        PASSENGERS_NOT_COLLECTING_DATA_KEYS
                                    }
                                />
                            </Grid>
                        </Grid>
                    </StyledSection>
                )}

                <StyledSnackbar
                    open={this.state.showSnackbar}
                    onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage}
                    variant={this.state.snackbarVariant}
                />
            </div>
        );
    }
}

const styles = theme => ({
    container: { ...theme.container },
    table: { width: '100%', overflowY: 'auto' },
    backBtn: { alignSelf: 'center' }
});

const mapStateToProps = state => {
    const { groupRedemptionFormDuck } = state;

    return { groupRedemptionFormDuck };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePassengers: passengers => dispatch(updatePassengers(passengers)),
        getGroupAndPassengersRedemption: input =>
            dispatch(getGroupAndPassengersRedemption(input)),
        groupSqApiCheck: data => dispatch(groupSqApiCheck(data)),
        groupCheckRedeemedPassenger: data =>
            dispatch(groupCheckRedeemedPassenger(data)),
        updateGroupRedemptionFormDuck: (k, v) =>
            dispatch(updateGroupRedemptionFormDuck(k, v)),
        updateGroupRedemptionFormDuckObj: obj =>
            dispatch(updateGroupRedemptionFormDuckObj(obj)),
        checkInactiveCards: cards => dispatch(checkInactiveCards(cards))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(GroupRedemptionForm));
