import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    withStyles,
    Grid,
    Typography
} from '@material-ui/core'
import GroupSubmissionTable from '../../containers/group/GroupSubmissionTable'
import {getAllGroup, voidGroup} from '../../ducks/groupApprovalDuck'
import {
    GROUP_TYPE_PENDING_APPROVAL,
    GROUP_TYPE_PENDING_COLLECTION,
    GROUP_TYPE_ACTION_REQUIRED,
    GROUP_TYPE_PENDING_SQ_API,
    GROUP_TYPE_APPROVED, GROUP_TYPE_REJECTED, GROUP_TYPE_COLLECTED, GROUP_TYPE_NOT_COLLECTED, GROUP_TYPE_VIEW_ALL
} from '../../constants/groupType'
import GroupStatusFilter from '../../components/GroupStatusFilter'
import DeleteDialog from '../../components/DeleteDialog'
import {hasGroupViewPermission, hasPermissions} from '../../helpers/utilities'
import StyledSnackbar from '../../components/StyledSnackbar'

import {
    hasCreatorPermissions, hasApproverPermissions, hasIssuerPermissions
} from '../../helpers/utilities'
import GroupStats from './GroupStats'
import StyledTable from "../../components/StyledTable";
import PageHelper from "../../helpers/pageHelper";
import {STATUS_PENDING_SQ_API, STATUS_ACTION_REQUIRED, STATUS_PENDING_APPROVAL,
    STATUS_APPROVED, STATUS_PENDING_COLLECTION, STATUS_REJECTED, STATUS_COLLECTED,
    STATUS_NOT_COLLECTED, STATUS_VIEW_ALL
} from "../../constants/groupStatus"


class GroupPane extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            statusFilter: "",
            groupType: '',
            getAllUser: false,
            showDeleteDialog: false,
            deletingGroup: {},
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            selectedPage: 1,
        }
        this.rowsPerPage = 100;
        this.pageHelper = new PageHelper({maxResultCount: this.rowsPerPage})
    }

    componentDidMount() {
        if (hasCreatorPermissions()) {
            this.setState({statusFilter: STATUS_ACTION_REQUIRED, groupType: GROUP_TYPE_ACTION_REQUIRED})
        } else if (hasApproverPermissions()) {
            this.setState({statusFilter: STATUS_PENDING_APPROVAL, groupType: GROUP_TYPE_PENDING_APPROVAL})
        } else if (hasIssuerPermissions()) {
            this.setState({statusFilter: STATUS_PENDING_COLLECTION, groupType: GROUP_TYPE_PENDING_COLLECTION})
        }
    }

    componentDidUpdate(prevProps, prevState){
        const {statusFilter} = this.props
        if (prevState.statusFilter !== this.state.statusFilter && this.state.statusFilter !== "") {
            this.onStatusFilterChange(this.state.statusFilter)
        }
    }

    onStatusFilterChange = async statusFilter => {
        let groupType = '';
        let getAllUser = false;

        switch (statusFilter) {
            case STATUS_PENDING_SQ_API:
                groupType = GROUP_TYPE_PENDING_SQ_API;
                break;
            case STATUS_ACTION_REQUIRED:
                groupType = GROUP_TYPE_ACTION_REQUIRED;
                break;
            case STATUS_PENDING_APPROVAL:
                groupType = GROUP_TYPE_PENDING_APPROVAL;
                if (hasApproverPermissions() || hasGroupViewPermission()) getAllUser = true;
                break;
            case STATUS_APPROVED:
                groupType = GROUP_TYPE_APPROVED;
                if (hasApproverPermissions() || hasGroupViewPermission()) getAllUser = true;
                break;
            case STATUS_PENDING_COLLECTION:
                if (hasApproverPermissions() || hasGroupViewPermission() || hasIssuerPermissions()) getAllUser = true
                groupType = GROUP_TYPE_PENDING_COLLECTION;
                break;
            case STATUS_REJECTED:
                if (hasApproverPermissions() || hasGroupViewPermission()) getAllUser = true;
                groupType = GROUP_TYPE_REJECTED;
                break;
            case STATUS_COLLECTED:
                if (hasApproverPermissions() || hasGroupViewPermission() || hasIssuerPermissions()) {
                    getAllUser = true
                } else if (hasCreatorPermissions()) {
                    getAllUser = false
                }
                groupType = GROUP_TYPE_COLLECTED;
                break;
            case STATUS_NOT_COLLECTED:
                if (hasApproverPermissions() || hasGroupViewPermission() || hasIssuerPermissions()) getAllUser = true;
                groupType = GROUP_TYPE_NOT_COLLECTED;
                break;

            case STATUS_VIEW_ALL:
                groupType = GROUP_TYPE_VIEW_ALL;
                break;
            default:
                console.log("Invalid group type")
        }
        this.pageHelper.populatePagingData({totalCount: 0}); //reset
        this.setState({
            getAllUser: getAllUser,
            groupType: groupType,
            selectedPage: 1, //reset
        }, ()=>{this._getAllGroups()});

    }

    async _getAllGroups(){
        let skipCount;
        if(this.pageHelper.totalCount===0){
            skipCount = 0;
        } else {
            let pageCallInput = this.pageHelper.pageCall(this.state.selectedPage)
            skipCount = this.pageHelper.skipCount;
        }

        const rs = await this.props.getAllGroup({
            groupType: this.state.groupType,
            getAllUser: this.state.getAllUser,
            maxResultCount:this.rowsPerPage,
            skipCount: skipCount
        });

        if (!rs.success) {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        } else {
            this.pageHelper.populatePagingData({totalCount: rs.result.totalCount});
            this.setState({totalCount: rs.result.totalCount})
        }
    }

    onChangePage = (e, page) => {
        let selectedPage;

        switch (page) {
            case 'first':
                selectedPage = 1;
                break;

            case 'prev':
                selectedPage = this.state.selectedPage -1;
                break;

            case 'next':
                selectedPage = this.state.selectedPage + 1;
                break;

            case 'last':
                selectedPage = this.pageHelper.getTotalNoOfPages();
                break;

            default:
                selectedPage = page;
        }

        this.setState({selectedPage: selectedPage},
            () => {
                this._getAllGroups()
            }
        )
    }


    onEdit = row => {
        if (this.state.statusFilter === 'PENDING APPROVAL') this.props.history.push('/group/group-approval/' + row.id)
        if (this.state.statusFilter === 'ACTION REQUIRED') this.props.history.push('/group/group-submission/' + row.id)
    }

    onDelete = row => {
        this.setState({showDeleteDialog: true, deletingGroup: row})
    }

    onDeleteConfirm = async () => {
        const {deletingGroup, groupType} = this.state
        const data = {id: deletingGroup.id}
        const rs = await this.props.voidGroup(data)
        if (rs.success) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'success',
                snackbarMessage: 'Group ' + deletingGroup.refNo + ' deleted',
                showDeleteDialog: false,
                deletingGroup: {}
            })
            const isApprover = hasPermissions('Group.Approver')
            this.props.getAllGroup({groupType, getAllUser: isApprover, maxResultCount: this.rowsPerPage, skipCount:0})
        } else {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: rs.message,
                showDeleteDialog: false,
                deletingGroup: {}
            })
        }
    }

    onDeleteCancel = () => {
        this.setState({showDeleteDialog: false, deletingGroup: {}})
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    onUpdateStatusFilter = statusFilter => {
        this.setState({statusFilter})
    }

    render() {
        const { groupApprovalDuck: {groups}} = this.props
        const {groupType, statusFilter, showDeleteDialog, deletingGroup: {refNo, submittedBy}} = this.state

        return (
            <div>
                <DeleteDialog showDeleteDialog={showDeleteDialog}
                    dialogTitle={'Proceed to delete the following group:'}
                    onDeleteCancel={this.onDeleteCancel} onDeleteConfirm={this.onDeleteConfirm}>
                    <Grid container item spacing={24} direction={'column'}>
                        <Grid container item spacing={24}>
                            <Grid item md={3}>
                                <Typography variant={'body1'}>Group ref no:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'}>{refNo}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={24}>
                            <Grid item md={3}>
                                <Typography variant={'body1'}>Submitted by:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'}>{submittedBy}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DeleteDialog>

                <Grid container spacing={24} direction={'column'}>
                    <Grid item>
                        <GroupStats onFabClick={this.onUpdateStatusFilter}/>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h5'}>Total: {this.pageHelper.getTotalCount()}</Typography>
                    </Grid>
                    <Grid container item spacing={24} direction={'column'}>
                        <Grid container item spacing={24} justify={'flex-end'}>
                            <Grid item>
                                <GroupStatusFilter
                                    statusFilter={statusFilter}
                                    onFilterChanged={this.onUpdateStatusFilter}

                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <GroupSubmissionTable
                                groupListing={this.props.groupApprovalDuck.groups}
                                onEdit={this.onEdit}
                                onDelete={this.onDelete}
                                groupType={groupType}
                                onChangePage={this.onChangePage}
                                rowsPerPage={this.rowsPerPage}
                                page={this.state.selectedPage - 1}
                                count={this.pageHelper.getTotalCount()}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

const mapStateToProps = state => {
    const {groupApprovalDuck} = state

    return {groupApprovalDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        getAllGroup: ({groupType, isApproval, maxResultCount, skipCount}) => dispatch(getAllGroup({groupType, isApproval, maxResultCount, skipCount})),
        voidGroup: data => dispatch(voidGroup(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupPane))