import React from 'react'
import {NavLink} from 'react-router-dom'
import {withStyles, Toolbar, Button} from '@material-ui/core'
import {hasPermissions} from '../../helpers/utilities'
import {GROUP_CREATOR_PERMISSION, GROUP_ISSUER_PERMISSION} from '../../constants/permissions'

class GroupHeader extends React.Component {

    render() {
        const {classes} = this.props

        const GROUP_SUBMISSION = props => <NavLink to={'/group/group-submission'} {...props}
            activeClassName={classes.active}/>
        const GROUP_ISSUANCE = props => <NavLink to={'/group/group-issuance'} {...props}
            activeClassName={classes.active}/>

        const hasCreatorPermissions = hasPermissions([GROUP_CREATOR_PERMISSION])
        const hasIssuerPermission = hasPermissions([GROUP_ISSUER_PERMISSION])

        return (
            <Toolbar>
                {hasCreatorPermissions &&
                <Button color={'primary'} component={GROUP_SUBMISSION}>Submission</Button>}
                {hasIssuerPermission && <Button color={'primary'} component={GROUP_ISSUANCE}>Issuance</Button>}
            </Toolbar>
        )
    }
}

const styles = theme => ({
    active: {
        backgroundColor: theme.palette.action.selected
    }
})

export default withStyles(styles)(GroupHeader)