import { HubConnectionBuilder } from '@aspnet/signalr';
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    withStyles
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper/Paper';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import StyledSection from '../../components/StyledSection';
import { signalRURL } from '../../config/config';
import {
    MULTI_CARD_DIGITAL,
    MULTI_CARD_EV,
    MULTI_CARD_UNLOADED,
    SINGLE_CARD_DIGITAL
} from '../../constants/groupCardAssignment';
import { TITLE_PREFIX } from '../../constants/titlePrefix';
import { resetGroupRedemptionFormReducer } from '../../ducks/groupRedemptionFormDuck';
import { saveCard } from '../../ducks/printCardDuck';
import signalRConstants from '../../helpers/signalRConstants';

class GroupCollectorSuccessDisplay extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            consentError: '',
            totalCtpGenValue: 0,
            totalCtpLpcValue: 0,
            undeletedPax: 0
        };
    }

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Group Collections Success`;

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(signalRURL)
            .build();
        this._startSignalRConnection();
        this.hubConnection.on('ReceiveMessage', this._receiveMessage);
        this.hubConnection.onclose(async () => {
            await this._startSignalRConnection();
        });

        const {
            groupRedemptionFormDuck: { passengers }
        } = this.props;
        let totalCtpGenValue = 0;
        let totalCtpLpcValue = 0;
        let undeletedPax = 0;
        for (const p of passengers) {
            if (!p.isDeleted) {
                totalCtpGenValue = totalCtpGenValue + p.ctpGenValue;
                totalCtpLpcValue = totalCtpLpcValue + p.ctpLpcValue;
                undeletedPax++;
            }
        }

        this.setState({ totalCtpGenValue, totalCtpLpcValue, undeletedPax });
    }

    _startSignalRConnection = async () => {
        this.hubConnection
            .start()
            .then(() => console.log('Connection started!'))
            .catch(err => {
                setTimeout(() => this._startSignalRConnection(), 5000);
                console.log('Error while establishing connection :(');
            });
    };

    _receiveMessage = (user, messageStr) => {
        let messageObj = JSON.parse(messageStr);

        if (messageObj.source === signalRConstants.USER_CONCIERGE) return;
        const currentUser = localStorage.getItem('username');
        if (messageObj.user !== currentUser) return;

        if (messageObj.data) {
            const {
                collectorDetails,
                formState,
                formDetailsList
            } = messageObj.data;
            this.props.updateCollectorDetails(collectorDetails);
            if (formState) this.setState({ formState });
            if (formDetailsList)
                this.props.updateFormDetailsList(formDetailsList);
            if (messageObj.type === signalRConstants.CLIENT_DONE) {
                this.setState({
                    clientIsDone: true,
                    isSharingScreen: false,
                    formState
                });
            }
        }
    };

    onPrintAll = cards => {
        let { groupRedemptionFormDuck } = this.props;
        window.open(
            'print-card?state=ctp_app&id=' +
                groupRedemptionFormDuck.redemptionTransactionId
        );
    };

    render() {
        const {
            classes,
            groupRedemptionFormDuck: {
                collector,
                tourLeader,
                group,
                passengers,
                cardAssignment
            }
        } = this.props;
        const cardsToPrint = passengers.map(m => {
            return {
                cardNo: m.preloadedVouchersCardNo
                    ? m.preloadedVouchersCardNo
                    : m.cardNo,
                voucherValidFrom: m.voucherValidFrom ? m.voucherValidFrom : '',
                voucherValidTo: m.voucherValidFrom ? m.voucherValidTo : '',
                voucherValuesAndRemarks: m.voucherValuesAndRemarks
                    ? m.voucherValuesAndRemarks
                    : '',
                issuedVouchersList: m.issuedVouchersList
            };
        });

        return (
            <div>
                <div className={classes.container}>
                    <Grid item>
                        <Button
                            onClick={() => {
                                this.onPrintAll(cardsToPrint);
                            }}
                            variant='contained'
                        >
                            Print All Cards
                        </Button>
                    </Grid>
                    <StyledSection
                        title={'Group Collections Success'}
                        toolbarButtons={this.props.toolbarButtons}
                    >
                        <Grid container spacing={24} direction={'column'}>
                            <Grid
                                container
                                item
                                spacing={24}
                                alignItems={'center'}
                            >
                                <Grid item xs={12} md={4}>
                                    <Typography variant={'body1'}>
                                        Group Reference No
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <Typography>{group.refNo}</Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                item
                                spacing={24}
                                alignItems={'center'}
                            >
                                <Grid item xs={12} md={4}>
                                    <Typography variant={'body1'}>
                                        Tour Company
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <Typography>{group.tourCompany}</Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                item
                                spacing={24}
                                alignItems={'center'}
                            >
                                <Grid item xs={12} md={4}>
                                    <Typography variant={'body1'}>
                                        Tour Leader Name
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <Typography>
                                        {tourLeader.lastName +
                                            ' ' +
                                            tourLeader.firstName}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                item
                                spacing={24}
                                alignItems={'center'}
                            >
                                <Grid item xs={12} md={4}>
                                    <Typography variant={'body1'}>
                                        Collection Date / Time
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <Typography>
                                        {moment().format('DD/MM/YY hh:mm')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container spacing={24} direction={'column'}>
                            <Grid item>
                                <Paper className={classes.root}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Pax Booking Ref. No.1
                                                </TableCell>
                                                <TableCell>
                                                    First name
                                                </TableCell>
                                                <TableCell>Last name</TableCell>
                                                {(cardAssignment ===
                                                    MULTI_CARD_UNLOADED ||
                                                    cardAssignment ===
                                                        SINGLE_CARD_DIGITAL ||
                                                    cardAssignment ===
                                                        MULTI_CARD_DIGITAL) && (
                                                    <TableCell>
                                                        Card No
                                                    </TableCell>
                                                )}
                                                {cardAssignment ===
                                                    MULTI_CARD_EV && (
                                                    <TableCell>
                                                        Preloaded Card No
                                                    </TableCell>
                                                )}

                                                <TableCell>
                                                    Voucher Value
                                                </TableCell>

                                                <TableCell>
                                                    Voucher start date
                                                </TableCell>

                                                <TableCell>
                                                    Voucher expiry date
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {passengers
                                                .filter(p => p.collecting)
                                                .map((passenger, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell
                                                                component='th'
                                                                scope='row'
                                                            >
                                                                <p>
                                                                    {
                                                                        passenger.pnr
                                                                    }
                                                                </p>
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    passenger.firstName
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    passenger.lastName
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {/* <Tooltip title='Print'>
																		<span className={classes.clickable} onClick={() => this.printCard(passenger)}>
																			{passenger.cardNo}
																		</span>
																	</Tooltip> */}
                                                                <span>
                                                                    {
                                                                        passenger.cardNo
                                                                    }
                                                                </span>
                                                            </TableCell>

                                                            {/* {(cardAssignment ===
                                                                MULTI_CARD_UNLOADED ||
                                                                cardAssignment ===
                                                                    MULTI_CARD_DIGITAL ||
                                                                cardAssignment ===
                                                                    MULTI_CARD_EV) && (
                                                                <TableCell>
                                                                    $
                                                                    {passenger.ctpGenValue
                                                                        ? passenger.ctpGenValue
                                                                        : 0}{' '}
                                                                    GEN | $
                                                                    {passenger.ctpLpcValue
                                                                        ? passenger.ctpLpcValue
                                                                        : 0}{' '}
                                                                    LPC
                                                                </TableCell>
                                                            )} */}
                                                            <TableCell>
                                                                {passenger.issuedVouchersList &&
                                                                    passenger
                                                                        .issuedVouchersList
                                                                        .length && (
                                                                        <span>
                                                                            {
                                                                                passenger
                                                                                    .issuedVouchersList
                                                                                    .length
                                                                            }{' '}
                                                                            x $
                                                                            {
                                                                                passenger
                                                                                    .issuedVouchersList[0]
                                                                                    .typeValue
                                                                            }{' '}
                                                                            Changi
                                                                            eVoucher(s)
                                                                        </span>
                                                                    )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {passenger.issuedVouchersList &&
                                                                    passenger
                                                                        .issuedVouchersList
                                                                        .length && (
                                                                        <span>
                                                                            {
                                                                                passenger
                                                                                    .issuedVouchersList[0]
                                                                                    .validFrom
                                                                            }
                                                                        </span>
                                                                    )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {passenger.issuedVouchersList &&
                                                                    passenger
                                                                        .issuedVouchersList
                                                                        .length && (
                                                                        <span>
                                                                            {
                                                                                passenger
                                                                                    .issuedVouchersList[0]
                                                                                    .validTo
                                                                            }
                                                                        </span>
                                                                    )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Typography
                                    component={'span'}
                                    variant={'body1'}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: collector.airlineConsent
                                        }}
                                    />
                                </Typography>
                            </Grid>

                            <Grid item>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: collector.collectionConsent
                                    }}
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                spacing={24}
                                direction={'column'}
                            >
                                {collector.clientSignatureBase64 && (
                                    <Grid
                                        container
                                        item
                                        spacing={24}
                                        direction={'column'}
                                    >
                                        <Grid item>
                                            <Typography variant={'body1'}>
                                                Collector's Signature:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <img
                                                src={
                                                    collector.clientSignatureBase64
                                                }
                                                alt=''
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </StyledSection>
                </div>
            </div>
        );
    }
}

const styles = theme => ({
    container: { ...theme.container },
    row: { display: 'flex' },
    rowItem: {
        display: 'flex',
        flex: 1,
        marginRight: 60,
        alignItems: 'center'
    },
    flexStart: { alignItems: 'flex-start' },
    consentText: { fontSize: '0.8125rem' },
    buttonMargin: { marginTop: '2rem' },
    clickable: { cursor: 'pointer', borderBottom: '1px dotted #ccc' }
});

const mapStateToProps = state => {
    const { groupRedemptionFormDuck } = state;

    return { groupRedemptionFormDuck };
};

const mapDispatchToProps = dispatch => {
    return {
        resetGroupRedemptionFormReducer: () =>
            dispatch(resetGroupRedemptionFormReducer()),
        saveCard: cards => dispatch(saveCard(cards))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(GroupCollectorSuccessDisplay));
