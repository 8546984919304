import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Fab} from '@material-ui/core'
import {Search} from '@material-ui/icons'


class StyledViewableBtn extends PureComponent {
    render() {
        return <Fab onClick={this.props.onClick} color={'primary'}
            variant={'round'} size={'small'} {...this.props}><Search/></Fab>
    }
}

StyledViewableBtn.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default StyledViewableBtn
