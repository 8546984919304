import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Fab } from '@material-ui/core'
import Header from '../containers/Header'
import { Print, ScreenShare } from '@material-ui/icons'
import { resetGroupRedemptionFormReducer } from '../ducks/groupRedemptionFormDuck'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import GroupCollectorSuccessDisplay from '../containers/group/GroupCollectorSuccessDisplay'
import { signalRURL } from '../config/config'
import signalRConstants from '../helpers/signalRConstants'
import { HubConnectionBuilder } from '@aspnet/signalr'

class GroupCollectorSuccess extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			consentError: '',
			totalCtpGenValue: 0,
			totalCtpLpcValue: 0,
			undeletedPax: 0,
			TABLE_HEAD: []
		}
	}

	_toolbarButtons = () => {
		return [
			<div>
				<Fab color={'primary'} variant='round' onClick={this.onPrint}>
					<Print />
				</Fab>
				<Fab color={'primary'} variant='round' onClick={this.onScreenShare}>
					<ScreenShare />
				</Fab>
			</div>
		]
	}

	_startSignalRConnection = async () => {
		this.hubConnection
			.start()
			.then(() => console.log('Connection started!'))
			.catch(err => {
				setTimeout(() => this._startSignalRConnection(), 5000)
				console.log('Error while establishing connection :(')
			})
	}

	_sendMessage = (dataObj, messageType) => {
		const user = localStorage.getItem('username')
		let messageObj = {
			user,
			type: messageType,
			source: signalRConstants.USER_CONCIERGE,
			data: dataObj
		}
		let messageStr = JSON.stringify(messageObj)
		this.hubConnection.invoke('SendMessage', 'default', messageStr).catch(function(err) {
			return console.error(err.toString())
		})
	}

	componentDidMount() {
		this.hubConnection = new HubConnectionBuilder().withUrl(signalRURL).build()
		this._startSignalRConnection()
		this.hubConnection.on('ReceiveMessage', this._receiveMessage)
		this.hubConnection.onclose(async () => {
			await this._startSignalRConnection()
		})
	}

	onScreenShare = () => {
		this._sendMessage(
			{
				group: this.props.groupRedemptionFormDuck.group,
				tourLeader: this.props.groupRedemptionFormDuck.tourLeader,
				collector: this.props.groupRedemptionFormDuck.collector,
				passengers: this.props.groupRedemptionFormDuck.passengers,
				signatureUri: this.props.groupRedemptionFormDuck.signatureUri
			},
			signalRConstants.DISPLAY_GROUP_COLLECTOR_SUCCESS
		)
	}

	onPrint = () => {
		window.print()
	}

	onBackClick = () => {
		this.props.resetGroupRedemptionFormReducer()
		window.location.replace('/group/group-submission')
	}

	render() {
		return (
			<div>
				<GlobalTimeChecker />

				<Header history={this.props.history} />
				<GroupCollectorSuccessDisplay toolbarButtons={this._toolbarButtons} history={this.props.history} />
			</div>
		)
	}
}

const styles = theme => ({
	container: { ...theme.container },
	row: { display: 'flex' },
	rowItem: { display: 'flex', flex: 1, marginRight: 60, alignItems: 'center' },
	flexStart: { alignItems: 'flex-start' },
	consentText: { fontSize: '0.8125rem' },
	buttonMargin: { marginTop: '2rem' }
})

const mapStateToProps = state => {
	const { groupRedemptionFormDuck } = state

	return { groupRedemptionFormDuck }
}

const mapDispatchToProps = dispatch => {
	return {
		resetGroupRedemptionFormReducer: () => dispatch(resetGroupRedemptionFormReducer())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupCollectorSuccess))
