import moment from "moment"
import {getCountryFromCode} from './airportCodeHelper.js'

const E_TIX_NO_INDEX = 90;   //91st character on boarding pass
const E_TIX_NO_LENGTH = 13;
const NAME_INDEX = 2;
const NAME_LENGTH = 20;
const ORIGIN_INDEX = 30;
const ORIGIN_LENGTH = 3;
const DESTINATION_INDEX = 33;
const DESTINATION_LENGTH = 3;
const JULIAN_DATE_INDEX = 44;
const JULIAN_DATE_LENGTH = 3;

class BoardingPassParser {
    constructor(boardingPassString) {
        this.boardingPassString = boardingPassString
    }

    getData = () => {
        if (!this.boardingPassString) throw new Error('boardingPassString cannot be null');
        if (typeof(this.boardingPassString) !== 'string') throw new Error('boardingPassString must be string')
        if (this.boardingPassString.length < 1) throw new Error('boardingPassString must be string')
        let nameObj = this._getName(this.boardingPassString);
        let bpData = {
            eTix: this._getETixNo(this.boardingPassString),
            originCountry: this._getOriginCountry(this.boardingPassString),
            destinationCountry: this._getDestinationCountry(this.boardingPassString),
            date: this._getDate(this.boardingPassString),
            lastName: nameObj.lastName,
            firstName: nameObj.firstName
        };
        return bpData;
    };

    _getName = (bpString)=>{
        let fullName = bpString.substr(NAME_INDEX, NAME_LENGTH);
        let nameLimiterRegex = /\//i;
        let nameLimiterRegexResult = nameLimiterRegex.exec(fullName);
        if(!nameLimiterRegexResult) return {};
        let nameLimiterIndex = nameLimiterRegexResult.index;
        let lastName = fullName.substr(0, nameLimiterIndex);
        let firstName = fullName.substr(nameLimiterIndex+1);
        return {firstName, lastName}

    }

    _getOriginCountry(bpString) {
        let originCode = bpString.substr(ORIGIN_INDEX, ORIGIN_LENGTH);
        let originCountry = getCountryFromCode(originCode);
        return originCountry
    }

    _getDestinationCountry(bpString) {
        let destinationCode = bpString.substr(DESTINATION_INDEX, DESTINATION_LENGTH);
        let destinationCountry = getCountryFromCode(destinationCode);
        return destinationCountry
    }

    _getETixNo(bpString) {
        let etixNo = bpString.substr(E_TIX_NO_INDEX, E_TIX_NO_LENGTH);
        return etixNo
    }

    _getDate(bgString) {
        let julianDate = bgString.substr(JULIAN_DATE_INDEX, JULIAN_DATE_LENGTH);
        let gregorianDate = this._convertJulianToGregorianDate(julianDate);
        return gregorianDate
    }

    _convertJulianToGregorianDate(julianDate) {
        var startOfYear = moment().startOf('year');
        var today = startOfYear.add(julianDate - 1, 'days');
        return today.format("YYYY-MM-DD")
    }


}

export default BoardingPassParser