import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {
	withStyles,
	Grid,
	Button,
	TextField,
	Typography,
	RadioGroup,
	FormLabel,
	FormControlLabel,
	FormControl,
	FormHelperText,
	Checkbox,
	Radio,
	Divider,
} from '@material-ui/core'
import Countries from '../../data/countriesCRM'
import CallingCodes from '../../data/callingCodesCRM'
import { FlightTransferOptions } from '../../dummy/flightTransferOptions'
import { dataURItoBlob, getCurrentAuthHeader, trueFalseToYesNo, myaxios, parseFullCardNo } from '../../helpers/utilities'
import { updateCollectorDetails, updateIndividualFormDuck } from '../../ducks/individualFormDuck'
import { submitIndividualForm, memberSearch, clearFormDetailsList, uploadFile, updateFormDetailsList } from '../../ducks/individualFormDuck'
import {
	selectExistingCr,
	selectNewCollector,
	selectExistingCrPhysicalVoucher,
	selectRegisterCr,
	selectIssueCtp,
	selectCtpPhysicalVoucher,
	selectCtpSingleCard,
	selectCtpMultipleCards,
	selectCtpDigitalCard,
	selectCtpPreloadedVouchersCard,
	selectExistingCrPreloadedVouchersCard,
} from '../../helpers/individualCollectorOptionHelper'
import { updateIndividualCollectorOptions } from '../../ducks/individualCollectorOptionDuck'
import { updateAllConsents } from '../../ducks/derivedDucks/consentDerivedDuck'
import StyledSelect from '../../components/StyledSelect'
import { labelToObject } from '../../helpers/reactSelectHelper'
import { isEqual } from 'lodash'
import StyledSnackbar from '../../components/StyledSnackbar'
import moment from 'moment'
import CardRangeHelper from '../../helpers/cardRangeHelper'
import * as cloneDeep from 'lodash/cloneDeep'
import { NOT_ALL_PAX_ASSIGNED_CARD_ERROR_MSG, NOT_ALL_PAX_ASSIGNED_GEN_ERROR_MSG, NOT_ALL_PAX_ASSIGNED_LPC_ERROR_MSG } from '../../constants/messages'
import Spacing from '../../components/Spacing'
import { CARD_NO_OF_DIGITS, CR_CARD_PREFIX, CTP_CARD_PREFIX } from '../../constants/cardConstants'
import { MEMBER_TYPE_REGISTER_CR, MEMBER_TYPE_EXISTING_CR, MEMBER_TYPE_NO_CR } from './helper/collectorPersonalDetailsHelper'
import ConsentDerivedDuckContainer from '../derivedDuckContainers/consentDerivedDuckContainer'
import { calculatePassengersVouchers } from '../../repos/calculatePassengersVouchers'
import { dummyIndividualFormDuck } from '../../dummy/dummyIndividualFormDuck'

class CollectorPersonalDetails extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
			existingCrError: '',
			registerCrError: '',
			nameError: '',
			surnameError: '',
			dobError: '',
			callingCodeError: '',
			mobileNoTempError: '',
			contactNo: '',
			contactNoError: '',
			consentError: '',
			countryError: '',
			emailHelperText: '',
			emailError: false,
			cardNoError: '',
			mobileNoTemp: '',
			callingCode: '',
			consent: false,
			cardNo: '',
			email: '',
			maxDob: '',
			validated: false,
			validateCount: 0,
			totalCtpGenValue: 0, //remove
			totalCtpLpcValue: 0, //remove
			undeletedPax: 0, //remove
			isEmailValid: false,
			cardPrefix: '0000400000000',
			cardRangeTo: '',
			cardRangeFrom: '',
			genCardPrefix: '',
			genCardRangeFrom: '',
			genCardRangeTo: '',
			lpcCardPrefix: '',
			lpcCardRangeTo: '',
			lpcCardRangeFrom: '',
			ocidExists: false,
		}
	}

	componentDidMount() {
		//this.props.updateIndividualFormDuck(dummyIndividualFormDuck)

		const {
			individualFormDuck: { collectorDetails, formDetailsList },
		} = this.props

		// set the collector options for cso
		if (this.props.parentView === 'conciergeView') {
			let pax = formDetailsList.filter((p) => !p.isDeleted).length
			let collectorOptions = selectIssueCtp(pax)
			this.props.updateIndividualCollectorOptions(collectorOptions)
		}

		if (collectorDetails.dob === '') {
			const maxDob = moment()
				.subtract(2, 'years')
				.format('YYYY-MM-DD')
			this.setState({ maxDob })
		}

		if (collectorDetails.country !== '' || collectorDetails.country !== undefined) {
			const {
				individualFormDuck: { collectorDetails },
			} = this.props
			collectorDetails.countryCode = this._updateCallingCode(collectorDetails.country)
			this.props.updateCollectorDetails(collectorDetails)
		}

		collectorDetails.clientSignatureBase64 = ''
		collectorDetails.csoSignatureBase64 = ''
		this.props.updateCollectorDetails(collectorDetails)
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.overrideExistingCrOptions !== this.props.overrideExistingCrOptions) {
			if (this.props.overrideExistingCrOptions) {
				let collectorOptions = selectExistingCr()
				this.props.updateIndividualCollectorOptions(collectorOptions)
			}
		}
		// set the collector options
		if (prevProps.individualFormDuck.formDetailsList.length !== this.props.individualFormDuck.formDetailsList.length) {
			let pax = this.props.individualFormDuck.formDetailsList.filter((p) => !p.isDeleted).length
			let collectorOptions = selectIssueCtp(pax)
			this.props.updateIndividualCollectorOptions(collectorOptions)
		}

		if (prevProps.formState !== this.props.formState) {
			if (!isEqual(this.props.formState, this.state)) {
				this.setState({ ...this.props.formState })
			}
		}
		if (prevProps.parentDidSubmit !== this.props.parentDidSubmit) {
			if (this.props.parentDidSubmit === true) this.onParentDidSubmit()
		}
		if (prevProps.parentDidDone !== this.props.parentDidDone) {
			if (this.props.parentDidDone === true) this.onParentDidDone()
		}

		if (prevState.validateCount !== this.state.validateCount) {
			if (this.props.childIsValidated) {
				if (this.state.validated) {
					this.props.childIsValidated({ ...this.state })
				}
				this.props.resetParentDone({ ...this.state })
			}
		}

		if (prevProps.individualFormDuck.collectorDetails.countryCode !== this.props.individualFormDuck.collectorDetails.countryCode) {
			this.setState({ callingCode: this.props.individualFormDuck.collectorDetails.countryCode })
		}

		if (prevProps.individualFormDuck.collectorDetails.country !== this.props.individualFormDuck.collectorDetails.country) {
			this._updateCallingCode(this.props.individualFormDuck.collectorDetails.country)
		}

		if (prevProps.individualFormDuck.uploadFileIsLoading === true && this.props.individualFormDuck.uploadFileIsLoading === false) {
			// if there's a signature Uri, proceed to submit form
			if (this.props.individualFormDuck.collectorDetails.signatureUri !== '') {
				const rs = await this.props.submitIndividualForm({
					form: this.props.individualFormDuck,
					redemptionTransactionId: this.props.redemptionTransaction.id,
					collectionConsent: this.props.consentDerivedDuck.collectionConsent,
					marketingConsent: this.props.consentDerivedDuck.marketingConsent,
					airlineConsent: this.props.consentDerivedDuck.airlineConsent,
					additionalCrConsent: this.props.consentDerivedDuck.additionalCrConsent,
					registerCr: this.props.individualCollectorOptionDuck.isRegisterCr,
					existingCr: this.props.individualCollectorOptionDuck.isExistingCr,
					isDigitalCard: this.props.individualCollectorOptionDuck.isCtpDigitalCard,
					isPreloadedVouchersCard:
						this.props.individualCollectorOptionDuck.isCtpPreloadedVouchersCard || this.props.individualCollectorOptionDuck.isExistingCrPreloadedVouchersCard,
					issuePhysicalVoucherToCr: this.props.individualFormDuck.issuePhysicalVoucherToCr,
				})
				if (rs.success) {
					// No need to send auditLogs on first request
					this.setState({
						showSnackbar: true,
						snackbarMessage: 'Form submitted',
						snackbarVariant: 'success',
						parentDidSumit: false,
					})
					//this.props.clearFormDetailsList();
					setTimeout(() => this.props.history.push('/individual-collector-success'), 2000)
				} else {
					this.setState({
						showSnackbar: true,
						snackbarMessage: rs.message,
						snackbarVariant: 'error',
						parentDidSumit: false,
					})
				}
			}
		}
	}

	_updateCallingCode = (country) => {
		const callingCode = CallingCodes.find((c) => c.country === country)
		if (callingCode) {
			this.setState({ callingCode: callingCode.label })
			return callingCode.label
		}
	}

	onCollectorPersonalDetailsChange = async (k, e) => {
		let collectorDetails = { ...this.props.individualFormDuck.collectorDetails }
		let collectorOptions = { ...this.props.individualCollectorOptionDuck }

		const formState = this.state

		if (k === 'callingCode' || k === 'country' || k === 'frequencyOfTravelInPastYear') {
			// process select fields differently
			if (k === 'callingCode') {
				this.setState({ callingCode: e.label })
				collectorDetails.countryCode = e.label
				collectorDetails.mobileNo = e.label + '-' + this.state.mobileNoTemp
				const country = CallingCodes.find((c) => c.label === e.label)
				if (country) collectorDetails.country = country.country
			} else if (k === 'country') {
				collectorDetails.countryCode = this._updateCallingCode(e.label)
				collectorDetails.country = e.label
			} else if (k === 'frequencyOfTravelInPastYear') {
				collectorDetails.frequencyOfTravelInPastYear = e.label
			}

			if (collectorOptions.isRegisterCr) {
				let collectorOptions = selectRegisterCr(collectorDetails.country)
				this.props.updateIndividualCollectorOptions(collectorOptions)
			}

			this.props.sendMessage({ collectorDetails, formState, collectorOptions })
			return this.props.updateCollectorDetails(collectorDetails)
		}

		if (k === 'existingCr' || k === 'registerCr') {
			let memberType = ''
			// process radio fields differently
			if (k === 'existingCr' && e.target.value === 'yes') {
				memberType = MEMBER_TYPE_EXISTING_CR
				collectorOptions = selectExistingCr()
			} else if (k === 'registerCr' && e.target.value === 'yes') {
				memberType = MEMBER_TYPE_REGISTER_CR
				collectorOptions = selectRegisterCr(collectorDetails.country)
			} else if (k === 'registerCr' && e.target.value !== 'yes') {
				memberType = MEMBER_TYPE_NO_CR
				let pax = this.props.individualFormDuck.formDetailsList.filter((p) => !p.isDeleted).length
				collectorOptions = selectIssueCtp(pax)
			} else {
				memberType = MEMBER_TYPE_NO_CR
				let pax = this.props.individualFormDuck.formDetailsList.filter((p) => !p.isDeleted).length
				collectorOptions = selectIssueCtp(pax)
			}

			// if either value is yes, then dob limit is 16 yo, else 2 yo
			if (collectorOptions.isExistingCr || collectorOptions.isRegisterCr) {
				this.setState({
					maxDob: moment()
						.subtract(16, 'years')
						.format('YYYY-MM-DD'),
				})
			} else {
				maxDob: moment()
					.subtract(2, 'years')
					.format('YYYY-MM-DD')
			}

			let getPassengersVouchersResponse = await calculatePassengersVouchers({
				formsDetailList: this.props.individualFormDuck.formDetailsList,
				isCr: collectorOptions.isExistingCr || collectorOptions.isRegisterCr,
				isPreloadedVoucher: this.props.individualCollectorOptionDuck.isCtpPreloadedVouchersCard || this.props.individualCollectorOptionDuck.isExistingCrPreloadedVouchersCard
			})

			if (!getPassengersVouchersResponse.success) {
				this.setState({
					showSnackbar: true,
					snackbarMessage: 'Cannot retrieve vouchers',
					snackbarVariant: 'error',
				})
				return
			}

			let formattedPassengersWithVouchers = getPassengersVouchersResponse.passengers
			this.props.sendMessage({
				collectorDetails,
				formState,
				formDetailsList: formattedPassengersWithVouchers,
				collectorOptions,
			})

			this.props.updateFormDetailsList(formattedPassengersWithVouchers)
			this.props.updateCollectorDetails(collectorDetails)
			this.props.updateIndividualCollectorOptions(collectorOptions)

			return
		}

		if (k === 'cardNo') {
			let cardNo = e.target.value.substr(0, 19)
			collectorDetails[k] = cardNo
			this.props.sendMessage({ collectorDetails, formState, collectorOptions })
			this.props.updateCollectorDetails(collectorDetails)
			let clonedPassengers = [].concat(this.props.individualFormDuck.formDetailsList)
			clonedPassengers.forEach((p) => {
				if (!p.isDeleted) p.cardNo = cardNo
			})
			this.props.updateFormDetailsList(clonedPassengers)
			return
		}

		if (k === 'contactNo') {
			this.setState({ contactNo: e.target.value })
			collectorDetails[k] = e.target.value
			this.props.sendMessage({ collectorDetails, formState, collectorOptions })
			this.props.updateCollectorDetails(collectorDetails)
		}

		collectorDetails[k] = e.target.value
		this.props.sendMessage({ collectorDetails, formState, collectorOptions })
		this.props.updateCollectorDetails(collectorDetails)
	}

	onParentDidDone = async () => {
		// triggers componentDidUpdate
		this._validate()
	}

	onParentDidSubmit = async () => {
		if (await this._validate()) {
			const { individualFormDuck } = this.props

			if (individualFormDuck.collectorDetails.clientSignatureBase64 === '' && individualFormDuck.collectorDetails.csoSignatureBase64 === '') {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: 'Signature is required to continue',
				})
				this.resetParentSubmit()
				return false
			}

			let blob = new Blob()
			if (individualFormDuck.collectorDetails.clientSignatureBase64 !== '') {
				blob = dataURItoBlob(individualFormDuck.collectorDetails.clientSignatureBase64)
			} else {
				blob = dataURItoBlob(individualFormDuck.collectorDetails.csoSignatureBase64)
			}
			const formData = new FormData()
			formData.append('file', blob)
			let rs = await this.props.uploadFile(formData, 'signature')
			if (rs.success) {
			} else {
				this.setState({
					showSnackbar: true,
					snackbarMessage: rs.message,
					snackbarVariant: 'error',
					parentDidSumit: false,
				})
				this.resetParentSubmit()

				return false
			}
		}
		this.resetParentSubmit()
	}

	_validateCardNo = (cardNo, type = '') => {
		if (!cardNo) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'No Card Number was found' })
			return false
		}

		const prefix = cardNo.substr(0, 5)

		if (/[^0-9]/g.test(cardNo)) {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: 'Card no. only accepts digits',
			})
			return false
		}

		if (this.props.individualCollectorOptionDuck.isCtpPhysicalVoucher && this.props.individualFormDuck.collectorDetails.issuePhysicalVoucherToCr) {
			if (prefix !== CR_CARD_PREFIX) {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: `CR prefix should be ${CR_CARD_PREFIX}`,
				})
				return false
			}
		}

		if (this.props.individualCollectorOptionDuck.isExistingCr) {
			if (prefix !== CR_CARD_PREFIX) {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: `CR prefix should be ${CR_CARD_PREFIX}`,
				})
				return false
			}
			if (cardNo.length !== 19) {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: `CR card length should be 19 digits`,
				})
				return false
			}
		}
		if (this.props.individualCollectorOptionDuck.isSingleCtpCard) {
			if (prefix !== CTP_CARD_PREFIX) {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: `CTP prefix should be ${CTP_CARD_PREFIX}`,
				})
				return false
			}
		}
		return true
	}

	_collectorDetailsValidation = async () => {}

	_validate = async () => {
		const { collectorDetails, formDetailsList } = this.props.individualFormDuck
		const { individualCollectorOptionDuck } = this.props

		const errMsg = 'This field is required'
		let rs = true

		if (individualCollectorOptionDuck.isRegisterCr) {
			if (collectorDetails['contactNo'].length < 5 || collectorDetails['contactNo'].length > 12) {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: `Mobile Number length must be minimum of 5 and maximum of 12`,
				})
				return false
			}
		}

		if (collectorDetails.contactNo) {
			const pattern = individualCollectorOptionDuck.isExistingCr ? /[^+A-Za-z0-9]/g : /[^A-Za-z0-9]/g
			if (pattern.test(collectorDetails.contactNo)) {
				//if it doesn't contain only digits
				this.setState({ contactNoError: 'This field only accept numbers and characters' })
				rs = false
			} else {
				this.setState({ contactNoError: '' })
			}
		} else {
			this.setState({ contactNoError: '' })
		}

		// if either registerCr or existingCR is yes, then dob limit is 16 yo, else 2 yo
		let dob = moment(collectorDetails.dob)

		if (individualCollectorOptionDuck.isExistingCr || individualCollectorOptionDuck.isRegisterCr) {
			if (dob.isAfter(moment().subtract(16, 'years'))) {
				this.setState({ dobError: 'Date of birth must be at least 16 years' })
				rs = false
			} else {
				this.setState({ dobError: '' })
			}
			// if registerCr or existingCr is yes, then check CR card prefix
			const {
				individualFormDuck: {
					collectorDetails: { cardNo },
				},
			} = this.props

			if (individualCollectorOptionDuck.isExistingCr) {
				if (this.props.parentView === 'conciergeView' && !this._validateCardNo(cardNo)) {
					return
				}
			}
		} else {
			if (dob.isAfter(moment().subtract(2, 'years'))) {
				this.setState({ dobError: 'Date of birth must be at least 2 years' })
				rs = false
			} else {
				this.setState({ dobError: '' })
			}
			// if not registerCr or existingCr is no, then check CTP card prefix
			const {
				individualFormDuck: {
					collectorDetails: { cardNo },
				},
			} = this.props
			if (this.props.parentView === 'conciergeView' && this.props.individualCollectorOptionDuck.isSingleCtpCard && !this._validateCardNo(cardNo)) {
				return
			}
		}

		this.setState({ validated: rs, validateCount: this.state.validateCount++ })
		if (!rs) return rs

		const requiredFields = ['name', 'surname', 'dob', 'country']

		if (this.props.parentView === 'conciergeView') {
			if (this.props.individualCollectorOptionDuck.isMultipleCtpCard) {
				let eligibleCardPassengers = formDetailsList.filter((p) => !p.isDeleted)

				const unassigned = eligibleCardPassengers.filter((p) => !!!p.cardNo)
				if (unassigned.length > 0) {
					this.setState({
						showSnackbar: true,
						snackbarVariant: 'error',
						snackbarMessage: NOT_ALL_PAX_ASSIGNED_CARD_ERROR_MSG,
					})
					return
				}

				let wrongPrefixPassengers = eligibleCardPassengers.filter((p) => p.cardNo.substring(0, 5) !== CTP_CARD_PREFIX)
				if (wrongPrefixPassengers.length > 0) {
					this.setState({
						showSnackbar: true,
						snackbarVariant: 'error',
						snackbarMessage: 'Please input correct ctp card no',
					})
					return
				}

				let cardNoSet = new Set()
				eligibleCardPassengers.forEach((p) => cardNoSet.add(p.cardNo))
				if (cardNoSet.size !== eligibleCardPassengers.length) {
					this.setState({
						showSnackbar: true,
						snackbarVariant: 'error',
						snackbarMessage: 'Duplicated Card No Detected',
					})
					return
				}
			}

			if (this.props.individualCollectorOptionDuck.isCtpPreloadedVouchersCard) {
				let eligibleCardPassengers = formDetailsList.filter((p) => !p.isDeleted)

				const unassigned = eligibleCardPassengers.filter((p) => !!!p.preloadedVouchersCardNo)
				if (unassigned.length > 0) {
					this.setState({
						showSnackbar: true,
						snackbarVariant: 'error',
						snackbarMessage: NOT_ALL_PAX_ASSIGNED_CARD_ERROR_MSG,
					})
					return
				}

				let wrongPrefixPassengers = eligibleCardPassengers.filter((p) => p.preloadedVouchersCardNo.substring(0, 5) !== CTP_CARD_PREFIX)
				if (wrongPrefixPassengers.length > 0) {
					this.setState({
						showSnackbar: true,
						snackbarVariant: 'error',
						snackbarMessage: 'Please input correct ctp card no',
					})
					return
				}

				let cardNoSet = new Set()
				eligibleCardPassengers.forEach((p) => cardNoSet.add(p.preloadedVouchersCardNo))
				if (cardNoSet.size !== eligibleCardPassengers.length) {
					this.setState({
						showSnackbar: true,
						snackbarVariant: 'error',
						snackbarMessage: 'Duplicated Card No Detected',
					})
					return
				}
			}

			if (this.props.individualCollectorOptionDuck.isCtpPhysicalVoucher) {
				let eligiblePhysicalPassengers = formDetailsList.filter((p) => !p.isDeleted)

				const unassigned = eligiblePhysicalPassengers.filter((p) => p.genPhysicalVoucherNo === '')
				if (unassigned.length > 0) {
					this.setState({
						showSnackbar: true,
						snackbarVariant: 'error',
						snackbarMessage: NOT_ALL_PAX_ASSIGNED_GEN_ERROR_MSG,
					})
					return
				}

				const unassignedLPC = eligiblePhysicalPassengers.filter((p) => p.lpcPhysicalVoucherNo === '' && p.ctpLpcValue > 0)
				if (unassignedLPC.length > 0) {
					this.setState({
						showSnackbar: true,
						snackbarVariant: 'error',
						snackbarMessage: NOT_ALL_PAX_ASSIGNED_LPC_ERROR_MSG,
					})
					return
				}

				let physicalNoSet = new Set()
				eligiblePhysicalPassengers.forEach((p) => physicalNoSet.add(p.genPhysicalVoucherNo))
				if (physicalNoSet.size !== eligiblePhysicalPassengers.length) {
					this.setState({
						showSnackbar: true,
						snackbarVariant: 'error',
						snackbarMessage: 'Duplicated Physical Voucher Detected',
					})
					return
				}
			}
		}

		if (this.props.parentView === 'conciergeView' && (!individualCollectorOptionDuck.isRegisterCr && !individualCollectorOptionDuck.isCtpDigitalCard)) {
			const isAssignedNo = formDetailsList.some((p) => !p.cardNo && !p.genPhysicalVoucherNo && !p.preloadedVouchersCardNo && !p.isDeleted)
			if (isAssignedNo) {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: 'Please enter card or physical voucher number for all passengers',
				})
				return
			}
		}

		requiredFields.forEach((k) => {
			const sk = k + 'Error'
			if (collectorDetails[k] === '' || collectorDetails[k] === null) {
				rs = false
				this.setState({ [sk]: errMsg })
			} else {
				this.setState({ [sk]: '' })
			}
		})

		if ((collectorDetails['email'] === '' || collectorDetails['email'] === null) && (individualCollectorOptionDuck.isRegisterCr || collectorDetails.allowReceivingInfo)) {
			rs = false
			this.setState({ emailHelperText: errMsg, emailError: true })
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: 'Email Address is required',
			})
		} else {
			this.setState({ emailHelperText: '', emailError: false })
		}

		// if email is not empty then validate it
		if (collectorDetails.email !== '') {
			if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(collectorDetails.email)) {
				this.setState({ emailHelperText: 'Email is invalid', emailError: true })
				return false
			} else {
				const rs = await this.onEmailBlur(collectorDetails.email)

				if (!rs) {
					return false
				} else {
					this.setState({ emailHelperText: '', emailError: false })
				}
			}
		}

		const requiredFields2 = ['callingCode', 'contactNo']

		requiredFields2.forEach((k) => {
			const sk = k + 'Error'

			if ((collectorDetails[k] === '' || collectorDetails[k] === null) && individualCollectorOptionDuck.isRegisterCr) {
				rs = false
				this.setState({ [sk]: errMsg })
			} else {
				this.setState({ [sk]: '' })
			}
		})

		this.setState({ validated: rs, validateCount: this.state.validateCount++ })
		if (!rs) return false
		return rs
	}

	onVerifyCRCard = async () => {
		const { cardNo } = this.state
		if (!cardNo) {
			this.setState({ showSnackbar: true, snackbarVariant: 'error', snackbarMessage: `Please enter CR Card No` })
			return
		}

		if (!this._validateCardNo(cardNo)) return

		const prefix = cardNo.substr(0, 5)
		if (prefix !== CR_CARD_PREFIX) {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: `CR prefix should be ${CR_CARD_PREFIX}`,
			})
			return
		}
		const rs = await this.props.memberSearch('cardNo', this.state.cardNo)
		if (rs.success) {
			let clonedPassengers = this.props.individualFormDuck.formDetailsList.map((m) => {
				m.cardNo = this.state.cardNo
				return m
			})
			this.props.updateFormDetailsList(clonedPassengers)
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'success',
				snackbarMessage: 'Verified',
			})
		} else {
			this.setState({
				showSnackbar: true,
				snackbarVariant: 'error',
				snackbarMessage: rs.message !== '' ? rs.message : 'Unexpected error',
			})
		}
	}

	_promptErrorMessage = (message) => {
		this.setState({
			showSnackbar: true,
			snackbarVariant: 'error',
			snackbarMessage: message,
		})
	}

	onVerifyEmail = async () => {
		const { email } = this.state
		let newFormDetailsList = [...this.props.individualFormDuck.formDetailsList]
		if (!email) {
			this._promptErrorMessage('Please enter email address')
			return
		}
		const rs = await this.props.memberSearch('email', email)
		if (rs.success) {
			try {
				let rs2 = await myaxios('post', '/Member/CheckOcidEmailExist', { email: email }, getCurrentAuthHeader())
				if (rs2.data && rs2.data.result === true) {
					this.setState({
						showSnackbar: true,
						snackbarVariant: 'success',
						snackbarMessage: 'Verified',
					})
					let isExistCardNo = newFormDetailsList.some((p) => !p.cardNo)
					if (isExistCardNo) {
						let newCollectorDetail = this.props.individualFormDuck.collectorDetails
						newFormDetailsList.forEach((e) => {
							e.cardNo = newCollectorDetail.cardNo
						})
					}
				} else {
					this._promptErrorMessage('Cannot credit into CR account, no OCID account exists')
				}
			} catch (e) {
				this._promptErrorMessage('Cannot credit into CR account, no OCID account exists')
			}
		} else {
			let errMessage = !!rs.message ? rs.message : 'Error checking CR account'
			this._promptErrorMessage(errMessage)
		}
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	onConsent = () => {
		let collectorDetails = { ...this.props.individualFormDuck.collectorDetails }
		collectorDetails.allowReceivingInfo = !this.state.consent
		this.props.updateCollectorDetails(collectorDetails)
		this.setState({ consent: !this.state.consent }, () => {
			const formState = this.state
			this.props.sendMessage({ collectorDetails, formState, collectorOptions: null })
		})
	}

	resetParentSubmit = () => {
		// reset parent submit so it can submit again
		this.props.resetParentSubmit()
	}

	onVerifyCRChange = (e) => {
		let cardNo = e.target.value
		let formattedCardNo = cardNo.substr(0, 19)
		this.setState({ cardNo: formattedCardNo })
	}

	onVerifyEmailChange = (e) => {
		this.setState({ email: e.target.value })
	}

	onEmailBlur = async (v) => {
		this.setState({ isEmailValid: false, emailHelperText: '', emailError: false, ocidExists: false })
		if (!this.props.individualCollectorOptionDuck.isRegisterCr) return true //only continue checking if registering CR

		if (v === '') return false
		if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(v)) {
			this.setState({ isEmailValid: false, emailHelperText: 'Invalid Email Address', emailError: true })
			return false
		}
		let masterReturn = false

		const data = { email: v }
		//const experianEmailCheck = myaxios('post', '/Account/ExperianEmailVerification', data, getCurrentAuthHeader())
		const ocidExistingEmailCheck = myaxios('post', '/Member/CheckOcidEmailExist', data, getCurrentAuthHeader())
		const doesMemberExist = myaxios('get', '/IndividualPassenger/MemberSearch', { params: { email: data.email }, ...getCurrentAuthHeader() })

		await Promise.all([ocidExistingEmailCheck, doesMemberExist /*experianEmailCheck */]).then((responses) => {
			let ocidExistingEmailResult = responses[0]
			let memberExistingEmailResult = responses[1]
			//let experianValidEmailResult = responses[2];

			if (memberExistingEmailResult.success) {
				this.setState({ isEmailValid: false, emailHelperText: 'Email exist in CR', emailError: true })
				masterReturn = false
				return
			}

			/* //todo:syd uncomment this if experianEmail check is required.
            if(!experianValidEmailResult.success){
                this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: experianValidEmailResult.message})
                masterReturn = false;
                return;
            } else {
                if(experianValidEmailResult.data && !experianValidEmailResult.data.result){
                    this.setState({isEmailValid: false, emailHelperText: 'Not a valid email', emailError:true})
                    masterReturn = false;
                    return;
                }
            }
            */

			if (!ocidExistingEmailResult.success) {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: ocidExistingEmailResult.message,
				})
				masterReturn = false
				return
			} else {
				if (ocidExistingEmailResult.data && ocidExistingEmailResult.data.result) {
					this.setState({ isEmailValid: false, emailHelperText: 'Email exist in OCID', emailError: true, ocidExists: true })
					masterReturn = false
					return
				}
			}
			this.setState({ isEmailValid: true, emailHelperText: 'Valid Email', emailError: false })
			masterReturn = true
		})

		return masterReturn
	}

	_doesMemberExist = async (email) => {
		const data = { params: { email }, ...getCurrentAuthHeader() }
		return await myaxios('get', '/IndividualPassenger/MemberSearch', data)
	}

	onCardPrefixChange = (e) => {
		this.setState({ cardPrefix: e.target.value })
	}

	onCardRangeFromChange = (e) => {
		this.setState({ cardRangeFrom: parseFullCardNo(e.target.value) })
	}

	onCardRangeToChange = (e) => {
		const { cardRangeFrom } = this.state
		this.setState({ cardRangeTo: parseFullCardNo(e.target.value) })
		if (cardRangeFrom) {
			this.setState({ cardRange: cardRangeFrom + '-' + e.target.value })
		}
	}

	onAssign = () => {
		const { cardRangeFrom, cardRangeTo, cardPrefix, genCardRangeFrom, genCardRangeTo, lpcCardRangeFrom, lpcCardRangeTo, genCardPrefix, lpcCardPrefix } = this.state

		const {
			individualFormDuck: { formDetailsList },
		} = this.props
		const { individualCollectorOptionDuck } = this.props

		let updatedPassengers = cloneDeep(formDetailsList)
		if (
			individualCollectorOptionDuck.isMultipleCtpCard ||
			individualCollectorOptionDuck.isCtpPreloadedVouchersCard ||
			individualCollectorOptionDuck.isExistingCrPreloadedVouchersCard
		) {
			let formattedCardRangeTo = cardRangeTo ? cardRangeTo : cardRangeFrom

			if (cardRangeFrom === '' || /[a-zA-Z]/.test(cardRangeFrom) || /[a-zA-Z]/.test(formattedCardRangeTo)) {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: 'Please enter a valid card range',
				})
				return
			}
			let cardRange = cardRangeFrom + '-' + formattedCardRangeTo

			let cardRangeHelper = new CardRangeHelper({
				rangeString: cardRange,
				prefix: cardPrefix,
				noOfDigits: CARD_NO_OF_DIGITS,
			})
			cardRangeHelper.prepareData()
			let cards = cardRangeHelper.generateRange()
			for (let i = 0; i < updatedPassengers.length; i++) {
				if (individualCollectorOptionDuck.isMultipleCtpCard) {
					if (!updatedPassengers[i].isDeleted) updatedPassengers[i].cardNo = cards.shift()
				} else if (individualCollectorOptionDuck.isCtpPreloadedVouchersCard || individualCollectorOptionDuck.isExistingCrPreloadedVouchersCard) {
					if (!updatedPassengers[i].isDeleted) updatedPassengers[i].preloadedVouchersCardNo = cards.shift()
				}
			}
		}

		if (individualCollectorOptionDuck.isCtpPhysicalVoucher || individualCollectorOptionDuck.isExistingCrPhysicalVoucher) {
			if (genCardRangeFrom === '' || /[a-zA-Z]/.test(genCardRangeFrom) || genCardRangeTo === '' || /[a-zA-Z]/.test(genCardRangeTo)) {
				this.setState({
					showSnackbar: true,
					snackbarVariant: 'error',
					snackbarMessage: 'Please enter a valid card range',
				})
				return
			}
			let genCardRange = genCardRangeFrom + '-' + genCardRangeTo

			let cardRangeHelper = new CardRangeHelper({
				rangeString: genCardRange,
				prefix: genCardPrefix,
			})
			cardRangeHelper.prepareData()
			let cards = cardRangeHelper.generateRange()
			for (let i = 0; i < updatedPassengers.length; i++) {
				if (cards.length === 0) break
				if (!updatedPassengers[i].isDeleted) updatedPassengers[i].genPhysicalVoucherNo = cards.shift()
			}

			if (!lpcCardRangeFrom) {
				for (let i = 0; i < updatedPassengers.length; i++) {
					if (updatedPassengers[i].ctpLpcValue !== 0) {
						this.setState({
							showSnackbar: true,
							snackbarVariant: 'error',
							snackbarMessage: 'Please enter LPC vouchers',
						})
						return
					}
				}
			}

			if (lpcCardRangeTo !== '') {
				if ((lpcCardRangeTo !== '' && /[a-zA-Z]/.test(lpcCardRangeTo)) || (lpcCardRangeFrom !== '' && /[a-zA-Z]/.test(lpcCardRangeFrom))) {
					this.setState({
						showSnackbar: true,
						snackbarVariant: 'error',
						snackbarMessage: 'Please enter a valid card range',
					})
					return
				}
				let lpcCardRange = lpcCardRangeFrom + '-' + lpcCardRangeTo
				let cardRangeHelper2 = new CardRangeHelper({
					rangeString: lpcCardRange,
					prefix: lpcCardPrefix,
				})
				cardRangeHelper2.prepareData()
				let cards2 = cardRangeHelper2.generateRange()
				for (let i = 0; i < updatedPassengers.length; i++) {
					if (cards2.length === 0) break
					if (!updatedPassengers[i].isDeleted && updatedPassengers[i].ctpLpcValue !== 0) updatedPassengers[i].lpcPhysicalVoucherNo = cards2.shift()
				}
			}
		}
		this.props.sendMessage({
			collectorDetails: this.props.individualFormDuck.collectorDetails,
			formDetailsList: updatedPassengers,
			formState: this.state,
			collectorOptions: null,
		})

		this.props.updateFormDetailsList(updatedPassengers)
	}

	_emptyCardNoAndPhysicalVoucher = (individualFormDuck) => {
		let individualFormDuckClone = { ...individualFormDuck }
		let collectorDetailsClone = { ...individualFormDuckClone.collectorDetails }
		let formDetailsListClone = [].concat(individualFormDuckClone.formDetailsList)

		collectorDetailsClone.cardNo = ''

		formDetailsListClone.forEach((passenger) => {
			passenger.cardNo = ''
			passenger.preloadedVouchersCardNo = ''
			passenger.genPhysicalVoucherNo = ''
			passenger.lpcPhysicalVoucherNo = ''
			passenger.physicalVoucherIssued = false
		})

		individualFormDuckClone.collectorDetails = collectorDetailsClone
		individualFormDuckClone.formDetailsList = formDetailsListClone
		return individualFormDuckClone
	}

	toggleExistingCrPhysicalVoucher = (existingCrPhysicalVoucher) => {
		let intention = !existingCrPhysicalVoucher
		let collectorOptions = selectExistingCrPhysicalVoucher(intention)
		this.props.updateIndividualCollectorOptions(collectorOptions)
	}

	toggleExistingCrPreloadedVouchersCard = (existingCrPreloadedVouchersCard) => {
		let intention = !existingCrPreloadedVouchersCard
		let collectorOptions = selectExistingCrPreloadedVouchersCard(intention)
		this.props.updateIndividualCollectorOptions(collectorOptions)
	}

	onCardAssignmentChange = (e, memberType) => {
		let individualFormDuckClone = this._emptyCardNoAndPhysicalVoucher(this.props.individualFormDuck)
		let collectorOptions

		switch (e.target.value) {
			case 'single':
				let pax = this.props.individualFormDuck.formDetailsList.filter((p) => !p.isDeleted).length
				collectorOptions = selectCtpSingleCard(pax)
				break
			case 'multi':
				collectorOptions = selectCtpMultipleCards()
				break
			case 'physical':
				collectorOptions = selectCtpPhysicalVoucher()
				break
			case 'digital':
				collectorOptions = selectCtpDigitalCard()
				break
			case 'preloadedVouchersCard':
				collectorOptions = selectCtpPreloadedVouchersCard()
				break
			default:
				console.log('invalid card type')
		}
		if (this.props.parentView !== 'clientView') {
			//client view do not update card assignment
			this.props.sendMessage({
				collectorDetails: individualFormDuckClone.collectorDetails,
				formDetailsList: individualFormDuckClone.formDetailsList,
				formState: this.state,
				collectorOptions,
			})
		}
		this.props.updateIndividualCollectorOptions(collectorOptions)
		this.props.updateIndividualFormDuck(individualFormDuckClone)
	}

	onGenCardPrefixChange = (e) => {
		this.setState({ genCardPrefix: e.target.value })
	}

	onlpcCardPrefixChange = (e) => {
		this.setState({ lpcCardPrefix: e.target.value })
	}

	onGenCardRangeFromChange = (e) => {
		let numericValue = e.target.value.replace(/\D/g, '')
		this.setState({ genCardRangeFrom: numericValue })
	}

	onGenCardRangeToChange = (e) => {
		let numericValue = e.target.value.replace(/\D/g, '')
		this.setState({ genCardRangeTo: numericValue })
	}

	onLpcCardRangeFromChange = (e) => {
		let numericValue = e.target.value.replace(/\D/g, '')
		this.setState({ lpcCardRangeFrom: numericValue })
	}

	onLpcCardRangeToChange = (e) => {
		let numericValue = e.target.value.replace(/\D/g, '')
		this.setState({ lpcCardRangeTo: numericValue })
	}

	onOcidLinkClick = (e) => {
		e.preventDefault() //prevent link from redirecting
		e.nativeEvent.stopImmediatePropagation()
		this.props.sendOpenOcidWindow()
	}

	render() {
		const { classes, consentDerivedDuck, individualCollectorOptionDuck } = this.props
		const { collectorDetails, formDetailsList } = this.props.individualFormDuck
		const { ocidExists } = this.state

		const { cardPrefix, cardRangeFrom, cardRangeTo, genCardRangeFrom, genCardRangeTo, lpcCardRangeFrom, lpcCardRangeTo } = this.state
		const pax = formDetailsList.filter((p) => !p.isDeleted).length
		const isSmallGroup = pax > 1
		let ctpCollectionType
		if (individualCollectorOptionDuck.isSingleCtpCard) ctpCollectionType = 'single'
		else if (individualCollectorOptionDuck.isMultipleCtpCard) ctpCollectionType = 'multi'
		else if (individualCollectorOptionDuck.isCtpDigitalCard) ctpCollectionType = 'digital'
		else if (individualCollectorOptionDuck.isCtpPreloadedVouchersCard) ctpCollectionType = 'preloadedVouchersCard'
		else if (individualCollectorOptionDuck.isCtpPhysicalVoucher) ctpCollectionType = 'physical'

		return (
			<div>
				<ConsentDerivedDuckContainer />
				<Grid container spacing={24}>
					<Grid item xs={6}>
						<Typography variant={'body1'}>Are you a CR member?*</Typography>
						<FormControl error={this.state.existingCrError !== ''}>
							<RadioGroup
								value={trueFalseToYesNo(individualCollectorOptionDuck.isExistingCr)}
								onChange={(e) => this.onCollectorPersonalDetailsChange('existingCr', e)}
								className={classes.radioGroup}
							>
								<FormControlLabel value={'yes'} control={<Radio color={'primary'} />} label={'Yes'} />
								<FormControlLabel value={'no'} control={<Radio color={'primary'} />} label={'No'} />
							</RadioGroup>

							<FormHelperText>{this.state.existingCrError}</FormHelperText>
						</FormControl>
					</Grid>
					{individualCollectorOptionDuck.isExistingCr === true && (
						<Grid item xs={6}>
							<Grid container spacing={24} direction={'column'}>
								<Grid item>
									<Typography variant={'body1'}>Verify your CR card or email</Typography>
								</Grid>
								<Grid container item spacing={24} alignItems={'center'}>
									<Grid item>
										<TextField
											variant={'outlined'}
											className={classes.textField}
											value={this.state.cardNo}
											label={'Verify CR Card No'}
											onChange={this.onVerifyCRChange}
										/>
									</Grid>
									<Grid item>
										<Button color={'primary'} variant='contained' onClick={this.onVerifyCRCard}>
											Verify
										</Button>
									</Grid>
								</Grid>
								<Grid container item spacing={24} alignItems={'center'}>
									<Grid item>
										<TextField
											variant={'outlined'}
											className={classes.textField}
											type={'email'}
											value={this.state.email}
											label={'Verify Email'}
											onChange={this.onVerifyEmailChange}
										/>
									</Grid>
									<Grid item>
										<Button color={'primary'} variant='contained' onClick={this.onVerifyEmail}>
											Verify
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					)}

					{individualCollectorOptionDuck.isNewCollector && (
						<Grid item md={6}>
							<Typography variant={'body1'}>Sign up as CR Member?*</Typography>
							<RadioGroup
								value={trueFalseToYesNo(individualCollectorOptionDuck.isRegisterCr)}
								onChange={(e) => this.onCollectorPersonalDetailsChange('registerCr', e)}
								className={classes.radioGroup}
							>
								<FormControlLabel value={'yes'} control={<Radio color={'primary'} disabled={individualCollectorOptionDuck.isExistingCr} />} label={'Yes'} />
								<FormControlLabel value={'no'} control={<Radio color={'primary'} disabled={individualCollectorOptionDuck.isExistingCr} />} label={'No'} />
							</RadioGroup>
						</Grid>
					)}
				</Grid>

				{formDetailsList.length < 2 && (
					<Grid container spacing={24} direction={'column'}>
						<Grid item>
							<Divider />
						</Grid>
					</Grid>
				)}

				<Grid container spacing={24} direction={'column'}>
					<Grid item>
						<div className={classes.row}>
							<div className={classes.rowItem}>
								<div className={classes.label}>First/Given Name*</div>
								<div className={classes.control}>
									<TextField
										variant={'outlined'}
										className={classes.textField}
										value={collectorDetails.name}
										error={this.state.nameError !== ''}
										helperText={this.state.nameError}
										onChange={(e) => this.onCollectorPersonalDetailsChange('name', e)}
										disabled={individualCollectorOptionDuck.isExistingCr}
									/>
								</div>
							</div>

							<div className={classes.rowItem}>
								<div className={classes.label}>Last Name/Surname*</div>
								<div className={classes.control}>
									<TextField
										variant={'outlined'}
										className={classes.textField}
										value={collectorDetails.surname}
										error={this.state.surnameError !== ''}
										helperText={this.state.surnameError}
										onChange={(e) => this.onCollectorPersonalDetailsChange('surname', e)}
										disabled={individualCollectorOptionDuck.isExistingCr}
									/>
								</div>
							</div>
						</div>

						<div className={classes.row}>
							<div className={classes.rowItem}>
								<div className={classes.label}>Country of Residence*</div>
								<div className={classes.control}>
									<StyledSelect
										value={labelToObject(collectorDetails.country)}
										onChange={(v) => this.onCollectorPersonalDetailsChange('country', v)}
										options={Countries}
										error={this.state.countryError !== ''}
										helperText={this.state.countryError}
										disabled={individualCollectorOptionDuck.isExistingCr}
									/>
								</div>
							</div>

							<div className={classes.rowItem}>
								<div className={classes.label}>Date of birth*</div>
								<div className={classes.control}>
									<TextField
										variant={'outlined'}
										className={classes.textField}
										type={'date'}
										value={moment(collectorDetails.dob).format('YYYY-MM-DD')}
										error={this.state.dobError !== ''}
										helperText={this.state.dobError}
										InputProps={{ inputProps: { max: this.state.maxDob } }}
										onChange={(e) => this.onCollectorPersonalDetailsChange('dob', e)}
										disabled={individualCollectorOptionDuck.isExistingCr}
									/>
								</div>
							</div>
						</div>

						<div className={classes.row}>
							<div className={classes.rowItem}>
								<div className={classes.label}>
									Email Address
									{(individualCollectorOptionDuck.isRegisterCr || individualCollectorOptionDuck.isExistingCr || collectorDetails.allowReceivingInfo) && (
										<span>*</span>
									)}
								</div>
								<div className={classes.control}>
									<div className={classes.tfWrap}>
										<TextField
											variant={'outlined'}
											className={classes.textField}
											type={'email'}
											value={collectorDetails.email}
											error={this.state.emailError && !individualCollectorOptionDuck.isExistingCr} //no need to throw error for existingCr
											helperText={this.state.emailHelperText}
											onBlur={(e) => this.onEmailBlur(e.target.value)}
											onChange={(e) => this.onCollectorPersonalDetailsChange('email', e)}
											disabled={individualCollectorOptionDuck.isExistingCr}
										/>
										{/* ocidExists && ( //todo:syd remove after 1 June 2020
                                        <a target={'_blank'} href={'https://logindev.cag.wwprojects.com/oauth/authorize?redirect_uri=https://ctpuat.changiairport.com/ocid-exchange&response_type=code&client_id=Iu5FSmVXhwJ1PwUQOGfPns3Se6q2gpS1M2y5TiWc&state=ctp_app'}>
                                            <Typography>Click here to login as OCID member.</Typography>
                                        </a>)
                                        */}
										{ocidExists && this.props.parentView === 'conciergeView' && this.props.individualCollectorOptionDuck.isRegisterCr && (
											<a href='#' onClick={(e) => this.onOcidLinkClick(e)}>
												<Typography>Click here to login as OCID member.</Typography>
											</a>
										)}
									</div>
								</div>
							</div>
							<div className={classes.rowItem}>
								<Grid container spacing={24} direction={'column'}>
									<Grid container item spacing={24} alignItems={'center'}>
										<Grid item md={5}>
											Country code{individualCollectorOptionDuck.isRegisterCr && <span>*</span>}
										</Grid>
										<Grid item md={7}>
											<StyledSelect
												value={labelToObject(this.state.callingCode)}
												options={CallingCodes}
												onChange={(v) => this.onCollectorPersonalDetailsChange('callingCode', v)}
												error={this.state.callingCodeError !== ''}
												helperText={this.state.callingCodeError}
												disabled={individualCollectorOptionDuck.isExistingCr}
											/>
										</Grid>
									</Grid>

									<Grid container item spacing={24} alignItems={'center'}>
										<Grid item md={5}>
											Area code
										</Grid>
										<Grid item md={7}>
											<TextField
												variant={'outlined'}
												fullWidth
												value={collectorDetails.areaCode}
												onChange={(e) => this.onCollectorPersonalDetailsChange('areaCode', e)}
												disabled={individualCollectorOptionDuck.isExistingCr}
											/>
										</Grid>
									</Grid>

									<Grid container item spacing={24} alignItems={'center'}>
										<Grid item md={5}>
											Mobile number{individualCollectorOptionDuck.isRegisterCr && <span>*</span>}
										</Grid>
										<Grid item md={7}>
											<TextField
												variant={'outlined'}
												fullWidth
												value={collectorDetails.contactNo}
												error={this.state.contactNoError !== ''}
												helperText={this.state.contactNoError}
												onChange={(e) => this.onCollectorPersonalDetailsChange('contactNo', e)}
												disabled={individualCollectorOptionDuck.isExistingCr}
											/>
										</Grid>
									</Grid>
								</Grid>
							</div>
						</div>

						{this.props.parentView === 'conciergeView' && (
							<Fragment>
								{(individualCollectorOptionDuck.isExistingCr || individualCollectorOptionDuck.isSingleCtpCard) && (
									<div className={classes.row}>
										<div className={classes.rowItem}>
											<div className={classes.label}>
												<span>
													Card No{(individualCollectorOptionDuck.isSingleCrCard || individualCollectorOptionDuck.isSingleCtpCard) && <span>*</span>}
												</span>
											</div>

											<div className={classes.control}>
												<TextField
													variant={'outlined'}
													className={classes.textField}
													disabled={
														!individualCollectorOptionDuck.isSingleCrCard &&
														!individualCollectorOptionDuck.isSingleCtpCard &&
														!individualCollectorOptionDuck.isExistingCrPhysicalVoucher &&
														!individualCollectorOptionDuck.isExistingCrPreloadedVouchersCard
													}
													value={collectorDetails.cardNo}
													error={this.state.cardNoError !== ''}
													helperText={this.state.cardNoError}
													onChange={(e) => this.onCollectorPersonalDetailsChange('cardNo', e)}
												/>
												{individualCollectorOptionDuck.isExistingCr && (
													<FormControlLabel
														control={
															<Checkbox
																onChange={() =>
																	this.toggleExistingCrPhysicalVoucher(individualCollectorOptionDuck.isExistingCrPhysicalVoucher)
																}
															/>
														}
														checked={individualCollectorOptionDuck.isExistingCrPhysicalVoucher}
														label={<Typography component={'span'}>Physical Voucher Issuance for Existing CR</Typography>}
													/>
												)}
												{individualCollectorOptionDuck.isExistingCr && (
													<FormControlLabel
														control={
															<Checkbox
																onChange={() =>
																	this.toggleExistingCrPreloadedVouchersCard(individualCollectorOptionDuck.isExistingCrPreloadedVouchersCard)
																}
															/>
														}
														checked={individualCollectorOptionDuck.isExistingCrPreloadedVouchersCard}
														label={<Typography component={'span'}>Preloaded Card Issuance for Existing CR</Typography>}
													/>
												)}
											</div>
										</div>
										<div className={classes.rowItem} />
									</div>
								)}

								<Spacing height={20} />

								{individualCollectorOptionDuck.isCtp && (
									<Grid container item spacing={24} alignItems={'center'}>
										<Grid item>
											<FormLabel>Card assignment</FormLabel>
											<RadioGroup row value={ctpCollectionType} onChange={this.onCardAssignmentChange}>
												{!isSmallGroup && <FormControlLabel value={'single'} control={<Radio color='primary' />} label='Single Card' />}
												{isSmallGroup && <FormControlLabel value={'multi'} control={<Radio color='primary' />} label='CTP Cards' />}
												<FormControlLabel value={'digital'} control={<Radio color='primary' />} label='Digital Card' />
												<FormControlLabel value={'physical'} control={<Radio color='primary' />} label='Physical Voucher' />
												<FormControlLabel value={'preloadedVouchersCard'} control={<Radio color='primary' />} label='Preloaded Card' />
											</RadioGroup>
										</Grid>
									</Grid>
								)}
							</Fragment>
						)}

						{this.props.parentView === 'conciergeView' &&
							(individualCollectorOptionDuck.isMultipleCtpCard ||
								individualCollectorOptionDuck.isCtpPreloadedVouchersCard ||
								individualCollectorOptionDuck.isExistingCrPreloadedVouchersCard) && (
								<Fragment>
									<div className={classes.row}>
										<div className={classes.rowItem}>
											<div className={classes.label}>Card Prefix</div>
											<div className={classes.control}>
												<TextField
													id=''
													label=''
													margin='normal'
													variant='outlined'
													value={cardPrefix}
													className={classes.textField}
													onChange={this.onCardPrefixChange}
												/>
											</div>
										</div>
										<div className={classes.rowItem}>
											<div className={classes.label}>Card Range (Last 6 Digit. E.g. 000001 - 000009)</div>
											<div className={classes.control}>
												<Grid container item spacing={24} alignItems={'center'}>
													<Grid item md={5}>
														<TextField
															id=''
															label='From'
															margin='normal'
															variant='outlined'
															value={cardRangeFrom}
															onChange={this.onCardRangeFromChange}
														/>
													</Grid>
													<Grid item md={5}>
														<TextField
															id=''
															label='To'
															margin='normal'
															variant='outlined'
															value={cardRangeTo}
															onChange={this.onCardRangeToChange}
														/>
													</Grid>
													<Grid item md={2}>
														<Button color={'primary'} variant='contained' onClick={this.onAssign}>
															Assign
														</Button>
													</Grid>
												</Grid>
											</div>
										</div>
									</div>
								</Fragment>
							)}

						{this.props.parentView === 'conciergeView' &&
							(individualCollectorOptionDuck.isCtpPhysicalVoucher || individualCollectorOptionDuck.isExistingCrPhysicalVoucher) && (
								<Fragment>
									<div className={classes.row}>
										<div className={classes.rowItem}>
											<div className={classes.label} style={{ flex: 1 }}>
												GEN Card Range
											</div>
											<div className={classes.control}>
												<Grid container item spacing={24} alignItems={'center'}>
													<Grid item>
														<TextField
															id=''
															label='From'
															margin='normal'
															variant='outlined'
															value={genCardRangeFrom}
															onChange={this.onGenCardRangeFromChange}
														/>
													</Grid>
													<Grid item>
														<TextField
															id=''
															label='To'
															margin='normal'
															variant='outlined'
															value={genCardRangeTo}
															onChange={this.onGenCardRangeToChange}
														/>
													</Grid>
												</Grid>
											</div>
										</div>
									</div>

									<div className={classes.row}>
										<div className={classes.rowItem}>
											<div className={classes.label} style={{ flex: 1 }}>
												LPC Card Range
											</div>
											<div className={classes.control}>
												<Grid container item spacing={24} alignItems={'center'}>
													<Grid item>
														<TextField
															id=''
															label='From'
															margin='normal'
															variant='outlined'
															value={lpcCardRangeFrom}
															onChange={this.onLpcCardRangeFromChange}
														/>
													</Grid>
													<Grid item>
														<TextField
															id=''
															label='To'
															margin='normal'
															variant='outlined'
															value={lpcCardRangeTo}
															onChange={this.onLpcCardRangeToChange}
														/>
													</Grid>
													<Grid item>
														<Button color={'primary'} variant='contained' onClick={this.onAssign}>
															Assign
														</Button>
													</Grid>
												</Grid>
											</div>
										</div>
									</div>
								</Fragment>
							)}

						<div className={classes.row}>
							<div className={classes.rowItem}>
								<div className={classes.consent}>
									<Grid container spacing={16} direction={'column'}>
										<Grid item>
											<Typography component={'span'} variant={'body1'}>
												<div dangerouslySetInnerHTML={{ __html: consentDerivedDuck.airlineConsent }} />
											</Typography>
										</Grid>

										<Grid item>
											<FormControl error={this.state.consentError !== ''} variant={'outlined'}>
												<FormHelperText>{this.state.consentError}</FormHelperText>
												<FormControlLabel
													control={<Checkbox onChange={this.onConsent} />}
													checked={this.state.consent}
													className={classes.flexStart}
													label={
														<Typography component={'span'} variant={'body1'}>
															<div dangerouslySetInnerHTML={{ __html: consentDerivedDuck.marketingConsent }} />
														</Typography>
													}
												/>
											</FormControl>
										</Grid>

										<Grid item>
											<div dangerouslySetInnerHTML={{ __html: consentDerivedDuck.collectionConsent }} />
										</Grid>
									</Grid>
								</div>
							</div>
						</div>
					</Grid>
				</Grid>
				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = (theme) => ({
	row: { display: 'flex', marginTop: 20 },
	rowItem: { display: 'flex', flex: 1, marginRight: 60, alignItems: 'center' },
	label: { flex: '2', marginRight: '1rem' },
	control: { flex: '3' },
	textField: { width: '100%' },
	button: { marginLeft: '1rem' },
	radioGroup: { display: 'flex', flexDirection: 'row' },
	notFullWidth: { width: 'auto' },
	callingCode: { width: '6rem', marginRight: '1rem' },
	country: { width: '100%' },
	flexStart: { alignItems: 'flex-start' },
	flightTransfers: { width: '5rem' },
	consent: { padding: '2rem' },
	tfWrap: { position: 'relative' },
})

const mapStateToProps = function(state) {
	const {
		individualFormDuck,
		redemptionTransactionDuck: { redemptionTransaction },
		auditLogDucks,
		consentDerivedDuck,
		individualCollectorOptionDuck,
	} = state

	return {
		individualFormDuck,
		redemptionTransaction,
		auditLogDucks,
		consentDerivedDuck,
		individualCollectorOptionDuck,
	}
}

const mapDispatchToProps = function(dispatch) {
	return {
		updateCollectorDetails: (details) => dispatch(updateCollectorDetails(details)),
		submitIndividualForm: ({
			form,
			redemptionTransactionId,
			collectionConsent,
			marketingConsent,
			airlineConsent,
			additionalCrConsent,
			registerCr,
			existingCr,
			isPreloadedVouchersCard,
			isDigitalCard,
		}) =>
			dispatch(
				submitIndividualForm({
					form,
					redemptionTransactionId,
					collectionConsent,
					marketingConsent,
					airlineConsent,
					additionalCrConsent,
					registerCr,
					existingCr,
					isPreloadedVouchersCard,
					isDigitalCard,
				})
			),
		memberSearch: (type, v) => dispatch(memberSearch(type, v)),
		clearFormDetailsList: () => dispatch(clearFormDetailsList()),
		uploadFile: (formData, type) => dispatch(uploadFile(formData, type)),
		updateFormDetailsList: (formDetailsList) => dispatch(updateFormDetailsList(formDetailsList)),
		updateIndividualFormDuck: (obj) => dispatch(updateIndividualFormDuck(obj)),
		updateAllConsents: ({ collectionConsent, marketingConsent, airlineConsent, additionalCrConsent }) =>
			dispatch(
				updateAllConsents({
					collectionConsent,
					marketingConsent,
					airlineConsent,
					additionalCrConsent,
				})
			),
		updateIndividualCollectorOptions: (collectorOptions) => dispatch(updateIndividualCollectorOptions(collectorOptions)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(CollectorPersonalDetails))
