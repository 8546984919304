import React, {Component} from 'react'
import {withStyles, Fab} from '@material-ui/core'
import {AddCircle} from '@material-ui/icons'

class StyledTinyErrorCross extends Component {
    render() {
        const {classes} = this.props

        return (
            <Fab className={classes.tinyFab}><AddCircle color={'secondary'} onClick={this.props.onClick}/></Fab>
        )
    }
}

const styles = theme => ({
    tinyFab: {
        transform: 'rotateZ(45deg)',
        width: '1.5rem',
        height: '1.5rem',
        minHeight: 'auto',
        position: 'absolute',
        right: '-2rem',
        top: '1rem',
        boxShadow: 'none',
        backgroundColor: 'transparent'
    }
})

export default withStyles(styles)(StyledTinyErrorCross)