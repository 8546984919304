import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Fab} from '@material-ui/core'
import {Edit} from '@material-ui/icons'


class StyledEditBtn extends PureComponent {
    render() {
        return <Fab onClick={this.props.onClick} color={'primary'}
            variant={'round'} size={'small'} {...this.props}><Edit/></Fab>
    }
}

StyledEditBtn.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default StyledEditBtn
