import axios from 'axios'
import {getSettings} from '../config/config'
import {checkValidStatusCode} from "../helpers/httpHelper";

let settings = getSettings();
let baseURL = settings.baseURL;

export const getGroupAudit = data => async dispatch => {
    dispatch(_getGroupAuditIsLoading())

    const rs = await axios.get(baseURL + '/Group/GetGroupAudit', data).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_getGroupAuditFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_getGroupAuditSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getGroupAuditFail())
        return {success: false, message: rs.data.error.message}
    }
}

const GET_GROUP_AUDIT_IS_LOADING = 'GET_GROUP_AUDIT_IS_LOADING'
const GET_GROUP_AUDIT_SUCCESS = 'GET_GROUP_AUDIT_SUCCESS'
const GET_GROUP_AUDIT_FAIL = 'GET_GROUP_AUDIT_FAIL'

const _getGroupAuditIsLoading = () => ({
    type: GET_GROUP_AUDIT_IS_LOADING
})

const _getGroupAuditSuccess = rs => ({
    type: GET_GROUP_AUDIT_SUCCESS,
    rs
})

const _getGroupAuditFail = () => ({
    type: GET_GROUP_AUDIT_FAIL
})

export const getRedemptionTransactionAudit = data => async dispatch => {
    dispatch(_getRedemptionTransactionAuditIsLoading())

    const rs = await axios.get(baseURL + '/RedemptionTransaction/GetRedemptionTransactionAudit', data).catch(e => {
        if (!checkValidStatusCode(e.response.status )) {
            const {response: {data: {error: {message}}}} = e
            return {data: {success: false, error: {message}}}
        }
        return {data: {success: false, error: {message: e.response.status + ' Unexpected error'}}}
    })
    if (!rs.data) {
        dispatch(_getRedemptionTransactionAuditFail())
        return {success: false, message: 'No data from server'}
    }
    if (rs.data.success) {
        dispatch(_getRedemptionTransactionAuditSuccess(rs))
        return {success: true, message: ''}
    } else {
        dispatch(_getRedemptionTransactionAuditFail())
        return {success: false, message: rs.data.error.message}
    }
}

const GET_REDEMPTION_TRANSACTION_AUDIT_IS_LOADING = 'GET_REDEMPTION_TRANSACTION_AUDIT_IS_LOADING'
const GET_REDEMPTION_TRANSACTION_AUDIT_SUCCESS = 'GET_REDEMPTION_TRANSACTION_AUDIT_SUCCESS'
const GET_REDEMPTION_TRANSACTION_AUDIT_FAIL = 'GET_REDEMPTION_TRANSACTION_AUDIT_FAIL'

const _getRedemptionTransactionAuditIsLoading = () => ({
    type: GET_REDEMPTION_TRANSACTION_AUDIT_IS_LOADING
})

const _getRedemptionTransactionAuditSuccess = rs => ({
    type: GET_REDEMPTION_TRANSACTION_AUDIT_SUCCESS,
    rs
})

const _getRedemptionTransactionAuditFail = () => ({
    type: GET_REDEMPTION_TRANSACTION_AUDIT_FAIL
})

let initState = {
    entityChange: {},
    entityChangePropertyList: [],
    getRedemptionTransactionAuditIsLoading: false,
    redemptionTransactionEntityChange: {},
    redemptionTransactionEntityChangePropertyList: []

}

const auditLogDuck = (state = initState, action) => {
    let entityChangePropertyList, entityChange
    switch (action.type) {

        case GET_GROUP_AUDIT_IS_LOADING:
            return {...state, getGroupAuditIsLoading: true}

        case GET_GROUP_AUDIT_SUCCESS:
            ({entityChangePropertyList, entityChange} = action.rs.data.result)
            return {...state, getGroupAuditIsLoading: false, entityChangePropertyList, entityChange}

        case GET_GROUP_AUDIT_FAIL:
            return {...state, getGroupAuditIsLoading: false}

        case GET_REDEMPTION_TRANSACTION_AUDIT_IS_LOADING:
            return {...state, getRedemptionTransactionAuditIsLoading: true}

        case GET_REDEMPTION_TRANSACTION_AUDIT_SUCCESS:
            ({entityChangePropertyList, entityChange} = action.rs.data.result)
            return {...state, getRedemptionTransactionAuditIsLoading: false, redemptionTransactionEntityChange: entityChange, redemptionTransactionEntityChangePropertyList: entityChangePropertyList}

        case GET_REDEMPTION_TRANSACTION_AUDIT_FAIL:
            return {...state, getRedemptionTransactionAuditIsLoading: false}

        default:
            return state
    }
}

export default auditLogDuck
