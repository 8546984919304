import React from "react"
import {connect} from 'react-redux'
import {Button, Modal, Typography} from '@material-ui/core'
import {Cancel} from "@material-ui/icons";
import {clearErrorMessage} from "../ducks/systemError";

class SystemModal extends React.Component {
    closeModal=()=>{
        this.props.clearErrorMessage()
    };

    render() {
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.isError}
                onClose={this.closeModal}
            >
                <div style={styles.container.styles}>
                    <div style={styles.container.header.styles}>
                        <Cancel color={'primary'} onClick={this.closeModal}/>
                    </div>
                    <div style={styles.container.body.styles}>
                        <Typography>{this.props.message}</Typography>
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.closeModal}
                    >
                        Back
                    </Button>
                </div>
            </Modal>

        )
    }
}


const styles = {
    container:{
        styles: {
            position: 'absolute',
            width: "40%",
            backgroundColor: 'white',
            top: "20%",
            left: "30%",
            paddingBottom: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        header:{
            styles: {display:'flex', justifyContent:'flex-end', width:'100%'}
        },
        body:{
            styles: {padding: 20}
        }
    }
};


const mapStateToProps = function (state) {
    const systemErrorReducer = state.systemErrorReducer;
    return {
        message: systemErrorReducer.message,
        isError: systemErrorReducer.isError
    }
};

const mapDispatchToProps = function (dispatch) {
    return {
        clearErrorMessage: ()=>dispatch(clearErrorMessage()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemModal)