import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withStyles, Grid, TextField, Button} from '@material-ui/core'
import Header from '../containers/Header.js'
import AdminSettingsNav from '../containers/adminSettings/AdminSettingsNav'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import StyledTable from '../components/StyledTable'
import StyledSection from '../components/StyledSection'
import StyledSnackbar from '../components/StyledSnackbar'
import {hasPermissions} from '../helpers/utilities'
import {getAllIata, addIata, updateIata, deleteIata} from '../ducks/iataDuck.js'
import {
    USER_ADMIN_ROLE_READ_PERMISSION, USER_ADMIN_ROLE_CREATE_PERMISSION, USER_ADMIN_ROLE_EDIT_PERMISSION
} from '../constants/permissions'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import StyledGeneralDialog from '../components/StyledGeneralDialog'
import DeleteDialog from '../components/DeleteDialog'

const RIGHT_TABLE_FIELDS = [
    {key: 'seq', label: 'S/N'},
    {key: 'iataCode', label: 'IATA CODE'},
    {key: 'name', label: 'Name'}
]

class AdminIataScreen extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            showDialog: false,
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            iataCodeError: '',
            iataCodeEditError: '',
            iataCode: '',
            nameError: '',
            nameEditError: '',
            name: '',
            showEditDialog: false,
            origEditing: {},
            editing: {},
            showDeleteDialog: false,
            deleting: {}
        }
    }

    componentDidMount = async () => {
        document.title = `${TITLE_PREFIX} Admin settings - IATA`
        const rs = await this.props.getAllIata()
        if (!rs.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
    }

    onEditCancel = () => {
        this.setState({showEditDialog: false})
    }

    onEditSave = async () => {
        if (this._validate(this.state.editing)) {
            const rs = await this.props.updateIata(this.state.editing)
            this.setState({showEditDialog: false})
            if (rs.success) {
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'success',
                    snackbarMessage: 'IATA updated',
                    showEditDialog: false
                })
                this.props.getAllIata()
            } else {
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'error',
                    snackbarMessage: rs.message,
                    showEditDialog: false,
                    showDeleteDialog: false
                })
            }
        }
    }

    onSubmit = async () => {
        if (this._validate({iataCode: this.state.iataCode})) {
            const rs = await this.props.addIata({iataCode: this.state.iataCode.toUpperCase(), name: this.state.name})
            if (rs.success) {
                this.setState({showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'IATA created'})
                this.props.getAllIata()
            } else {
                this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
            }
        }
    }

    _validate(obj) {
        const {showEditDialog} = this.state
        if (obj.iataCode.length !== 3) {
            !showEditDialog
                ? this.setState({iataCodeError: 'IATA code must be 3 letters'})
                : this.setState({iataCodeEditError: 'IATA code must be 3 letters'})
            return false
        } else {
            this.setState({iataCodeError: '', iataCodeEditError: ''})
        }

        return true
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    onEdit = row => {
        this.setState({showEditDialog: true, editing: row, origEditing: row})
    }

    onEditChange = (k, v) => {
        this.setState({editing: {...this.state.editing, [k]: v}})
    }

    onDelete = row => {
        this.setState({showDeleteDialog: true, deleting: row})
    }

    onDeleteCancel = () => {
        this.setState({showDeleteDialog: false})
    }

    onDeleteConfirm = async () => {
        const rs = await this.props.deleteIata(this.state.deleting.id)

        if (rs.success) {
            this.props.getAllIata()
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'success',
                snackbarMessage: `IATA ${this.state.deleting.iataCode} deleted`,
                showDeleteDialog: false
            })
        } else {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: rs.message,
                showDeleteDialog: false
            })
        }
    }

    updateIataCode = iataCode => {
        this.setState({iataCode})
    }

    updateName = name => {
        this.setState({name})
    }

    render() {
        const {classes, iataDuck} = this.props
        const {showEditDialog, showDeleteDialog, editing, deleting, origEditing, iataCodeEditError, nameEditError} = this.state

        const hasCreatePermission = hasPermissions([USER_ADMIN_ROLE_CREATE_PERMISSION])
        const hasEditPermission = hasPermissions([USER_ADMIN_ROLE_EDIT_PERMISSION])
        const hasReadPermission = hasPermissions([USER_ADMIN_ROLE_READ_PERMISSION]) || hasEditPermission || hasCreatePermission

        return (
            <div>
                <GlobalTimeChecker/>
                <Header history={this.props.history}/>
                <AdminSettingsNav/>

                <div className={classes.container}>
                    <DeleteDialog dialogTitle={'Deleting ' + deleting.iataCode} showDeleteDialog={showDeleteDialog}
                        onDeleteCancel={this.onDeleteCancel} onDeleteConfirm={this.onDeleteConfirm}>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item>
                                The following IATA Code will be removed:
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>IATA code:</Grid>
                                <Grid item>{deleting.iataCode}</Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>Name:</Grid>
                                <Grid item>{deleting.name}</Grid>
                            </Grid>
                        </Grid>
                    </DeleteDialog>

                    <StyledGeneralDialog title={'Editing ' + origEditing.iataCode} showDialog={showEditDialog}
                        onClose={this.onEditCancel}
                        onAction={this.onEditSave} actionText={'Save'}>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>IATA CODE</Grid>
                                <Grid item>
                                    <TextField id="" label="" margin="normal" variant="outlined"
                                        value={editing.iataCode}
                                        error={!!iataCodeEditError}
                                        helperText={iataCodeEditError}
                                        onChange={e => this.onEditChange('iataCode', e.target.value)}/>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>Name</Grid>
                                <Grid item>
                                    <TextField id="" label="" margin="normal" variant="outlined" value={editing.name}
                                        error={!!nameEditError}
                                        helperText={nameEditError}
                                        onChange={e => this.onEditChange('name', e.target.value)}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledGeneralDialog>

                    {hasReadPermission && <Grid container spacing={24} direction={'column'}>
                        <Grid item>
                            <StyledSection title={'Iata List'} isLoading={iataDuck.getAllIataIsLoading}>
                                {iataDuck.iatas.length > 0 && <StyledTable
                                    fields={RIGHT_TABLE_FIELDS}
                                    rows={iataDuck.iatas}
                                    isEditable={hasEditPermission}
                                    onEdit={this.onEdit}
                                />}
                            </StyledSection>
                        </Grid>

                        {hasCreatePermission && <Grid item>
                            <StyledSection title={'Add IATA'}>
                                <Grid container spacing={24} direction={'column'}>
                                    <Grid item md={6}>
                                        <TextField
                                            id="iataCode"
                                            label="Enter Iata Code*"
                                            margin="normal"
                                            helperText={this.state.iataCodeError}
                                            error={this.state.iataCodeError !== ''}
                                            variant="outlined"
                                            placeholder={'Iata Code'}
                                            fullWidth
                                            value={this.state.iataCode}
                                            onChange={e => this.updateIataCode(e.target.value)}/>
                                    </Grid>

                                    <Grid item md={6}>
                                        <TextField
                                            id="name"
                                            label="Enter Name"
                                            margin="normal"
                                            helperText={this.state.nameError}
                                            error={this.state.nameError !== ''}
                                            variant="outlined"
                                            placeholder={'Iata Name'}
                                            fullWidth
                                            value={this.state.name}
                                            onChange={e => this.updateName(e.target.value)}/>
                                    </Grid>

                                    <Grid item>
                                        <Button color={'primary'} variant="contained"
                                            onClick={this.onSubmit}>Submit</Button>
                                    </Grid>
                                </Grid>
                            </StyledSection>
                        </Grid>}
                    </Grid>}
                </div>
                <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

const mapStateToProps = state => {
    const {iataDuck} = state
    return {iataDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        getAllIata: () => dispatch(getAllIata()),
        addIata: iata => dispatch(addIata(iata)),
        updateIata: iata => dispatch(updateIata(iata)),
        deleteIata: id => dispatch(deleteIata(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminIataScreen))