import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import StyledTable from '../../components/StyledTable'
import {
    toHumanFriendlyDate, toHumanFriendlyDateTime, hasPermissions, hasIssuerPermissions,
    hasApproverPermissions, hasGroupViewPermission
} from '../../helpers/utilities'
import {getGroupLabel} from '../../helpers/groupStatusHelper.js'
import {
    GROUP_TYPE_PENDING_APPROVAL, GROUP_TYPE_ACTION_REQUIRED, GROUP_TYPE_PENDING_COLLECTION

} from '../../constants/groupType'
import {
    GROUP_ISSUER_PERMISSION, GROUP_ISSUER_READ_ONLY_PERMISSION, GROUP_CREATOR_PERMISSION,
    GROUP_CREATOR_WITH_READ_ALL_PERMISSION, GROUP_APPROVER_PERMISSION, GROUP_APPROVER_READ_ONLY_PERMISSION,
    GROUP_VIEW_PERMISSION
} from '../../constants/permissions'
import moment from 'moment'
import StyledPagination from '../../components/StyledPagination'
import PageHelper from "../../helpers/pageHelper";

const SORT_BY_REF = 'SORT_BY_REF';
const SORT_BY_PAX = 'SORT_BY_PAX';
const SORT_BY_FLT_DEP_DATE = 'SORT_BY_FLT_DEP_DATE';
const SORT_BY_COLLECTION_DATE = 'SORT_BY_COLLECTION_DATE';
const SORT_BY_TOUR_COMPANY = 'SORT_BY_TOUR_COMPANY';
const SORT_BY_SUBMITTER = 'SORT_BY_SUBMITTER';
const SORT_BY_APPROVER = 'SORT_BY_APPROVER';
const SORT_BY_CREATION_TIME = 'SORT_BY_CREATION_TIME';
const SORT_BY_TOUR_LEADER = 'SORT_BY_TOUR_LEADER';

class GroupSubmissionForm extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            groupTableFields: [],
            sortBy: SORT_BY_COLLECTION_DATE
        }



    }

    componentDidMount() {
        if (hasApproverPermissions() || hasGroupViewPermission() || hasIssuerPermissions()) {
            this.setState({
                groupTableFields: [
                    {label: 'Group ref. no.', key: 'appCustomLink', type: 'appCustomLink'},
                    {label: 'No. of Pax', key: 'paxCount'},
                    {label: 'Return Flt Departure Date', key: 'lastFlightDepartureDate'},
                    {label: 'Collection date', key: 'collectionDate'},
                    {label: 'Company', key: 'tourCompany'},
                    {label: 'Tour Leader', key: 'tourLeaderName'},
                    {label: 'Submitted by', key: 'submitterName'},
                    {label: 'Approved by', key: 'approverName'},
                    {label: 'Submitted date', key: 'creationTime'},
                    {label: 'Status', key: 'status'}
                ]
            })
        } else {
            this.setState({
                groupTableFields: [
                    {label: 'Group ref. no.', key: 'appCustomLink', type: 'appCustomLink'},
                    {label: 'No. of Pax', key: 'paxCount'},
                    {label: 'Return Flt Departure Date', key: 'lastFlightDepartureDate'},
                    {label: 'Collection date', key: 'collectionDate'},
                    {label: 'Company', key: 'tourCompany'},
                    {label: 'Tour Leader', key: 'tourLeaderName'},
                    {label: 'Submitted by', key: 'submitterName'},
                    {label: 'Approved by', key: 'approverName'},
                    {label: 'Submitted date', key: 'creationTime'}
                ]
            })
        }
    }

    _buildAppCustomLink = (refNo, groupType) => {
        if (hasIssuerPermissions() && groupType === GROUP_TYPE_PENDING_COLLECTION) {
            return <Link to={`/group/group-issuance/${refNo}`}>{refNo}</Link>
        }
        return <Link to={`/group/group-detail/${refNo}`}>{refNo}</Link>
    }

    _formatGroupList = (groupList, groupType, sortBy) => {
        if(sortBy === SORT_BY_COLLECTION_DATE) groupList.sort((a, b) => moment(a.collectionDate).diff(moment(b.collectionDate)))
        if(sortBy === SORT_BY_APPROVER) groupList.sort((a, b) => a.approverName.localeCompare(b.approverName));
        if(sortBy === SORT_BY_SUBMITTER) groupList.sort((a, b) => a.submitterName.localeCompare(b.submitterName));
        if(sortBy === SORT_BY_TOUR_COMPANY) groupList.sort((a, b) => a.tourCompany.localeCompare(b.tourCompany));
        if(sortBy === SORT_BY_REF) groupList.sort((a, b) => a.refNo.localeCompare(b.refNo));
        if(sortBy === SORT_BY_PAX) groupList.sort((a, b) => a.paxCount - b.paxCount);
        if(sortBy === SORT_BY_TOUR_LEADER) {
            groupList.sort((a, b) =>{
                let aName = (a.tourLeader && a.tourLeader.length > 0 && a.tourLeader[0].firstName) ? a.tourLeader[0].firstName : "a";
                let bName = (a.tourLeader && a.tourLeader.length > 0 && b.tourLeader[0].firstName) ? b.tourLeader[0].firstName : "a";
                return aName.localeCompare(bName);
            })
        }
        if(sortBy === SORT_BY_CREATION_TIME) groupList.sort((a, b) => moment(b.creationTime).diff(moment(a.creationTime)));
        if(sortBy === SORT_BY_FLT_DEP_DATE) groupList.sort((a, b) => moment(b.lastFlightDepartureDate).diff(moment(a.lastFlightDepartureDate)));


        return groupList.map(s => {
            return {
                ...s,
                creationTime: toHumanFriendlyDateTime(s.creationTime),
                firstFltArrivalDate: toHumanFriendlyDate(s.firstFltArrivalDate),
                lastFlightDepartureDate: toHumanFriendlyDate(s.lastFlightDepartureDate),
                collectionDate: toHumanFriendlyDate(s.collectionDate),
                status: getGroupLabel(s),
                appCustomLink: this._buildAppCustomLink(s.refNo, groupType),
                tourLeaderName: (s.tourLeader && s.tourLeader.length > 0) ? s.tourLeader[0].firstName + ' ' + s.tourLeader[0].lastName : '',
                approverName: (s.approverName !== s.submitterName) ? s.approverName : "",
            }
        })
    }

    _checkEditablePermission = groupType => {
        if (groupType === GROUP_TYPE_ACTION_REQUIRED) return (hasPermissions([GROUP_CREATOR_PERMISSION]))
        if (groupType === GROUP_TYPE_PENDING_APPROVAL) return hasPermissions([GROUP_APPROVER_PERMISSION])

        return false
    }

    _checkDeletePermission = groupType => {
        const hasEditPermissions = hasPermissions([GROUP_CREATOR_PERMISSION, GROUP_APPROVER_PERMISSION, GROUP_ISSUER_PERMISSION], 'or')
        if (groupType === GROUP_TYPE_ACTION_REQUIRED) {
            return hasEditPermissions
        }
        return false
    }

    sortTable = (key) => {
        let sortBy = SORT_BY_COLLECTION_DATE;
        if(key === "appCustomLink") sortBy = SORT_BY_REF;
        if(key === "paxCount") sortBy = SORT_BY_PAX;
        if(key === "lastFlightDepartureDate") sortBy = SORT_BY_FLT_DEP_DATE;
        if(key === "collectionDate") sortBy = SORT_BY_COLLECTION_DATE;
        if(key === "tourCompany") sortBy = SORT_BY_TOUR_COMPANY;
        if(key === "submitterName") sortBy = SORT_BY_SUBMITTER;
        if(key === "approverName") sortBy = SORT_BY_APPROVER;
        if(key === "creationTime") sortBy = SORT_BY_CREATION_TIME;
        if(key === "tourLeaderName") sortBy = SORT_BY_TOUR_LEADER;

        this.setState({sortBy: sortBy})
    }

    render() {
        const {groupListing, onDelete, groupType} = this.props;
        const {groupTableFields} = this.state;

        const groupPermissions = hasPermissions([
            GROUP_ISSUER_PERMISSION, GROUP_ISSUER_READ_ONLY_PERMISSION, GROUP_CREATOR_PERMISSION, GROUP_CREATOR_WITH_READ_ALL_PERMISSION,
            GROUP_APPROVER_PERMISSION, GROUP_APPROVER_READ_ONLY_PERMISSION, GROUP_VIEW_PERMISSION
        ], 'or');

        return (
            <div>
                {groupPermissions && <StyledTable
                    fields={groupTableFields}
                    rows={this._formatGroupList(groupListing, groupType, this.state.sortBy)}
                    isDeletable={this._checkDeletePermission(groupType)}
                    onDelete={onDelete}
                    sortable={true}
                    onHeaderClick={this.sortTable}
                    // isEditable={this._checkEditablePermission(groupType)}
                    // onEdit={onEdit}
                    hasPagination
                    onChangePage={this.props.onChangePage}
                    rowsPerPage={this.props.rowsPerPage}
                    page={this.props.page}
                    count={this.props.count}
                    ActionsComponent={StyledPagination}
                />}
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    const stateClone = {...state}
    const individualFormDuck = stateClone.individualFormDuck
    return {
        formDetailsList: individualFormDuck.formDetailsList
    }
}

const mapDispatchToProps = function (dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupSubmissionForm)