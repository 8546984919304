import moment from "moment";

class PassportParser {
    constructor(passportString) {
        this.passportString = passportString
    }

    getData = () => {
        let passportArr = this.passportString.split('/');
        let firstName = passportArr[0];
        let lastName = passportArr[1];
        let dobMoment = moment(passportArr[3], 'DD-MM-YYYY')
        let today = moment();
        if(dobMoment.isAfter(today)) dobMoment.subtract(100, 'years');
        let dob = dobMoment.format('YYYY-MM-DD');
        let nationality = passportArr[4];
        //Check if last name contains 3 digits
        let regex = /(?:\D*\d){3}/g
        if(lastName.match(regex)){
            lastName = ''
        }
        if(firstName.match(regex)){
            firstName = ''
        }
        return {
            firstName,
            lastName,
            dob,
            nationality
        };
    };

}

export default PassportParser