import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper, TextField, Tooltip } from '@material-ui/core'
import { lime } from '@material-ui/core/colors'
import StyledDeleteBtn from './StyledDeleteBtn'
import StyledUndoBtn from './StyledUndoBtn'
import SqApiCriteriasDialog from '../components/SqApiCriteriasDialog'
import { updateFormDetailsList } from '../ducks/individualFormDuck'
import { toHumanFriendlyDate } from '../helpers/utilities'
import { convertSqStatusForDisplay } from '../helpers/eticketStatusHelper'
import { saveCard } from '../ducks/printCardDuck'

class PassengersVoucherTable extends React.Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			criterias: {},
			criteriasMessage: '',
			viewCriterias: false,
			editingIdx: null,
			physicalEditingIdx: {},
		}
	}

	onViewCritierias = (r) => {
		if (r.ticketDetails && r.ticketDetails[0].criterias) {
			this.setState({
				viewCriterias: true,
				criterias: r.ticketDetails[0].criterias,
				criteriasMessage: '',
			})
			return
		}
		if (r.ticketDetails && r.ticketDetails[0].remarks) {
			this.setState({
				viewCriterias: true,
				criterias: {},
				criteriasMessage: r.ticketDetails[0].remarks,
			})
		} else {
			this.setState({
				viewCriterias: true,
				criterias: {},
				criteriasMessage: 'No results',
			})
		}
	}

	onViewCriteriasClose = () => {
		this.setState({ viewCriterias: false })
	}

	onCardNoKeyPress = (e) => {
		if (e.key === 'Enter') this.setState({ editingIdx: null, physicalEditingIdx: {} })
	}

	onCardNoChange = (idx, e) => {
		let value = e.target.value
		if (value.length > 19) {
			value = value.substring(0, 19)
		}
		const newPax = { ...this.props.passengers[idx] }
		newPax.cardNo = value
		let newFormDetailsList = [...this.props.passengers]
		newFormDetailsList.splice(idx, 1, newPax)
		this.props.updateFormDetailsList(newFormDetailsList)
	}

	onPreloadedVouchersCardNoChange = (idx, e) => {
		let value = e.target.value
		if (value.length > 19) {
			value = value.substring(0, 19)
		}
		const newPax = { ...this.props.passengers[idx] }
		newPax.preloadedVouchersCardNo = value
		let newFormDetailsList = [...this.props.passengers]
		newFormDetailsList.splice(idx, 1, newPax)
		this.props.updateFormDetailsList(newFormDetailsList)
	}

	onCardEdit = (i, row) => {
		this.setState({ editingIdx: i })
	}

	onPhysicalCardNoChange = (idx, k, e) => {
		const newPax = { ...this.props.passengers[idx] }
		newPax[k] = e.target.value
		let newFormDetailsList = [...this.props.passengers]
		newFormDetailsList.splice(idx, 1, newPax)
		this.props.updateFormDetailsList(newFormDetailsList)
	}

	onPhysicalCardEdit = (row, col) => {
		this.setState({ physicalEditingIdx: { row, col } })
	}

	onCardNoBlur = (idx) => {
		if (this.state.editingIdx === idx) this.setState({ editingIdx: null })
	}

	onPreloadedVouchersCardNoBlur = (idx) => {
		if (this.state.editingIdx === idx) this.setState({ editingIdx: null })
	}

	onPhysicalCardNoBlur = (row, col) => {
		const { physicalEditingIdx } = this.state
		if (physicalEditingIdx.row === row && physicalEditingIdx.col === col) this.setState({ physicalEditingIdx: [] })
	}

	_shouldShowCardNo = (passengers) => {
		return passengers.some((m) => m.cardNo)
	}

	_shouldShowPreloadedVouchersCardNo = (passengers) => {
		let shouldShow = false
		passengers.forEach((passenger) => {
			if (passenger.preloadedVouchersCardNo) shouldShow = true
		})
		return shouldShow
	}

	_shouldShowPhysicalVoucherNo = (passengers) => {
		let shouldShow = false
		passengers.forEach((passenger) => {
			if (passenger.genPhysicalVoucherNo || passenger.lpcPhysicalVoucherNo) shouldShow = true
		})
		return shouldShow
	}

	_shouldShowRedemptionDate = (passengers) => {
		let shouldShow = false
		passengers.forEach((passenger) => {
			if (passenger.redeemed) {
				if (passenger.redeemed.creationTime) shouldShow = true
			}
		})
		return shouldShow
	}

	printCard = (cardNo) => {
		console.log(this.props.redemptionTransactionId)
		window.open('print-card?state=ctp_app' + '&id=' + this.props.redemptionTransactionId + '&cardNo=' + cardNo)
	}

	render() {
		const { classes, currentPaxPosition, editMode, passengers, isShowDeletedPax } = this.props
		const { criterias, viewCriterias, criteriasMessage, editingIdx, physicalEditingIdx } = this.state
		let showCardNo = this._shouldShowCardNo(passengers)
		let showPreloadedVouchersCardNo = this._shouldShowPreloadedVouchersCardNo(passengers)
		let showPhysicalVoucherNo = this._shouldShowPhysicalVoucherNo(passengers)
		let showRedemptionDate = this._shouldShowRedemptionDate(passengers)

		return (
			<Paper className={classes.root}>
				<SqApiCriteriasDialog
					onViewCriteriasClose={this.onViewCriteriasClose}
					criterias={criterias}
					criteriasMessage={criteriasMessage}
					viewCriterias={viewCriterias}
				/>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>No</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>E-ticket</TableCell>
							<TableCell>Voucher Value</TableCell>
							<TableCell>CTP Eligibility</TableCell>
							<TableCell>Verified ET</TableCell>
							{showRedemptionDate && <TableCell>Redemption Date</TableCell>}
							{showCardNo && <TableCell>Card no</TableCell>}
							{showPreloadedVouchersCardNo && <TableCell>Preloaded Card no</TableCell>}
							{showPhysicalVoucherNo && <TableCell>GEN Voucher no</TableCell>}
							{showPhysicalVoucherNo && <TableCell>LPC Voucher no</TableCell>}
							{this.props.onDelete && <TableCell>Delete/Undo</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{passengers.filter(item => {
							if (this.props.isShowDeletedPax) {
								return true
							}
							return !item.isDeleted
						}).map((passenger, index) => {
							const eTicket = []
							let classNames = []

							let cardsToPrint = []

							if (passenger.fullCardNo && passenger.fullCardNo.startsWith('00002') && !passenger.preloadedVouchersCardNo) {
								// CR Card
								cardsToPrint.push({
									cardNo: passenger.cardNo,
									cvc: passenger.cvc,
									issuedVouchersList: [passenger.crVoucherInfo],
									noOfPax: passengers.length,
									isQantasFlight: passenger.eTicketsDetails.eTicket1.startsWith('081'),
								})
							} else if (passenger.preloadedVouchersCardNo) {
								cardsToPrint.push({
									cardNo: passenger.preloadedVouchersCardNo,
									voucherValidFrom: passenger.voucherValidFrom,
									voucherValidTo: passenger.voucherValidTo,
									voucherValuesAndRemarks: passenger.voucherValuesAndRemarks,
								})
							} else if (passenger.cardNo && passenger.ccn) {
								cardsToPrint.push({
									cardNo: passenger.cardNo,
									ccn: passenger.ccn,
									issuedVouchersList: passenger.issuedVouchersList,
								})
							}

							const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
							keys.forEach((k) => {
								if (passenger.eTicketsDetails[k] !== '' && passenger.eTicketsDetails[k] !== undefined) eTicket.push(passenger.eTicketsDetails[k])
							})
							if (passenger.isDeleted) classNames.push(classes.deleted)
							if (passenger.pax === currentPaxPosition) classNames.push(classes.editing)
							return (
								<TableRow key={index} className={classNames.join(' ')}>
									<TableCell component='th' scope='row'>
										<p className={passenger.isDeleted ? classes['textDeleted'] : null}>{passenger.pax}</p>
									</TableCell>
									<TableCell>
										<p className={passenger.isDeleted ? classes['textDeleted'] : null}>
											<span onClick={() => this.props.onEditPax(passenger)} className={passenger.isDeleted ? '' : classes.btn}>
												{passenger.personalDetails.firstName}
											</span>
										</p>
									</TableCell>
									<TableCell>
										{Object.keys(passenger.eTicketsDetails).length > 0 && (
											<ol className={passenger.isDeleted ? classes['textDeleted'] : null}>
												{eTicket.map((e, i) => (
													<li key={i}>{e}</li>
												))}
											</ol>
										)}
									</TableCell>
									<TableCell>
										<p className={passenger.isDeleted ? classes['textDeleted'] : null}>
											${passenger.ctpGenValue ? passenger.ctpGenValue : 0} GEN | ${passenger.ctpLpcValue ? passenger.ctpLpcValue : 0} LPC
										</p>
									</TableCell>
									<TableCell>
										<p className={passenger.isDeleted ? classes['textDeleted'] : null}>
											{passenger.ticketDetails.length > 0 ? (
												passenger.ticketDetails[0] ? (
													<span className={classes.btn} onClick={() => this.onViewCritierias(passenger)}>
														{convertSqStatusForDisplay(passenger.ticketDetails[0].ctpEligibility)}
													</span>
												) : null
											) : null}
										</p>
									</TableCell>
									<TableCell>
										<p className={passenger.isDeleted ? classes['textDeleted'] : null}>
											{passenger.ticketDetails.length > 0 ? (passenger.ticketDetails[0] ? passenger.ticketDetails[0].etVerified : null) : null}
										</p>
									</TableCell>

									{showRedemptionDate && (
										<TableCell>
											<p className={passenger.isDeleted ? classes['textDeleted'] : null}>
												{passenger.redeemed ? toHumanFriendlyDate(passenger.redeemed.creationTime) : null}
											</p>
										</TableCell>
									)}

									{showCardNo && this.props.collectorSuccess && (
										<TableCell>
											<Tooltip title='Print'>
												<span className={classes.clickable} onClick={() => this.printCard(passenger.cardNo)}>
													{passenger.cardNo}
												</span>
											</Tooltip>
										</TableCell>
									)}

									{showCardNo && !this.props.collectorSuccess && (
										<TableCell>
											{editingIdx !== index && (
												<span
													onClick={() => (passenger.isDeleted ? null : this.onCardEdit(index, passenger))}
													className={passenger.isDeleted ? classes['textDeleted'] : null}
												>
													{passenger.cardNo}
												</span>
											)}
											{editingIdx === index && (
												<TextField
													autoFocus
													InputProps={{ className: classes.textFieldInput }}
													onKeyPress={this.onCardNoKeyPress}
													value={passenger.cardNo}
													onChange={(e) => this.onCardNoChange(index, e)}
													onBlur={() => this.onCardNoBlur(index)}
													type='number'
												/>
											)}
										</TableCell>
									)}

									{showPreloadedVouchersCardNo && this.props.collectorSuccess && (
										<TableCell>
											<Tooltip title='Print'>
												<span className={classes.clickable} onClick={() => this.printCard(passenger.preloadedVouchersCardNo)}>
													{passenger.preloadedVouchersCardNo}
												</span>
											</Tooltip>
										</TableCell>
									)}

									{showPreloadedVouchersCardNo && !this.props.collectorSuccess && (
										<TableCell>
											{editingIdx !== index && (
												<span
													onClick={() => (passenger.isDeleted ? null : this.onCardEdit(index, passenger))}
													className={passenger.isDeleted ? classes['textDeleted'] : null}
												>
													{passenger.preloadedVouchersCardNo}
												</span>
											)}
											{editingIdx === index && (
												<TextField
													autoFocus
													InputProps={{ className: classes.textFieldInput }}
													onKeyPress={this.onCardNoKeyPress}
													value={passenger.preloadedVouchersCardNo}
													onChange={(e) => this.onPreloadedVouchersCardNoChange(index, e)}
													onBlur={() => this.onPreloadedVouchersCardNoBlur(index)}
													type='number'
												/>
											)}
										</TableCell>
									)}

									{showPhysicalVoucherNo && (
										<TableCell>
											{(physicalEditingIdx.row !== index || physicalEditingIdx.col !== 0) && (
												<span
													onClick={() => (passenger.isDeleted ? null : this.onPhysicalCardEdit(index, 0))}
													className={passenger.isDeleted ? classes['textDeleted'] : null}
												>
													{passenger.genPhysicalVoucherNo}
												</span>
											)}
											{physicalEditingIdx.row === index && physicalEditingIdx.col === 0 && (
												<TextField
													autoFocus
													InputProps={{ className: classes.textFieldInput }}
													onKeyPress={this.onCardNoKeyPress}
													value={passenger.genPhysicalVoucherNo}
													onChange={(e) => this.onPhysicalCardNoChange(index, 'genPhysicalVoucherNo', e)}
													onBlur={() => this.onPhysicalCardNoBlur(index, 0)}
												/>
											)}
										</TableCell>
									)}

									{showPhysicalVoucherNo && (
										<TableCell>
											{(physicalEditingIdx.row !== index || physicalEditingIdx.col !== 1) && (
												<span
													onClick={() => (passenger.isDeleted ? null : this.onPhysicalCardEdit(index, 1))}
													className={passenger.isDeleted ? classes['textDeleted'] : null}
												>
													{passenger.lpcPhysicalVoucherNo}
												</span>
											)}
											{physicalEditingIdx.row === index && physicalEditingIdx.col === 1 && (
												<TextField
													autoFocus
													InputProps={{ className: classes.textFieldInput }}
													onKeyPress={this.onCardNoKeyPress}
													value={passenger.lpcPhysicalVoucherNo}
													onChange={(e) => this.onPhysicalCardNoChange(index, 'lpcPhysicalVoucherNo', e)}
													onBlur={() => this.onPhysicalCardNoBlur(index, 1)}
												/>
											)}
										</TableCell>
									)}

									{this.props.onDelete && (
										<TableCell>
											{!passenger.isDeleted && <StyledDeleteBtn onClick={() => this.props.onDelete(passenger.pax)} disabled={editMode} />}

											{passenger.isDeleted && <StyledUndoBtn onClick={() => this.props.onRestore(passenger.pax)} />}
										</TableCell>
									)}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

const styles = (theme) => ({
	root: { width: '100%', marginTop: theme.spacing.unit * 3, overflowX: 'auto' },
	table: { minWidth: 700 },
	deleted: { background: '#eee' },
	textDeleted: { color: '#bbb', textDecoration: 'line-through' },
	btn: { border: 0, cursor: 'pointer', borderBottom: '1px dotted black' },
	editing: { backgroundColor: lime[50] },
	textFieldInput: { fontSize: '0.8125rem' },
	clickable: { cursor: 'pointer', borderBottom: '1px dotted #ccc' },
})

PassengersVoucherTable.propTypes = {
	classes: PropTypes.object.isRequired,
	passengers: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
	const {
		individualFormDuck: { currentPaxPosition, editMode, isShowDeletedPax, redemptionTransactionId },
		printCardDuck,

	} = state

	return { currentPaxPosition, editMode, printCardDuck, isShowDeletedPax, redemptionTransactionId }
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateFormDetailsList: (formDetailsList) => dispatch(updateFormDetailsList(formDetailsList)),
		saveCard: (cards) => dispatch(saveCard(cards)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(PassengersVoucherTable))
