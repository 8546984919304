import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Paper, Table, TableHead, TableBody, TableRow, TableCell, Button, Grid } from '@material-ui/core'
import StyledSection from '../components/StyledSection'
import StyledSnackbar from '../components/StyledSnackbar'
import { getGroupAudit, getRedemptionTransactionAudit } from '../ducks/auditLogDuck'
import { getCurrentAuthHeader, toHumanFriendlyDateTime, toHumanFriendlyMessage, objHasData } from '../helpers/utilities'
import { getReprintAuditLog } from '../ducks/reprintAuditLogDuck'

class AuditLogTable2 extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			showSnackbar: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
			dates: [],
			newAuditDatas: {},
		}
	}

	componentDidMount = async () => {}

	componentDidUpdate = async (prevProps, prevState) => {}

	_retriveAuditLogs = async () => {
		if (this.props.groupApprovalDuck.group && this.props.groupApprovalDuck.group.id) {
			const data = { params: { groupId: this.props.groupApprovalDuck.group.id }, ...getCurrentAuthHeader() }
			const rs = await this.props.getGroupAudit(data)

			if (rs.success) {
				const { auditLogDuck: { entityChangePropertyList } } = this.props
				this._filterAuditData(entityChangePropertyList)
			}
		}

		if (this.props.redemptionTransactionPassengersDuck.redemptionTransaction && this.props.redemptionTransactionPassengersDuck.redemptionTransaction.id) {
			var rid = this.props.redemptionTransactionPassengersDuck.redemptionTransaction.id
			const data = { params: { redemptionTransactionId: rid }, ...getCurrentAuthHeader() }
			const rs = await this.props.getRedemptionTransactionAudit(data)

			if (rs.success) {
				const { auditLogDuck: { redemptionTransactionEntityChangePropertyList } } = this.props
				this._filterAuditData(redemptionTransactionEntityChangePropertyList)
			}
		}
		if (this.props.redemptionTransactionId) await this.props.getReprintAuditLog(this.props.redemptionTransactionId)
	}

	_filterAuditData = (data) => {
		const tempDates = []
		const tempData = {}
		let lastDate = ''
		data.forEach((d) => {
			if (d.originalValue === null && d.propertyName !== 'SubmittedBy' && d.propertyName !== 'RefNo' && d.propertyName !== 'CreatorId' && d.propertyName !== 'RedeemerId') return

			if (d.originalValue === null && (d.newValue === null || d.newValue === '')) return

			// sample string: 2019-04-15T15:13:47
			if (d.changeTime.substr(0, 19) !== lastDate) {
				lastDate = d.changeTime.substr(0, 19)
				tempDates.push(lastDate)
			}
			if (lastDate) {
				if (tempData.hasOwnProperty(lastDate)) {
					if (d.propertyName === 'Remarks') {
						if (!(d.originalValue === null && d.newValue === null) && !(d.originalValue === null && d.newValue === '""')) {
							tempData[lastDate].remarks.push(d)
						}
					} else {
						tempData[lastDate].changes.push(d)
					}
				} else {
					tempData[lastDate] = { changes: [], remarks: [] }
					if (d.propertyName === 'Remarks') {
						if (!(d.originalValue === null && d.newValue === null) && !(d.originalValue === null && d.newValue === '""')) {
							tempData[lastDate].remarks.push(d)
						}
					} else {
						tempData[lastDate].changes.push(d)
					}
				}
			}
		})
		// remove dates that doesn't have relevant changes or remarks
		for (let k in tempData) {
			if (tempData[k].changes.length < 1 && tempData[k].remarks.length < 1) {
				const idx = tempDates.findIndex((d) => d === k)
				if (idx !== -1) tempDates.splice(idx, 1)
			}
		}
		this.setState({ dates: tempDates, newAuditDatas: tempData })
	}

	closeSnackbar = () => {
		this.setState({ showSnackbar: false })
	}

	render() {
		const { classes, auditLogDuck: { getGroupAuditIsLoading, getRedemptionTransactionAuditIsLoading }, reprintAuditLogDuck: { getReprintAuditLogIsLoading, reprintLogs } } = this.props
		const { dates, newAuditDatas } = this.state

		let userName = ''
		return (
			<div>
				<StyledSection title={'Audit Table'} isLoading={getGroupAuditIsLoading || getRedemptionTransactionAuditIsLoading}>
					<Grid container item spacing={24} alignItems={'center'}>
						<Grid item>
							<Button color={'primary'} variant='contained' onClick={this._retriveAuditLogs}>
								Retrieve Audit Logs
							</Button>
						</Grid>
					</Grid>
					{this.state.dates.length > 0 && (
						<Paper className={classes.container}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Date</TableCell>
										<TableCell>Changes</TableCell>
										<TableCell>Remarks</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{dates.length > 0 &&
										dates.map((d) => {
											userName = newAuditDatas[d].changes[0] ? newAuditDatas[d].changes[0].userName : newAuditDatas[d].remarks[0].userName
											return (
												<TableRow key={d}>
													<TableCell>
														{toHumanFriendlyDateTime(d)}
														<br />
														{userName}
													</TableCell>
													<TableCell>
														<ul className={classes.ul}>
															{objHasData(newAuditDatas[d].changes) &&
																newAuditDatas[d].changes.map((c) => {
																	return <li key={c.id}>{toHumanFriendlyMessage(c)}</li>
																})}
														</ul>
													</TableCell>
													<TableCell>
														{objHasData(newAuditDatas[d].remarks) && (
															<ul className={classes.ul}>
																{newAuditDatas[d].remarks.map((c) => {
																	return <li key={'remarks' + c.id}>{toHumanFriendlyMessage(c)}</li>
																})}
															</ul>
														)}
													</TableCell>
												</TableRow>
											)
										})}
								</TableBody>
							</Table>
						</Paper>
					)}

					{reprintLogs && reprintLogs.length > 0 && (
						<Paper className={classes.container}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Date</TableCell>
										<TableCell>Printed By</TableCell>
										<TableCell>Card No</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{reprintLogs.map((m, i) => {
										return (
											<TableRow key={i}>
												<TableCell>{toHumanFriendlyDateTime(m.creationTime)}</TableCell>
												<TableCell>{m.userName}</TableCell>
												<TableCell>{m.reprintCardNo}</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</Paper>
					)}
				</StyledSection>
				<StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar} message={this.state.snackbarMessage} variant={this.state.snackbarVariant} />
			</div>
		)
	}
}

const styles = (themes) => ({
	container: { ...themes.container, marginTop: 20 },
	ul: { paddingLeft: '1rem' },
})

const mapStateToProps = (state) => {
	const { auditLogDuck, groupApprovalDuck, redemptionTransactionPassengersDuck, reprintAuditLogDuck } = state

	return { auditLogDuck, groupApprovalDuck, redemptionTransactionPassengersDuck, reprintAuditLogDuck }
}

const mapDispatchToProps = (dispatch) => {
	return { getGroupAudit: (data) => dispatch(getGroupAudit(data)), getRedemptionTransactionAudit: (data) => dispatch(getRedemptionTransactionAudit(data)), getReprintAuditLog: (data) => dispatch(getReprintAuditLog(data)) }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(AuditLogTable2))
