import React, {Component} from 'react'
import {withStyles, Grid, IconButton, Icon, Typography} from '@material-ui/core'
import {LastPage, FirstPage, KeyboardArrowRight, KeyboardArrowLeft} from '@material-ui/icons'
import {blue} from '@material-ui/core/colors'
import PropTypes from 'prop-types'

class StyledPagination extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {}
    }

    renderPagesToShow = (totalPages, page) => {
        let pagesToShow = 3
        const {classes, onChangePage} = this.props
        const size = Math.floor(pagesToShow / 2)

        let start = 0, end = 0
        if (page - size + pagesToShow > totalPages - 1) {
            start = Math.max(totalPages - pagesToShow, 0)
            end = totalPages
        } else {
            start = Math.max(page - size, 0)
            end = Math.min(start + pagesToShow, totalPages)
        }

        let output = []

        if (start === end) {
            output.push(
                <IconButton key={start} color={'primary'} className={start === page ? classes.active : ''}
                    disabled>
                    <Icon color={'action'} className={start === page ? classes.active : ''}><Typography
                        className={start === page ? classes.activeText : classes.font}
                        variant={'body1'}>{start + 1}</Typography></Icon></IconButton>
            )
            return output
        }

        if (page < pagesToShow - 1) {
            output = []
            while (start < end) {
                let s = start
                if (s === page) {
                    output.push(
                        <IconButton key={s} color={'primary'} className={s === page ? classes.active : ''}
                            disabled>
                            <Icon color={'action'} className={s === page ? classes.active : ''}><Typography
                                className={s === page ? classes.activeText : classes.font}
                                variant={'body1'}>{s + 1}</Typography></Icon></IconButton>
                    )
                } else {
                    output.push(
                        <IconButton key={s} color={'primary'} className={s === page ? classes.active : ''}
                            variant="contained" onClick={e => onChangePage(e, s + 1)}
                        ><Icon color={'primary'}><Typography
                            className={s === page ? classes.activeText : classes.font}
                            variant={'body1'}>{s + 1}</Typography></Icon></IconButton>
                    )
                }
                start++
            }
            if (end < totalPages)
                output.push(<Icon key={end + 1} color={'action'}><Typography variant={'body1'}>...</Typography></Icon>)
            return output
        }

        if (page >= pagesToShow - 1) {
            output = [<Icon key={start - 1} color={'action'}><Typography variant={'body1'}>...</Typography></Icon>]
            while (start < end) {
                let s = start
                if (s === page) {
                    output.push(
                        <IconButton key={s} color={'primary'} className={s === page ? classes.active : ''}
                            disabled>
                            <Icon color={'action'} className={s === page ? classes.active : ''} disabled><Typography
                                className={s === page ? classes.activeText : classes.font}
                                variant={'body1'}>{s + 1}</Typography></Icon></IconButton>
                    )
                } else {
                    output.push(
                        <IconButton key={s} color={'primary'} className={s === page ? classes.active : ''}
                            variant="contained"
                            onClick={e => onChangePage(e, s + 1)}
                        ><Icon color={'action'}><Typography
                            className={s === page ? classes.activeText : classes.font}
                            variant={'body1'}>{s + 1}</Typography></Icon></IconButton>
                    )
                }
                start++
            }
            if (end < totalPages - 1) {
                output.push(<Icon key={end + 1} color={'action'}><Typography variant={'body1'}>...</Typography></Icon>)
            }
            return output
        }
    }

    render() {
        const {page, count, rowsPerPage, onChangePage} = this.props
        const totalPages = Math.ceil(count / rowsPerPage)

        return (
            <Grid container item spacing={24} alignItems={'center'}>
                <Grid item>
                    <IconButton color={'primary'} variant="contained"
                        onClick={e => onChangePage(e, 'first')} disabled={page < 1}><FirstPage/></IconButton>

                    <IconButton color={'primary'} variant="contained"
                        onClick={e => onChangePage(e, 'prev')}
                        disabled={page < 1}><KeyboardArrowLeft/></IconButton>

                    {this.renderPagesToShow(totalPages, page)}

                    <IconButton color={'primary'} variant="contained"
                        onClick={e => onChangePage(e, 'next')}
                        disabled={page >= totalPages - 1}><KeyboardArrowRight/></IconButton>

                    <IconButton color={'primary'} variant="contained"
                        onClick={e => onChangePage(e, 'last')}
                        disabled={page >= totalPages - 1}><LastPage/></IconButton>
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    container: {...theme.container},
    active: {background: blue[500]},
    activeText: {color: 'white', fontSize: '.8rem', lineHeight: 1.8},
    font: {fontSize: '.8rem', lineHeight: 1.8}
})

StyledPagination.propTypes = {
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
}

export default withStyles(styles)(StyledPagination)