import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    withStyles,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TextField, Dialog, DialogTitle, DialogContent, Grid, DialogActions, Button
} from '@material-ui/core'
import {updateSingleBannerDetails, voidBanner, getAllBanner} from '../ducks/bannerDuck'
import StyledDeleteBtn from './StyledDeleteBtn'
import {Warning} from '@material-ui/icons'
import StyledSnackbar from '../components/StyledSnackbar'
import {hasPermissions} from '../helpers/utilities'
import {USER_ADMIN_BANNER_EDIT_PERMISSION} from '../constants/permissions'

const BANNER_TABLE_FIELDS = [
    {key: 'uri', label: 'Images'},
    {key: 'name', label: 'Banner name'},
    {key: 'sortPriority', label: 'Order'}
]

class BannerTable extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            sortedBanners: [],
            deletingBanner: {},
            showDeleteDialog: false,
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success'
        }
    }

    componentDidMount = async () => {
        const rs = await this.props.getAllBanner()
        if (!rs.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
    }

    componentDidUpdate(prevProps, prevState) {
        const {bannerDuck: {getAllBannerIsLoading, banners}} = this.props
        if (prevProps.bannerDuck.getAllBannerIsLoading === true && getAllBannerIsLoading === false) {
            const {bannerDuck: {banners}} = this.props
            if (banners.length > 0 && banners[0].uri) {
                const sortedBanners = banners.filter(b => !b.isDeleted)
                sortedBanners.sort((b1, b2) => b1.sortPriority - b2.sortPriority)
                this.setState({sortedBanners})
            }
        }

        if (prevProps.bannerDuck.banners !== banners) {
            if (banners.length > 0 && banners[0].uri) {
                const sortedBanners = banners.filter(b => !b.isDeleted)
                sortedBanners.sort((b1, b2) => b1.sortPriority - b2.sortPriority)
                this.setState({sortedBanners})
            }
        }
    }

    onOrderChange = (banner, e) => {
        this.props.updateSingleBannerDetails(banner.id, 'sortPriority', e.target.value)
    }

    onDelete = deletingBanner => {
        this.setState({deletingBanner, showDeleteDialog: true})
    }

    onDeleteConfirm = async () => {
        const data = {bannerList: [{...this.state.deletingBanner}]}
        const rs = await this.props.voidBanner(data)
        if (rs.success) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'success',
                snackbarMessage: `Deleted banner ${this.state.deletingBanner.name}`,
                showDeleteDialog: false

            })
            this.props.getAllBanner()
        } else {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: rs.message,
                showDeleteDialog: false
            })
        }
    }

    onDeleteCancel = () => {
        this.setState({showDeleteDialog: false})
    }

    onNameChange = (banner, e) => {
        this.props.updateSingleBannerDetails(banner.id, 'name', e.target.value)
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    render() {
        const {classes} = this.props
        const {sortedBanners, deletingBanner} = this.state

        const hasEditPermission = hasPermissions([USER_ADMIN_BANNER_EDIT_PERMISSION])

        return (
            <div>
                {/*{<editor-fold desc="Alert Dialog">}*/}
                {Object.keys(deletingBanner).length > 0 &&
                <Dialog open={this.state.showDeleteDialog} onClose={this.onDeleteCancel}>
                    <DialogTitle>Proceed to delete the following banner:</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item container spacing={24}>
                                <Grid item md={3}>
                                    <Typography variant={'body1'}>Name: </Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography variant={'body1'}>{deletingBanner.name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={24}>
                                <Grid item md={3}>
                                    <Typography variant={'body1'}>Banner:</Typography>
                                </Grid>

                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'} color={'secondary'}>
                                    <Warning className={classes.icon}/> This process cannot be reversed.
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color={'default'} variant='text' onClick={this.onDeleteCancel}>Cancel</Button>
                        <Button color={'secondary'} variant='contained' onClick={this.onDeleteConfirm}>Yes,
                            Delete</Button>
                    </DialogActions>
                </Dialog>}
                {/*{</editor-fold>}*/}

                <Paper className={classes.container}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {BANNER_TABLE_FIELDS.map(f => {
                                    return <TableCell key={f.key}>{f.label}</TableCell>
                                })}
                                {hasEditPermission && <TableCell>Delete</TableCell>}
                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {sortedBanners.length > 0 && sortedBanners.map((b, ridx) => {
                                return <TableRow key={b.id} draggable>
                                    {BANNER_TABLE_FIELDS.map(f => {
                                        return <TableCell key={b.id + f.key}>
                                            {f.key === 'seq' && <Typography variant={'body1'}>{ridx + 1}</Typography>}

                                            {f.key === 'uri' && <img src={b.presignedUri} alt={''}
                                                className={classes.image}/>}

                                            {f.key === 'name' && <TextField id="" label="" value={b.name || ''}
                                                onChange={e => this.onNameChange(b, e)}
                                                disabled={!hasEditPermission}
                                            />}

                                            {f.key === 'sortPriority' &&
                                            <TextField id="" label=""
                                                value={b.sortPriority}
                                                onChange={e => this.onOrderChange(b, e)} type={'number'}
                                                disabled={!hasEditPermission}
                                            />}

                                        </TableCell>
                                    })}
                                    {hasEditPermission &&
                                    <TableCell><StyledDeleteBtn onClick={() => this.onDelete(b)}/></TableCell>}
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </Paper>
                <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container},
    image: {maxWidth: '100%', width: '200px'}
})

const mapStateToProps = state => {
    const {bannerDuck} = state

    return {bannerDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        getAllBanner: () => dispatch(getAllBanner()),
        updateSingleBannerDetails: (id, k, v) => dispatch(updateSingleBannerDetails(id, k, v)),
        voidBanner: data => dispatch(voidBanner(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BannerTable))