import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withStyles, Grid, Badge, Fab, Typography} from '@material-ui/core'
import {AirplanemodeActive, Edit, TouchApp, CloudDone, CloudOff} from '@material-ui/icons'
import {getAllCampaigns} from '../../ducks/campaignPeriodDuck'
import {
    getCurrentCampaign, hasIssuerPermissions, hasApproverPermissions, hasCreatorPermissions, getCurrentAuthHeader
} from '../../helpers/utilities'
import {getGroupStats} from '../../ducks/groupStatsDuck'
import {CREATOR, APPROVAL, ISSUER} from '../../constants/groupStatsPermissions'
import pendingCollectionIcon from '../../images/pending-collection-ico.png'

const permissionTypeMapper = {
    CREATOR: 0,
    APPROVAL: 1,
    ISSUER: 2
}

class GroupStats extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount = async () => {
        const rs = await this.props.getAllCampaigns()
        if (!rs.success) {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
            return
        }

        const {campaignPeriodDuck: {campaigns}} = this.props
        let currentCampaign = getCurrentCampaign(campaigns)
        if (!currentCampaign) {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'No Campaign found'})
        } else {
            let permissionName = this._getPermissionType()

            const data = {
                params: {
                    dateStart: currentCampaign.startDate,
                    dateEnd: currentCampaign.endDate,
                    userId: localStorage.getItem('userId'),
                    permissionType: permissionTypeMapper[permissionName]
                },
                ...getCurrentAuthHeader()
            }
            const rs2 = await this.props.getGroupStats(data)
            if (!rs2.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs2.message})
        }
    }

    _getPermissionType() {
        if (hasIssuerPermissions()) {
            return ISSUER
        } else if (hasApproverPermissions()) {
            return APPROVAL
        } else if (hasCreatorPermissions()) {
            return CREATOR
        }
    }

    onFabClick = statusFilter => {
        this.props.onFabClick(statusFilter)
    }

    render() {
        const {classes, groupStatsDuck: {actionRequired, approved, pendingApproval, pendingSQ, rejected}} = this.props

        return (
            <Grid container spacing={24}>
                {(false) && (
                    <Grid item className={classes.badgeContainer}>
                        <Badge color={'secondary'} badgeContent={pendingSQ}>
                            <Fab color={'primary'}
                                onClick={() => this.onFabClick('PENDING SQ API')}><AirplanemodeActive/></Fab>
                        </Badge>
                        <Typography variant={'body1'}>Pending SQ</Typography>
                    </Grid>
                )}

                {(hasCreatorPermissions()) && (
                    <Grid item className={classes.badgeContainer}>
                        <Badge color={'secondary'} badgeContent={actionRequired}>
                            <Fab color={'primary'} onClick={() => this.onFabClick('ACTION REQUIRED')}><Edit/></Fab>
                        </Badge>
                        <Typography variant={'body1'}>Action required</Typography>
                    </Grid>
                )}

                {(hasCreatorPermissions() || hasApproverPermissions()) && (
                    <Grid item className={classes.badgeContainer}>
                        <Badge color={'secondary'} badgeContent={pendingApproval}>
                            <Fab color={'primary'} onClick={() => this.onFabClick('PENDING APPROVAL')}><TouchApp/></Fab>
                        </Badge>
                        <Typography variant={'body1'}>Pending approval</Typography>
                    </Grid>
                )}

                {(hasCreatorPermissions() || hasApproverPermissions()) && (
                    <Grid item className={classes.badgeContainer}>
                        <Badge color={'secondary'} badgeContent={approved}>
                            <Fab color={'primary'}
                                onClick={() => this.onFabClick('PENDING COLLECTION')}><CloudDone/></Fab>
                        </Badge>
                        <Typography variant={'body1'}>Approved</Typography>
                    </Grid>
                )}

                {(hasCreatorPermissions() || hasApproverPermissions()) && (
                    <Grid item className={classes.badgeContainer}>
                        <Badge color={'secondary'} badgeContent={rejected}>
                            <Fab color={'primary'} onClick={() => this.onFabClick('REJECTED')}><CloudOff/></Fab>
                        </Badge>
                        <Typography variant={'body1'}>Rejected</Typography>
                    </Grid>
                )}

                {hasIssuerPermissions() && (
                    <Grid item className={classes.badgeContainer}>
                        <Badge color={'secondary'} badgeContent={rejected}>
                            <Fab color={'primary'} onClick={() => this.onFabClick('PENDING COLLECTION')}><img
                                src={pendingCollectionIcon} alt={''} className={classes.pendingCollectionIcon}/></Fab>
                        </Badge>
                        <Typography variant={'body1'}>Pending Collection</Typography>
                    </Grid>
                )}

                {/*{(hasCreatorPermissions() ) && (*/}
                {/*<Grid item className={classes.badgeContainer}>*/}
                {/*    <Badge color={'secondary'} badgeContent={deleted}>*/}
                {/*        <Fab color={'primary'}><Delete/></Fab>*/}
                {/*    </Badge>*/}
                {/*    <Typography variant={'body1'}>Deleted</Typography>*/}
                {/*</Grid>*/}
                {/*)}*/}
            </Grid>
        )
    }
}

const styles = theme => ({
    container: {...theme.container},
    badgeContainer: {textAlign: 'center', margin: '0 1rem', maxWidth: '7rem'},
    pendingCollectionIcon: {maxWidth: '50%'}
})

const mapStateToProps = state => {
    const {campaignPeriodDuck, groupStatsDuck} = state

    return {campaignPeriodDuck, groupStatsDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        getAllCampaigns: () => dispatch(getAllCampaigns()),
        getGroupStats: params => dispatch(getGroupStats(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupStats))