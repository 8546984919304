class PageHelper {
    constructor({maxResultCount}) {
        this.maxResultCount = maxResultCount;
        this.skipCount = 0;
        this.totalCount = 0;
        this.noOfPages = 0;
    }

    newCall(){
        this.totalCount = 0;
        this.noOfPages = 0;
        this.skipCount = 0;
        return {maxResultCount: this.maxResultCount, skipCount: this.skipCount}

    }

    populatePagingData({totalCount}){
        this.totalCount = totalCount;
        this.noOfPages = Math.ceil(totalCount / this.maxResultCount);
    }

    pageCall(pageNo){
        if(this.totalCount === 0 || this.noOfPages === 0) throw new Error ("Please populate the paging data before doing pageCall")
        this.skipCount = (pageNo - 1) * this.maxResultCount;
        return {maxResultCount: this.maxResultCount, skipCount: this.skipCount}
    }

    getTotalNoOfPages=()=> {
        if(this.totalCount === 0 || this.noOfPages === 0) throw new Error ("Please populate the paging data before invoking getTotalNoOfPages")
        return this.noOfPages;
    }

    getTotalCount=()=>{
        return this.totalCount;
    }

}

export default PageHelper