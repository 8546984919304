/*
const SELECT_EXISTING_CR = 'SELECT_EXISTING_CR';
const SELECT_NEW_COLLECTOR = 'SELECT_NEW_COLLECTOR';
const SELECT_REGISTER_CR = 'SELECT_REGISTER_CR';
const SELECT_ISSUE_CTP = 'SELECT_ISSUE_CTP';
const SELECT_CTP_PHYSICAL_VOUCHER = 'SELECT_CTP_PHYSICAL_VOUCHER';
const SELECT_CTP_SINGLE_CARD = 'SELECT_CTP_SINGLE_CARD';
const SELECT_CTP_MULTIPLE_CARDS = 'SELECT_CTP_MULTIPLE_CARDS';
*/

const UPDATE_INDIVIDUAL_COLLECTOR_OPTIONS = 'UPDATE_INDIVIDUAL_COLLECTOR_OPTIONS';


export const updateIndividualCollectorOptions = ({   isExistingCr, //1
                                                     isExistingCrCard, //2
                                                     isExistingCrPhysicalVoucher, //2
                                                     isExistingCrPreloadedVouchersCard, //2
                                                     isNewCollector, //1
                                                     isRegisterCr, //2
                                                     isSingleCrCard, //3
                                                     isCtp, //2
                                                     isSingleCtpCard, //3
                                                     isMultipleCtpCard, //3
                                                     isCtpPhysicalVoucher, //3
                                                     isCtpDigitalCard, //3
                                                     isCtpPreloadedVouchersCard, //3
    }) => {
    return ({
        type: UPDATE_INDIVIDUAL_COLLECTOR_OPTIONS,
        payload:{
            isExistingCr,
            isExistingCrCard,
            isExistingCrPhysicalVoucher,
            isExistingCrPreloadedVouchersCard,
            isNewCollector,
            isRegisterCr,
            isSingleCrCard,
            isCtp,
            isSingleCtpCard,
            isMultipleCtpCard,
            isCtpPhysicalVoucher,
            isCtpDigitalCard,
            isCtpPreloadedVouchersCard
        }

    });
}

const initState = {
    isExistingCr: false,
    isExistingCrCard: false,
    isExistingCrPhysicalVoucher: false,
    isExistingCrPreloadedVouchersCard: false,
    isNewCollector: false,
    isRegisterCr: false,
    isSingleCrCard: false,
    isCtp: false,
    isSingleCtpCard: false,
    isMultipleCtpCard: false,
    isCtpPhysicalVoucher: false,
    isCtpDigitalCard: false,
    isCtpPreloadedVouchersCard: false,
};

const individualCollectorOptionDuck = (state = initState, action) => {
    switch (action.type) {

        case UPDATE_INDIVIDUAL_COLLECTOR_OPTIONS:
            let payload = action.payload;
            return {
                ...state,
                ...payload
            };


        default:
            return state
    }
};

export default individualCollectorOptionDuck
