import React from 'react'
import PropTypes from 'prop-types'
import logo from '../images/CTP_Logo_Final_Black.png'

const Logo = props => {
    const {width: w, invert} = props
    const style = {width: w ? w : '8rem'}
    if (invert) {
        style.filter = 'invert(1)'
    }
    return (
        <img src={logo} alt="logo" style={style}/>
    )
}

Logo.propTypes = {
    width: PropTypes.string,
    invert: PropTypes.bool
}

export default Logo