import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Button, Grid } from '@material-ui/core'
import Header from '../containers/Header'
import { getCrDetailsFromOCID } from '../ducks/individualFormDuck'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import StyledSection from '../components/StyledSection'
import signalRConstants from "../helpers/signalRConstants";
import {HubConnectionBuilder} from "@aspnet/signalr";
import {signalRURL} from "../config/config";

class OCIDExchange extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			authorizationCode: '',
			origin: '',
			isLoading: false,
			printingCard: '',
			cardNoPrinted: '',
            signalRConnected: false,
		}
        this.ocidWindow = null;
    }

	async componentDidMount() {
		const queryString = require('query-string')
		const code = queryString.parse(window.location.search).code
		if (code)
			await this.props.getCrDetailsFromOCID(code)

        this.hubConnection = new HubConnectionBuilder().withUrl(signalRURL).build()
        this._startSignalRConnection()
        this.hubConnection.on('ReceiveMessage', this._receiveMessage)
        this.hubConnection.onclose(async () => {
            await this._startSignalRConnection()
        })
	}

	componentDidUpdate(prevProps,prevState){
		if(prevProps.individualFormDuck.crDetailsFromOCID !== this.props.individualFormDuck.crDetailsFromOCID ||
			prevState.signalRConnected !== this.state.signalRConnected

		){
			if(this.props.individualFormDuck.crDetailsFromOCID.cardNo && this.state.signalRConnected){
                this._sendMessage({crDetails:this.props.individualFormDuck.crDetailsFromOCID}, signalRConstants.UPDATE_CR_DETAILS_FROM_OCID )

            }
		}

	}

    _startSignalRConnection = async () => {
        this.hubConnection
            .start()
            .then(() =>{
                console.log('Connection started!');
        		this.setState({signalRConnected: true})
			})
            .catch(err => {
                setTimeout(() => this._startSignalRConnection(), 5000)
                console.log('Error while establishing connection :(')
            })
    }

    _sendMessage = ({ crDetails }, messageType) => {
        if (messageType === undefined) throw new Error("No message type defined for signalR")

        const user = localStorage.getItem('username')
        let messageObj = {
            user,
            type: messageType,
            source: signalRConstants.OCID_EXCHANGE,
            data: { crDetails }
        }
        let messageStr = JSON.stringify(messageObj)
        if(this.hubConnection){
            this.hubConnection.invoke('SendMessage', 'default', messageStr).catch(function(err) {
                return console.error(err.toString())
            })
        }
    }

    _receiveMessage = (user, messageStr) => {
      return; //no need to listen for any message on this screen
    }

	onPrint = () => {
		window.print()
	}

	render() {
		const { classes, individualFormDuck } = this.props
		return (
			<div>
				<Header history={this.props.history} />
				<GlobalTimeChecker />
				<div className={classes.container}>
					<StyledSection title={'OCID Exchange'} toolbarButtons={this._toolbarButtons} isLoading={individualFormDuck.getCrDetailsFromOCIDLoading}>
						<p> Redirecting you back to form </p>
					</StyledSection>
				</div>
			</div>
		)
	}
}

const styles = (theme) => ({
	container: { ...theme.container },
	row: { display: 'flex' },
	rowItem: { display: 'flex', flex: 1, marginRight: 60, alignItems: 'center' },
	flexStart: { alignItems: 'flex-start' },
	consentText: { fontSize: '0.8125rem' },
})

const mapStateToProps = (state) => {
	const { individualFormDuck } = state

	return { individualFormDuck }
}

const mapDispatchToProps = (dispatch) => {
	return {
		getCrDetailsFromOCID: (code) => dispatch(getCrDetailsFromOCID(code)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(OCIDExchange))
