import React, {Component} from 'react'
import {withStyles, Toolbar, Button} from '@material-ui/core'
import CustomNavButton from '../../components/CustomNavButton'

class SearchNav extends Component {
    render() {
        const {classes} = this.props

        const customPassenger = props => <CustomNavButton to={'/search/passenger'}  {...props}
            activeClassName={classes.active}/>
        const customGroup = props => <CustomNavButton to={'/search/group'}  {...props}
            activeClassName={classes.active}/>
        const customIssuanceSummary = props => <CustomNavButton to={'/search/issuance-summary'}  {...props}
            activeClassName={classes.active}/>

        return (
            <Toolbar>
                <Button color={'primary'} component={customPassenger}>Passenger</Button>
                <Button color={'primary'} component={customGroup}>Group</Button>
                <Button color={'primary'} component={customIssuanceSummary}>Issuance Summary</Button>
            </Toolbar>
        )
    }
}

const styles = theme => ({
    active: {backgroundColor: theme.palette.action.selected}
})

export default withStyles(styles)(SearchNav)