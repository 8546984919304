import React from 'react'
import {connect} from 'react-redux'
import {withStyles, Grid, Typography} from '@material-ui/core'
import Header from '../containers/Header.js'
import GroupHeader from '../containers/group/GroupHeader.js'
import GroupSubmissionForm from '../containers/group/GroupSubmissionForm'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import StyledSection from '../components/StyledSection'
import LoadingContainer from '../containers/loadingContainer'
import StyledSnackbar from '../components/StyledSnackbar'
import {getAllGroup, voidGroup} from '../ducks/groupApprovalDuck'
import {GROUP_TYPE_ACTION_REQUIRED} from '../constants/groupType'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import DeleteDialog from '../components/DeleteDialog'
import {hasPermissions} from '../helpers/utilities'

const LIST_VIEW = 'LIST_VIEW'
const UPLOAD_VIEW = 'UPLOAD_VIEW'

class GroupSubmission extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeView: UPLOAD_VIEW,
            selectedGroupId: '',
            isLoading: false,
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            statusFilter: 'ACTION REQUIRED',
            groupType: GROUP_TYPE_ACTION_REQUIRED,
            showDeleteDialog: false,
            deletingGroup: {}
        }
    }

    componentDidMount = async () => {
        document.title = `${TITLE_PREFIX} Group - Group Submission`
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.getAllGroupIsLoading === true && this.props.getAllGroupIsLoading === false) {
            this.setState({filteredGroups: this.props.groups})
        }
    }

    onEdit = row => {
        if (this.state.statusFilter === 'PENDING APPROVAL') this.props.history.push('/group/group-approval/' + row.id)
        if (this.state.statusFilter === 'ACTION REQUIRED') this.props.history.push('/group/group-submission/' + row.id)
    }

    onDelete = row => {
        this.setState({showDeleteDialog: true, deletingGroup: row})
    }

    onDeleteConfirm = async () => {
        const {deletingGroup, groupType} = this.state
        const data = {id: deletingGroup.id}
        const rs = await this.props.voidGroup(data)
        if (rs.success) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'success',
                snackbarMessage: 'Group ' + deletingGroup.refNo + ' deleted',
                showDeleteDialog: false,
                deletingGroup: {}
            })
            const isApprover = hasPermissions('Group.Approver')
            this.props.getAllGroup({groupType, getAllUser: isApprover})
        } else {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: rs.message,
                showDeleteDialog: false,
                deletingGroup: {}
            })
        }
    }

    onDeleteCancel = () => {
        this.setState({showDeleteDialog: false, deletingGroup: {}})
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    setActiveView = activeView => this.setState({activeView})

    onFilterChanged = (groupType, statusFilter) => {
        this.setState({groupType, statusFilter})
    }

    render() {
        const {classes, getAllGroupIsLoading, manifestUploadIsLoading} = this.props
        const {showDeleteDialog, deletingGroup: {refNo, submittedBy}} = this.state

        return (
            <div>
                <GlobalTimeChecker/>

                <Header history={this.props.history}/>
                <GroupHeader/>

                <div className={classes.container}>



                    <StyledSection title={'Group Submission'} isLoading={getAllGroupIsLoading || manifestUploadIsLoading}>
                        <Grid container spacing={24} direction={'column'}>

                            {this.state.activeView === UPLOAD_VIEW && (
                                <Grid item>
                                    <GroupSubmissionForm onBack={() => this.setActiveView(LIST_VIEW)}/>
                                </Grid>
                            )}

                        </Grid>
                    </StyledSection>
                </div>

                <LoadingContainer show={this.state.isLoading}/>
                <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
            </div>
        )

    }
}

const styles = theme => ({
    container: {...theme.container}
})

const mapStateToProps = function (state) {
    const {groupManifestDuck, groupApprovalDuck, individualFormDuck} = state

    const {groups, getAllGroupIsLoading} = groupApprovalDuck
    const {manifestUploadIsLoading, manifestUploadStatus, error, errorArray} = groupManifestDuck

    return {
        formDetailsList: individualFormDuck.formDetailsList,
        manifestUploadIsLoading,
        manifestUploadStatus,
        error,
        errorArray,
        groups,
        getAllGroupIsLoading
    }
}

const mapDispatchToProps = function (dispatch) {
    return {
        getAllGroup: ({groupType, getAllUser}) => dispatch(getAllGroup({groupType, getAllUser})),
        voidGroup: data => dispatch(voidGroup(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupSubmission))