import axios from 'axios';
import { getSettings } from '../config/config';
import { getCurrentAuthHeader, spreadObj } from '../helpers/utilities';
import querystring from 'querystring';
import {
    convertSqStatusForDisplay,
    DISPLAY_NO
} from '../helpers/eticketStatusHelper';
import { MemberRepo } from '../repos/memberRepo';
import * as cloneDeep from 'lodash/cloneDeep';
import { checkValidStatusCode } from '../helpers/httpHelper';
import {
    MULTI_CARD_EV,
    MULTI_CARD_DIGITAL,
    SINGLE_CARD_DIGITAL,
    MULTI_CARD_UNLOADED
} from '../constants/groupCardAssignment';
import { CTP_CARD_DEFAULT_CCN } from '../constants/cardConstants';

const RESET_GROUP_REDEMPTION_FORM_REDUCER =
    'RESET_GROUP_REDEMPTION_FORM_REDUCER';
const GET_AND_STORE_QR_CODE_DETAILS = 'GET_AND_STORE_QR_CODE_DETAILS';
const GET_GROUP_AND_PASSENGERS_REDEMPTION_IS_LOADING =
    'GET_GROUP_AND_PASSENGERS_REDEMPTION_IS_LOADING';
const GET_GROUP_AND_PASSENGERS_REDEMPTION_SUCCESS =
    'GET_GROUP_AND_PASSENGERS_REDEMPTION_SUCCESS';
const GET_GROUP_AND_PASSENGERS_REDEMPTION_FAIL =
    'GET_GROUP_AND_PASSENGERS_REDEMPTION_FAIL';
const GROUP_ISSUE_CARD_IS_LOADING = 'GROUP_ISSUE_CARD_IS_LOADING';
const GROUP_ISSUE_CARD_SUCCESS = 'GROUP_ISSUE_CARD_SUCCESS';
const GROUP_ISSUE_CARD_FAIL = 'GROUP_ISSUE_CARD_FAIL';
const UPDATE_PASSENGERS = 'UPDATE_PASSENGERS';
const UPDATE_GROUP_REDEMPTION_FORM_DATA = 'UPDATE_GROUP_REDEMPTION_FORM_DATA';
const UPDATE_SELECTED_GROUP_ID = 'UPDATE_SELECTED_GROUP_ID';
const UPDATE_GROUP_COLLECTOR_DETAILS = 'UPDATE_GROUP_COLLECTOR_DETAILS';
const CHECK_INACTIVE_CARDS_SUCCESS = 'CHECK_INACTIVE_CARDS_SUCCESS';
const CHECK_INACTIVE_CARDS_FAIL = 'CHECK_INACTIVE_CARDS_FAIL';
const CHECK_INACTIVE_CARDS_IS_LOADING = 'CHECK_INACTIVE_CARDS_IS_LOADING';
const GROUP_COLLECTOR_SIGNATURE_UPLOAD_IS_LOADING =
    'GROUP_COLLECTOR_SIGNATURE_UPLOAD_IS_LOADING';
const GROUP_COLLECTOR_SIGNATURE_UPLOAD_SUCCESS =
    'GROUP_COLLECTOR_SIGNATURE_UPLOAD_SUCCESS';
const GROUP_COLLECTOR_SIGNATURE_UPLOAD_FAIL =
    'GROUP_COLLECTOR_SIGNATURE_UPLOAD_FAIL';
const GROUP_SQ_API_CHECK_IS_LOADING = 'GROUP_SQ_API_CHECK_IS_LOADING';
const GROUP_SQ_API_CHECK_SUCCESS = 'GROUP_SQ_API_CHECK_SUCCESS';
const GROUP_SQ_API_CHECK_FAIL = 'GROUP_SQ_API_CHECK_FAIL';
const GROUP_CHECK_REDEEMED_PASSENGER_IS_LOADING =
    'GROUP_CHECK_REDEEMED_PASSENGER_IS_LOADING';
const GROUP_CHECK_REDEEMED_PASSENGER_SUCCESS =
    'GROUP_CHECK_REDEEMED_PASSENGER_SUCCESS';
const GROUP_CHECK_REDEEMED_PASSENGER_FAIL =
    'GROUP_CHECK_REDEEMED_PASSENGER_FAIL';
const UPDATE_GROUP_REDEMPTION_FORM_DUCK = 'UPDATE_GROUP_REDEMPTION_FORM_DUCK';
const UPDATE_GROUP_REDEMPTION_FORM_DUCK_OBJ =
    'UPDATE_GROUP_REDEMPTION_FORM_DUCK_OBJ';
const MULTIPLE_ACTIVATE_AND_ISSUE_CARD_IS_LOADING =
    'MULTIPLE_ACTIVATE_AND_ISSUE_CARD_IS_LOADING';
const MULTIPLE_ACTIVATE_AND_ISSUE_CARD_SUCCESS =
    'MULTIPLE_ACTIVATE_AND_ISSUE_CARD_SUCCESS';
const MULTIPLE_ACTIVATE_AND_ISSUE_CARD_FAIL =
    'MULTIPLE_ACTIVATE_AND_ISSUE_CARD_FAIL';
const CREATE_GROUP_TRANSACTION_AND_ASSIGN_DIGITAL_CARDS_IS_LOADING =
    'CREATE_GROUP_TRANSACTION_AND_ASSIGN_DIGITAL_CARDS_IS_LOADING';
const CREATE_GROUP_TRANSACTION_AND_ASSIGN_DIGITAL_CARDS_SUCCESS =
    'CREATE_GROUP_TRANSACTION_AND_ASSIGN_DIGITAL_CARDS_SUCCESS';
const CREATE_GROUP_TRANSACTION_AND_ASSIGN_DIGITAL_CARDS_FAIL =
    'CREATE_GROUP_TRANSACTION_AND_ASSIGN_DIGITAL_CARDS_FAIL';

let settings = getSettings();
let baseURL = settings.baseURL;

const initState = {
    getGroupAndPassengersIsLoading: false,
    group: { id: '' },
    passengers: [
        {
            physicalVoucher: ['', ''],
            physicalVoucherIssued: false,
            preloadedVouchersCardNo: '',
            cardNo: '',
            fullCardNo: ''
        }
    ],
    physicalVoucherIssued: false,
    rejectedPassengers: [],
    redeemedPassengers: [],
    tourLeader: { passportNo: '', firstName: '', lastName: '' },
    collector: {
        allowReceivingInfo: false,
        csoSignatureBase64: '',
        clientSignatureBase64: '',
        clientIsDone: false,
        collectionConsent: '',
        marketingConsent: '',
        airlineConsent: '',
        additionalCrConsent: ''
    },
    redemptionTransactionId: '',
    signatureUri: '',
    groupIssueCardIsLoading: false,
    createGroupTransactionAndAssignDigitalCardsIsLoading: false,
    createGroupTransactionAndAssignDigitalCardsCompleted: false,
    selectedGroupId: 0,
    groupCollectorSignatureUploadIsLoading: false,
    groupSqApiCheckIsLoading: false,
    groupSqApiCheckResults: [],
    paxFlown: 0,
    groupCheckRedeemedPassengerIsLoading: false,
    groupCheckRedeemedPassenger: [],
    cardAssignment: 'multi',
    groupMetaInfo: {},
    ui: {},
    checkInactiveCardsIsLoading: false,
    multipleActivateAndIssueCardIsLoading: false,
    multipleActivateAndIssueCardComplete: false
};

export const resetGroupRedemptionFormReducer = () => ({
    type: RESET_GROUP_REDEMPTION_FORM_REDUCER
});

export const updateSelectedGroupId = selectedGroupId => ({
    type: UPDATE_SELECTED_GROUP_ID,
    selectedGroupId: selectedGroupId
});

export const getAndStoreQRCodeDetails = value => ({
    type: GET_AND_STORE_QR_CODE_DETAILS,
    payload: value
});

export const updateGroupRedemptionFormData = ({
    group,
    tourLeader,
    passengers,
    collector,
    signatureUri
}) => {
    return {
        type: UPDATE_GROUP_REDEMPTION_FORM_DATA,
        payload: { group, tourLeader, passengers, collector, signatureUri }
    };
};

export const getGroupAndPassengersRedemption = ({
    groupId,
    refNo,
    getAllUser
}) => async (dispatch, getState) => {
    dispatch(_getGroupAndPassengersIsLoading());

    let urlParams = {};
    if (groupId) urlParams = { groupId: groupId };
    if (refNo) urlParams = { refNoFilter: refNo };
    if (getAllUser) urlParams.getAllUser = getAllUser;

    const data = { params: urlParams, ...getCurrentAuthHeader() };
    const rs = await axios
        .get(baseURL + '/Group/GetGroupAndPassengers', data)
        .catch(e => {
            if (!checkValidStatusCode(e.response.status)) {
                const {
                    response: {
                        data: {
                            error: { message }
                        }
                    }
                } = e;
                return { data: { success: false, error: { message } } };
            }
            return {
                data: {
                    success: false,
                    error: { message: e.response.status + ' Unexpected error' }
                }
            };
        });
    if (rs.data.success) {
        const {
            flightDuck: { flightData }
        } = getState();
        dispatch(_getGroupAndPassengersRedemptionSuccess(rs, flightData));
        return { success: true, message: '' };
    } else {
        dispatch(_getGroupAndPassengersRedemptionFail());
        return { success: false, message: rs.data.error.message };
    }
};

const _groupIssueCardsIsLoading = () => ({
    type: GROUP_ISSUE_CARD_IS_LOADING
});

const _groupIssueCardsSuccess = responseData => ({
    type: GROUP_ISSUE_CARD_SUCCESS,
    payload: responseData
});

const _groupIssueCardsFail = () => ({
    type: GROUP_ISSUE_CARD_FAIL
});

export const groupIssueCards = ({
    tourLeader,
    collector,
    group,
    passengers,
    signatureUri,
    cardAssignment,
    redemptionTransactionId,
    cardActivationResponse
}) => async dispatch => {
    dispatch(_groupIssueCardsIsLoading());
    let clonedPassengers = cloneDeep(passengers);
    clonedPassengers = clonedPassengers.map(p => {
        if (p.meta) delete p.meta;
        p.voucherValue = p.ctpVoucherValue;
        return p;
    });
    let clonedCollector = { ...collector, csoSignatureBase64: '' };

    let postData = {
        tourLeader,
        collector: clonedCollector,
        group,
        passengers: clonedPassengers.filter(p => p.collecting),
        signatureUri,
        isDigitalCard:
            cardAssignment === MULTI_CARD_DIGITAL ||
            cardAssignment === SINGLE_CARD_DIGITAL,
        isSingleCard: cardAssignment === SINGLE_CARD_DIGITAL,
        isPreloadedVouchersCard:
            cardAssignment === MULTI_CARD_EV ||
            cardAssignment === MULTI_CARD_UNLOADED,
        redemptionTransactionId: redemptionTransactionId
    };

    const rs = await axios
        .post(
            baseURL + '/Group/GroupIssueCards',
            postData,
            getCurrentAuthHeader()
        )
        .catch(e => {
            if (!checkValidStatusCode(e.response.status)) {
                const {
                    response: {
                        data: {
                            error: { message }
                        }
                    }
                } = e;
                return { data: { success: false, error: { message } } };
            }
            return {
                data: {
                    success: false,
                    error: { message: e.response.status + ' Unexpected error' }
                }
            };
        });

    if (rs.data.success) {
        dispatch(
            _groupIssueCardsSuccess({
                result: rs.data.result,
                cardActivationResponse
            })
        );
        return { success: true, message: '' };
    } else {
        dispatch(_groupIssueCardsFail());
        return { success: false, message: rs.data.error.message };
    }
};

const _createGroupTransactionAndAssignDigitalCardsIsLoading = () => ({
    type: CREATE_GROUP_TRANSACTION_AND_ASSIGN_DIGITAL_CARDS_IS_LOADING
});

const _createGroupTransactionAndAssignDigitalCardsSuccess = responseData => ({
    type: CREATE_GROUP_TRANSACTION_AND_ASSIGN_DIGITAL_CARDS_SUCCESS,
    payload: responseData
});

const _createGroupTransactionAndAssignDigitalCardsFail = () => ({
    type: CREATE_GROUP_TRANSACTION_AND_ASSIGN_DIGITAL_CARDS_FAIL
});

export const createGroupTransactionAndAssignDigitalCards = ({
    tourLeader,
    collector,
    group,
    passengers,
    signatureUri,
    cardAssignment
}) => async dispatch => {
    dispatch(_createGroupTransactionAndAssignDigitalCardsIsLoading());
    let clonedPassengers = cloneDeep(passengers);
    clonedPassengers = clonedPassengers.filter(p => p.collecting === true);
    clonedPassengers = clonedPassengers.map(p => {
        if (p.meta) delete p.meta;
        p.voucherValue = p.ctpVoucherValue;
        return p;
    });
    let clonedCollector = { ...collector, csoSignatureBase64: '' };

    let postData = {
        tourLeader,
        collector: clonedCollector,
        group,
        passengers: clonedPassengers,
        signatureUri,
        isDigitalCard:
            cardAssignment === MULTI_CARD_DIGITAL ||
            cardAssignment === SINGLE_CARD_DIGITAL,
        isSingleCard: cardAssignment === SINGLE_CARD_DIGITAL,
        isPreloadedVouchersCard:
            cardAssignment === MULTI_CARD_EV ||
            cardAssignment === MULTI_CARD_UNLOADED
    };

    const rs = await axios
        .post(
            baseURL + '/Group/CreateGroupTransaction',
            postData,
            getCurrentAuthHeader()
        )
        .catch(e => {
            if (!checkValidStatusCode(e.response.status)) {
                const {
                    response: {
                        data: {
                            error: { message }
                        }
                    }
                } = e;
                return { data: { success: false, error: { message } } };
            }
            return {
                data: {
                    success: false,
                    error: { message: e.response.status + ' Unexpected error' }
                }
            };
        });

    if (rs.data.success) {
        dispatch(
            _createGroupTransactionAndAssignDigitalCardsSuccess(rs.data.result)
        );
        return { success: true, message: '' };
    } else {
        dispatch(_createGroupTransactionAndAssignDigitalCardsFail());
        return { success: false, message: rs.data.error.message };
    }
};

const _checkInactiveCardsIsLoading = () => {
    return { type: CHECK_INACTIVE_CARDS_IS_LOADING };
};

const _checkInactiveCardsSuccess = () => {
    return { type: CHECK_INACTIVE_CARDS_SUCCESS };
};

const _checkInactiveCardsFail = () => {
    return { type: CHECK_INACTIVE_CARDS_FAIL };
};

export const checkInactiveCards = cards => async dispatch => {
    dispatch(_checkInactiveCardsIsLoading());

    let postData = { cards: cards };

    const rs = await axios
        .post(
            baseURL + '/Member/CheckInactiveCards',
            postData,
            getCurrentAuthHeader()
        )
        .catch(e => {
            dispatch(_checkInactiveCardsFail());
            if (!checkValidStatusCode(e.response.status)) {
                const {
                    response: {
                        data: {
                            error: { message }
                        }
                    }
                } = e;
                return { data: { success: false, error: { message } } };
            }
            return {
                data: {
                    success: false,
                    error: { message: e.response.status + ' Unexpected error' }
                }
            };
        });

    dispatch(_checkInactiveCardsSuccess());
    return rs.data;
};

const _getGroupAndPassengersIsLoading = () => ({
    type: GET_GROUP_AND_PASSENGERS_REDEMPTION_IS_LOADING
});

const _getGroupAndPassengersRedemptionSuccess = (rs, flightData) => ({
    type: GET_GROUP_AND_PASSENGERS_REDEMPTION_SUCCESS,
    rs,
    flightData
});

const _getGroupAndPassengersRedemptionFail = () => ({
    type: GET_GROUP_AND_PASSENGERS_REDEMPTION_FAIL
});

export const updatePassengers = passengers => ({
    type: UPDATE_PASSENGERS,
    passengers
});

export const updateGroupCollectorDetails = collector => ({
    type: UPDATE_GROUP_COLLECTOR_DETAILS,
    collector
});

export const groupCollectorSignatureUpload = formData => async dispatch => {
    dispatch(_groupCollectorSignatureUploadIsLoading());

    const rs = await axios
        .post(
            baseURL +
                '/File/FileUpload?' +
                querystring.stringify({ bucketType: 0 }),
            formData,
            getCurrentAuthHeader()
        )
        .catch(e => {
            if (!checkValidStatusCode(e.response.status)) {
                if (e.response.data && e.response.data.error) {
                    const {
                        response: {
                            data: {
                                error: { message }
                            }
                        }
                    } = e;
                    dispatch(_groupCollectorSignatureUploadFail());
                    return { data: { success: false, error: { message } } };
                }
            }
            dispatch(_groupCollectorSignatureUploadFail());
            return {
                data: {
                    success: false,
                    error: { message: e.response.status + ' Unexpected error' }
                }
            };
        });
    if (rs.data.success) {
        dispatch(_groupCollectorSignatureUploadSuccess(rs));
        return { success: true, message: '' };
    } else {
        dispatch(_groupCollectorSignatureUploadFail());
        return { success: false, message: rs.data.error.message };
    }
};

const _groupCollectorSignatureUploadIsLoading = () => ({
    type: GROUP_COLLECTOR_SIGNATURE_UPLOAD_IS_LOADING
});
const _groupCollectorSignatureUploadSuccess = rs => ({
    type: GROUP_COLLECTOR_SIGNATURE_UPLOAD_SUCCESS,
    rs
});
const _groupCollectorSignatureUploadFail = () => ({
    type: GROUP_COLLECTOR_SIGNATURE_UPLOAD_FAIL
});

export const groupSqApiCheck = data => async dispatch => {
    dispatch(_groupSqApiCheckIsLoading());

    const rs = await axios
        .post(baseURL + '/SqApi/GroupSqApiCheck', data, getCurrentAuthHeader())
        .catch(e => {
            if (!checkValidStatusCode(e.response.status)) {
                const {
                    response: {
                        data: {
                            error: { message }
                        }
                    }
                } = e;
                return { data: { success: false, error: { message } } };
            }
            return {
                data: {
                    success: false,
                    error: { message: e.response.status + ' Unexpected error' }
                }
            };
        });
    if (!rs.data) {
        dispatch(_groupSqApiCheckFail());
        return { success: false, message: 'No data from server' };
    }
    if (rs.data.success) {
        dispatch(_groupSqApiCheckSuccess(rs));
        return { success: true, message: '' };
    } else {
        dispatch(_groupSqApiCheckFail());
        return { success: false, message: rs.data.error.message };
    }
};

const _groupSqApiCheckIsLoading = () => ({
    type: GROUP_SQ_API_CHECK_IS_LOADING
});

const _groupSqApiCheckSuccess = rs => ({
    type: GROUP_SQ_API_CHECK_SUCCESS,
    rs
});

const _groupSqApiCheckFail = () => ({
    type: GROUP_SQ_API_CHECK_FAIL
});

/**
 * If idx is passed, toggle collection of idx.
 * If status is passed, set all collection to status
 * @param idx
 * @param status
 */
export const updateCollectingStatus = (idx, status) => ({
    type: UPDATE_COLLECTING_STATUS,
    idx,
    status
});
const UPDATE_COLLECTING_STATUS = 'UPDATE_COLLECTING_STATUS';

export const updateSinglePax = (idx, key, value) => ({
    type: UPDATE_SINGLE_PAX,
    idx,
    key,
    value
});
const UPDATE_SINGLE_PAX = 'UPDATE_SINGLE_PAX';

export const groupCheckRedeemedPassenger = data => async dispatch => {
    dispatch(_groupCheckRedeemedPassengerIsLoading());

    const rs = await axios
        .post(
            baseURL + '/SqApi/GroupCheckRedeemedPassenger',
            data,
            getCurrentAuthHeader()
        )
        .catch(e => {
            if (!checkValidStatusCode(e.response.status)) {
                const {
                    response: {
                        data: {
                            error: { message }
                        }
                    }
                } = e;
                return { data: { success: false, error: { message } } };
            }
            return {
                data: {
                    success: false,
                    error: { message: e.response.status + ' Unexpected error' }
                }
            };
        });
    if (!rs.data) {
        dispatch(_groupCheckRedeemedPassengerFail());
        return { success: false, message: 'No data from server' };
    }
    if (rs.data.success) {
        dispatch(_groupCheckRedeemedPassengerSuccess(rs));
        return { success: true, message: '' };
    } else {
        dispatch(_groupCheckRedeemedPassengerFail());
        return { success: false, message: rs.data.error.message };
    }
};

const _groupCheckRedeemedPassengerIsLoading = () => ({
    type: GROUP_CHECK_REDEEMED_PASSENGER_IS_LOADING
});

const _groupCheckRedeemedPassengerSuccess = rs => ({
    type: GROUP_CHECK_REDEEMED_PASSENGER_SUCCESS,
    rs
});

const _groupCheckRedeemedPassengerFail = () => ({
    type: GROUP_CHECK_REDEEMED_PASSENGER_FAIL
});

export const updateGroupRedemptionFormDuck = (k, v) => ({
    type: UPDATE_GROUP_REDEMPTION_FORM_DUCK,
    k,
    v
});

export const updateGroupRedemptionFormDuckObj = obj => ({
    type: UPDATE_GROUP_REDEMPTION_FORM_DUCK_OBJ,
    obj
});

const _mutipleActivateAndIssueCardIsLoading = () => ({
    type: MULTIPLE_ACTIVATE_AND_ISSUE_CARD_IS_LOADING
});

const _multipleActivateAndIssueCardSuccess = () => ({
    type: MULTIPLE_ACTIVATE_AND_ISSUE_CARD_SUCCESS
});

const _multipleActivateAndIssueCardFail = () => ({
    type: MULTIPLE_ACTIVATE_AND_ISSUE_CARD_FAIL
});

export const multipleActivateAndIssueCard = passengers => async dispatch => {
    dispatch(_mutipleActivateAndIssueCardIsLoading());
    const data = await MemberRepo.multipleActivateAndIssueCards(passengers);
    if (!data.success) {
        dispatch(_multipleActivateAndIssueCardFail());
        return data;
    } else {
        dispatch(_multipleActivateAndIssueCardSuccess());
        return data;
    }
};

const groupRedemptionFormDuck = (state = initState, action) => {
    let newPassenger, newPassengers, newRedeemedPassengers, k, v, obj, newState;

    switch (action.type) {
        case RESET_GROUP_REDEMPTION_FORM_REDUCER:
            return { ...initState };

        case GET_GROUP_AND_PASSENGERS_REDEMPTION_IS_LOADING:
            return { ...state, getGroupAndPassengersIsLoading: true };

        case GET_GROUP_AND_PASSENGERS_REDEMPTION_SUCCESS:
            const {
                data: {
                    result: {
                        group,
                        groupMetaInfo,
                        passengers,
                        tourLeader,
                        passengersWithMeta
                    }
                }
            } = action.rs;
            const acceptedPassengers = [];
            const rejectedPassengers = [];
            const redeemedPassengers = []
            passengers.forEach((passenger, i) => {
                passenger.collecting = false;
                if (passenger.physicalVoucher === null) {
                    passenger.physicalVoucher = ['', ''];
                }
                if (passenger.isDeleted === true) {
                    return;
                } else if (passenger.isRedeemed) {
                    redeemedPassengers.push(passenger);
                } else if (passenger.cardNo) {
                    // passenger.isRedeemed = true;
                    passenger.meta = passengersWithMeta[i];
                    rejectedPassengers.push(passenger);
                } else if (passenger.rejected !== true) {
                    // passenger.isRedeemed = false;
                    passenger.meta = passengersWithMeta[i];
                    acceptedPassengers.push(passenger);
                }
            });
            return {
                ...state,
                getGroupAndPassengersIsLoading: false,
                group,
                passengers: acceptedPassengers,
                rejectedPassengers: rejectedPassengers,
                redeemedPassengers,
                tourLeader,
                groupMetaInfo
            };

        case GET_GROUP_AND_PASSENGERS_REDEMPTION_FAIL:
            return { ...state, getGroupAndPassengersIsLoading: false };

        case GROUP_ISSUE_CARD_IS_LOADING:
            return { ...state, groupIssueCardIsLoading: true };

        case GROUP_ISSUE_CARD_SUCCESS: {
            let respData = action.payload;

            let clonedPassengers = [...state.passengers];
            let collectingPassengers = clonedPassengers.filter(
                e => e.collecting
            );
            debugger
            if (
                respData.result.length === 1 &&
                collectingPassengers.length &&
                !collectingPassengers[0].cardNo
            ) {
                // => single card
                let tourLeader = collectingPassengers.find(e => e.isTourLeader);
                if (!tourLeader) {
                    tourLeader = collectingPassengers[0];
                }
                tourLeader.cardNo = respData.result[0].cardNo;
                tourLeader.fullCardNo =
                    tourLeader.cardNo + CTP_CARD_DEFAULT_CCN;
                tourLeader.issuedVouchersList =
                    respData.result[0].voucherListsJson;
            } else {
                collectingPassengers.forEach((p, index) => {
                    if (!p.cardNo) {
                        p.cardNo = respData.result[index].cardNo;
                    }
                    if (p.cardNo) {
                        p.fullCardNo = p.cardNo + CTP_CARD_DEFAULT_CCN;
                    }
                    const temp = respData.result.find(
                        m => m.cardNo === p.cardNo
                    );
                    p.issuedVouchersList = temp && temp.voucherListsJson;
                });
            }

            return {
                ...state,
                passengers: clonedPassengers,
                groupIssueCardIsLoading: false
            };
        }
        case GROUP_ISSUE_CARD_FAIL:
            return { ...state, groupIssueCardIsLoading: false };

        case CREATE_GROUP_TRANSACTION_AND_ASSIGN_DIGITAL_CARDS_IS_LOADING:
            return {
                ...state,
                createGroupTransactionAndAssignDigitalCardsIsLoading: true
            };

        case CREATE_GROUP_TRANSACTION_AND_ASSIGN_DIGITAL_CARDS_SUCCESS: {
            let respData = action.payload;

            let clonedPassengers = [].concat(state.passengers);

            if (respData && respData.ctpDigitalCards) {
                clonedPassengers.forEach(p => {
                    let matchedPassenger = respData.ctpDigitalCards.find(
                        x => x.eTicket1 === p.eTicket1
                    );
                    if (matchedPassenger) {
                        p.cardNo = matchedPassenger.cardNo;
                    }
                });
            }
            let redemptionTransactionId = respData.redemptionTransactionId;
            return {
                ...state,
                createGroupTransactionAndAssignDigitalCardsIsLoading: false,
                passengers: clonedPassengers,
                redemptionTransactionId: redemptionTransactionId,
                createGroupTransactionAndAssignDigitalCardsCompleted: true
            };
        }
        case CREATE_GROUP_TRANSACTION_AND_ASSIGN_DIGITAL_CARDS_FAIL:
            return {
                ...state,
                createGroupTransactionAndAssignDigitalCardsIsLoading: false
            };

        case UPDATE_PASSENGERS:
            return { ...state, passengers: action.passengers };

        case UPDATE_GROUP_REDEMPTION_FORM_DATA:
            return {
                ...state,
                getGroupAndPassengersIsLoading: true,
                group: action.payload.group,
                collector: action.payload.collector,
                tourLeader: action.payload.tourLeader,
                passengers: action.payload.passengers,
                signatureUri: action.payload.signatureUri
            };

        case UPDATE_SELECTED_GROUP_ID:
            return {
                ...state,
                selectedGroupId: action.selectedGroupId
            };

        case UPDATE_GROUP_COLLECTOR_DETAILS:
            return { ...state, collector: { ...action.collector } };

        case GROUP_COLLECTOR_SIGNATURE_UPLOAD_IS_LOADING:
            return { ...state, groupCollectorSignatureUploadIsLoading: true };

        case GROUP_COLLECTOR_SIGNATURE_UPLOAD_SUCCESS:
            return {
                ...state,
                groupCollectorSignatureUploadIsLoading: false,
                signatureUri: action.rs.data.result[0]
            };

        case GROUP_COLLECTOR_SIGNATURE_UPLOAD_FAIL:
            return { ...state, groupCollectorSignatureUploadIsLoading: false };

        case GROUP_SQ_API_CHECK_IS_LOADING:
            return { ...state, groupSqApiCheckIsLoading: true };

        case GROUP_SQ_API_CHECK_SUCCESS:
            const groupSqApiCheckResults = action.rs.data.result.ticketDetails;
            // if any of the eticket has ctpEligibility `No`, put passenger into rejectedPassengers
            newPassengers = [...state.passengers];
            const keys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4'];
            let paxIdWithCtpEligibilityNo = [];

            const newRejectedPassengers = [...state.rejectedPassengers];
            let paxIdWhoHasFlown = new Set();
            let newPassengers2 = newPassengers.map((p, i) => {
                for (let k of keys) {
                    if (p[k] !== '') {
                        let rs = groupSqApiCheckResults.find(
                            t => t.ticketNumber === p[k]
                        );

                        let originalETicket = p.eTicket.find(
                            et => et.eTicketNo === rs.ticketNumber
                        );
                        if (
                            convertSqStatusForDisplay(rs.ctpEligibility) ===
                            DISPLAY_NO
                        ) {
                            newRejectedPassengers.push({ ...p });
                            paxIdWithCtpEligibilityNo.push(p.id);
                            return null;
                        } else {
                            p.ctpEligibility = rs.ctpEligibility;
                            p.etVerified = rs.etVerified;
                            p.collecting = true;
                            originalETicket.checkStatus = rs.checkStatus;
                            if (p.eTicket && p.eTicket[0]) {
                                p.eTicket[0].CheckStatus = rs.CheckStatus;
                            }
                        }
                        if (rs.isCheckPassengerFlown) {
                            paxIdWhoHasFlown.add(p.id);
                        }
                    }
                }
                return p;
            });
            newPassengers2 = newPassengers2.filter(x => x !== null);

            paxIdWithCtpEligibilityNo.forEach(id => {
                const idx = newPassengers2.findIndex(p => p.id === id);
                if (idx !== -1) newPassengers.splice(idx, 1);
            });

            return {
                ...state,
                groupSqApiCheckIsLoading: false,
                paxFlown: paxIdWhoHasFlown.size,
                passengers: newPassengers2,
                rejectedPassengers: newRejectedPassengers,
                groupSqApiCheckResults
            };

        case GROUP_SQ_API_CHECK_FAIL:
            return { ...state, groupSqApiCheckIsLoading: false };

        case UPDATE_COLLECTING_STATUS:
            if (action.idx !== undefined) {
                newPassenger = { ...state.passengers[action.idx] };
                newPassenger.collecting = !newPassenger.collecting;
                newPassenger.genPhysicalVoucherNo = null;
                newPassenger.lpcPhysicalVoucherNo = null;
                newPassenger.ctpVoucherValue = null;
                newPassenger.ctpGenValue = null;
                newPassenger.ctpLpcValue = null;
                newPassenger.physicalVoucherIssued = false;

                newPassengers = [...state.passengers];
                newPassengers.splice(action.idx, 1, newPassenger);
                return { ...state, passengers: newPassengers };
            }
            if (action.status !== undefined) {
                newPassengers = [...state.passengers];
                newPassengers.forEach(p => {
                    p.collecting = action.status;
                });
                return { ...state, passengers: newPassengers };
            }
            return state;

        case UPDATE_SINGLE_PAX:
            const { idx, key, value } = action;
            newPassenger = { ...state.passengers[idx] };
            newPassenger[key] = value;
            newPassengers = [...state.passengers];
            newPassengers.splice(idx, 1, newPassenger);
            return { ...state, passengers: newPassengers };

        case GROUP_CHECK_REDEEMED_PASSENGER_IS_LOADING:
            return { ...state, groupCheckRedeemedPassengerIsLoading: true };

        case GROUP_CHECK_REDEEMED_PASSENGER_SUCCESS:
            const {
                data: {
                    result: { items }
                }
            } = action.rs;

            newRedeemedPassengers = [...state.redeemedPassengers];
            if (items.length === 0) {
                return {
                    ...state,
                    groupCheckRedeemedPassengerIsLoading: false
                };
            }
            newPassengers2 = [];
            if (items.length > 0) {
                // find which pax the eticket belongs to and move to redeemedPassengers array
                newPassengers = [...state.passengers];
                for (let i = 0; i < newPassengers.length; i++) {
                    let redeemedPaxCount = 0,
                        redeemedPaxCountPrev = 0;
                    const keys = [
                        'eTicket1',
                        'eTicket2',
                        'eTicket3',
                        'eTicket4'
                    ];
                    keys.forEach(k => {
                        if (newPassengers[i][k] !== '') {
                            for (let e of items) {
                                if (newPassengers[i][k] === e.eTicketNo) {
                                    newPassengers[i].cardNo = e.cardNo;
                                    newPassengers[i].creationTime =
                                        e.creationTime;
                                    newRedeemedPassengers.push(
                                        newPassengers[i]
                                    );
                                    redeemedPaxCount++;
                                    break;
                                }
                            }
                        }
                    });
                    if (redeemedPaxCount === redeemedPaxCountPrev) {
                        newPassengers2.push(newPassengers[i]);
                    } else {
                        redeemedPaxCountPrev++;
                    }
                }
            }

            return {
                ...state,
                groupCheckRedeemedPassengerIsLoading: false,
                passengers: newPassengers2,
                redeemedPassengers: newRedeemedPassengers
            };

        case GROUP_CHECK_REDEEMED_PASSENGER_FAIL:
            return { ...state, groupCheckRedeemedPassengerIsLoading: false };

        case UPDATE_GROUP_REDEMPTION_FORM_DUCK:
            ({ k, v } = action);
            return { ...state, [k]: v };

        case UPDATE_GROUP_REDEMPTION_FORM_DUCK_OBJ:
            ({ obj } = action);
            newState = { ...state };
            newState = { ...spreadObj(newState, obj) };
            return newState;

        case CHECK_INACTIVE_CARDS_IS_LOADING:
            return { ...state, checkInactiveCardsIsLoading: true };

        case CHECK_INACTIVE_CARDS_FAIL:
            return { ...state, checkInactiveCardsIsLoading: false };

        case CHECK_INACTIVE_CARDS_SUCCESS:
            return { ...state, checkInactiveCardsIsLoading: false };

        case MULTIPLE_ACTIVATE_AND_ISSUE_CARD_IS_LOADING:
            return { ...state, multipleActivateAndIssueCardIsLoading: true };

        case MULTIPLE_ACTIVATE_AND_ISSUE_CARD_SUCCESS:
            return {
                ...state,
                multipleActivateAndIssueCardIsLoading: false,
                multipleActivateAndIssueCardComplete: true
            };

        case MULTIPLE_ACTIVATE_AND_ISSUE_CARD_FAIL:
            return {
                ...state,
                multipleActivateAndIssueCardIsLoading: false,
                multipleActivateAndIssueCardComplete: false
            };

        default:
            return state;
    }
};

export default groupRedemptionFormDuck;
