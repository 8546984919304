export const formDetailsListMockGenerator = (n = 1) => {
    const rs = []
    for (let i = 1; i < n + 1; i++) {
        rs.push(
            {
                pax: i,
                eTicketsDetails: {
                    eTicket1: '6182425386132',
                    eTicket2: '0861',
                    eTicket3: '0862',
                    eTicket4: '0863',
                    eTicket1LastName: 'aa',
                    eTicket2LastName: 'aa',
                    eTicket3LastName: 'aa',
                    eTicket4LastName: 'aa',
                    isETicketsVerified: false
                },
                personalDetails: {
                    firstName: 'aa',
                    lastName: 'KHAN',
                    dob: '2019-01-15'
                },
                sector1Details: {
                    flightNo: 'SQ107',
                    departingDate: '2019-01-21T00:00:00',
                    destination: 'Algeria'
                },
                sector2Details: {
                    landSeaScootBool: 'na',
                    flightNo: 'TR 109',
                    departingDate: '2019-01-15',
                    destination: 'Andorra'
                },
                isDeleted: false,
                ticketDetails: [
                    {
                        ticketNumber: '6182425386132',
                        flightNumber: 'SQ107',
                        departureDate: '2019-01-21T00:00:00',
                        nextDestination: 'HKT',
                        code: 'CSLAAA0000',
                        criterias: {
                            isFlightEligible: 'Yes',
                            haveTwoTouchPoint: 'Yes',
                            isWithinCampaign: 'Yes',
                            isThreeSector: 'No',
                            isFourSector: 'Yes',
                            isSameOrgDest: 'Yes',
                            isValidTransit: 'Yes',
                            isPaxSeated: 'Yes',
                            isValidOrigin: 'Yes',
                            isFlightConfirmed: 'Yes',
                            isUsedOrFlownFlight: 'Yes'
                        },
                        encodedCriteria: '11101111111',
                        ctpEligibility: 'Yes',
                        etVerified: 'Yes'
                    }
                ],
                ctpVoucherValue: 40,
                ctpGenValue: 20,
                ctpLpcValue: 20,
                cardNo: '',
                genPhysicalVoucherNo: '',
                lpcPhysicalVoucherNo: '',
                physicalVoucherIssued: false
            }
        )
    }

    return rs
}