import axios from 'axios'
import { getSettings } from '../config/config'
import { getCurrentAuthHeader } from '../helpers/utilities'
import { checkValidStatusCode } from '../helpers/httpHelper'

let settings = getSettings()
let baseURL = settings.baseURL

const initState = {
	cards: [],
}

const SAVE_CARD = 'SAVE_CARD'
const REQUEST_FOR_REPRINTING_SUCCESS = 'REQUEST_FOR_REPRINTING_SUCCESS'
const CREQUEST_FOR_REPRINTING_FAIL = 'CREQUEST_FOR_REPRINTING_FAIL'

export const saveCard = (cards) => ({
	type: SAVE_CARD,
	cards,
})

const _requestForReprintingSuccess = (rs) => ({
	type: REQUEST_FOR_REPRINTING_SUCCESS,
	rs,
})

const _requestForReprintingFail = () => ({
	type: CREQUEST_FOR_REPRINTING_FAIL,
})

export const requestForReprinting = (cards, redemptionTransactionId) => async (dispatch) => {
	const postBody = { cardList: cards, redemptionTransactionId: redemptionTransactionId }
	const rs = await axios.post(baseURL + '/Passenger/RequestForReprinting', postBody, getCurrentAuthHeader()).catch((e) => {
		if (!checkValidStatusCode(e.response.status)) {
			const {
				response: {
					data: {
						error: { message },
					},
				},
			} = e
			return { data: { success: false, error: { message } } }
		}
		return { data: { success: false, error: { message: e.response.status + ' Unexpected error' } } }
	})
	if (!rs.data) {
		dispatch(_requestForReprintingFail())
		return { success: false, message: 'No data from server' }
	}
	if (rs.data.success) {
		dispatch(_requestForReprintingSuccess(rs))
		return { success: true, message: '' }
	} else {
		dispatch(_requestForReprintingFail())
		return { success: false, message: rs.data.error.message }
	}
}

const printCardDuck = (state = initState, action) => {
	switch (action.type) {
		case SAVE_CARD:
			return { ...state, cards: action.cards }
		case REQUEST_FOR_REPRINTING_SUCCESS:
			return { ...state, cards: action.rs.data.result }
		default:
			return state
	}
}

export default printCardDuck
