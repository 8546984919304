import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    withStyles,
    Grid,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions
} from '@material-ui/core'
import Header from '../containers/Header.js'
import AdminSettingsNav from '../containers/adminSettings/AdminSettingsNav'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import StyledSection from '../components/StyledSection'
import StyledTable from '../components/StyledTable'
import {getAllCampaigns, updateCampaign, addCampaign, deleteCampaign} from '../ducks/campaignPeriodDuck'
import StyledSnackbar from '../components/StyledSnackbar'
import moment from 'moment'
import {Warning} from '@material-ui/icons'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import {toHumanFriendlyDate} from '../helpers/utilities'
import {hasPermissions} from '../helpers/utilities'
import {
    USER_ADMIN_CAMPAIGN_READ_PERMISSION, USER_ADMIN_CAMPAIGN_CREATE_PERMISSION, USER_ADMIN_CAMPAIGN_EDIT_PERMISSION
} from '../constants/permissions'

const TABLE_FIELDS = [
    {key: 'seq', label: 'S/N'},
    {key: 'startDate', label: 'Start Date'},
    {key: 'endDate', label: 'End Date'}
]

class CampaignPeriod extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            startDate: '',
            endDate: '',
            startDateError: '',
            endDateError: '',
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            editCampaign: {},
            deleteCampaign: {},
            showDeleteDialog: false,
            showDialog: false
        }
    }

    componentDidMount = async () => {
        document.title = `${TITLE_PREFIX} Admin settings - Campaign Period`

        const rs = await this.props.getAllCampaigns()
        if (!rs.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})

    }

    onAdd = async () => {
        const {startDate, endDate} = this.state
        if (this._validate(startDate, endDate)) {
            const data = {startDate, endDate}

            const rs = await this.props.addCampaign(data)
            if (rs.success) {
                this.setState({showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'Campaign added'})
                this.props.getAllCampaigns()
            } else {
                this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
            }
        }
    }

    _validate = (startDate, endDate, id) => {
        if (startDate === '' || endDate === '') {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Dates cannot be empty'})
            return false
        }
        if (moment(startDate).isAfter(moment(endDate))) {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'End date cannot be earlier than start date'})
            return false
        }

        // Check for overlaps
        const {campaignPeriodDuck: {campaigns}} = this.props
        let rs = true
        for (let c of campaigns) {
            if (id && c.id === id) { continue }
            const newStartDate = moment(startDate)
            const newEndDate = moment(endDate)
            const prevStartDate = moment(c.startDate)
            const prevEndDate = moment(c.endDate)
            if ((newStartDate.isSameOrBefore(prevEndDate) && newStartDate.isSameOrAfter(prevStartDate))
                || (prevStartDate.isSameOrBefore(newEndDate) && prevStartDate.isSameOrAfter(newStartDate))) {
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'error',
                    snackbarMessage: `New campaign overlaps with existing campaign ${toHumanFriendlyDate(prevStartDate)}, ${toHumanFriendlyDate(prevEndDate)}`
                })
                rs = false
                break
            }
        }
        return rs
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    _updateStartDate = (e) => {
        this.setState({startDate: e.target.value})
    }

    _updateEndDate = (e) => {
        this.setState({endDate: e.target.value})
    }

    _formatCampaigns = (campaignPeriods) => {
        let formattedCampaigns = []
        campaignPeriods.forEach(period => {
                let formattedPeriod = {
                    id: period.id,
                    startDate: moment(period.startDate).format('DD/MM/YYYY'),
                    endDate: moment(period.endDate).format('DD/MM/YYYY')
                }
                formattedCampaigns.push(formattedPeriod)
            }
        )
        return formattedCampaigns
    }

    onRowEdit = row => {
        const {campaignPeriodDuck: {campaigns}} = this.props
        const p = campaigns.find(c => c.id === row.id)
        this.setState({editCampaign: p, showDialog: true})
    }

    onRowDelete = row => {
        this.setState({deleteCampaign: row, showDeleteDialog: true})
    }

    onEditCancel = () => {
        this.setState({editCampaign: {}, showDialog: false})
    }

    onEditSave = async () => {
        const {editCampaign: {startDate, endDate, id}} = this.state
        if (this._validate(startDate, endDate, id)) {
            const data = {startDate, endDate, id}
            const rs = await this.props.updateCampaign(data)
            if (rs.success) {
                this.setState({
                    showSnackbar: true,
                    snackbarVariant: 'success',
                    snackbarMessage: 'Campaign updated',
                    showDialog: false,
                    editCampaign: {}
                })
                this.props.getAllCampaigns()
            } else {
                this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
            }
        }
    }

    onEditChange = (k, v) => {
        this.setState({editCampaign: {...this.state.editCampaign, [k]: v.target.value}})
    }

    onDeleteCancel = () => {
        this.setState({deleteCampaign: {}, showDeleteDialog: false})
    }

    onDeleteConfirm = async () => {
        const {deleteCampaign: {id}} = this.state
        const data = {data: {id}}
        const rs = await this.props.deleteCampaign(data)
        if (rs.success) {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'success',
                snackbarMessage: `Deleted campaign`,
                deleteCampaign: {},
                showDeleteDialog: false
            })
            this.props.getAllCampaigns()
        } else {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snackbarMessage: rs.message
            })
        }
    }

    render() {
        const {classes, campaignPeriodDuck} = this.props
        const {startDate, endDate, editCampaign, deleteCampaign} = this.state

        const hasEditPermission = hasPermissions([USER_ADMIN_CAMPAIGN_EDIT_PERMISSION])
        const hasCreatePermission = hasPermissions([USER_ADMIN_CAMPAIGN_CREATE_PERMISSION])
        const hasReadPermission = hasPermissions([USER_ADMIN_CAMPAIGN_READ_PERMISSION]) || hasEditPermission || hasCreatePermission

        return (
            <div>
                <GlobalTimeChecker/>

                <Header history={this.props.history}/>
                <AdminSettingsNav/>

                {/*{<editor-fold desc="Alert Dialog">}*/}
                {Object.keys(deleteCampaign).length > 0 &&
                <Dialog open={this.state.showDeleteDialog} onClose={this.onDeleteCancel}>
                    <DialogTitle>Proceed to delete the following campaign period:</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item container spacing={24}>
                                <Grid item md={3}>
                                    <Typography variant={'body1'}>Start date: </Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography variant={'body1'}>{deleteCampaign.startDate}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={24}>
                                <Grid item md={3}>
                                    <Typography variant={'body1'}>End date:</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography variant={'body1'}>{deleteCampaign.endDate}</Typography>
                                </Grid>

                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'} color={'secondary'}>
                                    <Warning className={classes.icon}/> This process cannot be reversed.
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color={'default'} variant='text' onClick={this.onDeleteCancel}>Cancel</Button>
                        <Button color={'secondary'} variant='contained' onClick={this.onDeleteConfirm}>Yes,
                            Delete</Button>
                    </DialogActions>
                </Dialog>}
                {/*{</editor-fold>}*/}

                {/*{<editor-fold desc="Edit Dialog">}*/}
                <Dialog open={this.state.showDialog} scroll={'body'} onClose={this.onEditCancel}>
                    <DialogTitle>Editing Period</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item>
                                <TextField id="from" label="From" margin="normal" variant="outlined"
                                    type={'date'}
                                    error={this.state.startDateError !== ''} helperText={this.state.startDateError}
                                    onChange={e => this.onEditChange('startDate', e)}
                                    value={moment(editCampaign.startDate).format('YYYY-MM-DD')}
                                    fullWidth/>
                            </Grid>
                            <Grid item>
                                <TextField id="to" label="To" margin="normal"
                                    type={'date'}
                                    error={this.state.endDateError !== ''}
                                    helperText={this.state.endDateError}
                                    onChange={e => this.onEditChange('endDate', e)}
                                    value={moment(editCampaign.endDate).format('YYYY-MM-DD')}
                                    variant="outlined" fullWidth/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color={'default'} variant='text' onClick={this.onEditCancel}>Cancel</Button>
                        <Button color={'primary'} variant='contained' onClick={this.onEditSave}>Save</Button>
                    </DialogActions>
                </Dialog>
                {/*{</editor-fold>}*/}

                <div className={classes.container}>
                    {hasReadPermission && <Grid container spacing={24} direction={'column'}>
                        <Grid item>
                            <StyledSection title={'Campaign Periods'}
                                isLoading={campaignPeriodDuck.getAllCampaignsIsLoading}>
                                {campaignPeriodDuck.campaigns.length > 0 &&
                                <Grid container item spacing={24} direction={'row'}>
                                    <Grid item xs={12}>
                                        <StyledTable
                                            fields={TABLE_FIELDS}
                                            rows={this._formatCampaigns(campaignPeriodDuck.campaigns)}
                                            isEditable={hasEditPermission}
                                            //isDeletable={hasEditPermission}
                                            onEdit={this.onRowEdit}
                                            //onDelete={this.onRowDelete}
                                        />
                                    </Grid>
                                </Grid>}
                            </StyledSection>
                        </Grid>

                        <Grid item>
                            {hasCreatePermission &&
                            <StyledSection title={'Add New Campaign Period'}>
                                <Grid container item spacing={24} direction={'row'}>
                                    <Grid item xs={3}>
                                        <TextField id="from" label="From" margin="normal" variant="outlined"
                                            type={'date'}
                                            value={startDate}
                                            onChange={this._updateStartDate}
                                            InputLabelProps={{shrink: true}} fullWidth/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField id="to" label="To" margin="normal" variant="outlined" type={'date'}
                                            value={endDate}
                                            onChange={this._updateEndDate}
                                            InputLabelProps={{shrink: true}} fullWidth/>
                                    </Grid>
                                    <Grid item xs={3} alignItems={'center'} container>
                                        <Button color={'primary'} variant='contained'
                                            onClick={this.onAdd}>Add campaign</Button>
                                    </Grid>
                                </Grid>
                            </StyledSection>}
                        </Grid>
                    </Grid>}
                </div>
                <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

const mapStateToProps = state => {
    const {campaignPeriodDuck} = state

    return {campaignPeriodDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        getAllCampaigns: () => dispatch(getAllCampaigns()),
        updateCampaign: data => dispatch(updateCampaign(data)),
        addCampaign: data => dispatch(addCampaign(data)),
        deleteCampaign: data => dispatch(deleteCampaign(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CampaignPeriod))