import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withStyles, Grid, Button, TextField, Typography} from '@material-ui/core'
import Header from '../containers/Header.js'
import AdminSettingsNav from '../containers/adminSettings/AdminSettingsNav'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import StyledSection from '../components/StyledSection'
import {
    getAllPhysicalVouchers, addPhysicalVouchers, deletePhysicalVoucher, updatePhysicalVouchers
} from '../ducks/physicalVoucherDuck'
import {getAllCampaigns} from '../ducks/campaignPeriodDuck'
import StyledSnackbar from '../components/StyledSnackbar'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import StyledSelect from '../components/StyledSelect'
import {toHumanFriendlyDate} from '../helpers/utilities'
import CardRangeHelper from '../helpers/cardRangeHelper'
import StyledTable from '../components/StyledTable'
import StyledGeneralDialog from '../components/StyledGeneralDialog'
import DeleteDialog from '../components/DeleteDialog'
import PageHelper from '../helpers/pageHelper'
import StyledPagination from '../components/StyledPagination'
import {getCurrentAuthHeader} from '../helpers/utilities'

const PHYSICAL_VOUCHERS_FIELDS = [{key: 'seq', label: 'S/N'}, {key: 'campaignPeriod', label: 'Campaign'},
    {key: 'voucherType', label: 'Voucher type'}, {key: 'voucherNo', label: 'Voucher no'}, {key: 'passengerId', label: 'status', render: v => !!v ? 'issued' : ''}]

class PhysicalVoucherScreen extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            campaign: {label: '', value: ''},
            filterCampaign: {label: 'ALL', value: ''},
            voucherType: {label: 'GEN', value: 'GEN'},
            filterVoucherType: {label: 'ALL', value: 'ALL'},
            voucherRange: '',
            showEditDialog: false,
            showDeleteDialog: false,
            editingVoucher: {},
            deletingVoucher: {},
            currentPage: 1,
            totalCount: 0
        }

        this.rowsPerPage = 100
        this.pageHelper = new PageHelper({maxResultCount: this.rowsPerPage})
    }

    componentDidMount = async () => {
        document.title = `${TITLE_PREFIX} Admin settings - Physical Vouchers`
        const rs2 = await this.props.getAllCampaigns()
        if (!rs2.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs2.message})

        /*
        let pageData = this.pageHelper.newCall()

        const data = {params: {MaxResultCount: pageData.maxResultCount, SkipCount: pageData.skipCount}, ...getCurrentAuthHeader()}
        const rs = await this.props.getAllPhysicalVouchers(data)
        if (!rs.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        if (rs.success) {
            this.pageHelper.populatePagingData({totalCount: rs.totalCount})
            this.setState({totalCount: this.pageHelper.getTotalCount()})
        }
        */
    };

    componentDidUpdate(prevProps, prevState) {

    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    _validate() {
        const {campaign, voucherRange} = this.state

        if (campaign.value === '') {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Campaign is required'})
            return false
        }
        if (voucherRange === '') {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Voucher range is required'})
            return false
        }
        if (/[^0-9\-,\s]/g.test(voucherRange)) {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Please enter a valid card range'})
            return false
        }
        return true
    }

    onSubmit = async () => {
        if (!this._validate()) return
        let cardRangeHelper = new CardRangeHelper({rangeString: this.state.voucherRange, fixedDigits: false})
        cardRangeHelper.prepareData()
        let cardRange = cardRangeHelper.generateRange()

        const {campaign, voucherType} = this.state
        let data = cardRange.map(n => {
            return {campaignId: campaign.value, voucherNo: n, voucherType: voucherType.value}
        })
        const rs = await this.props.addPhysicalVouchers({physicalVouchers: data})
        if (rs.success) {
            this.setState({showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'Physical vouchers added ', currentPage: 1})
        } else {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        }
    }

    _getAllPhysicalVouchers = async () => {
        const {filterCampaign, filterVoucherType} = this.state
        let data = {params: {}}
        if (filterCampaign.value) data.params.campaignId = filterCampaign.value
        if (filterVoucherType.value !== 'ALL') data.params.voucherType = filterVoucherType.value

        let pageData = this.pageHelper.pageCall(this.state.currentPage)
        data.params.MaxResultCount = pageData.maxResultCount
        data.params.SkipCount = pageData.skipCount

        data = {...data, ...getCurrentAuthHeader()}

        const rs = await this.props.getAllPhysicalVouchers(data)
        if (rs.success) {
            this.pageHelper.populatePagingData({totalCount: rs.totalCount})
            this.setState({totalCount: this.pageHelper.getTotalCount()})
        } else {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        }

    }

    campaignToSelectFriendly = campaigns => {
        return campaigns.map(c => {
            return {
                label: `${toHumanFriendlyDate(c.startDate)} - ${toHumanFriendlyDate(c.endDate)}`,
                value: c.id
            }
        })
    }

    filterCampaignOptions = (campaigns) => {
        const c = campaigns.map(c => {
            return {
                label: `${toHumanFriendlyDate(c.startDate)} - ${toHumanFriendlyDate(c.endDate)}`,
                value: c.id
            }
        })
        return [{label: 'ALL', value: ''}, ...c]
    }

    onCampaignChange = e => {
        this.setState({campaign: e})
    }

    onVoucherTypeChange = e => {
        this.setState({voucherType: e})
    }

    voucherTypeOptions = () => {
        const types = ['GEN', 'LPC']
        return types.map(t => ({label: t, value: t}))
    }

    filterVoucherTypeOptions = () => {
        const types = ['ALL', 'GEN', 'LPC']
        return types.map(t => ({label: t, value: t}))
    }

    onVoucherRangeChange = e => {
        this.setState({voucherRange: e.target.value})
    }

    onEditPhysicalVoucher = row => {
        this.setState({showEditDialog: true, editingVoucher: row})
    }

    onEditClose = () => {
        this.setState({showEditDialog: false})
    }

    _formatCampaignToSelectFriendly = physicalVoucher => {
        if (physicalVoucher.startDate.indexOf('T') !== -1) {
            return {
                label: `${toHumanFriendlyDate(physicalVoucher.startDate)} - ${toHumanFriendlyDate(physicalVoucher.endDate)}`,
                value: physicalVoucher.campaignId
            }
        } else {
            return {
                label: `${physicalVoucher.startDate} - ${physicalVoucher.endDate}`,
                value: physicalVoucher.campaignId
            }
        }
    }

    onEditingCampaignChange = e => {
        const {editingVoucher} = this.state
        const [startDate, endDate] = e.label.split('-').map(e => e.trim())
        this.setState({
            editingVoucher: {
                ...editingVoucher,
                campaignId: e.value,
                startDate: startDate,
                endDate: endDate
            }
        })
    }

    onEditingVoucherTypeChange = e => {
        const {editingVoucher} = this.state
        this.setState({editingVoucher: {...editingVoucher, voucherType: e.value}})
    }

    _formatVoucherToSelectFriendly = physicalVoucher => {
        return {label: physicalVoucher.voucherType, value: physicalVoucher.voucherType}
    }

    onDeletePhysicalVoucher = row => {
        this.setState({showDeleteDialog: true, deletingVoucher: row})
    }

    onEditingVoucherChange = e => {
        const {editingVoucher} = this.state
        this.setState({editingVoucher: {...editingVoucher, voucherNo: e.target.value}})
    }

    onEditSave = async () => {
        const {editingVoucher: {id, campaignId, voucherNo, voucherType}} = this.state

        const data = {id, campaignId, voucherNo, voucherType}
        const rs = await this.props.updatePhysicalVouchers(data)
        if (rs.success) {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: 'Voucher updated'})
            this.props.getAllPhysicalVouchers()
        } else {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        }
    }

    onDeleteCancel = () => {
        this.setState({showDeleteDialog: false})
    }

    onDeleteConfirm = async () => {
        const {deletingVoucher} = this.state
        const rs = await this.props.deletePhysicalVoucher(deletingVoucher.id)
        if (rs.success) {
            this.setState({showDeleteDialog: false})
            this.setState({showSnackbar: true, snackbarVariant: 'success', snackbarMessage: 'Voucher deleted'})
            this.props.getAllPhysicalVouchers()

        } else {
            this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        }
    }

    onChangePage = (e, page) => {
        switch (page) {
            case 'first':
                this.setState({currentPage: 1})
                this.pageHelper.pageCall(1)
                this.setState({currentPage: 1},
                    () => {this._getAllPhysicalVouchers()})
                break

            case 'prev':
                this.pageHelper.pageCall(this.state.currentPage - 1)
                this.setState({currentPage: this.state.currentPage - 1},
                    () => {this._getAllPhysicalVouchers()})
                break

            case 'next':
                this.pageHelper.pageCall(this.state.currentPage + 1)
                this.setState({currentPage: this.state.currentPage + 1},
                    () => {this._getAllPhysicalVouchers()})
                break

            case 'last':
                const lastPage = this.pageHelper.getTotalNoOfPages()
                this.pageHelper.pageCall(lastPage)
                this.setState({currentPage: lastPage},
                    () => {this._getAllPhysicalVouchers()})
                break

            default:
                this.setState({currentPage: page},
                    () => {this._getAllPhysicalVouchers()})
        }
    }

    onFilterVoucherTypeChange = e => {
        this.setState({filterVoucherType: e, currentPage: 1}, () => {this._getAllPhysicalVouchers()})
    }

    onFilterCampaignChange = e => {
        this.setState({filterCampaign: e, currentPage: 1}, () => {this._getAllPhysicalVouchers()})
    }

    _formatPhysicalVouchers = physicalVouchers => (
        physicalVouchers.map(p => {
            if(p.campaign){
                p.campaignPeriod = `${toHumanFriendlyDate(p.campaign.startDate)} - ${toHumanFriendlyDate(p.campaign.endDate)}`
            }
            return p
        })
    )

    render() {
        const {
            classes, physicalVoucherDuck: {
                getAllPhysicalVouchersIsLoading, addPhysicalVouchersIsLoading, physicalVouchers, deletePhysicalVoucherIsLoading,
                updatePhysicalVoucherIsLoading
            },
            campaignPeriodDuck: {campaigns}
        } = this.props
        const {
            campaign, voucherType, voucherRange, showEditDialog, editingVoucher, showDeleteDialog, deletingVoucher,
            currentPage, totalCount, filterCampaign, filterVoucherType
        } = this.state
        const isLoading = getAllPhysicalVouchersIsLoading || addPhysicalVouchersIsLoading || updatePhysicalVoucherIsLoading || deletePhysicalVoucherIsLoading

        return (
            <div>
                <GlobalTimeChecker/>
                <Header history={this.props.history}/>
                <AdminSettingsNav/>

                <div className={classes.container}>
                    <DeleteDialog dialogTitle={'Deleting voucher'} showDeleteDialog={showDeleteDialog}
                        onDeleteCancel={this.onDeleteCancel} onDeleteConfirm={this.onDeleteConfirm}>
                        {Object.keys(deletingVoucher).length > 0 && <Grid container spacing={24} direction={'column'}>
                            <Grid item>
                                The following voucher will be deleted
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>
                                    Campaign id
                                </Grid>
                                <Grid item>
                                    {deletingVoucher.campaignId}
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>
                                    Start date
                                </Grid>
                                <Grid item>
                                    {toHumanFriendlyDate(deletingVoucher.startDate)}
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>
                                    End date
                                </Grid>
                                <Grid item>
                                    {toHumanFriendlyDate(deletingVoucher.endDate)}
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>
                                    Voucher type
                                </Grid>
                                <Grid item>
                                    {deletingVoucher.voucherType}
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>
                                    Voucher no
                                </Grid>
                                <Grid item>
                                    {deletingVoucher.voucherNo}
                                </Grid>
                            </Grid>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>
                                    Passenger id
                                </Grid>
                                <Grid item>
                                    {deletingVoucher.passengerId}
                                </Grid>
                            </Grid>
                        </Grid>}
                    </DeleteDialog>

                    <StyledGeneralDialog actionText={'Save'} title={'Editing voucher'} showDialog={showEditDialog}
                        onAction={this.onEditSave} onClose={this.onEditClose}
                    >
                        {Object.keys(editingVoucher).length > 0 && <Grid container spacing={24} direction={'column'}>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>
                                    Campaign
                                </Grid>
                                <Grid item>
                                    <StyledSelect
                                        value={this._formatCampaignToSelectFriendly(editingVoucher)}
                                        className={classes.select}
                                        options={this.campaignToSelectFriendly(campaigns)}
                                        onChange={e => this.onEditingCampaignChange(e)}
                                        label={'Campaign'}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>
                                    Voucher type
                                </Grid>
                                <Grid item md={9}>
                                    <StyledSelect value={this._formatVoucherToSelectFriendly(editingVoucher)}
                                        className={classes.select}
                                        options={this.voucherTypeOptions()}
                                        onChange={e => this.onEditingVoucherTypeChange(e)}
                                        label={'Voucher type'}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>
                                    Voucher no
                                </Grid>
                                <Grid item md={9}>
                                    <TextField id="" label="" margin="normal" variant="outlined"
                                        value={editingVoucher.voucherNo} onChange={this.onEditingVoucherChange}/>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </StyledGeneralDialog>

                    <StyledSection title={'Add Physical Vouchers'} isLoading={isLoading}>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>
                                    Campaign
                                </Grid>
                                <Grid item md={9}>
                                    <StyledSelect value={campaign}
                                        className={classes.select}
                                        options={this.campaignToSelectFriendly(campaigns)}
                                        onChange={e => this.onCampaignChange(e)}
                                        label={'Campaign'}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>
                                    Voucher type
                                </Grid>
                                <Grid item md={9}>
                                    <StyledSelect value={voucherType}
                                        className={classes.select}
                                        options={this.voucherTypeOptions()}
                                        onChange={e => this.onVoucherTypeChange(e)}
                                        label={'Voucher type'}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item md={3}>
                                    Voucher no range
                                </Grid>
                                <Grid item md={9}>
                                    <TextField id="" label="" margin="normal" variant="outlined" value={voucherRange}
                                        onChange={this.onVoucherRangeChange}/>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Button color={'primary'} variant="contained" onClick={this.onSubmit}>Submit</Button>
                            </Grid>
                        </Grid>
                    </StyledSection>

                    {/*
                    <StyledSection title={'Physical Vouchers'} isLoading={isLoading}>
                        <Grid container spacing={24} direction={'column'}>
                            <Grid item>
                                <Typography variant={'body1'}>Filters</Typography>
                            </Grid>

                            <Grid container item spacing={24} alignItems={'center'}>
                                <Grid item>
                                    Campaign
                                </Grid>
                                <Grid item>
                                    <StyledSelect value={filterCampaign}
                                        className={classes.select}
                                        options={this.filterCampaignOptions(campaigns)}
                                        onChange={e => this.onFilterCampaignChange(e)}
                                        label={'Campaign'}
                                    />
                                </Grid>
                                <Grid item>
                                    Voucher type
                                </Grid>
                                <Grid item>
                                    <StyledSelect value={filterVoucherType}
                                        className={classes.select}
                                        options={this.filterVoucherTypeOptions()}
                                        onChange={e => this.onFilterVoucherTypeChange(e)}
                                        label={'Voucher type'}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>

                        <StyledTable
                            rows={this._formatPhysicalVouchers(physicalVouchers)}
                            fields={PHYSICAL_VOUCHERS_FIELDS}
                            // isEditable
                            // isDeletable
                            // onEdit={this.onEditPhysicalVoucher}
                            // onDelete={this.onDeletePhysicalVoucher}
                            hasPagination
                            onChangePage={this.onChangePage}
                            rowsPerPage={this.rowsPerPage}
                            page={currentPage - 1}
                            count={totalCount}
                            ActionsComponent={StyledPagination}
                        />
                    </StyledSection>
                    */}

                    <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                        message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
                </div>
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container},
    select: {width: '20rem', marginTop: '.3rem'}
})

const mapStateToProps = state => {
    const {physicalVoucherDuck, campaignPeriodDuck} = state

    return {physicalVoucherDuck, campaignPeriodDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        getAllPhysicalVouchers: data => dispatch(getAllPhysicalVouchers(data)),
        addPhysicalVouchers: data => dispatch(addPhysicalVouchers(data)),
        getAllCampaigns: () => dispatch(getAllCampaigns()),
        deletePhysicalVoucher: id => dispatch(deletePhysicalVoucher(id)),
        updatePhysicalVouchers: data => dispatch(updatePhysicalVouchers(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PhysicalVoucherScreen))