import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {
    withStyles,
    Grid,
    Fab,
    Typography
} from '@material-ui/core'
import {
   Group, Settings
} from '@material-ui/icons'
import Header from '../containers/Header.js'
import GroupPane from '../containers/dashboard/GroupPane'
import StyledSection from '../components/StyledSection'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import {hasPermissions} from '../helpers/utilities'
import {
    GROUP_APPROVER_PERMISSION, GROUP_APPROVER_READ_ONLY_PERMISSION, GROUP_ISSUER_PERMISSION,
    GROUP_ISSUER_READ_ONLY_PERMISSION, GROUP_CREATOR_PERMISSION, GROUP_CREATOR_WITH_READ_ALL_PERMISSION,
    USER_ADMIN_ACCOUNT_CREATE_PERMISSION, USER_ADMIN_ACCOUNT_EDIT_PERMISSION, USER_ADMIN_ACCOUNT_READ_PERMISSION,
    USER_ADMIN_BANNER_CREATE_PERMISSION, USER_ADMIN_BANNER_EDIT_PERMISSION, USER_ADMIN_BANNER_READ_PERMISSION,
    USER_ADMIN_CAMPAIGN_CREATE_PERMISSION, USER_ADMIN_CAMPAIGN_EDIT_PERMISSION, USER_ADMIN_CAMPAIGN_READ_PERMISSION,
    USER_ADMIN_ROLE_CREATE_PERMISSION, USER_ADMIN_ROLE_EDIT_PERMISSION, USER_ADMIN_ROLE_READ_PERMISSION
} from '../constants/permissions'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import SpreadSheetComponent from "../components/SpreadSheetComponent"

class Dashboard extends React.Component {

    componentDidMount() {
        document.title = `${TITLE_PREFIX} Dashboard`
    }

    render() {
        const {classes, groupApprovalDuck: {getAllGroupIsLoading}, groupStatsDuck: {getGroupStatsIsLoading}} = this.props
        const hasGroupApproverPermission = hasPermissions([GROUP_APPROVER_PERMISSION])

        const hasAdminPermissions = hasPermissions([USER_ADMIN_CAMPAIGN_READ_PERMISSION, USER_ADMIN_CAMPAIGN_CREATE_PERMISSION,
            USER_ADMIN_CAMPAIGN_EDIT_PERMISSION, USER_ADMIN_ACCOUNT_READ_PERMISSION, USER_ADMIN_ACCOUNT_CREATE_PERMISSION,
            USER_ADMIN_ACCOUNT_EDIT_PERMISSION, USER_ADMIN_ROLE_READ_PERMISSION, USER_ADMIN_ROLE_CREATE_PERMISSION,
            USER_ADMIN_ROLE_EDIT_PERMISSION, USER_ADMIN_BANNER_READ_PERMISSION, USER_ADMIN_BANNER_CREATE_PERMISSION, USER_ADMIN_BANNER_EDIT_PERMISSION], 'or')

        const hasGroupPermissions = hasPermissions([GROUP_ISSUER_PERMISSION, GROUP_ISSUER_READ_ONLY_PERMISSION, GROUP_CREATOR_PERMISSION, GROUP_CREATOR_WITH_READ_ALL_PERMISSION, GROUP_APPROVER_PERMISSION, GROUP_APPROVER_READ_ONLY_PERMISSION], 'or')

        return (
            <div>
                <GlobalTimeChecker/>
                <Header history={this.props.history}/>

                <div className={classes.container}>
                    <Grid container item spacing={24} direction={'column'}>
                        {hasGroupPermissions && <Grid item>
                            <StyledSection title={'Group'} isLoading={getAllGroupIsLoading}>
                                <Grid container spacing={24} direction={'column'}>
                                    <Grid item>
                                        <GroupPane history={this.props.history}/>
                                    </Grid>
                                </Grid>
                            </StyledSection>
                        </Grid>}
                    </Grid>
                </div>

            </div>
        )
    }
}

const mapStateToProps = function (state) {
    const {permissionDuck, groupApprovalDuck, groupStatsDuck} = state

    return {
        permissionDuck, groupApprovalDuck, groupStatsDuck
    }
}

const mapDispatchToProps = function (dispatch) {
    return {}
}

const styles = theme => ({
    container: theme.container,
    paper: {...theme.paper, marginTop: '1em'},
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridGap: '1em',
        textAlign: 'center',
        margin: '5em 1em'
    },
    noUnderline: {textDecoration: 'none'},
    title: {margin: '.5em auto'}
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard))