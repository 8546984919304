import React from "react";
import {Modal, Button, withStyles} from "@material-ui/core";
import {Cancel} from "@material-ui/icons";
import PropTypes from 'prop-types'


class PromiseModal extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        const { classes, modalOpen } = this.props;
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={modalOpen}
            >
                <div className={classes.modal}>
                    <div className={classes.content}>
                        <div className={classes.children}>
                            {this.props.children}
                        </div>

                    </div>
                </div>
            </Modal>
        );
    }

}


PromiseModal.propTypes = {
    modalOpen: PropTypes.bool.isRequired,
}

const styles = theme => ({
    modal: {
        position: 'absolute',
        width: '60%',
        height: '200px',
        backgroundColor: 'white',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto'

    },
    container: {
        height: '100%',
        overflowY: 'auto',
        boxSizing: 'border-box',
    },
    content: {
        marginTop: "20px",
        align: "center",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center"
    }
});


export default withStyles(styles)(PromiseModal)