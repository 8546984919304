import React, {Component} from 'react'
import {connect} from 'react-redux'
import Header from '../containers/Header'
import {withStyles} from '@material-ui/core'
import GroupRedemptionForm from '../containers/group/GroupRedemptionForm'
import GroupHeader from '../containers/group/GroupHeader.js'
import {TITLE_PREFIX} from '../constants/titlePrefix'
import GlobalTimeChecker from '../containers/GlobalTimeChecker'
import moment from 'moment'
import {getFlightsData, updateFlights} from '../ducks/flightDuck'
import StyledSnackbar from '../components/StyledSnackbar'
import {updateGroupRedemptionFormDuckObj} from '../ducks/groupRedemptionFormDuck'

class GroupSubmissionDetail extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            showSnackbar: false,
            snackbarMessage: '',
            snackbarVariant: 'success'
        }
    }

    componentDidMount = async () => {
        document.title = `${TITLE_PREFIX} Group - Group Issuance ${this.props.match.params.id}`

        let flightDataWithMetaStr = await localStorage.getItem('FLIGHT_DATA')
        let flightDataWithMeta = {}
        if (flightDataWithMetaStr) {
            flightDataWithMeta = JSON.parse(flightDataWithMetaStr)
            let today = moment().format('YYYY-MM-DD')
            if (flightDataWithMeta.flightData && flightDataWithMeta.dateCreated === today) {
                this.props.updateFlights(flightDataWithMeta.flightData)
            } else {
                const rs = await this.props.getFlightsData()
                if (!rs.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
            }
        } else {
            const rs = await this.props.getFlightsData()
            if (!rs.success) this.setState({showSnackbar: true, snackbarVariant: 'error', snackbarMessage: rs.message})
        }

        // clear signature
        const obj = {collector: {csoSignatureBase64: ''}}
        this.props.updateGroupRedemptionFormDuckObj(obj)
    }

    closeSnackbar = () => {
        this.setState({showSnackbar: false})
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.flightData !== this.props.flightData && this.props.flightData != null) {
            if (this.props.flightData.length > 0) {
                let today = moment().format('YYYY-MM-DD')
                let flightDataWithMeta = {
                    dateCreated: today,
                    flightData: this.props.flightData
                }
                let flightsDataWithMetaStr = JSON.stringify(flightDataWithMeta)
                localStorage.setItem('FLIGHT_DATA', flightsDataWithMetaStr)
            }
        }
    }

    render() {
        const {match} = this.props
        return (
            <div>
                <GlobalTimeChecker/>

                <Header history={this.props.history}/>
                <GroupHeader/>

                <GroupRedemptionForm
                    groupRefNo={match.params.id}
                    location={this.props.location}
                    history={this.props.history}
                />

                <StyledSnackbar open={this.state.showSnackbar} onClose={this.closeSnackbar}
                    message={this.state.snackbarMessage} variant={this.state.snackbarVariant}/>
            </div>
        )
    }
}

const styles = theme => ({
    container: {...theme.container}
})

const mapStateToProps = state => {
    const {flightDuck: {flightData}, groupRedemptionFormDuck} = state
    return {flightData, groupRedemptionFormDuck}
}

const mapDispatchToProps = dispatch => {
    return {
        getFlightsData: () => dispatch(getFlightsData()),
        updateFlights: (flights) => dispatch(updateFlights(flights)),
        updateGroupRedemptionFormDuckObj: obj => dispatch(updateGroupRedemptionFormDuckObj(obj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupSubmissionDetail))