import moment from 'moment'
import { initFormDetails } from '../../../ducks/individualFormDuck'
import { convertSqStatusForDisplay, DISPLAY_NO } from '../../../helpers/eticketStatusHelper'
import { checkBonusCities } from '../../../helpers/voucherBonusHelper'
import {NA, SCOOT} from '../../../constants/sector2Options.js'

export const validateCurrentPax = ({ currentPaxFormDetails, formDetailsList, campaignPeriods, files }) => {
	// remove own detailList to prevent false duplication error
	formDetailsList = formDetailsList.filter(p => p.pax !== currentPaxFormDetails.pax)

	// check whether form is blank for subsequent users
	if (formDetailsList.length > 0) {
		let isBlankForm = checkBlankForm(currentPaxFormDetails, initFormDetails)
		if (isBlankForm) return { success: true, message: 'No need to validate blank form' }
	}

	const { eTicketsDetails, personalDetails, sector1Details, sector2Details, sector3Details, sector4Details, ticketDetails, isFirstPax } = currentPaxFormDetails

	let nonDeletedFormDetailsList = formDetailsList.filter(x => x.isDeleted === false)
	const existingETicketsDetailsList = nonDeletedFormDetailsList.map(formDetails => formDetails.eTicketsDetails)

	if (!eTicketsDetails.eTicket1 || eTicketsDetails.eTicket1 === '') {
		return { success: false, message: 'Please fill up at least one ETicket' }
	}

	if (!eTicketsDetails.isETicketsVerified) {
		return { success: false, message: 'Please check eligibility of ETicket to ensure it is not used before' }
	}

	if (!_validateCurrentPaxETixDuplicate(eTicketsDetails)) {
		return { success: false, message: 'Please ensure no E Tickets duplicate' }
	}

	if (!_validatePreviousPaxETixDuplicate(eTicketsDetails, existingETicketsDetailsList)) {
		return { success: false, message: 'Please ensure no E Tickets duplicate' }
	}

	let personalKeys = ['dob', 'firstName', 'lastName']
	for (let i = 0; i < personalKeys.length; i++) {
		if (!personalDetails[personalKeys[i]] || personalDetails[personalKeys[i]] === '') {
			return { success: false, message: 'Please fill up ' + personalKeys[i] }
		}
	}

	let twoYearsAgo = moment().subtract(2, 'years')
	if (moment(personalDetails.dob).isAfter(twoYearsAgo)) {
		return { success: false, message: 'Date of birth must be at least 2 years' }
	}

	let sector1Keys = ['flightNo', 'departingDate', 'destination', 'origin'];
	for (let i = 0; i < sector1Keys.length; i++) {
		if (!sector1Details[sector1Keys[i]] || sector1Details[sector1Keys[i]] === '') {
			return { success: false, message: 'Please fill up Sector 1 ' + sector1Keys[i] }
		}
	}


	if(sector2Details.landSeaScootBool === NA || sector2Details.landSeaScootBool === SCOOT){
        let sector2Keys = ['flightNo', 'destination', 'departingDate'];
        for (let i = 0; i < sector2Keys.length; i++) {
            if (!sector2Details[sector2Keys[i]] || sector2Details[sector2Keys[i]] === '') {
                return { success: false, message: 'Please fill up Sector 2 ' + sector2Keys[i] }
            }
        }
	}

	//check departingDate for sector 2 is not earlier than sector 1
	if(sector2Details.departingDate){
        if (moment(sector2Details.departingDate).isBefore(sector1Details.departingDate)) {
            return { success: false, message: 'Sector 2 departing date must not be earlier than sector 1 departing date' }
        }
	}

	let firstPax = formDetailsList[0];
	// first pax
	if (isFirstPax == true || !firstPax) {
		let subString = eTicketsDetails.eTicket1.substr(0, 3);
		if (subString !== '086' && subString !== '081') {
			let numberOfSector1stPax = currentPaxFormDetails.numberOfSector;
			if (numberOfSector1stPax == 4) {
				if (!sector3Details.flightNo || !sector4Details.flightNo) {
					return { success: false, message: `Number of sector must be ${numberOfSector1stPax}` }
				}
			} else if (numberOfSector1stPax == 2) {
				if (sector3Details.flightNo || sector4Details.flightNo) {
					return { success: false, message: `Number of sector must be ${numberOfSector1stPax}` }
				}
			}
		}
	} 
	// is not first pax
	else {
		// is not 1st pax
		// get 1st pax
		let numberOfSector1stPax = firstPax.numberOfSector;
		// compare number of sector with 1st pax
		if (numberOfSector1stPax !== currentPaxFormDetails.numberOfSector) {
			return { success: false, message: `All passengers have to same number of sector of first passenger.` }
		}
		// validate number of sector base on number of sector of 1st pax
		if (numberOfSector1stPax == 4) {
			if (!sector3Details.flightNo || !sector4Details.flightNo) {
				return { success: false, message: `Number of sector must be ${numberOfSector1stPax}` }
			}
		} else if (numberOfSector1stPax == 2) {
			if (sector3Details.flightNo || sector4Details.flightNo) {
				return { success: false, message: `Number of sector must be ${numberOfSector1stPax}` }
			}
		}
	}

	// check if in case flightNo for sector 3 and 4 fill up
	if (sector3Details.flightNo || sector4Details.flightNo) {
		// check departingDate for sector 3 is not blank
		if(sector3Details.landSeaScootBool === NA || sector3Details.landSeaScootBool === SCOOT){
			let sector3Keys = ['flightNo', 'destination', 'departingDate'];
			for (let i = 0; i < sector3Keys.length; i++) {
				if (!sector2Details[sector3Keys[i]] || sector2Details[sector3Keys[i]] === '') {
					return { success: false, message: 'Please fill up Sector 3 ' + sector3Keys[i] }
				}
			}
		}
		// check departingDate for sector 4 is not blank
		let sector4Keys = ['flightNo', 'departingDate', 'destination', 'origin'];
		for (let i = 0; i < sector4Keys.length; i++) {
			if (!sector4Details[sector4Keys[i]] || sector4Details[sector4Keys[i]] === '') {
				return { success: false, message: 'Please fill up Sector 4 ' + sector4Keys[i] }
			}
		}
		// check departingDate for sector 3 is not earlier than sector 2
		if (moment(sector3Details.departingDate).isBefore(sector2Details.departingDate)) {
            return { success: false, message: 'Sector 3 departing date must not be earlier than sector 2 departing date' }
        }
		// check departingDate for sector 4 is not earlier than sector 3
		if (moment(sector4Details.departingDate).isBefore(sector3Details.departingDate)) {
            return { success: false, message: 'Sector 4 departing date must not be earlier than sector 3 departing date' }
        }
	}


	if (sector1Details.origin && sector1Details.origin === sector2Details.destination) {

		return { success: false, message: 'Round trip is not allowed. Please verify Sector 1 Origin and Sector 2 Destination value cannot be the same.' }
	}

	// check eligibility of eEtickets
	let ticketKeys = ['eTicket1', 'eTicket2', 'eTicket3', 'eTicket4']
	let eligibleInitials = ['618', '629', '086', '081', '000']
	for (let i = 0; i < ticketKeys.length; i++) {
		if (!eTicketsDetails[ticketKeys[i]]) continue
		let checkElgibleInitials = false
		eligibleInitials.forEach(initial => {
			if (eTicketsDetails[ticketKeys[i]].substr(0, 3) === initial) checkElgibleInitials = true
		})

		if (!checkElgibleInitials) {
			return { success: false, message: 'Please ensure eligible E Ticket No' }
		}
	}

	if (!_validateCampaignPeriod(currentPaxFormDetails, campaignPeriods)) {
		return { success: false, message: 'Dates are not within campaignPeriod' }
	}

	if (!_validateAttachmentRequired(sector2Details, ticketDetails, files)) {
		return { success: false, message: 'Please complete land/sea/scoot section' }
	}

	for (const t of currentPaxFormDetails.ticketDetails) {
		if (convertSqStatusForDisplay(t.ctpEligibility) === DISPLAY_NO) {
			return { success: false, message: `E-ticket ${t.ticketNumber} is not eligible. Please fix and check eligibility again.` }
		}

		if (t.isFromSpecialBoardPointCountry) {
			if (sector1Details.origin && !checkBonusCities(sector1Details.origin)) {
				return { success: false, message: `E-ticket ${t.ticketNumber} is from Special Board Point according to SQ API. Please check the city of origin.` }
			}
		}
	}

	//if there all is valid, return true
	return { success: true, message: 'All check pass' }
}

let _validateCampaignPeriod = (currentPax, campaignPeriods) => {
	let dateKeys = ['departingDate']
	let campaignPeriod = { startDate: '', endDate: '' }
	let now = moment().startOf('day')
	const prev = moment(now).subtract(1, 'days')
	const next = moment(now).add(1, 'days')
	const campaignsToCheck = { prev: '', now: '', next: '' }

	// earliest date first
	campaignPeriods.sort((prev, next) => {
		if (
			moment(prev.startDate)
				.startOf('day')
				.isBefore(moment(next.startDate).startOf('day'))
		) {
			return -1
		}
		if (
			moment(prev.startDate)
				.startOf('day')
				.isAfter(moment(next.startDate).startOf('day'))
		) {
			return 1
		}
		return 0
	})

	for (let i = 0; i < campaignPeriods.length; i++) {
		if (now.isSameOrAfter(moment(campaignPeriods[i].startDate).startOf('day')) && now.isSameOrBefore(moment(campaignPeriods[i].endDate).startOf('day'))) {
			campaignsToCheck.now = campaignPeriods[i]

			if (campaignPeriods[i - 1]) {
				if (prev.isSame(moment(campaignPeriods[i - 1].endDate).startOf('day'))) {
					campaignsToCheck.prev = campaignPeriods[i - 1]
				}
			}

			if (campaignPeriods[i + 1]) {
				if (next.isSame(moment(campaignPeriods[i + 1].startDate).startOf('day'))) {
					campaignsToCheck.next = campaignPeriods[i + 1]
				}
			}
			break
		}
	}

	for (let k of ['prev', 'now', 'next']) {
		if (campaignsToCheck[k] !== '') {
			if (campaignPeriod.startDate === '') {
				campaignPeriod.startDate = campaignsToCheck[k].startDate
			}
			campaignPeriod.endDate = campaignsToCheck[k].endDate
		}
	}

	if (!campaignPeriod.startDate) return false

	const { sector1Details, sector2Details } = currentPax

	if (campaignPeriod) {
		for (let i = 0; i < dateKeys.length; i++) {
			if (
				moment(sector1Details[dateKeys[i]]).isBefore(moment(campaignPeriod.startDate).startOf('day')) ||
				moment(sector1Details[dateKeys[i]]).isAfter(moment(campaignPeriod.endDate).startOf('day'))
			) {
				return false
			}

			if (
				moment(sector2Details[dateKeys[i]]).isBefore(moment(campaignPeriod.startDate).startOf('day')) ||
				moment(sector2Details[dateKeys[i]]).isAfter(moment(campaignPeriod.endDate).startOf('day'))
			) {
				return false
			}
		}
	}
	return true
}

let _validateCurrentPaxETixDuplicate = eTixObj => {
	if (eTixObj.eTicket4) {
		if (eTixObj.eTicket4 === eTixObj.eTicket1 || eTixObj.eTicket4 === eTixObj.eTicket2 || eTixObj.eTicket4 === eTixObj.eTicket3) {
			return false
		}
	}
	if (eTixObj.eTicket3) {
		if (eTixObj.eTicket3 === eTixObj.eTicket1 || eTixObj.eTicket3 === eTixObj.eTicket2) {
			return false
		}
	}

	if (eTixObj.eTicket2) {
		if (eTixObj.eTicket2 === eTixObj.eTicket1) {
			return false
		}
	}
	return true
}

let _validatePreviousPaxETixDuplicate = (eTixObj, existingList) => {
	for (let i = 0; i < existingList.length; i++) {
		if (eTixObj.eTicket4) {
			if (
				eTixObj.eTicket4 === existingList[i].eTicket1 ||
				eTixObj.eTicket4 === existingList[i].eTicket2 ||
				eTixObj.eTicket4 === existingList[i].eTicket3 ||
				eTixObj.eTicket4 === existingList[i].eTicket4
			) {
				return false
			}
		}

		if (eTixObj.eTicket3) {
			if (
				eTixObj.eTicket3 === existingList[i].eTicket1 ||
				eTixObj.eTicket3 === existingList[i].eTicket2 ||
				eTixObj.eTicket3 === existingList[i].eTicket3 ||
				eTixObj.eTicket3 === existingList[i].eTicket4
			) {
				return false
			}
		}

		if (eTixObj.eTicket2) {
			if (
				eTixObj.eTicket2 === existingList[i].eTicket1 ||
				eTixObj.eTicket2 === existingList[i].eTicket2 ||
				eTixObj.eTicket2 === existingList[i].eTicket3 ||
				eTixObj.eTicket2 === existingList[i].eTicket4
			) {
				return false
			}
		}

		if (eTixObj.eTicket1) {
			if (
				eTixObj.eTicket1 === existingList[i].eTicket1 ||
				eTixObj.eTicket1 === existingList[i].eTicket2 ||
				eTixObj.eTicket1 === existingList[i].eTicket3 ||
				eTixObj.eTicket1 === existingList[i].eTicket4
			) {
				return false
			}
		}
	}

	return true
}

let _validateAttachmentRequired = (sector2Details, ticketDetails, files) => {
	if (ticketDetails && ticketDetails[0].isAttachmentRequired) {
		if (sector2Details.landSeaScootBool === 'na' || sector2Details.landSeaRemarks === '') return false
	}

	if (sector2Details.landSeaScootBool !== 'na') {
		if (sector2Details.landSeaRemarks === '') return false
		if ((sector2Details.attachmentUris && sector2Details.attachmentUris.length < 1) || !sector2Details.attachmentUris) {
			if (files.length < 1) return false
		}
	}
	return true
}

export const validateFormDetailsList = ({ formDetailsList }) => {
	let result = { success: true, message: 'All check pass' }
	let allETickets = []
	let allETicketsSet = new Set()

	for (let i = 0; i < formDetailsList.length; i++) {
		let checkCtpEligibilityResult = _checkCtpEligibility(formDetailsList[i])
		if (!checkCtpEligibilityResult) {
			result = { success: false, message: 'Cannot proceed because of passenger/s who not are eligible for CTP' }
			break
		}

		if (!formDetailsList[i].isDeleted && formDetailsList[i].redeemed) {
			result = { success: false, message: 'Please delete pax who has already redeemed to proceed' }
			break
		}
	}

	// check any repeated etickets
	for (let i = 0; i < formDetailsList.length; i++) {
		if (!formDetailsList[i].isDeleted) {
			let eticketsDetails = formDetailsList[i].eTicketsDetails
			let { eTicket1, eTicket2, eTicket3, eTicket4 } = eticketsDetails
			if (eTicket1) allETickets.push(eTicket1)
			if (eTicket2) allETickets.push(eTicket2)
			if (eTicket3) allETickets.push(eTicket3)
			if (eTicket4) allETickets.push(eTicket4)

			if (eTicket1) allETicketsSet.add(eTicket1)
			if (eTicket2) allETicketsSet.add(eTicket2)
			if (eTicket3) allETicketsSet.add(eTicket3)
			if (eTicket4) allETicketsSet.add(eTicket4)
		}
	}

	if (allETickets.length !== allETicketsSet.size) {
		result = { success: false, message: 'Please ensure there is no duplicate ETicket' }
	}

	if (formDetailsList.length > 0) {
		// if all passengers are deleted, then don't continue;
		let nonDeletedPassengers = formDetailsList.filter(x => x.isDeleted === false)
		if (nonDeletedPassengers.length < 1) {
			result = { success: false, message: 'Please ensure there is at least one passenger' }
		}
	}

	// check number of sector (should be same as 1st pax)
	let nonDeletedPassengers = formDetailsList.filter(x => x.isDeleted === false)
	let firstPax = nonDeletedPassengers[0]
	let errorNumberOfSector = nonDeletedPassengers.filter(s => s.numberOfSector !== firstPax.numberOfSector)
	if (errorNumberOfSector && errorNumberOfSector.length > 0) {
		result = { success: false, message: 'Please correct number of sector for all passengers' }
	}

	return result
}

let _checkCtpEligibility = formDetails => {
	let check = true
	if (!formDetails.isDeleted) {
		for (const t of formDetails.ticketDetails) {
			if (convertSqStatusForDisplay(t.ctpEligibility) === DISPLAY_NO) {
				check = false
				break
			}
		}
	}
	return check
}

export const checkBlankForm = (currentData, initData) => {
	var check = true
	let dataKeys = ['eTicketsDetails', 'personalDetails', 'sector1Details', 'sector2Details']

	dataKeys.forEach(dataKey => {
		for (let prop in currentData[dataKey]) {
			if (currentData[dataKey].hasOwnProperty(prop)) {
				if (currentData[dataKey][prop] !== initData[dataKey][prop]) {
					//if both are array continue;
					if (Array.isArray(currentData[dataKey][prop] && initData[dataKey][prop])) {
						if (JSON.stringify(currentData[dataKey][prop]) === JSON.stringify(initData[dataKey][prop])) {
							continue
						}
					}

					check = false
					break
				}
			}
		}
	})

	return check
}
