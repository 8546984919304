import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { updateAuthDuck, updateMultipleAuthProperties } from '../ducks/authDuck'
import { SHOULD_CHANGE_PASSWORD } from '../constants/localStorageType'
import { decrypt } from '../helpers/utilities'

class ProtectedRoute extends Component {
	render() {
		const { component: Component, isLoggedIn, shouldChangePassword, redirectPath, ...rest } = this.props

		// uncomment below to bypass auth check
		// return <Route {...rest} render={props => <Component {...props} />}/>

		// All this does is explicitly set login status in authDuck
		if (isLoggedIn === null) {
			let shouldChangePassword
			let isLoggedIn = false
			let shouldChangePasswordEncrypted = localStorage.getItem(SHOULD_CHANGE_PASSWORD)
			if (shouldChangePasswordEncrypted) shouldChangePassword = JSON.parse(decrypt(shouldChangePasswordEncrypted))
			const accessToken = localStorage.getItem('accessToken')
			if (accessToken) isLoggedIn = true

			this.props.updateMultipleAuthProperties([
				{ key: 'isLoggedIn', value: isLoggedIn },
				{ key: 'shouldChangePassword', value: shouldChangePassword }
			])

			return null
		}

		// redirect to redirectPath is set
		if (!isLoggedIn && redirectPath) {
			return <Route {...rest} render={props => <Redirect to={{ pathname: redirectPath, state: { from: props.location } }} />} />
		}

		// redirect to redirectPath is set
		if (shouldChangePassword) {
			return <Route {...rest} render={props => <Redirect to={{ pathname: '/change-password', state: { from: props.location } }} />} />
		}
		if (isLoggedIn === true) {
			return <Route {...rest} render={props => <Component {...props} />} />
		} else {
			localStorage.clear() //this basically logs the user out
			return <Route {...rest} render={props => <Redirect to={{ pathname: '/', state: { from: props.location } }} />} /> //this should return a redirect to 404 or 500, not the root path
		}
	}
}

const mapStateToProps = state => {
	const {
		authDuck: { isLoggedIn, shouldChangePassword }
	} = state

	return { isLoggedIn, shouldChangePassword }
}

const mapDispatchToProps = dispatch => {
	return {
		updateAuthDuck: (k, v) => dispatch(updateAuthDuck(k, v)),
		updateMultipleAuthProperties: propertiesToUpdate => dispatch(updateMultipleAuthProperties(propertiesToUpdate))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
