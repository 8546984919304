const SET_MESSAGE = "SET_MESSAGE";
const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const setErrorMessage= (message) => ({
    type: SET_MESSAGE,
    message: message
});

export const clearErrorMessage= (message) => ({
    type: CLEAR_MESSAGE,
    message: message
});

const initState = {
    message: '',
    isError: false,
};

const systemErrorReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_MESSAGE:
            return {
                ...state,
                message: action.message,
                isError: true
            };
        case CLEAR_MESSAGE:
            return {
                ...state,
                message: '',
                isError: false,
            };
        default:
            return state
    }
}

export default systemErrorReducer